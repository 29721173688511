import React from "react";
import { Formik, FormikHelpers } from "formik";
import {
  Button,
  Grid,
  MenuItem,
  Select,
  TextField,
  Theme,
  Typography,
} from "@mui/material";
import { makeStyles } from "tss-react/mui";
import { logger } from "../../../apis";
import { User } from "../../../models/User";
const Yup = require("yup")

const useStyles = makeStyles()((theme: Theme) => ({
  formWrapper: {
    padding: theme.spacing(1),
    "& label": {
      fontWeight: "bold",
    },
  },
  inputs: {
    backgroundColor: theme.palette.background.paper,
  },
  selectWrapper: {
    flexDirection: "row",
    alignItems: "center",
  },
  select: {
    margin: theme.spacing(0, 1, 0, 0),
  },
  button: {
    marginRight: theme.spacing(1),
  },
}));

const priorities = [
  "P1 Urgent: Complete system outage",
  "P2 HIGH: A primary feature outage",
  "P3 NORMAL: Standard support request",
  "P4 LOW: Feature request or questions about the platform",
];

interface Values {
  priority: string;
  subject: string;
  description: string;
}

interface Props {
  user?: User;
}

export const SupportForm = ({ user }: Props): JSX.Element => {
  const { classes } = useStyles();

  // https://formik.org/docs/api/formik
  const formikInit = {
    values: {
      priority: priorities[2],
      subject: "",
      description: "",
    },
    schema: Yup.object().shape({
      priority: Yup.string().required("Priority is required"),
      subject: Yup.string().max(100).required("Subject is required"),
      description: Yup.string().max(255).required("Description is required"),
    }),
  };

  const handleSubmit = async (
    values: Values,
    { setSubmitting, setStatus }: FormikHelpers<Values>
  ) => {
    setStatus("");
    try {
      let mailTo = "mailTo:" + process.env.REACT_APP_SUPPORT_EMAIL || "";
      mailTo += "?";
      mailTo += "subject=" + values.subject;
      mailTo += "&body=Priority: " + values.priority;
      mailTo += "%0D%0A%0D%0ADescription:%0D%0A" + values.description;
      mailTo += "%0D%0A%0D%0AHost: " + window.location.hostname;
      mailTo += "%0D%0AUserId: " + user?.id;
      mailTo += "%0D%0AUsername: " + user?.cognitoUsername;
      mailTo += "%0D%0AEmail: " + window.encodeURIComponent(user?.email || "");
      mailTo += "%0D%0AAdvisorGroupName: " + user?.advisorGroupName;
      window.open(mailTo);
    } catch (error) {
      setStatus("Sorry, error: " + ((error as Error)?.message || ""));
      logger.error(error as Error);
      setSubmitting(false);
    }
  };

  return (
    <div className={classes.formWrapper}>
      <Formik
        initialValues={formikInit.values}
        validationSchema={formikInit.schema}
        onSubmit={handleSubmit}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          touched,
          values,
          resetForm,
          status,
        }) => (
          <form onSubmit={handleSubmit}>
            <Grid
              container
              spacing={1}
              alignItems="flex-end"
              justifyContent="flex-end"
            >
              <Grid item xs={12}>
                {/*<label htmlFor="priority">Subject </label>*/}
                {/*<br />*/}
                <Typography variant="body1">
                  A short description of the issue.
                </Typography>
                <TextField
                  name="subject"
                  label="Subject"
                  variant="outlined"
                  className={classes.inputs}
                  fullWidth
                  value={values.subject}
                  error={Boolean(touched.subject && errors.subject)}
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
                <Typography variant="body1" color="error">
                  {errors.subject}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="body1">
                  Please provide as much detail about the error as possible.
                  What are you trying to accomplish and what is going wrong?
                </Typography>
                <TextField
                  name="description"
                  label="Description"
                  variant="outlined"
                  className={classes.inputs}
                  fullWidth
                  multiline
                  minRows={4}
                  value={values.description}
                  error={Boolean(touched.description && errors.description)}
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
                <Typography variant="body1" color="error">
                  {errors.description}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Select
                  name="priority"
                  value={values.priority}
                  onChange={handleChange}
                  variant="outlined"
                  autoWidth={true}
                  displayEmpty={false}
                  renderValue={() => values.priority}
                  className={classes.select}
                >
                  {priorities.map((priority: string) => (
                    <MenuItem value={priority} key={priority}>
                      {priority}
                    </MenuItem>
                  ))}
                </Select>
                <Typography variant="body1" color="error">
                  {errors.priority}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="body2">
                  This form will create an email to send through your default
                  email provider. <br />
                  Please attach any appropriate files or screenshots to the
                  email. <br />
                  Thank you!
                  <br />
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography
                  variant="body1"
                  color="error"
                  style={{ marginRight: 8 }}
                >
                  {status}
                </Typography>
                <Button
                  disabled={isSubmitting}
                  variant="contained"
                  color="primary"
                  type="submit"
                  className={classes.button}
                >
                  Submit
                </Button>
                <Button
                  disabled={isSubmitting}
                  variant="outlined"
                  color="primary"
                  type="submit"
                  onClick={() => resetForm()}
                  className={classes.button}
                >
                  Reset
                </Button>
                {/*<ConfirmationDialog*/}
                {/*  open={deleteOpen}*/}
                {/*  question={`Are you sure you want to delete this opportunity?`}*/}
                {/*  description={opportunity.title || ""}*/}
                {/*  onConfirm={handleDelete}*/}
                {/*  onDeny={() => setDeleteOpen(false)}*/}
                {/*/>*/}
              </Grid>
            </Grid>
          </form>
        )}
      </Formik>
    </div>
  );
};
