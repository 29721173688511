import { Box, MenuItem, Select } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Country, State } from "../../models/Geography";
import { StoreState } from "../../reducers";
import { connect } from "react-redux";
import { fetchCountries, fetchStates } from "../../actions/geographyActions";

interface Props {
  countries?: Country[];
  fetchCountries?: () => void;
  initCountryId?: number;
  onSelectCountry?: (c?: Country) => void;
  states?: State[];
  fetchStates?: () => void;
  initStateId?: number;
  onSelectState?: (s?: State) => void;
  automationId?: string;
}

export const _CountryStateSelect = ({
  countries,
  fetchCountries,
  initCountryId,
  onSelectCountry,
  states,
  fetchStates,
  initStateId,
  onSelectState,
  automationId = "country-state-select",
}: Props): JSX.Element => {
  const [countryId, setCountryId] = useState<number>(initCountryId || 0);
  const [stateId, setStateId] = useState<number>(initStateId || 0);

  useEffect(() => {
    if (!countries || !countries?.length) {
      fetchCountries && fetchCountries();
    }
    if (!states || !states?.length) {
      fetchStates && fetchStates();
    }
  }, []);

  return (
    <Box data-automation-id={automationId}>
      {countries?.length ? (
        <Select
          value={countryId}
          onChange={(event) => {
            setStateId(0);
            onSelectState && onSelectState(undefined);
            const country = countries?.filter(
              (country: Country) =>
                country.id === (event.target.value as number)
            )[0];
            // if (country) {
            setCountryId(country?.id || 0);
            onSelectCountry && onSelectCountry(country);
            // }
          }}
          variant="outlined"
          displayEmpty={true}
          // className={classes.select}
        >
          <MenuItem value={0}>
            <em>Select Country</em>
          </MenuItem>
          {countries?.map((country: Country) => {
            return (
              <MenuItem value={country.id} key={"country_" + country.id}>
                {country.name}
              </MenuItem>
            );
          })}
        </Select>
      ) : (
        <></>
      )}
      {states?.length ? (
        <Select
          value={stateId}
          onChange={(event) => {
            const state = states?.filter(
              (state: State) => state.id === (event.target.value as number)
            )[0];
            // if (state) {
            setStateId(state?.id || 0);
            onSelectState && onSelectState(state);
            // }
          }}
          variant="outlined"
          displayEmpty={true}
          style={{
            marginLeft: 8,
          }}
          // className={classes.select}
        >
          <MenuItem value={0}>
            <em>Select State/Province</em>
          </MenuItem>
          {states
            ?.filter((s) => s.countryId == countryId)
            .map((state: State) => {
              return (
                <MenuItem value={state.id} key={"state_" + state.id}>
                  {state.name}
                </MenuItem>
              );
            })}
        </Select>
      ) : (
        <></>
      )}
    </Box>
  );
};

const mapStateToProps = ({
  countries,
  states,
}: StoreState): {
  countries: Country[];
  states: State[];
} => {
  return { countries, states };
};

export const CountryStateSelect = connect(mapStateToProps, {
  fetchCountries,
  fetchStates,
})(_CountryStateSelect);
