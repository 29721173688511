import React from "react";
import { Box, Card, CardContent, Typography } from "@mui/material";
import { Estate } from "../../../../models/Estate";

export type AssetSummaryDetail = {
  value?: string;
  title?: string;
  key: string;
};

interface Props {
  estate?: Estate;
}

export const SummaryCard = ({ estate }: Props): JSX.Element => {
  // summary card numbers
  const totalAssetValue = estate?.estateSummary?.totalAssetsValue;
  let numAssets = 0,
    numProbateAssets = 0,
    numAccountsLinked = 0;
  if (estate?.assets) {
    numAssets = estate.assets?.length;
    numProbateAssets =
      estate.assets?.reduce(
        (prev, curr, index, assets) =>
          assets[index]?.is_probate ? prev + 1 : prev,
        0
      ) || 0;
    // TODO: figure out what the linked FI cat and subcat will be identified
    //  currently "linkedFI" will return 0 results, but is a placeholder
    numAccountsLinked =
      estate.assets?.reduce(
        (prev, curr, index, assets) =>
          assets[index]?.subcategory?.subcategory === "linkedFI"
            ? prev + 1
            : prev,
        0
      ) || 0;
  }

  const details: AssetSummaryDetail[] = [
    {
      value: `$${totalAssetValue?.toLocaleString()}` || "$--",
      title: "Total estate value",
      key: "total-estate-value",
    },
    // { value: "$134,012", title: "Assets distributed" },
    {
      value: numAssets?.toString() || "0",
      title: "Total assets added",
      key: "total-assets-added",
    },
    {
      value: numProbateAssets?.toString() || "0",
      title: "Probate assets",
      key: "total-probate-assets",
    },
    {
      value: numAccountsLinked?.toString() || "0",
      title: "Accounts linked",
      key: "total-accounts-linked",
    },
  ];

  const getSummaryDetail = (detail: AssetSummaryDetail): JSX.Element => {
    return detail.value ? (
      <Box
        key={`AssetSummaryDetails_${detail.key}`}
        sx={{
          textAlign: "center",
          pr: 1,
          "> h2, > p": {
            color: "common.white",
          },
        }}
      >
        <Typography variant="h2">{detail.value}</Typography>
        <Typography variant="body2">{detail.title}</Typography>
      </Box>
    ) : (
      <></>
    );
  };

  return (
    <Card sx={{ mb: 2 }}>
      <CardContent
        sx={{
          display: "flex",
          justifyContent: "space-between",
          background: "linear-gradient(120.89deg, #734ED2 0%, #5E78E7 83.84%);",
          color: "common.white",
          p: 3,
        }}
      >
        {details?.map((detail: AssetSummaryDetail) => getSummaryDetail(detail))}
      </CardContent>
    </Card>
  );
};
