import React from "react";
import { Box, Typography } from "@mui/material";

import alert1 from "../../../../assets/alert@1x.png";
import alert2 from "../../../../assets/alert@2x.png";
import alert3 from "../../../../assets/alert@3x.png";

export const UnsavedContent = (): JSX.Element => {
  return (
    <Box>
      <img src={alert1} srcSet={`${alert2} 2x, ${alert3} 3x`} alt="Alert" />
      <Typography variant="h3" sx={{ fontWeight: 800 }}>
        Heads up, you have some unsaved changes.
      </Typography>
      <Typography variant="body2" sx={{ mt: 2 }}>
        If you exit without saving, your changes will be lost.
      </Typography>
    </Box>
  );
};
