import { ActionTypes } from "../actions/types";
import { FinancialConvo } from "../models/FinancialConvo";
import { AnyAction } from "redux";

export const financialConvoReducer = (
  financialConvos: FinancialConvo[] | null = null,
  action: AnyAction
): FinancialConvo[] | null => {
  switch (action.type) {
    case ActionTypes.FetchFinancialConvos:
      return action.payload;
    case ActionTypes.FetchSingleFinancialConvo:
      if (financialConvos) {
        const index = financialConvos?.findIndex(
          (financialConvo: FinancialConvo) =>
            financialConvo.id === action.payload.id
        );
        if (index !== -1) {
          const newFinancialConvos = [...financialConvos];
          newFinancialConvos[index] = action.payload;
          return newFinancialConvos;
        } else {
          return [...financialConvos, action.payload];
        }
      } else {
        return [action.payload];
      }
    case ActionTypes.AppSignOut:
      return null;
    default:
      return financialConvos;
  }
};
