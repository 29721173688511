import React, { useEffect } from "react";
import {
  Box,
  FormControl,
  FormHelperText,
  InputLabel,
  TextField,
  MenuItem,
  Grid,
  Select,
  Theme,
  InputAdornment,
  Typography,
} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import { makeStyles } from "tss-react/mui";
import { connect } from "react-redux";
import { StoreState } from "../../../reducers";
import {
  fetchCountries,
  fetchStates,
  fetchCounties,
} from "../../../actions/geographyActions";
import { Formik, FormikHelpers } from "formik";
import { FormType } from "../../../models/FormType";
import {
  Country,
  County,
  State,
  stateLabelMappings,
  GeographyType,
} from "../../../models/Geography";
import SearchIcon from "@mui/icons-material/Search";
import { QueryPayload } from "../../../apis/AtticusForms";
import { Language } from "../../../models/Language";

export interface FormQueryValues {
  country?: Country;
  state?: State;
  county?: County;
  formType?: FormType;
  searchTerms?: string;
  language?: Language;
  geographyInclusive?: boolean;
}

const useStyles = makeStyles()((theme: Theme) => ({
  formControl: {
    marginRight: theme.spacing(1),
  },
  errorText: {
    color: "error.main",
  },
  searchField: {
    marginRight: theme.spacing(1),
  },
  formBox: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    gap: "1rem",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
    },
  },
  fields: {
    flex: 1,
  },
  loadingWrapper: {
    display: "flex",
    justifyContent: "end",
  },
}));

interface Props {
  countries?: Country[];
  fetchCountries?: () => void;
  onSelectCountry?: (c?: Country) => void;
  states?: State[];
  fetchStates?: () => void;
  onSelectState?: (s?: State) => void;
  counties?: County[];
  fetchCounties?: (stateId: number) => void;
  onSelectCounty?: (ct?: County) => void;
  formTypes?: FormType[];
  initFormTypeSlug?: string;
  onSelectFormType?: (ft: FormType) => void;
  languages?: Language[];
  initLanguageCode: string;
  onSelectLanguage?: (lang: Language) => void;
  initSearchTerms?: string;
  initGeographyId?: number;
  initGeographyType?: GeographyType.Country | GeographyType.State; // Do not allow prepopulation of County
  geographyInclusive?: boolean;
  onComplete: (values: QueryPayload) => void;
  loadingForms: boolean;
}

export const _AtticusFormsTableSettings = ({
  countries,
  fetchCountries,
  states,
  fetchStates,
  counties,
  fetchCounties,
  formTypes,
  initFormTypeSlug,
  languages,
  initLanguageCode,
  initSearchTerms,
  initGeographyId,
  initGeographyType,
  geographyInclusive = false,
  onComplete,
  loadingForms,
}: Props): JSX.Element => {
  const { classes } = useStyles();

  useEffect(() => {
    if (!countries || !countries?.length) {
      fetchCountries && fetchCountries();
    }
    if (!states || !states?.length) {
      fetchStates && fetchStates();
    }
    if (initGeographyType == GeographyType.State && initGeographyId) {
      fetchCounties && fetchCounties(initGeographyId);
    }
  }, []);

  const initValues: FormQueryValues = {
    formType: formTypes?.find((type) => type.slug == initFormTypeSlug),
    language: languages?.find((lang) => lang.code == initLanguageCode),
    searchTerms: initSearchTerms,
    geographyInclusive: geographyInclusive,
  };

  if (initGeographyType == GeographyType.Country) {
    initValues.country = countries?.find(
      (country) => country.id == initGeographyId
    );
  } else if (initGeographyType == GeographyType.State) {
    initValues.state = states?.find((state) => state.id == initGeographyId);
    initValues.country = countries?.find(
      (country) => country.id == initValues.state?.countryId
    );
  } else if (initGeographyType == GeographyType.County) {
    initValues.county = counties?.find(
      (county) => county.id == initGeographyId
    );
    initValues.state = states?.find(
      (state) => state.id == initValues.county?.stateId
    );
    initValues.country = countries?.find(
      (country) => country.id == initValues.state?.countryId
    );
  }

  const formikInit = {
    values: initValues,
  };

  const handleSubmit = async (
    values: FormQueryValues,
    { setSubmitting, setStatus }: FormikHelpers<FormQueryValues>
  ) => {
    setSubmitting(true);
    setStatus("");
    try {
      // Validation
      if (!values.country && values.state) {
        throw new Error("Country is required if state is Selected");
      }
      if (!values.state && values.county) {
        throw new Error("State is required if county is Selected");
      }
      if (
        values.country &&
        values.state &&
        values.country?.id != values.state?.countryId
      ) {
        throw new Error("State must be within selected Country.");
      }
      if (
        values.state &&
        values.county &&
        values.state?.id != values.county?.stateId
      ) {
        throw new Error("County must be within selected State.");
      }

      // Build Query
      const query: QueryPayload = {
        form_type_id: values.formType?.id,
        search_terms: values.searchTerms,
        language_id: values.language?.id,
        geography_inclusive: values.geographyInclusive,
      };
      if (values.county) {
        query.geography_type = values.county.geographyType;
        query.geography_id = values.county.id;
      } else if (values.state) {
        query.geography_type = values.state.geographyType;
        query.geography_id = values.state.id;
      } else if (values.country) {
        query.geography_type = values.country.geographyType;
        query.geography_id = values.country.id;
      }

      onComplete(query);
    } catch (error) {
      setStatus("Sorry, error: " + ((error as Error)?.message || ""));
    }
    setSubmitting(false);
  };

  return (
    <Formik
      initialValues={formikInit.values}
      onSubmit={handleSubmit}
      enableReinitialize
    >
      {({
        errors,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values,
        status,
        setFieldValue,
      }) => (
        <form onSubmit={handleSubmit} className={classes.formBox}>
          <Grid container className={classes.fields} spacing={2}>
            {/* Jurisdiction Items */}
            <Grid container item xs={12} spacing={2}>
              <Grid item xs={12} sm={4}>
                <FormControl
                  variant="outlined"
                  fullWidth
                  className={classes.formControl}
                >
                  <InputLabel id="country-select-label">Country</InputLabel>
                  <Select
                    name="country"
                    value={values.country?.slug || ""}
                    labelId="country-select-label"
                    label="Country"
                    disabled={!countries}
                    onChange={async (event: any): Promise<any> => {
                      const newCountry = countries?.find(
                        (c) => c.slug == event.target.value
                      );
                      setFieldValue("country", newCountry);
                      setFieldValue("state", undefined);
                      setFieldValue("county", undefined);
                    }}
                  >
                    <MenuItem value="all" key="all">
                      all
                    </MenuItem>
                    {countries?.map((country: Country) => {
                      return (
                        <MenuItem key={country.id} value={country.slug}>
                          {country.name}
                        </MenuItem>
                      );
                    })}
                  </Select>
                  {touched.country && errors.country && (
                    <FormHelperText className={classes.errorText}>
                      {errors.country}
                    </FormHelperText>
                  )}
                </FormControl>
              </Grid>

              <Grid item xs={12} sm={4}>
                <FormControl
                  fullWidth
                  variant="outlined"
                  className={classes.formControl}
                  disabled={!values.country}
                >
                  <InputLabel id="state-select-label">
                    {values.country
                      ? stateLabelMappings[values.country.slug]
                      : "State"}
                  </InputLabel>
                  <Select
                    name="state"
                    labelId="state-select-label"
                    label={
                      values.country
                        ? stateLabelMappings[values.country.slug]
                        : "State"
                    }
                    value={values.state?.slug || ""}
                    onChange={async (event: any): Promise<any> => {
                      const newState = states?.find(
                        (s) => s.slug == event.target.value
                      );
                      setFieldValue("state", newState);
                      setFieldValue("county", undefined);
                      if (newState) {
                        counties = undefined; // Clear options until new ones are loaded.
                        fetchCounties && fetchCounties(newState?.id);
                      }
                    }}
                    variant="outlined"
                  >
                    <MenuItem value="">
                      <em>Clear</em>
                    </MenuItem>
                    {states
                      ?.filter((s) => s.countryId == values?.country?.id)
                      .map((state: State) => {
                        return (
                          <MenuItem key={state.id} value={state.slug}>
                            {state.name}
                          </MenuItem>
                        );
                      })}
                  </Select>
                  {touched.state && errors.state && (
                    <FormHelperText className={classes.errorText}>
                      {errors.state}
                    </FormHelperText>
                  )}
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={4}>
                <FormControl
                  fullWidth
                  variant="outlined"
                  className={classes.formControl}
                  disabled={!values.state || !counties || counties.length == 0}
                >
                  <InputLabel id="county-select-label">County</InputLabel>
                  <Select
                    name="county"
                    value={values.county?.slug || ""}
                    labelId="county-select-label"
                    label="County"
                    onChange={async (event: any): Promise<any> => {
                      const newCounty = counties?.find(
                        (c) => c.slug == event.target.value
                      );
                      setFieldValue("county", newCounty);
                    }}
                  >
                    <MenuItem value="">
                      <em>Clear</em>
                    </MenuItem>
                    {counties?.map((county: County) => {
                      return (
                        <MenuItem key={county.id} value={county.slug}>
                          {county.name}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
            <Grid container item xs={12} spacing={2}>
              <Grid item xs={12} sm={3}>
                <FormControl
                  variant="outlined"
                  fullWidth
                  className={classes.formControl}
                  disabled={!formTypes}
                >
                  <InputLabel id="form-type-select-label">Form Type</InputLabel>
                  <Select
                    name="formType"
                    value={values.formType?.id.toString() || ""}
                    labelId="form-type-select-label"
                    label="Form Type"
                    onChange={async (event: any): Promise<any> => {
                      const newFormType = formTypes?.find(
                        (ft) => ft.id == parseInt(event.target.value)
                      );
                      setFieldValue("formType", newFormType);
                    }}
                  >
                    <MenuItem value="">
                      <em>Clear</em>
                    </MenuItem>
                    {formTypes?.map((ft: FormType) => {
                      return (
                        <MenuItem key={ft.id.toString()} value={ft.id.toString()}>
                          {ft.name}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={3}>
                <FormControl
                  variant="outlined"
                  fullWidth
                  className={classes.formControl}
                  disabled={!formTypes}
                >
                  <InputLabel id="language-select-label">Language</InputLabel>
                  <Select
                    name="language"
                    value={values.language?.id.toString() || ""}
                    labelId="language-select-label"
                    label="Language"
                    onChange={async (event: any): Promise<any> => {
                      const newLanguage = languages?.find(
                        (lang) => lang.id == parseInt(event.target.value)
                      );
                      setFieldValue("language", newLanguage);
                    }}
                  >
                    <MenuItem value="">
                      <em>Clear</em>
                    </MenuItem>
                    {languages?.map((lang: Language) => {
                      return (
                        <MenuItem key={lang.id.toString()} value={lang.id.toString()}>
                          {`${lang.name} (${lang.code})`}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  name="searchTerms"
                  fullWidth
                  className={classes.searchField}
                  value={values.searchTerms || ""}
                  onChange={handleChange}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon />
                      </InputAdornment>
                    ),
                  }}
                  variant="outlined"
                />
              </Grid>
            </Grid>
            {status && (
              <Grid container item xs={12} spacing={2}>
                <Box mx={1}>
                  <Typography color="error">{status}</Typography>
                </Box>
              </Grid>
            )}
          </Grid>
          <Box>
            <LoadingButton
              loading={isSubmitting || loadingForms}
              variant="contained"
              color="primary"
              type="submit"
            >
              Get Forms
            </LoadingButton>
          </Box>
        </form>
      )}
    </Formik>
  );
};

const mapStateToProps = ({
  countries,
  states,
  counties,
  formTypes,
  languages,
}: StoreState): {
  countries: Country[];
  states: State[];
  counties: County[];
  formTypes: FormType[];
  languages: Language[];
} => {
  return {
    countries,
    states,
    counties,
    formTypes,
    languages,
  };
};

export const AtticusFormsTableSettings = connect(mapStateToProps, {
  fetchCountries,
  fetchStates,
  fetchCounties,
})(_AtticusFormsTableSettings);
