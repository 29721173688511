import React, { useEffect, useRef } from "react";
import { Box, Tab, Tabs } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { AssetDialogSection, assetDialogNavItems } from "./assetDialogNavItems";
import { SxProps } from "@mui/system/styleFunctionSx";

interface Props {
  selectedId?: string;
  onUpdateSelectedId?: (id: string) => void;
  sx?: SxProps;
  className?: string;
}

export const AssetDialogNavBar = ({
  selectedId,
  onUpdateSelectedId,
  sx,
  className,
}: Props): JSX.Element => {
  const { hash } = useLocation();
  const navigate = useNavigate();
  const previousSelectedId = useRef("");

  const [selectedIndex, setSelectedIndex] = React.useState(0);

  useEffect(() => {
    // monitor selectedId passed to component and scroll to that section when updated
    if (
      selectedId &&
      selectedId !== "" &&
      selectedId !== previousSelectedId.current
    ) {
      const newIndex = assetDialogNavItems?.findIndex(
        (item: AssetDialogSection) => item.hash === selectedId
      );
      newIndex !== -1 && setSelectedIndex(newIndex);
      previousSelectedId.current = selectedId;
    }
  }, [selectedId]);

  const handleNavigate = (navIndex: number) => {
    // add has to url to cause scroll
    //  or fire callback method to force it if the hash is the same
    const newHash = assetDialogNavItems[navIndex].hash;
    if (newHash === hash.replace("#", "")) {
      onUpdateSelectedId?.(newHash);
    } else {
      navigate(`#${newHash}`);
    }
  };

  return (
    <Box sx={sx} className={className}>
      <Tabs
        value={selectedIndex}
        indicatorColor="primary"
        variant="scrollable"
        scrollButtons="auto"
        aria-label="Asset details"
        sx={{
          ".MuiTabs-indicator": {
            height: 2,
          },
        }}
      >
        {assetDialogNavItems.map((screen: AssetDialogSection, index) => {
          return (
            <Tab
              component="a"
              label={screen.title}
              onClick={() => handleNavigate(index)}
              disableRipple={true}
              key={screen.title}
              sx={{
                fontWeight: 600,
                fontSize: 14,
                lineHeight: "20px",
                color: "text.secondary",
                minWidth: "auto",
                padding: "6px 0 5px",
                margin: "0 32px 0 0",
                "&.Mui-selected": {
                  color: "primary.main",
                },
              }}
            />
          );
        })}
      </Tabs>
      <Box
        sx={{
          height: 2,
          backgroundColor: "grey.300",
          position: "relative",
          top: -2,
          zIndex: 10,
        }}
      />
    </Box>
  );
};
