import React, { FormEvent, useEffect, useState } from "react";
import {
  Box,
  Grid,
  TextField,
  InputAdornment,
  SvgIcon,
  Typography,
  Select,
  MenuItem,
  Theme,
} from "@mui/material";
import { makeStyles } from "tss-react/mui";
import { Search as SearchIcon } from "react-feather";
import { useNavigate } from "react-router-dom";
import { getQueryStringParams } from "../../utils/getQueryStringParams";
import { AdvisorGroup } from "../../models/AdvisorGroup";
import { StoreState } from "../../reducers";
import { User } from "../../models/User";
import { connect } from "react-redux";
import { fetchAdvisorGroups } from "../../actions/advisorGroupActions";

const useStyles = makeStyles()((theme: Theme) => ({
  root: {
    marginBottom: 30,
  },
  searchInput: {
    marginRight: theme.spacing(1),
    marginLeft: theme.spacing(1),
  },
  subHeading: {
    color: theme.palette.text.secondary,
    fontSize: 14,
  },
}));

interface Props {
  heading?: string;
  headingLineThrough?: boolean;
  subHeading?: string | JSX.Element;
  className?: string;
  breadcrumbs?: JSX.Element;
  belowSearch?: JSX.Element;
  searchType?: SearchType;
  showInvite?: boolean;
  showSearch?: boolean;
  rest?: any[];
  user?: User;
  advisorGroups?: AdvisorGroup[];
  fetchAdvisorGroups: () => void;
}

export enum SearchType {
  Estates = "estates",
  Assets = "assets",
}

const _ContentHeader = ({
  heading,
  headingLineThrough = false,
  subHeading,
  className,
  breadcrumbs,
  belowSearch,
  searchType = SearchType.Estates,
  showSearch = true,
  user,
  advisorGroups,
  fetchAdvisorGroups,
}: Props): JSX.Element => {
  const { classes } = useStyles();
  const navigate = useNavigate();
  const [localTerms, setLocalTerms] = useState<string>("");
  const [groupId, setGroupId] = useState<number>(0);

  const showGroupSelect = user?.isUberAdmin || false;

  const { terms } = getQueryStringParams();
  useEffect(() => {
    setLocalTerms(terms || "");
  }, [terms]);
  useEffect(() => {
    if (showGroupSelect && advisorGroups === null) {
      fetchAdvisorGroups();
    }
  }, [showGroupSelect]);

  const handleSearchSubmit = async (
    event?: FormEvent<HTMLFormElement>
  ): Promise<void> => {
    event?.preventDefault();
    // put the terms & groupId in the query string, search will happen as page reloads
    let searchPath = `/app/estates?terms=${encodeURI(localTerms)}`;
    if (groupId) {
      searchPath += `&gid=${groupId}`;
    }
    navigate(searchPath);
  };

  return (
    <div
      className={`${classes.root} ${className}`}
      data-automation-id="content-header"
    >
      <Grid container spacing={{ xs: 1, sm: 2 }}>
        <Grid item xs={12} sm={showSearch || belowSearch ? 5 : 12}>
          {breadcrumbs}
          <Typography
            component="h2"
            variant="h2"
            style={{ textDecoration: headingLineThrough ? "line-through" : "" }}
          >
            {heading}
          </Typography>
          {subHeading && <div className={classes.subHeading}>{subHeading}</div>}
        </Grid>
        {(showSearch || belowSearch) && (
          <Grid item xs={12} sm={7}>
            <Box
              display="flex"
              alignItems="flex-end"
              flexDirection="column"
              sx={{ width: "100%" }}
            >
              {showSearch && (
                <Box
                  sx={{ width: "100%" }}
                  display="flex"
                  justifyContent="flex-end"
                  alignItems="flex-end"
                  flexDirection="row"
                >
                  <form
                    onSubmit={handleSearchSubmit}
                    style={{
                      display: "flex",
                      width: "100%",
                      justifyContent: "end",
                    }}
                  >
                    {showGroupSelect &&
                      advisorGroups &&
                      advisorGroups.length && (
                        <Select
                          name="group"
                          size="small"
                          value={groupId}
                          onChange={(event) => {
                            setGroupId(event.target.value as number);
                          }}
                          variant="outlined"
                          displayEmpty={true}
                          sx={{
                            maxWidth: "50%",
                          }}
                        >
                          <MenuItem value={0} key={`groupId-all`}>
                            all
                          </MenuItem>
                          {advisorGroups?.map((advisorGroup: AdvisorGroup) => {
                            return (
                              <MenuItem
                                value={advisorGroup.id}
                                key={`groupId-${advisorGroup.id}`}
                              >
                                {advisorGroup.id}:
                                {advisorGroup.title?.replace("AG-", "")}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      )}
                    <TextField
                      size="small"
                      sx={(theme) => ({
                        ml: 1,
                        flex: 1,
                        [theme.breakpoints.up("sm")]: {
                          maxWidth: "250px",
                        },
                      })}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <SvgIcon fontSize="small" color="action">
                              <SearchIcon />
                            </SvgIcon>
                          </InputAdornment>
                        ),
                      }}
                      placeholder={`Search ${searchType.toString()}`}
                      variant="outlined"
                      value={localTerms}
                      onChange={(event) => setLocalTerms(event.target.value)}
                      onKeyDown={(event) => {
                        if (event.key === "Enter") {
                          handleSearchSubmit();
                        }
                      }}
                    />
                  </form>
                </Box>
              )}
              {belowSearch}
            </Box>
          </Grid>
        )}
      </Grid>
    </div>
  );
};

const mapStateToProps = ({
  user,
  advisorGroups,
}: StoreState): {
  user: User;
  advisorGroups: AdvisorGroup[];
} => {
  return { user, advisorGroups };
};

const ContentHeader = connect(mapStateToProps, {
  fetchAdvisorGroups,
})(_ContentHeader);
export default ContentHeader;
