import { Product, ProductJSON } from "./Product";

export type SubscriptionJSON = {
  isActive?: boolean;
  paymentInfoNeedsFix?: boolean;
  paidDate?: number;
  latestPaymentType?: string;
  product?: Product;
};

export class Subscription {
  static path = "subscription";
  constructor(
    public isActive?: boolean,
    public paymentInfoNeedsFix?: boolean,
    public paidDate?: number,
    public latestPaymentType?: string,
    public product?: Product
  ) {}

  static fromJSON = (json: SubscriptionJSON): Subscription => {
    if (json) {
      return new Subscription(
        json.isActive,
        json.paymentInfoNeedsFix,
        json.paidDate, // || 1652748535,
        json.latestPaymentType, // || "Subscription charge",
        Product.fromJSON(json.product as ProductJSON)
      );
    } else {
      return new Subscription();
    }
  };
}
