import React, { useState } from "react";
import {
  Box,
  TextField,
  Typography,
  Tooltip,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  List,
  ListItem,
  ListItemText,
  Divider,
  Chip,
  CircularProgress,
  Theme,
} from "@mui/material";
import { makeStyles } from "tss-react/mui";
import { debounce } from "lodash";
import { connect } from "react-redux";
import { StoreState } from "../../../../reducers";
import {
  KnowledgeEngineField,
  KnowledgeEngineFieldCategory,
} from "../../../../models/KnowledgeEngine";
import { fetchKnowledgeEngineFieldCategories } from "../../../../actions/knowledgeEngineFieldCategoriesActions";
const useStyles = makeStyles()((theme: Theme) => ({
  root: {
    height: "100%",
    width: "100%",
    display: "flex",
    flexDirection: "column",
  },
  topBar: {
    padding: "0.5rem",
    backgroundColor: theme.palette.background.default,
  },
  mainContainer: {
    flex: "1",
    overflow: "auto",
    backgroundColor: theme.palette.background.paper,
  },
  loadingContainer: {
    flex: 1,
    display: "flex",
    padding: "2rem",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    height: "100%",
    textAlign: "center",
  },
  fieldInfoContainer: {
    display: "flex",
  },
  rowDescriptor: {
    display: "inline-block",
    minWidth: "80px",
  },
  inline: {
    display: "inline",
  },
  secondaryTextSection: {
    color: theme.palette.text.secondary,
  },
  fieldTitleSection: {
    display: "flex",
    gap: "0.5rem",
  },
  fieldTitle: {
    display: "inline",
    fontWeight: "bold",
    flex: 1,
  },
  chipContainer: {
    maxWidth: "40%",
    "& .MuiChip-label": {
      mozWhiteSpace: "-moz-pre-wrap" /* Mozilla */,
      whiteSpace: "pre-line" /* CSS 3 (and 2.1 as well, actually) */,
      wordWrap: "break-word" /* IE */,
      wordBreak: "break-all",
      lineHeight: "0.9rem",
      padding: "0.5rem",
    },
  },
}));

/**
 * Convert an answer value to a displayable value.
 */
const getAnswerValueDisplay = (answerValue: any): any => {
  let answerDisplay;
  if (typeof answerValue === "boolean") {
    if (answerValue === true) {
      answerDisplay = "true";
    } else if (answerValue === false) {
      answerDisplay = "false";
    }
  } else if (typeof answerValue === "number") {
    answerDisplay = answerValue;
  } else {
    if (answerValue) {
      answerDisplay = answerValue;
    } else {
      answerDisplay = null;
    }
  }
  return answerDisplay;
};
const delay = (ms: number) => new Promise((res) => setTimeout(res, ms));

const FieldResult = ({
  field,
  categoriesById,
}: {
  field: KnowledgeEngineField;
  categoriesById: any;
}): JSX.Element => {
  const { classes } = useStyles();
  const answerDisplay = getAnswerValueDisplay(field.interface.answer_value);
  const exampleDisplay = getAnswerValueDisplay(field.interface.example_value);
  const defaultClickToCopyText = "Click to Copy to Clipboard";
  const [clickToCopyText, setClickToCopyText] = useState<string>(
    defaultClickToCopyText
  );
  return (
    <Tooltip
      onClick={async () => {
        navigator.clipboard.writeText(field.field_id);
        setClickToCopyText(`"${field.field_id}" Copied to Clipboard!`);
        await delay(2000);
        setClickToCopyText(defaultClickToCopyText);
      }}
      title={clickToCopyText}
      arrow
    >
      <ListItem button alignItems="flex-start">
        <ListItemText
          primary={
            <React.Fragment>
              <Box>
                <Box className={classes.fieldTitleSection}>
                  <Typography
                    component="span"
                    variant="body1"
                    className={classes.fieldTitle}
                    color="primary"
                  >
                    {field.interface.answer_label}
                  </Typography>
                  <Box className={classes.chipContainer}>
                    <Chip label={`${field.field_id}`} color="primary" />
                  </Box>
                </Box>
              </Box>
              <Box className={classes.secondaryTextSection}>
                <Box className={classes.fieldInfoContainer}>
                  <Typography
                    component="span"
                    variant="body2"
                    className={classes.rowDescriptor}
                  >
                    {"Question: "}
                  </Typography>
                  <Typography
                    component="span"
                    variant="body2"
                    className={classes.inline}
                    color="textPrimary"
                  >
                    {field.interface.question}
                  </Typography>
                </Box>
                <Box className={classes.fieldInfoContainer}>
                  <Typography
                    component="span"
                    variant="body2"
                    className={classes.rowDescriptor}
                  >
                    {"Category: "}
                  </Typography>
                  <Typography
                    component="span"
                    variant="body2"
                    className={classes.inline}
                    color="textPrimary"
                  >
                    {categoriesById[field.interface.category]?.title}
                  </Typography>
                </Box>
                <Box className={classes.fieldInfoContainer}>
                  <Typography
                    component="span"
                    variant="body2"
                    className={classes.rowDescriptor}
                  >
                    {"Example: "}
                  </Typography>
                  <Typography
                    component="span"
                    variant="body2"
                    className={classes.inline}
                    color="textPrimary"
                  >
                    {exampleDisplay}
                  </Typography>
                </Box>
                <Box className={classes.fieldInfoContainer}>
                  <Typography
                    component="span"
                    variant="body2"
                    className={classes.rowDescriptor}
                  >
                    {"Answer: "}
                  </Typography>
                  <Typography
                    component="span"
                    variant="body2"
                    className={classes.inline}
                    color="textPrimary"
                  >
                    {answerDisplay === null ? "No Answer." : answerDisplay}
                  </Typography>
                </Box>
              </Box>
            </React.Fragment>
          }
        />
      </ListItem>
    </Tooltip>
  );
};

interface Props {
  fields: KnowledgeEngineField[] | undefined;
  fetchKnowledgeEngineFieldCategories: () => void;
  knowledgeEngineFieldCategories: KnowledgeEngineFieldCategory[];
  estatesLoading?: boolean;
  selectedEstateId?: number;
}

const _FieldSearch = ({
  fields,
  fetchKnowledgeEngineFieldCategories,
  knowledgeEngineFieldCategories,
  estatesLoading,
  selectedEstateId,
}: Props): JSX.Element => {
  const { classes } = useStyles();

  const [searchQuery, setSearchQuery] = useState("");
  const allCategory = {
    id: "all",
    title: "All",
    order: -1,
  };
  // map of categories by id. ex: { 'executor': KnowledgeEngineFieldCategory, ...}
  const [categoriesById, setCategoriesById] = useState<any>({});
  const [selectedCategoryId, setSelectedCategoryId] = useState<string | null>(
    allCategory.id
  );
  const [searchResults, setSearchResults] = useState<KnowledgeEngineField[]>(
    []
  );
  const debouncedSetSearchQuery = debounce((val) => {
    setSearchQuery(val);
  }, 300);

  if (!knowledgeEngineFieldCategories) {
    fetchKnowledgeEngineFieldCategories();
  }
  React.useEffect(() => {
    const categoriesByIdObj: any = {};
    knowledgeEngineFieldCategories?.forEach((thisCategory) => {
      categoriesByIdObj[thisCategory.id] = thisCategory;
    });
    setCategoriesById(categoriesByIdObj);
  }, [knowledgeEngineFieldCategories]);
  React.useEffect(() => {
    let allResultsBeforeCategoryFiltering: KnowledgeEngineField[] = [];
    if (!searchQuery && fields) {
      allResultsBeforeCategoryFiltering = fields;
    } else {
      const matchingResults = fields?.filter((thisField) => {
        const searchLower = searchQuery.toLowerCase();
        let matches = false;
        if (
          thisField.field_id &&
          thisField.field_id.indexOf(searchLower) !== -1
        ) {
          matches = true;
        }
        if (
          thisField.interface.question &&
          thisField.interface.question.indexOf(searchLower) !== -1
        ) {
          matches = true;
        }
        if (
          thisField.interface.answer_label &&
          thisField.interface.answer_label.indexOf(searchLower) !== -1
        ) {
          matches = true;
        }
        if (
          typeof thisField.interface.answer_value === "string" &&
          thisField.interface.answer_value.indexOf(searchLower) !== -1
        ) {
          matches = true;
        }
        return matches;
      });
      if (matchingResults) {
        allResultsBeforeCategoryFiltering = matchingResults;
      }
    }
    if (selectedCategoryId !== "all") {
      const allResultsAfterCategoryFiltering =
        allResultsBeforeCategoryFiltering.filter((thisField) => {
          return thisField.interface.category === selectedCategoryId;
        });
      setSearchResults(allResultsAfterCategoryFiltering);
    } else {
      setSearchResults(allResultsBeforeCategoryFiltering);
    }
  }, [fields, searchQuery, selectedCategoryId]);

  return (
    <Box className={classes.root}>
      <Box className={classes.topBar}>
        <Grid container spacing={1}>
          <Grid item xs={8}>
            <TextField
              label="Search all fields"
              id="searck-ke-fields"
              size="small"
              disabled={!fields}
              variant="outlined"
              fullWidth
              onChange={(event) => {
                debouncedSetSearchQuery(event.target.value as string);
              }}
            />
          </Grid>
          <Grid item xs={4}>
            <FormControl fullWidth variant="outlined" size="small">
              <InputLabel id="category-select-label">Category</InputLabel>
              <Select
                disabled={!fields}
                labelId="category-select-label"
                id="category-select"
                value={selectedCategoryId}
                label="Category 2"
                onChange={(e) => setSelectedCategoryId("" + e?.target?.value)}
              >
                <MenuItem value="all">All</MenuItem>
                {knowledgeEngineFieldCategories
                  ?.sort((a, b) => a.order - b.order)
                  .map((thisCategory) => {
                    return (
                      <MenuItem value={thisCategory.id} key={thisCategory.id}>
                        {thisCategory.title}
                      </MenuItem>
                    );
                  })}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      </Box>
      <Box className={classes.mainContainer}>
        {fields ? (
          <List>
            {searchResults.map((thisField, index) => {
              return (
                <Box key={index}>
                  <FieldResult
                    field={thisField}
                    categoriesById={categoriesById}
                    key={thisField.field_id}
                  />
                  {index < searchResults.length - 1 && (
                    <Divider component="li" />
                  )}
                </Box>
              );
            })}
          </List>
        ) : selectedEstateId || estatesLoading ? (
          <Box className={classes.loadingContainer}>
            <CircularProgress />
          </Box>
        ) : (
          <Box className={classes.loadingContainer}>
            <Typography>
              Select an Estate to see the available fields from our Knowledge
              Fetcher.
            </Typography>
          </Box>
        )}
      </Box>
    </Box>
  );
};

const mapStateToProps = ({
  knowledgeEngineFieldCategories,
}: StoreState): {
  knowledgeEngineFieldCategories: KnowledgeEngineFieldCategory[];
} => {
  return { knowledgeEngineFieldCategories };
};

export const FieldSearch = connect(mapStateToProps, {
  fetchKnowledgeEngineFieldCategories,
})(_FieldSearch);
