import React from "react";
import { Avatar, Box, Button, Menu, MenuItem, Theme, Typography } from "@mui/material";
import { makeStyles } from "tss-react/mui";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import { StoreState } from "../../../reducers";
import { connect } from "react-redux";
import { User } from "../../../models/User";
import { appSignOut } from "../../../actions/userActions";
import { logger } from "../../../apis";
import { NavigateFunction } from "react-router";

const useStyles = makeStyles()((theme:Theme) => ({
  avatar: {
    width: 42,
    height: 42,
    marginRight: 10,
  },
  buttonContent: {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
    alignItems: "center",
  },
  buttonContentLeft: {
    display: "flex",
    alignItems: "center",
  },
  menuItem: {
    width: 208,
    textAlign: "right",
    display: "inherit",
  },
}));

interface Props {
  user?: User;
  appSignOut: (user?: User, navigate?: NavigateFunction) => void;
}

export const _AdvisorMenuAndSignOut = ({
  user,
  appSignOut,
}: Props): JSX.Element => {
  const { classes } = useStyles();
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSignOut = async (): Promise<void> => {
    try {
      handleClose();
      appSignOut(user, navigate);
    } catch (error) {
      logger.error(error as Error);
    }
  };

  return (
    <>
      {!user ? null : (
        <Box
          alignItems="center"
          display="flex"
          sx={{
            p: 2,
          }}
        >
          <Button
            onClick={handleClick}
            fullWidth
            data-automation-id="profile-menu"
            sx={{
              p: "6px 8px",
            }}
          >
            <div className={classes.buttonContent}>
              <div className={classes.buttonContentLeft}>
                <Avatar className={classes.avatar} src={user?.pictureUrl} />
                <Typography color="textPrimary">{user?.first}</Typography>
              </div>
              <div>
                {anchorEl ? (
                  <ArrowDropUpIcon color="primary" />
                ) : (
                  <ArrowDropDownIcon color="primary" />
                )}
              </div>
            </div>
          </Button>
          <Menu
            id="advisor-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
            transformOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
            anchorOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
          >
            <MenuItem
              onClick={handleClose}
              component={RouterLink}
              to="/app/profile"
              className={classes.menuItem}
            >
              Profile
            </MenuItem>
            <MenuItem
              onClick={handleSignOut}
              className={classes.menuItem}
              data-automation-id="sign-out"
            >
              Sign out
            </MenuItem>
          </Menu>
        </Box>
      )}
    </>
  );
};

const mapStateToProps = ({ user }: StoreState): { user: User } => {
  return { user };
};

export const AdvisorMenuAndSignOut = connect(mapStateToProps, {
  appSignOut,
})(_AdvisorMenuAndSignOut);
