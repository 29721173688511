import React from "react";
import {
  MobileStepper,
  Box,
  Button,
  Theme,
  useMediaQuery,
} from "@mui/material";
import { makeStyles, withStyles } from "tss-react/mui";
import ArrowBackIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIcon from "@mui/icons-material/ArrowForwardIos";
import atticusColors from "../../../../theme/colors";

const useStyles = makeStyles()((theme: Theme) => ({
  root: {
    display: "flex",
    justifyContent: "space-between",
  },
  right: {
    justifyContent: "flex-end",
  },
  button: {
    minWidth: "60px",

    [theme.breakpoints.up("sm")]: {
      minWidth: "125px",
    },
  },
}));

const StepperStyled = withStyles(MobileStepper, (theme: Theme) => ({
  root: {
    background: "none",
  },
  dots: {
    alignItems: "center",
  },
  dot: {
    backgroundColor: atticusColors.purple0,
    width: "6px",
    height: "6px",
  },
  dotActive: {
    backgroundColor: atticusColors.purple35,
    width: "8px",
    height: "8px",
  },
}));

interface Props {
  steps?: number;
  activeStep: number | undefined;
  hidePrevious?: boolean;
  hideNext?: boolean;
  onPrevious: () => void;
  onNext: () => void;
}

export const TaskNav = ({
  activeStep,
  hidePrevious = false,
  hideNext = false,
  onPrevious,
  onNext,
  steps,
}: Props): JSX.Element => {
  const { classes } = useStyles();
  const matches = useMediaQuery((theme: Theme) => theme.breakpoints.down("xs"));

  // We add width to button wrapper to ensure elements stay positioned when hidden
  const buttonBoxWidth = matches ? "60px" : "125px";

  return (
    <StepperStyled
      variant={matches ? "text" : "dots"}
      steps={steps || 0}
      position="static"
      activeStep={activeStep}
      backButton={
        <Box width={buttonBoxWidth}>
          {!hidePrevious && (
            <Button
              variant="contained"
              color="primary"
              startIcon={<ArrowBackIcon />}
              onClick={() => onPrevious()}
              disableRipple={true}
              className={`${classes.button} primary-inverted`}
            >
              {!matches && "Previous"}
            </Button>
          )}
        </Box>
      }
      nextButton={
        <Box width={buttonBoxWidth}>
          {!hideNext && (
            <Button
              variant="contained"
              color="primary"
              endIcon={<ArrowForwardIcon />}
              onClick={() => onNext()}
              disableRipple={true}
              className={`${classes.button} primary-inverted`}
            >
              {!matches && "Next"}
            </Button>
          )}
        </Box>
      }
    />
  );
};