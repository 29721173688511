import React from "react";
import { SvgIcon, SvgIconProps } from "@mui/material";
import { ReactComponent as BoxesIcon } from "../../assets/icons/atticus/boxes.svg";
import { ReactComponent as ClipboardIcon } from "../../assets/icons/atticus/clipboard.svg";
import { ReactComponent as DollarIcon } from "../../assets/icons/atticus/dollar.svg";
import { ReactComponent as PhoneIcon } from "../../assets/icons/atticus/phone.svg";
import { ReactComponent as HeadphonesIcon } from "../../assets/icons/atticus/headphones.svg";
import { ReactComponent as CheckIcon } from "../../assets/icons/atticus/check.svg";
import { ReactComponent as FolderWithStarIcon } from "../../assets/icons/atticus/folder-with-star.svg";
import { ReactComponent as QuestionnaireIcon } from "../../assets/icons/atticus/questionnaire.svg";
import { ReactComponent as HomeIcon } from "../../assets/icons/atticus/house.svg";

export const Boxes = (props: SvgIconProps): JSX.Element => {
  return <SvgIcon component={BoxesIcon} {...props} />;
};

export const Dollar = (props: SvgIconProps): JSX.Element => {
  return <SvgIcon component={DollarIcon} {...props} />;
};

export const Phone = (props: SvgIconProps): JSX.Element => {
  return <SvgIcon component={PhoneIcon} {...props} />;
};

export const Clipboard = (props: SvgIconProps): JSX.Element => {
  return <SvgIcon component={ClipboardIcon} {...props} />;
};

export const Headphones = (props: SvgIconProps): JSX.Element => {
  return <SvgIcon component={HeadphonesIcon} {...props} />;
};

export const Check = (props: SvgIconProps): JSX.Element => {
  return <SvgIcon component={CheckIcon} {...props} />;
};

export const FolderWithStar = (props: SvgIconProps): JSX.Element => {
  return <SvgIcon component={FolderWithStarIcon} {...props} />;
};

export const QuestionnaireBox = (props: SvgIconProps): JSX.Element => {
  return <SvgIcon component={QuestionnaireIcon} {...props} />;
};

export const Home = (props: SvgIconProps): JSX.Element => {
  return <SvgIcon component={HomeIcon} {...props} />;
};

export const CheckMarkIcon = (props: SvgIconProps): JSX.Element => {
  return (
    <SvgIcon {...props}>
      <circle cx="30" cy="30" r="20" fill="#F2F2FF" />
      <rect
        x="24.7007"
        y="35.5334"
        width="17.4998"
        height="3.28122"
        rx="1.25"
        transform="rotate(-45 24.7007 35.5334)"
        fill="#9292FF"
      />
      <rect
        x="23.1538"
        y="29.3464"
        width="8.74992"
        height="3.28122"
        rx="1.25"
        transform="rotate(45 23.1538 29.3464)"
        fill="#9292FF"
      />
      <path
        d="M50 30C50 41.0457 41.0457 50 30 50C18.9543 50 10 41.0457 10 30C10 18.9543 18.9543 10 30 10C41.0457 10 50 18.9543 50 30ZM12 30C12 39.9411 20.0589 48 30 48C39.9411 48 48 39.9411 48 30C48 20.0589 39.9411 12 30 12C20.0589 12 12 20.0589 12 30Z"
        fill="#9292FF"
      />
    </SvgIcon>
  );
};
