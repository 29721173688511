import { SubScreen } from "../../models/SubScreen";

export const adminNavItems: SubScreen[] = [
  {
    title: "Forms Library",
    path: "forms",
  },
  {
    title: "Probate and Packets",
    path: "probate",
  },
  {
    title: "Groups",
    path: "groups",
  },
  {
    title: "Demo Estates Raw",
    path: "demo-estates",
  },
  {
    title: "Exploratory",
    path: "exploratory",
  },
];
