import {
  GeographyJson,
  GeographyType,
  Country,
  State,
  County,
} from "./Geography";
import { FormStatusType, FormStatusTypeJson } from "./FormStatusType";
import { User, UserJSON } from "./User";
import { PUBLIC_FORMS_CDN_URL } from "../constants";
import moment from "moment";
import { formsApi } from "../apis";
import { FormAuthority, FormAuthorityJson } from "./FormAuthority";
import { EstateFormMap, EstateFormMapJSON } from "./EstateFormMap";

export type FormTitleObject = {
  original: string;
  atticus?: string;
};

export type FormPacket = {
  probateTypeId: number;
  forms?: AtticusForm[];
};

export type FormDescriptionObject = {
  original?: string;
  atticus?: string;
};

export type S3FileKeys = {
  jsonFile?: string;
  pdfStorage?: string;
  processedPdf?: string;
  watermarkedPdf?: string;
  thumbnailFile?: string;
};

export type S3FileUrls = {
  asyncPdfStoragePdf?: () => Promise<string | undefined>;
  asyncProcessedPdf?: () => Promise<string | undefined>;
  watermarkedPdf?: string;
  thumbnailFile?: string;
};

export interface FormJson {
  atticus_description?: string;
  atticus_title?: string;
  contentful_form_page_template_id?: string;
  created_at?: string;
  deleted_at?: string;
  dynamodb_id?: string;
  effective_date?: string;
  file_name?: string;
  file_source_url?: string;
  form_category_from_scrape?: string;
  form_detail_url?: string;
  status?: FormStatusTypeJson;
  form_type_id?: number;
  geography_id?: number;
  geography?: GeographyJson;
  geography_type?: GeographyType;
  government_id?: string;
  id: number;
  imported_by_user_id?: number;
  imported_by?: UserJSON;
  is_fillable?: boolean;
  json_file_key?: string;
  language_id?: number;
  last_edited_by_user_id?: number;
  last_edited_by?: UserJSON;
  original_description?: string;
  pdf_storage_key?: string;
  processed_pdf_key?: string;
  published_date?: string;
  raw_text_content?: string;
  revision_date?: string;
  scrape_start?: string;
  scraped_file_ext?: string;
  statutes?: string;
  thumbnail_file_key?: string;
  title: string;
  updated_at?: string;
  watermarked_pdf_key?: string;
  authority?: FormAuthorityJson;
  slug?: string;
  latest_interface_id?: number;
  estate_form?: EstateFormMapJSON;
}

export class AtticusForm {
  constructor(
    public id: number,
    public title: FormTitleObject,
    public s3Keys?: S3FileKeys,
    public languageId?: number,
    public governmentId?: string,
    public description?: FormDescriptionObject,
    public scrapedFileExt?: string,
    public fileName?: string,
    public fileSourceUrl?: string,
    public formTypeId?: number,
    public formDetailUrl?: string,
    public formCategoryFromScrape?: string,
    public statutes?: string[],
    public publishedDate?: Date,
    public revisionDate?: Date,
    public effectiveDate?: Date,
    public scrapeStart?: string,
    public rawTextContent?: string,
    public importedBy?: User,
    public lastEditedBy?: User,
    public geography?: Country | State | County,
    public geographyId?: number,
    public geographyType?: GeographyType,
    public createdAt?: Date,
    public updatedAt?: Date,
    public deletedAt?: Date,
    public formStatus?: FormStatusType,
    public contentfulFormPageTemplateId?: string,
    public isFillable?: boolean,
    public dynamodbId?: string,
    public s3Urls?: S3FileUrls,
    public authority?: FormAuthority,
    public slug?: string,
    public latestInterfaceId?: number,
    public estateFormMap?: EstateFormMap
  ) {}

  getGovernmentId(): string | undefined {
    return this.governmentId;
  }

  getTitle(): string {
    return this.title.atticus || this.title.original;
  }

  getDescription(): string | undefined {
    return this.description?.atticus || this.description?.original;
  }

  toJSON(): FormJson {
    const retJson: FormJson = {
      atticus_description: this.description?.atticus,
      atticus_title: this.title.atticus,
      contentful_form_page_template_id: this.contentfulFormPageTemplateId,
      created_at: this.createdAt ? this.createdAt.toISOString() : undefined,
      deleted_at: this.deletedAt ? this.deletedAt.toISOString() : undefined,
      dynamodb_id: this.dynamodbId,
      effective_date: this.effectiveDate?.toISOString().split("T")[0],
      file_name: this.fileName,
      file_source_url: this.fileSourceUrl,
      form_category_from_scrape: this.formCategoryFromScrape,
      form_detail_url: this.formDetailUrl,
      status: this.formStatus?.toJSON(),
      form_type_id: this.formTypeId,
      geography: this.geography?.toJSON(),
      geography_id: this.geographyId,
      geography_type: this.geographyType,
      government_id: this.governmentId,
      id: this.id,
      imported_by_user_id: this.importedBy?.id,
      is_fillable: this.isFillable,
      json_file_key: this.s3Keys?.jsonFile,
      language_id: this.languageId,
      last_edited_by_user_id: this.lastEditedBy?.id,
      original_description: this.description?.original,
      pdf_storage_key: this.s3Keys?.pdfStorage,
      processed_pdf_key: this.s3Keys?.processedPdf,
      published_date: this.publishedDate?.toISOString().split("T")[0],
      raw_text_content: this.rawTextContent,
      revision_date: this.revisionDate?.toISOString().split("T")[0],
      scrape_start: this.scrapeStart,
      scraped_file_ext: this.scrapedFileExt,
      statutes: this.statutes ? JSON.stringify(this.statutes) : undefined,
      thumbnail_file_key: this.s3Keys?.thumbnailFile,
      title: this.title.original,
      updated_at: this.updatedAt ? this.updatedAt.toISOString() : undefined,
      watermarked_pdf_key: this.s3Keys?.watermarkedPdf,
      authority: this.authority?.toJSON(),
      slug: this.slug,
      latest_interface_id: this.latestInterfaceId,
      estate_form: this.estateFormMap?.toJSON(),
    };
    return retJson;
  }

  getLocalCreatedAt(): string {
    return moment.utc(this.createdAt).local().format("MMMM Do YYYY, h:mm:ss a");
  }

  getLocalUpdatedAt(): string {
    return moment.utc(this.updatedAt).local().format("MMMM Do YYYY, h:mm:ss a");
  }

  getLocalDeletedAt(): string {
    return moment.utc(this.deletedAt).local().format("MMMM Do YYYY, h:mm:ss a");
  }

  static from(form: AtticusForm): AtticusForm {
    return new AtticusForm(
      form.id,
      form.title,
      form.s3Keys,
      form.languageId,
      form.governmentId,
      form.description,
      form.scrapedFileExt,
      form.fileName,
      form.fileSourceUrl,
      form.formTypeId,
      form.formDetailUrl,
      form.formCategoryFromScrape,
      form.statutes,
      form.publishedDate,
      form.revisionDate,
      form.effectiveDate,
      form.scrapeStart,
      form.rawTextContent,
      form.importedBy,
      form.lastEditedBy,
      form.geography,
      form.geographyId,
      form.geographyType,
      form.createdAt,
      form.updatedAt,
      form.deletedAt,
      form.formStatus,
      form.contentfulFormPageTemplateId,
      form.isFillable,
      form.dynamodbId,
      form.s3Urls,
      form.authority,
      form.slug,
      form.latestInterfaceId,
      form.estateFormMap
    );
  }

  static fromJSON(json: FormJson): AtticusForm {
    // Create S3FileKeys
    const s3Keys: S3FileKeys = {
      jsonFile: json.json_file_key,
      pdfStorage: json.pdf_storage_key,
      processedPdf: json.processed_pdf_key,
      watermarkedPdf: json.watermarked_pdf_key,
      thumbnailFile: json.thumbnail_file_key,
    };

    const s3Urls: S3FileUrls = {
      asyncPdfStoragePdf: json.pdf_storage_key
        ? () => {
            return new Promise((resolve, reject) => {
              try {
                resolve(formsApi.fetchSignedUrl(json.id, true));
              } catch (err) {
                reject(err);
              }
            });
          }
        : undefined,
      asyncProcessedPdf: json.processed_pdf_key
        ? () => {
            return new Promise((resolve, reject) => {
              try {
                resolve(formsApi.fetchSignedUrl(json.id));
              } catch (err) {
                reject(err);
              }
            });
          }
        : undefined,
      watermarkedPdf: json.watermarked_pdf_key
        ? `${PUBLIC_FORMS_CDN_URL}/${encodeURI(json.watermarked_pdf_key)}`
        : undefined,
      thumbnailFile: json.thumbnail_file_key
        ? `${PUBLIC_FORMS_CDN_URL}/${encodeURI(json.thumbnail_file_key)}`
        : undefined,
    };

    // Create FormTitleObject
    const title: FormTitleObject = {
      original: json.title,
      atticus: json.atticus_title,
    };

    // Create FormDescriptionObject
    const description: FormDescriptionObject = {
      original: json.original_description,
      atticus: json.atticus_description,
    };

    // Create The Correct Geography
    // Hacky but works until I can revisit Polymorphic model in backend
    const geographyJSON = json.geography;
    let geographyObject:Country|State|County|undefined = undefined;

    if (geographyJSON?.geography_type == GeographyType.Country) {
      geographyObject = Country.fromJSON(geographyJSON);
    } else if (geographyJSON?.geography_type == GeographyType.State) {
      geographyObject = State.fromJSON(geographyJSON);
    } else if (geographyJSON?.geography_type == GeographyType.County) {
      geographyObject = County.fromJSON(geographyJSON);
    }

    return new AtticusForm(
      json.id,
      title,
      s3Keys,
      json.language_id,
      json.government_id,
      description,
      json.scraped_file_ext,
      json.file_name,
      json.file_source_url,
      json.form_type_id,
      json.form_detail_url,
      json.form_category_from_scrape,
      json.statutes ? JSON.parse(json.statutes) : undefined,
      json.published_date ? new Date(json.published_date) : undefined,
      json.revision_date ? new Date(json.revision_date) : undefined,
      json.effective_date ? new Date(json.effective_date) : undefined,
      json.scrape_start,
      json.raw_text_content,
      json.imported_by ? User.fromJSON(json.imported_by) : undefined, // User JSON to User
      json.last_edited_by ? User.fromJSON(json.last_edited_by) : undefined, // User JSON to User
      geographyObject ? geographyObject : undefined, // Geography JSON to Geography
      json.geography_id,
      json.geography_type,
      json.created_at ? new Date(json.created_at) : undefined,
      json.updated_at ? new Date(json.updated_at) : undefined,
      json.deleted_at ? new Date(json.deleted_at) : undefined,
      json.status ? FormStatusType.fromJSON(json.status) : undefined, // Form Status JSON to Form Status
      json.contentful_form_page_template_id,
      json.is_fillable,
      undefined,
      s3Urls,
      json.authority ? FormAuthority.fromJSON(json.authority) : undefined,
      json.slug,
      json.latest_interface_id,
      json.estate_form ? EstateFormMap.fromJSON(json.estate_form) : undefined
    );
  }
}
