import { TaskPhase } from "../../../../models/TaskPhase";
import React from "react";
import clsx from "clsx";
import {
  Step,
  Stepper,
  StepConnector,
  StepIconProps,
  StepLabel,
  Theme,
  Typography,
  StepButton,
  useTheme,
} from "@mui/material";
import { makeStyles, withStyles } from "tss-react/mui";
import { Check } from "../../../shared/AtticusIcons";
import atticusColors from "../../../../theme/colors";
import { TaskIcon } from "./TaskIcons";

function a11yProps(title: string) {
  return {
    id: `task-phase-${title}`,
    "aria-controls": `task-phase-${title}`,
  };
}


// const StyledStepConnector = withStyles(StepConnector, (theme: Theme) => ({
//   root: {

//   },
//   completed: {
//     "& $line": {
//       borderLeft: `4px solid ${theme.palette.secondary.main}`,
//     },
//   },
//   line: {
//     borderLeft: `2px solid ${theme.palette.grey["300"]}`,
//   },
// }));

// const StyledStepLabel = withStyles(StepLabel, (theme: Theme) => ({
//   root: {
//     paddingLeft: "24px",
//     textAlign: "left",
//   },
//   active: {},
//   completed: {},
//   label: {
//     "&$active": {
//       color: theme.palette.text.primary,
//       fontWeight: 600,
//     },
//     "&$completed": {
//       color: theme.palette.text.primary,
//       fontWeight: 600,
//     },
//     color: theme.palette.text.primary,
//     fontWeight: 600,
//   },
// }));

// const useStyles = makeStyles()((theme: Theme) => ({
//   root: {
//     position: "relative",
//     borderRight: `4px solid ${theme.palette.divider}`,
//     padding: "24px 0",
//   },
//   button: {
//     margin: "-17px 0 -17px -8px",
//     paddingRight: "0",
//     border: "none",
//     backgroundColor: "transparent",
//   },
//   selected: {
//     borderRight: `4px solid ${atticusColors.purple2}`,
//     backgroundColor: "rgba(98, 112, 201, 0.1)",
//   },
//   caption: {
//     fontSize: "14px",
//     color: theme.palette.text.secondary,
//   },
//   captionSelected: {
//     color: `${atticusColors.purple2}`,
//   },
// }));

// const customStepIconStyles = makeStyles()((theme: Theme) => ({
//   root: ,
//   active: {},
//   completed: {
//     border: `2px solid ${theme.palette.secondary.main}`,
//     backgroundColor: theme.palette.secondary.main,
//   },
// }));

interface Props {
  currentTaskPhase: TaskPhase;
  taskPhases: TaskPhase[];
  onPhase: (taskPhase: TaskPhase) => void;
  onClose?: () => void;
}

interface IconProps extends Omit<StepIconProps, "icon"> {
  icon: number;
}

const CustomStepIcon = (props: IconProps): JSX.Element => {
  const { active, completed, icon } = props;
  const theme = useTheme()

  return (
    <div>
      {completed ? (
        <Check style={{ width: "55%" }} viewBox="0 0 17 12" />
      ) : (
        <div style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          width: "36px",
          height: "36px",
          borderRadius: "50%",
          border: `2px solid ${theme.palette.grey["300"]}`,
          backgroundColor: "transparent",
        }}>
          <TaskIcon sequence={icon} iconSm active={active}  />
        </div>
      )}
    </div>
  );
};

export const TaskPhaseNav = ({
  currentTaskPhase,
  taskPhases,
  onPhase,
  onClose,
}: Props): JSX.Element => {
  const theme = useTheme()
  return (
    <div style={{
      position:"relative",
      borderRight: `4px solid ${theme.palette.divider}`,
      padding:"24px",
      paddingRight:"0",
    }}>
      <Stepper
        orientation="vertical"
        activeStep={currentTaskPhase.sequence - 1}
        style={{ 
          padding: "0",
          cursor: "pointer",
        }}
        connector={null}
      >
        {taskPhases.map((taskPhase: TaskPhase, index) => {
          const selected = index === currentTaskPhase.sequence - 1;
          const completed = taskPhase.status === "Complete"
          return taskPhase.taskPhase === "Not Apply" ? null : (
           <> <Step
              completed={completed}
              {...a11yProps(taskPhase?.taskPhase || "")}
              key={`task-phase-${taskPhase.id}`}
              onClick={() => {
                onPhase(taskPhase);
                onClose && onClose();
              }}
            >
              <StepButton 
                style={{ 
                  position:"relative",
                  height: "52px",
                  paddingRight:"0",
                  marginTop:"-17px",
                  marginBottom:"-17px",
                  left: "-20px",
                  paddingLeft:"29px",
                  border:"none",
                  borderRight: selected?`4px solid ${atticusColors.purple2}`:'',
                  backgroundColor: selected?"rgba(98, 112, 201, 0.1)":"transparent",
                }}
                icon={<CustomStepIcon icon={index+1} active={selected} completed={completed} />}>
                <StepLabel style={{paddingTop:"0", paddingBottom:"0"}}>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "flex-start",
                      fontWeight:"600",
                      color: theme.palette.text.primary,
                    }}
                  >
                    {taskPhase.taskPhase}
                    <Typography variant="caption">
                      {taskPhase.status}
                    </Typography>
                  </div>
                </StepLabel>
              </StepButton>
            </Step>
            {index !== taskPhases.length -1 && <StepConnector style={{
              borderLeft: completed? `4px solid ${theme.palette.secondary.main}` : 
                `2px solid ${theme.palette.grey["300"]}`,
              padding: 0,
              marginLeft: "17px",
            }} />}
          </>

          );
        })}
      </Stepper>
    </div>
  );
};