import {
  Button,
  FormControl,
  MenuItem,
  Select,
  Theme,
  Typography,
} from "@mui/material";
import { makeStyles } from "tss-react/mui";
import React, { useState } from "react";
import { ASSIGNABLE_ROLES, ROLE } from "../../../constants";

const useStyles = makeStyles()((theme: Theme) => ({
  root: {},
  button: {
    backgroundColor: theme.palette.primary.light,
    color: theme.palette.primary.contrastText,
  },
  selectWrapper: {
    flexDirection: "row",
    alignItems: "center",
  },
  select: {
    margin: theme.spacing(0, 1),
    minWidth: 120,
  },
  status: {
    padding: theme.spacing(0, 1),
  },
}));

interface Props {
  onAssign: (roleId: number, roleName: string) => Promise<boolean>;
  roleId?: number;
  excludeUber?: boolean;
}

export const AssignAdvisorRoleForm = ({
  onAssign,
  roleId,
  excludeUber = false,
}: Props): JSX.Element => {
  const { classes } = useStyles();
  const [status, setStatus] = useState("");
  const [newRoleId, setNewRoleId] = useState(roleId || 0);
  const handleChange = (event: any) => {
    setNewRoleId(event.target.value as number);
  };

  const getNewRoleName = (): string | undefined => {
    const newRole = ASSIGNABLE_ROLES?.filter(
      (role: ROLE) => role.id === newRoleId
    )[0];
    return newRole?.title;
  };

  return (
    <FormControl className={classes.selectWrapper}>
      <Select
        size="small"
        value={newRoleId}
        onChange={handleChange}
        variant="outlined"
        displayEmpty={true}
        className={classes.select}
      >
        <MenuItem value={0}>
          <em>select role</em>
        </MenuItem>
        {ASSIGNABLE_ROLES.map((role: ROLE) => {
          return excludeUber && role.title === "Uber" ? null : (
            <MenuItem value={role.id} key={"AssignAdvisorRoleForm_" + role.id}>
              {role.title}
            </MenuItem>
          );
        })}
      </Select>
      <Button
        color="primary"
        variant="contained"
        size="small"
        onClick={async () => {
          setStatus("");
          if (await onAssign(newRoleId, getNewRoleName() || "")) {
            setStatus("success!✌️");
            window.setTimeout(() => {
              setStatus("");
            }, 2500);
          } else {
            setStatus("failed😞");
          }
        }}
        disabled={newRoleId === 0}
      >
        Assign
      </Button>
      <Typography variant={"body2"} className={classes.status}>
        {status}
      </Typography>
    </FormControl>
  );
};
