import { Box, Typography } from "@mui/material";
import React from "react";

interface Props {
  icon?: JSX.Element;
  title?: JSX.Element | string;
  body?: JSX.Element | string;
}

export const DialogMessage = ({ icon, title, body }: Props): JSX.Element => {
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
      }}
    >
      {icon}
      {typeof title === "string" ? (
        <Typography variant="h3" sx={{ mb: 2, fontWeight: "bold" }}>
          {title}
        </Typography>
      ) : (
        title
      )}
      {typeof body === "string" ? <Typography>{body}</Typography> : body}
    </Box>
  );
};
