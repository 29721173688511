import { ProbateType, ProbateTypeJSON } from "../models/ProbateType";
import { callDelete, callGet, callPost, logger } from "./index";
import { AtticusForm } from "../models/AtticusForm";

export type ImportProbateMappingResponse = {
  imports?: string[];
  errors?: string[];
};

export class ProbateApi {
  async fetchProbateTypes(): Promise<ProbateType[] | undefined> {
    try {
      const response = await callGet(ProbateType.path);
      return (
        response?.data?.map((json: ProbateTypeJSON) =>
          ProbateType.fromJSON(json)
        ) || []
      );
    } catch (error) {
      logger.error(error as Error);
    }
  }

  async fetchFormsByProbateType(
    probateTypeId: number
  ): Promise<AtticusForm[] | undefined> {
    const fullPath = `${ProbateType.path}/${probateTypeId}/forms`;
    const response = await callGet(fullPath);
    return response?.data?.map((json: any) => AtticusForm.fromJSON(json)) || [];
  }

  async deleteFormMapping(
    probateType: ProbateType,
    form: AtticusForm
  ): Promise<boolean> {
    const response = await callDelete(
      `${ProbateType.path}/${probateType.id}/forms/${form.id}`
    );
    return !!response?.data?.id;
  }

  async importProbateMapping(
    json: string
  ): Promise<ImportProbateMappingResponse> {
    const response = await callPost(
      `${ProbateType.path}/forms`,
      {
        probate_map: json,
      },
      true
    );
    return response?.data;
  }

  async fetchProbateMapping(stateId: number): Promise<any> {
    const response = await callGet(`${ProbateType.path}/export/${stateId}`);
    return response?.data;
  }
}
