import { Dispatch } from "redux";
import { ActionTypes } from "./types";
import { advisorApi } from "../apis";
import { AdvisorGroup } from "../models/AdvisorGroup";

export interface FetchAdvisorGroupsAction {
  type: ActionTypes.FetchAdvisorGroups;
  payload: AdvisorGroup[] | null;
}

export const fetchAdvisorGroups = () => {
  return async (dispatch: Dispatch): Promise<void> => {
    let advisorGroups: AdvisorGroup[] | null = [];
    try {
      advisorGroups = await advisorApi.fetchAdvisorGroups();
    } catch (error) {
      console.error(error);
    }
    dispatch<FetchAdvisorGroupsAction>({
      type: ActionTypes.FetchAdvisorGroups,
      payload: advisorGroups,
    });
  };
};

export interface SaveAdvisorGroupAction {
  type: ActionTypes.SaveAdvisorGroup;
  payload: {
    advisorGroup: AdvisorGroup | null;
  };
}

export const saveAdvisorGroup = (
  advisorGroup: AdvisorGroup
): SaveAdvisorGroupAction => {
  return {
    type: ActionTypes.SaveAdvisorGroup,
    payload: {
      advisorGroup,
    },
  };
};

export interface ClearAdvisorGroupsAction {
  type: ActionTypes.ClearAdvisorGroups;
  payload: null;
}

export const clearAdvisorGroups = (): ClearAdvisorGroupsAction => {
  return {
    type: ActionTypes.ClearAdvisorGroups,
    payload: null,
  };
};
