import React from "react";
import { Box } from "@mui/material";
import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()(() => ({
  root: {
    padding: "0 16px",
  },
  noPad: {
    padding: 0,
  },
}));

interface Props {
  children?: JSX.Element;
  minWidth?: number;
  noPad?: boolean;
}

export const AppTableWrapper = ({
  children,
  minWidth,
  noPad = false,
}: Props): JSX.Element => {
  const { classes } = useStyles();
  return (
    <Box
      minWidth={minWidth || 1050}
      className={(noPad ? classes.noPad : classes.root)}
    >
      {children}
    </Box>
  );
};
