export type AssetDialogSection = {
  title: string;
  hash: string;
};

export const assetDialogNavItems: AssetDialogSection[] = [
  {
    title: "Overview",
    hash: "top",
  },
  {
    title: "Basic Info",
    hash: "basic",
  },
  {
    title: "Value",
    hash: "value",
  },
  {
    title: "Other",
    hash: "other",
  },
  // {
  //   title: "Comments",
  //   hash: "comments",
  // },
  // {
  //   title: "Activity",
  //   hash: "activity",
  // },
];
