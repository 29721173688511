import React from "react";
import { Grid, Divider, Typography, Box } from "@mui/material";
import { makeStyles } from "tss-react/mui";
import { Estate } from "../../../../models/Estate";
import moment from "moment";
import {
  doneSoFarOutcomes,
  estateAssetsOutcomes,
  estateTeamOutcomes,
  helpWithOutcomes,
} from "./OnboardingMappings";
import Skeleton from "@mui/lab/Skeleton";
import {NumericFormat} from "react-number-format";
import { AssetRealEstate } from "../../../../models/AssetDetail";
import { Asset } from "../../../../models/Asset";

const useStyles = makeStyles()(() => ({
  infoContent: {
    fontWeight: "bold",
  },
  divider: {
    marginBottom: "1rem",
  },
  skeletonGroup: {
    marginBottom: "1rem",
  },
  list: {
    padding: "0px 20px",
  },
}));

interface Props {
  estate?: Estate;
  assets?: Asset[] | null; // enables re-draw on asset retrieval
  isUber?: boolean;
}

export const OnboardingCard = ({ estate }: Props): JSX.Element => {
  const { classes } = useStyles();
  const primaryRealEstate = estate?.primaryRealEstate;

  const getPrimaryResidenceLocation = (): string | undefined => {
    if (primaryRealEstate?.detail) {
      return (primaryRealEstate?.detail as AssetRealEstate)?.getFullAddress();
    } else if (estate?.place) {
      return estate?.place.formatted_address;
    } else {
      return estate?.location ? estate?.location : "--";
    }
  };

  const getPrimaryResidenceValue = (): number | null | undefined => {
    if (primaryRealEstate) {
      return primaryRealEstate?.value;
    } else if (estate?.onboardingSummary?.propertyValue) {
      return estate?.onboardingSummary?.propertyValue;
    }
  };

  return (
    <>
      <Divider className={classes.divider} />
      {estate && (
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Typography variant="h6">Estate Info:</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body2">Deceased Name</Typography>
            <Typography variant="body2" className={classes.infoContent}>
              {estate?.name ? estate.name : "--"}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body2">Has passed yet?</Typography>
            <Typography variant="body2" className={classes.infoContent}>
              {estate?.hasPassed ? "Yes" : "No"}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body2">Passed on:</Typography>
            <Typography variant="body2" className={classes.infoContent}>
              {estate?.passedOn
                ? moment(estate?.passedOn).format("MM/DD/YYYY")
                : "N/A"}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body2">Already started Probate?</Typography>
            <Typography variant="body2" className={classes.infoContent}>
              {estate?.onboardingSummary?.alreadyStarted ? "Yes" : "No"}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body2">Was Veteran?</Typography>
            <Typography variant="body2" className={classes.infoContent}>
              {estate?.wasVeteran ? estate.wasVeteran : "--"}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body2">One or more wills?</Typography>
            <Typography variant="body2" className={classes.infoContent}>
              {estate?.onboardingSummary?.hasWill
                ? estate?.onboardingSummary?.hasWill
                : "--"}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body2">Submitted Letters</Typography>
            <Typography variant="body2" className={classes.infoContent}>
              {estate?.onboardingSummary?.letters
                ? estate?.onboardingSummary?.letters
                : "--"}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body2">Simple Probate?</Typography>
            <Typography variant="body2" className={classes.infoContent}>
              {estate?.onboardingSummary?.valueBelowThreshold
                ? estate?.onboardingSummary?.valueBelowThreshold
                : "--"}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body2">Primary Residence</Typography>
            <Typography variant="body2" className={classes.infoContent}>
              {getPrimaryResidenceLocation()}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body2">Own the property?</Typography>
            <Typography variant="body2" className={classes.infoContent}>
              {(
                primaryRealEstate?.detail as AssetRealEstate
              )?.ownership?.includes("Yes") ||
              !!estate?.onboardingSummary?.propertyOwnership
                ? "Yes"
                : "No"}
              <br />
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body2">Property FMV?</Typography>
            <Typography variant="body2" className={classes.infoContent}>
              {getPrimaryResidenceValue() ? (
                <NumericFormat
                  value={getPrimaryResidenceValue()}
                  displayType={"text"}
                  thousandSeparator={true}
                  prefix={"$"}
                />
              ) : (
                "--"
              )}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h6">Executor Info:</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body2">Has Completed Onboarding</Typography>
            <Typography variant="body2" className={classes.infoContent}>
              {estate?.executorSummary?.hasCompletedOnboarding
                ? "true"
                : "false"}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body2">
              Executor's Relationship to the Deceased
            </Typography>
            <Typography variant="body2" className={classes.infoContent}>
              {estate?.executorSummary?.relationshipDisplay}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body2">Executor's Declared Role</Typography>
            <Typography variant="body2" className={classes.infoContent}>
              {estate?.executorSummary?.declaredRole
                ? estate.executorSummary.declaredRole
                : "--"}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body2">
              Executor's Declared Advisory Position
            </Typography>
            <Typography variant="body2" className={classes.infoContent}>
              {estate?.executorSummary?.advisoryPositionDisplay}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body2">Needs help with:</Typography>
            {estate?.onboardingSummary?.need_help_with &&
            estate?.onboardingSummary?.need_help_with.length > 0 ? (
              <ul className={classes.list}>
                {estate?.onboardingSummary?.need_help_with?.map(
                  (task, index) => {
                    return (
                      <li key={index}>
                        <Typography
                          variant="body2"
                          className={classes.infoContent}
                        >
                          {helpWithOutcomes[task]?.text}
                        </Typography>
                      </li>
                    );
                  }
                )}
              </ul>
            ) : (
              "--"
            )}
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body2">Additional help needed on:</Typography>
            <Typography>
              {estate?.onboardingSummary?.need_help_with &&
              estate?.onboardingSummary?.need_help_with.includes(
                "somethingElse"
              ) ? (
                estate?.onboardingSummary?.need_help_with_something_else ? (
                  <Typography variant="body2" className={classes.infoContent}>
                    {estate?.onboardingSummary.need_help_with_something_else}
                  </Typography>
                ) : (
                  "--"
                )
              ) : (
                "N/A"
              )}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body2">Done so far:</Typography>
            {estate?.onboardingSummary?.doneSoFar &&
            estate?.onboardingSummary?.doneSoFar.length > 0 ? (
              <ul className={classes.list}>
                {estate?.onboardingSummary?.doneSoFar?.map((task, index) => {
                  return (
                    <li key={index}>
                      <Typography
                        variant="body2"
                        className={classes.infoContent}
                      >
                        {doneSoFarOutcomes[task]?.text}
                      </Typography>
                    </li>
                  );
                })}
              </ul>
            ) : (
              "--"
            )}
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body2">Estate assets:</Typography>
            {estate?.onboardingSummary?.typeOfAssets &&
            estate?.onboardingSummary?.typeOfAssets.length > 0 ? (
              <ul className={classes.list}>
                {estate?.onboardingSummary?.typeOfAssets?.map(
                  (asset, index) => {
                    return (
                      <li key={index}>
                        <Typography
                          variant="body2"
                          className={classes.infoContent}
                        >
                          {estateAssetsOutcomes[asset]?.text}
                        </Typography>
                      </li>
                    );
                  }
                )}
              </ul>
            ) : (
              "--"
            )}
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body2">Receiving help from:</Typography>
            {estate?.onboardingSummary?.estateTeam &&
            estate?.onboardingSummary?.estateTeam.length > 0 ? (
              <ul className={classes.list}>
                {estate?.onboardingSummary?.estateTeam?.map(
                  (teamMember, index) => {
                    return (
                      <li key={index}>
                        <Typography
                          variant="body2"
                          className={classes.infoContent}
                        >
                          {estateTeamOutcomes[teamMember]?.text}
                        </Typography>
                      </li>
                    );
                  }
                )}
              </ul>
            ) : (
              "--"
            )}
          </Grid>
        </Grid>
      )}
      {!estate && (
        <>
          <Box className={classes.skeletonGroup}>
            <Typography component="div" variant="body2">
              <Skeleton width="80%" />
            </Typography>

            <Typography component="div" variant="body2">
              <Skeleton width="50%" />
            </Typography>
          </Box>
          <Box className={classes.skeletonGroup}>
            <Typography component="div" variant="body2">
              <Skeleton width="80%" />
            </Typography>
            <Typography component="div" variant="body2">
              <Skeleton width="50%" />
            </Typography>
          </Box>
          <Box className={classes.skeletonGroup}>
            <Typography component="div" variant="body2">
              <Skeleton width="80%" />
            </Typography>
            <Typography component="div" variant="body2">
              <Skeleton width="50%" />
            </Typography>
          </Box>
          <Box className={classes.skeletonGroup}>
            <Typography component="div" variant="body2">
              <Skeleton width="80%" />
            </Typography>
            <Typography component="div" variant="body2">
              <Skeleton width="50%" />
            </Typography>
          </Box>
        </>
      )}
    </>
  );
};
