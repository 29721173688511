import { Grid } from "@mui/material";
import React from "react";
import { AssetSummaryDoughnut } from "./AssetSummaryDoughnut";
import { AssetValueRow } from "./AssetValueRow";
// import { displayValue } from "../../../utils/displayValue";

export interface AssetSubTotal {
  key: string;
  value: number;
}

interface Props {
  assetSubTotals?: AssetSubTotal[];
}

export const AssetSummary = ({ assetSubTotals }: Props): JSX.Element => {
  const totalAsset = assetSubTotals?.find(
    (asset: AssetSubTotal) => asset.key === "Total"
  );

  const donutSegments = assetSubTotals?.filter((asset: AssetSubTotal) => {
    return asset.value > 0 && asset.key !== "Total";
  });

  return (
    <Grid container spacing={1}>
      <Grid item xs={6}>
        {/*<div className={classes.totalCircle}>*/}
        {/*  {displayValue(totalAsset?.value || 0, true)}*/}
        {/*  /!*  /!*<Typography variant="h1" className={classes.totalCircleHead}>*!/*!/*/}
        {/*  /!*  /!*  {displayValue(getTotal(), true)}*!/*!/*/}
        {/*  /!*  /!*</Typography>*!/*!/*/}
        {/*  /!*  /!*<Typography variant="body2">of X</Typography>*!/*!/*/}
        {/*</div>*/}
        <AssetSummaryDoughnut
          segments={donutSegments}
          total={totalAsset?.value}
        />
      </Grid>
      <Grid item xs={6}>
        {assetSubTotals &&
          assetSubTotals.map(
            (asset: AssetSubTotal) =>
              asset.key !== "Total" && (
                <AssetValueRow asset={asset} key={asset.key} monetary={true} />
              )
          )}
        {totalAsset && (
          <AssetValueRow asset={totalAsset} bold={true} monetary={true} />
        )}
      </Grid>
    </Grid>
  );
};
