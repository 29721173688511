import { Opportunity } from "./Opportunity";
import { Report } from "./Report";
import { Asset } from "./Asset";
import { TaskPhase } from "./TaskPhase";
import { EstateNote } from "./EstateNote";
import { AssetsMap, EstateSummary } from "./EstateSummary";
import { Place } from "./Place";
import { OnboardingSummary } from "./OnboardingSummary";
import moment from "moment";
import { LARAVEL_APP_SERVICE_PATH } from "../constants";
import { ExecutorSummary } from "./ExecutorSummary";
import parsePhoneNumber, { PhoneNumber } from "libphonenumber-js";

// database field names
export type EstateJSON = {
  id?: number;
  relationship?: string;
  relationship_more?: string;
  advisory_position?: string;
  advisory_position_more?: string;
  user_id?: number;
  lead_id?: number;
  advisor_group_id?: number;
  // charge_id: "fake_charge_id"
  diseased_first_name?: string;
  diseased_last_name?: string;
  deceased_first_name?: string;
  deceased_middle_name?: string;
  deceased_last_name?: string;
  deceased_suffix?: string;
  estate_id?: number; // de-conflict id with estate_id
  executor_relationship_status: "Great";
  has_business_ownerships?: number; // convert to boolean
  has_investment_accounts?: 0; // convert to boolean
  has_life_insurance?: 0; // convert to boolean
  has_out_of_state_assets?: 1; // convert to boolean
  has_passed?: string; // convert to boolean
  has_real_estate: 1; // convert to boolean
  has_trust: 0; // convert to boolean
  has_will: string;
  leave_minors: "YES"; // convert to boolean
  leave_pets: "YES"; // convert to boolean
  passed_on?: string; // convert to date
  state_name?: string;
  state_id?: number;
  was_veteran: string;
  avatar_key?: string;
  executor_first?: string;
  executor_middle?: string;
  executor_last?: string;
  executor_suffix?: string;
  executor_email?: string;
  executor_phone?: string;
  address_one?: string;
  address_two?: string;
  zip_code?: string;
  latest_activity?: string;
  users_profile_id?: number;
  deleted_at?: string;
  created_at?: string;
  is_demo?: boolean;
  assets_value_total?: number;
  assets_value?: string;
  assets_inventoried?: string;
  num_tasks_assigned?: number;
  num_tasks_completed?: number;
  percent_complete?: number;
  letters?: string;
  value_below_threshold?: string;
  declared_role?: string;
  name_of_corporate_executor?: string;
  tasks_completed?: string;
  estate_team?: string;
  type_of_assets?: string;
  already_started?: string;
  need_help_with?: string;
  need_help_with_something_else?: string;
  property_ownership?: string;
  property_value?: number;
  gplace_formatted_address?: string;
  gplace_number?: number;
  gplace_city?: string;
  gplace_street?: string;
  gplace_county?: string;
  gplace_state?: string;
  gplace_country?: string;
  gplace_postal_code?: string;
  gplace_place_type?: string;
  probate_type_id?: number;
  probate_type_name?: string;
  internal_name?: string;
  price_value?: number;
  has_write_access?: boolean;
  has_completed_on_boarding?: number;
};

export class Estate {
  static path = "/estates";
  static path2 = "/estates2";
  static avatarPath = "/avatar";
  path = "/estates";
  address = {
    city: "",
    state: "",
    country: "",
  };

  constructor(
    public id?: number,
    public isMock?: boolean,
    public userId?: number,
    public leadId?: number,
    public userProfileId?: number,
    public deceasedFirstName?: string,
    public deceasedMiddleName?: string,
    public deceasedLastName?: string,
    public deceasedNameSuffix?: string,
    public relation?: string, // = "child",
    public hasPassed?: boolean,
    public passedOn?: string,
    public wasVeteran?: string,
    public avatarKey?: string,
    public executorFirstName?: string,
    public executorMiddleName?: string,
    public executorLastName?: string,
    public executorNameSuffix?: string,
    public executorEmail?: string,
    public executorPhone?: string,
    public addressOne?: string,
    public addressTwo?: string,
    public state?: string,
    public stateId?: number,
    public zipCode?: string,
    public country?: string,
    public advisorGroupId?: number,
    // public advisorGroupName?: string,
    // public numRelationships?: number,
    // public fairMarketValue?: number,
    // NOTE: opportunities are assets of a certain type?
    public assets?: Asset[] | null,
    public opportunities?: Opportunity[],
    public notes?: EstateNote[] | null,
    public reports?: Report[] | null,
    public taskPhases?: TaskPhase[] | null,
    public estateSummary?: EstateSummary | null,
    public executorSummary?: ExecutorSummary | null,
    public onboardingSummary?: OnboardingSummary | null,
    public place?: Place | null,
    public probateTypeId?: number,
    public probateTypeName?: string,
    public priceTierInternalName?: string,
    public priceTierValue?: number,
    public updatedAt?: string,
    public deletedAt?: string,
    public createdAt?: string,
    public isDemo?: boolean,
    public hasWriteAccess?: boolean
  ) {}

  static fromJSON = (json: EstateJSON): Estate => {
    return new Estate(
      json.id,
      false,
      json.user_id,
      json.lead_id,
      json.users_profile_id,
      json.deceased_first_name && json.deceased_first_name != ""
        ? json.deceased_first_name
        : json.diseased_first_name,
      json.deceased_middle_name,
      json.deceased_last_name && json.deceased_last_name != ""
        ? json.deceased_last_name
        : json.diseased_last_name,
      json.deceased_suffix,
      json.relationship,
      json.has_passed?.toLowerCase() == "yes" || json.has_passed == "1"
        ? true
        : json.has_passed?.toLowerCase() == "no" || json.has_passed == "0"
        ? false
        : !!json.has_passed,
      json.passed_on,
      json.was_veteran,
      json.avatar_key,
      json.executor_first,
      json.executor_middle,
      json.executor_last,
      json.executor_suffix,
      json.executor_email,
      json.executor_phone,
      json.address_one,
      json.address_two,
      json.state_name,
      json.state_id,
      json.zip_code,
      undefined,
      json.advisor_group_id,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      EstateSummary.fromJSON(json.id, {
        assets_value_total: json.assets_value_total,
        assets_value: json.assets_value
          ? (JSON.parse(json.assets_value) as AssetsMap)
          : undefined,
        assets_inventoried: json.assets_inventoried
          ? (JSON.parse(json.assets_inventoried) as AssetsMap)
          : undefined,
        num_tasks_assigned: json.num_tasks_assigned,
        num_tasks_completed: json.num_tasks_completed,
        percent_complete: json.percent_complete,
      }),
      ExecutorSummary.fromJSON(json.id, {
        declared_role: json.declared_role,
        name_of_corporate_executor: json.name_of_corporate_executor,
        has_completed_onboarding: json.has_completed_on_boarding,
        relationship: json.relationship,
        relationship_more: json.relationship_more,
        advisory_position: json.advisory_position,
        advisory_position_more: json.advisory_position_more,
      }),
      OnboardingSummary.fromJSON(json.id, {
        letters: json.letters,
        value_below_threshold: json.value_below_threshold,
        done_so_far: json.tasks_completed
          ? JSON.parse(json.tasks_completed)
          : undefined,
        estate_team: json.estate_team
          ? JSON.parse(json.estate_team)
          : undefined,
        type_of_assets: json.type_of_assets
          ? JSON.parse(json.type_of_assets)
          : undefined,
        already_started: json.already_started,
        need_help_with: json.need_help_with
          ? JSON.parse(json.need_help_with)
          : undefined,
        need_help_with_something_else: json.need_help_with_something_else,
        property_ownership: json.property_ownership,
        property_value: json.property_value,
        has_will: json.has_will,
      }),
      json.gplace_formatted_address
        ? Place.fromJSON(json.id, {
            formatted_address: json.gplace_formatted_address,
            number: json.gplace_number,
            city: json.gplace_city,
            street: json.gplace_street,
            county: json.gplace_county,
            state: json.gplace_state,
            country: json.gplace_country,
            postal_code: json.gplace_postal_code,
            place_type: json.gplace_place_type,
          })
        : undefined,
      json.probate_type_id,
      json.probate_type_name,
      json.internal_name,
      json.price_value,
      json.latest_activity,
      json.deleted_at,
      json.created_at,
      json.is_demo,
      json.has_write_access
    );
  };

  hasSummaryData(): boolean {
    return !!this.estateSummary;
  }

  get location(): string {
    const location:string[] = [];
    // if (this.addressOne) location.push(this.addressOne);
    // if (this.addressTwo) location.push(this.addressTwo);
    if (this.zipCode) location.push(this.zipCode);
    if (this.state) location.push(this.state);
    if (this.country) location.push(this.country);
    return location.join(", ");
  }

  get taskPercent(): string {
    if (
      this.estateSummary?.tasksAssigned !== undefined &&
      this.estateSummary?.tasksCompleted !== undefined &&
      this.estateSummary?.tasksAssigned !== null &&
      this.estateSummary?.tasksCompleted !== null
    ) {
      if (
        this.estateSummary.tasksAssigned === 0 ||
        this.estateSummary.tasksCompleted === 0
      ) {
        return 0 + "%";
      }
      return (
        Math.floor(
          this.estateSummary.tasksAssigned / this.estateSummary.tasksCompleted
        ) + "%"
      );
    }
    return "...";
  }

  estateCreationDate(): string {
    return moment.utc(this.createdAt).local().format("MM/DD/YYYY");
  }

  estateCreationTime(): string {
    return moment.utc(this.createdAt).local().format("hh:mm a");
  }

  get creationDate(): string {
    return this.updatedAt ? this.estateCreationDate() : "";
  }

  get creationDateWithTime(): string {
    return this.updatedAt
      ? this.estateCreationDate() + " at " + this.estateCreationTime()
      : "";
  }

  formatDate(dateTime: string): string {
    return moment.utc(dateTime).local().format("MM/DD/YYYY");
  }

  timeOfDate(dateTime: string): string {
    return moment.utc(dateTime).local().format("hh:mm a");
  }

  get lastActivity(): string {
    return this.updatedAt ? this.formatDate(this.updatedAt) : "";
  }

  get lastActivityWithTime(): string {
    return this.updatedAt
      ? this.formatDate(this.updatedAt) +
          " at " +
          this.timeOfDate(this.updatedAt)
      : "";
  }

  get createdDate(): string {
    return this.createdAt ? this.formatDate(this.createdAt) : "";
  }

  get avatarUrl(): string | undefined {
    if (this.avatarKey) {
      return (
        LARAVEL_APP_SERVICE_PATH + Estate.avatarPath + "/" + this.avatarKey
      );
    }
  }

  get primaryRealEstate(): Asset | undefined {
    let asset: Asset | undefined  = undefined;
    if (this.assets) {
      const reAssets = this.assets?.filter(
        (asset: Asset) => asset.category?.category === "Real Estate"
      );
      const primaryHomeIndex = reAssets?.findIndex(
        (asset: Asset) => asset.subcategory?.subcategory === "Primary Home"
      );
      if (primaryHomeIndex > 0) {
        asset = reAssets[primaryHomeIndex];
      } else {
        // return the first real estate asset
        asset = reAssets[0];
      }
    }
    return asset;
  }

  get executorPhoneFormatObject(): PhoneNumber | undefined {
    return this.executorPhone
      ? parsePhoneNumber(this.executorPhone)
      : undefined;
  }

  get name(): string | undefined {
    let retName = this.deceasedFirstName;
    if (this.deceasedMiddleName) {
      retName += ` ${this.deceasedMiddleName}`;
    }
    if (this.deceasedLastName) {
      retName += ` ${this.deceasedLastName}`;
    }
    if (this.deceasedNameSuffix) {
      retName += `, ${this.deceasedNameSuffix}`;
    }
    if (this.isMock) {
      retName += " (Mock)";
    }
    return retName;
  }

  get executorFullName(): string | undefined {
    let retName = this.executorFirstName;
    if (this.executorMiddleName) {
      retName += ` ${this.executorMiddleName}`;
    }
    if (this.executorLastName) {
      retName += ` ${this.executorLastName}`;
    }
    if (this.executorNameSuffix) {
      retName += `, ${this.executorNameSuffix}`;
    }
    return retName;
  }
}
