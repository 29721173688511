import { ActionTypes } from "../actions/types";
import { Estate } from "../models/Estate";
import { AnyAction } from "redux";

export const estatesMocksReducer = (
  estatesMocks: Estate[] | null = null,
  action: AnyAction
): Estate[] | null => {
  switch (action.type) {
    case ActionTypes.FetchMockEstates:
      if (estatesMocks) {
        return estatesMocks;
      } else {
        const updatedEstates = [...action.payload];
        // add "mock" to the name so its easy to find in the UI
        updatedEstates.forEach((thisMockEstate: Estate) => {
          thisMockEstate.isMock = true;
        });
        return updatedEstates;
      }
    default:
      return estatesMocks;
  }
};
