import React, { useEffect, useState } from "react";
import { CircularLoaderWithChild } from "./CircularLoaderWithChild";

interface Props {
  children?: JSX.Element | JSX.Element[];
  size?: number;
  duration?: number;
  start?: number;
  incrementor?: number;
  complete?: number;
  onComplete?: () => void;
}

export const TimedCircularLoader = ({
  children,
  size = 40,
  duration = 10000,
  start = 0,
  incrementor = 10,
  complete = 100,
  onComplete,
}: Props): JSX.Element => {
  // animation will run from {start} to {complete} over the given {duration}
  //  each re-render will change by {incrementor}
  //  number of re-renders in the duration is {complete}/{incrementor}
  //  delay between re-renders will be determined by number of {duration}/number of re-renders

  const [percent, setPercent] = useState<number>(start);
  let timer: number | null = null;
  let numRenders: number | null = null;
  let renderDelay: number | null = null;

  useEffect(() => {
    numRenders = Math.floor(complete / incrementor);
    renderDelay = Math.floor(duration / numRenders);
    timedRender(percent);
    return reset;
  }, []);

  const reset = () => {
    timer && clearInterval(timer);
    timer = null;
  };

  const timedRender = (percent: number) => {
    timer = window.setTimeout(() => {
      const nextPercent = percent + incrementor;
      setPercent(nextPercent);
      if (nextPercent === complete) {
        reset();
        onComplete?.();
      } else {
        timedRender(nextPercent);
      }
    }, renderDelay || 1000);
  };

  return (
    <CircularLoaderWithChild percent={percent} size={size}>
      {children}
    </CircularLoaderWithChild>
  );
};
