import React from "react";
import { Box, Typography } from "@mui/material";
import { Asset } from "../../../../models/Asset";

import can1 from "../../../../assets/large-trash-can@1x.png";
import can2 from "../../../../assets/large-trash-can@2x.png";
import can3 from "../../../../assets/large-trash-can@3x.png";
import { AssetImage } from "../../../../models/AssetImage";

interface Props {
  asset?: Asset;
  assetImage?: AssetImage;
}

export const DeleteContent = ({ asset, assetImage }: Props): JSX.Element => {
  return (
    <Box>
      <img src={can1} srcSet={`${can2} 2x, ${can3} 3x`} alt="Trash can" />
      <Typography variant="h3" sx={{ fontWeight: 800 }}>
        <strong>
          Are you sure you want to delete
          <br />
          {assetImage ? "this photo" : asset?.asset}?
        </strong>
      </Typography>
      <Typography variant="body2" sx={{ mt: 2 }}>
        You won't be able to undo this action.
      </Typography>
    </Box>
  );
};
