import React, { ReactElement } from "react";
import { Card, CardContent } from "@mui/material";

export const InCard = ({
  children,
  card = true,
}: {
  children: ReactElement;
  card?: boolean;
}): JSX.Element => {
  return card ? (
    <Card>
      <CardContent>{children}</CardContent>
    </Card>
  ) : (
    children
  );
};
