import { Button, Typography } from "@mui/material";
import React from "react";

export type HelpContent = {
  label: string;
  href?: string;
  onClick?: () => void;
  isButton?: boolean;
};

interface Props {
  helpContent?: HelpContent;
}

export const HelpComponent = ({ helpContent }: Props): JSX.Element => {
  if (!helpContent) return <></>;
  const { label, href, onClick, isButton } = helpContent;
  let help:JSX.Element|null = null;
  if (href) {
    help = isButton ? (
      <Button
        variant="contained"
        size="small"
        onClick={() => window.open(href)}
      >
        {label || href}
      </Button>
    ) : (
      <a href={href} target="_blank">
        {label || href}
      </a>
    );
  } else if (onClick) {
    help = isButton ? (
      <Button variant="contained" size="small" onClick={onClick}>
        {label}
      </Button>
    ) : (
      <a href="javascript:;" onClick={onClick}>
        {label}
      </a>
    );
  } else {
    help = <Typography variant="body2">{label}</Typography>;
  }
  return help ? <Typography variant="body2">{help}</Typography> : <></>;
};
