import React from "react";
import { Navigate } from "react-router-dom";

import { DashboardLayout } from "./layouts/AppLayout";
import { DashboardView } from "./views/dashboard/DashboardView";
import { ProfileView } from "./views/profile/ProfileView";
import { EstateListView } from "./views/estate/EstateListView";
import { EstateView } from "./views/estate/EstateView";
import { OverviewView } from "./views/estate/EstateView/Overview";
import { OpportunitiesView } from "./views/estate/EstateView/Opportunities";
import { FormsLibraryView } from "./views/forms/FormsLibraryView";

import { FinancialConvoView } from "./views/estate/EstateView/FinancialConvo";
import { FormsFilesView } from "./views/estate/EstateView/FormsAndFiles";
import { ProgressView } from "./views/estate/EstateView/Progress";
import { AssetsView } from "./views/estate/EstateView/Assets";
import { NotesView } from "./views/estate/EstateView/Notes";
import { ReportsView } from "./views/estate/EstateView/Reports";
import { AllOpportunitiesView } from "./views/opportunities/AllOpportunitiesView";
import { MetricsView } from "./views/metrics/MetricsView";
import { SupportView } from "./views/support/SupportView";
import { InviteClientView } from "./views/invite/InviteClientView";
import { InviteAdvisorView } from "./views/invite/InviteAdvisorView";
import { AdvisorListView } from "./views/advisors/AdvisorListView";
import { AdminView } from "./views/admin";
import { AllGroupsView } from "./views/admin/AllGroupsView";
import { AtticusFormsView } from "./views/admin/AtticusForms/AtticusFormsView";
import { FormInterfaceView } from "./views/admin/FormInterfaceView";
import { ProbateView } from "./views/admin/ProbateView/ProbateView";
import { MainLayout } from "./layouts/NoAuthLayout";
import { NotFoundView } from "./views/errors/NotFoundView";
import { AssignmentListView } from "./views/assignments/AssignmentListView";
// import { OpportunitiesAdminView } from "./views/opportunities/AdminView";
import { Invite } from "./views/auth/Invite";
import { AllAdvisorsView } from "./views/assignments/AllAdvisorsView";
import { TestView } from "./views/test/TestView";
import { MaintenanceView } from "./views/maintenance/MaintenanceView";
import { DemoEstatesView } from "./views/admin/DemoEstatesView";
import { SCREEN_PATHS } from "./constants";
import { HostedAuth } from "./views/auth/HostedAuth";
import { SignOut } from "./views/auth/SignOut";
import { OverviewRedirect } from "./views/estate/EstateView/OverviewRedirect";
import { ExploratoryView } from "./views/admin/ExploratoryView";
import EstateFormsView from "./views/estate/EstateView/formsAndFiles/EstateFormsView";
import FormsLibraryEstateView from "./views/estate/EstateView/formsAndFiles/FormsLibraryEstateView";

// Having to check access before rendering the component in the component AS WELL AS having to
// define access control within `restrictScreenAccess`seems like an inefficient routing pattern.
// It also isn't really good to have to manually ensure route paths in both SCREEN_PATHS and
// the `routes.tsx` file are in sync.
// Perhaps we should write High Order Routing/Auth components to wrap our View components in,
// sticking all of it in the router's component slot for the path, to standardize and clean this up.
// A good resource: https://adarshaacharya.com.np/blog/role-based-auth-with-react-router-v6

// NOTE: SCREEN_PATHS in the constants.ts files are used for redirects throughout the app
const routes = [
  {
    path: "app", // do I need to get rid of this?
    element: <DashboardLayout />,
    children: [
      { path: "dashboard", element: <DashboardView /> },
      { path: "estates", element: <EstateListView /> },
      {
        path: "estates/:estateId",
        element: <EstateView />,
        children: [
          { path: "", element: <OverviewView /> },
          {
            // /app/estates/{estate_id}/overview has been replaced with /app/estates/{estate_id}
            //  this captures any potential straggler links
            path: "overview",
            element: <OverviewRedirect />,
          },
          {
            path: "opportunities",
            element: <OpportunitiesView />,
          },
          {
            path: "opportunities/financial-convos/:financialConvoId",
            element: <FinancialConvoView />,
          },
          { path: "progress", element: <ProgressView /> },
          {
            path: "forms-and-files",
            element: <FormsFilesView />,
          },
          {
            path: "forms-and-files/estate-forms",
            element: <EstateFormsView />,
          },
          {
            path: "forms-and-files/forms-library",
            element: <FormsLibraryEstateView />,
          },
          {
            path: "assets",
            element: <AssetsView />,
            children: [
              { path: ":assetId", element: <AssetsView /> },
              // used when creating new assets - assetId will = new
              { path: ":assetId/:categoryId", element: <AssetsView /> },
              {
                path: ":assetId/:categoryId/:subCategoryId",
                element: <AssetsView />,
              },
            ],
          },
          { path: "notes", element: <NotesView /> },
          { path: "reports", element: <ReportsView /> },
        ],
      },
      { path: "opportunities", element: <AllOpportunitiesView /> },
      { path: "forms-library", element: <FormsLibraryView /> },
      { path: "metrics", element: <MetricsView /> },
      { path: "support", element: <SupportView /> },
      { path: "invite-client", element: <InviteClientView /> },
      { path: "invite-advisor", element: <InviteAdvisorView /> },
      { path: "advisors", element: <AdvisorListView /> },
      {
        path: "assignments",
        element: <AssignmentListView />,
        children: [{ path: "advisors", element: <AllAdvisorsView /> }],
      },
      {
        path: "admin",
        element: <AdminView />,
        children: [
          { path: "groups", element: <AllGroupsView /> },
          {
            path: "forms",
            element: <AtticusFormsView />,
          },
          {
            path: "forms/interface/:formId",
            element: <FormInterfaceView />,
          },
          { path: "demo-estates", element: <DemoEstatesView /> },
          { path: "probate", element: <ProbateView /> },
          {
            path: "exploratory",
            element: <ExploratoryView />,
          },
        ],
      },

      // { path: "opp-admin", element: <OpportunitiesAdminView /> },
      { path: "profile", element: <ProfileView /> },
      { path: "test", element: <TestView /> },
      {
        path: "*",
        element: <Navigate to={SCREEN_PATHS.not_found} replace={true} />,
      },
    ],
  },
  {
    path: "/",
    element: <MainLayout />,
    children: [
      { path: "login", element: <HostedAuth /> },
      { path: "sign-out", element: <SignOut /> },
      { path: "invite/:inviteId", element: <Invite /> },
      { path: "maintenance", element: <MaintenanceView /> },
      { path: "404", element: <NotFoundView /> },
      { path: "/", element: <Navigate to={SCREEN_PATHS.login} /> },
      { path: "*", element: <Navigate to={SCREEN_PATHS.not_found} /> },
    ],
  },
];

export default routes;
