import { GlobalStyles } from "@mui/material";
import { Theme } from "@mui/material"

export default () => <GlobalStyles styles={(theme:Theme) => ({ 
    ".query-builder-container": {
      padding: "0 0 0 0 !important",
    },
    ".qb-lite": {
      margin: "0 0 0 0 !important",
    },
    ".query-builder": {
      margin: "0 0 0 0 !important",
    },
    ".group": { 
        backgroundColor: theme.palette.background.paper + ' !important',
        border: "1px solid " + theme.palette.primary.main + ' !important',
    },
    ".rule": {
        backgroundColor: theme.palette.background.paper + ' !important',
    },
    ".rule-with-error .rule": {
        border: "1px solid " + theme.palette.error.main + ' !important',
    },
  ".rule_group": {
    background: theme.palette.primary.main + ' !important',
    border: "1px solid " + theme.palette.primary.main + ' !important',
  },
  ".qb-placeholder": {
    border: "1px dashed " + theme.palette.primary.main + ' !important',
  },
  ".group--header:not(.no--children):not(.hide--conjs)::before": {
    borderColor: theme.palette.primary.main + ' !important',
  },
  ".group--header:not(.no--children):not(.hide--conjs).hide--line::before": {
    borderColor: theme.palette.primary.main + ' !important',
  },
  ".group--children > .group-or-rule-container > .group-or-rule::before, .group--children > .group-or-rule-container > .group-or-rule::after": {
    borderColor: theme.palette.primary.main + ' !important',
  },
  ".group--children.hide--line > .group-or-rule-container > .group-or-rule::before, .group--children.hide--line > .group-or-rule-container > .group-or-rule::after": {
    borderColor: theme.palette.primary.main + ' !important',
  },
  ".customSelectOption": {
    color: theme.palette.primary.main + ' !important',
  }
})}
/>