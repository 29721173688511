export const camelToSpaced = (
  theString?: string,
  dontEditFirst = true
): string | undefined => {
  return (
    theString &&
    theString
      ?.split("")
      .map((char: string, index: number) => {
        if (char === char.toUpperCase()) {
          if (index === 0) {
            // first letter
            if (dontEditFirst) {
              return char;
            } else {
              return char.toLowerCase();
            }
          }
          return ` ${char.toLowerCase()}`;
        }
        return char;
      })
      .join("")
  );
};
