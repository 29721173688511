import React from "react";
import { Container, Theme } from "@mui/material";
import { makeStyles } from "tss-react/mui";
import { connect } from "react-redux";
import Page from "../../../components/Page";
import ContentHeader from "../../shared/ContentHeader";
import { StoreState } from "../../../reducers";
import { ATTICUS_PAGE_PREFIX } from "../../../constants";
import { User } from "../../../models/User";
import { Outlet, useNavigate } from "react-router-dom";
import { SubNavBar } from "../../shared/SubNavBar";
import { assignmentNavItems } from "../assignmentNavItems";

const useStyles = makeStyles()((theme: Theme) => ({
  root: {
    backgroundColor: theme.palette.background.default,
    minHeight: "100%",
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
}));

interface Props {
  user: User;
}

const _AssignmentListView = ({ user }: Props) => {
  const { classes } = useStyles();
  const navigate = useNavigate();

  const hasAccess = user.isUberAdmin;

  if (!hasAccess) {
    window.setTimeout(() => {
      navigate(`/app/dashboard`);
    }, 0);
  }

  return (
    <Page
      className={classes.root}
      title={`${ATTICUS_PAGE_PREFIX} - Assignments`}
      data-automation-id="page-content-assignments"
    >
      {hasAccess ? (
        <Container maxWidth={false}>
          <ContentHeader heading="Assignments" showSearch={false} />
          <SubNavBar navItems={assignmentNavItems} />
          <Outlet />
        </Container>
      ) : (
        <div />
      )}
    </Page>
  );
};

const mapStateToProps = ({ user }: StoreState): { user: User } => {
  return { user };
};

export const AssignmentListView = connect(
  mapStateToProps,
  {}
)(_AssignmentListView);
