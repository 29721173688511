import React from "react";
import { Box, useTheme } from "@mui/material";
import { makeStyles } from "tss-react/mui";
import { ResourceCard } from "./ResourceCard";
import { Resource, resourcesContent } from "./resourcesContent";
import { shuffle } from "../../utils/shuffle";
import { DetailCard } from "./DetailCard";

const useStyles = makeStyles()(() => ({
  noHandleScroller: {
    msOverflowStyle: "none" /* Internet Explorer 10+ */,
    scrollbarWidth: "none" /* Firefox */,
    overflowX: "scroll",
    "&::-webkit-scrollbar": {
      display: "none" /* Safari and Chrome */,
    },
  },
  handledScroller: {
    overflowX: "scroll",
    paddingBottom: 16,
  },
}));

const CARD_WIDTH = 340;

export const Resources = (): JSX.Element => {
  const { classes } = useStyles();
  const theme = useTheme();

  return (
    <DetailCard
      title="Resources for Your Clients"
      subTitle="Our best stories for your best clients"
      backgroundColor={
        theme.palette.grey[theme.palette.mode === "dark" ? "800" : "300"]
      }
    >
      <Box className={classes.handledScroller}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            minWidth: (resourcesContent.length + 1) * (CARD_WIDTH - 16),
          }}
        >
          {shuffle(resourcesContent).map((resource: Resource) => (
            <ResourceCard
              key={resource.title}
              title={resource.title}
              brief={resource.brief}
              url={resource.url}
              chips={resource.chips}
              maxWidth={CARD_WIDTH}
            />
          ))}
        </Box>
      </Box>
    </DetailCard>
  );
};
