export type FormAuthorityJson = {
  type: string;
  name: string;
  description: string;
  logo_file_key?: string;
  id: number;
};

export class FormAuthority {
  constructor(
    public id: number,
    public type: string,
    public name: string,
    public description: string,
    public logoFileKey?: string
  ) {}

  static fromJSON(json: FormAuthorityJson): FormAuthority {
    return new FormAuthority(
      json.id,
      json.type,
      json.name,
      json.description,
      json.logo_file_key
    );
  }

  toJSON(): FormAuthorityJson {
    return {
      id: this.id,
      type: this.type,
      name: this.name,
      description: this.description,
      logo_file_key: this.logoFileKey,
    };
  }
}
