import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  useMediaQuery,
  useTheme,
  // CircularProgress,
  Typography,
} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

const dialogContentStyle = {
  height: "75vh",
  width: "100%",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  padding: 0,
  marginBottom: 0,
} as const;

export enum PdfFileChoice {
  PROCESSED = "processed",
  WATERMARKED = "watermarked",
  THUMBNAIL = "thumbnail",
}

interface Props {
  onClose: () => void;
  src?: string;
  title?: string;
  contentType?: string;
}

export default function IFrameDialog({
  src,
  title,
  onClose,
  contentType = "text/html",
}: Props): JSX.Element {
  const theme = useTheme();
  const isMobileSize = useMediaQuery(theme.breakpoints.down("sm"));

  // useEffect(() => {}, []);

  const open = !!src;
  return (
    <Dialog
      fullScreen={isMobileSize}
      fullWidth
      maxWidth="xl"
      open={open}
      onClose={onClose}
      sx={{
        "&>div>div": isMobileSize
          ? {
              p: 0,
              pt: 4,
            }
          : {
              p: 2,
            },
      }}
    >
      {src ? (
        <>
          <DialogTitle
            id="responsive-dialog-title"
            className="centeredText"
            component="div"
          >
            {title && <Typography variant="h3">{title}</Typography>}
            {onClose ? (
              <IconButton
                aria-label="close"
                sx={{
                  position: "absolute",
                  right: 0,
                  top: 0,
                  padding: "1rem",
                  color: "grey.500",
                }}
                onClick={onClose}
              >
                <CloseIcon />
              </IconButton>
            ) : null}
          </DialogTitle>
          <DialogContent sx={dialogContentStyle}>
            {/* show loading spinner? */}
            <object
              style={{
                width: "100%",
                height: "100%",
              }}
              data={src}
              type={contentType}
            >
              <iframe src={src} />
            </object>
          </DialogContent>
        </>
      ) : (
        <DialogContent sx={dialogContentStyle}>Form Required.</DialogContent>
      )}
    </Dialog>
  );
}
