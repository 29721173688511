import { AppTableWrapper } from "../../../shared/AppTableWrapper";
import {
  Box,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import { makeStyles } from "tss-react/mui";
import moment from "moment/moment";
import EditIcon from "@mui/icons-material/Edit";
import PerfectScrollbar from "react-perfect-scrollbar";
import React, { useState } from "react";
import { NoteForm } from "./NoteForm";
import { EstateNote } from "../../../../models/EstateNote";
import { StoreState } from "../../../../reducers";
import { User } from "../../../../models/User";
import { connect } from "react-redux";
import { fetchEstateNotes } from "../../../../actions/estateActions";
import { AddRecordButton } from "../../../shared/AddRecordButton";

const useStyles = makeStyles()(() => ({
  root: {
    position: "relative",
  },
  topAlignCellContent: {
    // "&>td": {
    //   verticalAlign: "top",
    // },
  },
}));

interface Props {
  estateNotes: EstateNote[];
  estateId: number;
  user: User;
}

const _NotesTable = ({ estateNotes, estateId, user }: Props): JSX.Element => {
  const { classes } = useStyles();
  const [showNewForm, setShowNewForm] = useState(estateNotes.length === 0);
  const [editNoteId, setEditNoteId] = useState<number | undefined>();

  const sortedNotes = estateNotes.sort((a: EstateNote, b: EstateNote) =>
    moment(a.updatedAt).isAfter(b.updatedAt) ? -1 : 1
  );
  return (
    <div className={classes.root}>
      <Box
        style={{
          position: "absolute",
          right: 0,
          zIndex: 10,
        }}
      >
        <AddRecordButton
          title="Add Note"
          onClick={() => {
            setShowNewForm(true);
            setEditNoteId(undefined);
          }}
        />
      </Box>
      <PerfectScrollbar>
        <AppTableWrapper minWidth={600} noPad={true}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell width="5%">Id</TableCell>
                <TableCell width="10%">Date</TableCell>
                <TableCell>Note</TableCell>
                <TableCell width="20%">Advisor</TableCell>
                <TableCell width="5%" />
              </TableRow>
            </TableHead>
            <TableBody>
              {showNewForm && (
                <NoteForm
                  estateNote={new EstateNote()}
                  estateId={estateId}
                  onComplete={() => setShowNewForm(false)}
                  colSpan={5}
                />
              )}
              {sortedNotes.map((estateNote: EstateNote) => {
                const editable = estateNote.userId === user.id;
                return editNoteId === estateNote.id ? (
                  <NoteForm
                    estateNote={estateNote}
                    estateId={estateId}
                    onComplete={() => setEditNoteId(undefined)}
                    key={estateNote.id + "-form"}
                    colSpan={5}
                  />
                ) : (
                  <TableRow
                    hover
                    key={estateNote.id}
                    className={classes.topAlignCellContent}
                  >
                    <TableCell>{estateNote.id}</TableCell>
                    <TableCell>
                      {moment(estateNote.updatedAt).format("MM/DD/YYYY")}
                    </TableCell>
                    <TableCell>{estateNote.text}</TableCell>
                    <TableCell>{estateNote.creatorName}</TableCell>
                    <TableCell>
                      {editable && (
                        <IconButton
                          aria-label="edit"
                          color="primary"
                          onClick={() => {
                            setEditNoteId(estateNote.id || undefined);
                            setShowNewForm(false);
                          }}
                        >
                          <EditIcon fontSize="small" />
                        </IconButton>
                      )}
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </AppTableWrapper>
      </PerfectScrollbar>
    </div>
  );
};

const mapStateToProps = ({ user }: StoreState): { user: User } => {
  return { user };
};

export const NotesTable = connect(mapStateToProps, {
  fetchEstateNotes,
})(_NotesTable);
