import React from "react";
import PerfectScrollbar from "react-perfect-scrollbar";
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  Button,
  Switch,
  Theme,
  Pagination,
} from "@mui/material";
import { makeStyles } from "tss-react/mui";
import { Link, useNavigate } from "react-router-dom";
import { Estate } from "../../../models/Estate";
import { AppTableWrapper } from "../../shared/AppTableWrapper";
import { TableBodyLoader } from "../../shared/TableBodyLoader";
import { ZeroStateBody } from "../../shared/ZeroStateBody";
import { abbreviateNumber } from "js-abbreviation-number";
import { AsyncAvatar } from "../../shared/AsyncAvatar";
import {
  ESTATE_SORT_BY,
  ESTATE_SORT_DIR,
  SCREEN_PATHS,
} from "../../../constants";
import atticusColors from "../../../theme/colors";

const useStyles = makeStyles()((theme: Theme) => ({
  root: {},
  avatar: {
    marginRight: theme.spacing(2),
  },
  zeroWrapper: {
    textAlign: "center",
  },
  zeroImage: {
    marginTop: theme.spacing(4),
    width: "80%",
    maxWidth: 1000,
  },
  zeroContent: {
    maxWidth: 400,
    margin: `${theme.spacing(2)}px auto`,
  },
  selected: {
    backgroundColor: theme.palette.background.default,
  },
  paginationWrapper: {
    display: "flex",
    justifyContent: "space-between",
  },
  narrowButton: {
    minWidth: "auto",
    color: atticusColors.grey4,
    padding: 0,
    "&:hover": {
      backgroundColor: "inherit",
    },
  },
}));

interface Props {
  estates: Estate[];
  total?: number;
  searchResult?: boolean;
  page?: number;
  perPage?: number;
  onPageChange?: (newPage: number) => void;
  onPerPageChange?: (newPerPage: number) => void;
  includeDeleted?: boolean;
  onIncludeDeletedChange?: (includeDeleted: boolean) => void;
  onSort?: (field: string, dir: string) => void;
  sort?: string;
  dir?: string;
  isUber?: boolean;
}

const zeroImagePrefix = "/static/images/zero/estates";

const EstateTable = ({
  estates,
  total,
  searchResult = false,
  page = 1,
  perPage = 10,
  onPageChange,
  onPerPageChange,
  includeDeleted,
  onIncludeDeletedChange,
  onSort,
  sort,
  dir = ESTATE_SORT_DIR.dsc,
  isUber = false,
}: Props): JSX.Element => {
  const { classes } = useStyles();
  const navigate = useNavigate();

  const handleSwitchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { checked: newCheckedState } = event.target;
    onIncludeDeletedChange && onIncludeDeletedChange(newCheckedState);
  };

  const goToEstate = (id?: number): void => {
    navigate(`${SCREEN_PATHS.estates}/${id}`, {
      state: { from: SCREEN_PATHS.estates },
    });
  };

  const Invite = (): JSX.Element => (
    <>
      <Link to="/app/invite-client">Invite a client</Link> to get started.
    </>
  );

  const handlePageChange = (
    event: React.ChangeEvent<unknown> | null,
    newPage: number
  ) => {
    if (newPage !== page) {
      onPageChange && onPageChange(newPage);
    }
  };

  const handlePerPageChange = (newPerPage: number) => {
    if (newPerPage !== perPage) {
      onPerPageChange && onPerPageChange(newPerPage);
    }
  };

  const handleSortChange = (field: string) => {
    if (onSort) {
      if (sort === field) {
        // same field, toggle dir
        onSort(
          field,
          dir === ESTATE_SORT_DIR.asc
            ? ESTATE_SORT_DIR.dsc
            : ESTATE_SORT_DIR.asc
        );
      } else {
        // new field, same dir
        onSort(field, dir);
      }
    }
  };


  const emptyCell = <div style={{marginLeft:"10px"}}>--</div>
  const loading = estates === null;
  const showZero = estates !== null && estates.length === 0;
  return (
    <PerfectScrollbar>
      <AppTableWrapper minWidth={!showZero ? 800 : 1} noPad={true}>
        {showZero ? (
          <ZeroStateBody
            text={
              searchResult
                ? "No matching estates found"
                : "It looks like you aren't managing any estates yet"
            }
            textLink={searchResult ? undefined : <Invite />}
            imageAlt="No Estates"
            imagePrefix={zeroImagePrefix}
          />
        ) : (
          <Table data-automation-id="estate-table">
            <TableHead>
              <TableRow>
                <TableCell>
                  {/* search result columns not sortable */}
                  {!searchResult ? (
                    <Button
                      onClick={() => handleSortChange(ESTATE_SORT_BY.id)}
                      component="a"
                      className={classes.narrowButton}
                    >
                      Id
                    </Button>
                  ) : (
                    "Id"
                  )}
                </TableCell>
                {isUber ? (
                  <TableCell>
                    {!searchResult ? (
                      <Button
                        onClick={() => handleSortChange(ESTATE_SORT_BY.group)}
                        component="a"
                        className={classes.narrowButton}
                      >
                        Gp
                      </Button>
                    ) : (
                      "Gp"
                    )}
                  </TableCell>
                ) : (
                  <></>
                )}
                <TableCell>
                  {!searchResult ? (
                    <Button
                      onClick={() => handleSortChange(ESTATE_SORT_BY.name)}
                      component="a"
                      className={classes.narrowButton}
                    >
                      Estate Name
                    </Button>
                  ) : (
                    "Estate Name"
                  )}
                </TableCell>
                {/*<TableCell>Relationships</TableCell>*/}
                <TableCell>
                  {!searchResult ? (
                    <Button
                      onClick={() => handleSortChange(ESTATE_SORT_BY.value)}
                      component="a"
                      className={classes.narrowButton}
                    >
                      FMV
                    </Button>
                  ) : (
                    "FMV"
                  )}
                </TableCell>
                <TableCell>
                  {!searchResult ? (
                    <Button
                      onClick={() => handleSortChange(ESTATE_SORT_BY.progress)}
                      component="a"
                      className={classes.narrowButton}
                    >
                      Progress
                    </Button>
                  ) : (
                    "Progress"
                  )}
                </TableCell>
                <TableCell>
                  {!searchResult ? (
                    <Button
                      onClick={() => handleSortChange(ESTATE_SORT_BY.state)}
                      component="a"
                      className={classes.narrowButton}
                    >
                      Location
                    </Button>
                  ) : (
                    "Location"
                  )}
                </TableCell>
                <TableCell>
                  {!searchResult ? (
                    <Button
                      onClick={() => handleSortChange(ESTATE_SORT_BY.inboundFunnel)}
                      component="a"
                      className={classes.narrowButton}
                    >
                      Inbound<br />Funnel
                    </Button>
                  ) : (
                    <>Inbound<br />Funnel</>
                  )}
                </TableCell>
                <TableCell>
                  {!searchResult ? (
                    <Button
                      onClick={() => handleSortChange(ESTATE_SORT_BY.packet)}
                      component="a"
                      className={classes.narrowButton}
                    >
                      Packet
                    </Button>
                  ) : (
                    "Packet"
                  )}
                </TableCell>
                <TableCell>
                  {!searchResult ? (
                    <Button
                      onClick={() => handleSortChange(ESTATE_SORT_BY.payment)}
                      component="a"
                      className={classes.narrowButton}
                    >
                      Payment
                    </Button>
                  ) : (
                    "Payment"
                  )}
                </TableCell>
                <TableCell>
                  {!searchResult ? (
                    <Button
                      onClick={() => handleSortChange(ESTATE_SORT_BY.activity)}
                      component="a"
                      className={classes.narrowButton}
                    >
                      Last activity
                    </Button>
                  ) : (
                    "Last activity"
                  )}
                </TableCell>
                <TableCell>
                  {!searchResult ? (
                    <Button
                      onClick={() => handleSortChange(ESTATE_SORT_BY.created)}
                      component="a"
                      className={classes.narrowButton}
                    >
                      Created
                    </Button>
                  ) : (
                    "Created"
                  )}
                </TableCell>
              </TableRow>
            </TableHead>
            {loading ? (
              <TableBodyLoader colSpan={isUber ? 8 : 7} />
            ) : (
              <TableBody>
                {estates?.map((estate: Estate) => (
                  <TableRow
                    hover
                    key={`${estate.id}_${Math.floor(Math.random() * 100000)}`}
                    onClick={() => goToEstate(estate.id)}
                    className="clickable"
                  >
                    <TableCell>{estate.id?.toLocaleString()}</TableCell>
                    {isUber ? (
                      <TableCell>{estate.advisorGroupId}</TableCell>
                    ) : (
                      emptyCell
                    )}
                    <TableCell>
                      <Box alignItems="center" display="flex">
                        <AsyncAvatar estate={estate} />
                        <Typography
                          color="textPrimary"
                          variant="body1"
                          sx={{
                            textDecoration: estate?.deletedAt
                              ? "line-through"
                              : "",
                            fontWeight: estate.hasWriteAccess
                              ? "700"
                              : "normal",
                          }}
                        >
                          {estate.name}
                          {estate.isDemo ? " *" : null}
                        </Typography>
                      </Box>
                    </TableCell>
                    {/*<TableCell>{estate.numRelationships}</TableCell>*/}
                    <TableCell>
                      $
                      {abbreviateNumber(
                        estate.estateSummary?.assetsValue?.total || 0,
                        0
                      )}
                    </TableCell>
                    <TableCell>
                      {estate.estateSummary?.percentCompleted || 0}%
                    </TableCell>
                    <TableCell>{estate.location?estate.location: emptyCell}</TableCell>
                    <TableCell>
                      {estate.leadId ? "Probate Questionnaire" : "--"}
                    </TableCell>
                    <TableCell>
                      {estate.probateTypeName ? estate.probateTypeName : emptyCell}
                    </TableCell>
                    <TableCell>
                      {(estate.priceTierInternalName || estate.priceTierValue) ? <>
                        {estate.priceTierInternalName}
                        {estate.priceTierValue && estate.priceTierInternalName && <br />}
                        {estate.priceTierValue && Number.isSafeInteger(estate.priceTierValue) ? "$" + (estate.priceTierValue/100).toString() : ""}
                      </> : emptyCell}
                    </TableCell>
                    <TableCell>{estate.lastActivity ? estate.lastActivity : emptyCell}</TableCell>
                    <TableCell>{estate.createdDate ? estate.createdDate : emptyCell}</TableCell>
                  </TableRow>
                ))}
                {estates && onPageChange && !!total && (
                  <TableRow className="no-background">
                    <TableCell colSpan={isUber ? 8 : 7}>
                      <div className={classes.paginationWrapper}>
                        <Pagination
                          size="large"
                          color="primary"
                          count={Math.ceil(total / perPage)}
                          onChange={handlePageChange}
                          page={page}
                          showFirstButton={true}
                          showLastButton={true}
                        />
                        <div>
                          per page:{" "}
                          <Button
                            onClick={() => handlePerPageChange(10)}
                            className={
                              perPage === 10 ? classes.selected : undefined
                            }
                            style={{
                              minWidth: 40,
                            }}
                          >
                            10
                          </Button>{" "}
                          |{" "}
                          <Button
                            onClick={() => handlePerPageChange(25)}
                            className={
                              perPage === 25 ? classes.selected : undefined
                            }
                            style={{
                              minWidth: 40,
                            }}
                          >
                            25
                          </Button>{" "}
                          |{" "}
                          <Button
                            onClick={() => handlePerPageChange(50)}
                            className={
                              perPage === 50 ? classes.selected : undefined
                            }
                            style={{
                              minWidth: 40,
                            }}
                          >
                            50
                          </Button>
                        </div>
                      </div>
                      {isUber && (
                        <div>
                          <Switch
                            checked={includeDeleted}
                            onChange={handleSwitchChange}
                            color="primary"
                            name="include-deleted-switch"
                            id="include-deleted-switch"
                            inputProps={{ "aria-label": "include deleted" }}
                          />
                          <label
                            htmlFor="include-deleted-switch"
                            style={{ cursor: "pointer" }}
                          >
                            Include soft deleted estates
                          </label>
                        </div>
                      )}
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            )}
          </Table>
        )}
      </AppTableWrapper>
    </PerfectScrollbar>
  );
};

export default EstateTable;
