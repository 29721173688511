import {
  Button,
  Divider,
  Grid,
  MenuItem,
  Select,
  TableCell,
  TableRow,
  TextField,
  Theme,
  Typography,
} from "@mui/material";
import { makeStyles } from "tss-react/mui";
import React from "react";
import { logger, opportunitiesApi } from "../../apis";
import { ConfirmationDialog } from "../shared/dialog/ConfirmationDialog";
import { Opportunity } from "../../models/Opportunity";
import { StoreState } from "../../reducers";
import { User } from "../../models/User";
import { connect } from "react-redux";
import {
  deleteOpportunity,
  saveOpportunity,
} from "../../actions/opportunityActions";
import { OpportunityStatus } from "../../models/OpportunityStatus";
import { OpportunityType } from "../../models/OpportunityType";

const useStyles = makeStyles()((theme: Theme) => ({
  formWrapper: {
    padding: theme.spacing(1),
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  inputs: {
    backgroundColor: theme.palette.background.paper,
  },
  buttonWrapper: {
    display: "flex",
    flexDirection: "row-reverse",
  },
  buttonsLeft: {
    "& > Button": {
      marginRight: theme.spacing(1),
    },
  },
  columnRight: {
    display: "flex",
    flexDirection: "row-reverse",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  button: {
    margin: "2px 0",
  },
  selectWrapper: {
    flexDirection: "row",
    alignItems: "center",
  },
  select: {
    margin: theme.spacing(0, 1, 0, 0),
  },
  status: {
    padding: theme.spacing(0, 1),
  },
}));

interface Values {
  title: string;
  type: string;
  status: string;
}

interface Props {
  opportunity: Opportunity;
  onComplete: () => void;
  user: User;
  estateId: number;
  estateName?: string | null;
  advisorGroupId: number;
  opportunityTypes: OpportunityType[];
  opportunityStatuses: OpportunityStatus[];
  saveOpportunity: (opportunity: Opportunity, isNew: boolean) => void;
  deleteOpportunity: (opportunity: Opportunity) => void;
}

export const _OpportunityForm = ({
  opportunity,
  onComplete,
  user,
  estateId,
  estateName,
  advisorGroupId,
  opportunityStatuses,
  saveOpportunity,
  deleteOpportunity,
}: Props): JSX.Element => {
  const { classes } = useStyles();
  const isNew = opportunity.id === undefined;
  const [deleteOpen, setDeleteOpen] = React.useState<boolean>(false);
  const [opportunityTitle, setTitle] = React.useState<string>(opportunity.title || opportunity.originalTitle || "");
  const [opportunityStatus, setStatus] = React.useState<string>(opportunity.status?.title || "");
  const [submitting, setSubmitting] = React.useState<boolean>(false);

  const descriptionElementRef = React.useRef<HTMLElement>(null);
  React.useEffect(() => {
    if (deleteOpen) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [deleteOpen]);


  async function handleSubmit() {
    try {
      if (!user.advisorGroupName) {
        setSubmitting(false);
        return;
      }
      opportunity.title = opportunityTitle;
      // opportunity.type = OpportunityType.fromTitle(
      //   opportunityTypes,
      //   values.type
      // );
      opportunity.status = opportunityStatuses.find(s => s.title === opportunityStatus)
      if (isNew) {
        opportunity.advisorId = String(user.cognitoUsername);
        opportunity.advisorName = user.fullName;
        opportunity.estateId = estateId;
        opportunity.estateName = estateName || "";
        opportunity.advisorGroupId = advisorGroupId;
      }

      const updatedOpportunity = await opportunitiesApi.saveOpportunity(
        opportunity
      );
      if (updatedOpportunity) {
        setSubmitting(false);
        onComplete();
        saveOpportunity(updatedOpportunity, isNew);
      }
    } catch (error) {
      logger.error(error as Error);
      setSubmitting(false);
    }
  }

  const handleDeleteRequest = () => {
    setDeleteOpen(true);
  };

  const handleDelete = async () => {
    try {
      if (await opportunitiesApi.deleteOpportunity(opportunity)) {
        setDeleteOpen(false);
        onComplete();
        deleteOpportunity(opportunity);
      } else {
        // display error
      }
    } catch (error) {
      // TODO: display an error message to user
      console.error(error);
    }
  };

  return (
    <TableRow hover key="update-opportunity">
      <TableCell colSpan={7}>
        <div className={classes.formWrapper}>
        <form>
          <Grid
            container
            spacing={1}
            alignItems="flex-end"
            justifyContent="flex-end"
          >
            <Grid item xs={12}>
              <TextField
                name="title"
                label={
                  opportunity
                    ? "New Opportunity title"
                    : "Opportunity title"
                }
                variant="outlined"
                className={classes.inputs}
                fullWidth
                value={opportunityTitle}
                error={opportunityTitle === ''}
                onChange={(event) => setTitle(event.target.value)}
                style={{ marginTop: 8 }}
              />
            </Grid>
            <Grid item xs={12}>
              <Select
                name="status"
                value={opportunityStatus}
                onChange={(event) => { setStatus(event?.target.value || "")}}
                variant="outlined"
                autoWidth={true}
                displayEmpty={true}
                renderValue={() => opportunityStatus  || "Select status"}
                className={classes.select}
              >
                <MenuItem value={0}>
                  <em>Select status</em>
                </MenuItem>
                {opportunityStatuses &&
                  opportunityStatuses.map(
                    (oppStatus: OpportunityStatus) => (
                      <MenuItem
                        value={oppStatus.title}
                        key={oppStatus.id}
                      >
                        {oppStatus?.title || "----"}
                      </MenuItem>
                    )
                  )}
              </Select>
              <Divider className="dark" />
            </Grid>
            <Grid item xs={12} className={classes.buttonWrapper}>
              <Grid item xs={6} className={classes.columnRight}>
                <Button
                  disabled={submitting}
                  variant="contained"
                  color="primary"
                  className={classes.button}
                  onClick={handleSubmit}
                >
                  Save
                </Button>
                {!isNew && (
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => handleDeleteRequest()}
                    className={`${classes.button} primary-inverted`}
                    style={{ marginRight: 8 }}
                  >
                    Delete
                  </Button>
                )}
                <Typography
                  variant="body1"
                  color="error"
                  style={{ marginRight: 8 }}
                >
                  {status}
                </Typography>
              </Grid>
              <Grid item xs={6} className={classes.buttonsLeft}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    onComplete();
                  }}
                  className={`${classes.button} primary-inverted`}
                >
                  Cancel
                </Button>
              </Grid>
              <ConfirmationDialog
                open={deleteOpen}
                question={`Are you sure you want to delete this opportunity?`}
                description={
                  opportunity.title || opportunity.originalTitle || ""
                }
                onConfirm={handleDelete}
                confirmText="Delete It"
                onDeny={() => setDeleteOpen(false)}
                denyText="Cancel"
              />
            </Grid>
          </Grid>
        </form>
        </div>
      </TableCell>
    </TableRow>
  );
};

const mapStateToProps = ({
  user,
  opportunityTypes,
  opportunityStatuses,
}: StoreState): {
  user: User;
  opportunityTypes: OpportunityType[];
  opportunityStatuses: OpportunityStatus[];
} => {
  return {
    user,
    opportunityTypes,
    opportunityStatuses,
  };
};

export const OpportunityForm = connect(mapStateToProps, {
  saveOpportunity,
  deleteOpportunity,
})(_OpportunityForm);
