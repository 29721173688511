import React from "react";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import { StoreState } from "../../../reducers";
import { Estate } from "../../../models/Estate";
import { Grid } from "@mui/material";
import { SummaryCard } from "../../shared/SummaryCard";
import { DetailCard } from "../../shared/DetailCard";
import { EstateInfo } from "./overview/EstateInfo";
import { AssetSummary } from "../../shared/AssetSummary";
import { LatestOpportunities } from "../../opportunities/LatestOpportunities";
import { User } from "../../../models/User";
import { SubscriptionInfo } from "./overview/SubscriptionInfo";
import { ProbateInfo } from "./overview/ProbateInfo";
import { displayValue } from "../../../utils/displayValue";
import { Opportunity } from "../../../models/Opportunity";
import { Resources } from "../../shared/Resources";
import { ResponsiveCards } from "../../shared/ResponsiveCards";
import { AdvisorInfo } from "./overview/AdvisorInfo";
import { fetchSingleEstate } from "../../../actions/estateActions";

interface Props {
  user?: User;
  estates?: Estate[];
  opportunities?: Opportunity[];
  fetchSingleEstate?: (estateId: number) => Promise<void>;
}

// The EstateView (estate/EstateView/index.tsx) component wraps all estate screen components.
//  It will retrieve estate list and redirect away if none are returned
//  or the requested single state (via query string) was not found
const _OverviewView = ({
  user,
  estates,
  opportunities,
  fetchSingleEstate,
}: Props) => {
  const { estateId } = useParams();

  let estate: Estate | undefined;
  if (estateId && estates?.length) {
    estate = estates.find((estate: Estate) => estate.id === +estateId);
  }

  const refresh = () => {
    estate?.id && fetchSingleEstate?.(estate.id);
  };

  const filteredOpps =
    (estate
      ? opportunities?.filter((opp: Opportunity) => {
          return opp.estateId === estate?.id;
        })
      : opportunities) || [];

  return (
    <div data-automation-id="page-content-estate-overview">
      {!estate ? null : (
        <Grid container spacing={2}>
          {/*<Grid item lg={3} sm={6} xl={3} xs={12}>*/}
          <Grid item lg={3} sm={6} xs={12}>
            <SummaryCard
              title="Tasks Completed"
              content={
                estate?.estateSummary?.percentCompleted?.toString() + "%" || "0"
              }
            />
          </Grid>
          <Grid item lg={3} sm={6} xs={12}>
            <SummaryCard
              title="Current Opportunities"
              content={`${filteredOpps ? filteredOpps.length : "..."}`}
            />
          </Grid>
          <Grid item lg={3} sm={6} xs={12}>
            <SummaryCard
              title="Active Assets"
              content={
                estate.estateSummary?.assetsInventoried?.total?.toLocaleString() ||
                "0"
              }
            />
          </Grid>
          <Grid item lg={3} sm={6} xs={12}>
            <SummaryCard
              title="Estate FMV"
              content={
                displayValue(estate.estateSummary?.assetsValue?.total, true) ||
                "0"
              }
            />
          </Grid>
          <Grid item xs={12}>
              <LatestOpportunities count={3} estate={estate} />
          </Grid>
          <Grid item xs={12}>
            <ResponsiveCards
              breakAt="lg"
              leftPercent={55}
              rightPercent={45}
              titleLeft="Total Active Estate Assets"
              cardLeft={
                <AssetSummary
                  assetSubTotals={[
                    {
                      key: "Total",
                      value: estate.estateSummary?.assetsValue?.total || 0,
                    },
                    {
                      key: "Financial accounts",
                      value: estate.estateSummary?.assetsValue?.financial || 0,
                    },
                    // { key: "Credits & liabilities", value: estate.estateSummary?.assetsValue?. },
                    {
                      key: "Real estate",
                      value: estate.estateSummary?.assetsValue?.realEstate || 0,
                    },
                    {
                      key: "Household",
                      value: estate.estateSummary?.assetsValue?.household || 0,
                    },
                    {
                      key: "Vehicles",
                      value: estate.estateSummary?.assetsValue?.vehicle || 0,
                    },
                    {
                      key: "Digital",
                      value: estate.estateSummary?.assetsValue?.digital || 0,
                    },
                    {
                      key: "Other",
                      value: estate.estateSummary?.assetsValue?.other || 0,
                    },
                  ]}
                />
              }
              cardRight={
                <EstateInfo
                  estate={estate}
                  isUber={user?.isUberAdmin}
                  onRefresh={refresh}
                />
              }
            />
          </Grid>
          {user?.isUberAdmin && (
            <Grid item xs={12}>
              <DetailCard title="Product">
                <SubscriptionInfo estate={estate} isUber={user?.isUberAdmin} />
              </DetailCard>
            </Grid>
          )}
          <Grid item xs={12}>
            <ResponsiveCards
              breakAt="lg"
              leftPercent={55}
              rightPercent={45}
              titleLeft="Probate Type"
              cardLeft={
                <ProbateInfo
                  estate={estate}
                  isAdvisorAdmin={user?.isAdvisorAdmin}
                />
              }
              cardRight={
                <AdvisorInfo
                  estate={estate}
                  isAdvisorAdmin={user?.isAdvisorAdmin}
                  onRefresh={refresh}
                />
              }
            />
          </Grid>
          <Grid item xs={12}>
            <Resources />
          </Grid>
        </Grid>
      )}
    </div>
  );
};

const mapStateToProps = ({
  user,
  estates,
  opportunities,
}: StoreState): {
  user: User;
  estates: Estate[];
  opportunities: Opportunity[];
} => {
  return { user, estates, opportunities };
};

export const OverviewView = connect(mapStateToProps, {
  fetchSingleEstate,
})(_OverviewView);
