import React from "react";
import AddIcon from "@mui/icons-material/Add";
import { IconButton, Theme } from "@mui/material";
import { makeStyles } from "tss-react/mui";


const useStyles = makeStyles()((theme: Theme) => ({
  root: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    "&:hover": {
      backgroundColor: theme.palette.primary.light,
    },
  },
}));

interface Props {
  onClick: () => void;
  title?: string;
}

export const AddRecordButton = ({ onClick, title }: Props): JSX.Element => {
  const { classes } = useStyles();

  return (
    <IconButton
      color="primary"
      onClick={onClick}
      className={classes.root}
      size="small"
      title={title}
    >
      <AddIcon />
    </IconButton>
  );
};
