import { Box, InputAdornment, TextField, Typography } from "@mui/material";
import React, { useContext, useState } from "react";
import { ViewAssetAttribute } from "../ViewAssetAttribute";
import { HelpComponent, HelpContent } from "../HelpComponent";
import { trimOnBlur } from "../../../../../../utils/trimOnBlur";
import { AssetFormContext } from "../AssetFormContext";
import { InputType } from "./InputTypes";

export type InputMatches = {
  regexp: RegExp;
  failMessage: string;
};

interface Props {
  isEditMode?: boolean;
  type?: InputType;
  value?: string | number;
  label?: string;
  name?: string;
  caption?: string;
  inputComponent?: any;
  noneTextOption?: string;
  prefix?: string;
  suffix?: string;
  inputMatches?: InputMatches;
  required?: boolean;
  charLimit?: number;
  multiline?: boolean;
  rows?: number;
  onChange?: (e: any) => void;
  error?: string;
  touched?: boolean;
  helpContent?: HelpContent;
  noCommas?: boolean;
}

export const EditableText = ({
  isEditMode = false,
  type,
  value,
  label,
  name,
  caption,
  inputComponent,
  // noneTextOption,
  error,
  touched,
  prefix,
  suffix,
  rows,
  // inputMatches,
  // required = true,
  charLimit,
  onChange,
  helpContent,
  noCommas,
}: Props): JSX.Element => {
  const [charCount, setCharCount] = useState(value?.toString()?.length || 0);

  const { handleBlur, setFieldValue } = useContext(AssetFormContext) || {};

  const inputProps =
    prefix || suffix
      ? {
          startAdornment: prefix ? (
            <InputAdornment position="start">{prefix}</InputAdornment>
          ) : undefined,
          endAdornment: prefix ? (
            <InputAdornment position="end">{suffix}</InputAdornment>
          ) : undefined,
          inputComponent: inputComponent ? (inputComponent as any) : undefined,
        }
      : {
          inputComponent: inputComponent ? (inputComponent as any) : undefined,
        };

  const htmlInputType =
    type === InputType.number || type === InputType.usCurrency
      ? "number"
      : "text";

  return isEditMode ? (
    <Box sx={{ mt: 1, width: "60%" }}>
      <label htmlFor={`${label}-label`}>
        <strong>{label}</strong>
      </label>
      {caption && <Typography variant="body2">{caption}</Typography>}
      <TextField
        multiline={!!rows}
        InputProps={inputProps}
        error={
          Boolean(touched && error) || !!(charLimit && charCount > charLimit)
        }
        // error={!!error}
        fullWidth
        rows={rows}
        helperText={charLimit ? `${charLimit - charCount} characters left` : ""}
        // label={label || "Text"}
        margin="normal"
        name={name}
        onBlur={(evt: React.FocusEvent<any>) => {
          if (name && setFieldValue && type === InputType.text) {
            trimOnBlur(evt, name, setFieldValue, handleBlur);
          } else {
            handleBlur?.(evt);
          }
        }}
        onChange={(event) => {
          setCharCount(event.target.value.length);
          onChange?.(event);
        }}
        type={htmlInputType}
        value={value || ""}
        variant="outlined"
      />
      {error && (
        <Typography variant="body2" color="error">
          {error}
        </Typography>
      )}
      <HelpComponent helpContent={helpContent} />
    </Box>
  ) : (
    <ViewAssetAttribute
      type={type}
      label={label}
      caption={caption}
      value={value}
      helpContent={helpContent}
      noCommas={noCommas}
    />
  );
};
