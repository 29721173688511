import { ConfirmationDialog } from "../../shared/dialog/ConfirmationDialog";
import {
  ATTICUS_DOC_LOGO_LOCATIONS,
  IMPORT_FORMS_DETAIL_URL,
} from "../../../constants";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  IconButton,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { Help } from "@mui/icons-material";
import React, { useState } from "react";
import { ImportType } from "./AtticusFormsView";

interface Props {
  onImport: (
    importType: ImportType,
    keys: string[],
    logoLocation: string
  ) => Promise<any>;
}

export const ImportFormsByFolders = ({ onImport }: Props): JSX.Element => {
  const [isBucketImportHelpOpen, setIsBucketImportHelpOpen] = useState(false);
  const [importKeys, setImportKeys] = useState("");
  const [logoLocation, setLogoLocation] = useState("BottomRight");

  const [status, setStatus] = useState("");
  const [loading, setLoading] = useState(false);

  return (
    <Card style={{ marginTop: 16 }}>
      <ConfirmationDialog
        open={isBucketImportHelpOpen}
        question="Import new forms by s3 folder key(s)"
        description="This will read the s3 folder and import all docs into the database based on the json metadata files. A single key, or comma seperated list of keys are accepted."
        onDeny={() => setIsBucketImportHelpOpen(false)}
        denyText="Ok"
        onConfirm={() => window.open(IMPORT_FORMS_DETAIL_URL)}
        confirmText="See detailed instructions"
      />
      <CardHeader
        title="Import new forms by s3 folder key(s)"
        action={
          <IconButton
            aria-label="about"
            color="primary"
            onClick={() => {
              setIsBucketImportHelpOpen(true);
            }}
          >
            <Help />
          </IconButton>
        }
      />
      <CardContent>
        <Typography variant="body2">
          example: "upload/united-states/california,
          upload/united-states/north-carolina"
        </Typography>
        <TextField
          fullWidth
          label="S3 Folder Key(s)"
          margin="normal"
          name="folder"
          onFocus={() => {
            setStatus("");
          }}
          onChange={(event) => {
            setStatus("");
            setImportKeys(event.target.value as string);
          }}
          type="text"
          value={importKeys}
          variant="outlined"
        />
        <Box my={2} display="flex" flexDirection="row" alignItems="center">
          <Typography style={{ marginRight: 8 }}>Logo location: </Typography>
          <Select
            name="logoLocation"
            value={logoLocation}
            onChange={(event) => {
              setLogoLocation(event.target.value as string);
            }}
            variant="outlined"
          >
            {ATTICUS_DOC_LOGO_LOCATIONS.map((loc: any) => {
              return (
                <MenuItem value={loc.name} key={loc.name}>
                  {loc.name}
                </MenuItem>
              );
            })}
          </Select>
        </Box>
        <Button
          variant="contained"
          color="primary"
          type="button"
          disabled={loading}
          onClick={async () => {
            const keys: string[] = [];
            importKeys.split(",").forEach((doc: string) => {
              const trimmedDoc = doc.trim();
              keys.push(trimmedDoc);
            });
            if (keys.length) {
              setLoading(true);
              try {
                const response = await onImport(
                  ImportType.folders,
                  keys,
                  logoLocation
                );
                console.log("IMPORT RESULT: ", response);
                let message = "Import Started! ✌ ";
                message += `${response.message} (Batch ID: '${response.batchId}')`;

                setStatus(message);
                setImportKeys("");
              } catch (error: any) {
                setStatus((error as Error)?.message);
              }
            } else {
              setStatus("Error: object keys must end with .json");
            }
            setLoading(false);
          }}
        >
          Import New Forms
        </Button>
        <Typography
          color={status?.toLowerCase().includes("error") ? "error" : "inherit"}
        >
          {status}
        </Typography>
      </CardContent>
    </Card>
  );
};
