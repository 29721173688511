// Gets around an issue with makeStyles no longer existing in mui 5
// From https://github.com/mui/material-ui/issues/26571

import { useMemo } from "react";
import { Theme, useTheme } from "@mui/material"
import { CSSObject } from "@emotion/styled/types/base";
import createCache from "@emotion/cache";
import { css } from "@emotion/react";
import { insertStyles } from "@emotion/utils";
import _ from "lodash";

const cache = createCache({ key: "prefix", prepend: true });
cache.compat = true;

export const makeStyles = <Key extends string>(
  generateStyles: (theme: Theme) => Record<Key, CSSObject>
) => {
  return () => {
    const theme = useTheme();
    const classes = useMemo(() => {
      const styles = generateStyles(theme);
      return Object.keys(styles).reduce((s, key) => {
        const serialized = css(styles[key as Key]);
        s[key as Key] = _.camelCase(`${cache.key}_${serialized.name}`.replaceAll("-", "_"));
        insertStyles(cache, serialized, false);
        console.log(JSON.stringify(s))
        return s;
      }, {} as Record<Key, string>);
    }, [theme]);

    return classes;
  };
};
