import React, { createContext } from "react";
import { Asset } from "../../../../../models/Asset";
interface Props {
  asset?: Asset;
  activeSection: string;
  helpers: any;
  children: JSX.Element;
}

export interface AssetFormContextInterface {
  asset: Asset;
  activeSection: string;
  // Add any formik helps you need to access
  errors: any;
  handleBlur: any;
  handleChange: any;
  handleSubmit: any;
  handleReset: any;
  isSubmitting: any;
  touched: any;
  values: any;
  status: any;
  setFieldValue: any;
  setFieldTouched: any;
}

export const AssetFormContext = createContext<
  AssetFormContextInterface | undefined
>(undefined);

export const AssetFormContextWrapper = ({
  asset,
  activeSection,
  helpers,
  children,
}: Props): JSX.Element => {
  return (
    <AssetFormContext.Provider value={{ asset, activeSection, ...helpers }}>
      {children}
    </AssetFormContext.Provider>
  );
};
