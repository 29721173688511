import { ActionTypes } from "../actions/types";
import { KnowledgeEngineFieldCategory } from "../models/KnowledgeEngine";
import { AnyAction } from "redux";

export const knowledgeEngineFieldCategoriesReducer = (
  categories: KnowledgeEngineFieldCategory[] | null = null,
  action: AnyAction
): KnowledgeEngineFieldCategory[] | null => {
  switch (action.type) {
    case ActionTypes.FetchKnowledgeEngineFieldCategories:
      if (categories) {
        return categories;
      } else {
        return action.payload;
      }
    default:
      return categories;
  }
};
