import { API } from "aws-amplify";
import { REST_API } from "../constants";

export type Log = {
  level: string;
  message: string;
  timestamp: string;
  sessionId?: string;
};

export class LogProxy {
  static path = "/logs";
  async log(log: Log): Promise<string | null> {
    try {
      await API.post(REST_API, LogProxy.path, {
        body: log,
      });
    } catch (error) {
      console.error(error);
    }
    return null;
  }
}
