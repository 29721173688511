import { Dispatch } from "redux";
import { ActionTypes } from "./types";
import { languagesApi } from "../apis";
import { Language } from "../models/Language";

export interface FetchLanguagesAction {
  type: ActionTypes.FetchLanguages;
  payload: Language[] | null;
}

export const fetchLanguages = () => {
  return async (dispatch: Dispatch): Promise<void> => {
    let languages: Language[] | null = null;
    try {
      languages = await languagesApi.fetchLanguages();
    } catch (error) {
      console.error(error);
    }
    dispatch<FetchLanguagesAction>({
      type: ActionTypes.FetchLanguages,
      payload: languages,
    });
  };
};

export interface FetchSingleLanguageAction {
  type: ActionTypes.FetchSingleLanguage;
  payload: Language | null;
}

export const fetchLanguage = (languageId: number) => {
  return async (dispatch: Dispatch): Promise<void> => {
    let language: Language | null = null;
    try {
      language = await languagesApi.fetchLanguage(languageId);
    } catch (error) {
      console.error(error);
    }
    dispatch<FetchSingleLanguageAction>({
      type: ActionTypes.FetchSingleLanguage,
      payload: language,
    });
  };
};
