import React, { useState, useEffect } from "react";
import { Box, Card, Theme } from "@mui/material";
import { makeStyles } from "tss-react/mui";

import { useParams } from "react-router-dom";
import { PDFDocument, PDFTextField, PDFCheckBox, PDFField } from "pdf-lib";
import { formsApi, knowledgeEngineApi, estateApi } from "../../../apis";
import { connect } from "react-redux";
import { StoreState } from "../../../reducers";

import { AtticusForm } from "../../../models/AtticusForm";
import { User } from "../../../models/User";

import { Estate } from "../../../models/Estate";
import { AdvisorGroup } from "../../../models/AdvisorGroup";
import { KnowledgeEngineField } from "../../../models/KnowledgeEngine";
import {
  FormInterfaceFieldJson,
  FormInterface,
  PDFLibFieldTypes,
} from "../../../models/FormInterface";

import { fetchEstates } from "../../../actions/estateActions";
import { fetchAdvisorGroups } from "../../../actions/advisorGroupActions";
import { fetchMockEstates } from "../../../actions/estateMocksActions";
import {
  keFieldsToHandlebarsVars,
  applyHandlebars,
} from "../../../utils/handlebarsCompiler";

import { FieldSearch } from "./FieldSearch/FieldSearch";
import {
  InterfaceEditor,
  EstateGrouping,
} from "./InterfaceEditor/InterfaceEditor";

import { MosaicNode } from "react-mosaic-component";
import {
  AtticusMosaicGrid,
  TILE,
} from "../../shared/AtticusMosaic/AtticusMosaicGrid";

import { TopMetadataBar } from "./TopMetadataBar";
import { PdfViewer } from "./PdfViewer/PdfViewer";
import { mergeWith } from "lodash";

import { ESTATE_SORT_BY, ESTATE_SORT_DIR } from "../../../constants";

const useStyles = makeStyles()((theme:Theme) => ({
  root: {
    height: "85vh",
    display: "flex",
    flexDirection: "column",
  },
  flexOne: {
    flex: "1",
  },
  cardMainCenterAll: {
    flex: 1,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    height: "100%",
  },
  iframe: {
    height: "100%",
    width: "100%",
  },
}));

interface Props {
  estates: Estate[];
  user: User;
  fetchEstates: (
    limit?: number,
    offset?: number,
    sort?: string,
    dir?: string,
    includeDeleted?: boolean,
    gid?: number,
    append?: boolean
  ) => void;
  estatesMocks: Estate[];
  fetchMockEstates: () => void;
  advisorGroups: AdvisorGroup[];
  fetchAdvisorGroups: () => void;
}

export const _FormInterfaceView = ({
  estates,
  user,
  estatesMocks,
  advisorGroups,
  fetchEstates,
  fetchMockEstates,
  fetchAdvisorGroups,
}: Props): JSX.Element => {
  const { classes } = useStyles();
  const { formId } = useParams();

  const [form, setForm] = useState<AtticusForm | undefined>(undefined);
  const [formUrl, setFormUrl] = useState<string | undefined>(undefined);
  const [estatesSandbox, setEstatesSandbox] = useState<Estate[]>([]);

  const [selectedEstateId, setSelectedEstateId] = useState<number | undefined>(
    undefined
  );

  // This is the PDF that is actually displaying in the app, within memory.
  const [pdfByteArray, setPdfByteArray] = useState<Uint8Array | undefined>(
    undefined
  );

  // Always reference this PDF as the original when doing any changes (Always slot into the original PDF)
  const [originalPdfByteArray, setOriginalPdfByteArray] = useState<
    Uint8Array | undefined
  >(undefined);

  const [formInterface, setFormInterface] = useState<FormInterface | undefined>(
    undefined
  );
  const [formInterfaceParsingError, setFormInterfaceParsingError] =
    useState<Error | null>(null);
  const [knowledgeEngineFields, setKnowledgeEngineFields] = useState<
    KnowledgeEngineField[] | undefined
  >(undefined);

  useEffect(() => {
    if (!estatesMocks) {
      // Redux store for Evan's Mock Estates
      fetchMockEstates();
    }
    if (!advisorGroups) {
      fetchAdvisorGroups();
    }
    const dir = ESTATE_SORT_DIR.dsc;
    const sortBy = ESTATE_SORT_BY.id;

    // Redux store for the last 10 estates for the user's Advisor Group.
    fetchEstates(8, 0, sortBy, dir, false, user.advisorGroupId);
  }, []);

  // In the future, think about how to best integrate this into Redux store.
  // Currently is a bit complicated to have Redux store two specific AG's estates.
  useEffect(() => {
    const dir = ESTATE_SORT_DIR.dsc;
    const sortBy = ESTATE_SORT_BY.id;

    let sandboxGroups: AdvisorGroup[] = [];
    if (advisorGroups?.length > 0) {
      sandboxGroups = advisorGroups.filter(
        (group) => group.title == "AG-Sandbox" || group.title == "AG-Sandbox2"
      );
    }

    const fetchSandboxEstates = async () => {
      let tempSandboxEstates: Estate[] = [];

      // Get Sandbox Estates
      if (sandboxGroups?.length > 0) {
        for (const group of sandboxGroups) {
          const estatesWithTotal = await estateApi.fetchEstates(
            50,
            0,
            sortBy,
            dir,
            false,
            group.id
          );
          tempSandboxEstates = tempSandboxEstates.concat(
            estatesWithTotal.estates
          );
        }
        setEstatesSandbox(tempSandboxEstates);
      }
    };

    try {
      fetchSandboxEstates();
    } catch (error) {
      // There was an issue with fetching Sandbox Estates, most likely because the user does not have permissions to access them.
      // Ignore and just do not add the sandbox estates, since they're not necessary.
    }
  }, [advisorGroups]);

  const copyByteArray = (src: Uint8Array) => {
    const dst = new Uint8Array(src);
    return dst;
  };

  const arrayBufferToByteArray = (buffer: ArrayBuffer) => {
    const byteArray = new Uint8Array(buffer);
    return byteArray;
  };

  // Fire this every time the pdfByteArray changes, to regenerate the form in the iFrame.
  useEffect(() => {
    if (pdfByteArray) {
      const fileBlob = new Blob([pdfByteArray], {
        type: "application/pdf",
      });
      const fileUrl = URL.createObjectURL(fileBlob);
      setFormUrl(fileUrl);
    }
  }, [pdfByteArray]);

  const saveFormInterface = async (
    formId: number,
    formInterface: FormInterface
  ) => {
    try {
      const savedInterface = await formsApi.saveFormInterface(
        formId,
        formInterface
      );
      setFormInterface(savedInterface);
    } catch (error: any) {
      console.error(error);
      alert("Could not save form interface: " + error.response.data.message);
    }
  };

  const slotPdf = async (
    pdfDoc: PDFDocument,
    slotMap: {
      [key: string]: string | boolean | undefined;
    }
  ) => {
    const pdfLibForm = pdfDoc.getForm();
    const pdfLibFields = pdfLibForm.getFields();
    for (const pdfLibField of pdfLibFields) {
      const name = pdfLibField.getName();
      const value = slotMap[name];
      if (pdfLibField instanceof PDFTextField) {
        const field = pdfLibForm.getTextField(name);
        field.setText(value?.toString());
      } else if (pdfLibField instanceof PDFCheckBox) {
        const checkBox = pdfLibForm.getCheckBox(name);
        if (value === true) {
          checkBox.check();
        } else {
          checkBox.uncheck();
        }
      }
    }
    const pdfBytes = await pdfDoc.save();
    setPdfByteArray(pdfBytes);
  };

  const generateSlotMapFromFieldNames = (
    pdfFields: PDFField[]
  ): {
    [key: string]: string | boolean | undefined;
  } => {
    const retJson: { [key: string]: string | boolean | undefined } = {};
    for (const field of pdfFields) {
      const name = field.getName();
      const value = `<${field.getName()}>`;
      if (field instanceof PDFTextField) {
        retJson[name] = value;
      }
    }

    return retJson;
  };

  const generateSlotMapFromInterface = (
    postHandlebarsInterfaceJson: FormInterface
  ): {
    [key: string]: string | boolean | undefined;
  } => {
    const retJson: { [key: string]: string | boolean | undefined } = {};

    postHandlebarsInterfaceJson.data.forEach((field) => {
      let valToSlotIn:string|undefined|boolean = undefined;
      const answerValue = field.answer_value;
      const fieldName = field.field_name;

      if (answerValue) {
        if (field.field_type == PDFLibFieldTypes.TEXTFIELD) {
          valToSlotIn = answerValue;
        } else if (field.field_type == PDFLibFieldTypes.CHECKBOX) {
          let shouldCheck = false;
          if (answerValue.toLowerCase().trim() == "true") {
            shouldCheck = true;
          }
          valToSlotIn = shouldCheck;
        }
      }
      retJson[fieldName] = valToSlotIn || undefined;
    });
    return retJson;
  };

  const loadPdfDoc = async (byteArray: Uint8Array) => {
    try {
      return await PDFDocument.load(byteArray);
    } catch (err) {
      console.error(err);
      if ((err as Error)?.stack?.includes("EncryptedPDFError")) {
        alert(
          "Pdf is encrypted. We currently can not do data slotting on this form."
        );
      } else {
        alert("Handlebars Error! Check console for more info.");
      }
    }
  };

  // Run this every time the form ID changes
  useEffect(() => {
    const fetchForm = async () => {
      if (formId) {
        const atticusForm = await formsApi.fetchForm(Number(formId));
        if (atticusForm) {
          setForm(atticusForm);
          if (atticusForm.s3Urls?.asyncPdfStoragePdf) {
            const newFormUrl = await atticusForm.s3Urls?.asyncPdfStoragePdf();
            if (newFormUrl) {
              setFormUrl(newFormUrl);
              const pdfBuffer = await fetch(newFormUrl).then((res) =>
                res.arrayBuffer()
              );
              const byteArray = arrayBufferToByteArray(pdfBuffer);

              // Once we get the Byte Array of the original PDF, we need to store the Original as a copy to
              // refer to during each new injection action.
              setOriginalPdfByteArray(copyByteArray(byteArray));
              setPdfByteArray(byteArray);

              const pdfDoc = await loadPdfDoc(byteArray);
              if (pdfDoc) {
                const form = pdfDoc.getForm();
                const fields = form.getFields();

                if (fields.length == 0) {
                  alert("PDF does not have any fields.");
                }

                const interfaceFromBackend =
                  await formsApi.fetchLatestFormInterface(Number(formId));

                if (interfaceFromBackend) {
                  // No need to create a starter Forms Interface data array since it already exists in DB.
                  setFormInterface(interfaceFromBackend);
                } else {
                  // Create a starter Forms Interface Data array
                  const fieldsJson = fields.map((field) => {
                    let fieldType:PDFLibFieldTypes|null = null;
                    if (field instanceof PDFCheckBox) {
                      fieldType = PDFLibFieldTypes.CHECKBOX;
                    } else if (field instanceof PDFTextField) {
                      fieldType = PDFLibFieldTypes.TEXTFIELD;
                    }
                    const interfaceField: FormInterfaceFieldJson = {
                      question: null,
                      field_name: field.getName(),
                      field_type: fieldType,
                      answer_value: null,
                    };
                    return interfaceField;
                  });
                  const iface = new FormInterface(fieldsJson);
                  setFormInterface(iface);
                }

                const slotMap = generateSlotMapFromFieldNames(fields);
                slotPdf(pdfDoc, slotMap);
              }
            }
          }
        }
      }
    };

    fetchForm();
  }, [formId]);

  // Run the following every time the Selected Estate ID changes.
  useEffect(() => {
    const fetchNewKEVals = async (estateId: number) => {
      const estatesToSearchThrough: Estate[] = [];
      estateGroupings.forEach((grouping) => {
        estatesToSearchThrough.push(...grouping.estates);
      });
      const estate = estatesToSearchThrough.find((e) => e.id == estateId);
      const userId = estate?.userId;
      if (userId) {
        const keValsForEstate = await knowledgeEngineApi.getAllFieldsForUser(
          userId
        );
        if (keValsForEstate) {
          setKnowledgeEngineFields(keValsForEstate);
        }
      } else {
        console.error(
          `Estate ${estate?.name} does not have a User associated with it!`
        );
      }
    };
    if (selectedEstateId) {
      setKnowledgeEngineFields(undefined);
      fetchNewKEVals(selectedEstateId);
    }
  }, [selectedEstateId]);

  const applyHandlebarsToInterfaceWithKE = (
    iJson: FormInterface,
    keFields: KnowledgeEngineField[]
  ) => {
    try {
      const handlebarsVars = keFieldsToHandlebarsVars(keFields);
      return applyHandlebars(iJson, handlebarsVars);
    } catch (err) {
      console.error(err);
      alert("Handlebars Error! Check console for more info.");
    }
  };

  const onInjectClick = async () => {
    if (formInterfaceParsingError) {
      window.alert("Invalid JSON detected: \r\n" + formInterfaceParsingError);
      return;
    }
    if (originalPdfByteArray && formInterface && knowledgeEngineFields) {
      const pdfDoc = await loadPdfDoc(originalPdfByteArray);

      if (pdfDoc) {
        // Get Handlebars-Injected Interface SlotMap
        const postHandlebarsInterfaceJson = applyHandlebarsToInterfaceWithKE(
          formInterface,
          knowledgeEngineFields
        );
        const slotMap = generateSlotMapFromInterface(
          postHandlebarsInterfaceJson
        );

        // Slot the PDF.
        await slotPdf(pdfDoc, slotMap);
      }
    }
  };

  const onClearFormClick = async () => {
    setPdfByteArray(originalPdfByteArray);
  };

  const onViewHybridClick = async () => {
    if (originalPdfByteArray && formInterface && knowledgeEngineFields) {
      const pdfDoc = await loadPdfDoc(originalPdfByteArray);

      if (pdfDoc) {
        const form = pdfDoc.getForm();
        const fields = form.getFields();

        // Get Form Fields SlotMap
        const fieldsSlotMap = generateSlotMapFromFieldNames(fields);

        // Get Handlebars-Injected Interface SlotMap
        const postHandlebarsInterfaceJson = applyHandlebarsToInterfaceWithKE(
          formInterface,
          knowledgeEngineFields
        );
        const interfaceSlotMap = generateSlotMapFromInterface(
          postHandlebarsInterfaceJson
        );

        // Combine the two
        const combinedSlotMap = mergeWith(
          {},
          fieldsSlotMap,
          interfaceSlotMap,
          (a, b) => (b === null ? a : undefined)
        );

        // Slot the PDF.
        await slotPdf(pdfDoc, combinedSlotMap);
      }
    }
  };

  const onViewFieldsClick = async () => {
    if (originalPdfByteArray) {
      const pdfDoc = await loadPdfDoc(originalPdfByteArray);
      if (pdfDoc) {
        const form = pdfDoc.getForm();
        const fields = form.getFields();

        // Just get the Form Fields SlotMap
        const slotMap = generateSlotMapFromFieldNames(fields);

        // Slot the PDF.
        slotPdf(pdfDoc, slotMap);
      }
    }
  };

  const estateGroupings: EstateGrouping[] = [
    {
      title: "Mock Estates",
      estates: estatesMocks,
    },
    {
      title: "Sandbox Estates",
      estates: estatesSandbox,
    },
    {
      title: `Advisor's Estates`,
      estates: estates,
    },
  ];

  const tileMap: { [viewId: string]: TILE } = {
    interface: {
      title: "Interface Data",
      element: (
        <InterfaceEditor
          form={form}
          formInterface={formInterface}
          setFormInterface={setFormInterface}
          saveFormInterface={saveFormInterface}
          setFormInterfaceParsingError={setFormInterfaceParsingError}
          estateGroupings={estateGroupings}
          isInjectDisabled={
            !selectedEstateId ||
            knowledgeEngineFields == null ||
            formInterfaceParsingError != null
          }
          isClearFormDisabled={!originalPdfByteArray}
          isViewFormFieldsDisabled={pdfByteArray ? false : true}
          estatesLoading={!estates || !estatesMocks || !estatesSandbox}
          selectedEstateId={selectedEstateId}
          setSelectedEstateId={setSelectedEstateId}
          onInjectClick={onInjectClick}
          onViewFieldsClick={onViewFieldsClick}
          onViewHybridClick={onViewHybridClick}
          onClearFormClick={onClearFormClick}
        />
      ),
    },
    "knowledge-fetcher": {
      title: "Knowledge Fetcher",
      element: (
        <FieldSearch
          fields={knowledgeEngineFields}
          selectedEstateId={selectedEstateId}
          estatesLoading={!estates || !estatesMocks || !estatesSandbox}
        />
      ),
    },
    preview: {
      title: "PDF Preview",
      element: <PdfViewer formUrl={formUrl} />,
    },
  };

  const nodeMap: MosaicNode<string> = {
    direction: "row",
    first: {
      direction: "column",
      first: "interface",
      second: "knowledge-fetcher",
      splitPercentage: 50,
    },
    second: "preview",
    splitPercentage: 60,
  };

  return (
    <>
      <Card className={classes.root}>
        <Box>
          <TopMetadataBar form={form} formInterface={formInterface} />
        </Box>
        <Box className={classes.flexOne}>
          {/* NOTE: It's weird that MosaicGrid's onRelease() triggers during a tile drag operation.
            Guess it makes sense and is needed to cause the mosaic to rerender all nodes,
            but it interferes with the disableIframes() call in the
            window's onDragStart/onDragEnd handlers becuase it re-enables iFrames during
            the drag event. Figure out a workaround and then allow for drag/drop with iFrames. */}
          <AtticusMosaicGrid
            initialNode={nodeMap}
            tileMap={tileMap}
            draggable={false}
          />
        </Box>
      </Card>
    </>
  );
};

const mapStateToProps = ({
  estates,
  estatesMocks,
  advisorGroups,
  user,
}: StoreState): {
  estates: Estate[];
  estatesMocks: Estate[];
  advisorGroups: AdvisorGroup[];
  user: User;
} => {
  return { estates, estatesMocks, advisorGroups, user };
};

export const FormInterfaceView = connect(mapStateToProps, {
  fetchEstates,
  fetchMockEstates,
  fetchAdvisorGroups,
})(_FormInterfaceView);
