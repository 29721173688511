import React, { useState } from "react";
import { Box, Button, TextField, Typography } from "@mui/material";
import { probateApi } from "../../../apis";
import axios, { AxiosError } from "axios";
import { ImportProbateMappingResponse } from "../../../apis/Probate";
import { SCREEN_PATHS } from "../../../constants";
import { LoadingSpinner } from "../../shared/LoadingSpinner";

interface Props {
  onImportComplete?: () => void;
}

export const ProbateMappingForm = ({
  onImportComplete,
}: Props): JSX.Element => {
  const [json, setJson] = useState<string>();
  // const [isJSONInvalid, setIsJSONInvalid] = useState(false);
  const [errors, setErrors] = useState<string[] | undefined>();
  const [status, setStatus] = useState<string[] | undefined>();
  const [loading, setLoading] = useState(false);

  const handleImport = async () => {
    setStatus(undefined);
    setErrors(undefined);
    if (!json) {
      setErrors(["Please enter import map json"]);
      setStatus(undefined);
      setLoading(false);
      return;
    }
    try {
      const jsonObject = JSON.parse(json);
      setLoading(true);
      const result: ImportProbateMappingResponse =
        await probateApi.importProbateMapping(jsonObject);
      if (!result?.imports?.length && !result?.errors?.length) {
        setErrors(["Sorry, import failed.  Error unknown."]);
        setStatus(undefined);
      } else {
        if (result?.imports) {
          setStatus(result.imports);
          onImportComplete && onImportComplete();
        } else {
          setStatus(undefined);
        }
        if (result?.errors) {
          setErrors(result?.errors);
        } else {
          setErrors(undefined);
        }
      }
    } catch (error) {
      let message;
      if (error?.constructor === SyntaxError) {
        message =
          "Please check your JSON" +
          ((error as Error)?.message ? ": " + (error as Error)?.message : "");
      } else if (axios.isAxiosError(error)) {
        if ((error as any)?.response?.status === 401) {
          window.document.location.href = SCREEN_PATHS.login;
          return;
        }
        message =
          (error as any)?.response?.data?.message ||
          (error as any)?.response?.statusText;
      } else {
        message = (error as Error)?.message || "Sorry, there was an error";
      }
      setErrors([message]);
    }
    setLoading(false);
  };

  return (
    <Box
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-end",
      }}
    >
      {/* TODO: add client-side JSON validation */}
      <TextField
        name="packetMappingJSON"
        label="probate packet mapping json"
        variant="outlined"
        // className={classes.inputs}
        multiline
        minRows={20}
        value={json}
        // error={Boolean(touched.estateJSON && errors.estateJSON)}
        // onBlur={handleBlur}
        onChange={(evt) => {
          setJson(evt.target.value);
          // window.setTimeout(() => {
          //   setIsJSONInvalid(false);
          //   setError("");
          // }, 0);
          // handleChange(evt);
        }}
        style={{ width: "100%", marginBottom: 10 }}
      />
      {errors?.length ? (
        <Typography
          variant="h4"
          color="error"
          style={{
            width: "100%",
          }}
        >
          {`Error${errors.length > 1 ? "s" : ""}:`}
        </Typography>
      ) : null}
      {errors?.map((error: string) => (
        <Typography
          key={`error_${error}`}
          color="error"
          style={{
            width: "100%",
            marginTop: 8,
          }}
        >
          {error}
        </Typography>
      ))}
      {!loading &&
        status?.map((st: string) => (
          <Typography
            key={`status_${st}`}
            color="primary"
            style={{
              width: "100%",
              marginTop: 8,
            }}
          >
            {st}
          </Typography>
        ))}
      <div
        style={{
          display: "flex",
          alignItems: "flex-end",
        }}
      >
        {loading && (
          <LoadingSpinner inCard={false} hideMessage={true} margin="0 16px" />
        )}
        <Button
          variant="contained"
          onClick={handleImport}
          color="primary"
          disabled={loading}
        >
          Import
        </Button>
      </div>
    </Box>
  );
};
