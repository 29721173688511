import React, { useEffect } from "react";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import { StoreState } from "../../../reducers";
import {
  clearTaskPhases,
  fetchTaskPhases,
} from "../../../actions/taskPhasesActions";
import { TaskProgress } from "./tasks";
import { Card, CardContent, Theme } from "@mui/material";
import { TaskUtils } from "./tasks/TaskUtils";
import { TaskPhase } from "../../../models/TaskPhase";
import { withStyles } from "tss-react/mui";
import {
  ContentState,
  ContentStateWrapper,
} from "../../shared/ContentStateWrapper";
import { Estate } from "../../../models/Estate";

const CardStyled = withStyles(Card, (theme: Theme) => ({
  root: {
    [theme.breakpoints.down("lg")]: {
      backgroundColor: "transparent",
      boxShadow: "none",
    },
  },
}));

const CardContentStyled = withStyles(CardContent, (theme: Theme) => ({
  root: {
    padding: 0,
    "&:last-child": {
      paddingBottom: 0,
    },
  },
}));

interface Props {
  estates?: Estate[];
  taskPhases?: TaskPhase[];
  fetchTaskPhases: (estateId: number) => void;
  clearTaskPhases: () => void;
}

const _ProgressView = ({
  estates,
  taskPhases,
  fetchTaskPhases,
  clearTaskPhases,
}: Props): JSX.Element => {
  const { estateId: estateIdString } = useParams();
  const estateId = estateIdString ? parseInt(estateIdString, 10) : undefined;

  let estate: Estate | undefined;
  if (estateId && estates?.length) {
    estate = estates.find((estate: Estate) => estate.id === +estateId);
  }

  useEffect(() => {
    if (estateId && !taskPhases) {
      fetchTaskPhases(estateId);
    }
    return () => {
      clearTaskPhases();
    };
  }, []);

  const prepareTaskPhases = (taskPhases: TaskPhase[]): TaskPhase[] => {
    const cleaned = TaskUtils.cleanUpSequences(
      taskPhases.filter(
        (taskPhase: TaskPhase) => taskPhase.taskPhase !== "Not Apply"
      )
    );
    return cleaned as TaskPhase[];
  };

  return (
    <div data-automation-id="page-content-estate-progress">
      <CardStyled>
        <CardContentStyled>
          <ContentStateWrapper
            contentType="Estate Progress"
            contentTypeSingular="Estate Progress"
            zeroText="It looks like this estate has not made any progress yet."
            zeroImagePrefix="/static/images/zero/estate"
            zeroShowAddButton={false}
            nonContentInCard={false}
            contentState={((): ContentState => {
              if (!taskPhases) {
                return ContentState.loading;
              } else if (taskPhases?.length === 0) {
                return ContentState.zero;
              } else {
                return ContentState.render;
              }
            })()}
          >
            {taskPhases ? (
              <TaskProgress
                estateId={estateId}
                taskPhases={prepareTaskPhases(taskPhases)}
                hasWriteAccess={estate?.hasWriteAccess}
              />
            ) : (
              <></>
            )}
          </ContentStateWrapper>
        </CardContentStyled>
      </CardStyled>
    </div>
  );
};

const mapStateToProps = ({
  estates,
  taskPhases,
}: StoreState): { estates: Estate[]; taskPhases: TaskPhase[] } => {
  return { estates, taskPhases };
};

export const ProgressView = connect(mapStateToProps, {
  fetchTaskPhases,
  clearTaskPhases,
})(_ProgressView);