import React, { useContext, useEffect, useState } from "react";
import {
  Button,
  Dialog,
  Box,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
  useTheme,
  useMediaQuery,
  Breakpoint,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import { AssetsContext } from "./AssetsContext";
import { AssetCategory } from "../../../../models/AssetCategory";
import { IconButton as AtticusIconButton } from "../../../shared/IconButton";
import { AssetSubCategory } from "../../../../models/AssetSubCategory";
import { assetIcons } from "../../../../assets/icons/assets/assetIcons";

interface Props {
  open: boolean;
  onConfirm: (catId?: number, subCatId?: number) => void;
  confirmText?: string;
  onDeny?: () => void;
  denyText?: string;
  maxWidth?: Breakpoint | false;
}

export const CategorySelectDialog = ({
  open,
  onConfirm,
  onDeny,
  maxWidth = "md",
}: Props): JSX.Element => {
  const theme = useTheme();
  const isMobileSize = useMediaQuery(theme.breakpoints.down("sm"));

  const { categories } = useContext(AssetsContext) || {};

  const [categoryId, setCategoryId] = useState<number | undefined>();
  const [confirmedCategoryId, setConfirmedCategoryId] = useState<
    number | undefined
  >();
  const [subCategoryId, setSubCategoryId] = useState<number | undefined>();

  const [error, setError] = useState("");

  useEffect(() => {
    return () => {
      setError("");
      setConfirmedCategoryId(undefined);
    };
  }, []);

  useEffect(() => {
    // complete the screen when sub cat is selected
    subCategoryId && handleNext();
  }, [subCategoryId]);

  const renderCategoryButtons = () => {
    if (!confirmedCategoryId) {
      // get the category buttons
      return categories?.map((cat: AssetCategory) => {
        const icon = (cat?.category && assetIcons[cat.category]) || undefined;
        return (
          <Box
            sx={{
              width: 180,
              height: 180,
              mb: 2,
              mr: 2,
            }}
            key={`cat_select_${cat.category}`}
          >
            <AtticusIconButton
              text={cat.category}
              icon={icon}
              selected={cat.id === categoryId}
              onClick={() => {
                setError("");
                setCategoryId(cat.id);
                // auto-progress to the next screen
                setConfirmedCategoryId(cat.id);
              }}
            />
          </Box>
        );
      });
    } else {
      // get the sub category buttons
      return categories
        ?.find((cat: AssetCategory) => cat.id === confirmedCategoryId)
        ?.subCategories?.map((subCat: AssetSubCategory) => {
          const icon =
            (subCat.subcategory && assetIcons[subCat.subcategory]) || undefined;
          return (
            <Box
              sx={{
                width: 180,
                height: 180,
                mb: 2,
                mr: 2,
              }}
              key={`subcat_select_${subCat.subcategory}`}
            >
              <AtticusIconButton
                text={subCat.subcategory}
                icon={icon}
                selected={subCat.id === subCategoryId}
                onClick={() => setSubCategoryId(subCat.id)}
              />
            </Box>
          );
        });
    }
  };

  const handleNext = () => {
    setError("");
    if (!categoryId) {
      setError("Please select a category");
      return;
    }
    if (!confirmedCategoryId) {
      // must be on cat select
      setConfirmedCategoryId(categoryId);
    } else {
      // must be on subCat select
      onConfirm(categoryId, subCategoryId);
    }
  };

  const title = confirmedCategoryId
    ? "Now select a subcategory"
    : "Select a category";

  return (
    <Dialog
      fullScreen={isMobileSize}
      fullWidth
      maxWidth={maxWidth}
      open={open}
      onClose={onDeny}
      aria-labelledby="details-dialog-title"
      className="noPadding"
      sx={{
        "& .SelectButtonsWrapper": {
          px: 3,
        },
      }}
    >
      {open && (
        <>
          <DialogTitle
            id="details-dialog-title"
            component="div"
            className="centeredText"
          >
            <Box sx={{ p: 2, pb: 0 }}>
              <Typography variant="h2">{title}</Typography>
            </Box>
            <IconButton
              aria-label="close"
              sx={{
                position: "absolute",
                right: 0,
                top: 0,
                padding: "1rem",
                color: "grey.500",
              }}
              onClick={onDeny}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent>
            {confirmedCategoryId ? (
              <Box
                sx={{
                  textAlign: "left",
                  pl: 3,
                  pb: 2,
                }}
              >
                <Button
                  aria-label="back"
                  sx={{
                    padding: "1rem",
                    color: "grey.500",
                  }}
                  onClick={() => {
                    setConfirmedCategoryId(undefined);
                  }}
                >
                  <ChevronLeftIcon /> back
                </Button>
              </Box>
            ) : (
              <></>
            )}
            <Box
              className="SelectButtonsWrapper"
              sx={{
                display: "flex",
                flexWrap: "wrap",
              }}
            >
              {renderCategoryButtons()}
            </Box>
            {error && <Typography color="error">{error}</Typography>}
          </DialogContent>
          <DialogActions
            sx={{
              display: "flex",
              flexDirection: "row-reverse",
              justifyContent: "center",
              ">button": {
                minWidth: 180,
                mx: 2,
              },
            }}
          >
            <Button
              onClick={handleNext}
              color="primary"
              variant="contained"
              sx={{
                width: onDeny ? "inherit" : isMobileSize ? "100%" : "50%",
              }}
            >
              Next
            </Button>
            {onDeny && (
              <Button
                onClick={() => onDeny()}
                color="primary"
                variant="outlined"
              >
                Cancel
              </Button>
            )}
          </DialogActions>
        </>
      )}
    </Dialog>
  );
};
