import React, { useEffect, useRef, useState } from "react";
import { Box, Card, CardHeader, Container, Theme } from "@mui/material";
import { makeStyles } from "tss-react/mui";
import { connect } from "react-redux";
import Page from "../../../components/Page";
import { AdvisorTable } from "./AdvisorTable";
import ContentHeader from "../../shared/ContentHeader";
import { StoreState } from "../../../reducers";
import { ATTICUS_PAGE_PREFIX } from "../../../constants";
import { User } from "../../../models/User";
import { useNavigate } from "react-router-dom";
import { UnassignedEstateTable } from "./UnassignedEstateTable";
import { AdvisorsEstates } from "../../../models/AdvisorsEstates";
import { getQueryStringParams } from "../../../utils/getQueryStringParams";
import { advisorApi } from "../../../apis";
import {
  assignToAdvisor,
  fetchAdvisors,
} from "../../../actions/advisorActions";
import { NotifyUnassignedDialog } from "./NotifyUnassignedDialog";

type ListParams = {
  page?: number;
  perPage?: number;
};

const useStyles = makeStyles()((theme: Theme) => ({
  root: {
    backgroundColor: theme.palette.background.default,
    minHeight: "100%",
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
}));

interface Props {
  user: User;
  fetchAdvisors: () => void;
  assignToAdvisor: (estate: AdvisorsEstates) => void;
}

const _AdvisorListView = ({ user, fetchAdvisors, assignToAdvisor }: Props) => {
  const { classes } = useStyles();
  const navigate = useNavigate();
  const [unassignedEstates, setUnassignedEstates] =
    useState<AdvisorsEstates[]>();
  const [total, setTotal] = useState(0);
  let { page, perPage } = getQueryStringParams();
  page = parseInt(page, 10) || 1;
  perPage = parseInt(perPage, 10) || 10;

  useEffect(() => {
    (async () => {
      getUnassigned();
      fetchAdvisors();
    })();
  }, [page, perPage]);

  // Notify advisor admin that there are unassigned estates
  //  should only be displayed once per screen view
  const unassignedNotificationDisplayed = useRef(false);
  const [notifyUnassignedOpen, setNotifyUnassignedOpen] = useState(false);

  useEffect(() => {
    if (
      !unassignedNotificationDisplayed.current &&
      !notifyUnassignedOpen &&
      unassignedEstates?.length
    ) {
      unassignedNotificationDisplayed.current = true;
      setNotifyUnassignedOpen(true);
    }
  }, [unassignedEstates]);

  const getUnassigned = async () => {
    const offset = (page - 1) * perPage;
    const { total, estates } = await advisorApi.fetchAdvisorsEstates(
      0,
      perPage,
      offset
    );
    setTotal(total || 0);
    setUnassignedEstates(estates);
  };

  const goToUpdatedList = (params: ListParams) => {
    let url = location.pathname;
    url += `?page=${params.page || page}`;
    url += `&perPage=${params.perPage || perPage}`;
    navigate(url);
  };

  const handleAssign = async (
    advisorsEstates?: AdvisorsEstates
  ): Promise<boolean> => {
    if (advisorsEstates && advisorsEstates.advisorUserId) {
      try {
        // network call for persistence
        const newAdvisorsEstates = await advisorApi.assignAdvisor(
          advisorsEstates
        );
        if (newAdvisorsEstates.id) {
          // update client-side store
          newAdvisorsEstates.estateName = advisorsEstates.estateName;
          assignToAdvisor(newAdvisorsEstates);
          // remove from unassignedEstates list
          getUnassigned();
          return true;
        } else {
          throw Error("newAdvisorsEstates has no id");
        }
      } catch (error) {
        // TODO: show error (modal?)
      }
    }
    return false;
  };

  const hasAccess = user.isAdvisorAdmin;

  if (!hasAccess) {
    window.setTimeout(() => {
      navigate(`/app/dashboard`);
    }, 0);
  }

  return (
    <Page
      className={classes.root}
      title={`${ATTICUS_PAGE_PREFIX} - Advisors`}
      data-automation-id="page-content-advisors"
    >
      {hasAccess ? (
        <Container maxWidth={false}>
          <NotifyUnassignedDialog
            open={notifyUnassignedOpen}
            numUnassigned={unassignedEstates?.length}
            onConfirm={() => {
              setNotifyUnassignedOpen(false);
            }}
          />
          <ContentHeader heading="Advisors" showSearch={false} />
          <Box mt={3}>
            <Card>
              <CardHeader
                title="Unassigned Estates"
                sx={{
                  mb: 2,
                }}
              />
              <UnassignedEstateTable
                unassignedEstates={unassignedEstates || []}
                onAssign={handleAssign}
                total={total}
                page={page}
                perPage={perPage}
                onPageChange={(page: number) => {
                  goToUpdatedList({ page });
                }}
                onRowsPerPageChange={(perPage: number) => {
                  goToUpdatedList({ page: 1, perPage });
                }}
              />
            </Card>
          </Box>
          <Box mt={3}>
            <Card sx={{ pb: 1 }}>
              <CardHeader
                title="Advisor Assignments"
                sx={{
                  mb: 2,
                }}
              />
              <AdvisorTable
                onUpdate={() => {
                  getUnassigned();
                }}
              />
            </Card>
          </Box>
        </Container>
      ) : (
        <div />
      )}
    </Page>
  );
};

const mapStateToProps = ({ user }: StoreState): { user: User } => {
  return { user };
};

export const AdvisorListView = connect(mapStateToProps, {
  assignToAdvisor,
  fetchAdvisors,
})(_AdvisorListView);
