import React, { useEffect, useState } from "react";
import { Box, MenuItem, Select, Theme, Typography } from "@mui/material";
import { makeStyles } from "tss-react/mui";
import { Advisor } from "../../../models/Advisor";
import { StoreState } from "../../../reducers";
import { connect } from "react-redux";
import { AdvisorsEstates } from "../../../models/AdvisorsEstates";
import { enumToArrayString } from "../../../utils/enumToArrayString";
import { ConfirmationDialog } from "../../shared/dialog/ConfirmationDialog";
import { DialogMessage } from "../../shared/DialogMessage";
import { BigCircledCheck } from "../../shared/BigCircledCheck";

const useStyles = makeStyles()((theme: Theme) => ({
  root: {},
  selectWrapper: {
    flexDirection: "row",
  },
  select: {
    margin: theme.spacing(0, 1),
  },
}));

enum AccessTypes {
  View = "View",
  Edit = "Edit",
  Remove = "Remove",
}

interface Props {
  advisorsEstates: AdvisorsEstates;
  advisors?: Advisor[];
  advisor?: Advisor;
  onAssign?: (advisorsEstates: AdvisorsEstates) => void;
  onUnassign?: (advisorsEstates: AdvisorsEstates) => void;
}

export const _AssignAdvisorForm = ({
  advisorsEstates,
  advisors,
  advisor,
  onAssign,
  onUnassign,
}: Props): JSX.Element => {
  const { classes } = useStyles();
  // in the unassigned estates table selecting multiple advisors is possible
  const [advisorsAffected, setAdvisorsAffected] = useState<Advisor[]>(
    advisor ? [advisor] : []
  );
  const [access, setAccess] = useState<AccessTypes>();
  const [confirmOpen, setConfirmOpen] = useState(false);

  useEffect(() => {
    setAccess(getInitialAccess());
  }, []);

  const getInitialAccess = (): AccessTypes | undefined => {
    if (advisor) {
      // estates table: pre-select access since advisor known at init
      switch (advisorsEstates.hasWriteAccess) {
        case true:
          return AccessTypes.Edit;
        case false:
          return AccessTypes.View;
        default:
          return undefined;
      }
    } else {
      // unassigned estates table
      return undefined;
    }
  };

  const handleConfirm = () => {
    setConfirmOpen(false);
    advisorsAffected?.forEach((advsr: Advisor) => {
      advisorsEstates.advisorUserId = advsr?.user?.id;
      switch (access) {
        case AccessTypes.View:
          advisorsEstates.hasWriteAccess = false;
          onAssign?.(advisorsEstates);
          break;
        case AccessTypes.Edit:
          advisorsEstates.hasWriteAccess = true;
          onAssign?.(advisorsEstates);
          break;
        case AccessTypes.Remove:
          onUnassign?.(advisorsEstates);
          break;
      }
    });
  };

  return (
    <>
      <ConfirmationDialog
        open={confirmOpen}
        description={
          <DialogMessage
            icon={<BigCircledCheck />}
            title="Please confirm this change"
            body={
              <Typography>
                This will{" "}
                {access === "Remove" ? (
                  <strong>Remove</strong>
                ) : (
                  <>
                    grant <strong>{access}</strong>
                  </>
                )}{" "}
                access to the{" "}
                <strong>
                  {advisorsEstates?.estateName
                    ? `${advisorsEstates?.estateName} estate`
                    : `estate #${advisorsEstates?.estateId}`}
                </strong>{" "}
                for the following advisors:
                <br />
                <br />
                <strong>
                  {advisorsAffected
                    ?.map((advsr: Advisor) => advsr?.user?.selectName)
                    .join(", ")}
                </strong>
                {/*<br />*/}
                {/*<br />*/}
                {/*This action can be changed later, but the effect will be*/}
                {/*immediate.*/}
              </Typography>
            }
          />
        }
        maxWidth="md"
        onConfirm={handleConfirm}
        confirmText="Confirm"
        confirmButtonColor="secondary"
        confirmWide={true}
        onDeny={() => {
          setAccess(undefined);
          setConfirmOpen(false);
        }}
        denyText="Cancel"
        denyButtonVariant="outlined"
        denyButtonColor="secondary"
        denyWide={true}
        buttonOrientation="row-reverse"
      />
      <Box
        sx={{
          display: "flex",
        }}
      >
        {!advisor && advisors?.length && (
          <Select
            size="small"
            multiple
            value={advisorsAffected.map((advsr: Advisor) => advsr?.user?.id)}
            onChange={(event: any) => {
              const ids = event.target.value as number[];
              const newAdvisorsAffected = advisors?.filter(
                (advsr: Advisor) =>
                  advsr?.user?.id && ids.includes(advsr?.user?.id)
              );
              setAdvisorsAffected(newAdvisorsAffected);
            }}
            variant="outlined"
            autoWidth={true}
            displayEmpty={true}
            renderValue={() =>
              advisorsAffected?.length
                ? advisorsAffected.length === 1
                  ? advisorsAffected[0]?.user?.selectName
                  : "Multiple Advisors"
                : "Select Advisor(s)"
            }
            className={classes.select}
          >
            {advisors.map((advsr: Advisor) => {
              return (
                advsr?.user?.id && (
                  <MenuItem
                    value={advsr.user.id}
                    key={
                      "AssignAdvisorForm_" +
                      advisorsEstates?.id +
                      "_" +
                      advsr.user.id
                    }
                  >
                    {advsr.user.selectName}
                  </MenuItem>
                )
              );
            })}
          </Select>
        )}
        <Select
          size="small"
          value={access || ""}
          onChange={(event: any) => {
            setAccess(event.target.value as AccessTypes);
            setConfirmOpen(true);
          }}
          variant="outlined"
          autoWidth={true}
          displayEmpty={true}
          renderValue={() => access || "Select Access"}
          className={classes.select}
          disabled={!advisorsAffected?.length}
        >
          {enumToArrayString(AccessTypes)
            ?.filter((type: string) => {
              // don't show remove option if onUnassign isn't passed in
              return !(type === "Remove" && onUnassign === undefined);
            })
            .map((type: string) => {
              return (
                <MenuItem
                  sx={{
                    borderTop: type === "Remove" ? "solid 1px" : "none",
                    borderColor: "grey.500",
                  }}
                  value={type}
                  key={"AccessType_" + advisorsEstates?.id + "_" + type}
                >
                  {type}
                </MenuItem>
              );
            })}
        </Select>
      </Box>
    </>
  );
};

const mapStateToProps = ({ advisors }: StoreState): { advisors: Advisor[] } => {
  return { advisors };
};

export const AssignAdvisorForm = connect(mapStateToProps)(_AssignAdvisorForm);
