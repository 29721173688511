import React from "react";
import {
  Box,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Theme,
} from "@mui/material";
import { makeStyles } from "tss-react/mui";


const useStyles = makeStyles()((theme: Theme) => ({
  content: {
    padding: theme.spacing(2, 2, 0),
  },
  footer: {
    display: "flex",
    justifyContent: "flex-end",
  },
}));

interface Props {
  title?: string;
  subTitle?: string;
  actionComponent?: JSX.Element;
  children?: JSX.Element;
  footerComponent?: JSX.Element;
  topRightComponent?: JSX.Element;
  backgroundColor?: string;
}

export const DetailCard = ({
  title,
  subTitle,
  actionComponent,
  children,
  footerComponent,
  topRightComponent,
  backgroundColor = "auto",
}: Props): JSX.Element => {
  const { classes } = useStyles();
  return (
    <Card
      style={{
        position: "relative",
        backgroundColor,
      }}
    >
      {topRightComponent && (
        <Box
          style={{
            position: "absolute",
            top: 10,
            right: 10,
          }}
        >
          {topRightComponent}
        </Box>
      )}
      <CardHeader action={actionComponent} title={title} subheader={subTitle} />
      <CardContent className={classes.content}>{children}</CardContent>
      <CardActions className={classes.footer}>{footerComponent}</CardActions>
    </Card>
  );
};
