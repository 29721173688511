import React from "react";
import { Box, Theme, Typography } from "@mui/material";
import { makeStyles } from "tss-react/mui";
import { StoreState } from "../../../../reducers";
import { connect } from "react-redux";
import { FinancialConvo } from "../../../../models/FinancialConvo";
import { OpportunityType } from "../../../../models/OpportunityType";
import { OpportunityStatus } from "../../../../models/OpportunityStatus";
import {
  financialFeelingsOutcomes,
  financialExperienceOutcomes,
  discussionTopicsOutcomes,
  assetsOutcomes,
  beneRelationshipStatusOutcomes,
} from "./FinancialConvoMappings";

const useStyles = makeStyles()((theme:Theme) => { return {
  questionText: {
    marginTop: "2rem",
    marginBottom: "0.5rem",
    color: "#2D2A5C",
    fontWeight: 600,
  },
  emotionAnswer: {
    verticalAlign: "middle",
    display: "inline-flex",
    alignItems: "center",
    gap: "0.5rem",
  },
  answerText: {
    color: "#393A3D",
    fontWeight: 400,
  },
}});

interface Props {
  financialConvo?: FinancialConvo;
}

const _FinancialConvoSummary = ({ financialConvo }: Props): JSX.Element => {
  const { classes } = useStyles();

  if (financialConvo) {
    return (
      <Box>
        {/* Financial Feelings */}
        <Typography variant="h5" className={classes.questionText}>
          How they're feeling about the financial side of things:
        </Typography>
        {financialConvo.financialFeelings ? (
          <Typography
            className={`${classes.answerText} ${classes.emotionAnswer}`}
          >
            {financialFeelingsOutcomes[financialConvo.financialFeelings].icon}
            {financialFeelingsOutcomes[financialConvo.financialFeelings].text}
          </Typography>
        ) : (
          <Typography className={classes.answerText}>{"--"}</Typography>
        )}

        {/* Financial Experience */}
        <Typography variant="h5" className={classes.questionText}>
          Experience level with investing, managing, and maintaining investment
          accounts:
        </Typography>
        {financialConvo.financialExperience ? (
          <Typography
            className={`${classes.answerText} ${classes.emotionAnswer}`}
          >
            {
              financialExperienceOutcomes[financialConvo.financialExperience]
                .icon
            }
            {
              financialExperienceOutcomes[financialConvo.financialExperience]
                .text
            }
          </Typography>
        ) : (
          <Typography className={classes.answerText}>{"--"}</Typography>
        )}

        {/* Declared Assets */}
        <Typography variant="h5" className={classes.questionText}>
          Assets that belong to the estate:
        </Typography>
        {financialConvo.declaredTypeOfAssets &&
        financialConvo.declaredTypeOfAssets.length > 0 ? (
          <ul>
            {financialConvo.declaredTypeOfAssets?.map((asset, index) => {
              return (
                <li key={index}>
                  <Typography className={classes.answerText}>
                    {assetsOutcomes[asset].text}
                  </Typography>
                </li>
              );
            })}
          </ul>
        ) : (
          <Typography className={classes.answerText}>{"--"}</Typography>
        )}

        {/* Discussion Topics */}
        <Typography variant="h5" className={classes.questionText}>
          Discussion Topics:
        </Typography>
        {financialConvo.discussionTopics &&
        financialConvo.discussionTopics.length > 0 ? (
          <ul>
            {financialConvo.discussionTopics?.map((topic, index) => {
              return (
                <li key={index}>
                  <Typography className={classes.answerText}>
                    {discussionTopicsOutcomes[topic].text}
                  </Typography>
                </li>
              );
            })}
          </ul>
        ) : (
          <Typography className={classes.answerText}>{"--"}</Typography>
        )}

        {/* Discussion Topics - Something Else? */}

        <Typography variant="h5" className={classes.questionText}>
          Additional custom discussion topics:
        </Typography>
        <Typography className={classes.answerText}>
          {financialConvo.discussionTopics?.includes("somethingElse")
            ? financialConvo.discussionTopicsSomethingElse
              ? financialConvo.discussionTopicsSomethingElse
              : "--"
            : "N/A"}
        </Typography>

        {/* Bene Relationship Status */}
        <Typography variant="h5" className={classes.questionText}>
          How are the relationships between the beneficiaries?
        </Typography>
        {financialConvo.beneRelationshipStatus ? (
          <Typography
            className={`${classes.answerText} ${classes.emotionAnswer}`}
          >
            {
              beneRelationshipStatusOutcomes[
                financialConvo.beneRelationshipStatus
              ].icon
            }
            {
              beneRelationshipStatusOutcomes[
                financialConvo.beneRelationshipStatus
              ].text
            }
          </Typography>
        ) : (
          <Typography className={classes.answerText}>{"--"}</Typography>
        )}

        {/* Anything Else */}
        <Typography variant="h5" className={classes.questionText}>
          Anything else they want you to know:
        </Typography>
        <Typography className={classes.answerText}>
          {financialConvo.anythingElseToKnow || "--"}
        </Typography>
      </Box>
    );
  } else {
    return (
      <Box>
        <Typography>Loading...</Typography>
      </Box>
    );
  }
};

const mapStateToProps = ({
  opportunityTypes,
  opportunityStatuses,
}: StoreState): {
  opportunityTypes: OpportunityType[];
  opportunityStatuses: OpportunityStatus[];
} => {
  return { opportunityTypes, opportunityStatuses };
};

export const FinancialConvoSummary = connect(mapStateToProps)(
  _FinancialConvoSummary
);
