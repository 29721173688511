import React, { useEffect, useState } from "react";
import {
  Box,
  Breakpoint,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import Carousel from "react-material-ui-carousel";
import CloseIcon from "@mui/icons-material/Close";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import { AssetImage } from "../../../../models/AssetImage";
import { AsyncAssetImage, Dimensions } from "./AsyncAssetImage";

interface Props {
  images?: AssetImage[];
  open?: boolean;
  onCancel?: () => void;
  onDelete?: (image: AssetImage) => void;
  startIndex?: number;
  maxWidth?: Breakpoint | false;
}

export const ImageCarouselDialog = ({
  images,
  open = false,
  onCancel,
  onDelete,
  startIndex = 0,
  maxWidth = "lg",
}: Props): JSX.Element => {
  const theme = useTheme();
  const isMobileSize = useMediaQuery(theme.breakpoints.down("sm"));
  const [imageIndex, setImageIndex] = useState<number | undefined>(startIndex);

  useEffect(() => {
    setImageIndex(0);
  }, [images?.length]);

  const keyListener = (event: KeyboardEvent) => {
    if (imageIndex !== undefined && images?.length) {
      switch (event.key) {
        case "ArrowRight":
          setImageIndex((prevIndex = 0) => {
            return prevIndex < images.length - 1 ? prevIndex + 1 : 0;
          });
          break;
        case "ArrowLeft":
          setImageIndex((prevIndex = 0) => {
            return prevIndex > 0 ? prevIndex - 1 : images.length - 1;
          });
          break;
      }
    }
  };

  useEffect(() => {
    window?.addEventListener("keydown", keyListener);
    return () => {
      window?.removeEventListener("keydown", keyListener);
    };
  }, []);

  return (
    <Dialog
      fullScreen={isMobileSize}
      fullWidth
      maxWidth={maxWidth}
      open={open}
      onClose={onCancel}
      className="noPadding"
      sx={{
        backgroundColor: "rgba(0,0,0,0.8)",
        "& .MuiDialog-paper": {
          backgroundColor: "common.black",
        },
      }}
    >
      <DialogTitle component="div" sx={{ color: "inherit", mt: 4 }}>
        <IconButton
          aria-label="close"
          sx={{
            position: "absolute",
            right: 0,
            top: 0,
            padding: "1rem",
            color: "grey.500",
          }}
          onClick={onCancel}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent sx={{ mb: 0 }}>
        {images?.length && images.length > 0 ? (
          <>
            {imageIndex !== undefined ? (
              <Box sx={{ mb: 2, position: "relative" }}>
                <Typography sx={{ color: "common.white" }}>
                  {imageIndex + 1} of {images?.length} photos
                </Typography>
                {onDelete && (
                  <IconButton
                    aria-label="delete"
                    sx={{
                      position: "absolute",
                      right: 0,
                      top: 0,
                      color: "common.white",
                    }}
                    onClick={() => {
                      onDelete(images[imageIndex]);
                    }}
                  >
                    <DeleteForeverIcon />
                  </IconButton>
                )}
              </Box>
            ) : (
              <></>
            )}
            <Carousel
              index={imageIndex}
              onChange={(now?: number) => setImageIndex(now)}
              indicators={false}
              navButtonsAlwaysVisible={images.length > 1}
              autoPlay={false}
              sx={{
                "& button svg.MuiSvgIcon-root": {
                  width: "2em",
                  height: "2em",
                },
              }}
            >
              {images.map((assetImage: AssetImage, index: number) => (
                <Box
                  key={`Carousel_${assetImage?.fileName}_${
                    assetImage?.id || index
                  }`}
                >
                  <AsyncAssetImage
                    assetImage={assetImage}
                    title={assetImage?.fileName}
                    dimensions={Dimensions.x1440}
                  />
                </Box>
              ))}
            </Carousel>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                my: 1,
              }}
            >
              <Box sx={{ display: "flex", flexWrap: "wrap" }}>
                {images.map((assetImage: AssetImage, index: number) => (
                  <Box
                    sx={{ width: 110, height: 110, cursor: "pointer" }}
                    key={`thumbnail_${assetImage?.fileName}_${
                      assetImage?.id || index
                    }`}
                    onClick={() => setImageIndex(index)}
                  >
                    <AsyncAssetImage
                      assetImage={assetImage}
                      title={assetImage?.fileName}
                      sx={{
                        width: 100,
                        height: 100,
                      }}
                      dimensions={Dimensions.x150}
                    />
                  </Box>
                ))}
              </Box>
            </Box>
          </>
        ) : (
          <Typography>No asset images</Typography>
        )}
      </DialogContent>
    </Dialog>
  );
};
