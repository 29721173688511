import React, { ReactElement } from "react";
import { NavLink as RouterLink } from "react-router-dom";
// import { Icon as RFIcon } from 'react-feather';
import {
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";

interface Props {
  path: string;
  title: string;
  className?: string;
  key?: string;
  icon?: ReactElement;
  rest?: any[];
}

const NavItem = ({
  className,
  path,
  icon,
  title,
  ...rest
}: Props): JSX.Element => {
  return (
    <ListItem
      className={className}
      disableGutters
      {...rest}
      sx={{
        display: "flex",
        py: 0,
        mb: 1,
      }}
    >
      <ListItemButton
        component={RouterLink}
        to={path}
        disableRipple={true}
        sx={{
          color: "primary.light",
          fontWeight: 700,
          height: "2.75rem",
          justifyContent: "flex-start",
          letterSpacing: 0,
          textTransform: "none",
          width: "100%",
          transition:
            "background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
          borderRadius: 0,
          "&:hover": {
            color: "primary.main",
            backgroundColor: "transparent",
          },
          "&.active": {
            color: "primary.main",
            borderRight: "solid 4px",
            borderColor: "primary.main",
            "& span": {
              fontWeight: 700,
              color: "primary.main",
            },
          },
        }}
      >
        <ListItemIcon
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            color: "inherit",
          }}
        >
          {icon}
        </ListItemIcon>
        <ListItemText primary={title} />
      </ListItemButton>
    </ListItem>
  );
};

export default NavItem;
