import { AssetCategory } from "./AssetCategory";

export type AssetSubCategoryJSON = {
  id?: number;
  subcategory?: string;
};

export class AssetSubCategory {
  constructor(
    public id?: number,
    public subcategory?: string,
    public category?: AssetCategory
  ) {}
  static fromJSON = (json: AssetSubCategoryJSON): AssetSubCategory => {
    return new AssetSubCategory(json.id, json.subcategory);
  };
}
