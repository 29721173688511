import { ActionTypes } from "../actions/types";
import { AdvisorGroup } from "../models/AdvisorGroup";
import { AnyAction } from "redux";

export const advisorGroupsReducer = (
  advisorGroups: AdvisorGroup[] | null = null,
  action: AnyAction
): AdvisorGroup[] | null => {
  switch (action.type) {
    case ActionTypes.ClearAdvisorGroups:
    case ActionTypes.FetchAdvisorGroups:
      return action.payload;
    case ActionTypes.SaveAdvisorGroup:
      // if advisor group exists in store updated it, otherwise add it
      if (advisorGroups && action.payload.advisorGroup) {
        return [...advisorGroups, action.payload.advisorGroup];
      }
      return advisorGroups;
    case ActionTypes.AppSignOut:
      return null;
    default:
      return advisorGroups;
  }
};
