import React, { useEffect } from "react";
import {
  Box,
  Card,
  CardContent,
  Container,
  IconButton,
  Theme,
  Typography,
} from "@mui/material";
import { makeStyles } from "tss-react/mui";
import { connect } from "react-redux";
import Page from "../../../components/Page";
import EstateTable from "./EstateTable";
import ContentHeader from "../../shared/ContentHeader";
import { StoreState } from "../../../reducers";
import { fetchEstates, searchEstates } from "../../../actions/estateActions";
import { Estate } from "../../../models/Estate";
import {
  ATTICUS_PAGE_PREFIX,
  ESTATE_SORT_BY,
  ESTATE_SORT_DIR,
  SCREEN_PATHS,
} from "../../../constants";
import { getQueryStringParams } from "../../../utils/getQueryStringParams";
import { EstateSearch } from "../../../reducers/estateSearchCache";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { useNavigate } from "react-router-dom";
import { setSearchTerms } from "../../../actions/appActions";
import { AppState } from "../../../reducers/app";
import { User } from "../../../models/User";
import { AdvisorGroup } from "../../../models/AdvisorGroup";
import { EstatesFilter } from "./EstatesFilter";

const useStyles = makeStyles()((theme: Theme) => ({
  root: {
    backgroundColor: theme.palette.background.default,
    minHeight: "100%",
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
  resultsHeadingWrapper: {
    display: "flex",
    alignItems: "center",
  },
  resultsHeading: {
    marginRight: theme.spacing(1),
  },
}));

interface Props {
  app: AppState;
  user: User;
  estates: Estate[];
  estateSearchCache: EstateSearch[];
  fetchEstates: (
    limit?: number,
    offset?: number,
    sort?: string,
    dir?: string,
    includeDeleted?: boolean,
    gid?: number
  ) => void;
  searchEstates: (terms: string, cacheKey: string, groupId?: number) => void;
  advisorGroups: AdvisorGroup[];
}

type ListParams = {
  page?: number;
  perPage?: number;
  sort?: string;
  dir?: string;
  includeDeleted?: boolean;
  advisorGroupId?: number;
};

const _EstateListView = ({
  app,
  user,
  estates,
  estateSearchCache,
  fetchEstates,
  searchEstates,
  advisorGroups,
}: Props) => {
  const initPerPage = 25;
  const { classes } = useStyles();
  const navigate = useNavigate();
  const { terms, gid } = getQueryStringParams();
  let { page, perPage, sort, dir, includeDeleted, advisorGroupId } = getQueryStringParams();
  page = parseInt(page, 10) || 1;
  perPage = parseInt(perPage, 10) || initPerPage;
  sort = sort || ESTATE_SORT_BY.activity;
  dir = dir || ESTATE_SORT_DIR.dsc;
  includeDeleted = includeDeleted === "true" || false;
  advisorGroupId = parseInt(advisorGroupId, 10) || 0;

  useEffect(() => {
    if (!terms) {
      const offset = (page - 1) * perPage;
      fetchEstates(perPage, offset, sort, dir, includeDeleted, advisorGroupId);
    }
  }, [page, perPage, sort, dir, includeDeleted, advisorGroupId]);

  let estateSearchResults: EstateSearch[] = [];
  if (terms) {
    // check the cache
    // cacheKey is all the inputs that make this query unique: terms, gid
    let cacheKey = terms;
    gid ? (cacheKey += `+${gid}`) : null;
    estateSearchResults = estateSearchCache?.filter(
      (estateSearch: EstateSearch) => estateSearch.cacheKey === cacheKey
    );
    if (!estateSearchResults || estateSearchResults.length === 0) {
      searchEstates(terms, cacheKey, gid); // start the search. results to be in redux store
    }
  }
  const estateSearchResult = estateSearchResults?.length
    ? estateSearchResults[0]
    : undefined;

  const handleClear = () => {
    setSearchTerms("");
    navigate(SCREEN_PATHS.estates);
  };

  const handlePageChange = (page: number) => {
    goToUpdatedList({ page });
  };

  const handlePerPageChange = (perPage: number) => {
    goToUpdatedList({ page: 1, perPage });
  };

  const handleSortChange = (sort: string, dir: string) => {
    goToUpdatedList({ dir, sort });
  };

  const handleIncludeDeletedChange = (includeDeleted: boolean) => {
    goToUpdatedList({ page: 1, includeDeleted });
  };

  const handleAdvisorGroupChange = (advisorGroupId: number) => {
    goToUpdatedList({ page: 1, advisorGroupId });
  };

  const goToUpdatedList = (params: ListParams) => {
    let url = SCREEN_PATHS.estates;
    url += `?page=${params.page || page}`;
    url += `&perPage=${params.perPage || perPage}`;
    url += `&sort=${params.sort || sort}`;
    url += `&dir=${params.dir || dir}`;
    if (user.isUberAdmin) {
      url += `&includeDeleted=${
        params.includeDeleted !== undefined
          ? params.includeDeleted
          : includeDeleted
      }`;
      url += `&advisorGroupId=${params.advisorGroupId || advisorGroupId}`;
    }
    navigate(url);
  };

  return (
    <Page
      className={classes.root}
      title={`${ATTICUS_PAGE_PREFIX} - Estates`}
      data-automation-id="page-content-estate-list"
    >
      <Container maxWidth={false}>
        <ContentHeader heading="Estates" showInvite />
        {user.isUberAdmin && !terms && (
          <EstatesFilter
            advisorGroups={advisorGroups}
            advisorGroupId={advisorGroupId}
            onSelect={handleAdvisorGroupChange}
          />
        )}
        <Box mt={3}>
          <Card>
            <CardContent>
              {terms ? (
                <>
                  <div className={classes.resultsHeadingWrapper}>
                    <h3 className={classes.resultsHeading}>
                      Results for "{terms}"
                    </h3>
                    <IconButton
                      onClick={() => handleClear()}
                      size="small"
                      color="primary"
                    >
                      <HighlightOffIcon />
                    </IconButton>
                  </div>
                  <EstateTable
                    estates={estateSearchResult?.estates || []}
                    searchResult={true}
                    // onSort={handleSortChange}
                    // sort={sort}
                    // dir={dir}
                    isUber={user.isUberAdmin}
                  />
                </>
              ) : (
                <EstateTable
                  total={app.totalEstates}
                  estates={estates}
                  page={page}
                  perPage={perPage}
                  onPageChange={handlePageChange}
                  onPerPageChange={handlePerPageChange}
                  includeDeleted={includeDeleted}
                  onIncludeDeletedChange={handleIncludeDeletedChange}
                  onSort={handleSortChange}
                  sort={sort}
                  dir={dir}
                  searchResult={false}
                  isUber={user.isUberAdmin}
                />
              )}
            </CardContent>
          </Card>
          {user.isUberAdmin ? (
            <Box m={2}>
              <Typography variant="body2" color="textSecondary">
                * demo estate
              </Typography>
            </Box>
          ) : (
            <></>
          )}
        </Box>
      </Container>
    </Page>
  );
};

const mapStateToProps = ({
  app,
  user,
  estates,
  estateSearchCache,
  advisorGroups,
}: StoreState): {
  app: AppState;
  user: User;
  estates: Estate[];
  estateSearchCache: EstateSearch[];
  advisorGroups: AdvisorGroup[];
} => {
  return { app, user, estates, estateSearchCache, advisorGroups };
};

export const EstateListView = connect(mapStateToProps, {
  fetchEstates,
  searchEstates,
})(_EstateListView);
