import {
  Button,
  FormControl,
  MenuItem,
  Select,
  Theme,
  Typography,
} from "@mui/material";
import { makeStyles } from "tss-react/mui";
import React, { useState } from "react";
import { AdvisorGroupAccess } from "../../../models/AdvisorGroup";

const useStyles = makeStyles()((theme: Theme) => ({
  root: {},
  selectWrapper: {
    flexDirection: "row",
    alignItems: "center",
  },
  select: {
    margin: theme.spacing(0, 1),
    minWidth: 120,
  },
  status: {
    padding: theme.spacing(0, 1),
  },
}));

interface Props {
  onUpdate: (accessId: number) => Promise<boolean>;
  accessLevels?: AdvisorGroupAccess[];
  accessId?: number;
}

export const AssignGroupAccessForm = ({
  onUpdate,
  accessLevels,
  accessId,
}: Props): JSX.Element => {
  const { classes } = useStyles();
  const [status, setStatus] = useState("");
  const [newAccessId, setNewAccessId] = useState(accessId || 1);
  const handleChange = (event: any) => {
    setNewAccessId(event.target.value as number);
  };

  return (
    <FormControl className={classes.selectWrapper}>
      {accessLevels && (
        <Select
          value={newAccessId}
          onChange={handleChange}
          variant="outlined"
          displayEmpty={true}
          className={classes.select}
        >
          {accessLevels?.map((level: AdvisorGroupAccess) => {
            return (
              <MenuItem value={level.id} key={level.id}>
                {level.access}
              </MenuItem>
            );
          })}
        </Select>
      )}
      <Button
        variant="contained"
        size="small"
        onClick={async () => {
          setStatus("");
          if (await onUpdate(newAccessId)) {
            setStatus("success!✌️");
            window.setTimeout(() => {
              setStatus("");
            }, 2500);
          } else {
            setStatus("failed😞");
          }
        }}
        disabled={newAccessId === 0}
      >
        save
      </Button>
      <Typography variant={"body2"} className={classes.status}>
        {status}
      </Typography>
    </FormControl>
  );
};
