export type EstateSummaryJSON = {
  assets_value_total?: number;
  assets_value?: AssetsMap;
  assets_inventoried?: AssetsMap;
  num_tasks_assigned?: number;
  num_tasks_completed?: number;
  percent_complete?: number;
  num_opportunities?: number;
};

export type AssetsMap = {
  total?: number;
  household?: number;
  financial?: number;
  realEstate?: number;
  vehicle?: number;
  digital?: number;
  other?: number;
  other2?: number;
};

export type EstatesSummary = {
  estates_total_value?: number;
  num_estates?: number;
  num_opportunities?: number;
  num_tasks_completed?: number;
  total_assets_inventoried?: AssetsMap;
  total_assets_value?: AssetsMap;
};

export class EstateSummary {
  constructor(
    public estateId?: number,
    public totalAssetsValue?: number,
    public assetsValue?: AssetsMap,
    public assetsInventoried?: AssetsMap,
    public tasksAssigned?: number,
    public tasksCompleted?: number,
    public percentCompleted?: number,
    public num_opportunities?: number
  ) {}

  static fromJSON(
    estateId?: number,
    estateSummary?: EstateSummaryJSON
  ): EstateSummary {
    if (estateId && estateSummary) {
      return new EstateSummary(
        estateId,
        estateSummary.assets_value_total,
        estateSummary.assets_value,
        estateSummary.assets_inventoried,
        estateSummary.num_tasks_assigned,
        estateSummary.num_tasks_completed,
        estateSummary.percent_complete,
        estateSummary.num_opportunities
      );
    } else {
      return new EstateSummary();
    }
  }
}
