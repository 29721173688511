import React from "react";
import { Container, Theme } from "@mui/material";
import { makeStyles } from "tss-react/mui";
import Page from "../../../components/Page";
import ContentHeader from "../../shared/ContentHeader";
import { StoreState } from "../../../reducers";
import { connect } from "react-redux";
import { ATTICUS_PAGE_PREFIX } from "../../../constants";
import { Opportunity } from "../../../models/Opportunity";
import { EditableOpportunities } from "../EditableOpportunities";
import { OpportunityStatus } from "../../../models/OpportunityStatus";

const useStyles = makeStyles()((theme: Theme) => ({
  root: {
    backgroundColor: theme.palette.background.default,
    minHeight: "100%",
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
}));

interface Props {
  opportunities?: Opportunity[];
  opportunitiesCount?: number;
  opportunityStatuses?: OpportunityStatus[],
}

const _AllOpportunitiesView = ({ opportunities, opportunitiesCount, opportunityStatuses }: Props): JSX.Element => {
  const { classes } = useStyles();

  return (
    <>
      <Page
        className={classes.root}
        title={`${ATTICUS_PAGE_PREFIX} - Opportunities`}
        data-automation-id="page-content-opportunities"
      >
        <Container maxWidth={false}>
          <ContentHeader
            heading="Opportunities"
            subHeading="Last updated 2/17/20 at 9:41am"
            showInvite={true}
          />
            <EditableOpportunities
              estate={undefined}
              disableAdd={true}
            />
        </Container>
      </Page>
    </>
  );
};

const mapStateToProps = ({
  opportunities,
  opportunitiesCount,
  opportunityStatuses,
}: StoreState): {
  opportunities: Opportunity[],
  opportunitiesCount: number,
  opportunityStatuses: OpportunityStatus[],
} => {
  return { opportunities, opportunitiesCount, opportunityStatuses };
};

export const AllOpportunitiesView = connect(mapStateToProps)(
  _AllOpportunitiesView
);
