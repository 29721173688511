import React, { useState } from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  TextField,
  Typography,
  Select,
  MenuItem,
  Grid,
  InputAdornment,
  FormControl,
  FormControlLabel,
  FormLabel,
  RadioGroup,
  Radio,
  Theme,
} from "@mui/material";
import { makeStyles } from "tss-react/mui";
import { callPut, callGet, callDelete, callPost } from "../../../apis";
import JSONPretty from "react-json-pretty";
import Editor from "@monaco-editor/react";
import { AxiosResponse } from "axios";
import { LARAVEL_APP_HOST } from "../../../constants";

const useStyles = makeStyles()((theme:Theme) => {return{
  endpointBody: {},
  endpointRoute: { flex: 1 },
  httpDropdown: {},
  endpointSettingsTopContainer: {
    display: "flex",
    gap: "1rem",
  },
  selectUrl: {},
  jsonPretty: {
    overflow: "auto",
    maxHeight: "50rem",
    backgroundColor: "#f0f0f0",
    padding: "1rem",
    borderRadius: "0.25rem",
  },
  buttonsBox: {
    display: "flex",
    gap: "1rem",
  },
}});

enum httpRequests {
  GET = "GET",
  POST = "POST",
  PUT = "PUT",
  DELETE = "DELETE",
}

const httpRequestsArray = Object.values(httpRequests);

const serviceUrls: { [key: string]: string } = {
  advisorV1: LARAVEL_APP_HOST + "/advisor" + "/v1",
  appV1: LARAVEL_APP_HOST + "/api" + "/v1",
  appV2: LARAVEL_APP_HOST + "/api" + "/v2",
  formsAppV1: LARAVEL_APP_HOST + "/forms-app" + "/v1",
};

export const ExploratoryView = (): JSX.Element => {
  const { classes } = useStyles();
  const [updateLimit, setUpdateLimit] = useState(2);

  const [serviceUrl, setServiceUrl] = useState<string>(serviceUrls.advisorV1);

  const [httpRequest, setHttpRequest] = useState<httpRequests>(
    httpRequests.GET
  );
  const [endpointRoute, setEndpointRoute] = useState<string>("");
  const [endpointBody, setEndpointBody] = useState<string>("{}");
  const [apiResponse, setApiResponse] = useState<string | undefined>("");

  const handleEndpointRouteChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setEndpointRoute(event.target.value);
  };

  const handleChangeServiceUrl = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setServiceUrl(event.target.value);
  };

  return (
    <div data-automation-id="page-content-endpoint-tester">
      <Box my={2}>
        <Typography variant="h2">Warning</Typography>
        <Typography>
          The following features modify the database to solve certain use cases.
          Don't use them unless you know what exactly they do. Go read the code.
        </Typography>
      </Box>
      <Box my={2}>
        <Card>
          <CardHeader
            title="Update estate summary fields"
            subheader="assets_value, percent_completed, etc."
          />
          <CardContent>
            <TextField
              label="Update limit"
              name="updateLimit"
              // type="email"
              variant="outlined"
              value={updateLimit}
              onChange={(evt) => {
                setUpdateLimit(parseInt(evt.target.value, 10));
              }}
            />
            <Button
              variant="contained"
              color="inherit"
              onClick={async () => {
                if (updateLimit) {
                  await callPut("estates/update_summaries", {
                    limit: updateLimit,
                  });
                }
              }}
            >
              estates/update_summaries
            </Button>
          </CardContent>
        </Card>
      </Box>
      <Box my={2}>
        <Card>
          <CardHeader
            title="Migrate estates"
            subheader="create estates_users record for estates with only estates.user_id"
          />
          <CardContent>
            <TextField
              label="Update limit"
              name="updateLimit"
              // type="email"
              variant="outlined"
              value={updateLimit}
              onChange={(evt) => {
                setUpdateLimit(parseInt(evt.target.value, 10));
              }}
            />
            <Button
              variant="contained"
              color="inherit"
              onClick={async () => {
                if (updateLimit) {
                  await callPut("estates/migrate", {
                    limit: updateLimit,
                  });
                }
              }}
            >
              estates/migrate
            </Button>
          </CardContent>
        </Card>
      </Box>
      <Box my={2}>
        <Card>
          <CardHeader
            title="Endpoint Tester"
            subheader="Manually call an endpoint here!"
          />
          <CardContent>
            <Grid container direction="column" spacing={2}>
              <Grid item container spacing={2} xs={12} md={12}>
                <Grid item xs={12} className={classes.selectUrl}>
                  <FormControl component="fieldset">
                    <FormLabel component="legend">Service URL</FormLabel>
                    <RadioGroup
                      aria-label="gender"
                      name="gender1"
                      value={serviceUrl}
                      onChange={handleChangeServiceUrl}
                    >
                      {Object.keys(serviceUrls).map((url, index) => {
                        return (
                          <FormControlLabel
                            key={index}
                            value={serviceUrls[url]}
                            control={<Radio />}
                            label={`${url} - ${serviceUrls[url]}`}
                          />
                        );
                      })}
                    </RadioGroup>
                  </FormControl>
                </Grid>
                <Grid
                  item
                  xs={12}
                  className={classes.endpointSettingsTopContainer}
                >
                  <Select
                    value={httpRequest}
                    variant="outlined"
                    onChange={(event) => {
                      setHttpRequest(event.target.value as httpRequests);
                    }}
                    className={classes.httpDropdown}
                  >
                    {httpRequestsArray.map((request, index) => (
                      <MenuItem key={index} value={request}>
                        {request}
                      </MenuItem>
                    ))}
                  </Select>
                  <TextField
                    label="Endpoint Route"
                    name="endpointRoute"
                    variant="outlined"
                    value={endpointRoute}
                    onChange={handleEndpointRouteChange}
                    className={classes.endpointRoute}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">/</InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  {!(
                    httpRequest === httpRequests.GET ||
                    httpRequest === httpRequests.DELETE
                  ) && (
                    <Editor
                      height="25rem"
                      defaultLanguage="json"
                      defaultValue={endpointBody}
                      onChange={(newVal) => {
                        setEndpointBody(newVal ? newVal : "{}");
                      }}
                    />
                  )}
                </Grid>
                <Grid item className={classes.buttonsBox}>
                  <Button
                    variant="contained"
                    color="inherit"
                    onClick={async () => {
                      setApiResponse(undefined);

                      const axiosConfig = {
                        baseURL: serviceUrl,
                      };

                      let response:AxiosResponse<any,any>|undefined|null = undefined;

                      try {
                        if (httpRequest == httpRequests.GET) {
                          response = await callGet(
                            endpointRoute,
                            true,
                            axiosConfig
                          );
                        } else if (httpRequest == httpRequests.POST) {
                          response = await callPost(
                            endpointRoute,
                            JSON.parse(endpointBody || "{}"),
                            true,
                            axiosConfig
                          );
                        } else if (httpRequest == httpRequests.PUT) {
                          response = await callPut(
                            endpointRoute,
                            JSON.parse(endpointBody || "{}"),
                            true,
                            axiosConfig
                          );
                        } else if (httpRequest == httpRequests.DELETE) {
                          response = await callDelete(
                            endpointRoute,
                            true,
                            axiosConfig
                          );
                        }
                        console.log("response", response);
                        if (response && response.data) {
                          setApiResponse(response.data);
                        }
                      } catch (error: any) {
                        setApiResponse(
                          "ERROR!\n\n" +
                            JSON.stringify(error.response.data, null, 2)
                        );
                      }
                    }}
                  >
                    Send Request
                  </Button>
                  <Button
                    variant="outlined"
                    onClick={() => {
                      setApiResponse(undefined);
                    }}
                  >
                    Clear Response
                  </Button>
                </Grid>
              </Grid>
              {apiResponse && (
                <Grid item xs={12}>
                  <Box>
                    <Typography>Response:</Typography>

                    <Box className={classes.jsonPretty}>
                      <JSONPretty data={apiResponse} />
                    </Box>
                  </Box>
                </Grid>
              )}
            </Grid>
          </CardContent>
        </Card>
      </Box>
    </div>
  );
};
