export const nameSuffixes = [
  "Jr.",
  "Sr.",
  "2nd",
  "3rd",
  "II",
  "III",
  "IV",
  "V",
  "VI",
  "Esq.",
  "CPA",
  "DC",
  "DDS",
  "VM",
  "JD",
  "MD",
  "PhD",
];

export const booleanVerbose = ["Yes", "No", "I'm not sure"];

export const declaredRoles = [
  "Executor",
  "Co-executor",
  "Corporate executor",
  "Personal representative",
  "Beneficiary or Heir",
];

export const advisoryPositions = [
  "Attorney",
  "Accountant",
  "Financial advisor",
  "Probate clerk",
  "Real estate agent",
  "Insurance agent",
  "Something else",
];

export const relationshipTypes = [
  "Spouse",
  "Child",
  "Parent",
  "Sibling",
  "Other family member",
  "Not related",
  "Something else",
];
