import { LARAVEL_APP_SERVICE_PATH } from "../constants";

export type AssetImagesJSON = {
  id?: number;
  asset_id?: number;
  url?: string;
  file_name?: string;
};

export class AssetImage {
  static path = "/images";
  static getPath = "/asset/image";
  constructor(
    public id?: number,
    public index?: number,
    public assetId?: number,
    public estateId?: number,
    public fileName?: string,
    public extension?: string,
    public url?: string,
    public base64Data?: string
  ) {}
  static fromJSON = (
    json: AssetImagesJSON,
    index: number,
    estateId?: number
  ): AssetImage => {
    const extension = json?.url?.split(".")[1];
    return new AssetImage(
      json.id,
      index,
      json.asset_id,
      estateId,
      json.file_name,
      extension
    );
  };

  static fromStatic = (fileName: string): AssetImage => {
    return new AssetImage(
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      fileName
    );
  };

  static fromBase64 = (base64Data: string): AssetImage => {
    return new AssetImage(
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      base64Data
    );
  };

  get networkUrl(): string | undefined {
    if (this.fileName) {
      return (
        LARAVEL_APP_SERVICE_PATH + AssetImage.getPath + "/" + this.fileName
      );
    }
  }
}
