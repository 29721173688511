import {
  Grid,
  Table,
  TableBody,
  TablePagination,
  TableRow,
  Theme,
  Typography,
} from "@mui/material";
import { makeStyles } from "tss-react/mui";
import { Report, ReportStatus } from "../../../../models/Report";
import React, { useState } from "react";
import moment from "moment";
import { estateApi, logger } from "../../../../apis";
import { ReportCard } from "./ReportCard";
import { CircularLoaderWithChild } from "../../../shared/CircularLoaderWithChild";

import folder from "../../../../assets/folder-files-green.png";
import folder2 from "../../../../assets/folder-files-green@2x.png";
import folder3 from "../../../../assets/folder-files-green@3x.png";
import IFrameDialog from "../../../shared/IFrameDialog";

const useStyles = makeStyles()((theme: Theme) => ({
  iconWrapper: {
    border: `solid 5px ${theme.palette.grey["300"]}`,
    borderRadius: "40px",
    width: 64,
    height: 64,
    textAlign: "center",
  },
  icon: {
    color: theme.palette.primary.dark,
    opacity: 0.5,
    height: 50,
  },
  pagination: {
    "& .MuiTablePagination-spacer": {
      display: "none",
    },
  },
  iframe: {
    border: "solid 2px #666",
    borderRadius: 5,
  },
}));

interface Props {
  reports?: Report[];
  total?: number;
  perPage?: number;
  onPageChange?: (newPage: number) => void;
  onError?: (error?: string) => void;
}

// type DownloadErrors = {
//   [id: number]: {
//     error: string;
//   };
// };

export const ReportsTable = ({
  reports,
  total = 100,
  perPage = 10,
  onPageChange,
  onError,
}: Props): JSX.Element => {
  const { classes } = useStyles();
  const [page, setPage] = useState(0);
  const [reportSrc, setReportSrc] = useState<string>();

  const viewReport = async (id?: number) => {
    try {
      const response = await estateApi.getReport(id);
      if (response?.data) {
        const file = new Blob([response.data], { type: "application/pdf" });
        setReportSrc(URL.createObjectURL(file));
      }
    } catch (error) {
      switch ((error as any)?.response?.status) {
        case 401:
          window.document.location.href = `/app/estates`;
          break;
        case 404:
          onError?.("Sorry, the report was not found.");
          break;
        default:
          logger.error(error as Error);
          onError?.(
            "Sorry, there was a server error while downloading the report."
          );
          break;
      }
    }
  };

  const handlePageChange = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    page: number
  ) => {
    setPage(page);
    onPageChange && onPageChange(page);
  };

  const getButtonText = (status: ReportStatus | undefined): string => {
    switch (status) {
      case ReportStatus.ready:
        return "View report";
      case ReportStatus.processing:
        return "Processing...";
      default:
        return "Failed";
    }
  };

  return (
    <Grid
      container
      spacing={2}
      sx={{
        mb: 4,
      }}
    >
      <IFrameDialog
        src={reportSrc}
        contentType="application/pdf"
        onClose={() => {
          setReportSrc(undefined);
        }}
      />
      {reports?.map((report: Report) => (
        <Grid
          item
          sm={12}
          key={report?.title}
          style={{
            width: "100%",
          }}
        >
          <ReportCard
            key={report?.title}
            disabled={report?.status !== ReportStatus.ready}
            onClick={() => viewReport(report?.id)}
            clickText={getButtonText(report?.status)}
            title={report?.estateName ? report?.estateName + " estate" : ""}
            subtitle={
              <>
                <Typography variant="body2">Generated on</Typography>
                <Typography variant="body1">
                  {moment
                    .utc(report?.createdAt)
                    .local()
                    .format("MM/DD/YYYY hh:mm a")}
                </Typography>
              </>
            }
            icon={
              <CircularLoaderWithChild percent={0} size={68}>
                <img
                  src={folder}
                  srcSet={`${folder2} 2x, ${folder3} 3x`}
                  alt="Estate Report"
                />
              </CircularLoaderWithChild>
            }
          />
        </Grid>
      ))}
      {onPageChange && !!total && (
        <Table>
          <TableBody>
            <TableRow>
              <TablePagination
                count={total}
                onPageChange={handlePageChange}
                page={page}
                rowsPerPage={perPage}
                rowsPerPageOptions={[]}
                className={classes.pagination}
              />
            </TableRow>
          </TableBody>
        </Table>
      )}
    </Grid>
  );
};
