import React, { useState } from "react";
import PerfectScrollbar from "react-perfect-scrollbar";
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { AppTableWrapper } from "../../shared/AppTableWrapper";
import { Advisor } from "../../../models/Advisor";
import { TableBodyLoader } from "../../shared/TableBodyLoader";
import { ZeroStateBody } from "../../shared/ZeroStateBody";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { AdvisorEstateList } from "./AdvisorEstateList";
import { AdvisorsEstates } from "../../../models/AdvisorsEstates";
import { StoreState } from "../../../reducers";
import { connect } from "react-redux";
import { unassignFromAdvisor } from "../../../actions/advisorActions";
import { advisorApi } from "../../../apis";

interface Props {
  advisors: Advisor[];
  unassignFromAdvisor: (advisorsEstates: AdvisorsEstates) => void;
  onUpdate: () => void;
}

export const _AdvisorTable = ({
  advisors,
  unassignFromAdvisor,
  onUpdate,
}: Props): JSX.Element => {
  const [advisorsOpen, setAdvisorsOpen] = useState<string[]>([]);

  const handleUnassign = async (
    advisorsEstates?: AdvisorsEstates
  ): Promise<void> => {
    if (advisorsEstates && advisorsEstates.id) {
      // network call for persistence
      const assigned = await advisorApi.unassignAdvisor(advisorsEstates.id);
      if (assigned) {
        // update client-side store
        unassignFromAdvisor(advisorsEstates);
        onUpdate();
      } else {
        // show error message
      }
    }
  };

  const toggleEstatesView = (advisorsUsername?: string): void => {
    if (advisorsUsername) {
      if (advisorsOpen.includes(advisorsUsername)) {
        // remove it
        setAdvisorsOpen(advisorsOpen.filter((aId) => aId !== advisorsUsername));
      } else {
        // add it
        setAdvisorsOpen([...advisorsOpen, advisorsUsername]);
      }
    }
  };

  const nameSortedAdvisors =
    advisors?.sort((a: Advisor, b: Advisor) =>
      (a?.user?.email || "") > (b?.user?.email || "") ? 1 : -1
    ) || advisors;

  const loading = advisors === null;
  const showZero = advisors !== null && advisors.length === 0;
  return (
    <PerfectScrollbar>
      <AppTableWrapper minWidth={!showZero ? 800 : 1}>
        {showZero ? (
          <ZeroStateBody
            text="It looks like there are no advisors assigned to your group"
            // imageAlt="No Advisors"
            // imagePrefix={zeroImagePrefix}
          />
        ) : (
          <Table
            sx={{
              "& tr": {
                height: 56,
              },
            }}
          >
            <TableHead>
              <TableRow
                sx={{
                  "&>th": {
                    backgroundColor: "background.default",
                    color: "grey.700",
                  },
                }}
              >
                <TableCell width="5%" />
                <TableCell width="40%">Advisor Name</TableCell>
                <TableCell width="40%">Role</TableCell>
                <TableCell width="15%"># Estates</TableCell>
              </TableRow>
            </TableHead>
            {loading ? (
              <TableBodyLoader colSpan={6} rows={3} />
            ) : (
              <TableBody>
                {nameSortedAdvisors.map((advisor: Advisor) => {
                  const isOpen =
                    advisor.user?.cognitoUsername &&
                    advisorsOpen.includes(advisor.user.cognitoUsername);
                  return advisor.user?.cognitoUsername && advisor.user?.id ? (
                    <React.Fragment
                      key={
                        "AdvisorTable_" + advisor.user?.id ||
                        advisor.user.cognitoUsername
                      }
                    >
                      <TableRow
                        hover
                        onClick={() =>
                          toggleEstatesView(advisor.user.cognitoUsername)
                        }
                        className="clickable"
                        sx={{
                          ">td": {
                            backgroundColor: isOpen
                              ? "#F2F2FF"
                              : "background.paper",
                            borderBottom: "solid 1px",
                            borderColor: "grey.300",
                          },
                          "&:hover": {
                            ">td": {
                              backgroundColor: "#F2F2FF",
                            },
                          },
                        }}
                      >
                        <TableCell>
                          {isOpen ? (
                            <ExpandMoreIcon color="primary" />
                          ) : (
                            <KeyboardArrowRightIcon color="primary" />
                          )}
                        </TableCell>
                        <TableCell>
                          <Box alignItems="center" display="flex">
                            <Typography color="textPrimary" variant="body1">
                              {advisor.user.selectName}
                            </Typography>
                          </Box>
                        </TableCell>
                        <TableCell>{advisor?.user?.role}</TableCell>
                        <TableCell>{advisor?.estates?.length}</TableCell>
                      </TableRow>
                      {isOpen &&
                        advisor.user?.cognitoUsername &&
                        advisor?.estates &&
                        advisor?.estates?.length > 0 && (
                          <>
                            <TableRow
                              sx={{
                                "&>td:first-of-type": {
                                  ml: 1,
                                },
                                "&>td": {
                                  backgroundColor: "background.paper",
                                  color: "grey.700",
                                },
                              }}
                            >
                              <TableCell>{""}</TableCell>
                              <TableCell sx={{ fontWeight: "bold" }}>
                                Estate Name
                              </TableCell>
                              <TableCell>{""}</TableCell>
                              <TableCell
                                sx={{ fontWeight: "bold", textAlign: "left" }}
                              >
                                Access Type
                              </TableCell>
                            </TableRow>
                            <AdvisorEstateList
                              advisor={advisor}
                              advisorsEstatesList={advisor.estates}
                              onUnassign={handleUnassign}
                            />
                          </>
                        )}
                      {isOpen &&
                        advisor?.estates &&
                        advisor?.estates.length === 0 && (
                          <>
                            <TableRow
                              className="dense no-background"
                              key={
                                "AdvisorTable_" + advisor.user?.id ||
                                advisor.user.cognitoUsername + "_add_estate"
                              }
                            >
                              <TableCell
                                colSpan={5}
                                sx={{
                                  textAlign: "center",
                                  py: 2,
                                }}
                              >
                                No Estates Assigned
                              </TableCell>
                            </TableRow>
                          </>
                        )}
                    </React.Fragment>
                  ) : null;
                })}
              </TableBody>
            )}
          </Table>
        )}
      </AppTableWrapper>
    </PerfectScrollbar>
  );
};

const mapStateToProps = ({ advisors }: StoreState): { advisors: Advisor[] } => {
  return { advisors };
};

export const AdvisorTable = connect(mapStateToProps, {
  unassignFromAdvisor,
})(_AdvisorTable);
