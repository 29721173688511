import React, { useEffect, useState } from "react";
import { Grid, Typography } from "@mui/material";
import { makeStyles } from "tss-react/mui";
import { Estate } from "../../../../models/Estate";
import moment from "moment";
import { advisorApi } from "../../../../apis";
import { Subscription } from "../../../../models/Subscription";
import { LoadingSpinner } from "../../../shared/LoadingSpinner";

const useStyles = makeStyles()(() => ({
  root: {
    // display: "flex",
  },
  infoContent: {
    fontWeight: "bold",
  },
}));

interface Props {
  estate?: Estate;
  isUber?: boolean;
}

export const SubscriptionInfo = ({
  estate,
  isUber = false,
}: Props): JSX.Element => {
  const { classes } = useStyles();
  const [subscription, setSubscription] = useState<Subscription>();

  useEffect(() => {
    (async () => {
      if (estate?.id) {
        const sub = await advisorApi.fetchSubscription(estate?.id);
        setSubscription(sub);
      }
    })();
  }, [estate]);

  const formatPrice = (price?: number): string | undefined => {
    if (price) {
      return `$${price / 100}.00`;
    } else {
      return undefined;
    }
  };

  return (
    <Grid container spacing={1}>
      <Grid item sm={12}>
        <Grid container spacing={2}>
          {subscription ? (
            <>
              <Grid item xs={3}>
                <Typography variant="body2">Name</Typography>
                <Typography variant="body2" className={classes.infoContent}>
                  {subscription?.product?.name || "--"}
                </Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography variant="body2">Price USD</Typography>
                <Typography variant="body2" className={classes.infoContent}>
                  {formatPrice(subscription?.product?.priceUsd) || "--"}
                </Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography variant="body2">Active</Typography>
                <Typography variant="body2" className={classes.infoContent}>
                  {subscription?.isActive?.toString() || "--"}
                </Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography variant="body2">Recurring</Typography>
                <Typography variant="body2" className={classes.infoContent}>
                  {subscription?.product?.isRecurring?.toString() || "--"}
                </Typography>
              </Grid>
              {subscription?.product?.description && (
                <Grid item xs={12}>
                  <Typography variant="body2">Description</Typography>
                  <Typography variant="body2" className={classes.infoContent}>
                    {subscription?.product?.description || "--"}
                  </Typography>
                </Grid>
              )}
              {isUber && (
                <>
                  <Grid item xs={3}>
                    <Typography variant="body2">Internal Name</Typography>
                    <Typography variant="body2" className={classes.infoContent}>
                      {subscription?.product?.internalName || "--"}
                    </Typography>
                  </Grid>
                  <Grid item xs={3}>
                    <Typography variant="body2">Price Value</Typography>
                    <Typography variant="body2" className={classes.infoContent}>
                      {subscription?.product?.priceValue || "--"}
                    </Typography>
                  </Grid>
                  <Grid item xs={3}>
                    <Typography variant="body2">Price Level Id</Typography>
                    <Typography variant="body2" className={classes.infoContent}>
                      {subscription?.product?.productPriceLevelsId || "--"}
                    </Typography>
                  </Grid>
                  <Grid item xs={3}>
                    <Typography variant="body2">Paid Date</Typography>
                    <Typography variant="body2" className={classes.infoContent}>
                      {subscription?.paidDate
                        ? moment
                            .unix(subscription.paidDate)
                            .format("MM/DD/YYYY")
                        : "--"}
                    </Typography>
                  </Grid>
                </>
              )}
            </>
          ) : (
            <LoadingSpinner
              message="Loading Production Info..."
              inCard={false}
            />
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};
