import { Button, TextField, Typography } from "@mui/material";
import React from "react";
import { Formik, FormikHelpers } from "formik";
import { authApi } from "../../apis";
const Yup = require("yup")
import { User } from "../../models/User";
import { PASSWORD_REGEX, PASSWORD_REQUIREMENT_MESSAGE } from "../../constants";

interface Props {
  updateRequired?: boolean;
  user?: User;
  onComplete?: () => void;
  intro?: JSX.Element;
}

interface Values {
  oldPassword: string;
  password: string;
  password2: string;
}

/**
 * used on the profile screen to allow advisor to update when they'd like
 * used during sign in flow when sign in returns cognitoUser.challengeName = NEW_PASSWORD_REQUIRED
 *  this is when updateRequired = true and the other props will be sent
 * @param updateRequired
 * @param user
 * @param onComplete
 * @param intro
 * @constructor
 */
export const CognitoPasswordUpdateForm = ({
  updateRequired = false,
  user,
  onComplete,
  intro,
}: Props): JSX.Element => {
  // https://formik.org/docs/api/formik
  const formikInit = {
    values: {
      oldPassword: updateRequired ? "_" : "",
      password: "",
      password2: "",
    },
    schema: Yup.object().shape({
      oldPassword: !updateRequired
        ? Yup.string().max(255).required("Old password is required")
        : Yup.string(),
      password: Yup.string()
        .max(255)
        .required("New Password is required")
        .matches(PASSWORD_REGEX, {
          message: PASSWORD_REQUIREMENT_MESSAGE,
        }),
      password2: Yup.string()
        .oneOf([Yup.ref("password")], "Passwords must match")
        .required("Password confirmation"),
    }),
  };

  const handleSubmit = async (
    values: Values,
    { setSubmitting, setStatus }: FormikHelpers<Values>
  ) => {
    setStatus("");
    const { oldPassword, password, password2 } = values;
    if (oldPassword && password && password2) {
      try {
        if (updateRequired) {
          if (user?.cognitoUser) {
            await authApi.setNewPassword(user.cognitoUser, password);
          } else {
            throw Error("Could not determine cognito user");
          }
        } else {
          await authApi.updatePassword(oldPassword, password);
        }
        setStatus("Success!");
        window.setTimeout(() => {
          setStatus("");
          onComplete && onComplete();
        }, 1500);
      } catch (error) {
        setStatus(
          "There was a problem updating: " + ((error as Error)?.message || "")
        );
      }
    }
    setSubmitting(false);
  };

  return (
    <Formik
      initialValues={formikInit.values}
      validationSchema={formikInit.schema}
      onSubmit={handleSubmit}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values,
        status,
      }) => (
        <form onSubmit={handleSubmit}>
          {intro}
          {!updateRequired && (
            <TextField
              error={Boolean(touched.oldPassword && errors.oldPassword)}
              fullWidth
              helperText={touched.oldPassword && errors.oldPassword}
              label="Old password"
              margin="normal"
              name="oldPassword"
              onBlur={handleBlur}
              onChange={handleChange}
              type="password"
              value={values.oldPassword}
              variant="outlined"
            />
          )}
          <TextField
            error={Boolean(touched.password && errors.password)}
            fullWidth
            helperText={touched.password && errors.password}
            label="New password"
            margin="normal"
            name="password"
            onBlur={handleBlur}
            onChange={handleChange}
            type="password"
            value={values.password}
            variant="outlined"
          />
          <TextField
            error={Boolean(touched.password2 && errors.password2)}
            fullWidth
            helperText={touched.password2 && errors.password2}
            label="Password confirmation"
            margin="normal"
            name="password2"
            onBlur={handleBlur}
            onChange={handleChange}
            type="password"
            value={values.password2}
            variant="outlined"
          />
          <Typography variant="h4" color="error">
            {status}
          </Typography>
          {isSubmitting && <Typography variant="h4">submitting...</Typography>}
          <br />
          <Button variant="contained" color="primary" type="submit" fullWidth>
            Update
          </Button>
        </form>
      )}
    </Formik>
  );
};
