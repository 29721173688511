import React from "react";
import DonutChart from "react-donut-chart";
import { AssetSubTotal } from "./AssetSummary";
import atticusColors from "../../theme/colors";
import { Typography } from "@mui/material";
import { makeStyles } from "tss-react/mui";


const useStyles = makeStyles()(() => ({
  chart: {
    cursor: "pointer",
    "& .donutchart-innertext": {
      fontWeight: 600,
    },
  },
}));

interface Props {
  segments?: AssetSubTotal[];
  total?: number;
}

export const AssetSummaryDoughnut = ({
  segments,
  total = 0,
}: Props): JSX.Element => {
  const { classes } = useStyles();
  // const [item, setItem] = useState<Item>();
  // useEffect(() => {}, []);

  const items = segments?.map((asset: AssetSubTotal) => {
    return {
      label: asset.key,
      value: asset.value,
    };
  });
  // .sort((a: Item, b: Item) => a.value - b.value);

  // console.log(items);

  return (
    <div className={classes.chart}>
      {items && total > 0 ? (
        <DonutChart
          data={items}
          strokeColor="#ffffff"
          colors={[
            // atticusColors.purple2,
            // atticusColors.purple1,
            // atticusColors.green1,
            atticusColors.purple35,
            // atticusColors.green2,
            // atticusColors.purple0,
            // atticusColors.pink,
            // atticusColors.lightYellow,
            // atticusColors.magenta,
            // atticusColors.turquoise,
          ]}
          width={300}
          height={300}
          innerRadius={0.6}
          legend={false}
          clickToggle={false}
          selectedOffset={0}
          // onMouseLeave={(item: Item) => {
          // console.log("onMouseLeave", item);
          // setItem(undefined);
          // }}
          // onMouseEnter={(item: Item) => {
          // console.log("onMouseEnter", item);
          // setItem(item);
          // }}
          // interactive={false}
        />
      ) : (
        <Typography
          variant="h3"
          style={{
            marginTop: 16,
            color: "#999",
          }}
        >
          No Assets Yet
        </Typography>
      )}
      {/*<Typography>active: {item?.label}</Typography>*/}
    </div>
  );
};
