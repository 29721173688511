import { Card, CardContent, Switch, Theme, Typography } from "@mui/material";
import { makeStyles } from "tss-react/mui";
import React from "react";
import { StoreState } from "../../../reducers";
import { connect } from "react-redux";
import { setThemeKey, SetThemeKeyAction } from "../../../actions/appActions";
import { ThemeKey } from "../../../theme/ThemeKey";
import { AppState } from "../../../reducers/app";

const useStyles = makeStyles()((theme: Theme) => ({
  root: {
    marginTop: theme.spacing(2),
  },
  content: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
}));

interface Props {
  app: AppState;
  setThemeKey: (themeKey: ThemeKey) => SetThemeKeyAction;
}

export const _ThemeSwitcher = ({
  app: { themeKey },
  setThemeKey,
}: Props): JSX.Element => {
  const { classes } = useStyles();
  const [checked, setChecked] = React.useState(themeKey === ThemeKey.Dark);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { checked: newCheckedState } = event.target;
    setChecked(newCheckedState);
    setThemeKey(newCheckedState ? ThemeKey.Dark : ThemeKey.Default);
  };

  return (
    <Card className={classes.root}>
      <CardContent className={classes.content}>
        <label htmlFor="mode-switch" style={{ cursor: "pointer" }}>
          <Typography variant="h5" component="div">
            Dark mode:
          </Typography>
        </label>
        <Switch
          checked={checked}
          onChange={handleChange}
          color="primary"
          name="mode-switch"
          id="mode-switch"
          inputProps={{ "aria-label": "dark mode" }}
        />
      </CardContent>
    </Card>
  );
};

const mapStateToProps = ({ app }: StoreState): { app: AppState } => {
  return { app };
};

export const ThemeSwitcher = connect(mapStateToProps, { setThemeKey })(
  _ThemeSwitcher
);
