import React from "react";

const imagePrefix = "/static/images/Atticus";
export const Logo = (): JSX.Element => {
  return (
    <img
      srcSet={`${imagePrefix}-1.5x.png 1.5x, ${imagePrefix}-2x.png 2x, ${imagePrefix}-3x.png 3x`}
      src={`${imagePrefix}.png`}
      alt="Atticus"
      title="Atticus"
    />
  );
};
