import { API } from "aws-amplify";
import { REST_API } from "../constants";

export type ClientInvite = {
  email?: string;
  inviteId?: string;
  toFirstName?: string;
  toLastName?: string;
  fromFirstName?: string;
  fromLastName?: string;
  closing?: string;
};

export class SendEmail {
  static path = "/sendinvite";
  async sendInvite(inviteValues: ClientInvite): Promise<boolean> {
    try {
      await API.post(REST_API, SendEmail.path, {
        body: inviteValues,
      });
      return true;
    } catch (error) {
      console.error(error);
    }
    return false;
  }
}
