import React from "react";
import { Typography, Theme } from "@mui/material";
import { makeStyles } from "tss-react/mui";
import { MosaicBranch, MosaicWindow } from "react-mosaic-component";

const useStyles = makeStyles()((theme: Theme) => ({
  toolbarWrapper: {
    height: "100%",
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: theme.palette.background.paper,
  },
  toolbarTitle: {},
}));

interface MosaicWindowProps {
  viewTitle: string;
  viewElement: JSX.Element;
  preview?: JSX.Element;
  path: MosaicBranch[];
  draggable?: boolean;
  disableIframes: () => void;
  enableIframes: () => void;
}

export const AtticusMosaicWindow = ({
  viewTitle,
  viewElement,
  preview,
  path,
  draggable,
  disableIframes,
  enableIframes,
}: MosaicWindowProps): JSX.Element => {
  const { classes } = useStyles();

  return (
    <MosaicWindow<string>
      renderToolbar={() => (
        <div className={classes.toolbarWrapper}>
          <Typography className={classes.toolbarTitle}>{viewTitle}</Typography>
        </div>
      )}
      draggable={draggable}
      renderPreview={preview ? () => preview : undefined}
      title={viewTitle}
      path={path}
      onDragStart={() => {
        disableIframes();
      }}
      onDragEnd={() => {
        enableIframes();
      }}
    >
      {viewElement}
    </MosaicWindow>
  );
};
