import React from "react";
import {
  TableCell,
  TablePagination,
  TableRow,
  Typography,
} from "@mui/material";
import { Link } from "react-router-dom";
import { AdvisorsEstates } from "../../../models/AdvisorsEstates";
import { AssignAdvisorForm } from "./AssignAdvisorForm";
import { SCREEN_PATHS } from "../../../constants";
import { Advisor } from "../../../models/Advisor";

interface Props {
  advisor?: Advisor;
  advisorsEstatesList?: AdvisorsEstates[];
  onUnassign?: (advisorsEstates: AdvisorsEstates) => void;
  onAssign?: (advisorsEstates?: AdvisorsEstates) => Promise<boolean>;
  total?: number;
  page?: number;
  perPage?: number;
  onPageChange?: (page: number) => void;
  onRowsPerPageChange?: (perPage: number) => void;
}

export const AdvisorEstateList = ({
  advisor,
  advisorsEstatesList,
  onUnassign,
  onAssign,
  total = 0,
  page = 1,
  perPage = 10,
  onPageChange,
  onRowsPerPageChange,
}: Props): JSX.Element => {
  // this content appears as a list of unassigned estates
  //  and as a list of estate that have been assigned to an advisor
  const advisorsUsername = advisor?.user?.cognitoUsername;
  const inUnassignedTable = !advisorsUsername;

  return (
    <>
      {advisorsEstatesList?.map((advisorsEstates: AdvisorsEstates) => (
        <TableRow
          className="no-background"
          key={`AdvisorEstateList_${advisorsEstates.estateId}_${advisorsEstates.estateName}`}
          sx={{
            "&>td": {
              borderBottom: "solid 1px",
              borderColor: "grey.300",
            },
          }}
        >
          <TableCell />
          <TableCell>
            <Typography variant="body1">
              <Link to={`${SCREEN_PATHS.estates}/${advisorsEstates.estateId}`}>
                {advisorsEstates.estateName
                  ? `${advisorsEstates.estateName} estate`
                  : `estate #${advisorsEstates.estateId}`}
              </Link>
            </Typography>
          </TableCell>
          <TableCell />
          <TableCell>
            <AssignAdvisorForm
              advisor={advisor}
              advisorsEstates={advisorsEstates}
              onAssign={onAssign}
              onUnassign={!inUnassignedTable ? onUnassign : undefined}
            />
          </TableCell>
        </TableRow>
      ))}
      <TableRow
        sx={{
          backgroundColor: "background.paper",
        }}
      >
        {onPageChange && (
          <TablePagination
            className="TablePagination"
            count={total}
            page={page - 1}
            rowsPerPageOptions={[10, 25, 50]}
            onPageChange={(
              event: React.MouseEvent<HTMLButtonElement> | null,
              zeroIndexPage: number
            ) => {
              onPageChange && onPageChange(zeroIndexPage + 1);
            }}
            rowsPerPage={perPage}
            onRowsPerPageChange={(
              event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
            ) => {
              onRowsPerPageChange &&
                onRowsPerPageChange(parseInt(event.target.value, 10));
            }}
          />
        )}
      </TableRow>
    </>
  );
};
