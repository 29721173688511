import React from "react";
import {
  styled,
  IconButton,
  Skeleton,
  Drawer,
  DrawerProps,
  Box,
  Stack,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

interface DrawerHeaderProps {
  handleDrawerClose: any;
}

export const DrawerHeader = ({
  handleDrawerClose,
}: DrawerHeaderProps): JSX.Element => {
  const CustomHeader = styled("div")(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: "flex-end",
  }));

  return (
    <CustomHeader>
      <IconButton onClick={handleDrawerClose}>
        <CloseIcon />
      </IconButton>
    </CustomHeader>
  );
};

interface DrawerBodyProps {
  children?: any;
}
export const DrawerBody = ({ children }: DrawerBodyProps): JSX.Element => {
  const CustomBody = styled("div")(({ theme }) => ({
    display: "flex",
    padding: theme.spacing(0, 3),
    width: "100%",
  }));

  return (
    <CustomBody>
      {children ? (
        <>{children}</>
      ) : (
        <Stack gap={4} sx={{ width: "100%" }}>
          <Box>
            <Skeleton
              variant="text"
              sx={{ fontSize: "1.3rem", width: "100%" }}
            />
          </Box>
          <Box>
            <Skeleton variant="text" sx={{ fontSize: "1rem", width: "100%" }} />
            <Skeleton variant="text" sx={{ fontSize: "1rem", width: "100%" }} />
            <Skeleton variant="text" sx={{ fontSize: "1rem", width: "100%" }} />
            <Skeleton variant="text" sx={{ fontSize: "1rem", width: "75%" }} />
          </Box>
          <Box>
            <Skeleton variant="text" sx={{ fontSize: "1rem", width: "100%" }} />
            <Skeleton variant="text" sx={{ fontSize: "1rem", width: "100%" }} />
            <Skeleton variant="text" sx={{ fontSize: "1rem", width: "100%" }} />
            <Skeleton variant="text" sx={{ fontSize: "1rem", width: "75%" }} />
          </Box>
        </Stack>
      )}
    </CustomBody>
  );
};

interface AtticusDrawerProps extends DrawerProps {
  handleDrawerClose?: any;
  contents?: JSX.Element;
}

const AtticusDrawer = ({
  contents,
  onClose,
  ...rest
}: AtticusDrawerProps): JSX.Element => {
  return (
    <Drawer
      onClose={onClose}
      {...rest}
      sx={{
        "& .MuiDrawer-paper": {
          width: "450px",
          maxWidth: "100%",
        },
      }}
    >
      <DrawerHeader handleDrawerClose={onClose} />
      <DrawerBody>{contents}</DrawerBody>
    </Drawer>
  );
};

export default AtticusDrawer;
