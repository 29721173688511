export type PlaceJSON = {
  formatted_address?: string;
  number?: number;
  city?: string;
  street?: string;
  county?: string;
  state?: string;
  country?: string;
  postal_code?: string;
  place_type?: string;
};

export class Place {
  constructor(
    public estateId?: number,
    public formatted_address?: string,
    public number?: number,
    public city?: string,
    public street?: string,
    public county?: string,
    public state?: string,
    public country?: string,
    public postal_code?: string,
    public place_type?: string
  ) {}

  static fromJSON(estateId?: number, json?: PlaceJSON): Place {
    if (estateId && json) {
      return new Place(
        estateId,
        json.formatted_address,
        json.number,
        json.city,
        json.street,
        json.county,
        json.state,
        json.country,
        json.postal_code,
        json.place_type
      );
    } else {
      return new Place();
    }
  }

  toJSON(): PlaceJSON {
    return {
      formatted_address: this.formatted_address,
      number: this.number,
      city: this.city,
      street: this.street,
      county: this.county,
      state: this.state,
      country: this.country,
      postal_code: this.postal_code,
      place_type: this.place_type,
    };
  }
}
