export type ProbateAtticusTierJSON = {
  id?: number;
  name?: string;
  description?: string;
};

export class ProbateAtticusTier {
  constructor(
    public id?: number,
    public name?: string,
    public description?: string
  ) {}

  static fromJSON(json: ProbateAtticusTierJSON): ProbateAtticusTier {
    return new ProbateAtticusTier(json.id, json.name, json.description);
  }

  toJSON(): ProbateAtticusTierJSON {
    return {
      id: this.id,
      name: this.name,
      description: this.description,
    };
  }
}
