import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import { StoreState } from "../../../reducers";
import { Estate } from "../../../models/Estate";
import { User } from "../../../models/User";
import { GeographyType, State } from "../../../models/Geography";
import { Language } from "../../../models/Language";
import { FormType } from "../../../models/FormType";
import { fetchProbateTypes } from "../../../actions/probateTypeActions";
import { fetchStates } from "../../../actions/geographyActions";
import { fetchLanguages } from "../../../actions/languageActions";
import { fetchFormTypes } from "../../../actions/formTypeActions";
import { ProbateType } from "../../../models/ProbateType";
import EstateFormsComponent from "./formsAndFiles/EstateFormsComponent";
import { useNavigate } from "react-router-dom";
import AtticusDrawer from "../../shared/drawer/AtticusDrawer";
import { AtticusForm } from "../../../models/AtticusForm";
import { QueryPayload } from "../../../apis/AtticusForms";
import { FormsLibrarySearch } from "../../shared/forms/library/FormsLibrarySearch";

const MAX_ESTATE_FORMS_TO_SHOW = 3;

interface Props {
  user: User;
  estates?: Estate[];
  states?: State[];
  estateForms?: AtticusForm[];
  fetchStates: () => void;
  languages?: Language[];
  fetchLanguages: () => void;
  formTypes?: FormType[];
  fetchFormTypes: () => void;
  probateTypes?: ProbateType[];
  fetchProbateTypes: () => void;
}

const _FormsFilesView = ({
  user,
  estates,
  states,
  estateForms,
  fetchStates,
  languages,
  fetchLanguages,
  formTypes,
  fetchFormTypes,
  probateTypes,
  fetchProbateTypes,
}: Props): JSX.Element => {
  const navigate = useNavigate();
  const { estateId: estateIdString } = useParams();
  const estateId = estateIdString ? parseInt(estateIdString, 10) : undefined;

  const [estate, setEstate] = useState<Estate | undefined>(undefined);
  const [probateType, setProbateType] = useState<ProbateType | undefined>(
    undefined
  );

  const [drawerOpen, setDrawerOpen] = React.useState(false);
  const [drawerContents, setDrawerContents] = React.useState<JSX.Element>();
  const handleDrawerOpen = () => {
    setDrawerOpen(true);
  };

  const handleDrawerClose = () => {
    setDrawerOpen(false);
  };

  // If you do not do the below and only add to `restrictScreenAcceess`, your screen will temporarily
  // flash with the component before redirecting. Perhaps we should write High Order Routing/Auth
  // components to wrap our View components in, sticking all of it in the router's component slot
  // for the path, to standardize and clean this up. Left a note in routes.tsx about this.
  const hasAccess = user?.advisorGroup?.hasAdvisorAccessToForms;

  useEffect(() => {
    if (hasAccess && estateId) {
      if (!states || !states?.length) {
        fetchStates();
      }
      if (!languages || !languages?.length) {
        fetchLanguages();
      }
      if (!formTypes || !formTypes?.length) {
        fetchFormTypes();
      }
      if (!probateTypes || !probateTypes?.length) {
        fetchProbateTypes();
      }
    }
  }, []);

  useEffect(() => {
    if (estateId && estates?.length) {
      const estate = estates.find((estate: Estate) => estate.id === +estateId);
      setEstate(estate);
    }
  }, [estates]);

  useEffect(() => {
    const newPT = probateTypes?.find((pt) => pt.id === estate?.probateTypeId);
    setProbateType(newPT);
  }, [estate, probateTypes]);

  return hasAccess ? (
    <div data-automation-id="page-content-forms-files">
      {estate && (
        <>
          <EstateFormsComponent
            estate={estate}
            probateType={probateType}
            truncateFormsAt={MAX_ESTATE_FORMS_TO_SHOW}
            sx={{ mb: 5 }}
            openDrawer={handleDrawerOpen}
            setDrawerContents={setDrawerContents}
            onEmptyButtonClick={() => {
              navigate(`forms-library`, {
                state: {
                  geography_type: GeographyType.State,
                  geography_id: estate.stateId,
                },
              });
            }}
          />
          {estateForms && estateForms.length > 0 && (
            <FormsLibrarySearch
              onComplete={(values: QueryPayload) => {
                navigate(`forms-library`, {
                  state: values,
                });
              }}
              loadingForms={false}
              initGeographyType={GeographyType.State}
              initGeographyId={estate.stateId}
            />
          )}
        </>
      )}
      <AtticusDrawer
        variant="temporary"
        anchor="right"
        open={drawerOpen}
        onClose={handleDrawerClose}
        contents={drawerContents}
      />
    </div>
  ) : (
    <></>
  );
};

const mapStateToProps = ({
  user,
  estates,
  states,
  formTypes,
  languages,
  probateTypes,
  estateForms,
}: StoreState): {
  user: User;
  estates: Estate[];
  states: State[];
  formTypes: FormType[];
  languages: Language[];
  probateTypes: ProbateType[];
  estateForms: AtticusForm[];
} => {
  return {
    user,
    estates,
    states,
    formTypes,
    languages,
    probateTypes,
    estateForms,
  };
};
export const FormsFilesView = connect(mapStateToProps, {
  fetchStates,
  fetchLanguages,
  fetchFormTypes,
  fetchProbateTypes,
})(_FormsFilesView);
