import React, { useState, useRef } from "react";

import { Mosaic, MosaicNode } from "react-mosaic-component";
import { AtticusMosaicWindow } from "./AtticusMosaicWindow";

export type ViewId = "interface" | "knowledge-engine" | "preview";

export interface TILE {
  title: string;
  element: JSX.Element;
  preview?: JSX.Element;
}

interface Props {
  initialNode: MosaicNode<string>;
  tileMap: { [viewId: string]: TILE };
  draggable?: boolean;
}

export const AtticusMosaicGrid = ({
  initialNode,
  tileMap,
  draggable = true,
}: Props): JSX.Element => {
  // const { classes } = useStyles();
  const mosaicRef = useRef<HTMLElement>(null);

  const [currentNode, setCurrentNode] = useState<MosaicNode<string> | null>(
    initialNode
  );

  const disableIframes = () => {
    if (mosaicRef.current) {
      const iframes = mosaicRef.current.querySelectorAll("iframe");
      Array.from(iframes).forEach((iframe) => {
        iframe.style.pointerEvents = "none";
      });
    }
  };

  const enableIframes = () => {
    if (mosaicRef.current) {
      const iframes = mosaicRef.current.querySelectorAll("iframe");
      Array.from(iframes).forEach((iframe) => {
        iframe.style.pointerEvents = "auto";
      });
    }
  };

  const onChange = (currentNode: MosaicNode<string> | null) => {
    setCurrentNode(currentNode);
    disableIframes();
  };

  const onRelease = () => {
    enableIframes();
  };

  return (
    <span ref={mosaicRef}>
      <Mosaic<string>
        renderTile={(viewId, path) => {
          const tile = tileMap[viewId];

          if (!tile) {
            return <div>Tile not found</div>;
          } else {
            const viewTitle = tile.title;
            const viewElement = tile.element;
            const preview = tile.preview;
            return (
              <AtticusMosaicWindow
                viewTitle={viewTitle}
                viewElement={viewElement}
                preview={preview}
                path={path}
                draggable={draggable}
                // Make draggable once I solve the disableIframes when drag/drop bug
                disableIframes={disableIframes}
                enableIframes={enableIframes}
              />
            );
          }
        }}
        value={currentNode}
        onChange={onChange}
        onRelease={onRelease}
      />
    </span>
  );
};
