export type OnboardingSummaryJSON = {
  letters?: string;
  value_below_threshold?: string;
  done_so_far?: string[];
  estate_team?: string[];
  type_of_assets?: string[];
  already_started?: string;
  need_help_with?: string[];
  need_help_with_something_else?: string;
  property_ownership?: string;
  property_value?: number;
  has_will?: string;
};

export class OnboardingSummary {
  constructor(
    public estateId?: number,
    public letters?: string,
    public valueBelowThreshold?: string,
    public doneSoFar?: string[],
    public estateTeam?: string[],
    public typeOfAssets?: string[],
    public alreadyStarted?: string,
    public need_help_with?: string[],
    public need_help_with_something_else?: string,
    public propertyOwnership?: string,
    public propertyValue?: number,
    public hasWill?: string,
  ) {}

  static fromJSON(
    estateId?: number,
    obJson?: OnboardingSummaryJSON
  ): OnboardingSummary {
    if (estateId && obJson) {
      return new OnboardingSummary(
        estateId,
        obJson.letters,
        obJson.value_below_threshold,
        obJson.done_so_far,
        obJson.estate_team,
        obJson.type_of_assets,
        obJson.already_started,
        obJson.need_help_with,
        obJson.need_help_with_something_else,
        obJson.property_ownership,
        obJson.property_value,
        obJson.has_will,
      );
    } else {
      return new OnboardingSummary();
    }
  }
}
