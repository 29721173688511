import { logger } from "./index";
import { Invite } from "../models/Invite";
import { isProd } from "../constants";
import axios from "axios";
import Cookies from "js-cookie";
import { COOKIES } from "../constants"

const path = "/invites"

export class Invites {
  async createInvite(invite: Invite): Promise<string | null> {
    try {
      const baseUrl = "https://advisor-invite-v2" + (isProd? "" : "-ppd1") + ".weareatticus.com"
      const res = await axios.post(baseUrl + path, invite.toJSON(), {
        headers: {
          "Authorization": "Bearer " + Cookies.get(COOKIES.authToken)
        }
      })
      return res?.data?.data?.id
    } catch (error) {
      logger.error(error as Error);
    }
    return null;
  }

  async redeemInvite(inviteId: string): Promise<boolean> {
    try {
      const baseUrl = "https://advisor-invite-v2" + (isProd? "" : "-ppd1") + ".weareatticus.com"
      const res = await axios.put(baseUrl + `${path}/${inviteId}`)
      return res?.status === 200
    } catch (error) {
      logger.error(error as Error);
    }
    return false;
  }
}
