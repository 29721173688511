import React, { useState } from "react";
import { Outlet } from "react-router-dom";
import { Box, Theme, useTheme } from "@mui/material";
import { makeStyles } from "tss-react/mui";
import { NavBar } from "./NavBar";
import TopBar from "./TopBar";
import { SCROLL_DIV } from "../../constants";

const useStyles = makeStyles()((theme:Theme) => ({
    root: {
      display: "flex",
      height: "100%",
      overflow: "hidden",
      width: "100%",
      [theme.breakpoints.down("md")]: {
        marginTop: 64,
      },
    },
    wrapper: {
      display: "flex",
      flex: "1 1 auto",
      overflow: "hidden",
      [theme.breakpoints.up("md")]: {
        paddingLeft: 256,
      },
    },
    contentContainer: {
      display: "flex",
      flex: "1 1 auto",
      overflow: "hidden",
    },
    content: {
      flex: "1 1 auto",
      height: "100%",
      overflow: "auto",
    },
    topBar: {
      display: "none",
      [theme.breakpoints.down("md")]: {
        display: "block",
      },
    },
  })
);

export const DashboardLayout = (): JSX.Element => {
  const { classes } = useStyles();
  const [isMobileNavOpen, setMobileNavOpen] = useState(false);

  return (
    <Box
      id="DashboardLayout"
      className={classes.root}
      sx={{
        backgroundColor: "background.default",
      }}
    >
      <TopBar
        onMobileNavOpen={() => setMobileNavOpen(true)}
        className={classes.topBar}
      />
      <NavBar
        onMobileClose={() => setMobileNavOpen(false)}
        openMobile={isMobileNavOpen}
      />
      <div className={classes.wrapper}>
        <div className={classes.contentContainer}>
          <div className={`${classes.content} ${SCROLL_DIV}`}>
            <Outlet />
          </div>
        </div>
      </div>
    </Box>
  );
};