import React from "react";
import { Box, Card, Typography, Grid, Divider, Theme } from "@mui/material";
import { makeStyles } from "tss-react/mui";
import { CheatSheetItem } from "../../../../../utils/handlebarsCompiler";
const useStyles = makeStyles()((theme: Theme) => ({
  root: {
    height: "100%",
    textAlign: "left",
  },
  categoryTitle: {
    marginBottom: "1rem",
  },
  itemCard: {
    padding: "1rem",
  },
  itemTitle: {
    fontWeight: 800,
    marginBottom: "1rem",
  },
  itemDesc: {
    fontWeight: 300,
    marginBottom: "0.5rem",
  },
  exampleCard: {
    padding: "0.5rem",
  },
  exampleText: {
    marginBottom: "0.5rem",
  },
  codeSpan: {
    display: "inline",
    padding: "0.2rem",
    backgroundColor: theme.palette.grey[200],
    color: theme.palette.info.dark,
    borderRadius: "0.2rem",
  },
  divider: {
    margin: "2rem 0",
  },
}));

interface Props {
  cheatSheetList: CheatSheetItem[];
}

export const CheatSheetContent = ({ cheatSheetList }: Props): JSX.Element => {
  const { classes } = useStyles();

  const groupBy = function (xs: any, key: any) {
    return xs.reduce(function (rv: any, x: any) {
      (rv[x[key]] = rv[x[key]] || []).push(x);
      return rv;
    }, {});
  };
  const groupedByCategory = groupBy(cheatSheetList, "category");

  return (
    <Box className={classes.root}>
      <Typography variant="body1">
        This project heavily uses Handlebars Mustache Templating for variable
        slotting. For more information, visit{" "}
        <a href="https://handlebarsjs.com/guide/" target="_blank">
          the Official Handlebars documentation
        </a>
        .
      </Typography>
      <Divider className={classes.divider} />
      {Object.keys(groupedByCategory).map((category: string) => {
        return (
          <Box key={category}>
            <Typography className={classes.categoryTitle} variant="h4">
              {category}
            </Typography>
            <Grid container spacing={1}>
              {groupedByCategory[category].map((item: CheatSheetItem) => {
                return (
                  <Grid item xs={12} sm={6} md={4} key={item.key}>
                    <Card variant="outlined" className={classes.itemCard}>
                      <Typography variant="body1" className={classes.itemTitle}>
                        {item.name}
                        {": "}
                        <Box component={"span"} className={classes.itemDesc}>
                          {item.description}
                        </Box>
                      </Typography>
                      <Card variant="outlined" className={classes.exampleCard}>
                        <Typography
                          variant="body1"
                          className={classes.exampleText}
                        >
                          Example:{" "}
                          <Box component={"span"} className={classes.codeSpan}>
                            {item.example}
                          </Box>
                        </Typography>
                        <Typography variant="body1">
                          Result:{" "}
                          <Box component={"span"} className={classes.codeSpan}>
                            {item.exampleResult?.toString()}
                          </Box>
                        </Typography>
                      </Card>
                    </Card>
                  </Grid>
                );
              })}
            </Grid>
            <Divider className={classes.divider} />
          </Box>
        );
      })}
      <Typography variant="h4" className={classes.categoryTitle}>
        Combine Multiple Cheats
      </Typography>
      <Typography variant="body1">
        You can combine or nest multiple cheats to create a more complex result
        by using parentheses.
      </Typography>
      <Card variant="outlined" className={classes.exampleCard}>
        <Typography variant="body1" className={classes.exampleText}>
          Syntax:{" "}
          <Box component={"span"} className={classes.codeSpan}>
            {"{{outer-cheat (inner-cheat 'abc') 'def'}}"}
          </Box>
        </Typography>
        <Typography variant="body1" className={classes.exampleText}>
          Example:{" "}
          <Box component={"span"} className={classes.codeSpan}>
            {"${{format_number (round 1234567.2346 2)}}"}
          </Box>
        </Typography>
        <Typography variant="body1">
          Result:{" "}
          <Box component={"span"} className={classes.codeSpan}>
            {"$1,234,567.23"}
          </Box>
        </Typography>
      </Card>
    </Box>
  );
};
