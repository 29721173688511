import React from "react";
import { makeStyles } from "tss-react/mui";

import {
  CourtStuffIcon,
  FinalFinishesIcon,
  FirstThingsFirstIcon,
  FuneralArrangmentsIcon,
  GettingStartedIcon,
  InventoryIcon,
  NotifyingOthersIcon,
  ReviewReportIcon,
  TakingActionIcon,
} from "../../../shared/AtticusAdvisorProgressIcons";

interface Props {
  sequence: number;
  iconSm?: boolean;
  iconMd?: boolean;
  active?: boolean;
}

const useStyles = makeStyles()(() => ({
  iconSm: {
    width: "24px",
    height: "24px",
  },
  iconMd: {
    width: "48px",
    height: "48px",
  },
}));

export const TaskIcon = ({
  sequence,
  iconSm,
  iconMd,
  active,
}: Props): JSX.Element | null => {
  const { classes } = useStyles();

  switch (sequence) {
    case 1:
      return (
        <FirstThingsFirstIcon
          className={`${iconSm? classes.iconSm : ""} ${iconMd ? classes.iconMd : ""}`}
          viewBox="0 0 90 137"
          color={active ? undefined : "disabled"}
        />
      );
    case 2:
      return (
        <FuneralArrangmentsIcon
          className={`${iconSm? classes.iconSm : ""} ${iconMd ? classes.iconMd : ""}`}
          viewBox="0 0 130 135"
          color={active ? undefined : "disabled"}
          // color="disabled"
        />
      );
    case 3:
      return (
        <GettingStartedIcon
          className={`${iconSm? classes.iconSm : ""} ${iconMd ? classes.iconMd : ""}`}
          viewBox="0 0 82 134"
          color={active ? undefined : "disabled"}
        />
      );
    case 4:
      return (
        <CourtStuffIcon
          className={`${iconSm? classes.iconSm : ""} ${iconMd ? classes.iconMd : ""}`}
          viewBox="0 0 130 118"
          color={active ? undefined : "disabled"}
        />
      );
    case 5:
      return (
        <NotifyingOthersIcon
          className={`${iconSm? classes.iconSm : ""} ${iconMd ? classes.iconMd : ""}`}
          viewBox="0 0 130 90"
          color={active ? undefined : "disabled"}
        />
      );
    case 6:
      return (
        <TakingActionIcon
          className={`${iconSm? classes.iconSm : ""} ${iconMd ? classes.iconMd : ""}`}
          viewBox="0 0 112 136"
          color={active ? undefined : "disabled"}
        />
      );
    case 7:
      return (
        <InventoryIcon
          className={`${iconSm? classes.iconSm : ""} ${iconMd ? classes.iconMd : ""}`}
          viewBox="0 0 133 136"
          color={active ? undefined : "disabled"}
        />
      );
    case 8:
      return (
        <ReviewReportIcon
          className={`${iconSm? classes.iconSm : ""} ${iconMd ? classes.iconMd : ""}`}
          viewBox="0 0 132 117"
          color={active ? undefined : "disabled"}
        />
      );
    case 9:
      return (
        <FinalFinishesIcon
          className={`${iconSm? classes.iconSm : ""} ${iconMd ? classes.iconMd : ""}`}
          viewBox="0 0 100 150"
          color={active ? undefined : "disabled"}
        />
      );
    default:
      return null;
  }
};
