export type AssignmentScreen = {
  title: string;
  path: string;
};

export const assignmentNavItems: AssignmentScreen[] = [
  {
    title: "Advisors",
    path: "advisors",
  },
];
