import { Dispatch } from "redux";
import { ActionTypes } from "./types";
import { probateApi } from "../apis";
import { ProbateType } from "../models/ProbateType";

export interface FetchProbateTypesAction {
  type: ActionTypes.FetchProbateTypes;
  payload: ProbateType[] | undefined;
}

export const fetchProbateTypes = () => {
  return async (dispatch: Dispatch): Promise<void> => {
    let probateTypes: ProbateType[] | undefined = undefined;
    try {
      probateTypes = await probateApi.fetchProbateTypes();
    } catch (error) {
      console.error(error);
    }
    dispatch<FetchProbateTypesAction>({
      type: ActionTypes.FetchProbateTypes,
      payload: probateTypes,
    });
  };
};
