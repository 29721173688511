import { User } from "./User";
import { AdvisorsEstates } from "./AdvisorsEstates";

// Advisor is just a special type of User
export class Advisor {
  static path = "/advisors";

  constructor(public user: User, public estates?: AdvisorsEstates[]) {}

  static fromUser(user: User, estates?: AdvisorsEstates[]): Advisor {
    return new Advisor(user, estates);
  }
}
