import { ActionTypes } from "../actions/types";
import { Estate } from "../models/Estate";
import { EstateNote } from "../models/EstateNote";
import { AnyAction } from "redux";

export const estatesReducer = (
  estates: Estate[] | null = null,
  action: AnyAction
): Estate[] | null => {
  switch (action.type) {
    case ActionTypes.ClearEstates:
    case ActionTypes.FetchEstates:
      return action.payload;
    case ActionTypes.FetchSingleEstate:
      if (estates) {
        const index = estates?.findIndex(
          (estate: Estate) => estate.id === action.payload.id
        );
        if (index !== -1) {
          const newEstates = [...estates];
          newEstates[index] = action.payload;
          return newEstates;
        } else {
          return [...estates, action.payload];
        }
      } else {
        return [action.payload];
      }
    case ActionTypes.FetchEstateAssets:
      if (estates) {
        const newEstates = [...estates];
        const index = newEstates.findIndex(
          (estate: Estate) => estate.id === action.payload.estateId
        );
        if (index !== -1) {
          newEstates[index].assets = action.payload.assets;
          return newEstates;
        } else {
          return estates;
        }
      } else {
        return estates;
      }
    case ActionTypes.FetchEstateReports:
      if (estates) {
        const newEstates = [...estates];
        const index = newEstates.findIndex(
          (estate: Estate) => estate.id === action.payload.estateId
        );
        if (index !== -1) {
          newEstates[index].reports = action.payload.reports;
          return newEstates;
        } else {
          return estates;
        }
      } else {
        return estates;
      }
    case ActionTypes.FetchEstateNotes:
      if (estates) {
        const newEstates = [...estates];
        const index = newEstates.findIndex(
          (estate: Estate) => estate.id === action.payload.estateId
        );
        if (index !== -1) {
          newEstates[index].notes = action.payload.estateNotes;
          return newEstates;
        } else {
          return estates;
        }
      } else {
        return estates;
      }
    case ActionTypes.SaveEstateNote:
      if (estates) {
        const newEstates = [...estates];
        const index = newEstates.findIndex(
          (estate: Estate) => estate.id === action.payload.estateId
        );
        if (
          index !== -1 &&
          newEstates[index] &&
          newEstates[index].notes &&
          action.payload.estateNote
        ) {
          let noteIndex = -1;
          const estateNotes = newEstates[index].notes as EstateNote[];
          noteIndex = estateNotes.findIndex((estateNote: EstateNote) => {
            return (
              action.payload.estateNote &&
              estateNote.id === action.payload.estateNote.id
            );
          });
          if (noteIndex !== -1) {
            estateNotes[noteIndex] = action.payload.estateNote;
          } else {
            estateNotes.push(action.payload.estateNote);
          }
          return newEstates;
        } else {
          return estates;
        }
      } else {
        return estates;
      }
    case ActionTypes.DeleteEstateNote:
      if (estates) {
        const newEstates = [...estates];
        const index = newEstates.findIndex(
          (estate: Estate) => estate.id === action.payload.estateId
        );
        if (
          index !== -1 &&
          newEstates[index] &&
          newEstates[index].notes &&
          action.payload.estateNote
        ) {
          const estateNotes = newEstates[index].notes as EstateNote[];
          newEstates[index].notes = estateNotes.filter(
            (estateNote: EstateNote) =>
              estateNote.id !== action.payload.estateNote?.id
          );
          return newEstates;
        } else {
          return estates;
        }
      } else {
        return estates;
      }
    case ActionTypes.SetEstateIsDemo:
      if (estates) {
        const newEstates = [...estates];
        const index = newEstates.findIndex(
          (estate: Estate) => estate.id === action.payload.estateId
        );
        if (index !== -1) {
          newEstates[index].isDemo = action.payload.isDemo;
          return newEstates;
        } else {
          return estates;
        }
      } else {
        return estates;
      }
    case ActionTypes.AppSignOut:
      return null;
    default:
      return estates;
  }
};
