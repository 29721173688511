import React, { useEffect, useState } from "react";
import {
  Button,
  FormControl,
  Grid,
  MenuItem,
  Select,
  Theme,
  Typography,
} from "@mui/material";
import { makeStyles } from "tss-react/mui";
import { Estate } from "../../../../models/Estate";
import { StoreState } from "../../../../reducers";
import { ProbateType } from "../../../../models/ProbateType";
import { connect } from "react-redux";
import { fetchEstateForms } from "../../../../actions/estateFormActions";

import { fetchProbateTypes } from "../../../../actions/probateTypeActions";
import { estateApi } from "../../../../apis";
import { AxiosError } from "axios";

const useStyles = makeStyles()((theme: Theme) => ({
  root: {
    // display: "flex",
  },
  infoContent: {
    fontWeight: "bold",
  },
  button: {
    backgroundColor: theme.palette.primary.light,
    color: theme.palette.primary.contrastText,
  },
  selectWrapper: {
    flexDirection: "row",
    alignItems: "center",
    maxWidth: "500px",
  },
  select: {
    margin: theme.spacing(0, 1, 0, 0),
    minWidth: 120,
  },
}));

interface Props {
  estate?: Estate;
  isAdvisorAdmin?: boolean;
  fetchProbateTypes: () => void;
  fetchEstateForms: (estateId: number) => void;
  probateTypes: ProbateType[];
}

/**
 * This is a separate component since there will probably be more probate details to display in the future
 *
 * @param estate
 * @param isAdvisorAdmin
 * @param fetchProbateTypes
 * @param probateTypes
 */
const _ProbateInfo = ({
  estate,
  fetchProbateTypes,
  fetchEstateForms,
  probateTypes,
}: Props): JSX.Element => {
  const { classes } = useStyles();
  const [initProbateTypeId, setInitProbateTypeId] = useState<number>(
    estate?.probateTypeId || 0
  );
  const [probateTypeId, setProbateTypeId] = useState<number>(
    estate?.probateTypeId || 0
  );
  const [status, setStatus] = useState<string>();

  useEffect(() => {
    (async () => {
      if (!probateTypes) {
        fetchProbateTypes();
      }
    })();
  }, []);

  const stateProbateTypes: ProbateType[] = estate?.stateId
    ? probateTypes?.filter(
        (probateType: ProbateType) => probateType.stateId === estate.stateId
      )
    : [];

  // NOTE: if estates state has been changed after the probate type has been set
  //  it's possible probateTypeId to not be in the range of state stateProbateTypes
  const probateTypeIdWithinRange = stateProbateTypes?.find(
    (probateType: ProbateType) => probateType.id === probateTypeId
  );
  //  when this happens inject that probateType into the stateProbateTypes
  if (!probateTypeIdWithinRange && probateTypeId !== 0) {
    stateProbateTypes?.push(
      new ProbateType(probateTypeId, "probate from another state", 0, "⚠️")
    );
  }

  const updateType = async (
    estateId?: number,
    probateTypeId?: number
  ): Promise<boolean> => {
    if (!estateId) return false;
    try {
      await estateApi.saveProbateType(estateId, probateTypeId || "null");
      fetchEstateForms(estateId);
      return true;
    } catch (error) {
      if ((error as any)?.code === "402") {
        setStatus("Unauthorized");
      } else {
        setStatus("Error");
      }
      return false;
    }
  };

  const hasWriteAccess = estate?.hasWriteAccess;

  return (
    <Grid container spacing={1}>
      <Grid item sm={12}>
        <Grid container spacing={2}>
          <>
            <Grid item xs={12}>
              {/*<Typography variant="body2">Probate Type</Typography>*/}
              {stateProbateTypes?.length ? (
                !hasWriteAccess ? (
                  <Typography>
                    {
                      stateProbateTypes?.find(
                        (probateType: ProbateType) =>
                          estate?.probateTypeId === probateType?.id
                      )?.name
                    }
                  </Typography>
                ) : (
                  <FormControl className={classes.selectWrapper}>
                    <Select
                      value={probateTypeId}
                      onChange={(event) => {
                        setStatus(undefined);
                        setProbateTypeId(event.target.value as number);
                      }}
                      variant="outlined"
                      displayEmpty={true}
                      className={classes.select}
                    >
                      <MenuItem value={0}>
                        <em>Select Probate Type</em>
                      </MenuItem>
                      {stateProbateTypes?.map((probateType: ProbateType) => {
                        return (
                          <MenuItem
                            value={probateType.id}
                            key={probateType.name}
                          >
                            {initProbateTypeId === probateType.id ? (
                              <strong>
                                {probateType.stateName}: {probateType.name}
                              </strong>
                            ) : (
                              `${probateType.stateName}: ${probateType.name}`
                            )}
                          </MenuItem>
                        );
                      })}
                      <MenuItem value={-1}>Reset to NULL</MenuItem>
                    </Select>
                    {estate?.hasWriteAccess && (
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={async () => {
                          if (estate && estate?.id) {
                            if (probateTypeId < 0) {
                              // resetting
                              if (
                                confirm(
                                  "are you sure you want to reset probate type to null?"
                                )
                              ) {
                                if (await updateType(estate?.id)) {
                                  estate.probateTypeId = undefined;
                                  setInitProbateTypeId(0);
                                  setStatus("Saved!");
                                }
                              }
                            } else {
                              if (await updateType(estate?.id, probateTypeId)) {
                                estate.probateTypeId = probateTypeId;
                                setInitProbateTypeId(probateTypeId);
                                setStatus("Saved!");
                              }
                            }
                            setTimeout(() => setStatus(undefined), 8000);
                          }
                        }}
                      >
                        save
                      </Button>
                    )}
                    <Typography style={{ marginLeft: 8 }}>{status}</Typography>
                  </FormControl>
                )
              ) : (
                <Typography>
                  Probate Types have not yet been created for this
                  state/province: <strong>{estate?.state}</strong>
                </Typography>
              )}
            </Grid>
          </>
        </Grid>
      </Grid>
    </Grid>
  );
};

const mapStateToProps = ({
  probateTypes,
}: StoreState): {
  probateTypes: ProbateType[];
} => {
  return { probateTypes };
};

export const ProbateInfo = connect(mapStateToProps, {
  fetchProbateTypes,
  fetchEstateForms,
})(_ProbateInfo);
