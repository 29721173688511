interface genericAnswer {
  text: string;
}

export const doneSoFarOutcomes: { [key: string]: genericAnswer } = {
  deathCert: {
    text: "Ordered death certificates",
  },
  ein: {
    text: "Filed for an estate EIN",
  },
  noticeToCreditors: {
    text: "Submitted Notice to Creditors",
  },
  estateBankAccount: {
    text: "Opened a bank account for the estate’s expenses",
  },
  funeral: {
    text: "Already had the funeral",
  },
  obituary: {
    text: "Posted public obituary online or in a newspaper",
  },
  locatedProbateCourt: {
    text: "Located the correct probate court",
  },
  stoppedSSI: {
    text: "Stopped social security checks",
  },
  frozeCredit: {
    text: "Froze deceased credit",
  }, // TODO: inject deceased name - plural possessive
  changeOfEstateAddress: {
    text: "Filed for change of estate address",
  },
  nothing: {
    text: "Nothing - just started the process",
  },
};

export const helpWithOutcomes: { [key: string]: genericAnswer } = {
  probateForms: {
    text: "Need help finding probate forms",
  },
  speakWithExport: {
    text: "Would like to speak to an expert",
  },
  findAttorney: {
    text: "Need to find an attorney",
  },
  inventoryReports: {
    text: "Need to create a inventory of assets and/or reports",
  },
  somethingElse: {
    text: "Something else",
  },
};

export const estateTeamOutcomes: { [key: string]: genericAnswer } = {
  coExecutor: {
    text: "Another executor (co-executor)",
  },
  beneficiaries: {
    text: "One or more beneficiaries",
  },
  attorney: {
    text: "An attorney",
  },
  funeralHome: {
    text: "Funeral home",
  },
  financialAdvisor: {
    text: "Financial Advisor",
  },
  cpa: {
    text: "CPA",
  },
  insuranceAdvisor: {
    text: "Insurance advisor",
  },
  probateClerk: {
    text: "Probate clerk",
  },
  realEstateAgent: {
    text: "Real estate agent",
  },
  alone: {
    text: "I’m working on this alone",
  },
};

export const estateAssetsOutcomes: { [key: string]: genericAnswer } = {
  rentalRE: {
    text: "Rental real estate property (non-primary residence)",
  },
  investment: {
    text: "Investment / brokerage accounts",
  },
  retirement: {
    text: "Retirement accounts",
  },
  business: {
    text: "Own one or more businesses",
  },
  multiStateAssets: {
    text: "Assets in multiple states",
  },
  highValueEstate: {
    text: "Total estate assets value over {$XYZk}",
  },
  jewelry: {
    text: "Jewelry",
  },
  insuranceAccounts: {
    text: "Insurance accounts",
  },
  vehicles: {
    text: "Vehicles (e.g. boat, plane, car, tractor)",
  },
  collections: {
    text: "Accounts in collections",
  },
  trust: {
    text: "There is a trust",
  },
  none: {
    text: "None of these assets",
  },
};
