import { AssetSubCategory } from "./AssetSubCategory";

export type AssetCategoryJSON = {
  id?: number;
  category?: string;
  sequence?: number;
  sub_categories?: any;
};

export class AssetCategory {
  constructor(
    public id?: number,
    public category?: string,
    public sequence?: number,
    public subCategories?: AssetSubCategory[]
  ) {}
  static fromJSON = (json: AssetCategoryJSON): AssetCategory => {
    return new AssetCategory(
      json.id,
      json.category,
      json.sequence,
      json.sub_categories?.map((subjson: any) =>
        AssetSubCategory.fromJSON(subjson)
      )
    );
  };
  static getCategoryId(
    categories: AssetCategory[],
    categoryName: string
  ): number | undefined {
    return categories?.find(
      (cat: AssetCategory) => cat.category === categoryName
    )?.id;
  }
}
