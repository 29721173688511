export const phoneFormat = (phone?: string): string | undefined => {
  let formatted = phone;
  if (formatted) {
    if (formatted.length === 10) {
      // TODO: handle non US #s
      formatted = "+1" + formatted;
    } else if (formatted.length === 11) {
      formatted = "+" + formatted;
    }
  }
  return formatted;
};
