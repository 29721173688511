import { Dispatch } from "redux";
import { ActionTypes } from "./types";
import { Estate } from "../models/Estate";
import { estateApi } from "../apis";

export interface FetchMockEstatesAction {
  type: ActionTypes.FetchMockEstates;
  payload: Estate[];
}

export const fetchMockEstates = () => {
  return async (dispatch: Dispatch): Promise<void> => {
    let estates: Estate[] = [];
    try {
      const estatesWithTotal = await estateApi.fetchMockEstates();
      estates = estatesWithTotal.estates;
    } catch (error) {
      console.error(error);
    }
    dispatch<FetchMockEstatesAction>({
      type: ActionTypes.FetchMockEstates,
      payload: estates,
    });
  };
};
