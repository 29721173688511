import { Dispatch } from "redux";
import { ActionTypes } from "./types";
import { authApi } from "../apis";
import { User } from "../models/User";
import { NavigateFunction } from "react-router";
import { SCREEN_PATHS } from "../constants";

export interface SetUserAction {
  type: ActionTypes.SetUser;
  payload: User | null;
}

export const setUser = (user: User | null): SetUserAction => {
  return {
    type: ActionTypes.SetUser,
    payload: user,
  };
};

export interface AppSignOutAction {
  type: ActionTypes.AppSignOut;
  payload: null;
}

export const appSignOut = (user?: User, navigate?: NavigateFunction) => {
  return async (dispatch: Dispatch): Promise<void> => {
    if (user) {
      await user?.signOut();
    }
    dispatch<AppSignOutAction>({
      type: ActionTypes.AppSignOut,
      payload: null,
    });
    if (navigate) {
      window.setTimeout(() => {
        navigate(SCREEN_PATHS.login);
      }, 100);
    }
  };
};

export const getUser = () => {
  return async (dispatch: Dispatch): Promise<void> => {
    const user = await authApi.getUser();
    dispatch<SetUserAction>({
      type: ActionTypes.SetUser,
      payload: user,
    });
  };
};
