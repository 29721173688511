import { TaskPhase } from "../../../../models/TaskPhase";
import { Task } from "../../../../models/Task";

type Sequenced = TaskPhase | Task;

export class TaskUtils {
  /**
   * solves for gaps in sequence: [1][2][4][5]
   * solves for duplicates in sequence: [1][2][2][3]
   *  loop through all the task phases setting the sequence numbers properly
   *
   * @param tasks: TaskPhases[] | Task[]
   */
  static cleanUpSequences(tasks: Sequenced[]): Sequenced[] {
    // console.log("cleanUpSequences init", tasks);
    const cleaned: Sequenced[] = [];
    // 1. sort them by sequence
    // 2. step through each, checking the sequence number
    //  a. if correct add to newTaskPhases
    //  b. if not set the expect sequence and then add to newTaskPhases
    const sorted = tasks.sort(
      (a: Sequenced, b: Sequenced) => a.sequence - b.sequence
    );
    let seq = 1;
    sorted.forEach((sequenced: Sequenced) => {
      if (sequenced instanceof TaskPhase && sequenced.tasks) {
        // console.log("cleaning tasks");
        sequenced.tasks = this.cleanUpSequences(sequenced.tasks) as Task[];
      }
      if (sequenced.sequence !== seq) {
        sequenced.sequence = seq;
      }
      cleaned.push(sequenced);
      seq++;
    });
    // console.log("cleanUpSequences cleaned", cleaned);
    return cleaned;
  }
}
