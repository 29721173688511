import React from "react";
import {
  Box,
  Button,
  Grid,
  Stack,
  InputAdornment,
  MenuItem,
  Select,
  SvgIcon,
  TextField,
  Typography,
  // Theme,
} from "@mui/material";
import { connect } from "react-redux";
const Yup = require("yup")
import { Form, Formik, FormikHelpers } from "formik";
import { AdvisorGroup } from "../../../models/AdvisorGroup";
import { ASSIGNABLE_ROLES, ROLE } from "../../../constants";
import { StoreState } from "../../../reducers";
import { logger } from "../../../apis";
import { Search as SearchIcon } from "react-feather";

// const useStyles = makeStyles()((theme: Theme) => ({
//   root: {},
//   inputs: {
//     backgroundColor: theme.palette.background.paper,
//   },
// }));

interface Values {
  terms: string;
  group: string;
  role: string;
}

interface Props {
  advisorGroups: AdvisorGroup[];
  onFilter: (groupId?: number, roleId?: number) => void;
  onSearch: (terms?: string) => void;
}

const _AllAdvisorsFilter = ({
  advisorGroups,
  onFilter,
  onSearch,
}: Props): JSX.Element => {
  // const { classes } = useStyles();

  // https://formik.org/docs/api/formik
  const formikInit = {
    values: {
      terms: "",
      group: "",
      role: "",
    },
    schema: Yup.object().shape({
      terms: Yup.string(),
      group: Yup.string(),
      role: Yup.string(),
    }),
  };

  const handleSubmit = async (
    values: Values,
    { setFieldValue }: FormikHelpers<Values>
  ) => {
    try {
      if (values.terms) {
        setFieldValue("role", "");
        setFieldValue("group", "");
        onFilter(undefined, undefined);
        onSearch(values.terms);
      } else {
        // send chosen values to parent to be applied to table filter
        const group = advisorGroups.filter(
          (advisorGroup: AdvisorGroup) =>
            values.group !== "" && advisorGroup.title === values.group
        )[0];
        const role = ASSIGNABLE_ROLES.filter(
          (role: ROLE) => role.title === values.role
        )[0];
        onSearch(undefined);
        onFilter(group?.id, role?.id);
      }
      logger.debug(JSON.stringify(values));
    } catch (error) {
      logger.error(error as Error);
    }
  };

  /**
   * single form with terms input, group select, role select
   *  terms input and enter: clear select, run advisor name search
   *  group select: clear terms input (don't clear role select), run group filter
   *  role select: clear terms input (don't clear group select), run role filter
   */
  return (
    <Formik
      initialValues={formikInit.values}
      validationSchema={formikInit.schema}
      onSubmit={handleSubmit}
    >
      {({ handleChange, setFieldValue, values, submitForm, resetForm }) => (
        <Form>
          <Box marginBottom={2} width="100%">
            <Grid container spacing={{ xs: 1, lg: 2 }}>
              <Grid item xs={12} sm={12} lg={4}>
                <Box display="flex" alignItems="center">
                  <TextField
                    sx={{
                      mr: 1,
                      "& input": {
                        p: "10px",
                      },
                    }}
                    fullWidth
                    name="terms"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <SvgIcon fontSize="small" color="action">
                            <SearchIcon />
                          </SvgIcon>
                        </InputAdornment>
                      ),
                      margin: "dense",
                    }}
                    placeholder={`Search Advisors`}
                    variant="outlined"
                    value={values.terms}
                    onChange={handleChange}
                  />{" "}
                  <Typography
                    sx={{ lineHeight: "1.25rem", fontStyle: "italic" }}
                  >
                    or filter
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={12} sm={5} lg={2}>
                <Select
                  name="group"
                  value={values.group}
                  onChange={(event) => {
                    handleChange(event);
                    setFieldValue("terms", "");
                    submitForm();
                  }}
                  fullWidth
                  variant="outlined"
                  autoWidth={true}
                  displayEmpty={true}
                  renderValue={() => values.group || "all groups"}
                >
                  <MenuItem value={0}>
                    <em>all groups</em>
                  </MenuItem>
                  {advisorGroups?.map((advisorGroup: AdvisorGroup) => {
                    return advisorGroup?.title ? (
                      <MenuItem
                        value={advisorGroup?.title}
                        key={advisorGroup?.id}
                      >
                        {advisorGroup?.title || "----"}
                      </MenuItem>
                    ) : null;
                  })}
                </Select>
              </Grid>
              <Grid item xs={12} sm={5} lg={2}>
                <Select
                  name="role"
                  value={values.role}
                  onChange={(event) => {
                    handleChange(event);
                    setFieldValue("terms", "");
                    submitForm();
                  }}
                  fullWidth
                  variant="outlined"
                  autoWidth={true}
                  renderValue={() => values.role || "all roles"}
                  displayEmpty={true}
                >
                  <MenuItem value={0}>
                    <em>all roles</em>
                  </MenuItem>
                  {ASSIGNABLE_ROLES.map((role: ROLE) => {
                    return (
                      <MenuItem value={role.title} key={role.id}>
                        {role.title}
                      </MenuItem>
                    );
                  })}
                </Select>
              </Grid>
              <Grid item xs={12} sm={2} lg={2}>
                <Stack direction={"row"} spacing={{ xs: 1, sm: 2 }}>
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={() => {
                      onSearch(undefined);
                      onFilter(undefined, undefined);
                      resetForm();
                    }}
                  >
                    Reset
                  </Button>
                  <Button type="submit" style={{ visibility: "hidden" }}>
                    Submit
                  </Button>
                </Stack>
              </Grid>
              {/* <Grid item sm={7} xs={12}>
                <Box flexGrow={1} display="flex" alignItems="center">
                  <Select
                    name="group"
                    value={values.group}
                    onChange={(event) => {
                      handleChange(event);
                      setFieldValue("terms", "");
                      submitForm();
                    }}
                    variant="outlined"
                    autoWidth={true}
                    displayEmpty={true}
                    renderValue={() => values.group || "all groups"}
                    className={classes.select}
                    style={{ minWidth: 240 }}
                  >
                    <MenuItem value={0}>
                      <em>all groups</em>
                    </MenuItem>
                    {advisorGroups?.map((advisorGroup: AdvisorGroup) => {
                      return advisorGroup?.title ? (
                        <MenuItem
                          value={advisorGroup?.title}
                          key={advisorGroup?.id}
                        >
                          {advisorGroup?.title || "----"}
                        </MenuItem>
                      ) : null;
                    })}
                  </Select>
                  <Select
                    name="role"
                    value={values.role}
                    onChange={(event) => {
                      handleChange(event);
                      setFieldValue("terms", "");
                      submitForm();
                    }}
                    variant="outlined"
                    autoWidth={true}
                    renderValue={() => values.role || "all roles"}
                    displayEmpty={true}
                    className={classes.select}
                    style={{ minWidth: 120 }}
                  >
                    <MenuItem value={0}>
                      <em>all roles</em>
                    </MenuItem>
                    {ASSIGNABLE_ROLES.map((role: ROLE) => {
                      return (
                        <MenuItem value={role.title} key={role.id}>
                          {role.title}
                        </MenuItem>
                      );
                    })}
                  </Select>
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={() => {
                      onSearch(undefined);
                      onFilter(undefined, undefined);
                      resetForm();
                    }}
                    className={classes.button}
                  >
                    Reset
                  </Button>
                  <Button type="submit" style={{ visibility: "hidden" }}>
                    Submit
                  </Button>
                </Box>
              </Grid> */}
            </Grid>
          </Box>
        </Form>
      )}
    </Formik>
  );
};

const mapStateToProps = ({
  advisorGroups,
}: StoreState): {
  advisorGroups: AdvisorGroup[];
} => {
  return { advisorGroups };
};

export const AllAdvisorsFilter = connect(mapStateToProps)(_AllAdvisorsFilter);
