import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";

export const Invite = (): JSX.Element => {
  const navigate = useNavigate();
  const { inviteId } = useParams();

  let inviteParamString = "";

  if (inviteId) {
    inviteParamString = `?invite=${inviteId}`;
  }

  useEffect(() => {
    navigate(`/login${inviteParamString}`);
  })

  return <></>;
};
