import { Dispatch } from "redux";
import { ActionTypes } from "./types";
import { formsApi } from "../apis";
import { FormType } from "../models/FormType";

export interface FetchFormTypesAction {
  type: ActionTypes.FetchFormTypes;
  payload: FormType[] | null;
}

export const fetchFormTypes = () => {
  return async (dispatch: Dispatch): Promise<void> => {
    let formTypes: FormType[] | null = null;
    try {
      formTypes = await formsApi.fetchFormTypes();
    } catch (error) {
      console.error(error);
    }
    dispatch<FetchFormTypesAction>({
      type: ActionTypes.FetchFormTypes,
      payload: formTypes,
    });
  };
};

export interface FetchSingleFormTypeAction {
  type: ActionTypes.FetchSingleFormType;
  payload: FormType | null;
}

export const fetchSingleFormType = (formTypeId: number) => {
  return async (dispatch: Dispatch): Promise<void> => {
    let formType: FormType | null = null;
    try {
      formType = await formsApi.fetchFormType(formTypeId);
    } catch (error) {
      console.error(error);
    }
    dispatch<FetchSingleFormTypeAction>({
      type: ActionTypes.FetchSingleFormType,
      payload: formType,
    });
  };
};
