import React from "react";
import {
  Button,
  ButtonGroup,
  Popper,
  Paper,
  ClickAwayListener,
  Grow,
  MenuItem,
  MenuList,
  SxProps,
  Theme,
} from "@mui/material";
import { PopperPlacementType } from "@mui/material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";

export interface SplitButtonOption {
  label: string;
  key: string;
  disabled?: boolean;
  hidden?: boolean;
  onClick: (event: any) => void;
}

interface Props {
  options: SplitButtonOption[];
  variant?: "text" | "contained" | "outlined";
  size?: "small" | "medium" | undefined;
  color?:
    | "inherit"
    | "primary"
    | "secondary"
    | "success"
    | "error"
    | "info"
    | "warning";
  popperPlacement?: PopperPlacementType;
  defaultKey?: string;
  fullWidth?: boolean;
  sx?: SxProps<Theme>;
}

export const SplitButtonGroup = ({
  options,
  variant,
  color,
  size,
  popperPlacement,
  defaultKey,
  fullWidth = false,
  sx,
}: Props): JSX.Element => {
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef<HTMLDivElement>(null);

  const defaultButtonIndex = defaultKey
    ? options.findIndex((option) => option.key === defaultKey)
    : 0;
  const [selectedIndex, setSelectedIndex] = React.useState(defaultButtonIndex);

  const handleMenuItemClick = (
    event: React.MouseEvent<HTMLLIElement, MouseEvent>,
    index: number
  ) => {
    setSelectedIndex(index);
    setOpen(false);
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event: any) => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target as HTMLElement)
    ) {
      return;
    }

    setOpen(false);
  };

  return (
    <>
      <ButtonGroup
        fullWidth={fullWidth}
        variant={variant}
        size={size}
        ref={anchorRef}
        aria-label="split button"
        sx={sx}
      >
        <Button
          onClick={options[selectedIndex].onClick}
          disabled={options[selectedIndex].disabled}
          color={color ? color : undefined}
          fullWidth
        >
          {options[selectedIndex].label}
        </Button>
        {options.length > 1 && (
          <Button
            size="small"
            aria-controls={open ? "split-button-menu" : undefined}
            aria-expanded={open ? "true" : undefined}
            aria-label="select action"
            aria-haspopup="menu"
            color={color ? color : undefined}
            onClick={handleToggle}
          >
            <ArrowDropDownIcon />
          </Button>
        )}
      </ButtonGroup>
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        placement={popperPlacement || "bottom"}
        role={undefined}
        transition
        disablePortal
        sx={{
          zIndex: 1,
        }}
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: placement.includes("bottom")
                ? "center top"
                : "center bottom",
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList id="split-button-menu">
                  {options.map((option, index) => {
                    if (!option.disabled) {
                      return (
                        <MenuItem
                          key={option.key}
                          disabled={option.disabled}
                          selected={index === selectedIndex}
                          onClick={(event) => handleMenuItemClick(event, index)}
                        >
                          {option.label}
                        </MenuItem>
                      );
                    }
                  })}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
};
