import React from "react";
import { Box, Tab, Tabs, Theme } from "@mui/material";
import { makeStyles } from "tss-react/mui";
import { useLocation, useNavigate } from "react-router-dom";
import { EstateScreen, estateNavItems } from "./estateNavItems";
import { User } from "../../../models/User";
import { connect } from "react-redux";
import { StoreState } from "../../../reducers";

function a11yProps(title: string) {
  return {
    id: `estate-${title}`,
    "aria-controls": `estate-tab-${title}`,
  };
}

const useStyles = makeStyles()((theme: Theme) => ({
  root: {
    margin: theme.spacing(3, 0),
  },
  tabs: {
    marginTop: -20,
  },
  tab: {
    fontWeight: 600,
    fontSize: 16,
    lineHeight: "24px",
    color: theme.palette.text.secondary,
    minWidth: "auto",
    padding: "6px 0 5px",
    margin: "0 32px 0 0",
    "&.Mui-selected": {
      color: `${theme.palette.primary.main}`,
    },
  },
  tabsBottom: {
    height: 4,
    backgroundColor: `${theme.palette.grey["300"]}`,
    position: "relative",
    top: -4,
    zIndex: 10,
  },
}));

interface Props {
  user?: User;
}

const _EstateNavBar = ({ user }: Props): JSX.Element => {
  const { classes } = useStyles();
  const location = useLocation();
  const navigate = useNavigate();
  const pathParts = location.pathname.split("/");

  const hasFormsAccess = user?.advisorGroup?.hasAdvisorAccessToForms;

  let estateNavItemsToDisplay = estateNavItems;

  // If forms is not allowed, remove forms from nav items
  if (!hasFormsAccess) {
    estateNavItemsToDisplay = estateNavItemsToDisplay.filter(
      (item) => item.path !== "forms-and-files"
    );
  }

  let navPathIndex = estateNavItemsToDisplay.findIndex(
    (screen: EstateScreen) => pathParts[4] === screen.path
  );
  if (navPathIndex === -1) navPathIndex = 0;
  const [selectedIndex, setSelectedIndex] = React.useState(0);

  if (selectedIndex !== navPathIndex) {
    setSelectedIndex(navPathIndex);
  }

  const handleNavigate = (navIndex: number) => {
    navigate(`${estateNavItems[navIndex].path}`);
  };

  return (
    <Box className={classes.root}>
      <Tabs
        value={selectedIndex}
        indicatorColor="primary"
        variant="scrollable"
        scrollButtons="auto"
        className={classes.tabs}
        aria-label="Estate details"
      >
        {estateNavItemsToDisplay.map((screen: EstateScreen, index) => {
          return (
            <Tab
              component="a"
              className={classes.tab}
              label={screen.title}
              {...a11yProps(screen.title)}
              onClick={() => handleNavigate(index)}
              disableRipple={true}
              key={screen.title}
            />
          );
        })}
      </Tabs>
      <div className={classes.tabsBottom} />
    </Box>
  );
};

const mapStateToProps = ({
  user,
}: StoreState): {
  user: User;
} => {
  return {
    user,
  };
};

export const EstateNavBar = connect(mapStateToProps)(_EstateNavBar);
