import { ZeroStateBody } from "../shared/ZeroStateBody";
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Grid,
  Theme,
  Typography,
} from "@mui/material";
import { makeStyles } from "tss-react/mui";
import React from "react";
import { useNavigate } from "react-router-dom";

const useStyles = makeStyles()((theme: Theme) => ({
  root: {
    textAlign: "center",
    padding: theme.spacing(2),
  },
  innerCard: {
    boxShadow: "none",
    backgroundColor: theme.palette.background.default,
    margin: "10px 0 0",
  },
  innerCardHeader: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
  },
}));

const zeroImagePrefix = "/static/images/zero/opportunities";

interface Props {
  onAddOpp: () => void;
  disableEdit?: boolean;
}

export const OpportunitiesZeroState = ({
  onAddOpp,
  disableEdit = false,
}: Props): JSX.Element => {
  const { classes } = useStyles();
  const navigate = useNavigate();

  return (
    <div className={classes.root}>
      <Grid container spacing={2} style={{ alignItems: "center" }}>
        <Grid item sm={12} lg={6}>
          <ZeroStateBody
            text="We'll let you know as soon as new relationship opportunities pop up."
            imageAlt="No Opportunities"
            imagePrefix={zeroImagePrefix}
          />
        </Grid>
        <Grid item sm={12} lg={6}>
          <Card className={classes.innerCard}>
            <CardHeader
              className={classes.innerCardHeader}
              title="Pro tips"
              disableTypography={true}
            />
            <CardContent>
              <Button
                color="primary"
                onClick={() => {
                  navigate("/app/invite-client");
                }}
              >
                Begin by inviting a client
              </Button>
              {!disableEdit && (
                <>
                  <br />
                  <Typography variant="body2">Or</Typography>
                  <br />
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={onAddOpp}
                  >
                    Record an opportunity
                  </Button>
                </>
              )}
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </div>
  );
};
