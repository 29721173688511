// https://en.wikipedia.org/wiki/List_of_ISO_639-1_codes
export enum LanguageCodes {
  english = "en",
  french = "fr",
  german = "de",
  spanish = "es",
  vietnamese = "vi",
  japanese = "ja",
  korean = "ko",
  tagalog = "tl",
  chinese = "zh",
}

export type LanguageJson = {
  name: string;
  code: string;
  id: number;
};

export class Language {
  constructor(public name: string, public code: string, public id: number) {}

  static fromJSON(json: LanguageJson): Language {
    return new Language(json.name, json.code, json.id);
  }

  toJSON(): LanguageJson {
    return {
      name: this.name,
      code: this.code,
      id: this.id,
    };
  }
}
