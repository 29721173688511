import { Dispatch } from "redux";
import { ActionTypes } from "./types";
import { financialConvosApi } from "../apis";
import { FinancialConvo } from "../models/FinancialConvo";

export interface FetchFinancialConvosAction {
  type: ActionTypes.FetchFinancialConvos;
  payload: FinancialConvo[] | null;
}

export const fetchFinancialConvos = () => {
  return async (dispatch: Dispatch): Promise<void> => {
    let financialConvos: FinancialConvo[] | null = null;
    try {
      financialConvos = await financialConvosApi.fetchFinancialConvos();
    } catch (error) {
      console.error(error);
    }
    dispatch<FetchFinancialConvosAction>({
      type: ActionTypes.FetchFinancialConvos,
      payload: financialConvos,
    });
  };
};

export interface FetchSingleFinancialConvo {
  type: ActionTypes.FetchSingleFinancialConvo;
  payload: FinancialConvo | null;
}

export const fetchSingleFinancialConvo = (financialConvoId: number) => {
  return async (dispatch: Dispatch): Promise<void> => {
    let financialConvo: FinancialConvo | null = null;
    try {
      financialConvo = await financialConvosApi.fetchSingleFinancialConvo(
        financialConvoId
      );
    } catch (error) {
      console.error(error);
    }
    dispatch<FetchSingleFinancialConvo>({
      type: ActionTypes.FetchSingleFinancialConvo,
      payload: financialConvo,
    });
  };
};
