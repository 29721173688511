import React from "react";
import {
  Grid,
  Stack,
  TextField,
  Button,
  Select,
  FormControl,
  InputLabel,
  MenuItem,
  FormHelperText,
  Typography,
  Box,
} from "@mui/material";
import { CircularProgress } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

import { Formik, FormikHelpers, getIn } from "formik";
const Yup = require("yup")
import moment from "moment";
import {
  EstateLaravelized,
  EstateLaravelizedJSON,
} from "../../../../models/EstateLaravelized";
import LoadingButton from "@mui/lab/LoadingButton";
import { connect } from "react-redux";
import {
  Country,
  State,
  stateLabelMappingsById,
} from "../../../../models/Geography";

// Redux Imports
import { StoreState } from "../../../../reducers";

import { nameSuffixes, booleanVerbose } from "./answerOptions";
import { UpdateStatus } from "./EstateOverviewEditDialog";
import { AxiosError } from "axios";

interface Props {
  estate?: EstateLaravelized;
  onComplete: (estateId: number, estate: EstateLaravelized) => void;
  countries: Country[];
  states: State[];
}

function _EstateOverviewEstateEditForm({
  estate,
  onComplete,
  countries,
  states,
}: Props): JSX.Element {
  const [statusDisplay, setStatusDisplay] = React.useState<
    UpdateStatus | undefined
  >(undefined);

  const submitForm = async (
    values: EstateLaravelizedJSON,
    { setSubmitting, setStatus }: FormikHelpers<EstateLaravelizedJSON>
  ) => {
    setSubmitting(true);
    setStatus(undefined);
    setStatusDisplay(undefined);
    let stat: UpdateStatus | undefined = undefined;

    try {
      estate &&
        (await onComplete(estate.id, EstateLaravelized.fromJSON(values)));
      stat = {
        success: true,
        message: "Estate has been updated successfully!",
      };
    } catch (e: any) {
      const error: AxiosError = e;
      const errorData = error.response?.data as any;
      stat = {
        success: false,
        message: errorData?.message,
        errors: errorData?.errors,
      };
    }
    setStatus(stat);
    setStatusDisplay(stat);
    setSubmitting(false);
  };

  const maxPassedDate = moment(undefined, true);
  const minPassedDate = moment("0000-01-01", true);

  const formikInit = {
    values: estate?.toJSON() as EstateLaravelizedJSON,
    schema: Yup.object().shape({
      deceased_first_name: Yup.string().required("Required"),
      deceased_middle_name: Yup.string().nullable(),
      deceased_last_name: Yup.string().required("Required"),
      deceased_suffix: Yup.string().nullable(),
      passed_on: Yup.date()
        .nullable()
        .transform((curr, orig) => (orig === "" ? null : curr)) // Black Magic for Nullable MUI DateTime
        .typeError("Invalid date")
        .max(
          maxPassedDate.toDate(),
          "User has not died yet. Please select a more recent date, or leave blank."
        )
        .min(minPassedDate.toDate(), "Cannot use a date before 1900."),
      was_veteran: Yup.string().nullable(),
      country_id: Yup.number().required("Required"),
      state_id: Yup.number().required("Required"),
    }),
  };

  return (
    <>
      <Typography variant="h3" sx={{ marginBottom: "1rem" }}>
        Estate Information
      </Typography>
      {estate ? (
        <>
          <Formik
            initialValues={formikInit.values}
            validationSchema={formikInit.schema}
            onSubmit={submitForm}
            enableReinitialize
          >
            {({
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting,
              values,
              errors,
              touched,
              setFieldValue,
              dirty,
              resetForm,
            }) => (
              <form onSubmit={handleSubmit}>
                <>
                  <Grid container spacing={2} sx={{ marginBottom: "1rem" }}>
                    <Grid item xs={12} sm={3}>
                      <FormControl variant="outlined" fullWidth>
                        <TextField
                          name="deceased_first_name"
                          label="Deceased First Name"
                          value={values.deceased_first_name || ""}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          variant="outlined"
                          error={
                            getIn(errors, "deceased_first_name") &&
                            getIn(touched, "deceased_first_name")
                              ? true
                              : false
                          }
                          helperText={
                            getIn(errors, "deceased_first_name") &&
                            getIn(touched, "deceased_first_name")
                              ? getIn(errors, "deceased_first_name")
                              : undefined
                          }
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={3}>
                      <FormControl variant="outlined" fullWidth>
                        <TextField
                          name="deceased_middle_name"
                          label="Deceased Middle Name"
                          value={values.deceased_middle_name || ""}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          variant="outlined"
                          error={
                            getIn(errors, "deceased_middle_name") &&
                            getIn(touched, "deceased_middle_name")
                              ? true
                              : false
                          }
                          helperText={
                            getIn(errors, "deceased_middle_name") &&
                            getIn(touched, "deceased_middle_name")
                              ? getIn(errors, "deceased_middle_name")
                              : undefined
                          }
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={3}>
                      <FormControl variant="outlined" fullWidth>
                        <TextField
                          name="deceased_last_name"
                          label="Deceased Last Name"
                          value={values.deceased_last_name || ""}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          variant="outlined"
                          error={
                            getIn(errors, "deceased_last_name") &&
                            getIn(touched, "deceased_last_name")
                              ? true
                              : false
                          }
                          helperText={
                            getIn(errors, "deceased_last_name") &&
                            getIn(touched, "deceased_last_name")
                              ? getIn(errors, "deceased_last_name")
                              : undefined
                          }
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={3}>
                      <FormControl
                        variant="outlined"
                        fullWidth
                        error={
                          getIn(errors, "deceased_suffix") &&
                          getIn(touched, "deceased_suffix")
                            ? true
                            : false
                        }
                      >
                        <InputLabel id="deceased_suffix">
                          Deceased Suffix
                        </InputLabel>
                        <Select
                          name="deceased_suffix"
                          value={values.deceased_suffix || ""}
                          label="Deceased Suffix"
                          onChange={handleChange}
                          onBlur={handleBlur}
                        >
                          <MenuItem value="">
                            <em>Clear</em>
                          </MenuItem>
                          {nameSuffixes?.map((option: string) => {
                            return (
                              <MenuItem key={option} value={option}>
                                {option}
                              </MenuItem>
                            );
                          })}
                        </Select>
                        <FormHelperText>
                          {getIn(errors, "deceased_suffix") &&
                          getIn(touched, "deceased_suffix")
                            ? getIn(errors, "deceased_suffix")
                            : undefined}
                        </FormHelperText>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <DatePicker
                        label="Date of Death"
                        format="MM/DD/YYYY"
                        minDate={minPassedDate}
                        maxDate={maxPassedDate}
                        sx={{
                          width: "100%",
                        }}
                        slotProps={{
                          textField: {
                            error:
                              getIn(errors, "passed_on") &&
                              getIn(touched, "passed_on")
                                ? true
                                : false,
                            helperText:
                              getIn(errors, "passed_on") &&
                              getIn(touched, "passed_on")
                                ? getIn(errors, "passed_on")
                                : undefined,
                          },
                        }}
                        value={
                          values.passed_on
                            ? moment(values.passed_on, true) || null
                            : null
                        }
                        onChange={(newDate) => {
                          if (newDate) {
                            setFieldValue("passed_on", newDate);
                          } else {
                            setFieldValue("passed_on", null);
                          }
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <FormControl
                        variant="outlined"
                        fullWidth
                        error={
                          getIn(errors, "was_veteran") &&
                          getIn(touched, "was_veteran")
                            ? true
                            : false
                        }
                      >
                        <InputLabel>Was a Veteran?</InputLabel>
                        <Select
                          name="was_veteran"
                          value={values.was_veteran || ""}
                          label="Was a veteran?"
                          onChange={handleChange}
                          onBlur={handleBlur}
                        >
                          <MenuItem value="">
                            <em>Clear</em>
                          </MenuItem>
                          {booleanVerbose?.map((option: string) => {
                            return (
                              <MenuItem key={option} value={option}>
                                {option}
                              </MenuItem>
                            );
                          })}
                        </Select>
                        <FormHelperText>
                          {getIn(errors, "was_veteran") &&
                          getIn(touched, "was_veteran")
                            ? getIn(errors, "was_veteran")
                            : undefined}
                        </FormHelperText>
                      </FormControl>
                    </Grid>
                  </Grid>
                  <Grid container spacing={2} sx={{ marginBottom: "1rem" }}>
                    <Grid item xs={12} sm={6}>
                      <FormControl
                        variant="outlined"
                        fullWidth
                        error={
                          getIn(errors, "country_id") &&
                          getIn(touched, "country_id")
                            ? true
                            : false
                        }
                      >
                        <InputLabel>Deceased Domicile Country</InputLabel>
                        <Select
                          name="country_id"
                          value={values.country_id?.toString() || ""}
                          label="Deceased Domicile Country"
                          disabled={!countries}
                          onChange={(event: any) => {
                            handleChange(event);
                            setFieldValue("state_id", "");
                          }}
                          onBlur={handleBlur}
                        >
                          {countries?.map((country: Country) => {
                            return (
                              <MenuItem key={country.id} value={country.id.toString()}>
                                {country.name}
                              </MenuItem>
                            );
                          })}
                        </Select>
                        <FormHelperText>
                          {getIn(errors, "country_id") &&
                          getIn(touched, "country_id")
                            ? getIn(errors, "country_id")
                            : undefined}
                        </FormHelperText>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <FormControl
                        fullWidth
                        variant="outlined"
                        disabled={!values.country_id}
                        error={
                          getIn(errors, "state_id") &&
                          getIn(touched, "state_id")
                            ? true
                            : false
                        }
                      >
                        <InputLabel>
                          {`Deceased Domicile ${
                            values.country_id
                              ? stateLabelMappingsById[values.country_id]
                              : "State"
                          }`}
                        </InputLabel>
                        <Select
                          name="state_id"
                          label={`Deceased Domicile ${
                            values.country_id
                              ? stateLabelMappingsById[values.country_id]
                              : "State"
                          }`}
                          value={values.state_id?.toString() || ""}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          variant="outlined"
                        >
                          <MenuItem value="">
                            <em>Clear</em>
                          </MenuItem>
                          {states
                            ?.filter((s) => s.countryId == values?.country_id)
                            .map((state: State) => {
                              return (
                                <MenuItem key={state.id} value={state.id?.toString()}>
                                  {state.name}
                                </MenuItem>
                              );
                            })}
                        </Select>
                        <FormHelperText>
                          {getIn(errors, "state_id") &&
                          getIn(touched, "state_id")
                            ? getIn(errors, "state_id")
                            : undefined}
                        </FormHelperText>
                      </FormControl>
                    </Grid>
                  </Grid>
                  {statusDisplay && (
                    <Box sx={{ marginBottom: "1rem" }}>
                      <Typography
                        variant="body1"
                        sx={{
                          color: statusDisplay.success
                            ? "success.main"
                            : "error.main",
                        }}
                      >
                        {statusDisplay.message}
                      </Typography>
                      {statusDisplay.success === false && (
                        <>
                          <Typography
                            variant="body2"
                            sx={{
                              color: statusDisplay.success
                                ? "success.main"
                                : "error.main",
                            }}
                          >
                            {statusDisplay.errors && Object.values(statusDisplay.errors).map(
                              (value) => value
                            )}
                          </Typography>
                        </>
                      )}
                    </Box>
                  )}
                  <Stack direction="row" spacing={2}>
                    <Button
                      variant="outlined"
                      disabled={isSubmitting || !dirty}
                      onClick={() => resetForm()}
                    >
                      Reset
                    </Button>
                    <LoadingButton
                      variant="contained"
                      loading={isSubmitting}
                      disabled={!dirty || isSubmitting}
                      type="submit"
                    >
                      Save Estate Info
                    </LoadingButton>
                  </Stack>
                </>
              </form>
            )}
          </Formik>
        </>
      ) : (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <CircularProgress />
        </Box>
      )}
    </>
  );
}

const mapStateToProps = ({
  countries,
  states,
}: StoreState): {
  countries: Country[];
  states: State[];
} => {
  return { countries, states };
};

const EstateOverviewEstateEditForm = connect(mapStateToProps)(
  _EstateOverviewEstateEditForm
);

export default EstateOverviewEstateEditForm;
