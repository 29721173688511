import React from "react";
import { Card, CardContent, Container, Theme, Typography } from "@mui/material";
import { makeStyles } from "tss-react/mui";
import Page from "../../../components/Page";
import ContentHeader from "../../shared/ContentHeader";
import { StoreState } from "../../../reducers";
import { User } from "../../../models/User";
import { connect } from "react-redux";
import { ATTICUS_PAGE_PREFIX } from "../../../constants";
import { SupportForm } from "./SupportForm";

const useStyles = makeStyles()((theme: Theme) => ({
  root: {
    backgroundColor: theme.palette.background.default,
    minHeight: "100%",
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
}));

interface Props {
  user?: User;
}

const _SupportView = ({ user }: Props): JSX.Element => {
  const { classes } = useStyles();

  return (
    <Page
      className={classes.root}
      title={`${ATTICUS_PAGE_PREFIX} - Support`}
      data-automation-id="page-content-support"
    >
      <Container maxWidth={false}>
        <ContentHeader heading="Support" subHeading="" showSearch={false} />
        <Card>
          <CardContent>
            <Typography variant="h3">How can we help you?</Typography>
            <SupportForm user={user} />
          </CardContent>
        </Card>
      </Container>
    </Page>
  );
};

const mapStateToProps = ({ user }: StoreState): { user: User } => {
  return { user };
};

export const SupportView = connect(mapStateToProps)(_SupportView);
