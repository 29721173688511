import React from "react";
import { Link as RouterLink } from "react-router-dom";
import { AppBar, Toolbar } from "@mui/material";
import { Logo } from "../../components/Logo";

const TopBar = (): JSX.Element => {
  return (
    <AppBar
      id="NoAuth/TopBar"
      sx={{
        backgroundColor: "background.paper",
      }}
      elevation={0}
    >
      <Toolbar
        sx={{
          height: 64,
        }}
      >
        <RouterLink to="/">
          <Logo />
        </RouterLink>
      </Toolbar>
    </AppBar>
  );
};

export default TopBar;
