import React from "react";
import { useNavigate } from "react-router-dom";
import { authApi } from "../../apis";
import { SCREEN_PATHS } from "../../constants";

export const SignOut = (): JSX.Element => {
  const navigate = useNavigate();

  const signOut = async () => {
    await authApi.signOut();
    window.setTimeout(() => {
      navigate(SCREEN_PATHS.login, { replace: true });
    }, 0);
  };

  signOut();

  return <></>;
};
