export type OpportunityStatusJSON = {
  id?: number;
  title?: string;
};

export class OpportunityType {
  static path = "/opportunity-types";
  constructor(public id?: number, public title?: string) {}

  static fromJSON(json: OpportunityStatusJSON): OpportunityType {
    return new OpportunityType(json.id, json.title);
  }

  static fromTitle(
    oppTypes: OpportunityType[],
    title: string
  ): OpportunityType | undefined {
    return oppTypes.find((oppType: OpportunityType) => oppType.title === title);
  }

  static fromId(
    oppTypes: OpportunityType[],
    id: number
  ): OpportunityType | undefined {
    return oppTypes.find((oppType: OpportunityType) => oppType.id === id);
  }
}
