import { ActionTypes } from "../actions/types";
import { AssetImage } from "../models/AssetImage";
import { AnyAction } from "redux";

export const assetImageCacheReducer = (
  images: AssetImage[] = [],
  action: AnyAction
): AssetImage[] => {
  switch (action.type) {
    case ActionTypes.CacheAssetImage:
      if (images && action.payload.assetImage) {
        return [...images, action.payload.assetImage];
      } else {
        return images;
      }
    case ActionTypes.AppSignOut:
      return [];
    default:
      return images;
  }
};
