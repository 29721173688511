export type OpportunityStatusJSON = {
  id?: number;
  title?: string;
};

export class OpportunityStatus {
  static path = "/opportunity-statuses";
  constructor(public id?: number, public title?: string) {}

  static fromJSON(json: OpportunityStatusJSON): OpportunityStatus {
    return new OpportunityStatus(json.id, json.title);
  }

  static fromTitle(
    oppStatuses: OpportunityStatus[],
    title: string
  ): OpportunityStatus | undefined {
    return oppStatuses.find(
      (oppStatus: OpportunityStatus) => oppStatus.title === title
    );
  }

  static fromId(
    oppStatuses: OpportunityStatus[],
    id: number
  ): OpportunityStatus | undefined {
    return oppStatuses.find(
      (oppStatus: OpportunityStatus) => oppStatus.id === id
    );
  }
}
