import { Box, Typography } from "@mui/material";
import { EditButtons } from "./EditButtons";
import { EditableSelect } from "./inputs/EditableSelect";
import React, { useContext } from "react";
import { EditableText } from "./inputs/EditableText";
import { AssetFormContext } from "./AssetFormContext";
import { InputType } from "./inputs/InputTypes";
import { AssetAttribute } from "../../../../../models/Asset";
import { SelectOption } from "../../../../../models/SelectOption";
import { ViewAssetAttribute } from "./ViewAssetAttribute";
import { StateSelect } from "./inputs/StateSelect";

interface Props {
  sectionId: string;
  sectionTitle?: string;
  onSave?: () => void;
  onEdit?: () => void;
  onCancel?: () => void;
  assetAttributes?: AssetAttribute[];
  hasWriteAccess?: boolean;
}

export const RenderContentSection = ({
  sectionId,
  sectionTitle,
  onSave,
  onEdit,
  onCancel,
  assetAttributes,
  hasWriteAccess,
}: Props): JSX.Element => {
  const {
    asset,
    activeSection,
    errors,
    touched,
    handleChange,
    isSubmitting,
    values,
    setFieldTouched,
  } = useContext(AssetFormContext) || {};
  const isNew = !asset?.id;
  const isEditMode = activeSection === sectionId || activeSection === "all";

  const renderAttribute = (attr: AssetAttribute) => {
    const attr_key = attr?.key;
    const value = attr_key && values?.[attr_key];
    const error = attr_key && errors?.[attr_key];
    const inputTouched = attr_key && touched?.[attr_key];

    // temporary work-around for state select
    if (attr_key === "states_id") {
      return (
        <StateSelect
          name={attr?.key}
          isEditMode={isEditMode}
          value={value}
          key={`AssetAttribute_${sectionId}_${attr_key}`}
          onChange={(event: any) => {
            setFieldTouched(attr?.key);
            handleChange(event);
          }}
          error={error}
          sx={{ width: "60%", mb: 3 }}
        />
      );
    }

    const type = attr?.type;
    switch (type) {
      case InputType.select: {
        return (
          <Box
            className="EditableSelect-wrapper"
            sx={{ width: sectionId === "overview" ? "45%" : "auto" }}
            key={`AssetAttribute_${sectionId}_${attr_key}`}
          >
            <EditableSelect
              label={attr?.label}
              caption={attr?.caption}
              name={attr?.key}
              isEditMode={isEditMode}
              value={value}
              options={attr?.options?.map(({ label, value }: SelectOption) => ({
                label,
                value,
              }))}
              onChange={(event: any) => {
                setFieldTouched(attr?.key);
                handleChange(event);
              }}
              error={error}
              sx={{ width: "60%", mb: 3 }}
              helpContent={attr?.helpContent}
            />
          </Box>
        );
      }
      case InputType.text:
      case InputType.usCurrency:
      case InputType.number: {
        return (
          <EditableText
            isEditMode={isEditMode}
            type={type}
            label={attr?.label}
            name={attr?.key}
            caption={attr?.caption}
            key={`AssetAttribute_${sectionId}_${attr_key}`}
            value={value}
            onChange={handleChange}
            error={error}
            touched={inputTouched}
            charLimit={attr?.maxLength}
            prefix={type === InputType.usCurrency ? "$" : undefined}
            helpContent={attr?.helpContent}
            rows={attr?.rows}
            noCommas={attr?.noCommas}
          />
        );
      }
      case InputType.address: {
        return (
          <EditableText
            label={attr?.label}
            name={attr?.key}
            caption={attr?.caption}
            key={`AssetAttribute_${sectionId}_${attr_key}`}
            isEditMode={isEditMode}
            value={value}
            onChange={handleChange}
            error={error}
            touched={inputTouched}
            helpContent={attr?.helpContent}
          />
        );
      }
      case InputType.static: {
        return (
          <ViewAssetAttribute
            type={InputType.static}
            label={attr?.label}
            key={`AssetAttribute_${sectionId}_${attr_key}`}
            caption={attr?.caption}
            helpContent={attr?.helpContent}
          />
        );
      }
      default:
        return <></>;
    }
  };

  const getContent = (): JSX.Element => {
    switch (sectionId) {
      case "overview": {
        return (
          <Box
            sx={{ display: "flex", flexWrap: "wrap" }}
            className="overviewSectionWrapper"
          >
            {assetAttributes?.map((attr: AssetAttribute) =>
              renderAttribute(attr)
            )}
          </Box>
        );
      }
      case "basic":
      case "value": {
        return (
          <Box className="sectionWrapper">
            {assetAttributes?.map((attr: AssetAttribute) =>
              renderAttribute(attr)
            )}
          </Box>
        );
      }
      case "other": {
        return (
          <Box className="sectionWrapper" sx={{ mb: 30 }}>
            {assetAttributes?.map((attr: AssetAttribute) =>
              renderAttribute(attr)
            )}
          </Box>
        );
      }
      default:
        return <></>;
    }
  };

  return (
    <>
      <a
        id={sectionId}
        className="section"
        style={{
          position: "relative",
          top: -15, // offset to deal with sticky subnav overlapping content
        }}
      />
      <Box
        sx={{
          position: "relative",
          mb: 4,
          h4: {
            color: "grey.900",
            fontWeight: 800,
            mb: 3,
          },
          hr: {
            backgroundColor: "grey.300",
            border: "none",
            height: 2,
            mb: 4,
          },
        }}
      >
        {sectionId !== "overview" && (
          <>
            <hr />
            <Typography variant="h4">{sectionTitle}</Typography>
          </>
        )}
        {hasWriteAccess && !isNew ? (
          <EditButtons
            isEditMode={activeSection === sectionId}
            onEdit={onEdit}
            onCancel={onCancel}
            onSave={onSave}
            isSubmitting={isSubmitting}
            top={sectionId === "overview" ? 0 : undefined}
          />
        ) : (
          <></>
        )}
      </Box>
      {getContent()}
    </>
  );
};
