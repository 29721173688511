import React from "react";
import { Box, CircularProgress, Typography } from "@mui/material";
import { InCard } from "./InCard";

interface Props {
  inCard?: boolean;
  margin?: string;
  message?: string;
  hideMessage?: boolean;
}

export const LoadingSpinner = ({
  margin = "16px",
  message = "Loading...",
  inCard = true,
  hideMessage = false,
}: Props): JSX.Element => {
  return (
    <InCard card={inCard}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          m: margin,
        }}
      >
        <CircularProgress />
        {!hideMessage && (
          <Typography variant="h4" style={{ marginTop: 16 }}>
            {message}
          </Typography>
        )}
      </Box>
    </InCard>
  );
};
