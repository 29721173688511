import { State, Country } from "./Geography";

export class AssetDetail {
  toJson(): any {
    return {};
  }
}

export class AssetHousehold extends AssetDetail {
  constructor(public quantity?: number | null) {
    super();
  }

  toJson(): any {
    const json: any = {};
    this.quantity ? (json.quantity = this.quantity) : null;
    return json;
  }
}

export class AssetFinancial extends AssetDetail {
  // db has user_name and password, but won't display in advisor app
  constructor(public bank_institution?: string) {
    super();
  }

  toJson(): any {
    const json: any = {};
    this.bank_institution
      ? (json.bank_institution = this.bank_institution)
      : null;
    return json;
  }
}

export class AssetRealEstate extends AssetDetail {
  constructor(
    public address1?: string,
    public address2?: string,
    public assessor_parcel_number?: string,
    public city?: string,
    public states_id?: number | null,
    public zip_code?: string,
    public counties_id?: number | null,
    public google_places_id?: string | null,
    public formatted_address?: string,
    public countries_id?: number | null,
    public ownership?: string,
    public has_mortgage?: string,
    public remaining_mortgage?: number | null,
    public title_type?: string,
    public title?: string,
    public future_plan?: string
  ) {
    super();
  }

  getFullAddress(states?: State[], countries?: Country[]): string | undefined {
    const state = states?.find((state: State) => state.id === this.states_id);
    const country = countries?.find(
      (country: Country) => country.id === this.countries_id
    );

    if (this?.formatted_address) {
      return this.formatted_address;
    } else {
      const fullAddress = `${this?.address1 ? this?.address1 : ""}${
        this?.address2 ? " " + this?.address2 : ""
      }${this.city ? ", " + this.city : ""}${
        state?.name ? ", " + state?.name : ""
      }${this.zip_code ? " " + this.zip_code : ""}${
        country?.name ? ", " + country?.name : ""
      }`;
      return fullAddress !== "" ? fullAddress : undefined;
    }
  }

  toJson(): any {
    const json: any = {};
    this.address1 ? (json.address1 = this.address1) : null;
    this.address2 ? (json.address2 = this.address2) : null,
      this.assessor_parcel_number
        ? (json.assessor_parcel_number = this.assessor_parcel_number)
        : null,
      this.city ? (json.city = this.city) : null,
      this.states_id ? (json.states_id = this.states_id) : null,
      this.zip_code ? (json.zip_code = this.zip_code) : null,
      this.counties_id ? (json.counties_id = this.counties_id) : null,
      this.google_places_id
        ? (json.google_places_id = this.google_places_id)
        : null,
      this.formatted_address
        ? (json.formatted_address = this.formatted_address)
        : null,
      this.countries_id ? (json.countries_id = this.countries_id) : null,
      this.ownership ? (json.ownership = this.ownership) : null,
      this.has_mortgage ? (json.has_mortgage = this.has_mortgage) : null,
      (json.remaining_mortgage = this.remaining_mortgage),
      this.title_type ? (json.title_type = this.title_type) : null,
      this.title ? (json.title = this.title) : null,
      this.future_plan ? (json.future_plan = this.future_plan) : null;
    return json;
  }
}

export class AssetVehicle extends AssetDetail {
  constructor(
    public make?: string,
    public model?: string,
    public year?: number | null,
    public vin?: string
  ) {
    super();
  }

  toJson(): any {
    const json: any = {};
    this.make ? (json.make = this.make) : null;
    this.model ? (json.model = this.model) : null;
    this.year ? (json.year = this.year) : null;
    this.vin ? (json.vin = this.vin) : null;
    return json;
  }
}

export class AssetDigital extends AssetDetail {
  // db has user_name and password, but won't display in advisor app
  constructor(public web_address?: string) {
    super();
  }

  toJson(): any {
    const json: any = {};
    this.web_address ? (json.web_address = this.web_address) : null;
    return json;
  }
}
