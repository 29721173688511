import { callGet, logger } from "./index";
import { Language } from "../models/Language";
const path = "/language";

export class Languages {
  async fetchLanguages(): Promise<Language[] | null> {
    try {
      const fullPath = `${path}`;
      const response = await callGet(fullPath);

      const languages: Language[] = [];
      response?.data?.forEach((lang: any) => {
        languages.push(Language.fromJSON(lang));
      });
      return languages;
    } catch (error) {
      this.handleError(error);
      return null;
    }
  }

  async fetchLanguage(languageId: number): Promise<Language | null> {
    try {
      const fullPath = `${path}/${languageId}`;
      const response = await callGet(fullPath);

      const language: Language = Language.fromJSON(response?.data);
      return language;
    } catch (error) {
      this.handleError(error);
      return null;
    }
  }

  handleError(error: any): void {
    if ((error as Error)?.message === "no response.data") {
      logger.error(error, false);
    } else {
      logger.error(error as Error);
    }
  }
}
