import React, { useEffect, useState } from "react";
import { Card, CardContent, CardHeader, Typography } from "@mui/material";
import { StoreState } from "../../../reducers";
import {
  AdvisorGroup,
  AdvisorGroupAccess,
  AdvisorGroupAccessJSON,
} from "../../../models/AdvisorGroup";
import { connect } from "react-redux";
import { fetchAdvisorGroups } from "../../../actions/advisorGroupActions";
import { AllGroupsTable } from "./AllGroupsTable";
import { callGet } from "../../../apis";

interface Props {
  advisorGroups: AdvisorGroup[];
  fetchAdvisorGroups: () => void;
}

export const _AllGroupsView = ({
  advisorGroups,
  fetchAdvisorGroups,
}: Props): JSX.Element => {
  const [accessLevels, setAccessLevels] = useState<AdvisorGroupAccess[]>();
  useEffect(() => {
    (async () => {
      setAccessLevels(await fetchAdvisorGroupAccess());
    })();
    if (!advisorGroups) {
      fetchAdvisorGroups();
    }
  }, []);

  const fetchAdvisorGroupAccess = async (): Promise<AdvisorGroupAccess[]> => {
    const access = await callGet(AdvisorGroupAccess.path);
    return access?.data?.map((access: AdvisorGroupAccessJSON) =>
      AdvisorGroupAccess.fromJSON(access)
    );
  };

  return (
    <div data-automation-id="page-content-groups">
      <Card>
        <CardHeader title="All Advisor Groups" />
        <AllGroupsTable
          advisorGroups={advisorGroups}
          accessLevels={accessLevels}
        />
      </Card>
      <Card style={{ marginTop: 16 }}>
        <CardHeader title="Access Levels" />
        <CardContent>
          {accessLevels?.map((level: AdvisorGroupAccess) => (
            <Typography key={level.access}>
              <strong>{level.access} - </strong>
              {level.description}
            </Typography>
          ))}
        </CardContent>
      </Card>
    </div>
  );
};

const mapStateToProps = ({
  advisorGroups,
}: StoreState): { advisorGroups: AdvisorGroup[] } => {
  return { advisorGroups };
};

export const AllGroupsView = connect(mapStateToProps, {
  fetchAdvisorGroups,
})(_AllGroupsView);
