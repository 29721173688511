import { SxProps } from "@mui/system/styleFunctionSx";
import { Box, Button } from "@mui/material";

interface Props {
  isEditMode?: boolean;
  onEdit?: () => void;
  onCancel?: () => void;
  onSave?: () => void;
  isSubmitting?: boolean;
  sx?: SxProps;
  top?: number;
}

export const EditButtons = ({
  isEditMode = false,
  onEdit,
  onCancel,
  onSave,
  isSubmitting,
  sx,
  top = 32,
}: Props): JSX.Element => {
  return (
    <Box
      sx={{
        ...sx,
        button: {
          minWidth: 92,
        },
        position: "absolute",
        top,
        right: 0,
      }}
    >
      {isEditMode ? (
        <>
          <Button variant="outlined" onClick={onCancel} size="small">
            Cancel
          </Button>
          <Button
            variant="contained"
            onClick={onSave}
            size="small"
            sx={{ ml: 1 }}
            disabled={isSubmitting}
          >
            Save
          </Button>
        </>
      ) : (
        <Button variant="outlined" onClick={onEdit} size="small">
          Edit
        </Button>
      )}
    </Box>
  );
};
