import { EstateLaravelized, EstateLaravelizedJSON } from "./EstateLaravelized";
import { User } from "./User";
import { AtticusForm, FormJson } from "./AtticusForm";
import { ProbateType, ProbateTypeJSON } from "./ProbateType";
import { UserJSON } from "./User";

// database field names
export type EstateFormMapJSON = {
  id: number;
  estates_id: number;
  estate?: EstateLaravelizedJSON;
  forms_id: number;
  form?: FormJson;
  source_type?: string;
  source_id?: number;
  source: UserJSON | ProbateTypeJSON | undefined;
  created_at?: string;
  updated_at?: string;
  deleted_at?: string;
};

export class EstateFormMap {
  constructor(
    public id: number,
    public estateId: number,
    public formId: number,
    public estate?: EstateLaravelized,
    public form?: AtticusForm,
    public sourceType?: string,
    public sourceId?: number,
    public source?: User | ProbateType,
    public createdAt?: Date,
    public updatedAt?: Date,
    public deletedAt?: Date
  ) {}

  static fromJSON(json: EstateFormMapJSON): EstateFormMap {
    let source: User | ProbateType | undefined = undefined;
    if (json.source) {
      if (json.source_type == ProbateType.className) {
        source = ProbateType.fromJSON(json.source);
      } else if (json.source_type == User.className) {
        source = User.fromJSON(json.source);
      }
    }

    return new EstateFormMap(
      json.id,
      json.estates_id,
      json.forms_id,
      json.estate ? EstateLaravelized.fromJSON(json.estate) : undefined,
      json.form ? AtticusForm.fromJSON(json.form) : undefined,
      json.source_type,
      json.source_id,
      source,
      json.created_at ? new Date(json.created_at) : undefined,
      json.updated_at ? new Date(json.updated_at) : undefined,
      json.deleted_at ? new Date(json.deleted_at) : undefined
    );
  }

  toJSON(): EstateFormMapJSON {
    return {
      id: this.id,
      estates_id: this.estateId,
      forms_id: this.formId,
      estate: this.estate ? this.estate.toJSON() : undefined,
      form: this.form ? this.form.toJSON() : undefined,
      source_type: this.sourceType,
      source_id: this.sourceId,
      source:
        this.source instanceof User
          ? this.source.toJson()
          : this.source instanceof ProbateType
          ? this.source.toJSON()
          : undefined,
      created_at: this.createdAt ? this.createdAt.toISOString() : undefined,
      updated_at: this.updatedAt ? this.updatedAt.toISOString() : undefined,
      deleted_at: this.deletedAt ? this.deletedAt.toISOString() : undefined,
    };
  }
}
