import React, { ReactElement } from "react";
import {
  PlusCircle as PlusCircleIcon,
  Users as AdvisorIcon,
} from "react-feather";
import {
  Dollar,
  Phone,
  Headphones,
  FolderWithStar,
  QuestionnaireBox,
  Home,
} from "../../../views/shared/AtticusIcons";
import GroupWorkIcon from "@mui/icons-material/GroupWork";
import SettingsIcon from "@mui/icons-material/Settings";

export type AppScreen = {
  path: string;
  icon: ReactElement;
  title: string;
};

const iconStyles = {
  maxHeight: 24,
  maxWidth: 24,
  contentFit: "fill",
} as const;

export const estateAccessNavItems: AppScreen[] = [
  {
    title: "Dashboard",
    path: "/app/dashboard",
    icon: <Home style={iconStyles} inheritViewBox />,
  },
  {
    title: "Estates",
    path: "/app/estates",
    icon: <Dollar style={iconStyles} inheritViewBox />,
  },
  {
    title: "Opportunities",
    path: "/app/opportunities",
    icon: <Phone style={iconStyles} inheritViewBox />,
  },
];
export const formNavItems: AppScreen[] = [
  {
    title: "Forms Library",
    path: "/app/forms-library",
    icon: <FolderWithStar style={iconStyles} inheritViewBox />,
  },
];
export const communicationNavItems: AppScreen[] = [
  {
    title: "Support",
    path: "/app/support",
    icon: <Headphones style={iconStyles} inheritViewBox />,
  },
];

export const inviteClientNavItems: AppScreen[] = [
  {
    title: "Invite a Client",
    path: "/app/invite-client",
    icon: <PlusCircleIcon style={iconStyles} />,
  },
];

export const inviteAdvisorNavItems: AppScreen[] = [
  {
    title: "Invite an Advisor",
    path: "/app/invite-advisor",
    icon: <PlusCircleIcon style={iconStyles} />,
  },
];

export const metricNavItems: AppScreen[] = [
  {
    title: "Metrics",
    path: "/app/metrics",
    icon: <QuestionnaireBox style={iconStyles} inheritViewBox />,
  },
];

export const advisorNavItems: AppScreen[] = [
  {
    title: "Advisors",
    path: "/app/advisors",
    icon: <AdvisorIcon style={iconStyles} />,
  },
];

export const uberNavItems: AppScreen[] = [
  {
    title: "Assignments",
    path: "/app/assignments/advisors",
    icon: <GroupWorkIcon style={iconStyles} />,
  },
  // {
  //   title: "Opp Admin",
  //   path: "/app/opp-admin",
  //   icon: (
  //     <PhoneIncoming style={{ ...iconStyles, transform: "rotate(45deg)" }} />
  //   ),
  // },
];

export const adminNavItems: AppScreen[] = [
  {
    title: "Admin",
    path: "/app/admin/forms",
    icon: <SettingsIcon style={iconStyles} />,
  },
];
