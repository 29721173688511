import React from "react";
import { SvgIcon, SvgIconProps, Theme, useTheme } from "@mui/material";

const getColor = (
  color: string,
  monoChrome: boolean,
  theme: Theme
): string | undefined => {
  const colorMap = {
    "#6F79D4": theme.palette.grey["400"],
    "#4E57AA": theme.palette.grey["600"],
    "#C3C3DD": theme.palette.grey["200"],
    white: theme.palette.common.white,
    "#9292FF": theme.palette.grey["300"],
    "#DDEBF1": theme.palette.grey["100"],
    "#B8C5CB": theme.palette.grey["200"],
    "#00D9C6": theme.palette.grey["400"],
    "#2D2A5C": theme.palette.grey["700"],
    "#E2E2EE": theme.palette.grey["100"],
    "#27B8AB": theme.palette.grey["400"],
    "#CDCDDB": theme.palette.grey["100"],
    "#737199": theme.palette.grey["400"],
    "#B5B5FF": theme.palette.grey["300"],
    "#D8D8FF": theme.palette.grey["200"],
    "#EDEDF4": theme.palette.grey["100"],
  };
  if (monoChrome && colorMap.hasOwnProperty(color)) {
    return colorMap[color];
  } else {
    monoChrome && console.log("no color: ", color);
    return color;
  }
};

export const FirstThingsFirstIcon = (props: SvgIconProps): JSX.Element => {
  const theme = useTheme();
  const monoChrome = props?.color === "disabled";
  return (
    <SvgIcon {...props}>
      <path
        d="M68.0138 2.47566C70.4639 0.423322 77.2238 1.01474 80.4069 3.55991C83.5901 6.10509 92.5491 20.489 91.5651 31.7364C90.581 42.9838 82.2467 60.3881 82.2467 60.3881C82.2467 60.3881 70.1233 58.4331 63.2905 56.463C63.2888 44.8835 56.2453 33.1797 56.9342 25.3066C57.623 17.4334 65.5637 4.52799 68.0138 2.47566Z"
        fill={getColor("#6F79D4", monoChrome, theme)}
      />
      <path
        d="M81.0439 66.1783C81.0439 66.1783 80.5519 71.802 80.1583 76.3009C79.7647 80.7999 74.7661 86.0293 68.0062 85.4378C61.2463 84.8464 58.3584 78.927 58.752 74.4281C59.1456 69.9292 62.0877 62.2531 62.0877 62.2531L81.0439 66.1783Z"
        fill={getColor("#6F79D4", monoChrome, theme)}
      />
      <path
        d="M26.0304 53.31C23.5102 51.3444 16.775 52.1714 13.6827 54.8261C10.5903 57.4808 2.13872 72.1687 3.51466 83.3749C4.89061 94.581 13.8273 111.684 13.8273 111.684L32.6349 107.1C31.3966 97.0141 38.8632 83.5846 37.9001 75.7403C36.9369 67.896 28.5506 55.2756 26.0304 53.31Z"
        fill={getColor("#6F79D4", monoChrome, theme)}
      />
      <path
        d="M16.1108 116.356C16.1108 116.356 16.7988 121.959 17.3492 126.441C17.8996 130.924 23.0776 135.975 29.8128 135.148C36.5479 134.321 39.2275 128.305 38.6771 123.822C38.1267 119.34 34.9185 111.771 34.9185 111.771L16.1108 116.356Z"
        fill={getColor("#6F79D4", monoChrome, theme)}
      />
    </SvgIcon>
  );
};

export const FuneralArrangmentsIcon = (props: SvgIconProps): JSX.Element => {
  const theme = useTheme();
  const monoChrome = props?.color === "disabled";
  return (
    <SvgIcon {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.4253 107.023C17.4161 105.959 17.9295 101.555 21.1028 101.212L99.854 92.6935C100.45 92.6291 101.051 92.7445 101.581 93.0248L136.084 111.295C138.451 112.548 138.114 116.039 135.551 116.817L89.4426 130.809C88.8303 130.994 88.1746 130.98 87.5714 130.766L20.4253 107.023Z"
        fill={getColor("#E2E2EE", monoChrome, theme)}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M101.84 47.1699L113.632 114.763L106.208 119.051L63.3343 109.939L101.84 47.1699Z"
        fill={getColor("#737199", monoChrome, theme)}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M34.304 11.8506L107.736 9.17059L85.76 123.339L11.792 102.971L34.304 11.8506Z"
        fill={getColor("#EDEDF4", monoChrome, theme)}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M72.36 39.1866C57.1859 32.1053 51.6817 39.7226 47.782 47.7626C43.8822 55.8026 43.416 63.3066 45.024 65.9866C46.632 68.6666 51.992 70.9892 51.992 70.9892L47.782 76.1706C47.782 76.1706 47.9156 83.9955 53.064 85.2826C59.8451 86.9779 67 77.7786 67 77.7786C67 77.7786 66.464 73.4906 67 70.9892C67.7618 67.4341 69.2881 65.3785 70.216 64.9146C72.36 63.8426 76.497 63.2462 77.72 57.9466C79.328 50.9786 80.4 42.9386 72.36 39.1866Z"
        fill={getColor("#E2E2EE", monoChrome, theme)}
      />
      <ellipse
        cx="62.6209"
        cy="52.3118"
        rx="2.854"
        ry="2.10031"
        fill={getColor("#737199", monoChrome, theme)}
      />
      <ellipse
        cx="52.9827"
        cy="48.8233"
        rx="2.59871"
        ry="2.1327"
        fill={getColor("#737199", monoChrome, theme)}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M70.216 57.4106C70.216 58.4826 72.36 55.2666 75.04 56.8746C77.72 58.4826 74.3748 61.8832 74.3052 61.9699C73.1847 63.3657 71.2253 63.8112 70.216 63.3066C69.144 62.7706 69.144 62.7706 69.144 62.7706L68.3983 67.5946C68.3983 67.5946 73.968 67.0652 77.184 61.9699C80.4 56.8746 87.904 42.1534 77.184 36.382C66.464 30.6106 57.9055 33.2906 56.0208 35.9706C54.136 38.6506 58.6891 40.7946 60.032 41.3306C61.3749 41.8666 71.288 40.7946 73.432 46.1546C75.2277 50.6439 70.216 56.3386 70.216 57.4106Z"
        fill={getColor("#737199", monoChrome, theme)}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M53.064 53.6586C53.6 53.6586 51.0808 55.2375 50.384 57.1321C49.6872 59.0267 53.064 59.974 52.6091 60.6056C52.1541 61.2371 48.24 59.6583 48.776 57.1321C49.312 54.6059 52.528 53.6586 53.064 53.6586Z"
        fill={getColor("#737199", monoChrome, theme)}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M57.352 68.6666C57.352 68.6666 53.868 69.7386 51.188 69.2026C48.508 68.6666 45.56 66.5226 45.024 65.9866C44.488 65.4506 47.168 69.2026 49.848 70.2746C52.528 71.3466 46.5817 76.7066 48.7257 76.1706C50.8697 75.6346 57.352 68.6666 57.352 68.6666Z"
        fill={getColor("#737199", monoChrome, theme)}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M45.56 80.4586L50.5679 84.2106L59.7669 85.951L66.464 86.8906L52.609 109.939L36.448 105.651L40.2 86.8906L45.56 80.4586Z"
        fill={getColor("white", monoChrome, theme)}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M42.88 90.1066L49.2462 87.4266L49.848 90.1066L43.416 107.259L48.24 108.331C48.24 108.331 52.609 98.6826 52.609 96.5386C52.609 94.3946 53.064 91.1786 53.064 91.1786L55.0241 90.1066L56.816 95.4666L66.464 87.4266L42.344 81.5306L42.88 90.1066Z"
        fill={getColor("#C3C3DD", monoChrome, theme)}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M67.536 76.1706L70.216 83.1386C70.216 83.1386 64.32 87.9626 61.104 90.6426C57.888 93.3226 57.352 92.7866 57.352 92.7866L55.024 84.2106C55.024 84.2106 60.032 83.8086 62.712 81.8656C65.392 79.9226 67.536 76.1706 67.536 76.1706Z"
        fill={getColor("white", monoChrome, theme)}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M50.5679 82.6026L43.416 87.9626C43.416 87.9626 43.416 84.8806 43.416 81.8656C43.416 78.8506 43.8774 77.7786 43.8774 77.7786L48.776 73.4906C48.776 73.4906 48.0561 77.7786 48.776 79.3866C49.4959 80.9946 50.5679 82.6026 50.5679 82.6026Z"
        fill={getColor("white", monoChrome, theme)}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M52.6091 83.1386C51.0011 82.6026 49.312 82.0666 48.24 83.6746C47.168 85.2826 48.1743 90.1066 49.2463 90.6426C50.3183 91.1786 55.208 87.1832 55.744 85.5752C56.28 83.9672 54.2171 83.6746 52.6091 83.1386Z"
        fill={getColor("#00D9C6", monoChrome, theme)}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M70.216 82.6026C70.216 82.6026 62.8514 89.0346 56.816 95.4666C50.7805 101.899 47.168 107.795 47.168 107.795L79.864 116.371L82.008 105.058C82.008 105.058 82.544 93.8586 81.472 91.7146C80.4 89.5706 70.216 82.6026 70.216 82.6026Z"
        fill={getColor("#2D2A5C", monoChrome, theme)}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M43.8774 77.7786C43.8774 77.7786 43.952 86.8906 42.88 90.6426C41.808 94.3946 39.128 105.651 39.128 105.651L18.76 100.291C18.76 100.291 29.9462 83.3396 31.3211 82.6026C32.696 81.8656 43.8774 77.7786 43.8774 77.7786Z"
        fill={getColor("#2D2A5C", monoChrome, theme)}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M39.664 105.651C39.664 105.651 42.7404 96.9853 43.8822 94.9306C45.024 92.876 48.776 89.5706 48.776 89.5706L50.92 90.1066C50.92 90.1066 51.456 93.8586 50.92 97.0746C50.384 100.291 46.096 107.795 46.096 107.795L39.664 105.651Z"
        fill={getColor("#00D9C6", monoChrome, theme)}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M33.7566 5.1825C31.198 5.32377 29.0116 7.0743 28.314 9.53996L1.76559 103.377C0.860383 106.576 2.72977 109.902 5.93365 110.791L85.361 132.847C88.7476 133.787 92.2137 131.628 92.8631 128.174L115.434 8.11601C116.153 4.28949 113.094 0.801904 109.206 1.01656L33.7566 5.1825ZM100.768 15.0666L38.056 17.2106L15.3818 99.0066L79.8026 115.959L100.768 15.0666Z"
        fill={getColor("#9292FF", monoChrome, theme)}
      />
    </SvgIcon>
  );
};

export const GettingStartedIcon = (props: SvgIconProps): JSX.Element => {
  const theme = useTheme();
  const monoChrome = props?.color === "disabled";
  return (
    <SvgIcon {...props}>
      <g clipPath="url(#clip0_2626_4968)">
        <path
          d="M78.8014 134V1.30432H2.36926L2.55259 134H78.8014Z"
          // fill="#9292FF"
          fill={getColor("#9292FF", monoChrome, theme)}
        />
        <path
          d="M78.8014 134V31.1368L2.36926 59.1139L2.55259 134H78.8014Z"
          // fill="#9292FF"
          fill={getColor("#9292FF", monoChrome, theme)}
        />
        <rect
          x="19"
          y="89"
          width="16"
          height="30"
          fill={getColor("#DDEBF1", monoChrome, theme)}
        />
        <rect
          x="47"
          y="89"
          width="16"
          height="30"
          fill={getColor("#DDEBF1", monoChrome, theme)}
        />
        <rect
          x="19"
          y="48"
          width="16"
          height="30"
          fill={getColor("#DDEBF1", monoChrome, theme)}
        />
        <rect
          x="47"
          y="48"
          width="16"
          height="30"
          fill={getColor("#DDEBF1", monoChrome, theme)}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M19 38H63C63 38 63 13 41 13C19 13 19 38 19 38Z"
          fill={getColor("#DDEBF1", monoChrome, theme)}
        />
        <path
          d="M76.668 0H4.90086H0L0.245218 134H5.26752L5.56762 3.8943H76.0013L76.3014 134H81.3237L81.5689 0H76.668Z"
          fill={getColor("#6F79D4", monoChrome, theme)}
        />
        <path
          d="M76.668 0H4.90086H0L0.245218 60.4568L5.26752 58.7052L5.56762 3.8943H76.1507V32.3373L81.5689 30.2973V0H76.668Z"
          fill={getColor("#6F79D4", monoChrome, theme)}
        />
        <path
          d="M4.81445 3.00916V3.89428H76.0012L76.3013 134H77.2764V3.18548L4.81445 3.00916Z"
          fill={getColor("#4E57AA", monoChrome, theme)}
        />
        <path
          d="M77.2764 3.00916V3.89428H6.08959L5.78949 134H4.81445V3.18548L77.2764 3.00916Z"
          fill={getColor("#4E57AA", monoChrome, theme)}
        />
        <path
          d="M16.0243 69.4737C16.0326 70.269 15.8043 71.0488 15.3685 71.7142C14.9327 72.3795 14.309 72.9003 13.5765 73.2104C12.8441 73.5205 12.036 73.6059 11.2549 73.4558C10.4738 73.3057 9.75502 72.9268 9.18973 72.3672C8.62444 71.8077 8.2382 71.0928 8.08008 70.3133C7.92196 69.5338 7.9991 68.7249 8.30168 67.9893C8.60426 67.2537 9.11864 66.6247 9.77948 66.1821C10.4403 65.7395 11.2178 65.5033 12.0132 65.5035C13.0714 65.4985 14.0884 65.9139 14.8405 66.6583C15.5926 67.4028 16.0184 68.4154 16.0243 69.4737Z"
          fill={getColor("#DDEBF1", monoChrome, theme)}
        />
        <path
          d="M12.9271 72.2474C12.4706 72.2495 12.0182 72.1617 11.5957 71.989C11.1732 71.8163 10.7889 71.562 10.4646 71.2407C10.1404 70.9194 9.88264 70.5373 9.70609 70.1164C9.52954 69.6955 9.43765 69.2439 9.43566 68.7874C9.43442 68.0262 9.6876 67.2864 10.155 66.6855C9.54471 67.1044 9.08525 67.7083 8.84438 68.4082C8.60351 69.1081 8.59399 69.8668 8.81723 70.5725C9.04047 71.2782 9.48464 71.8935 10.0842 72.3275C10.6838 72.7615 11.4069 72.9913 12.1471 72.983C12.6827 72.9832 13.2111 72.8605 13.6919 72.6245C14.1727 72.3884 14.5929 72.0453 14.9204 71.6214C14.3351 72.028 13.6397 72.2463 12.9271 72.2474Z"
          fill={getColor("#B8C5CB", monoChrome, theme)}
        />
      </g>
      <defs>
        <clipPath id="clip0_2626_4968">
          <rect
            width="81.5689"
            height="134"
            fill={getColor("white", monoChrome, theme)}
          />
        </clipPath>
      </defs>
    </SvgIcon>
  );
};

export const CourtStuffIcon = (props: SvgIconProps): JSX.Element => {
  const theme = useTheme();
  const monoChrome = props?.color === "disabled";
  return (
    <SvgIcon {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M30.7537 106.646C37.2537 105.163 53.5037 102.692 53.5037 102.692L60.0037 12.2281H72.4621L78.9621 102.692C78.9621 102.692 95.2121 105.163 101.17 106.646C107.129 108.129 107.67 117.522 107.67 117.522H24.2537C24.2537 117.522 24.2537 108.129 30.7537 106.646Z"
        fill={getColor("#C3C3DD", monoChrome, theme)}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M30.7537 105.614C37.2537 103.99 53.5037 101.283 53.5037 101.283H78.9621C78.9621 101.283 95.2121 103.99 101.17 105.614C107.129 107.237 107.67 117.522 107.67 117.522H24.2537C24.2537 117.522 24.2537 107.237 30.7537 105.614Z"
        fill={getColor("#4E57AA", monoChrome, theme)}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.9453 14.668C20.7369 14.668 32.6536 14.668 38.0703 14.668C43.4869 14.668 52.1536 0.594604 66.2369 0.594604C80.3203 0.594604 88.9869 14.668 94.9453 14.668C100.904 14.668 110.112 14.668 114.445 14.668C118.779 14.668 118.779 23.3286 118.779 23.3286H12.6119C12.6119 23.3286 13.1536 14.668 16.9453 14.668Z"
        fill={getColor("#4E57AA", monoChrome, theme)}
      />
      <ellipse
        cx="65.9701"
        cy="11.2587"
        rx="3.8806"
        ry="3.87786"
        fill={getColor("white", monoChrome, theme)}
      />
      <ellipse
        cx="17.875"
        cy="72.5855"
        rx="17.875"
        ry="8.11928"
        fill={getColor("#4E57AA", monoChrome, theme)}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.0113525 64.5793C0.0113525 64.5793 14.819 31.5883 15.5224 30.1633C16.2257 28.7382 16.7171 27.2549 18.4328 27.2549C20.1486 27.2549 20.7213 28.8249 21.3433 30.1633C21.9652 31.5017 35.7768 64.5793 35.7768 64.5793H31.4112L18.4328 32.5797L3.89195 64.5793H0.0113525Z"
        fill={getColor("#6F79D4", monoChrome, theme)}
      />
      <ellipse
        cx="111.979"
        cy="72.6986"
        rx="17.875"
        ry="8.11928"
        fill={getColor("#4E57AA", monoChrome, theme)}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M94.1045 65.0641C94.1045 65.0641 108.912 32.0731 109.616 30.648C110.319 29.223 110.81 27.7396 112.526 27.7396C114.242 27.7396 114.814 29.3096 115.436 30.648C116.058 31.9864 129.87 65.0641 129.87 65.0641H125.504L112.526 33.0644L97.9851 65.0641H94.1045Z"
        fill={getColor("#6F79D4", monoChrome, theme)}
      />
    </SvgIcon>
  );
};

export const NotifyingOthersIcon = (props: SvgIconProps): JSX.Element => {
  const theme = useTheme();
  const monoChrome = props?.color === "disabled";
  return (
    <SvgIcon {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 0.594604H136V89.5946H109.991C109.997 89.4287 110 89.2621 110 89.0946C110 82.191 105.075 76.5946 99 76.5946C92.9249 76.5946 88 82.191 88 89.0946C88 89.2621 88.0029 89.4287 88.0086 89.5946H48C48 82.691 43.0751 77.0946 37 77.0946C30.9249 77.0946 26 82.691 26 89.5946H0V0.594604Z"
        fill={getColor("#E2E2EE", monoChrome, theme)}
      />
      <circle
        cx="37"
        cy="26.5947"
        r="11"
        fill={getColor("#27B8AB", monoChrome, theme)}
      />
      <path
        d="M48 26.5947C48 32.6699 43.0751 37.5947 37 37.5947C37 37.5947 37 32.6699 37 26.5947C37 20.5196 37 15.5947 37 15.5947C43.0751 15.5947 48 20.5196 48 26.5947Z"
        fill={getColor("#00D9C6", monoChrome, theme)}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.0062 64C20.0062 64 19.1669 41 37.0062 41C54.8454 41 53.812 64 53.812 64H20.0062Z"
        fill={getColor("#27B8AB", monoChrome, theme)}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M37.0061 64L37.0061 41C54.8454 41 53.8119 64 53.8119 64H37.0061Z"
        fill={getColor("#00D9C6", monoChrome, theme)}
      />
      <rect
        x="64"
        y="20.5946"
        width="59"
        height="4"
        fill={getColor("#4E57AA", monoChrome, theme)}
      />
      <rect
        x="64"
        y="36.5946"
        width="59"
        height="4"
        fill={getColor("#4E57AA", monoChrome, theme)}
      />
      <rect
        x="64"
        y="52.5946"
        width="59"
        height="4"
        fill={getColor("#4E57AA", monoChrome, theme)}
      />
    </SvgIcon>
  );
};

export const TakingActionIcon = (props: SvgIconProps): JSX.Element => {
  const theme = useTheme();
  const monoChrome = props?.color === "disabled";
  return (
    <SvgIcon {...props}>
      <rect
        y="18.1702"
        width="111.977"
        height="117.209"
        rx="10.89"
        fill={getColor("#6F79D4", monoChrome, theme)}
      />
      <rect
        x="12.5582"
        y="30.7283"
        width="86.8605"
        height="92.093"
        fill={getColor("white", monoChrome, theme)}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M68.6511 13.3092C68.6511 14.03 68.5914 14.7368 68.4766 15.4249C82.5802 20.8054 82.5802 34.9241 82.5802 34.9241H29.3965C29.3965 34.9241 29.3965 20.8054 43.5001 15.4249C43.3853 14.7368 43.3256 14.03 43.3256 13.3092C43.3256 6.28714 48.9949 0.594604 55.9883 0.594604C62.9818 0.594604 68.6511 6.28714 68.6511 13.3092ZM55.9883 17.1236C58.7857 17.1236 61.0535 14.8466 61.0535 12.0378C61.0535 9.22893 58.7857 6.95192 55.9883 6.95192C53.191 6.95192 50.9232 9.22893 50.9232 12.0378C50.9232 14.8466 53.191 17.1236 55.9883 17.1236Z"
        fill={getColor("#C3C3DD", monoChrome, theme)}
      />
      <path d="M50.4576 52.0888H88.9535" stroke="#4E57AA" strokeWidth="4" />
      <path
        d="M23.0233 50.4032L28.7215 58.6677L40.118 44.5481"
        stroke={getColor("#00D9C6", monoChrome, theme)}
        strokeWidth="4"
      />
      <path d="M50.2325 77.5132H88.7285" stroke="#4E57AA" strokeWidth="4" />
      <path
        d="M23.0233 75.5196L28.7215 83.7841L40.118 69.6644"
        stroke={getColor("#00D9C6", monoChrome, theme)}
        strokeWidth="4"
      />
      <path d="M50.2325 102.629H88.7285" stroke="#4E57AA" strokeWidth="4" />
      <path
        d="M23.0233 100.636L28.7215 108.9L40.118 94.7806"
        stroke={getColor("#00D9C6", monoChrome, theme)}
        strokeWidth="4"
      />
    </SvgIcon>
  );
};

export const InventoryIcon = (props: SvgIconProps): JSX.Element => {
  const theme = useTheme();
  const monoChrome = props?.color === "disabled";
  return (
    <SvgIcon {...props}>
      <ellipse cx="31.5" cy="122.97" rx="31.5" ry="12.375" fill="#EDEDF4" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16 32.9757C16 32.4005 16.1654 31.8373 16.4765 31.3534L35.6595 1.51309C36.0275 0.940644 36.6614 0.594604 37.3419 0.594604H111.658C112.339 0.594604 112.972 0.940644 113.34 1.51309L132.524 31.3534C132.835 31.8373 133 32.4005 133 32.9757V115.595C133 116.699 132.105 117.595 131 117.595H16V32.9757Z"
        fill={getColor("#E2E2EE", monoChrome, theme)}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16 33.2196H133V115.595C133 116.699 132.105 117.595 131 117.595H16L16 33.2196Z"
        fill={getColor("#CDCDDB", monoChrome, theme)}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M69.25 0.594604H84.25L88 33.2196V59.0946H65.5V33.2196L69.25 0.594604Z"
        fill={getColor("#C3C3DD", monoChrome, theme)}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M88 33.2196V59.0946H65.5V33.2196H88Z"
        fill={getColor("#737199", monoChrome, theme)}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M112.75 81.5947L101.5 95.0947H107.125V106.345H118.375V95.0947H124L112.75 81.5947Z"
        fill={getColor("#737199", monoChrome, theme)}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M23.2444 70.5947H32.2444C32.2444 70.5947 32.2444 78.0947 32.2444 81.1611C32.2444 84.2275 49.2444 92.1022 47.2444 101.595C45.2444 111.087 37.2444 126.389 37.2444 126.389C37.2444 126.389 33.6229 128.389 28.5 128.389C23.3771 128.389 18.2444 126.389 18.2444 126.389C18.2444 126.389 10.7444 111.095 8.24444 101.095C5.74444 91.0947 23.2444 84.2275 23.2444 81.1611C23.2444 78.0947 23.2444 70.5947 23.2444 70.5947Z"
        fill={getColor("#27B8AB", monoChrome, theme)}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M28.1929 70.5947H32.4373C32.4373 70.5947 32.4373 78.0947 32.4373 81.1611C32.4373 84.2275 49.4373 92.1022 47.4373 101.595C45.4373 111.087 37.4373 126.389 37.4373 126.389C37.4373 126.389 33.8158 128.389 28.6929 128.389C28.1929 128.389 28.1929 70.5947 28.1929 70.5947Z"
        fill={getColor("#00D9C6", monoChrome, theme)}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.50244 29.5946L27.3809 29.5946L45 29.5946L54 72.871C54 72.871 40.5 78.0575 27 78.0575C13.5 78.0575 0 72.871 0 72.871L9.50244 29.5946Z"
        fill={getColor("#9292FF", monoChrome, theme)}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.50244 29.5946L27.3809 29.5946C27.3809 29.5946 27.3809 78.0575 27 78.0575C13.5 78.0575 0 72.871 0 72.871L9.50244 29.5946Z"
        fill={getColor("#6F79D4", monoChrome, theme)}
      />
      <ellipse
        cx="27.25"
        cy="29.5946"
        rx="17.75"
        ry="4"
        fill={getColor("#4E57AA", monoChrome, theme)}
      />
    </SvgIcon>
  );
};

export const ReviewReportIcon = (props: SvgIconProps): JSX.Element => {
  const theme = useTheme();
  const monoChrome = props?.color === "disabled";
  return (
    <SvgIcon {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 21C0 17.134 3.13401 14 7 14H63.8088C66.3893 14 68.7606 12.5802 69.9792 10.3055L73.5208 3.69445C74.7394 1.41976 77.1107 0 79.6912 0H116C119.866 0 123 3.13401 123 7V87C123 90.866 119.866 94 116 94H7C3.13401 94 0 90.866 0 87V21Z"
        fill={getColor("#6F79D4", monoChrome, theme)}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.09861 8.78101L115.831 15.2111L109.901 86.267L0.169312 79.8369L6.09861 8.78101Z"
        fill={getColor("#B5B5FF", monoChrome, theme)}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.0986 4.26685L123.831 10.6969L117.901 81.7529L8.16931 75.3228L14.0986 4.26685Z"
        fill={getColor("#B5B5FF", monoChrome, theme)}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 21C0 17.134 3.13401 14 7 14H68L75.5 14H116C119.866 14 123 17.134 123 21V87C123 90.866 119.866 94 116 94H7C3.13401 94 0 90.866 0 87V21Z"
        fill={getColor("#D8D8FF", monoChrome, theme)}
      />
      <circle
        cx="83.6692"
        cy="57.1423"
        r="22"
        transform="rotate(-31 83.6692 57.1423)"
        stroke={getColor("#4E57AA", monoChrome, theme)}
        strokeWidth="4"
      />
      <rect
        x="93.2856"
        y="77.03"
        width="4"
        height="44"
        transform="rotate(-31 93.2856 77.03)"
        fill={getColor("#737199", monoChrome, theme)}
      />
      <rect
        x="94.8345"
        y="85.4326"
        width="10"
        height="36"
        transform="rotate(-31 94.8345 85.4326)"
        fill={getColor("#4E57AA", monoChrome, theme)}
      />
    </SvgIcon>
  );
};

export const FinalFinishesIcon = (props: SvgIconProps): JSX.Element => {
  const theme = useTheme();
  const monoChrome = props?.color === "disabled";
  return (
    <SvgIcon {...props}>
      <g clipPath="url(#clip0_2627_3403)">
        <path
          d="M51.59 154.1C72.9027 154.1 90.18 150.317 90.18 145.65C90.18 140.983 72.9027 137.2 51.59 137.2C30.2773 137.2 13 140.983 13 145.65C13 150.317 30.2773 154.1 51.59 154.1Z"
          fill={getColor("#B5B5FF", monoChrome, theme)}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M71.11 144.78C68.62 147.24 60.11 149.04 50.01 149.04C39.91 149.04 31.2 147.19 28.82 144.69C12.44 128.57 7.53002 111.67 4.08002 78.3701H95.96C92.51 111.72 87.53 128.74 71.12 144.86L71.11 144.78Z"
          fill={getColor("#D8D8FF", monoChrome, theme)}
        />
        <path
          d="M93.64 96.7801C93.4398 97.8012 92.8421 98.7009 91.9785 99.2813C91.1149 99.8617 90.0561 100.075 89.035 99.8751C88.0139 99.6748 87.1142 99.0772 86.5338 98.2136C85.9534 97.35 85.7398 96.2912 85.94 95.2701C86.4954 92.4388 87.8761 89.8351 89.9082 87.7869C91.9404 85.7387 94.5332 84.3376 97.36 83.7601C99.6244 83.245 101.976 83.245 104.24 83.7601C106.619 84.3183 108.822 85.4592 110.65 87.0801C115.23 91.1401 117.92 98.5601 115.32 109.82C112.92 120.23 107.17 125.65 101.24 127.3C98.887 127.963 96.4062 128.032 94.02 127.5C91.6849 126.976 89.5209 125.868 87.73 124.28C83.73 120.72 81.55 114.81 83.59 107.6C83.8711 106.599 84.5385 105.75 85.4452 105.241C86.352 104.732 87.4238 104.604 88.425 104.885C89.4262 105.166 90.2747 105.834 90.7839 106.74C91.293 107.647 91.4212 108.719 91.14 109.72C90.03 113.67 90.99 116.72 92.92 118.42C93.7196 119.128 94.6868 119.621 95.73 119.85C96.8602 120.106 98.0367 120.071 99.15 119.75C102.59 118.75 106.03 115.21 107.68 108.07C109.52 100.07 108.09 95.2801 105.46 92.9501C104.604 92.1933 103.573 91.6607 102.46 91.4001C101.337 91.1502 100.173 91.1502 99.05 91.4001C97.718 91.663 96.4936 92.3141 95.5309 93.2714C94.5682 94.2288 93.9103 95.4496 93.64 96.7801Z"
          fill={getColor("#D8D8FF", monoChrome, theme)}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M71.11 144.78C68.97 146.89 62.42 148.51 54.22 148.93C45.91 148.51 39.28 146.84 37.22 144.69C20.83 128.57 15.95 111.67 12.5 78.3701H95.96C92.51 111.71 87.53 128.73 71.12 144.85L71.11 144.78Z"
          fill={getColor("#D8D8FF", monoChrome, theme)}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M50.02 89.8599C75.34 89.8599 96.02 84.4299 96.02 77.7599C96.02 71.0899 75.36 65.6599 50.02 65.6599C24.68 65.6599 4.02002 71.0999 4.02002 77.7599C4.02002 84.4199 24.69 89.8599 50.02 89.8599Z"
          fill={getColor("#D8D8FF", monoChrome, theme)}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M50.02 87C73.2 87 92.1 82.85 92.1 77.76C92.1 72.67 73.2 68.53 50.02 68.53C26.84 68.53 7.92999 72.68 7.92999 77.76C7.92999 82.84 26.83 87 50.02 87Z"
          fill={getColor("#B5B5FF", monoChrome, theme)}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M86.04 82.5401C78.67 85.2101 65.29 87.0001 50.04 87.0001C34.79 87.0001 21.39 85.2101 14.04 82.5401C21.41 79.8701 34.79 78.0801 50.04 78.0801C65.29 78.0801 78.67 79.8701 86.04 82.5401Z"
          fill={getColor("#4E57AA", monoChrome, theme)}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M86.04 82.5401C83.2369 83.5097 80.3612 84.2553 77.44 84.7701C76.6629 83.3913 75.5212 82.253 74.14 81.4801C70.24 79.4801 56.73 79.3801 52.67 80.2801C48.61 81.1801 41.86 82.0801 38.41 81.4801C34.96 80.8801 25.35 81.9301 21.81 84.6101C19.1691 84.1134 16.5686 83.4215 14.03 82.5401C21.4 79.8701 34.78 78.0801 50.03 78.0801C65.28 78.0801 78.67 79.8701 86.04 82.5401Z"
          fill={getColor("#4E57AA", monoChrome, theme)}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M76.57 45.7001C88.57 39.8501 86.85 23.4201 71.62 22.0901C53.92 20.5501 44.62 44.8001 51.95 64.3901C53.85 41.5301 62.27 30.9801 72.2 31.1001C80.62 31.1901 77.85 42.9701 76.57 45.7001Z"
          fill={getColor("#E2E2EE", monoChrome, theme)}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M46.91 57.87C35.67 31.81 65 17.02 59.3 0C53.22 17.6 22.77 30.18 46.91 57.87Z"
          fill={getColor("#E2E2EE", monoChrome, theme)}
        />
      </g>
      <defs>
        <clipPath id="clip0_2627_3403">
          <rect
            width="116.34"
            height="154.1"
            fill={getColor("white", monoChrome, theme)}
          />
        </clipPath>
      </defs>
    </SvgIcon>
  );
};
