import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import { StoreState } from "../../../reducers";
import { Opportunity } from "../../../models/Opportunity";
import { FinancialConvo } from "../../../models/FinancialConvo";
import { Estate } from "../../../models/Estate";
import { FinancialConvoSummary } from "./financialConvo/FinancialConvoSummary";
import { Box, Button, Theme } from "@mui/material";
import { makeStyles } from "tss-react/mui";
import { DetailCard } from "../../shared/DetailCard";
import moment from "moment/moment";
import Skeleton from "@mui/lab/Skeleton";

import { User } from "../../../models/User";
import {
  fetchSingleOpportunity,
  fetchOpportunityStatuses,
  fetchOpportunityTypes,
} from "../../../actions/opportunityActions";
import { fetchSingleFinancialConvo } from "../../../actions/financialConvoActions";
import { fetchSingleEstate } from "../../../actions/estateActions";

import { OpportunityType } from "../../../models/OpportunityType";
import { OpportunityStatus } from "../../../models/OpportunityStatus";
import { EditableOpportunity } from "../../opportunities/EditableOpportunity";
import { Typography, Grid, Divider } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { SCREEN_PATHS } from "../../../constants";

const useStyles = makeStyles()((theme:Theme) => {return {
  convoTitle: {
    marginBottom: "1rem",
  },
  divider: {
    marginBottom: "1rem",
  },
  sectionTitle: {
    marginBottom: "2rem",
  },
}});

interface Props {
  user?: User;
  estates?: Estate[];
  financialConvos?: FinancialConvo[];
  opportunities?: Opportunity[];
  opportunityTypes: OpportunityType[];
  opportunityStatuses: OpportunityStatus[];
  fetchSingleFinancialConvo: (financialConvoId: number) => void;
  fetchSingleOpportunity: (
    opportunityId: number,
    opportunityTypes: OpportunityType[],
    opportunityStatuses: OpportunityStatus[]
  ) => void;
  fetchSingleEstate: (estateId: number) => void;
  fetchOpportunityStatuses: () => void;
  fetchOpportunityTypes: () => void;
}

const _FinancialConvoView = ({
  user,
  estates,
  financialConvos,
  opportunities,
  opportunityTypes,
  opportunityStatuses,
  fetchSingleFinancialConvo,
  fetchSingleOpportunity,
  fetchSingleEstate,
  fetchOpportunityStatuses,
  fetchOpportunityTypes,
}: Props) => {
  const navigate = useNavigate();
  const { classes } = useStyles();
  const { estateId, financialConvoId } = useParams();

  const [startedConvoFetch, setStartedConvoFetch] = useState(false);
  const [startedOppFetch, setStartedOppFetch] = useState(false);
  // Check URL Params:
  const parsedEstateId = (estateId && parseInt(estateId)) || 0;
  const parsedFinancialConvoId = (financialConvoId && parseInt(financialConvoId)) || 0;

  useEffect( () => {  
    if(opportunityTypes && opportunityTypes.length > 0) {
      return
    }
    fetchOpportunityTypes() 
  }, []) 
  useEffect( () => {  
    if(opportunityStatuses && opportunityStatuses.length > 0) {
      return
    }
    fetchOpportunityStatuses() 
  }, []) 
  

  const cachedEstate = estates?.filter(
    (estate: Estate) => estate?.id === parsedEstateId
  )[0];

  const estate: Estate | undefined = cachedEstate;

  const cachedFinancialConvo = financialConvos?.filter(
    (financialConvo: FinancialConvo) =>
      financialConvoId && financialConvo?.id === +financialConvoId
  )[0];

  const financialConvo: FinancialConvo | undefined = cachedFinancialConvo;

  let opportunity: Opportunity | undefined = financialConvo
    ? opportunities?.filter(
        (opp: Opportunity) => opp?.id === financialConvo.opportunityId
      )[0]
    : undefined;

  useEffect(() => {
    if (isNaN(parsedEstateId)) {
      navigate(SCREEN_PATHS.estates);
    } else if (isNaN(parsedFinancialConvoId)) {
      navigate(`${SCREEN_PATHS.estates}/${parsedEstateId}`);
    } else {
      if (!estate) {
        fetchSingleEstate(parsedEstateId);
      }
      if (!financialConvo && !startedConvoFetch) {
        setStartedConvoFetch(true);
        fetchSingleFinancialConvo(parsedFinancialConvoId);
      }
      if (financialConvo && financialConvo?.estateId != parsedEstateId) {
        navigate(`${SCREEN_PATHS.estates}/${parsedEstateId}`);
      }
    }
  }, []);

  useEffect(() => {
    opportunity = opportunities?.filter(
      (opp: Opportunity) => opp?.id === financialConvo?.opportunityId
    )[0];
    if (
      !opportunity &&
      financialConvo &&
      financialConvo.opportunityId &&
      opportunityTypes &&
      !startedOppFetch &&
      opportunityStatuses
    ) {
      setStartedOppFetch(true);
      fetchSingleOpportunity(
        financialConvo.opportunityId,
        opportunityTypes,
        opportunityStatuses
      );
    }
  }, [financialConvo,opportunityStatuses, opportunityTypes]);

  return (
    <div data-automation-id="page-content-estate-financial-convo">
      <DetailCard
        title="Financial Conversation Request"
        subTitle={
          opportunity && financialConvo
            ? opportunity.createdAt
              ? `Requested on ${moment(opportunity.createdAt).format(
                  "MM/DD/YYYY, h:mm a"
                )}`
              : `Requested on ${moment(financialConvo.updatedAt).format(
                  "MM/DD/YYYY, h:mm a"
                )}`
            : `Not Requested Yet`
        }
        topRightComponent={
          <Button onClick={() => navigate(-1)}>&lt; Back</Button>
        }
      >
        <>
          {financialConvo && (
            <>
              <Divider className={classes.divider} />

              <Grid container spacing={4}>
                {opportunity && (
                  <Grid item xs={12}>
                    <Typography variant="h4" className={classes.sectionTitle}>
                      Linked Opportunity
                    </Typography>
                    <EditableOpportunity
                      opportunity={opportunity}
                      user={user}
                      estate={estate}
                      noPad
                    />
                  </Grid>
                )}
                <Grid item xs={12}>
                  <Typography variant="h4" className={classes.sectionTitle}>
                    Questionnaire Summary
                  </Typography>
                  <FinancialConvoSummary financialConvo={financialConvo} />
                </Grid>
              </Grid>
            </>
          )}
          {!financialConvo && (
            <Box>
              <Typography
                component="div"
                variant="body2"
                className={classes.convoTitle}
              >
                <Skeleton width="30%" />
              </Typography>
              <Skeleton variant="rectangular" height="100px" />

              <Divider className={classes.divider} />
              <Typography
                component="div"
                variant="body2"
                className={classes.convoTitle}
              >
                <Skeleton width="30%" />
              </Typography>
              <Skeleton variant="rectangular" height="500px" />
            </Box>
          )}
        </>
      </DetailCard>
    </div>
  );
};

const mapStateToProps = ({
  user,
  estates,
  financialConvos,
  opportunities,
  opportunityTypes,
  opportunityStatuses,
}: StoreState): {
  user: User;
  estates: Estate[];
  financialConvos: FinancialConvo[];
  opportunities: Opportunity[];
  opportunityTypes: OpportunityType[];
  opportunityStatuses: OpportunityStatus[];
} => {
  return {
    user,
    estates,
    financialConvos,
    opportunities,
    opportunityTypes,
    opportunityStatuses,
  };
};

export const FinancialConvoView = connect(mapStateToProps, {
  fetchSingleFinancialConvo,
  fetchSingleOpportunity,
  fetchSingleEstate,
  fetchOpportunityStatuses,
  fetchOpportunityTypes,
})(_FinancialConvoView);
