import { Dispatch } from "redux";
import { ActionTypes } from "./types";
import { KnowledgeEngineFieldCategory } from "../models/KnowledgeEngine";
import { knowledgeEngineApi } from "../apis";

export interface FetchKnowledgeEngineFieldCategoriesAction {
  type: ActionTypes.FetchKnowledgeEngineFieldCategories;
  payload: KnowledgeEngineFieldCategory[];
}

export const fetchKnowledgeEngineFieldCategories = () => {
  return async (dispatch: Dispatch): Promise<void> => {
    let categories: KnowledgeEngineFieldCategory[] = [];
    try {
      categories = await knowledgeEngineApi.getFieldCategories();
    } catch (error) {
      console.error(error);
    }
    dispatch<FetchKnowledgeEngineFieldCategoriesAction>({
      type: ActionTypes.FetchKnowledgeEngineFieldCategories,
      payload: categories,
    });
  };
};
