import React, { useEffect } from "react";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import { StoreState } from "../../../reducers";
import { Estate } from "../../../models/Estate";
import { Card, CardContent } from "@mui/material";
import { fetchEstateNotes } from "../../../actions/estateActions";
import { NotesTable } from "./notes/NotesTable";
import {
  ContentState,
  ContentStateWrapper,
} from "../../shared/ContentStateWrapper";

interface Props {
  estates?: Estate[];
  fetchEstateNotes: (estate: Estate) => void;
}

const _NotesView = ({ estates, fetchEstateNotes }: Props) => {
  const { estateId } = useParams();

  let estate: Estate | undefined;
  if (estateId && estates?.length) {
    estate = estates.find((estate: Estate) => estate.id === +estateId);
  }

  useEffect(() => {
    if (estate) {
      if (estate.notes === undefined) {
        fetchEstateNotes(estate);
      }
    }
  }, []);

  return (
    <div data-automation-id="page-content-estate-notes">
      <Card>
        <CardContent>
          <ContentStateWrapper
            contentType="Notes"
            contentTypeSingular="Note"
            zeroText="There aren't any notes yet. This is where you can keep track of all those important details about the estate."
            zeroImagePrefix="/static/images/zero/notes"
            zeroShowAddButton={true}
            contentState={((): ContentState => {
              if (!estate?.notes) {
                return ContentState.loading;
              } else if (estate?.notes?.length === 0) {
                return ContentState.zero;
              } else {
                return ContentState.render;
              }
            })()}
          >
            {estate?.notes ? (
              <NotesTable
                estateNotes={estate.notes}
                estateId={estate?.id || 0}
              />
            ) : (
              <></>
            )}
          </ContentStateWrapper>
        </CardContent>
      </Card>
    </div>
  );
};

const mapStateToProps = ({ estates }: StoreState): { estates: Estate[] } => {
  return { estates };
};

export const NotesView = connect(mapStateToProps, {
  fetchEstateNotes,
})(_NotesView);
