import React, { useEffect, useState } from "react";
import { Box } from "@mui/material";
import { axiosInstance, CancelToken, logger } from "../../../../apis";
import { isProd } from "../../../../constants"
import axios, { AxiosError, CancelTokenSource } from "axios";
import { StoreState } from "../../../../reducers";
import { connect } from "react-redux";
import { AssetImage } from "../../../../models/AssetImage";
import { cacheAssetImage } from "../../../../actions/estateActions";
import { SxProps } from "@mui/system/styleFunctionSx";

// created by img-resizer
//  https://us-east-2.console.aws.amazon.com/lambda/home?region=us-east-2#/functions/img-resizer-dev?tab=configure
export enum Dimensions {
  "x75" = "75x75",
  "x150" = "150x150",
  "x165" = "165x165",
  "x238" = "238x238",
  "x300" = "300x300",
  "x330" = "330x330",
  "x360" = "360x360",
  "x476" = "476x476",
  "x720" = "720x720",
  "x952" = "952x952",
  "x1440" = "1440x1440",
}

interface Props {
  assetImage?: AssetImage;
  title?: string;
  dimensions?: Dimensions;
  retries?: number;
  assetImageCache?: AssetImage[];
  cacheAssetImage: (assetImage: AssetImage) => void;
  sx?: SxProps;
  allowVertCrop?: boolean;
}

const _AsyncAssetImage = ({
  assetImage,
  title,
  dimensions = Dimensions.x300,
  retries = 0,
  assetImageCache,
  cacheAssetImage,
  sx = {},
  allowVertCrop = true,
}: Props): JSX.Element => {
  const [assetImageSrc, setAssetImageSrc] = useState("");
  const [isHorizontal, setIsHorizontal] = useState(true);

  useEffect(() => {
    if (assetImage?.url) {
      // static images during load will have url already set
      setAssetImageSrc(assetImage.url);
    } else if (assetImage?.base64Data) {
      setAssetImageSrc(assetImage.base64Data);
    } else {
      // have asset image cached? use it, otherwise go get it, then place in cache
      const filteredAssetImages = assetImageCache?.filter(
        (image: AssetImage) =>
          image.assetId === assetImage?.assetId && image.id === assetImage?.id
      );
      if (filteredAssetImages?.length) {
        setAssetImageSrc(filteredAssetImages[0]?.url || "");
      } else {
        const cancelTokenSource = CancelToken.source();
        getAssetImageUrl(cancelTokenSource, dimensions, retries);
        return () => {
          // cleans up an incomplete network calls
          cancelTokenSource.cancel();
        };
      }
    }
  }, []);

  const getAssetImageUrl = async (
    cancelTokenSource: CancelTokenSource,
    dimensions: Dimensions,
    retriesLeft?: number
  ): Promise<void> => {
    // index may be 0
    if (assetImage?.index !== undefined && assetImage?.fileName) {
      try {
        const response = await axiosInstance.get(
          `${assetImage.networkUrl}/${dimensions}`,
          {
            responseType: "blob",
            cancelToken: cancelTokenSource.token,
          }
        );
        if (response?.data) {
          // TODO: what if images aren't jpeg?
          const file = new Blob([response.data], {
            type: `image/${assetImage.extension}`,
          });
          const urlCreator = window.URL || window.webkitURL;
          const imageUrl = urlCreator.createObjectURL(file);
          assetImage.url = imageUrl;
          cacheAssetImage(assetImage);
          setAssetImageSrc(imageUrl);
        } else {
          throw Error("Sorry, invalid response.");
        }
      } catch (error) {
        const is404 = (error as any)?.response?.status === 404;
        if (is404 && retriesLeft) {
          setTimeout(() => {
            // console.log("retry loading image", retriesLeft);
            getAssetImageUrl(cancelTokenSource, dimensions, retriesLeft - 1);
          }, 350);
        } else {
          // don't log cancel errors or non-prod 404s and 500s
          const isNonProd404 = is404 && !isProd;
          const isNonProd500 =
            (error as any)?.response?.status === 500 && !isProd;
          if (!(axios.isCancel(error) || isNonProd404 || isNonProd500)) {
            logger.error(error as Error);
          }
        }
      }
    }
  };

  const getImage = (): JSX.Element => {
    if (!assetImageSrc) return <></>;
    // determine if image is vertical
    const preloadedImage = new Image();
    preloadedImage.src = assetImageSrc;
    preloadedImage.onload = (event: any) => {
      const width = event?.target?.width;
      const height = event?.target?.height;
      if (width && height && width < height) {
        setIsHorizontal(false);
      }
    };

    return (
      <Box
        sx={{
          ...sx,
        }}
        key={`AsyncAssetImage_${assetImage?.id}`}
      >
        <img
          style={{
            width: isHorizontal && allowVertCrop ? "100%" : "auto",
            height: "100%",
            objectFit: "cover",
          }}
          src={assetImageSrc || ""}
          alt={title || ""}
          data-id={assetImage?.id}
        />
      </Box>
    );
  };

  return getImage();
};

const mapStateToProps = ({
  assetImageCache,
}: StoreState): { assetImageCache: AssetImage[] } => {
  return { assetImageCache };
};

export const AsyncAssetImage = connect(mapStateToProps, {
  cacheAssetImage,
})(_AsyncAssetImage);
