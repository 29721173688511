import React from "react";
import { Box, SxProps, Theme } from "@mui/material";
import { PUBLIC_FORMS_CDN_URL } from "../../constants";

const NorthAmericaWatercolorImageUrl =
  "../../assets/icons/assets/north-america-watercolor-outline.png";
interface Props {
  geographySlug?: string;
  sx?: SxProps<Theme>;
}

export const GeographyWatercolorImg = ({
  geographySlug,
  sx,
}: Props): JSX.Element => {
  return (
    <Box
      sx={sx}
      component="img"
      src={
        geographySlug
          ? `${PUBLIC_FORMS_CDN_URL}/watercolor-geography-icons/${geographySlug}-watercolor-icon-atticus.png`
          : NorthAmericaWatercolorImageUrl
      }
    />
  );
};
