import { callGet, logger } from "./index";
import { KnowledgeEngineFieldCategory } from "../models/KnowledgeEngine";
import { LARAVEL_APP_HOST } from "../constants";

const path = "/knowledge-engine";

export class KnowledgeEngine {
  async getAllFieldsForUser(userId: number): Promise<any> {
    try {
      const fullPath = `${path}/fields/${userId}`;
      const response = await callGet(fullPath, true);
      return response?.data;
    } catch (error) {
      this.handleError(error);
    }
  }

  async getFieldCategories(): Promise<any> {
    try {
      const fullPath = `${path}/categories`;
      const response = await callGet(fullPath, true, {
        baseURL: `${LARAVEL_APP_HOST}/api/v2`,
      });
      if (response?.data) {
        const categories: KnowledgeEngineFieldCategory[] =
          response.data.categories.map((thisCategory: any) => {
            return {
              id: thisCategory.id,
              title: thisCategory.title,
              order: thisCategory.order,
            };
          });
        return categories;
      } else {
        return null;
      }
      return response?.data;
    } catch (error) {
      this.handleError(error);
    }
  }

  handleError(error: any): void {
    if ((error as Error)?.message === "no response.data") {
      logger.error(error, false);
    } else {
      logger.error(error as Error);
    }
  }
}
