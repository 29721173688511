import React, { useEffect } from "react";
import {
  Card,
  CardContent,
  CardHeader,
  Container,
  Grid,
  Theme,
} from "@mui/material";
import { makeStyles } from "tss-react/mui";
import Page from "../../../components/Page";
import ContentHeader from "../../shared/ContentHeader";
import { StoreState } from "../../../reducers";
import { User } from "../../../models/User";
import { connect } from "react-redux";
import { InviteAdvisorForm } from "./InviteAdvisorForm";
import { ATTICUS_PAGE_PREFIX, INVITE_TTL_DAYS } from "../../../constants";
import { AdvisorGroup } from "../../../models/AdvisorGroup";
import { fetchAdvisorGroups } from "../../../actions/advisorGroupActions";

const useStyles = makeStyles()((theme: Theme) => ({
  root: {
    backgroundColor: theme.palette.background.default,
    minHeight: "100%",
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
  button: {
    margin: theme.spacing(1),
  },
}));

interface Props {
  user: User;
  advisorGroups: AdvisorGroup[];
  fetchAdvisorGroups: () => void;
}

const _InviteAdvisorView = ({
  user,
  advisorGroups,
  fetchAdvisorGroups,
}: Props): JSX.Element => {
  const { classes } = useStyles();

  useEffect(() => {
    if (user?.isUberAdmin && !advisorGroups?.length) {
      fetchAdvisorGroups();
    }
  }, []);

  return (
    <Page
      className={classes.root}
      title={`${ATTICUS_PAGE_PREFIX} - Invite`}
      data-automation-id="page-content-invite-advisor"
    >
      <Container maxWidth={false}>
        <ContentHeader
          heading="Invite an Advisor"
          subHeading=""
          showSearch={false}
        />
        <Grid container spacing={2}>
          <Grid item sm={10} xs={12}>
            <Card>
              <CardHeader title="Create an advisor invitation link" />
              <CardContent>
                <ul>
                  <li>The advisor must sign up using the email entered here</li>
                  <li>
                    The advisor must take action in {INVITE_TTL_DAYS} days
                  </li>
                  <li>
                    Simply create another invitation if any of these become an
                    issue
                  </li>
                </ul>
                <InviteAdvisorForm user={user} advisorGroups={advisorGroups} />
              </CardContent>
            </Card>
          </Grid>
          {/*<Grid item sm={10} xs={12}>*/}
          {/*  <Card>*/}
          {/*    <CardContent>*/}
          {/*      <Typography variant="h4">future features</Typography>*/}
          {/*      <ul>*/}
          {/*        <li>Send an email directly to advisor with clickable link</li>*/}
          {/*        <li>*/}
          {/*          Configurable email domain requirement: must be*/}
          {/*          @example.bank.com*/}
          {/*        </li>*/}
          {/*        <li>*/}
          {/*          batch invite creation via comma-delimited email input field*/}
          {/*          above*/}
          {/*        </li>*/}
          {/*      </ul>*/}
          {/*    </CardContent>*/}
          {/*  </Card>*/}
          {/*</Grid>*/}
        </Grid>
      </Container>
    </Page>
  );
};

const mapStateToProps = ({
  user,
  advisorGroups,
}: StoreState): { user: User; advisorGroups: AdvisorGroup[] } => {
  return { user, advisorGroups };
};

export const InviteAdvisorView = connect(mapStateToProps, {
  fetchAdvisorGroups,
})(_InviteAdvisorView);
