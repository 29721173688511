// database field names
export type AdvisorsEstatesJson = {
  id?: number;
  estate_id?: number;
  estate_name?: string;
  advisor_user_id?: number;
  advisor_name?: string;
  has_write_access?: number;
};

export class AdvisorsEstates {
  static path = "/advisors-estates";
  path = "/advisors-estates";

  constructor(
    public id?: number,
    public estateId?: number,
    public estateName?: string,
    public advisorUserId?: number,
    public advisorName?: string,
    public hasWriteAccess?: boolean
  ) {}

  static fromJSON = (json: AdvisorsEstatesJson): AdvisorsEstates => {
    return new AdvisorsEstates(
      json.id,
      json.estate_id,
      json.estate_name,
      json.advisor_user_id,
      json.advisor_name,
      json.has_write_access === 1
    );
  };

  toJson = (): AdvisorsEstatesJson => {
    return {
      estate_id: this.estateId,
      advisor_user_id: this.advisorUserId,
      has_write_access: this.hasWriteAccess ? 1 : 0,
    };
  };
}
