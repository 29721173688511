import { InviteTypes } from "../constants";

export class Invite {
  constructor(
    public type?: InviteTypes,
    public email?: string,
    public roleId?: number,
    public advisorId?: number,
    public advisorGroupId?: number,
    public advisorGroup?: string
  ) {}

  toJSON(): any {
    if (this.type === InviteTypes.Advisor) {
      return {
        type: this.type?.toString(),
        email: this.email,
        roleId: this.roleId,
        advisorGroup: this.advisorGroup,
      };
    } else {
      // End-User
      return {
        type: this.type?.toString(),
        advisorId: this.advisorId,
        advisorGroupId: this.advisorGroupId,
      };
    }
  }
}
