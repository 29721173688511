export type FormTypeJson = {
  name: string;
  slug: string;
  id: number;
};
export class FormType {
  constructor(public name: string, public slug: string, public id: number) {}

  static fromJSON(json: FormTypeJson): FormType {
    return new FormType(json.name, json.slug, json.id);
  }

  toJSON(): FormTypeJson {
    return {
      name: this.name,
      slug: this.slug,
      id: this.id,
    };
  }
}
