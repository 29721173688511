export type EstateNoteJSON = {
  id?: number;
  text?: string;
  estate_id?: number;
  user_id?: number;
  creator_name?: string;
  updated_at?: Date | string;
  created_at?: Date | string;
};

export class EstateNote {
  static path = "notes";

  constructor(
    public id?: number,
    public text?: string,
    public estateId?: number,
    public userId?: number,
    public creatorName?: string,
    public updatedAt?: Date,
    public createdAt?: Date
  ) {}

  static fromJSON(estateNote: EstateNoteJSON): EstateNote {
    if (estateNote && estateNote.id) {
      return new EstateNote(
        estateNote.id,
        estateNote.text,
        estateNote.estate_id,
        estateNote.user_id,
        estateNote.creator_name,
        estateNote.updated_at ? new Date(estateNote.updated_at) : undefined,
        estateNote.created_at ? new Date(estateNote.created_at) : undefined
      );
    } else {
      return new EstateNote();
    }
  }

  toJSON(): EstateNoteJSON {
    return {
      id: this.id,
      text: this.text,
      estate_id: this.estateId,
      user_id: this.userId,
      creator_name: this.creatorName,
    };
  }
}
