import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import React, { useEffect, useState } from "react";
import { StoreState } from "../../../../reducers";
import { connect } from "react-redux";
import { fetchAdvisors } from "../../../../actions/advisorActions";
import { Estate } from "../../../../models/Estate";
import { Advisor } from "../../../../models/Advisor";
import { AdvisorsEstates } from "../../../../models/AdvisorsEstates";
import { advisorApi } from "../../../../apis";
import { AssignAdvisorForm } from "../../../advisors/AdvisorListView/AssignAdvisorForm";

interface Props {
  open?: boolean;
  estate?: Estate;
  advisors?: Advisor[];
  fetchAdvisors: () => void;
  onClose: (refresh?: boolean) => void;
}

export const _AdvisorsDialog = ({
  open,
  estate,
  advisors,
  fetchAdvisors,
  onClose,
}: Props): JSX.Element => {
  const [refreshOnClose, setRefreshOnClose] = useState(false);

  const handleAssign = async (
    advisorsEstates: AdvisorsEstates
  ): Promise<void> => {
    setRefreshOnClose(true);
    if (advisorsEstates?.id) {
      await advisorApi.updateAdvisorEstateAccess(advisorsEstates);
    } else {
      await advisorApi.assignAdvisor(advisorsEstates);
    }
    fetchAdvisors();
  };

  const handleUnassign = async (advisedEstateId?: number): Promise<void> => {
    if (advisedEstateId) {
      setRefreshOnClose(true);
      await advisorApi.unassignAdvisor(advisedEstateId);
      fetchAdvisors();
    }
  };

  // const handleUpdateAccess = () => {
  //   setRefreshOnClose(true);
  //   fetchAdvisors();
  // };

  useEffect(() => {
    (async () => {
      if (!advisors) {
        fetchAdvisors();
      }
    })();
  }, []);

  const sortedAdvisors =
    advisors?.sort((a: Advisor, b: Advisor) =>
      (a?.user?.email || "") > (b?.user?.email || "") ? 1 : -1
    ) || advisors;

  // console.log("~ sortedAdvisors", sortedAdvisors);

  return (
    <Dialog
      open={!!open}
      onClose={() => onClose(refreshOnClose)}
      sx={{
        "&>div>div": {
          p: 1,
          pt: 4,
        },
      }}
      fullWidth={true}
      maxWidth="lg"
    >
      <IconButton
        style={{
          position: "absolute",
          top: 8,
          right: 8,
        }}
        aria-label="delete"
        onClick={() => onClose(refreshOnClose)}
      >
        <CloseIcon />
      </IconButton>
      <DialogTitle id="alert-dialog-title" component="div">
        <Typography variant="h2">Advisors for {estate?.name} estate</Typography>
      </DialogTitle>
      <DialogContent
        style={{
          textAlign: "left",
        }}
      >
        <Table
          sx={{
            "& tr": {
              height: 56,
            },
          }}
        >
          <TableHead>
            <TableRow
              sx={{
                "&>th": {
                  backgroundColor: "background.default",
                  color: "grey.700",
                },
              }}
            >
              <TableCell>Advisor Name</TableCell>
              <TableCell>Access</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {sortedAdvisors?.map((advisor: Advisor) => {
              // Is this Advisors assigned to the currently focused estate?
              //  If not, create a new AdvisorsEstates to be modified and saved
              const advisedEstate =
                advisor?.estates?.find(
                  (ae: AdvisorsEstates) => ae?.estateId === estate?.id
                ) || new AdvisorsEstates(undefined, estate?.id);
              return (
                <TableRow
                  key={"AdvisorsDialog_" + advisor?.user?.id}
                  sx={{
                    "&>td": {
                      backgroundColor: "background.paper",
                      borderBottom: "solid 1px",
                      borderColor: "grey.300",
                    },
                  }}
                >
                  <TableCell>
                    <Typography
                      sx={{
                        fontWeight: advisedEstate?.hasWriteAccess
                          ? "bold"
                          : "normal",
                      }}
                    >
                      {advisor?.user?.selectName}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <AssignAdvisorForm
                        advisorsEstates={advisedEstate}
                        advisor={advisor}
                        onAssign={(advisedEstate: AdvisorsEstates) => {
                          handleAssign(advisedEstate);
                        }}
                        onUnassign={
                          advisedEstate?.id
                            ? () => {
                                handleUnassign(advisedEstate?.id);
                              }
                            : undefined
                        }
                      />
                    </Box>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </DialogContent>
    </Dialog>
  );
};

const mapStateToProps = ({
  advisors,
}: StoreState): {
  advisors: Advisor[];
} => {
  return { advisors };
};

export const AdvisorsDialog = connect(mapStateToProps, {
  fetchAdvisors,
})(_AdvisorsDialog);
