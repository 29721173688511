import React from "react";
import { Box, Button, Typography } from "@mui/material";
import { CheckMarkIcon } from "./AtticusIcons";

export type SelectButton = {
  key: string;
  text: string;
  icon?: JSX.Element;
  response?: string;
};

interface Props {
  text?: string;
  icon?: JSX.Element;
  selected?: boolean;
  onClick?: (selected: boolean) => void;
}

export const IconButton = ({
  text,
  icon,
  selected,
  onClick,
}: Props): JSX.Element => {
  return (
    <Button
      sx={{
        borderRadius: "10px",
        border: "solid 1px",
        borderColor: selected ? "primary" : "#E0E0E0",
        backgroundColor: selected ? "#F0F0FE" : "none",
        "&:hover, &:focus": {
          backgroundColor: "#F0F0FE",
        },
      }}
      disableRipple
      fullWidth
      onClick={() => onClick && onClick(!selected)}
    >
      <Box
        sx={{
          pt: 2,
          px: 3,
          pb: 2,
          minWidth: "180px",
          minHeight: "156px",
          display: "flex",
          gap: 1,
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        {selected ? (
          <CheckMarkIcon sx={{ width: 60, height: 60 }} viewBox="0 0 60 60" />
        ) : (
          icon
        )}
        <Typography
          variant="body2"
          sx={{ lineHeight: "20px", color: "grey.800" }}
        >
          {text}
        </Typography>
      </Box>
    </Button>
  );
};
