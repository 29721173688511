import React, { createContext, useEffect, useState } from "react";
import { AssetCategory } from "../../../../models/AssetCategory";
import { assetApi } from "../../../../apis";

interface Props {
  children: JSX.Element;
}

export interface AssetsContextInterface {
  categories?: AssetCategory[];
}

export const AssetsContext = createContext<AssetsContextInterface | undefined>(
  undefined
);

export const AssetsContextWrapper = ({ children }: Props): JSX.Element => {
  const [categories, setCategories] = useState<AssetCategory[]>();
  useEffect(() => {
    (async () => {
      setCategories(await assetApi.fetchAssetCategories());
    })();
  }, []);

  return (
    <AssetsContext.Provider value={{ categories }}>
      {children}
    </AssetsContext.Provider>
  );
};
