export type ExecutorSummaryJSON = {
  has_completed_onboarding?: number;
  declared_role?: string;
  name_of_corporate_executor?: string;
  relationship?: string;
  relationship_more?: string;
  advisory_position?: string;
  advisory_position_more?: string;
};

export class ExecutorSummary {
  constructor(
    public estateId?: number,
    public hasCompletedOnboarding?: boolean,
    public declaredRole?: string,
    public nameOfCorporateExecutor?: string,
    public relationship?: string,
    public relationshipMore?: string,
    public advisoryPosition?: string,
    public advisoryPositionMore?: string
  ) {}

  static fromJSON(
    estateId?: number,
    obJson?: ExecutorSummaryJSON
  ): ExecutorSummary {
    if (estateId && obJson) {
      return new ExecutorSummary(
        estateId,
        obJson.has_completed_onboarding === 1,
        obJson.declared_role,
        obJson.name_of_corporate_executor,
        obJson.relationship,
        obJson.relationship_more,
        obJson.advisory_position,
        obJson.advisory_position_more
      );
    } else {
      return new ExecutorSummary();
    }
  }

  get advisoryPositionDisplay(): string {
    if (this.advisoryPosition && this.advisoryPosition !== "Something else") {
      return this.advisoryPosition;
    } else if (this.advisoryPositionMore) {
      return this.advisoryPositionMore;
    } else {
      return "--";
    }
  }

  get relationshipDisplay(): string {
    if (this.relationship && this.relationship !== "Something else") {
      return this.relationship;
    } else if (this.relationshipMore) {
      return this.relationshipMore;
    } else {
      return "--";
    }
  }
}
