// database field names
export type UserProfileJSON = {
  id?: number;
  first_name?: string;
  middle_name?: string;
  name_suffix?: string;
  last_name?: string;
  user_id?: number;
  address_one?: string;
  address_two?: string;
  zip_code?: string;
  avatar?: string;
  created_at?: number;
  updated_at?: number;
};

export class UserProfile {
  static path = "/user-profile";
  path = "/user-profile";

  constructor(
    public id?: number,
    public firstName?: string,
    public middleName?: string,
    public lastName?: string,
    public nameSuffix?: string,
    public userId?: number,
    public addressOne?: string,
    public addressTwo?: string,
    public zipCode?: string,
    public avatar?: string, // = "/static/images/avatars/avatar_6.png",
    public createdAt?: number,
    public updatedAt?: number
  ) {}

  static fromJSON(json: UserProfileJSON): UserProfile {
    return new UserProfile(
      json.id,
      json.first_name,
      json.middle_name,
      json.last_name,
      json.name_suffix,
      json.user_id,
      json.address_one,
      json.address_two,
      json.zip_code,
      json.avatar,
      json.created_at,
      json.updated_at
    );
  }

  toJSON(): UserProfileJSON {
    return {
      id: this.id,
      first_name: this.firstName,
      middle_name: this.middleName,
      last_name: this.lastName,
      name_suffix: this.nameSuffix,
      user_id: this.userId,
      address_one: this.addressOne,
      address_two: this.addressTwo,
      zip_code: this.zipCode,
      avatar: this.avatar,
      created_at: this.createdAt,
      updated_at: this.updatedAt,
    };
  }

  get fullName(): string | undefined {
    let retName = this.firstName;
    if (this.middleName) {
      retName += ` ${this.middleName}`;
    }
    if (this.lastName) {
      retName += ` ${this.lastName}`;
    }
    if (this.nameSuffix) {
      retName += `, ${this.nameSuffix}`;
    }
    return retName;
  }
}
