import { createTheme, colors, PaletteMode, Palette, Theme } from "@mui/material";
import { getTypography } from "./typography";
import { getOverrides } from "./overrides";
import atticusColors from "./colors";

const defaultTheme = createTheme();

// https://mui.com/material-ui/customization/default-theme/?expand-path=$.palette#explore
const lightPalette = {
  mode: "light" as PaletteMode,
  action: {
    ...defaultTheme.palette.action,
    selected: atticusColors.greenAlpha1,
  },
  background: {
    default: atticusColors.purple7,
    paper: colors.common.white,
  },
  primary: {
    light: atticusColors.purple0,
    main: atticusColors.purple35,
    dark: atticusColors.purple4,
    contrastText: colors.common.white,
  },
  secondary: {
    ...defaultTheme.palette.secondary,
    main: atticusColors.green2,
    dark: atticusColors.green1,
  },
  error: {
    ...defaultTheme.palette.error,
    light: atticusColors.red2,
    main: atticusColors.red1,
  },
  text: {
    primary: atticusColors.grey1,
    secondary: atticusColors.grey4,
    disabled: atticusColors.grey5,
  },
  divider: atticusColors.grey7,
  common: {
    black: colors.common.black,
    white: colors.common.white,
  },
} as Palette;

const light = createTheme({
  palette: lightPalette,
  typography: getTypography(lightPalette),
  components: getOverrides(lightPalette, defaultTheme),
});

// https://mui.com/material-ui/customization/default-theme/?expand-path=$.palette#explore
const darkPalette = {
  mode: "dark" as PaletteMode,
  background: {
    default: atticusColors.grey3,
    paper: atticusColors.grey2,
  },
  primary: {
    light: atticusColors.green1,
    main: atticusColors.green2,
    dark: atticusColors.purple0,
    contrastText: colors.common.black,
  },
  secondary: {
    main: atticusColors.purple2,
    dark: atticusColors.purple35,
  },
  error: {
    main: atticusColors.red2,
    light: atticusColors.red1,
  },
  text: {
    primary: atticusColors.white,
    secondary: atticusColors.grey7,
    disabled: atticusColors.grey6,
  },
  divider: atticusColors.grey3,
  common: {
    black: colors.common.white,
    white: colors.common.black,
  },
} as Palette;

const dark = createTheme({
  palette: darkPalette,
  typography: getTypography(darkPalette),
  components: getOverrides(darkPalette, defaultTheme),
});

export const themes:{[key:string]:Theme} = {
  light,
  dark,
};
