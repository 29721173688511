import React from "react";
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from "react-router-dom";
import { createStore, applyMiddleware } from "redux";
import { Provider } from "react-redux";
import thunk from "redux-thunk";
import { reducers } from "./reducers";
import { App } from "./App";
import packageJson from "../package.json";
import { composeWithDevTools } from "@redux-devtools/extension";
import { LARAVEL_APP_SERVICE_PATH } from "./constants";
import { HelmetProvider } from "react-helmet-async";
import GlobalStyles from "./components/GlobalStyles";

let store = createStore(reducers, applyMiddleware(thunk))
if (process.env.NODE_ENV == "development") {
  store = createStore(reducers, composeWithDevTools(applyMiddleware(thunk)));
}

const startupLog =
  "environment: " +
  process.env.NODE_ENV +
  "; version: " +
  packageJson.version +
  "; api: " +
  LARAVEL_APP_SERVICE_PATH;

console.log(startupLog);

const container = document.getElementById('root') as HTMLElement
const root = ReactDOM.createRoot(container)
root.render(
<React.StrictMode>
  {GlobalStyles}
  <Provider store={store}>
    <BrowserRouter>
      <HelmetProvider>
        <App />
      </HelmetProvider>
    </BrowserRouter>
  </Provider>
</React.StrictMode>)

