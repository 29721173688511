import React from "react";
import {
  Card,
  CardActionArea,
  Box,
  SxProps,
  Theme,
  useTheme,
  Typography,
  Skeleton,
  useMediaQuery,
} from "@mui/material";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";

export interface EstateFormsInfoCardProps {
  title?: string;
  description?: string;
  imageElement?: JSX.Element;
  loading?: boolean;
  onClick?: () => void;
  sx?: SxProps<Theme>;
}

const EstateFormsInfoCard = ({
  title,
  description,
  imageElement,
  loading,
  onClick,
  sx,
}: EstateFormsInfoCardProps): JSX.Element => {
  const theme = useTheme();
  const isMobileSize = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Card variant="outlined" sx={[...(Array.isArray(sx) ? sx : [sx])]}>
      <CardActionArea
        disabled={loading || !onClick}
        onClick={onClick}
        sx={[
          {
            display: "flex",
            flexDirection: isMobileSize ? "column" : "row",
            backgroundColor: "#F2F2FF",
            borderColor: "red !IMPORTANT",
            gap: 2,
            paddingX: 2,
            paddingY: 2,
            borderRadius: "0px",
            color: "#2d2a5c",
          },
        ]}
      >
        {imageElement && (
          <>
            {loading ? (
              <Skeleton
                variant="circular"
                sx={{ width: "5.5rem", height: "5.5rem" }}
              />
            ) : (
              imageElement
            )}
          </>
        )}

        <Box sx={{ flex: 1, display: "flex", gap: 2, alignItems: "center" }}>
          <Box sx={{ flex: 1 }}>
            {title && (
              <>
                {loading ? (
                  <Skeleton
                    variant="text"
                    sx={{ fontSize: "1rem", width: "80%" }}
                  />
                ) : (
                  <Typography
                    component="h5"
                    variant="h4"
                    sx={{ color: "#2d2a5c", fontWeight: "bold" }}
                  >
                    {title}
                  </Typography>
                )}
              </>
            )}
            {description && (
              <>
                {loading ? (
                  <Skeleton
                    variant="text"
                    sx={{ fontSize: "1rem", width: "50%" }}
                  />
                ) : (
                  <Typography variant="body2">{description}</Typography>
                )}
              </>
            )}
          </Box>
          {onClick && !loading && (
            <ArrowForwardIcon sx={{ fontSize: "2.25rem" }} />
          )}
        </Box>
      </CardActionArea>
    </Card>
  );
};

export default EstateFormsInfoCard;
