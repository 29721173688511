import React, { useEffect, useState } from "react";
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  TextField,
  Typography,
} from "@mui/material";
import { ReassignDialog } from "./ReassignDialog";
import { CONSTANTS } from "../../../../constants";
import { estateApi } from "../../../../apis";
import { Estate } from "../../../../models/Estate";
import { AxiosError } from "axios";
import { LoadingSpinner } from "../../../shared/LoadingSpinner";

interface Props {
  estate?: Estate;
  isOpen: boolean;
  onClose: (reassigned?: boolean) => void;
}

export const ReassignAccount = ({
  estate,
  isOpen,
  onClose,
}: Props): JSX.Element => {
  const [reassignComplete, setReassignComplete] = useState(false);
  const [processing, setProcessing] = useState(false);
  const [newEmail, setNewEmail] = useState<string>();
  const [acknowledge, setAcknowledge] = useState(false);
  const [error, setError] = useState<string>();

  useEffect(() => {
    return () => {
      setNewEmail("");
      setAcknowledge(false);
      setError("");
      setReassignComplete(false);
    };
  }, [isOpen]);

  const handleReassign = async (): Promise<boolean> => {
    setError("");
    if (estate?.id) {
      if (!newEmail) {
        setError("Email is required");
        return false;
      }
      if (!CONSTANTS.REGEX.email.test(newEmail)) {
        setError("Please check the email");
        return false;
      }
      if (!acknowledge) {
        setError("Please confirm acknowledgement");
        return false;
      }

      try {
        await estateApi.reassignUserToEmail(estate.id, newEmail);
      } catch (error) {
        if ((error as any)?.response?.status === 401) {
          window.document.location.href = `/app/estates`;
        } else {
          const message =
            (error as any)?.response?.data?.message ||
            (error as any)?.message;
          setError(message);
          return false;
        }
      }
    } else {
      setError("Estate.id unknown");
      return false;
    }
    return true;
  };

  return (
    <ReassignDialog
      question={
        !reassignComplete
          ? "Email address to reassign this account to"
          : "Success!"
      }
      open={isOpen}
      description={
        !reassignComplete ? (
          <FormGroup
            sx={
              {
                // textAlign: "center",
              }
            }
          >
            <FormControl
              sx={{
                "& input": {
                  minWidth: 350,
                },
              }}
            >
              <TextField
                // error={Boolean(touched.first && errors.first)}
                fullWidth
                // helperText={touched.first && errors.first}
                label="Email"
                margin="normal"
                name="email"
                // onBlur={() => {
                //   console.log("validate email");
                // }}
                onFocus={() => setError("")}
                onChange={(event: any) => {
                  setNewEmail(event.target.value as string);
                }}
                type="email"
                value={newEmail}
                variant="outlined"
                required
              />
            </FormControl>
            <FormControlLabel
              sx={{
                mx: "auto",
              }}
              control={
                <Checkbox
                  checked={acknowledge}
                  onChange={(event: any) => {
                    setError("");
                    setAcknowledge(event.target.checked);
                  }}
                />
              }
              label="I acknowledge this new email address will have full access to this account"
            />
            {error && <Typography color="error">{error}</Typography>}
            {processing && (
              <LoadingSpinner hideMessage={true} margin="0" inCard={false} />
            )}
          </FormGroup>
        ) : (
          <>
            <Typography
              sx={{
                fontWeight: "bold",
              }}
            >{`Account reassigned to ${newEmail}.`}</Typography>
            {/* TODO: setup the email send */}
            {/* should link to the sign-in screen with reset password selected */}
            {/*<Typography>*/}
            {/*  An email will be sent to this address informing them how to access*/}
            {/*  the account.*/}
            {/*</Typography>*/}
          </>
        )
      }
      onConfirm={() => {
        !reassignComplete
          ? (async () => {
              setProcessing(true);
              if (await handleReassign()) {
                setReassignComplete(true);
              }
              setProcessing(false);
            })()
          : onClose(true);
      }}
      confirmText={!reassignComplete ? "Reassign Account" : "Close"}
      onDeny={
        !reassignComplete
          ? () => {
              onClose(false);
            }
          : undefined
      }
      denyText={!reassignComplete ? "Cancel" : ""}
    />
  );
};
