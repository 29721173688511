import React, { useEffect, useState } from "react";
import {
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import { makeStyles } from "tss-react/mui";
import { Opportunity } from "../../models/Opportunity";
import { AppTableWrapper } from "../shared/AppTableWrapper";
import PerfectScrollbar from "react-perfect-scrollbar";
import { OpportunityForm } from "./OpportunityForm";
import EditIcon from "@mui/icons-material/Edit";
import moment from "moment/moment";
import { TableBodyLoader } from "../shared/TableBodyLoader";
import { Estate } from "../../models/Estate";
import { Link } from "react-router-dom";
import { User } from "../../models/User";
import { SCREEN_PATHS } from "../../constants";
// import { FinancialConvoActionButton } from "../../views/estate/EstateView/financialConvo/FinancialConvoActionButton";

const useStyles = makeStyles()(() => ({
  root: {
    // overflowX: "scroll",
  },
  tableHead: {
    lineHeight: "1rem",
  },
  tableRow: {
    height: 10,
  },
  tableCell: {
    height: "auto !important",
    lineHeight: "1rem",
  },
}));

interface Props {
  opportunity?: Opportunity;
  user?: User;
  estate?: Estate;
  noPad?: boolean;
  minWidth?: number;
  disableEdit?: boolean;
}

export const SingleOpportunityTable = ({
  opportunity,
  user,
  estate,
  minWidth,
  noPad = false,
  disableEdit = false,
}: Props): JSX.Element => {
  const { classes } = useStyles();

  const [showNewForm, setShowNewForm] = useState<boolean>(false);
  const [editId, setEditId] = useState<number>();
  const [hasEditAccess, setHasEditAccess] = useState<boolean>(false);

  useEffect(() => {
    if (user && opportunity) {
      setHasEditAccess(
        user?.isUberAdmin ||
          (user?.isAdvisorAdmin &&
            user?.advisorGroupId === opportunity?.advisorGroupId) ||
          user?.id === opportunity?.advisorId
      );
    }
  }, []);

  return (
    <>
      <div className={classes.root}>
        <PerfectScrollbar
          options={{ suppressScrollY: true, useBothWheelAxes: false }}
        >
          <AppTableWrapper minWidth={minWidth} noPad={noPad}>
            <Table>
              <TableHead className={classes.tableCell}>
                <TableRow className={classes.tableRow}>
                  <TableCell className={classes.tableCell}>#</TableCell>
                  <TableCell className={classes.tableCell}>Date</TableCell>
                  <TableCell className={classes.tableCell}>Type</TableCell>
                  <TableCell className={classes.tableCell}>Details</TableCell>
                  <TableCell className={classes.tableCell}>
                    Estate Name
                  </TableCell>
                  <TableCell className={classes.tableCell}>Status</TableCell>
                  {!disableEdit && (
                    <TableCell align="right" className={classes.tableCell} />
                  )}
                </TableRow>
              </TableHead>
              {!opportunity ? (
                <TableBodyLoader colSpan={6} rows={1} />
              ) : (
                <TableBody>
                  {showNewForm && estate?.id && estate?.advisorGroupId && (
                    <OpportunityForm
                      estateId={estate.id}
                      estateName={estate.name}
                      advisorGroupId={estate.advisorGroupId}
                      opportunity={new Opportunity()}
                      onComplete={() => {
                        setShowNewForm(false);
                      }}
                      key="new-opp-form"
                    />
                  )}
                  {editId === opportunity?.id &&
                  opportunity?.estateId &&
                  opportunity?.advisorGroupId ? (
                    <OpportunityForm
                      estateId={opportunity?.estateId}
                      estateName={opportunity?.estateName}
                      advisorGroupId={opportunity?.advisorGroupId}
                      opportunity={opportunity}
                      onComplete={() => {
                        setEditId(undefined);
                      }}
                      key={opportunity?.id + "-form"}
                    />
                  ) : (
                    <TableRow
                      hover
                      key={opportunity?.title + "-" + opportunity?.id + "-row"}
                    >
                      <TableCell>{opportunity?.id}</TableCell>
                      <TableCell>
                        {moment(opportunity?.updatedAt).format("MM/DD/YYYY")}
                      </TableCell>
                      <TableCell>{opportunity?.type?.title}</TableCell>
                      <TableCell>
                        {opportunity?.title || opportunity?.originalTitle}
                      </TableCell>
                      <TableCell>
                        <Link
                          to={`${SCREEN_PATHS.estates}/${opportunity?.estateId}`}
                        >
                          {opportunity?.estateName
                            ? `${opportunity?.estateName} estate`
                            : `Estate #${opportunity?.estateId}`}
                        </Link>
                      </TableCell>
                      <TableCell>{opportunity?.status?.title}</TableCell>
                      {!disableEdit && (
                        <TableCell align="right">
                          {hasEditAccess && (
                            <>
                              <IconButton
                                aria-label="edit"
                                color="primary"
                                onClick={() => {
                                  setEditId(opportunity?.id || undefined);
                                  setShowNewForm(false);
                                }}
                              >
                                <EditIcon />
                              </IconButton>
                            </>
                          )}
                        </TableCell>
                      )}
                    </TableRow>
                  )}
                </TableBody>
              )}
            </Table>
          </AppTableWrapper>
        </PerfectScrollbar>
      </div>
    </>
  );
};
