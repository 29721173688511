import React, { useState } from "react";
import { Box, Button, Modal, TextField, Typography } from "@mui/material";
import { makeStyles } from "tss-react/mui";
import { InviteSentBody } from "../InviteSentBody";
import { useNavigate } from "react-router-dom";
const Yup = require("yup")
import { Formik, FormikHelpers } from "formik";
import { Role, User } from "../../../models/User";
import { inviteApi, logger } from "../../../apis";
import { SendEmail } from "../../../apis/SendEmail";
import { Invite } from "../../../models/Invite";
import { InviteTypes } from "../../../constants";

const useStyles = makeStyles()(() => ({
  root: {},
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  inputs: {
    margin: "15px 0",
  },
}));

interface Values {
  first: string;
  last: string;
  email: string;
}

interface Props {
  inviteId?: string;
  user?: User;
  createAdvisorInvite?: () => Promise<string | null>;
}

export const InviteClientForm = ({
  inviteId,
  user,
}: // createAdvisorInvite,
Props): JSX.Element => {
  const { classes } = useStyles();
  const navigate = useNavigate();
  const [confirmIsOpen, setConfirmIsOpen] = useState(false);

  const handleClose = () => {
    setConfirmIsOpen(false);
  };

  const goToDashboard = () => {
    navigate(`/app/dashboard`);
  };

  // https://formik.org/docs/api/formik
  const formikInit = {
    values: {
      first: "",
      last: "",
      email: "",
    },
    schema: Yup.object().shape({
      first: Yup.string().max(255),
      last: Yup.string().max(255),
      email: Yup.string()
        .email("Must be a valid email")
        .max(255)
        .required("Email is required"),
    }),
  };

  const handleSubmit = async (
    values: Values,
    { setSubmitting, setStatus }: FormikHelpers<Values>
  ) => {
    try {
      setStatus("");
      const { first, last, email } = values;

      if (!email) {
        setStatus("email is required");
        return;
      }

      if (!inviteId) {
        const invite = new Invite(
          InviteTypes.EndUser,
          undefined,
          Role["Primary_End-User"],
          user?.id
        );
        // TODO: enable Uber to select advisor group
        // only send advisor group if uber
        // invite.advisorGroup = user.advisorGroupName;
        const createdId = await inviteApi.createInvite(invite);
        // setInviteId(inviteId || "");
        // const createdId = await createAdvisorInvite();
        inviteId = createdId || undefined;
      }

      const sendEmail = new SendEmail();
      if (
        inviteId &&
        (await sendEmail.sendInvite({
          email: email,
          inviteId,
          toFirstName: first,
          toLastName: last,
          fromFirstName: user?.first,
          fromLastName: user?.last,
          // closing: "" // customizable, but default is "Best"
        }))
      ) {
        setConfirmIsOpen(true);
      } else {
        throw Error(
          "Sorry, we had a problem sending the invitation. Please try again or you can try sending the invitation url directly."
        );
      }
    } catch (error) {
      logger.error("invite client error: " + (error as Error)?.message);
      setStatus((error as Error)?.message);
    }
    setSubmitting(false);
  };

  return (
    <Box>
      <Formik
        initialValues={formikInit.values}
        validationSchema={formikInit.schema}
        onSubmit={handleSubmit}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          touched,
          values,
          status,
          setFieldValue,
        }) => (
          <form onSubmit={handleSubmit}>
            <Modal
              className={classes.modal}
              open={confirmIsOpen}
              onClose={handleClose}
              aria-labelledby="invite-sent-title"
              aria-describedby="invite-sent-body"
            >
              <>
                <InviteSentBody
                  title="Your invite was sent"
                  body=""
                  // body="We'll let you know when Cindy has accepted your invite and created an account."
                  onClose={() => {
                    setFieldValue("first", "");
                    setFieldValue("last", "");
                    setFieldValue("email", "");
                    handleClose();
                  }}
                  onDashboard={goToDashboard}
                />
              </>
            </Modal>
            <TextField
              error={Boolean(touched.first && errors.first)}
              fullWidth
              helperText={touched.first && errors.first}
              label="First Name"
              margin="normal"
              name="first"
              onBlur={handleBlur}
              onChange={handleChange}
              type="text"
              value={values.first}
              variant="outlined"
            />
            <TextField
              error={Boolean(touched.last && errors.last)}
              fullWidth
              helperText={touched.last && errors.last}
              label="Last name"
              margin="normal"
              name="last"
              onBlur={handleBlur}
              onChange={handleChange}
              type="last"
              value={values.last}
              variant="outlined"
            />
            <TextField
              error={Boolean(touched.email && errors.email)}
              fullWidth
              helperText={touched.email && errors.email}
              label="Email"
              margin="normal"
              name="email"
              onBlur={handleBlur}
              onChange={handleChange}
              type="email"
              value={values.email}
              variant="outlined"
            />
            {isSubmitting && (
              <Typography variant="h4">submitting...</Typography>
            )}
            <Typography
              variant="body2"
              color={
                status?.toString()?.includes("success") ? "primary" : "error"
              }
              style={{ margin: "10px 0" }}
            >
              {status}
            </Typography>
            <Button
              color="primary"
              disabled={isSubmitting}
              size="small"
              type="submit"
              variant="contained"
            >
              Email an invite
            </Button>
          </form>
        )}
      </Formik>
    </Box>
  );
};
