import React, { useState } from "react";
import { callPost } from "../../../apis";
import { TestButton } from "./TestButton";
import { Switch, TextField, Typography } from "@mui/material";

export const OpportunitiesTest = (): JSX.Element => {
  const [advisorGroupId, setAdvisorGroupId] = useState<number | undefined>(
    undefined
  );
  const [debugLogEnabled, setDebugLogEnabled] = React.useState(false);
  return (
    <>
      <div style={{ display: "flex", alignItems: "center" }}>
        <Switch
          checked={debugLogEnabled}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            setDebugLogEnabled(event.target.checked);
          }}
          color="primary"
          name="log-switch"
          id="log-switch"
        />
        <label htmlFor="log-switch" style={{ cursor: "pointer" }}>
          <Typography variant="h5" component="div">
            Enable verbose logging
          </Typography>
        </label>
      </div>
      <br />
      Leave this field blank to run opportunity algorithms for all advisor
      groups
      <br />
      <TextField
        onChange={(event) => {
          const groupId = event?.target?.value;
          setAdvisorGroupId(groupId ? +groupId : undefined);
        }}
        name="advisorGroupId"
        label="Advisor Group Id"
        variant="outlined"
        fullWidth
        value={advisorGroupId}
      />
      <br />
      <TestButton
        onClick={() => {
          (async () => {
            try {
              const response = await callPost(
                `opportunities/check-and-create`,
                {
                  advisor_group_id: advisorGroupId,
                  debug_log_enabled: debugLogEnabled,
                },
                true
              );
              console.log(response);
            } catch (error) {
              console.error(error);
            }
          })();
        }}
      >
        Test opportunities/check-and-create
      </TestButton>
    </>
  );
};
