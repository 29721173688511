import React, { useEffect, useState } from "react";
import { Avatar, Theme } from "@mui/material";
import { makeStyles } from "tss-react/mui";

import { getInitials } from "../../utils/getInitials";
import { Estate } from "../../models/Estate";
import { axiosInstance, CancelToken, logger } from "../../apis";
import { isProd } from "../../constants"
import axios, { AxiosError, CancelTokenSource } from "axios";
import { StoreState } from "../../reducers";
import { connect } from "react-redux";
import { cacheEstateAvatar } from "../../actions/estateActions";
import { EstateAvatar } from "../../models/EstateAvatar";

const useStyles = makeStyles()((theme: Theme) => ({
  small: {
    marginRight: theme.spacing(2),
  },
  large: {
    width: 100,
    height: 100,
    margin: "10px 0 0",
  },
}));

interface Props {
  estate?: Estate;
  size?: string;
  estateAvatarCache?: EstateAvatar[];
  cacheEstateAvatar: (estateAvatar: EstateAvatar) => void;
}

const _AsyncAvatar = ({
  estate,
  size = "small",
  estateAvatarCache,
  cacheEstateAvatar,
}: Props): JSX.Element => {
  const { classes } = useStyles();
  const [avatarSrc, setAvatarSrc] = useState("");

  useEffect(() => {
    // have avatar cached? use it, otherwise go get it, then place in cache
    const filteredAvatars = estateAvatarCache?.filter(
      (avatar: EstateAvatar) => avatar.estateId === estate?.id
    );
    if (filteredAvatars?.length) {
      setAvatarSrc(filteredAvatars[0].url);
    } else {
      const cancelTokenSource = CancelToken.source();
      getAvatarImageUrl(cancelTokenSource);
      return () => {
        // cleans up an incomplete network calls
        cancelTokenSource.cancel();
      };
    }
  }, []);

  const getAvatarImageUrl = async (
    cancelTokenSource: CancelTokenSource,
    dimensions = "100x100"
  ): Promise<void> => {
    if (estate?.id && estate?.avatarUrl) {
      try {
        const response = await axiosInstance.get(
          `${estate.avatarUrl}/${dimensions}`,
          {
            responseType: "blob",
            cancelToken: cancelTokenSource.token,
          }
        );
        if (response?.data) {
          // TODO: what if images aren't jpeg?
          const file = new Blob([response.data], { type: "image/jpeg" });
          const urlCreator = window.URL || window.webkitURL;
          const avatarUrl = urlCreator.createObjectURL(file);
          cacheEstateAvatar(new EstateAvatar(estate?.id, avatarUrl));
          setAvatarSrc(avatarUrl);
        } else {
          throw Error("Sorry, invalid response.");
        }
      } catch (error) {
        // don't log cancel errors or non-prod 404s
        const isNonProd404 =
          (error as any)?.response?.status === 404 && !isProd;
        if (!(axios.isCancel(error) || isNonProd404)) {
          logger.error(error as Error);
        }
      }
    }
  };

  return (
    <Avatar
      className={size === "large" ? classes.large : classes.small}
      src={avatarSrc || ""}
    >
      {getInitials(estate?.name)}
    </Avatar>
  );
};

const mapStateToProps = ({
  estateAvatarCache,
}: StoreState): { estateAvatarCache: EstateAvatar[] } => {
  return { estateAvatarCache };
};

export const AsyncAvatar = connect(mapStateToProps, {
  cacheEstateAvatar,
})(_AsyncAvatar);
