import { OpportunityStatus } from "../models/OpportunityStatus";
import { OpportunityType } from "../models/OpportunityType";
import { Opportunity } from "../models/Opportunity";
import { callDelete, callGet, callPost, callPut, logger } from "./index";

export class Opportunities {
  fetchOpportunitiesQueryString(
    typeIds: number[],
    statusIds: number[],
    estateIds: number[],
    limit: number,
    offset: number,
    sort: string,
    dir: "DESC"|"ASC"): string {
      const queryParts:string[] = [] 
      if(limit) {
        queryParts.push("limit=" + limit)
      }
      if(offset) {
        queryParts.push("offset=" + offset)
      }
      if(sort) {
        queryParts.push("sort=" + sort)
      }
      if(dir) {
        queryParts.push("dir=" + dir)
      }
      if(estateIds && estateIds.length > 0 && !(estateIds.length === 1 && estateIds[0] == 0)) {
        queryParts.push("estate_ids=" + estateIds.join(","))
      }
      if(typeIds && typeIds.length > 0 && !(typeIds.length === 1 && typeIds[0] == 0)) {
        queryParts.push("types=" + typeIds.join(","))
      }
      if(statusIds && statusIds.length > 0 && !(statusIds.length === 1 && statusIds[0] == 0)) {
        queryParts.push("statuses=" + statusIds.join(","))
      }
      return (queryParts.length > 0 ? "?": "") + queryParts.join("&")
    }

  async fetchOpportunities(
    types: OpportunityType[],
    statuses: OpportunityStatus[],
    estateIds: number[],
    limit: number,
    offset: number,
    sort: string,
    dir: "DESC"|"ASC",
  ): Promise<Opportunity[] | null> {
    try {
      let path = Opportunity.path
      path += this.fetchOpportunitiesQueryString(
        types.map(t => {
          if(Number.isSafeInteger(t)) {
            return t
          }

          return t.id
        }) as number[]
        , statuses.map(s => {
          if(Number.isSafeInteger(s)) {
            return s
          }

          return s.id
        }) as number[]
        , estateIds, limit, offset, sort, dir)
      console.warn(path)
      const response = await callGet(path);
      let responseData = response?.data
      return responseData?.map((opp: any) =>
            Opportunity.fromJSON(opp, types, statuses)
          ) || []
    } catch (error) {
      logger.error(error as Error);
    }
    return null;
  }

  async fetchOpportunitiesWithCount(
    types: OpportunityType[],
    statuses: OpportunityStatus[],
    estateIds: number[],
    limit: number,
    offset: number,
    sort: string,
    dir: "DESC"|"ASC",
  ): Promise<{opportunities:Opportunity[] | null, count: number | null} | null> {
    try {
      let path = Opportunity.withCountPath
      path += this.fetchOpportunitiesQueryString(
        types.map(t => {
          if(Number.isSafeInteger(t)) {
            return t
          }

          return t.id
        }) as number[]
        , statuses.map(s => {
          if(Number.isSafeInteger(s)) {
            return s
          }

          return s.id
        }) as number[]
        ,estateIds, limit, offset, sort, dir)
      
      const response = await callGet(path);
      return {
        opportunities: response?.data?.opportunities?.map((opp: any) => {
          return Opportunity.fromJSON(opp, types, statuses)
        }) || [],
        count: response?.data?.count,
      }
    } catch (error) {
      logger.error(error as Error);
    }
    return null;
  }

  async fetchSingleOpportunity(
    opportunity_id: number,
    types: OpportunityType[],
    statuses: OpportunityStatus[]
  ): Promise<Opportunity | null> {
    try {
      const response = await callGet(
        `${Opportunity.path}/single/${opportunity_id}`,
        true
      );
      if (response?.data) {
        return Opportunity.fromJSON(response?.data, types, statuses);
      } else {
        logger.error("no response.data", false);
      }
      return null;
    } catch (error) {
      if (
        (error as any)?.response?.status === 404 ||
        (error as any)?.response?.status === 401
      ) {
        // requesting missing or invalid resource
        window.document.location.href = `/app/estates`;
      }
    }
    return null;
  }

  async saveOpportunity(opportunity: Opportunity): Promise<Opportunity | null> {
    try {
      if (!opportunity.id) {
        // new
        const updateFields = {
          title: opportunity.title,
          estate_id: opportunity.estateId,
          opportunity_type_id: opportunity?.type?.id,
          opportunity_status_id: opportunity?.status?.id,
          advisor_group_id: opportunity?.advisorGroupId, // will be ignored unless user isUber
        };
        const response = await callPost(Opportunity.path, updateFields);
        if (response?.data?.id) {
          opportunity.id = response.data.id;
          return opportunity;
        }
      } else {
        const updateFields = {
          title: opportunity.title,
          opportunity_type_id: opportunity?.type?.id,
          opportunity_status_id: opportunity?.status?.id,
        };
        const response = await callPut(
          `${Opportunity.path}/${opportunity.id}`,
          updateFields
        );
        if (response?.data?.id) {
          return opportunity;
        }
      }
    } catch (error) {
      logger.error(error as Error);
    }
    return null;
  }

  async deleteOpportunity(opportunity: Opportunity): Promise<boolean | null> {
    try {
      const response = await callDelete(
        `${Opportunity.path}/${opportunity.id}`
      );
      return !!response?.data?.id;
    } catch (error) {
      logger.error(error as Error);
    }
    return null;
  }

  async fetchOpportunityStatuses(): Promise<OpportunityStatus[] | null> {
    try {
      const response = await callGet(OpportunityStatus.path);
      return (
        response?.data?.map((opp: any) => OpportunityStatus.fromJSON(opp)) || []
      );
    } catch (error) {
      logger.error(error as Error);
    }
    return null;
  }

  async fetchOpportunityTypes(): Promise<OpportunityType[] | null> {
    try {
      const response = await callGet(OpportunityType.path);
      return (
        response?.data?.map((opp: any) => OpportunityType.fromJSON(opp)) || []
      );
    } catch (error) {
      logger.error(error as Error);
    }
    return null;
  }

  async saveOpportunityMeta(
    oppMeta: OpportunityType | OpportunityStatus
  ): Promise<OpportunityType | OpportunityStatus | null> {
    console.error(oppMeta);
    // try {
    //   if (oppMeta instanceof OpportunityStatus) {
    //     if (!oppMeta.id) {
    //       // new
    //       const response = await callGraphQL<CreateOpportunityStatusMutation>(
    //         createOpportunityStatus,
    //         {
    //           input: oppMeta as CreateOpportunityStatusInput,
    //         }
    //       );
    //       return (
    //         OpportunityStatus.fromGQL(
    //           response?.data?.createOpportunityStatus as GQLOpportunityStatus
    //         ) || null
    //       );
    //     } else {
    //       const response = await callGraphQL<UpdateOpportunityStatusMutation>(
    //         updateOpportunityStatus,
    //         {
    //           input: oppMeta as UpdateOpportunityStatusMutationVariables,
    //         }
    //       );
    //       return (
    //         OpportunityStatus.fromGQL(
    //           response?.data?.updateOpportunityStatus as GQLOpportunityStatus
    //         ) || null
    //       );
    //     }
    //   } else {
    //     if (!oppMeta.id) {
    //       // new
    //       const response = await callGraphQL<CreateOpportunityTypeMutation>(
    //         createOpportunityType,
    //         {
    //           input: oppMeta as CreateOpportunityTypeInput,
    //         }
    //       );
    //       return (
    //         OpportunityType.fromGQL(
    //           response?.data?.createOpportunityType as GQLOpportunityType
    //         ) || null
    //       );
    //     } else {
    //       const response = await callGraphQL<UpdateOpportunityTypeMutation>(
    //         updateOpportunityType,
    //         {
    //           input: oppMeta as UpdateOpportunityTypeMutationVariables,
    //         }
    //       );
    //       return (
    //         OpportunityType.fromGQL(
    //           response?.data?.updateOpportunityType as GQLOpportunityType
    //         ) || null
    //       );
    //     }
    //   }
    // } catch (error) {
    //   logger.error(error as Error);
    // }
    return null;
  }

  async deleteOpportunityMeta(
    oppMeta: OpportunityType | OpportunityStatus
  ): Promise<boolean | null> {
    console.error(oppMeta);
    // try {
    //   if (oppMeta instanceof OpportunityStatus) {
    //     const response = await callGraphQL<DeleteOpportunityStatusMutation>(
    //       deleteOpportunityStatus,
    //       {
    //         input: {
    //           id: oppMeta.id,
    //         },
    //       }
    //     );
    //     return !!response?.data?.deleteOpportunityStatus;
    //   } else {
    //     const response = await callGraphQL<DeleteOpportunityTypeMutation>(
    //       deleteOpportunityType,
    //       {
    //         input: {
    //           id: oppMeta.id,
    //         },
    //       }
    //     );
    //     return !!response?.data?.deleteOpportunityType;
    //   }
    // } catch (error) {
    //   logger.error(error as Error);
    // }
    return null;
  }
}
