export type FormStatusTypeJson = {
  type: string;
  name: string;
  visible_to_end_user: boolean;
  id: number;
};

export class FormStatusType {
  constructor(
    public type: string,
    public name: string,
    public visibleToEndUser: boolean,
    public id: number
  ) {}

  static fromJSON(json: FormStatusTypeJson): FormStatusType {
    return new FormStatusType(
      json.type,
      json.name,
      json.visible_to_end_user,
      json.id
    );
  }

  toJSON(): FormStatusTypeJson {
    return {
      type: this.type,
      name: this.name,
      visible_to_end_user: this.visibleToEndUser,
      id: this.id,
    };
  }
}
