import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Box, Pagination, Typography, Grid } from "@mui/material";
import { StoreState } from "../../../reducers";
import { useSnackbar } from "notistack";
import {
  fetchEstateAssets,
  fetchSingleEstate,
} from "../../../actions/estateActions";
import { Estate } from "../../../models/Estate";
import { AssetCard } from "./assets/AssetCard";
import { Asset } from "../../../models/Asset";
import { useNavigate, useParams } from "react-router-dom";
import { AssetsFilter } from "./assets/AssetsFilter";
import { ASSETS_PER_PAGE } from "../../../constants";
import { User } from "../../../models/User";
import {
  ContentState,
  ContentStateWrapper,
} from "../../shared/ContentStateWrapper";
import { SummaryCard } from "./assets/SummaryCard";
import { AssetDialog } from "./assets/assetDialog";
import { AssetCategory } from "../../../models/AssetCategory";
import { assetApi } from "../../../apis";
import { CategorySelectDialog } from "./assets/CategorySelectDialog";
import { AssetSubCategory } from "../../../models/AssetSubCategory";
import { ConfirmationDialog } from "../../shared/dialog/ConfirmationDialog";
import { DeleteContent } from "./assets/DeleteContent";

interface Props {
  user: User;
  estates?: Estate[];
  fetchEstateAssets: (estate: Estate) => void;
  fetchSingleEstate: (estateId: number) => void;
}

const _AssetsView = ({
  estates,
  fetchEstateAssets,
  fetchSingleEstate,
}: Props) => {
  // asset list: /app/estates/143/assets - estateId=143
  // asset view & edit: /app/estates/143/assets/227 - assetId=227
  // asset create: /app/estates/143/assets/new/3 - categoryId=3
  const { estateId, assetId, categoryId, subCategoryId } = useParams();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const [filterCategories, setFilterCategories] = useState<string[]>([]);
  const [filterProbate, setFilterProbate] = useState("all");
  const [page, setPage] = useState(1);
  // const [categorySelect, setCategorySelect] = useState(false);
  const [categorySelectOpen, setCategorySelectOpen] = useState(false);

  // this manages the visibility of the Create/Edit asset dialog
  const [detailAsset, setDetailAsset] = useState<Asset | undefined>();
  // this manages the visibility of the delete asset dialog
  const [deleteAsset, setDeleteAsset] = useState<Asset | undefined>();

  let estate: Estate | undefined;
  if (estateId && estates?.length) {
    estate = estates.find((estate: Estate) => estate.id === +estateId);
  }

  useEffect(() => {
    if (estate) {
      if (estate.assets === undefined) {
        fetchEstateAssets(estate);
      }
      if (estate?.assets && assetId) {
        if (assetId === "new") {
          if (categoryId) {
            setDetailAsset(
              Asset.ofCategory(
                new AssetCategory(+categoryId),
                subCategoryId ? new AssetSubCategory(+subCategoryId) : undefined
              )
            );
          } else {
            setDetailAsset(new Asset());
          }
        } else {
          const focusedAsset = estate?.assets.find(
            (asset: Asset) => asset?.id === +assetId
          );
          focusedAsset && setDetailAsset(focusedAsset);
        }
      }
    }
  }, [estate?.assets, assetId, categoryId]);

  const handleClick = (asset: Asset) => {
    if (asset?.id) {
      // update url with focused asset
      navigate(`/app/estates/${estateId}/assets/${asset?.id}`);
    } else if (asset?.categoryId) {
      if (asset?.subcategoryId) {
        navigate(
          `/app/estates/${estateId}/assets/new/${asset?.categoryId}/${asset?.subcategoryId}`
        );
      } else {
        navigate(`/app/estates/${estateId}/assets/new/${asset?.categoryId}`);
      }
    } else {
      setCategorySelectOpen(true);
    }
  };

  const handleDetailClose = () => {
    setDetailAsset(undefined);
    navigate(`/app/estates/${estateId}/assets`);
  };

  const categoryKeys: string[] = [];
  if (estate?.assets) {
    estate.assets.forEach((asset: Asset) => {
      if (
        asset.category &&
        asset?.category?.category &&
        !categoryKeys.includes(asset.category.category)
      ) {
        categoryKeys.push(asset.category.category);
      }
    });
  }

  const handleDelete = async (assetId?: number) => {
    if (await assetApi.deleteAsset(estate?.id, assetId)) {
      estate?.id && fetchSingleEstate(estate.id);
    }
    setPage(1);
    setDeleteAsset(undefined);
    if (assetId) {
      // when called from the view asset detail dialog
      handleDetailClose();
    }
    enqueueSnackbar(
      <Typography>
        <strong>Asset deleted</strong>
      </Typography>,
      { variant: "success" }
    );
  };

  const handleFilter = (prob: string, cats: string[]) => {
    setPage(1);
    setFilterProbate(prob);
    setFilterCategories(cats);
  };

  let filteredAssets: Asset[] = estate?.assets || [];
  // apply category filter
  if (filterCategories?.length > 0) {
    filteredAssets = filteredAssets?.filter(
      (asset: Asset) =>
        asset.category?.category &&
        filterCategories.includes(asset.category.category)
    );
  }

  // apply probate filter
  if (filterProbate !== "all") {
    filteredAssets = filteredAssets?.filter((asset: Asset) => {
      let filterValue;
      if (filterProbate === "null") {
        filterValue = null;
      } else if (filterProbate === "Probate") {
        filterValue = true;
      } else if (filterProbate === "Not Probate") {
        filterValue = false;
      }
      return asset.is_probate === filterValue;
    });
  }

  // add new empty asset if advisor has write access
  const filteredWithAddAsset = estate?.hasWriteAccess
    ? [new Asset(), ...filteredAssets]
    : filteredAssets;

  const numPages = filteredWithAddAsset?.length
    ? Math.ceil(filteredWithAddAsset?.length / ASSETS_PER_PAGE)
    : 1;

  const firstElement = (page - 1) * ASSETS_PER_PAGE;

  const paginatedAssets = filteredWithAddAsset?.slice(
    firstElement,
    firstElement + ASSETS_PER_PAGE
  );

  return (
    <div data-automation-id="page-content-estate-assets">
      <ContentStateWrapper
        contentType="Assets"
        contentTypeSingular="Asset"
        zeroText="It looks like this estate hasn't added any assets yet. Consider reaching out and giving them a gentle nudge."
        zeroImagePrefix="/static/images/zero/estates"
        zeroShowAddButton={estate && estate?.hasWriteAccess}
        nonContentInCard={true}
        contentState={((): ContentState => {
          if (!estate?.assets) {
            return ContentState.loading;
          } else if (estate?.assets?.length === 0) {
            return ContentState.zero;
          } else {
            return ContentState.render;
          }
        })()}
      >
        <>
          {/* delete asset confirmation */}
          {deleteAsset && (
            <ConfirmationDialog
              open={!!deleteAsset}
              description={<DeleteContent asset={deleteAsset} />}
              onConfirm={() => handleDelete(deleteAsset?.id)}
              confirmText="Delete Asset"
              maxWidth="md"
              confirmWide={true}
              onDeny={() => {
                setDeleteAsset(undefined);
              }}
              denyText="Cancel without deleting"
              denyWide={true}
              denyButtonVariant="outlined"
              buttonOrientation="row-reverse"
            />
          )}
          {categorySelectOpen && (
            <CategorySelectDialog
              open={categorySelectOpen}
              onConfirm={(catId?: number, subCatId?: number) => {
                // create a new empty assets with these details and setDetailAsset
                setCategorySelectOpen(false);
                const newAssetTemplate = Asset.ofCategory(
                  new AssetCategory(catId),
                  subCatId ? new AssetSubCategory(subCatId) : undefined
                );
                handleClick(newAssetTemplate);
              }}
              onDeny={() => {
                setCategorySelectOpen(false);
              }}
            />
          )}
          {!!detailAsset && (
            <AssetDialog
              open={!!detailAsset}
              asset={detailAsset}
              estate={estate}
              onComplete={(refresh?: boolean) => {
                refresh && estate?.id && fetchSingleEstate(estate.id);
                handleDetailClose();
              }}
              onCancel={handleDetailClose}
              onDelete={() => setDeleteAsset(detailAsset)}
            />
          )}
          <SummaryCard estate={estate} />
          {filteredAssets && (
            <AssetsFilter
              onFilter={handleFilter}
              categoryKeys={categoryKeys}
              categories={filterCategories}
              probate={filterProbate}
            />
          )}
          <Grid
            container
            spacing={3}
            sx={{
              mt: 3,
            }}
          >
            {paginatedAssets.map((asset: Asset) => (
              <Grid item key={`asset_${asset.id}`} xs={12} sm={6} lg={4} xl={3}>
                <AssetCard
                  asset={asset}
                  key={`asset_${asset.id}`}
                  onClick={() => handleClick(asset)}
                  onDelete={() => setDeleteAsset(asset)}
                  hasWriteAccess={estate?.hasWriteAccess}
                />
              </Grid>
            ))}
          </Grid>
          {numPages > 1 && (
            <Box mt={3} display="flex" justifyContent="center">
              <Pagination
                color="primary"
                count={numPages}
                size="large"
                page={page}
                onChange={(evt, page) => setPage(page)}
              />
            </Box>
          )}
        </>
      </ContentStateWrapper>
    </div>
  );
};

const mapStateToProps = ({
  user,
  estates,
}: StoreState): { user: User; estates: Estate[] } => {
  return { user, estates };
};

export const AssetsView = connect(mapStateToProps, {
  fetchEstateAssets,
  fetchSingleEstate,
})(_AssetsView);
