import { OpportunityStatus } from "./OpportunityStatus";
import { OpportunityType } from "./OpportunityType";

export type OpportunityJSON = {
  id?: number;
  title?: string;
  original_title?: string | null;
  advisor_id?: string | null;
  advisor_name?: string | null;
  estate_id?: number;
  estate_name?: string | null;
  advisor_group_id?: number;
  opportunity_type_id?: number;
  opportunity_status_id?: number;
  updated_at?: Date;
  created_at?: Date;
};

export class Opportunity {
  static path = "/opportunities";
  static withCountPath = "/opportunities-with-count";
  constructor(
    public id?: number,
    public originalTitle?: string | null,
    public title?: string | null,
    public advisorId?: string | null,
    public advisorName?: string | null,
    public estateId?: number,
    public estateName?: string | null,
    public advisorGroupId?: number,
    public type?: OpportunityType,
    public status?: OpportunityStatus,
    public updatedAt?: Date,
    public createdAt?: Date
  ) {}

  static fromJSON(
    opp: OpportunityJSON,
    types: OpportunityType[],
    statuses: OpportunityStatus[]
  ): Opportunity {
    if (opp && opp.id) {
      return new Opportunity(
        opp.id,
        opp.original_title,
        opp.title,
        opp.advisor_id,
        opp.advisor_name,
        opp.estate_id,
        opp.estate_name,
        opp.advisor_group_id,
        opp.opportunity_type_id
          ? OpportunityType.fromId(types, opp.opportunity_type_id)
          : undefined,
        opp.opportunity_status_id
          ? OpportunityStatus.fromId(statuses, opp.opportunity_status_id)
          : undefined,
        opp.updated_at ? new Date(opp.updated_at) : undefined,
        opp.created_at ? new Date(opp.created_at) : undefined
      );
    } else {
      return new Opportunity();
    }
  }
}
