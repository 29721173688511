/**
 * WARNING: don't use these colors directly in the app. Use the theme.palette
 * instead so that the theme switcher will work properly
 * CORRECTION: design is making ☝️ this impossible, so have at it!
 *
 * These colors should only be referenced when initializing the themes: /theme/index.js
 */
const atticusColors = {
  white: "#FFFFFF",
  green1: "#27B8AB",
  green2: "#01D9C6",
  greenAlpha1: "rgba(1, 217, 198, 0.1)",
  purple0: "#B5BFDA",
  purple1: "#643BAE",
  purple2: "#6363FF",
  purple3: "#4E57AA",
  purple35: "#656FC4",
  purple4: "#2D2A5C",
  purple5: "#D8D8FF",
  purple6: "#9292FF",
  purple7: "#F5F6FA",
  silver1: "#CDCDDB",
  silver2: "#EDEDF4",
  blue1: "#2C2CFE",
  black: "#000000",
  grey1: "#393A3D",
  grey2: "#595A5F",
  grey3: "#77797E",
  grey4: "#96979C",
  grey5: "#B5B7BA",
  grey6: "#D5D6D7",
  grey7: "#F4F5F5",
  grey8: "#F9FAFA", // not in design specs
  red1: "#FF0000", // not in design specs
  red2: "#d32f2f", // not in design specs
  magenta: "#a52651",
  pink: "#BD698D",
  turquoise: "#196e83",
  lightYellow: "#FECE80",
};

export default atticusColors;
