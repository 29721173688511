import { EstateNote, EstateNoteJSON } from "../models/EstateNote";
import { callDelete, callGet, callPost, callPut, logger } from "./index";

export class EstateNotes {
  async fetchEstateNotes(estateId: number): Promise<EstateNote[] | undefined> {
    try {
      const response = await callGet(`${EstateNote.path}/estate/${estateId}`);
      return (
        response?.data?.map((note: EstateNoteJSON) =>
          EstateNote.fromJSON(note)
        ) || ([] as EstateNote[])
      );
    } catch (error) {
      if ((error as any)?.response?.status === 401) {
        // user not authorized
        return [];
      } else {
        logger.error(error as Error);
      }
    }
  }
  async saveEstateNote(estateNote: EstateNote): Promise<EstateNote> {
    try {
      if (!estateNote.id) {
        // new estateNote
        const response = await callPost(EstateNote.path, estateNote.toJSON());
        if (response?.data?.note) {
          return EstateNote.fromJSON(response?.data?.note);
        } else {
          throw Error("estate note creation failed");
        }
      } else {
        const response = await callPut(
          `${EstateNote.path}/${estateNote.id}`,
          estateNote.toJSON()
        );
        if (response?.data?.id) {
          return estateNote;
        } else {
          throw Error("estate note update failed");
        }
      }
    } catch (error) {
      logger.error(error as Error);
      throw error;
    }
  }
  async deleteEstateNote(estateNote: EstateNote): Promise<boolean> {
    try {
      // new estateNote
      const response = await callDelete(`${EstateNote.path}/${estateNote.id}`);
      if (response?.data?.id) {
        return true;
      } else {
        throw Error("estate note delete failed");
      }
    } catch (error) {
      logger.error(error as Error);
      throw error;
    }
  }
}
