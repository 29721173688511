export const getQueryStringParams = (): any => {
  return decodeURI(window.location.search)
    .replace("?", "")
    .split("&")
    .map((param) => param.split("="))
    .reduce((values: any, [key, value]) => {
      values[key] = value;
      return values;
    }, {});
};
