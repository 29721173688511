import { AtticusForm, FormJson } from "../models/AtticusForm";
import { Estate } from "../models/Estate";
import { EstateFormMap } from "../models/EstateFormMap";
import { callDelete, callGet, callPost } from "./index";

export class EstateForms {
  async getEstateForms(
    estateId: number,
    rethrowError = false
  ): Promise<AtticusForm[]> {
    const fullPath = `${Estate.path}/${estateId}/forms`;
    const response = await callGet(fullPath, rethrowError);
    const forms: AtticusForm[] = [];
    response?.data?.forEach((formJson: FormJson) => {
      forms.push(AtticusForm.fromJSON(formJson));
    });

    return forms;
  }

  async addFormToEstate(
    estateId: number,
    form: AtticusForm,
    rethrowError = false
  ): Promise<AtticusForm> {
    const fullPath = `${Estate.path}/${estateId}/forms/${form.id}`;
    const response = await callPost(fullPath, undefined, rethrowError);
    if (response?.data) {
      const estateFormMap = EstateFormMap.fromJSON(response?.data);
      const copiedForm = AtticusForm.from(form);
      copiedForm.estateFormMap = estateFormMap;
      return copiedForm;
    } else {
      throw Error("Estate Form addition failed");
    }
  }

  async removeFormFromEstate(
    estateId: number,
    form: AtticusForm,
    rethrowError = false
  ): Promise<boolean> {
    const fullPath = `${Estate.path}/${estateId}/forms/${form.id}`;
    const response = await callDelete(fullPath, rethrowError);
    if (response?.data) {
      return true;
    } else {
      throw Error("Estate Form removal failed");
    }
  }
}
