import React, { useState, useEffect } from "react";
import { Box, Container, Theme } from "@mui/material";
import { makeStyles } from "tss-react/mui";
import Page from "../../../components/Page";
import ContentHeader from "../../shared/ContentHeader";
import { ATTICUS_PAGE_PREFIX } from "../../../constants";
import { NoStateForms } from "../../shared/forms/library/NoStateForms";

// Redux Imports
import { connect } from "react-redux";
import { StoreState } from "../../../reducers";
import { FormsLibrarySearch } from "../../shared/forms/library/FormsLibrarySearch";
import { FormsLibraryList } from "../../shared/forms/library/FormsLibraryList";
import { fetchFormTypes } from "../../../actions/formTypeActions";
import { fetchLanguages } from "../../../actions/languageActions";
import { fetchStates } from "../../../actions/geographyActions";

// Model Imports
import { State } from "../../../models/Geography";
import { AtticusForm } from "../../../models/AtticusForm";
import { FormType } from "../../../models/FormType";
import { Language } from "../../../models/Language";
import { User } from "../../../models/User";

// API Imports
import { QueryPayload } from "../../../apis/AtticusForms";
import { formsApi } from "../../../apis";
import { GeographyType } from "../../../models/Geography";

const useStyles = makeStyles()((theme: Theme) => ({
  root: {
    backgroundColor: theme.palette.background.default,
    minHeight: "100%",
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
}));

interface Props {
  formTypes?: FormType[];
  fetchFormTypes: () => void;
  languages?: Language[];
  fetchLanguages: () => void;
  states?: State[];
  fetchStates: () => void;
  user: User;
}

const _FormsLibraryView = ({
  formTypes,
  fetchFormTypes,
  languages,
  fetchLanguages,
  states,
  fetchStates,
  user,
}: Props): JSX.Element => {
  const { classes } = useStyles();

  useEffect(() => {
    if (!formTypes || !formTypes?.length) {
      fetchFormTypes();
    }
    if (!languages || !languages?.length) {
      fetchLanguages();
    }
    if (!states || !states?.length) {
      fetchStates();
    }
  }, []);

  const [showFallback, setShowFallback] = useState<boolean>(false);
  const [fallbackState, setFallbackState] = useState<State | undefined>();

  const [forms, setForms] = useState<AtticusForm[] | undefined>(undefined);
  const [loadingForms, setLoadingForms] = useState(false);

  // If you do not do the below and only add to `restrictScreenAcceess`, your screen will temporarily
  // flash with the component before redirecting. Perhaps we should write High Order Routing/Auth
  // components to wrap our View components in, sticking all of it in the router's component slot
  // for the path, to standardize and clean this up. Left a note in routes.tsx about this.
  const hasAccess = user?.advisorGroup?.hasAdvisorAccessToForms;

  const getForms = async (queryPayload: QueryPayload) => {
    setLoadingForms(true);
    // setFormQuery(queryPayload);
    const newForms = await formsApi.fetchForms(queryPayload);
    if (newForms) {
      setForms(newForms);
    }

    const probateFormType = formTypes?.find(
      (ft: FormType) => ft.slug == "probate"
    );

    if (
      queryPayload.geography_id &&
      queryPayload.geography_type == GeographyType.State &&
      queryPayload.form_type_id == probateFormType?.id &&
      !queryPayload.search_terms &&
      (!newForms || !newForms.length)
    ) {
      const queriedState = states?.find(
        (state: State) => state.id == queryPayload.geography_id
      );
      setShowFallback(true);
      setFallbackState(queriedState);
      console;
    } else {
      setShowFallback(false);
      setFallbackState(undefined);
    }
    setLoadingForms(false);
  };

  return hasAccess ? (
    <Page
      className={classes.root}
      title={`${ATTICUS_PAGE_PREFIX} - Forms Library`}
      data-automation-id="page-content-forms-library"
    >
      <Container maxWidth="xl" sx={{ ml: 0 }}>
        <ContentHeader
          heading="Atticus Forms Library"
          subHeading={
            "Looking for other forms? Search our File Library to find or use more than 3,500 forms. Start by searching for a form, choosing a location, or both:"
          }
          showSearch={false}
        />
        <Box mt={3}>
          <FormsLibrarySearch
            onComplete={getForms}
            loadingForms={loadingForms}
            initLanguageCode="en"
          />
        </Box>
        <Box mt={3}>
          {showFallback && fallbackState ? (
            <NoStateForms state={fallbackState} />
          ) : (
            <FormsLibraryList forms={forms} loadingForms={loadingForms} />
          )}
        </Box>
      </Container>
    </Page>
  ) : (
    <></>
  );
};

const mapStateToProps = ({
  formTypes,
  languages,
  states,
  user,
}: StoreState): {
  formTypes: FormType[];
  languages: Language[];
  states: State[];
  user: User;
} => {
  return { formTypes, languages, states, user };
};

export const FormsLibraryView = connect(mapStateToProps, {
  fetchFormTypes,
  fetchStates,
  fetchLanguages,
})(_FormsLibraryView);
