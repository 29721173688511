import { ActionTypes } from "../actions/types";
import { User } from "../models/User";
import { AnyAction } from "redux";

export const userReducer = (
  user: User | null = null,
  action: AnyAction
): User | null => {
  switch (action.type) {
    case ActionTypes.SetUser:
      return action.payload;
    case ActionTypes.AppSignOut:
      // NOTE: this prevents screen re-render & navigation in App.tsx authRequired logic
      return new User(undefined, "temp");
    default:
      return user;
  }
};
