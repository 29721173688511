export enum PDFLibFieldTypes {
  BUTTON = "PDFButton",
  CHECKBOX = "PDFCheckBox",
  DROPDOWN = "PDFDropdown",
  OPTIONLIST = "PDFOptionList",
  RADIOGROUP = "PDFRadioGroup",
  SIGNATURE = "PDFSignature",
  TEXTFIELD = "PDFTextField",
}

export interface FormInterfaceFieldJson {
  question: string | null;
  field_name: string;
  field_type: PDFLibFieldTypes | null;
  answer_value: string | null;
  cbx_conditional_operation?: string | null;
  cbx_conditional_argument?: any | null;
}

interface LastEditedByUserInfo {
  id: number;
  email: string;
}

export type FormInterfaceJson = {
  id?: number;
  form_id?: number;
  interface_s3_key?: string;
  interface_s3_version?: string;
  last_edited_at?: string;
  last_edited_by_user_info?: LastEditedByUserInfo;
  data: FormInterfaceFieldJson[];
};

export class FormInterface {
  constructor(
    public data: FormInterfaceFieldJson[],
    public id?: number,
    public formId?: number,
    public interfaceS3Key?: string,
    public interfaceS3Version?: string,
    public lastEditedByUserInfo?: LastEditedByUserInfo,
    public lastEditedAt?: Date
  ) {}

  static fromJSON(json: FormInterfaceJson): FormInterface {
    return new FormInterface(
      json.data,
      json.id,
      json.form_id,
      json.interface_s3_key,
      json.interface_s3_version,
      json.last_edited_by_user_info ? json.last_edited_by_user_info : undefined,
      json.last_edited_at ? new Date(json.last_edited_at) : undefined
    );
  }

  toJSON(): FormInterfaceJson {
    return {
      id: this.id,
      form_id: this.formId,
      data: this.data,
      interface_s3_key: this.interfaceS3Key,
      interface_s3_version: this.interfaceS3Version,
      last_edited_by_user_info: this.lastEditedByUserInfo,
      last_edited_at: this.lastEditedAt?.toISOString(),
    };
  }

  setData(data: FormInterfaceFieldJson[]): FormInterface {
    this.data = data;
    return this;
  }
}
