import { ActionTypes } from "../actions/types";
import { FormStatusType } from "../models/FormStatusType";
import { AnyAction } from "redux";

export const formStatusTypesReducer = (
  formStatusTypes: FormStatusType[] | null = null,
  action: AnyAction
): FormStatusType[] | null => {
  switch (action.type) {
    case ActionTypes.FetchFormStatusTypes:
      return action.payload;
    case ActionTypes.FetchSingleFormStatusType:
      if (formStatusTypes) {
        const index = formStatusTypes?.findIndex(
          (formStatusType: FormStatusType) =>
            formStatusType.id === action.payload.id
        );
        if (index !== -1) {
          const newFormStatusTypes = [...formStatusTypes];
          newFormStatusTypes[index] = action.payload;
          return newFormStatusTypes;
        } else {
          return [...formStatusTypes, action.payload];
        }
      } else {
        return [action.payload];
      }
    case ActionTypes.AppSignOut:
      return null;
    default:
      return formStatusTypes;
  }
};
