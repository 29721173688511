import React from "react";
import {
  Grid,
  Stack,
  TextField,
  Button,
  Select,
  FormControl,
  InputLabel,
  MenuItem,
  FormHelperText,
  Typography,
  Box,
  CircularProgress,
} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import { Formik, FormikHelpers, getIn } from "formik";
const Yup = require("yup")
import { EstateLaravelized } from "../../../../models/EstateLaravelized";
import { User, UserJSON } from "../../../../models/User";
import { connect } from "react-redux";
import MuiPhoneNumber from "material-ui-phone-number";
import parsePhoneNumber from "libphonenumber-js";

import { Country } from "../../../../models/Geography";

// Redux Imports
import { StoreState } from "../../../../reducers";

import {
  nameSuffixes,
  relationshipTypes,
  advisoryPositions,
  declaredRoles,
} from "./answerOptions";
import { AxiosError } from "axios";
import { UpdateStatus } from "./EstateOverviewEditDialog";

interface Props {
  estate?: EstateLaravelized;
  executor?: User;
  onComplete: (estateId: number, executorId: number, executor: User) => void;
  countries: Country[];
}

function _EstateOverviewExecutorEditForm({
  estate,
  executor,
  onComplete,
  countries,
}: Props): JSX.Element {
  const [statusDisplay, setStatusDisplay] = React.useState<
    UpdateStatus | undefined
  >(undefined);

  const submitForm = async (
    values: UserJSON,
    { setSubmitting, setStatus }: FormikHelpers<UserJSON>
  ) => {
    setSubmitting(true);
    setStatus(undefined);
    setStatusDisplay(undefined);
    let stat: UpdateStatus | undefined = undefined;

    try {
      if (estate && executor && executor.id) {
        // Reformat the Phone Number
        await onComplete(estate.id, executor.id, User.fromJSON(values));
        stat = {
          success: true,
          message: "Executor has been updated successfully!",
        };
      }
    } catch (e: any) {
      const error: AxiosError = e;
      const errorData = error.response?.data as any;
      console.error(errorData);
      stat = {
        success: false,
        message: errorData.message,
        errors: errorData.errors,
      };
    }
    setStatus(stat);
    setStatusDisplay(stat);
    setSubmitting(false);
  };

  const formikInit = {
    values: executor?.toJson() as UserJSON,
    schema: Yup.object().shape({
      user_profile: Yup.object().shape({
        first_name: Yup.string().nullable().required("Required"),
        middle_name: Yup.string().nullable(),
        last_name: Yup.string().nullable(),
        name_suffix: Yup.string().nullable(),
      }),
      phone: Yup.string().nullable().required("Required"),
      countries_id: Yup.number().nullable().required("Required"),
      estate_user_info: Yup.object().shape({
        relationship_to_deceased: Yup.string().nullable().required("Required"),
        relationship_to_deceased_more: Yup.string()
          .nullable()
          .when("relationship_to_deceased", {
            is: "Something else",
            then: Yup.string().required("Required"),
          }),
        declared_role: Yup.string().nullable().required("Required"),
        name_of_corporate_executor: Yup.string()
          .nullable()
          .when("declared_role", {
            is: "Corporate executor",
            then: Yup.string().required("Required"),
          }),
        advisory_position: Yup.string().nullable(),
        advisory_position_more: Yup.string()
          .nullable()
          .when("advisory_position", {
            is: "Something else",
            then: Yup.string().required("Required"),
          }),
      }),
    }),
  };

  return (
    <>
      <Typography variant="h3" sx={{ marginBottom: "1rem" }}>
        Executor Information
      </Typography>
      {estate && executor ? (
        <>
          <Formik
            initialValues={formikInit.values}
            validationSchema={formikInit.schema}
            onSubmit={submitForm}
            enableReinitialize
          >
            {({
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting,
              values,
              errors,
              touched,
              setFieldValue,
              dirty,
              resetForm,
            }) => (
              <form onSubmit={handleSubmit}>
                <>
                  <Grid container spacing={2} sx={{ marginBottom: "1rem" }}>
                    <Grid item xs={12} sm={3}>
                      <FormControl variant="outlined" fullWidth>
                        <TextField
                          name="user_profile.first_name"
                          label="Executor First Name"
                          value={values.user_profile?.first_name || ""}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          variant="outlined"
                          disabled={
                            !!values.estate_user_info
                              ?.name_of_corporate_executor
                          }
                          error={
                            getIn(errors, "user_profile.first_name") &&
                            getIn(touched, "user_profile.first_name")
                              ? true
                              : false
                          }
                          helperText={
                            getIn(errors, "user_profile.first_name") &&
                            getIn(touched, "user_profile.first_name")
                              ? getIn(errors, "user_profile.first_name")
                              : undefined
                          }
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={3}>
                      <FormControl variant="outlined" fullWidth>
                        <TextField
                          name="user_profile.middle_name"
                          label="Executor Middle Name"
                          value={values.user_profile?.middle_name || ""}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          variant="outlined"
                          error={
                            getIn(errors, "user_profile.middle_name") &&
                            getIn(touched, "user_profile.middle_name")
                              ? true
                              : false
                          }
                          disabled={
                            !!values.estate_user_info
                              ?.name_of_corporate_executor
                          }
                          helperText={
                            getIn(errors, "user_profile.middle_name") &&
                            getIn(touched, "user_profile.middle_name")
                              ? getIn(errors, "user_profile.middle_name")
                              : undefined
                          }
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={3}>
                      <FormControl variant="outlined" fullWidth>
                        <TextField
                          name="user_profile.last_name"
                          label="Executor Last Name"
                          value={values.user_profile?.last_name || ""}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          variant="outlined"
                          disabled={
                            !!values.estate_user_info
                              ?.name_of_corporate_executor
                          }
                          error={
                            getIn(errors, "user_profile.last_name") &&
                            getIn(touched, "user_profile.last_name")
                              ? true
                              : false
                          }
                          helperText={
                            getIn(errors, "user_profile.last_name") &&
                            getIn(touched, "user_profile.last_name")
                              ? getIn(errors, "user_profile.last_name")
                              : undefined
                          }
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={3}>
                      <FormControl
                        variant="outlined"
                        fullWidth
                        error={
                          getIn(errors, "user_profile.name_suffix") &&
                          getIn(touched, "user_profile.name_suffix")
                            ? true
                            : false
                        }
                      >
                        <InputLabel id="user_profile.name_suffix">
                          Executor Suffix
                        </InputLabel>
                        <Select
                          name="user_profile.name_suffix"
                          value={values.user_profile?.name_suffix || ""}
                          labelId="user_profile.name_suffix"
                          label="Executor Suffix"
                          disabled={
                            !!values.estate_user_info
                              ?.name_of_corporate_executor
                          }
                          onChange={(event: any) => {
                            setFieldValue(
                              "user_profile.name_suffix",
                              event.target.value
                            );
                          }}
                          onBlur={handleBlur}
                        >
                          <MenuItem value="">
                            <em>Clear</em>
                          </MenuItem>
                          {nameSuffixes?.map((option: string) => {
                            return (
                              <MenuItem key={option} value={option}>
                                {option}
                              </MenuItem>
                            );
                          })}
                        </Select>
                        <FormHelperText>
                          {getIn(errors, "user_profile.name_suffix") &&
                          getIn(touched, "user_profile.name_suffix")
                            ? getIn(errors, "user_profile.name_suffix")
                            : undefined}
                        </FormHelperText>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <FormControl variant="outlined" fullWidth>
                        <MuiPhoneNumber
                          variant="outlined"
                          fullWidth
                          value={values.phone}
                          label="Phone Number"
                          defaultCountry={"us"}
                          disableDropdown
                          onlyCountries={["us", "ca"]}
                          onChange={(e: any) => {
                            setFieldValue("phone", parsePhoneNumber(e)?.number);
                          }}
                          onBlur={handleBlur}
                          error={
                            getIn(errors, "phone") && getIn(touched, "phone")
                              ? true
                              : false
                          }
                          helperText={
                            getIn(errors, "phone") && getIn(touched, "phone")
                              ? getIn(errors, "phone")
                              : undefined
                          }
                        />
                      </FormControl>
                    </Grid>
                  </Grid>
                  <Grid container spacing={2} sx={{ marginBottom: "1rem" }}>
                    <Grid item xs={12} sm={6}>
                      <FormControl
                        variant="outlined"
                        fullWidth
                        error={
                          (getIn(errors, "countries_id") &&
                            getIn(touched, "countries_id")) ||
                          undefined
                        }
                      >
                        <InputLabel>Executor's Country</InputLabel>
                        <Select
                          name="countries_id"
                          value={values.countries_id?.toString() || ""}
                          label="Executor's Country"
                          disabled={!countries}
                          onChange={(event: any) => {
                            setFieldValue("countries_id", event.target.value);
                          }}
                          onBlur={handleBlur}
                        >
                          {countries?.map((country: Country) => {
                            return (
                              <MenuItem key={country.id} value={country.id?.toString()}>
                                {country.name}
                              </MenuItem>
                            );
                          })}
                        </Select>
                        <FormHelperText>
                          {getIn(errors, "countries_id") &&
                          getIn(touched, "countries_id")
                            ? getIn(errors, "countries_id")
                            : undefined}
                        </FormHelperText>
                      </FormControl>
                    </Grid>
                  </Grid>
                  <Grid container spacing={2} sx={{ marginBottom: "1rem" }}>
                    <Grid item xs={12} sm={6}>
                      <FormControl
                        variant="outlined"
                        fullWidth
                        error={
                          getIn(
                            errors,
                            "estate_user_info.relationship_to_deceased"
                          ) &&
                          getIn(
                            touched,
                            "estate_user_info.relationship_to_deceased"
                          )
                            ? true
                            : false
                        }
                      >
                        <InputLabel id="relationship">
                          Relationship to Decedent
                        </InputLabel>
                        <Select
                          name="estate_user_info.relationship_to_deceased"
                          value={
                            values.estate_user_info?.relationship_to_deceased ||
                            ""
                          }
                          labelId="estate_user_info.relationship_to_deceased"
                          label="Relationship to Decedent"
                          onChange={(event: any) => {
                            handleChange(event);
                            setFieldValue(
                              "estate_user_info.relationship_to_deceased_more",
                              null
                            );
                          }}
                          onBlur={handleBlur}
                        >
                          {relationshipTypes?.map((option: string) => {
                            return (
                              <MenuItem key={option} value={option}>
                                {option}
                              </MenuItem>
                            );
                          })}
                        </Select>
                        <FormHelperText>
                          {getIn(
                            errors,
                            "estate_user_info.relationship_to_deceased"
                          ) &&
                          getIn(
                            touched,
                            "estate_user_info.relationship_to_deceased"
                          )
                            ? getIn(
                                errors,
                                "estate_user_info.relationship_to_deceased"
                              )
                            : undefined}
                        </FormHelperText>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      {values.estate_user_info?.relationship_to_deceased ==
                        "Something else" && (
                        <FormControl variant="outlined" fullWidth>
                          <TextField
                            name="estate_user_info.relationship_to_deceased_more"
                            label="Relationship (Other)"
                            disabled={
                              values.estate_user_info
                                ?.relationship_to_deceased != "Something else"
                            }
                            value={
                              values.estate_user_info
                                ?.relationship_to_deceased_more || ""
                            }
                            onChange={handleChange}
                            onBlur={handleBlur}
                            variant="outlined"
                            error={
                              getIn(
                                errors,
                                "estate_user_info.relationship_to_deceased_more"
                              ) &&
                              getIn(
                                touched,
                                "estate_user_info.relationship_to_deceased_more"
                              )
                                ? true
                                : false
                            }
                            helperText={
                              getIn(
                                errors,
                                "estate_user_info.relationship_to_deceased_more"
                              ) &&
                              getIn(
                                touched,
                                "estate_user_info.relationship_to_deceased_more"
                              )
                                ? getIn(
                                    errors,
                                    "estate_user_info.relationship_to_deceased_more"
                                  )
                                : undefined
                            }
                          />
                        </FormControl>
                      )}
                    </Grid>
                  </Grid>
                  <Grid container spacing={2} sx={{ marginBottom: "1rem" }}>
                    <Grid item xs={12} sm={6}>
                      <FormControl
                        variant="outlined"
                        fullWidth
                        error={
                          getIn(errors, "estate_user_info.declared_role") &&
                          getIn(touched, "estate_user_info.declared_role")
                            ? true
                            : false
                        }
                      >
                        <InputLabel id="estate_user_info.declared_role">
                          Declared Role
                        </InputLabel>
                        <Select
                          name="estate_user_info.declared_role"
                          value={values.estate_user_info?.declared_role || ""}
                          labelId="estate_user_info.declared_role"
                          label="Declared Role"
                          onChange={(event: any) => {
                            handleChange(event);
                            if (event.target.value != "Corporate executor") {
                              setFieldValue(
                                "estate_user_info.name_of_corporate_executor",
                                null
                              );
                            }
                          }}
                          onBlur={handleBlur}
                        >
                          {declaredRoles?.map((option: string) => {
                            return (
                              <MenuItem key={option} value={option}>
                                {option}
                              </MenuItem>
                            );
                          })}
                        </Select>
                        <FormHelperText>
                          {getIn(errors, "estate_user_info.declared_role") &&
                          getIn(touched, "estate_user_info.declared_role")
                            ? getIn(errors, "estate_user_info.declared_role")
                            : undefined}
                        </FormHelperText>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      {values.estate_user_info?.declared_role ==
                        "Corporate executor" && (
                        <FormControl variant="outlined" fullWidth>
                          <TextField
                            name="estate_user_info.name_of_corporate_executor"
                            disabled={
                              values.estate_user_info.declared_role !=
                              "Corporate executor"
                            }
                            label="Name of Corporate Executor"
                            value={
                              values.estate_user_info
                                .name_of_corporate_executor || ""
                            }
                            onChange={(event: any) => {
                              handleChange(event);
                              setFieldValue(
                                "user_profile.first_name",
                                event.target.value
                              );
                              setFieldValue("user_profile.middle_name", null);
                              setFieldValue("user_profile.last_name", null);
                              setFieldValue("user_profile.name_suffix", null);
                            }}
                            onBlur={handleBlur}
                            variant="outlined"
                            error={
                              getIn(
                                errors,
                                "estate_user_info.name_of_corporate_executor"
                              ) &&
                              getIn(
                                touched,
                                "estate_user_info.name_of_corporate_executor"
                              )
                                ? true
                                : false
                            }
                            helperText={
                              getIn(
                                errors,
                                "estate_user_info.name_of_corporate_executor"
                              ) &&
                              getIn(
                                touched,
                                "estate_user_info.name_of_corporate_executor"
                              )
                                ? getIn(
                                    errors,
                                    "estate_user_info.name_of_corporate_executor"
                                  )
                                : "This will replace the Executor name."
                            }
                          />
                        </FormControl>
                      )}
                    </Grid>
                  </Grid>
                  <Grid container spacing={2} sx={{ marginBottom: "1rem" }}>
                    <Grid item xs={12} sm={6}>
                      <FormControl
                        variant="outlined"
                        fullWidth
                        error={
                          getIn(errors, "estate_user_info.advisory_position") &&
                          getIn(touched, "estate_user_info.advisory_position")
                            ? true
                            : false
                        }
                      >
                        <InputLabel id="estate_user_info.advisory_position">
                          Declared Advisory Position
                        </InputLabel>
                        <Select
                          name="estate_user_info.advisory_position"
                          value={
                            values.estate_user_info?.advisory_position || ""
                          }
                          labelId="estate_user_info.advisory_position"
                          label="Declared Advisory Position"
                          onChange={(event: any) => {
                            handleChange(event);
                            setFieldValue(
                              "estate_user_info.advisory_position_more",
                              null
                            );
                          }}
                          onBlur={handleBlur}
                        >
                          <MenuItem value="">
                            <em>Clear</em>
                          </MenuItem>
                          {advisoryPositions?.map((option: string) => {
                            return (
                              <MenuItem key={option} value={option}>
                                {option}
                              </MenuItem>
                            );
                          })}
                        </Select>
                        <FormHelperText>
                          {getIn(
                            errors,
                            "estate_user_info.advisory_position"
                          ) &&
                          getIn(touched, "estate_user_info.advisory_position")
                            ? getIn(
                                errors,
                                "estate_user_info.advisory_position"
                              )
                            : undefined}
                        </FormHelperText>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      {values.estate_user_info?.advisory_position ==
                        "Something else" && (
                        <FormControl variant="outlined" fullWidth>
                          <TextField
                            name="estate_user_info.advisory_position_more"
                            disabled={
                              values.estate_user_info.advisory_position !=
                              "Something else"
                            }
                            label="Advisory Position (Other)"
                            value={
                              values.estate_user_info.advisory_position_more ||
                              ""
                            }
                            onChange={handleChange}
                            onBlur={handleBlur}
                            variant="outlined"
                            error={
                              getIn(
                                errors,
                                "estate_user_info.advisory_position_more"
                              ) &&
                              getIn(
                                touched,
                                "estate_user_info.advisory_position_more"
                              )
                                ? true
                                : false
                            }
                            helperText={
                              getIn(
                                errors,
                                "estate_user_info.advisory_position_more"
                              ) &&
                              getIn(
                                touched,
                                "estate_user_info.advisory_position_more"
                              )
                                ? getIn(
                                    errors,
                                    "estate_user_info.advisory_position_more"
                                  )
                                : undefined
                            }
                          />
                        </FormControl>
                      )}
                    </Grid>
                  </Grid>
                  {statusDisplay && (
                    <Box sx={{ marginBottom: "1rem" }}>
                      <Typography
                        variant="body1"
                        sx={{
                          color: statusDisplay.success
                            ? "success.main"
                            : "error.main",
                        }}
                      >
                        {statusDisplay.message}
                      </Typography>
                      {statusDisplay.success === false && (
                        <>
                          <Typography
                            variant="body2"
                            sx={{
                              color: statusDisplay.success
                                ? "success.main"
                                : "error.main",
                            }}
                          >
                            {statusDisplay.errors && Object.values(statusDisplay.errors)?.map(
                              (value) => value
                            )}
                          </Typography>
                        </>
                      )}
                    </Box>
                  )}
                  <Stack direction="row" spacing={2}>
                    <Button
                      variant="outlined"
                      disabled={isSubmitting || !dirty}
                      onClick={() => resetForm()}
                    >
                      Reset
                    </Button>
                    <LoadingButton
                      variant="contained"
                      loading={isSubmitting}
                      disabled={!dirty || isSubmitting}
                      type="submit"
                    >
                      Save Executor Info
                    </LoadingButton>
                  </Stack>
                </>
              </form>
            )}
          </Formik>
        </>
      ) : (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <CircularProgress />
        </Box>
      )}
    </>
  );
}

const mapStateToProps = ({
  countries,
}: // states,
StoreState): {
  countries: Country[];
  // states: State[];
} => {
  return {
    countries,
    // states
  };
};

const EstateOverviewExecutorEditForm = connect(mapStateToProps)(
  _EstateOverviewExecutorEditForm
);

export default EstateOverviewExecutorEditForm;
