import {
  Button,
  Divider,
  Grid,
  TableCell,
  TableRow,
  TextField,
  Theme,
  Typography,
} from "@mui/material";
import { makeStyles } from "tss-react/mui";
import React from "react";
import { Formik, FormikHelpers } from "formik";
const Yup = require("yup")
import { StoreState } from "../../../../reducers";
import { User } from "../../../../models/User";
import { connect } from "react-redux";
import {
  deleteEstateNote,
  saveEstateNote,
} from "../../../../actions/estateActions";
import { logger, notesApi } from "../../../../apis";
import { EstateNote } from "../../../../models/EstateNote";
import { ConfirmationDialog } from "../../../shared/dialog/ConfirmationDialog";

const useStyles = makeStyles()((theme: Theme) => ({
  formWrapper: {
    padding: theme.spacing(1),
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  inputs: {
    backgroundColor: theme.palette.background.paper,
  },
  buttonWrapper: {
    display: "flex",
    flexDirection: "row-reverse",
  },
  buttonsLeft: {
    "& > Button": {
      marginRight: theme.spacing(1),
    },
  },
  columnRight: {
    display: "flex",
    flexDirection: "row-reverse",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  button: {
    margin: "2px 0",
  },
  status: {
    padding: theme.spacing(0, 1),
  },
}));

interface Values {
  text: string;
}

interface Props {
  estateNote: EstateNote;
  estateId: number;
  onComplete: () => void;
  user: User;
  saveEstateNote: (estateNote: EstateNote, estateId: number) => void;
  deleteEstateNote: (estateNote: EstateNote, estateId: number) => void;
  colSpan: number;
}

export const _NoteForm = ({
  estateNote,
  estateId,
  onComplete,
  user,
  saveEstateNote,
  deleteEstateNote,
  colSpan,
}: Props): JSX.Element => {
  const { classes } = useStyles();
  const newEstateNote = estateNote.id === undefined;
  const [deleteOpen, setDeleteOpen] = React.useState(false);

  const descriptionElementRef = React.useRef<HTMLElement>(null);
  React.useEffect(() => {
    if (deleteOpen) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [deleteOpen]);

  // https://formik.org/docs/api/formik
  const formikInit = {
    values: {
      text: estateNote.text || "",
    },
    schema: Yup.object().shape({
      text: Yup.string().max(1000).required("Note is required"),
    }),
  };

  const handleSubmit = async (
    values: Values,
    { setSubmitting, resetForm, setStatus }: FormikHelpers<Values>
  ) => {
    try {
      setStatus("");
      if (!user.advisorGroupName) {
        setStatus(
          "Sorry, error: can not determine your advisor group required for data security."
        );
        return;
      }
      estateNote.text = values.text;
      if (newEstateNote) {
        estateNote.estateId = estateId;
        estateNote.creatorName = user.fullName;
      }
      const updatedEstateNote = await notesApi.saveEstateNote(estateNote);
      if (updatedEstateNote) {
        // will add to the estate notes list and ui will update
        saveEstateNote(updatedEstateNote, estateId);
        setSubmitting(false);
        resetForm();
        onComplete();
      } else {
        setStatus(
          "Sorry, there was an error updating the note.  Please try again."
        );
      }
    } catch (error) {
      setStatus("Sorry, error: " + ((error as Error)?.message || ""));
      logger.error(error as Error);
      setSubmitting(false);
    }
  };

  const handleDeleteRequest = () => {
    setDeleteOpen(true);
  };

  const handleDelete = async () => {
    try {
      if (await notesApi.deleteEstateNote(estateNote)) {
        setDeleteOpen(false);
        deleteEstateNote(estateNote, estateId);
        onComplete();
      } else {
        // display error
      }
    } catch (error) {
      // TODO: display an error message to user
      console.error(error);
    }
  };

  return (
    <TableRow hover key="create-note">
      <TableCell colSpan={colSpan}>
        <div className={classes.formWrapper}>
          <Formik
            initialValues={formikInit.values}
            validationSchema={formikInit.schema}
            onSubmit={handleSubmit}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting,
              touched,
              values,
              resetForm,
              status,
            }) => (
              <form onSubmit={handleSubmit}>
                <Grid
                  container
                  spacing={1}
                  alignItems="flex-end"
                  justifyContent="flex-end"
                >
                  <Grid item xs={12}>
                    <TextField
                      id="text"
                      label={newEstateNote ? "New Note" : "Note"}
                      variant="outlined"
                      className={classes.inputs}
                      multiline
                      fullWidth
                      minRows={3}
                      value={values.text}
                      error={Boolean(touched.text && errors.text)}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      autoFocus={true}
                      style={{ marginTop: 8 }}
                    />
                    <Typography variant="body1" color="error">
                      {errors.text}
                    </Typography>
                    <Divider className="dark" />
                  </Grid>
                  <Grid item xs={12} className={classes.buttonWrapper}>
                    <Grid item xs={6} className={classes.columnRight}>
                      <Button
                        disabled={isSubmitting}
                        variant="contained"
                        color="primary"
                        type="submit"
                        className={classes.button}
                      >
                        Save
                      </Button>
                      {!newEstateNote && (
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={() => handleDeleteRequest()}
                          className={`${classes.button} primary-inverted`}
                          style={{ marginRight: 8 }}
                        >
                          Delete
                        </Button>
                      )}
                      <Typography variant="body1" color="error">
                        {status}
                      </Typography>
                    </Grid>
                    <Grid item xs={6} className={classes.buttonsLeft}>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={() => {
                          resetForm();
                          onComplete();
                        }}
                        className={`${classes.button} primary-inverted`}
                      >
                        Cancel
                      </Button>
                    </Grid>
                    <ConfirmationDialog
                      open={deleteOpen}
                      question="Are you sure you want to delete this note?"
                      description={estateNote.text || ""}
                      onConfirm={handleDelete}
                      confirmText="Delete it"
                      onDeny={() => setDeleteOpen(false)}
                      denyText="Cancel"
                    />
                  </Grid>
                </Grid>
              </form>
            )}
          </Formik>
        </div>
      </TableCell>
    </TableRow>
  );
};

const mapStateToProps = ({ user }: StoreState): { user: User } => {
  return { user };
};

export const NoteForm = connect(mapStateToProps, {
  saveEstateNote,
  deleteEstateNote,
})(_NoteForm);
