import React from "react";
import { Box, Tab, Tabs, Theme } from "@mui/material";
import { makeStyles } from "tss-react/mui";
import { useLocation, useNavigate } from "react-router-dom";
import { SubScreen } from "../../models/SubScreen";

function a11yProps(title: string) {
  return {
    id: `estate-${title}`,
    "aria-controls": `estate-tab-${title}`,
  };
}

const useStyles = makeStyles()((theme: Theme) => ({
  root: {
    margin: theme.spacing(3, 0),
  },
  tabs: {
    marginTop: -20,
  },
  tab: {
    fontWeight: 600,
    fontSize: 16,
    lineHeight: "24px",
    color: theme.palette.text.secondary,
    minWidth: "auto",
    padding: "6px 0 5px",
    margin: "0 32px 0 0",
    "&.Mui-selected": {
      color: `${theme.palette.primary.main}`,
    },
  },
  tabsBottom: {
    height: 4,
    backgroundColor: `${theme.palette.grey["300"]}`,
    position: "relative",
    top: -4,
    zIndex: 10,
  },
}));

interface Props {
  navItems: SubScreen[];
}

export const SubNavBar = ({ navItems }: Props): JSX.Element => {
  const { classes } = useStyles();
  const location = useLocation();
  const navigate = useNavigate();
  const navPathIndex = navItems.findIndex((screen: SubScreen) =>
    location.pathname.includes(screen.path)
  );
  const [selectedIndex, setSelectedIndex] = React.useState(0);

  if (selectedIndex !== navPathIndex) {
    setSelectedIndex(navPathIndex);
  }

  const handleNavigate = (navIndex: number) => {
    navigate(`${navItems[navIndex].path}`);
  };

  return (
    <Box className={classes.root}>
      <Tabs
        value={selectedIndex}
        indicatorColor="primary"
        variant="scrollable"
        scrollButtons="auto"
        className={classes.tabs}
      >
        {navItems.map((screen: SubScreen, index: number) => {
          return (
            <Tab
              component="a"
              className={classes.tab}
              label={screen.title}
              {...a11yProps(screen.title)}
              onClick={() => handleNavigate(index)}
              disableRipple={true}
              key={screen.title}
            />
          );
        })}
      </Tabs>
      <div className={classes.tabsBottom} />
    </Box>
  );
};
