import React from "react";
import { Box, FormControl, MenuItem, Select } from "@mui/material";
import { makeStyles } from "tss-react/mui";
import { AdvisorGroup } from "../../../models/AdvisorGroup";

const useStyles = makeStyles()(() => ({
  root: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: "20px",
  },
  margin: {},
}));

interface Props {
  advisorGroups: AdvisorGroup[];
  advisorGroupId?: number;
  onSelect: (id: number) => void;
}

export const EstatesFilter = ({
  advisorGroups,
  advisorGroupId,
  onSelect,
}: Props): JSX.Element => {
  const { classes } = useStyles();

  return (
    <Box display="flex" justifyContent="space-between" className={classes.root}>
      <FormControl>
        {advisorGroups && advisorGroups.length && (
          <Select
            name="group"
            value={advisorGroupId}
            onChange={(event) => {
              onSelect(event.target.value as number);
            }}
            variant="outlined"
            displayEmpty={true}
          >
            <MenuItem value={0} key={`groupId-all`}>
              all
            </MenuItem>
            {advisorGroups?.map((advisorGroup: AdvisorGroup) => {
              return (
                <MenuItem
                  value={advisorGroup.id}
                  key={`groupId-${advisorGroup.id}`}
                >
                  {advisorGroup.id}:{advisorGroup.title?.replace("AG-", "")}
                </MenuItem>
              );
            })}
          </Select>
        )}
      </FormControl>
    </Box>
  );
};
