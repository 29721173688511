import { Box, CircularProgress, CircularProgressProps } from "@mui/material";

function CircularProgressWithLabel(
  props: CircularProgressProps & {
    value: number;
    size: number;
    children?: JSX.Element | JSX.Element[];
  }
) {
  return (
    <Box
      sx={{
        position: "relative",
        display: "inline-flex",
        width: props.size,
        height: props.size,
        backgroundColor: "grey.100",
        borderRadius: "50%",
      }}
    >
      <CircularProgress
        variant="determinate"
        {...props}
        sx={{
          color: "secondary.main",
        }}
        size={props.size}
      />
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: "absolute",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        {props.children}
      </Box>
    </Box>
  );
}

interface Props {
  children?: JSX.Element | JSX.Element[];
  size?: number;
  percent?: number;
}

export const CircularLoaderWithChild = ({
  children,
  size = 40,
  percent = 10,
}: Props): JSX.Element => {
  return (
    <CircularProgressWithLabel value={percent} size={size}>
      {children}
    </CircularProgressWithLabel>
  );
};
