import { Country, County, State } from "../models/Geography";
import { callGet, logger } from "./index";

const path = "/geographies";

export class Geographies {
  async fetchCountries(): Promise<Country[] | null> {
    try {
      const fullPath = `${path}/countries`;
      const response = await callGet(fullPath, true);
      const countries: Country[] = [];
      response?.data?.forEach((country: any) => {
        countries.push(Country.fromJSON(country));
      });

      return countries;
    } catch (error) {
      this.handleError(error);
    }
    return null;
  }

  async fetchCountry(countryId: number): Promise<Country | null> {
    try {
      const fullPath = `${path}/countries/${countryId}`;
      const response = await callGet(fullPath, true);
      const country: Country = Country.fromJSON(response?.data);
      return country;
    } catch (error) {
      this.handleError(error);
    }
    return null;
  }

  async fetchStates(): Promise<State[] | null> {
    try {
      const fullPath = `${path}/states`;
      const response = await callGet(fullPath, true);
      const states: State[] = [];
      response?.data?.forEach((state: any) => {
        states.push(State.fromJSON(state));
      });

      return states.sort(function (a: State, b: State) {
        if (a.name < b.name) {
          return -1;
        }
        if (a.name > b.name) {
          return 1;
        }
        return 0;
      });
    } catch (error) {
      this.handleError(error);
    }
    return null;
  }

  async fetchState(stateId: number): Promise<State | null> {
    try {
      const fullPath = `${path}/state/${stateId}`;
      const response = await callGet(fullPath, true);
      const state: State = State.fromJSON(response?.data);
      return state;
    } catch (error) {
      this.handleError(error);
    }
    return null;
  }

  async fetchCounties(stateId: number): Promise<County[] | null> {
    try {
      const fullPath = `${path}/state/${stateId}/counties`;
      const response = await callGet(fullPath, true);

      const counties: County[] = [];
      response?.data?.forEach((county: any) => {
        counties.push(County.fromJSON(county));
      });

      return counties.sort(function (a: County, b: County) {
        if (a.name < b.name) {
          return -1;
        }
        if (a.name > b.name) {
          return 1;
        }
        return 0;
      });
    } catch (error) {
      this.handleError(error);
    }
    return null;
  }

  async fetchCounty(countyId: number): Promise<County | null> {
    try {
      const fullPath = `${path}/county/${countyId}`;
      const response = await callGet(fullPath, true);
      const county = County.fromJSON(response?.data);
      return county;
    } catch (error) {
      this.handleError(error);
    }
    return null;
  }

  handleError(error: any): void {
    if ((error as Error)?.message === "no response.data") {
      logger.error(error, false);
    } else {
      logger.error(error as Error);
    }
  }
}
