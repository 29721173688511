import React, { useState, useEffect } from "react";
import { Box, SxProps, Theme, Button, Typography } from "@mui/material";

import { AtticusForm } from "../../../../../models/AtticusForm";

import { StoreState } from "../../../../../reducers";
import { connect } from "react-redux";
import { GeographyType, State } from "../../../../../models/Geography";
import { ProbateType } from "../../../../../models/ProbateType";
import { Estate } from "../../../../../models/Estate";
import { fetchEstateForms } from "../../../../../actions/estateFormActions";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { useNavigate } from "react-router";
import EstateFormsInfoCard, {
  EstateFormsInfoCardProps,
} from "../../../../shared/EstateFormsInfoCard";
import { GeographyWatercolorImg } from "../../../../shared/GeographyWatercolorImg";
import MuiMarkdown from "mui-markdown";
import { FormsList } from "../../../../shared/forms/list/FormsList";

interface Props {
  estate: Estate;
  probateType?: ProbateType;
  truncateFormsAt?: number;
  sx: SxProps<Theme>;
  estateForms?: AtticusForm[];
  fetchEstateForms: (estateId: number) => void;
  states: State[];
  openDrawer: () => void;
  setDrawerContents: (contents: JSX.Element) => void;
  onEmptyButtonClick?: () => void;
}

const _EstateFormsComponent = ({
  estate,
  probateType,
  openDrawer,
  setDrawerContents,
  estateForms,
  fetchEstateForms,
  truncateFormsAt,
  sx,
  states,
  onEmptyButtonClick,
}: Props): JSX.Element => {
  const navigate = useNavigate();

  useEffect(() => {
    if (!estateForms && estate.id) {
      fetchEstateForms(estate.id);
    }
  }, []);

  const [probateState, setProbateState] = useState<State | undefined>();
  const [estateState, setEstateState] = useState<State | undefined>();

  useEffect(() => {
    if (states) {
      setProbateState(
        states.find((state) => state.id === probateType?.stateId)
      );
    }
  }, [probateType, states]);

  useEffect(() => {
    if (states) {
      setEstateState(states.find((state) => state.id === estate?.stateId));
    }
  }, [estate, states]);

  const getEstateFormsInfoCard = (): JSX.Element => {
    let props: EstateFormsInfoCardProps;

    if (estate.probateTypeId && probateType) {
      // If we have a probate packet, display the Probate Packet card.
      props = {
        title: `${probateType?.probateAtticusTier?.name} Packet for ${probateState?.name}`,
        description: `${probateType?.name}`,
        imageElement: (
          <GeographyWatercolorImg
            geographySlug={probateState?.slug}
            sx={{ maxHeight: "5.5rem" }}
          />
        ),
      };
    } else if (estateState?.content.probateFallbackContent) {
      // If the Estate has fallback text, show the fallback text card
      props = {
        title: `Some helpful information about ${estateState?.name} Probate`,
        description: `Check out our Guide to ${estateState?.name} Probate to get a quick rundown of what the process looks like.`,
        imageElement: (
          <GeographyWatercolorImg
            geographySlug={estateState?.slug}
            sx={{ maxHeight: "5.5rem" }}
          />
        ),
        onClick: () => {
          setDrawerContents(
            <MuiMarkdown
              overrides={{
                ol: {
                  component: "ol",
                  props: {
                    style: { marginBottom: "0.5rem", padding: "10px 20px" },
                  },
                },
              }}
            >
              {estateState?.content.probateFallbackContent}
            </MuiMarkdown>
          );
          openDrawer();
        },
      };
    } else {
      // If all else fails, show "Looking for estate's state forms?" card to link to Forms Library
      props = {
        title: `Looking for ${estateState?.name} Estate & Probate Forms?`,
        description: `Use our Forms Library to search, find, access, and use more than 3,500 local court forms across the United States and Canada.`,
        imageElement: (
          <GeographyWatercolorImg
            geographySlug={estateState?.slug}
            sx={{ maxHeight: "5.5rem" }}
          />
        ),
        onClick: () => {
          navigate(`forms-library`, {
            state: {
              geography_type: GeographyType.State,
              geography_id: estate.stateId,
            },
          });
        },
      };
    }

    return (
      <EstateFormsInfoCard
        {...props}
        sx={{
          mb: 2,
        }}
        loading={!estate || !estateState}
      />
    );
  };

  return (
    <Box sx={sx}>
      <Box sx={{ mb: 2 }}>
        <Typography variant="h5">{`Estate Forms`}</Typography>
        <Typography variant="body1">
          {`Here’s a running list of forms needing to be completed. As you or any other users connected to your estate add required forms, they will begin to appear here. Atticus may also add suggested forms, based on information provided or steps you take throughout the process.`}
        </Typography>
      </Box>
      {/* We need to use the packet for the below. */}
      {getEstateFormsInfoCard()}
      <FormsList
        forms={estateForms}
        estate={estate}
        truncateFormsAt={truncateFormsAt}
        emptyState={
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              gap: 4,
              padding: 4,
            }}
          >
            <Typography
              variant="body2"
              sx={{
                fontWeight: "800",
                width: "17rem",
                textAlign: "center",
              }}
            >
              No forms added yet. As you add forms, they will appear here.
            </Typography>
            {onEmptyButtonClick && (
              <Button variant="outlined" onClick={onEmptyButtonClick}>
                Add forms
              </Button>
            )}
          </Box>
        }
      />
      {truncateFormsAt && estateForms && estateForms.length > 0 && (
        <Box
          sx={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Button
            variant="outlined"
            endIcon={<ArrowForwardIcon />}
            onClick={() => {
              navigate(`estate-forms`);
            }}
          >
            {truncateFormsAt && estateForms.length > truncateFormsAt
              ? `View All ${estateForms.length} Estate Forms`
              : `View All Estate Forms`}
          </Button>
        </Box>
      )}
    </Box>
  );
};

const mapStateToProps = ({
  estateForms,
  states,
}: StoreState): {
  estateForms: AtticusForm[];
  states: State[];
} => {
  return {
    estateForms,
    states,
  };
};

const EstateFormsComponent = connect(mapStateToProps, {
  fetchEstateForms,
})(_EstateFormsComponent);

export default EstateFormsComponent;
