import React, { useEffect, useState } from "react";
import PerfectScrollbar from "react-perfect-scrollbar";
import {
  Box,
  Card,
  CardHeader,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";
import { makeStyles } from "tss-react/mui";
import CancelIcon from "@mui/icons-material/Cancel";
import { AppTableWrapper } from "../../shared/AppTableWrapper";
import { Advisor } from "../../../models/Advisor";
import { TableBodyLoader } from "../../shared/TableBodyLoader";
import { ZeroStateBody } from "../../shared/ZeroStateBody";
import { StoreState } from "../../../reducers";
import { connect } from "react-redux";
import {
  fetchAdvisors,
  unassignFromAdvisor,
} from "../../../actions/advisorActions";
import { AdvisorGroup } from "../../../models/AdvisorGroup";
import { advisorApi, authApi, logger } from "../../../apis";
import { AssignAdvisorGroupForm } from "../AssignAdvisorGroupForm";
import { AssignAdvisorRoleForm } from "./AssignAdvisorRoleForm";
import { User } from "../../../models/User";
import { AllAdvisorsFilter } from "./AllAdvisorsFilter";
import { ConfirmationDialog } from "../../shared/dialog/ConfirmationDialog";

const useStyles = makeStyles()(() => ({
  root: {},
  pagination: {
    "& .MuiTablePagination-spacer": {
      display: "none",
    },
  },
}));

interface Props {
  user: User;
  advisors: Advisor[];
  fetchAdvisors: () => void;
  advisorGroups: AdvisorGroup[];
}

enum AssignType {
  Group,
  Role,
}

export const _AllAdvisorsTable = ({
  user,
  advisors,
  fetchAdvisors,
  advisorGroups,
}: Props): JSX.Element => {
  const { classes } = useStyles();
  const perPage = 10;
  const [page, setPage] = useState(0);
  const [terms, setTerms] = useState<string | undefined>(undefined);
  const [groupIdFilter, setGroupIdFilter] = useState<number | undefined>(
    undefined
  );
  const [roleIdFilter, setRoleIdFilter] = useState<number | undefined>(
    undefined
  );
  const [isDeleteConfirmOpen, setIsDeleteConfirmOpen] = useState(false);
  const [deleteAdvisorId, setDeleteAdvisorId] = useState<number>();
  const [deleteAdvisorName, setDeleteAdvisorName] = useState<string>();

  useEffect(() => {
    fetchAdvisors();
  }, []);

  const handleAssign = async (
    assign: AssignType,
    laravelUserId?: number,
    cognitoUsername?: string,
    id?: number,
    name?: string
  ): Promise<boolean> => {
    if (laravelUserId && cognitoUsername && id) {
      try {
        const cognitoAssign = await authApi.updateAdvisorGroup(
          cognitoUsername,
          name
        );
        let body;
        if (assign === AssignType.Group) {
          body = { advisor_group_id: id };
        } else {
          body = { role_id: id };
        }
        const laravelAssign = await advisorApi.updateAdvisorAccess(
          laravelUserId,
          body
        );
        return cognitoAssign && laravelAssign;
      } catch (error) {
        logger.error(error as Error);
        return false;
      }
    }
    return false;
  };

  const handleDelete = async (advisorId?: number): Promise<void> => {
    if (advisorId) {
      try {
        // NOTE: only soft deleting in mysql database, the cognito record will remain
        //  to hard-delete you'll need to manually delete from both cognito and mysql users table
        if (await advisorApi.deleteAdvisor(advisorId)) {
          setDeleteAdvisorId(undefined);
          setDeleteAdvisorName("Success! ✌️");
          fetchAdvisors();
          window.setTimeout(() => {
            setDeleteAdvisorName("");
            setIsDeleteConfirmOpen(false);
          }, 1000);
        }
      } catch (error) {
        logger.error(error as Error);
      }
    }
  };

  const handleFilter = (groupId?: number, roleId?: number) => {
    setPage(0);
    setGroupIdFilter(groupId);
    setRoleIdFilter(roleId);
  };

  const handleSearch = (terms?: string) => {
    setPage(0);
    setTerms(terms);
  };

  const handlePageChange = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    page: number
  ) => {
    setPage(page);
  };

  const loading = advisors === null;

  let filteredAdvisors = advisors;

  if (groupIdFilter || roleIdFilter) {
    filteredAdvisors = groupIdFilter
      ? advisors?.filter(
          (advisor: Advisor) => advisor?.user.advisorGroupId === groupIdFilter
        )
      : advisors;
    filteredAdvisors = roleIdFilter
      ? filteredAdvisors?.filter(
          (advisor: Advisor) => advisor?.user.roleId === roleIdFilter
        )
      : filteredAdvisors;
  } else {
    filteredAdvisors =
      (terms !== undefined &&
        advisors?.filter((advisor: Advisor) =>
          advisor?.user.fullName
            ?.toLocaleLowerCase()
            ?.includes(terms.toLowerCase())
        )) ||
      advisors;
  }

  const paginatedAdvisors = filteredAdvisors?.slice(
    page * perPage,
    page * perPage + perPage
  );

  const showZero = filteredAdvisors !== null && filteredAdvisors?.length === 0;
  return (
    <>
      <AllAdvisorsFilter onFilter={handleFilter} onSearch={handleSearch} />
      <Card>
        <CardHeader
          title="All Advisors"
          subheader="🦉 Advisors will need to sign out for group changes to take effect.  Browser refresh isn't enough."
        />
        <PerfectScrollbar>
          <AppTableWrapper minWidth={!showZero ? 800 : 1}>
            {showZero ? (
              <ZeroStateBody text="No advisors returned" />
            ) : (
              <>
                <ConfirmationDialog
                  open={isDeleteConfirmOpen}
                  description={deleteAdvisorName || ""}
                  question="Are you sure you want to delete (soft) this advisor?"
                  onConfirm={() => handleDelete(deleteAdvisorId)}
                  onDeny={() => {
                    setDeleteAdvisorId(undefined);
                    setDeleteAdvisorName("");
                    setIsDeleteConfirmOpen(false);
                  }}
                />
                <Table>
                  <TableHead>
                    <TableRow className="dense">
                      <TableCell width="40%">Advisor Name</TableCell>
                      <TableCell width="30%">Group</TableCell>
                      <TableCell width="25%">Role</TableCell>
                      <TableCell width="5%" />
                    </TableRow>
                  </TableHead>
                  {loading ? (
                    <TableBodyLoader
                      colSpan={4}
                      rows={3}
                      title="Loading advisors..."
                    />
                  ) : (
                    <TableBody>
                      {paginatedAdvisors &&
                        paginatedAdvisors.map((advisor: Advisor) => {
                          const advisorGroup = advisorApi.getAdvisorGroupById(
                            advisor?.user?.advisorGroupId,
                            advisorGroups
                          );
                          return advisor.user ? (
                            <TableRow
                              key={
                                advisor.user.cognitoUsername +
                                "_" +
                                Math.floor(Math.random() * 1000)
                              }
                              className="dense"
                            >
                              <TableCell>
                                <Box alignItems="center" display="flex">
                                  <Typography
                                    color="textPrimary"
                                    variant="body1"
                                  >
                                    {advisor.user.fullName ? (
                                      <Tooltip title={advisor.user.email || ""}>
                                        <span style={{ cursor: "default" }}>
                                          {advisor.user.fullName}
                                        </span>
                                      </Tooltip>
                                    ) : (
                                      <span style={{ cursor: "default" }}>
                                        {advisor.user.email}
                                      </span>
                                    )}
                                  </Typography>
                                </Box>
                              </TableCell>
                              <TableCell>
                                <AssignAdvisorGroupForm
                                  advisorGroupId={advisorGroup?.id}
                                  onAssign={async (
                                    newId: number,
                                    newName: string
                                  ) => {
                                    const result = await handleAssign(
                                      AssignType.Group,
                                      advisor.user.id,
                                      advisor.user.cognitoUsername,
                                      newId,
                                      newName
                                    );
                                    if (
                                      result &&
                                      advisor.user.cognitoUsername ===
                                        user.cognitoUsername
                                    ) {
                                      user.advisorGroupId = newId;
                                      user.advisorGroupName = newName;
                                    }
                                    return result;
                                  }}
                                  showEstateGroupConfirm={false}
                                />
                              </TableCell>
                              <TableCell>
                                <AssignAdvisorRoleForm
                                  roleId={advisor.user?.roleId}
                                  onAssign={async (
                                    newRoleId: number,
                                    newRoleName: string
                                  ) => {
                                    return await handleAssign(
                                      AssignType.Role,
                                      advisor.user.id,
                                      advisor.user.cognitoUsername,
                                      newRoleId,
                                      newRoleName
                                    );
                                  }}
                                />
                              </TableCell>
                              <TableCell>
                                <IconButton
                                  aria-label="delete"
                                  onClick={() => {
                                    // show confirm delete
                                    setDeleteAdvisorName(
                                      advisor.user.fullName || ""
                                    );
                                    setDeleteAdvisorId(advisor.user.id);
                                    setIsDeleteConfirmOpen(true);
                                  }}
                                  color="primary"
                                >
                                  <CancelIcon />
                                </IconButton>
                              </TableCell>
                            </TableRow>
                          ) : null;
                        })}
                      {filteredAdvisors && (
                        <TableRow className="no-background">
                          <TablePagination
                            count={filteredAdvisors.length}
                            onPageChange={handlePageChange}
                            page={page}
                            rowsPerPage={perPage}
                            rowsPerPageOptions={[]}
                            className={classes.pagination}
                          />
                        </TableRow>
                      )}
                    </TableBody>
                  )}
                </Table>
              </>
            )}
          </AppTableWrapper>
        </PerfectScrollbar>
      </Card>
    </>
  );
};

const mapStateToProps = ({ advisors }: StoreState): { advisors: Advisor[] } => {
  return { advisors };
};

export const AllAdvisorsTable = connect(mapStateToProps, {
  fetchAdvisors,
  unassignFromAdvisor,
})(_AllAdvisorsTable);
