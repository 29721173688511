import React, { useState, useEffect } from "react";
import { Box, Card, CardContent, CardHeader } from "@mui/material";
import { formsApi, logger } from "../../../apis";
import { AtticusForm } from "../../../models/AtticusForm";
import { AtticusFormsTable } from "./AtticusFormsTable";
import { ImportFormsByKeys } from "./ImportFormsByKeys";
import { ImportFormsByFolders } from "./ImportFormsByFolders";
import { AtticusFormsTableSettings } from "./AtticusFormsTableSettings";
import { QueryPayload } from "../../../apis/AtticusForms";
import { AxiosError } from "axios";
import { GeographyType } from "../../../models/Geography";
import { FormType } from "../../../models/FormType";
import { Language } from "../../../models/Language";
import { connect } from "react-redux";
import { StoreState } from "../../../reducers";
import { fetchFormTypes } from "../../../actions/formTypeActions";
import { fetchLanguages } from "../../../actions/languageActions";

export enum ImportType {
  files = "files",
  folders = "folders",
}

interface Props {
  formTypes?: FormType[];
  fetchFormTypes: () => void;
  languages?: Language[];
  fetchLanguages: () => void;
}

const _AtticusFormsView = ({
  formTypes,
  fetchFormTypes,
  languages,
  fetchLanguages,
}: Props): JSX.Element => {
  useEffect(() => {
    (async () => {
      if (!formTypes || !formTypes?.length) {
        fetchFormTypes();
      }
      if (!languages || !languages?.length) {
        fetchLanguages();
      }
    })();
  }, []);

  const [forms, setForms] = useState<AtticusForm[]>();
  const [loadingForms, setLoadingForms] = useState(false);
  const [formQuery, setFormQuery] = useState<QueryPayload>();

  const getForms = async (queryPayload: QueryPayload) => {
    setLoadingForms(true);
    setFormQuery(queryPayload);
    const newForms = await formsApi.fetchForms(queryPayload);
    if (newForms) {
      setForms(newForms);
    }
    setLoadingForms(false);
  };

  const refreshForms = async () => {
    if (formQuery) {
      getForms(formQuery);
    }
  };

  const handleImport = async (
    importType: ImportType,
    keys: string[],
    logoLocation?: string
  ): Promise<any> => {
    try {
      let response: any;
      switch (importType) {
        case ImportType.files:
          response = await formsApi.importFormsByFileKeys(keys, logoLocation);
          break;
        case ImportType.folders:
          response = await formsApi.importFormsByFolderKeys(keys, logoLocation);
          break;
      }
      return response;
    } catch (error: any) {
      logger.error(error);
      if (error?.response?.data?.error) {
        throw Error("error: " + error?.response?.data?.error);
      } else {
        throw Error((error as any)?.message);
      }
    }
  };

  return (
    <div data-automation-id="page-content-forms">
      <Card>
        <CardHeader title="Atticus Forms" />
        <CardContent sx={{ paddingBottom: "1rem !IMPORTANT" }}>
          <Box display="flex" alignItems="center" sx={{ marginBottom: "1rem" }}>
            <AtticusFormsTableSettings
              onComplete={getForms}
              loadingForms={loadingForms}
              initGeographyId={1}
              initGeographyType={GeographyType.Country}
              initLanguageCode={"en"}
              initFormTypeSlug={"probate"}
            />
          </Box>
          <Box>
            <AtticusFormsTable
              atticusForms={forms}
              loadingForms={loadingForms}
              // onImport={handleImport}
              onRefresh={refreshForms}
            />
          </Box>
        </CardContent>
      </Card>
      <ImportFormsByFolders onImport={handleImport} />
      <ImportFormsByKeys onImport={handleImport} />
    </div>
  );
};

const mapStateToProps = ({
  formTypes,
  languages,
}: StoreState): {
  formTypes: FormType[];
  languages: Language[];
} => {
  return {
    formTypes,
    languages,
  };
};

export const AtticusFormsView = connect(mapStateToProps, {
  fetchFormTypes,
  fetchLanguages,
})(_AtticusFormsView);
