import React from "react";
import merge from "lodash/merge";
import {
  BasicConfig, BasicFuncs,
  // types:
  Settings, Operators, Widgets, Fields, Config, Types, Conjunctions, LocaleSettings, OperatorProximity, Funcs,
} from "@react-awesome-query-builder/mui";

export function createConfig(InitialConfig: BasicConfig): Config {
  const fields: Fields = {
    deceased_date: {
      label: "Deceased Date",
      type: "date",
    },
    "days_since_passing": {
      label: "Days passed since date of death",
      type: "number",
    },
    gross_value_as_of_date_of_passing: {
      label: "Gross value as of date of passing",
      type: "number",
      preferWidgets: ["number"],
    },
    has_real_estate: {
      label: "Has real estate",
      type: "boolean",
    },
    has_will: {
      label: "Has will",
      type: "boolean",
    },
    is_executor_named_in_will: {
      label: "Is executor named in will",
      type: "boolean",
    },
    is_executor_named_and_ready_and_willing_to_serve: {
      label: "Is named executor ready and willing to serve",
      type: "boolean"
    },
    will_left_real_property_to_heirs_other_than_spouse_or_partner: {
      label: "Will left real property to heir other than spouse or partner",
      type: "boolean",
    },
    surviving_spouse_is_sole_heir: {
      label: "Surviving spouse is sole heir",
      type: "boolean",
    },
    has_filed_petition_for_probate: {
      label: "Has filed petition for probate",
      type: "boolean",
    },
    has_surviving_spouse: {
      label: "Has surviving spouse",
      type: "boolean",
    },
    estate_debts_paid: {
      label: "Estate debts are paid",
      type: "boolean",
    },
    heirs_agree_to_division_of_estate: {
      label: "Heirs agree to division of estate",
      type: "boolean",
    },
    all_heirs_known_and_present: {
      label: "All heirs known and present",
      type: "boolean",
    },
    has_filed_application_for_appoint_personal_rep: {
      label: "Has filed application for appointment of personal representative",
      type: "boolean",
    },
    has_surviving_other_direct_family: {
      label: "Has other surviving direct family",
      type: "boolean",
    },
    year_allowance_application_approved: {
      label: "Year allowance application approved",
      type: "boolean",
    },
    year_allowance_approved_paid_amount: {
      label: "Year allowance approved paid amount",
      type: "number",
    },
    has_remaining_personal_property: {
      label: "Has remaining personal property",
      type: "boolean",
    },
    relationship_to_deceased: {
      label: "Relationship to deceased",
      type: "select",
      valueSources: ["value"],
      fieldSettings: {
        listValues: [
          { value: "spouse", title: "Spouse" },
          { value: "grandparent", title: "Grandparent" },
          { value: "parent", title: "Parent" },
          { value: "sibling", title: "Sibling" },
          { value: "child", title: "Child" },
          { value: "grandchild", title: "Grandchild" },
        ],
      }
    },
    has_real_property_in_estate: {
      label: "Has real property in estate",
      type: "boolean",
    },
    personal_property_value: {
      label: "Personal property value",
      type: "number",
    },
  };
  
  
  //////////////////////////////////////////////////////////////////////
  
  const conjunctions: Conjunctions = {
    AND: InitialConfig.conjunctions.AND,
    OR: InitialConfig.conjunctions.OR,
  };
  
  
  const proximity: OperatorProximity = {
    ...InitialConfig.operators.proximity,
    valueLabels: [
      { label: "Word 1", placeholder: "Enter first word" },
      { label: "Word 2", placeholder: "Enter second word" },
    ],
    textSeparators: [
      //'Word 1',
      //'Word 2'
    ],
    options: {
      ...InitialConfig.operators.proximity.options,
      optionLabel: "Near", // label on top of "near" selectbox (for config.settings.showLabels==true)
      optionTextBefore: "Near", // label before "near" selectbox (for config.settings.showLabels==false)
      optionPlaceholder: "Select words between", // placeholder for "near" selectbox
      minProximity: 2,
      maxProximity: 10,
      defaults: {
        proximity: 2
      },
      customProps: {}
    }
  };
  
  const operators: Operators = {
    ...InitialConfig.operators,
    // examples of  overriding
    between: {
      ...InitialConfig.operators.between,
      valueLabels: [
        "Value from",
        "Value to"
      ],
      textSeparators: [
        "from",
        "to"
      ],
    },
    proximity,
  };
  
  const widgets: Widgets = {
    ...InitialConfig.widgets,
    // examples of  overriding
    text: {
      ...InitialConfig.widgets.text,
    },
    slider: {
      ...InitialConfig.widgets.slider,
      customProps: {
        width: "300px"
      }
    },
    rangeslider: {
      ...InitialConfig.widgets.rangeslider,
      customProps: {
        width: "300px"
      }
    },
    date: {
      ...InitialConfig.widgets.date,
      valueFormat: "YYYY-MM-DD",
    },
    time: {
      ...InitialConfig.widgets.time,
      timeFormat: "HH:mm",
      valueFormat: "HH:mm:ss",
    },
    datetime: {
      ...InitialConfig.widgets.datetime,
      timeFormat: "HH:mm",
      valueFormat: "YYYY-MM-DD HH:mm:ss",
    },
    func: {
      ...InitialConfig.widgets.func,
      customProps: {
        showSearch: true
      }
    },
    treeselect: {
      ...InitialConfig.widgets.treeselect,
      customProps: {
        showSearch: true
      }
    },
  };
  
  
  const types: Types = {
    ...InitialConfig.types,
    // examples of  overriding
    boolean: merge({}, InitialConfig.types.boolean, {
      widgets: {
        boolean: {
          widgetProps: {
            hideOperator: true,
            operatorInlineLabel: "is",
          }
        },
      },
    }),
  };
  
  
  const localeSettings: LocaleSettings = {
    locale: {
      moment: "en-US",
    },
    valueLabel: "Value",
    valuePlaceholder: "Value",
    fieldLabel: "Field",
    operatorLabel: "Operator",
    fieldPlaceholder: "Select field",
    operatorPlaceholder: "Select operator",
    deleteLabel: undefined,
    addGroupLabel: "Add group",
    addRuleLabel: "Add rule",
    addSubRuleLabel: "Add sub rule",
    delGroupLabel: undefined,
    notLabel: "Not",
    fieldSourcesPopupTitle: "Select source",
    valueSourcesPopupTitle: "Select value source",
    removeRuleConfirmOptions: {
      title: "Are you sure delete this rule?",
      okText: "Yes",
      okType: "danger",
    },
    removeGroupConfirmOptions: {
      title: "Are you sure delete this group?",
      okText: "Yes",
      okType: "danger",
    },
  };
  
  const settings: Settings = {
    ...InitialConfig.settings,
    ...localeSettings,
  
    valueSourcesInfo: {
      value: {
        label: "Value"
      },
      field: {
        label: "Field",
        widget: "field",
      },
      func: {
        label: "Function",
        widget: "func",
      }
    },
    // canReorder: false,
    canRegroup: true,
    // showNot: false,
    // showLabels: true,
    maxNesting: 50,
    canLeaveEmptyGroup: true, //after deletion
  };
  
  
  const funcs: Funcs = {
    LINEAR_REGRESSION: BasicFuncs.LINEAR_REGRESSION,
    LOWER: BasicFuncs.LOWER,
  };
  
  const ctx = InitialConfig.ctx;
  
  const config: Config = {
    ctx,
    conjunctions,
    operators,
    widgets,
    types,
    settings,
    fields,
    funcs
  };

  return config;
  
}
