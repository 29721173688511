import React, { useEffect } from "react";
import { Container, Theme } from "@mui/material";
import { makeStyles } from "tss-react/mui";
import { connect } from "react-redux";
import Page from "../../components/Page";
import ContentHeader from "../shared/ContentHeader";
import { StoreState } from "../../reducers";
import { ATTICUS_PAGE_PREFIX } from "../../constants";
import { User } from "../../models/User";
import { Outlet, useNavigate } from "react-router-dom";
import { SubNavBar } from "../shared/SubNavBar";
import { adminNavItems } from "./adminNavItems";

const useStyles = makeStyles()((theme: Theme) => ({
  root: {
    backgroundColor: theme.palette.background.default,
    minHeight: "100%",
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
}));

interface Props {
  user: User;
}

const _AdminView = ({ user }: Props) => {
  const { classes } = useStyles();
  const navigate = useNavigate();

  const hasAccess = user?.hasAdminToolAccess;

  if (!hasAccess) {
    window.setTimeout(() => {
      navigate(`/app/dashboard`);
    }, 0);
  }

  useEffect(() => {
    // fetchStates();
  }, []);

  return (
    <Page
      className={classes.root}
      title={`${ATTICUS_PAGE_PREFIX} - Admin`}
      data-automation-id="page-content-admin"
    >
      {hasAccess ? (
        <Container maxWidth={false}>
          <ContentHeader heading="Admin" showSearch={false} />
          <SubNavBar navItems={adminNavItems} />
          <Outlet />
        </Container>
      ) : (
        <></>
      )}
    </Page>
  );
};

const mapStateToProps = ({ user }: StoreState): { user: User } => {
  return { user };
};

export const AdminView = connect(mapStateToProps, {})(_AdminView);
