import React from "react";
import { Box, CircularProgress, Theme } from "@mui/material";
import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()((theme: Theme) => ({
  root: {
    height: "100%",
    width: "100%",
    display: "flex",
    flexDirection: "column",
  },
  mainContainer: {
    flex: "1",
    backgroundColor: theme.palette.background.paper,
  },
  loadingContainer: {
    flex: 1,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    height: "100%",
  },
  iframe: {
    height: "100%",
    width: "100%",
  },
}));

interface Props {
  formUrl?: string;
}

export const PdfViewer = ({ formUrl }: Props): JSX.Element => {
  const { classes } = useStyles();

  return (
    <>
      <Box className={classes.root}>
        <Box className={classes.mainContainer}>
          {formUrl ? (
            <iframe className={classes.iframe} src={formUrl} />
          ) : (
            <Box className={classes.loadingContainer}>
              <CircularProgress />
            </Box>
          )}
        </Box>
      </Box>
    </>
  );
};
