import { InputType } from "./inputs/InputTypes";
import { CategoryAttributes } from "../../../../../models/Asset";

const isProbateAttr = {
  label: "Probate",
  key: "is_probate",
  caption: undefined,
  placeholder: undefined,
  type: InputType.select,
  regex: undefined,
  value: "Probate",
  options: [
    {
      label: "Probate",
      value: "true", // NOTE: will be translated to a boolean before sent to backend
    },
    {
      label: "Not Probate",
      value: "false", // NOTE: will be translated to a boolean before sent to backend
    },
    {
      label: "I don't know",
      value: "null", // NOTE: will be translated to null before sent to backend
    },
  ],
  helpContent: {
    label: "How do I know if it’s probate or not?",
    href: "https://www.weareatticus.com/probate/what-is-probate",
    isButton: false,
  },
};

const descriptionAttr = {
  label: "Notes",
  key: "description",
  placeholder: undefined,
  type: InputType.text,
  maxLength: 190,
  rows: 3,
  value: null,
};

// hard-coded field details to match legacy asset database
export const attributesByCategory: CategoryAttributes[] = [
  {
    id: undefined,
    categoryName: "Household",
    comment:
      "groups in field_groups should be returned to the FE in the order to be displayed",
    groups: [
      {
        group: "basic",
        attributes: [
          {
            label: "Name",
            key: "name",
            // caption: "Name of the home or residence",
            type: InputType.text,
            regex: "",
            value: null,
          },
          isProbateAttr,
        ],
      },
      {
        group: "value",
        attributes: [
          {
            label: "Quantity",
            key: "quantity",
            // caption: "This is the estimated current value of the home",
            placeholder: undefined,
            type: InputType.number,
            value: null,
          },
          {
            label: "Value",
            key: "value",
            caption: "This is the estimated current value",
            placeholder: undefined,
            type: InputType.usCurrency,
            value: null,
          },
        ],
      },
      {
        group: "other",
        attributes: [descriptionAttr],
      },
    ],
  },

  {
    id: undefined,
    categoryName: "Financial",
    groups: [
      {
        group: "basic",
        attributes: [
          {
            label: "Account Name",
            key: "name",
            // caption: "Name of the home or residence",
            type: InputType.text,
            regex: "",
            value: null,
          },
          {
            label: "Bank/Institution name",
            key: "bank_institution",
            // caption: "Name of the home or residence",
            type: InputType.text,
            regex: "",
            value: null,
          },
          isProbateAttr,
        ],
      },
      {
        group: "value",
        attributes: [
          {
            label: "Value",
            key: "value",
            caption: "This is the estimated current value",
            placeholder: undefined,
            type: InputType.usCurrency,
            value: null,
          },
        ],
      },
      {
        group: "other",
        attributes: [descriptionAttr],
      },
    ],
  },

  {
    id: undefined,
    categoryName: "Real Estate",
    groups: [
      {
        group: "basic",
        attributes: [
          {
            label: "Name",
            key: "name",
            caption: "Name of the home or residence",
            type: InputType.text,
            regex: null,
            value: null,
          },
          {
            label: "Address line 1",
            key: "address1",
            type: InputType.text,
            maxLength: 500,
            regex: null,
            value: null,
          },
          {
            label: "Address line 2",
            key: "address2",
            type: InputType.text,
            maxLength: 191,
            regex: null,
            value: null,
          },
          {
            label: "City",
            key: "city",
            type: InputType.text,
            maxLength: 500,
            regex: null,
            value: null,
          },
          {
            label: "State",
            key: "states_id",
            type: InputType.select,
            // maxLength: 500,
            // options: undefined, // these will be injected at run-time
            regex: null,
            value: null,
          },
          {
            label: "Zip code",
            key: "zip_code",
            type: InputType.text,
            // maxLength: 500,
            regex: null,
            value: null,
          },
          // {
          //   label: "Title & ownership",
          //   key: "ownership",
          //   caption: "Address of the home or residence",
          //   type: InputType.text,
          //   regex: null,
          //   value: null,
          //   helpContent: {
          //     label:
          //       "Get help determining the titling—this impacts your probate forms",
          //     href: "http://asdfasdf",
          //     isButton: false,
          //   },
          // },
          {
            label: "APN",
            key: "assessor_parcel_number",
            caption:
              "An Assessor Parcel Number is a 14-digit number used to identify property",
            type: InputType.number,
            regex: null,
            value: null,
          },
          isProbateAttr,
        ],
      },
      {
        group: "value",
        attributes: [
          {
            label: "Fair market value",
            key: "value",
            caption: "This is the estimated current value of the home",
            placeholder: undefined,
            type: InputType.usCurrency,
            value: null,
          },
          // {
          //   label: "Zestimate",
          //   key: "zestimate",
          //   caption: "Get an estimate on your home by Zillow",
          //   placeholder: undefined,
          //   type: InputType.static,
          //   helpContent: {
          //     label: "Get the Zestimate",
          //     href: "http://zillow.com/23141234",
          //     isButton: true,
          //   },
          //   value: null,
          // },
          // {
          //   label: "Remaining mortgage",
          //   key: "mortgage",
          //   caption: "This is the estimated remaining mortgage of the home",
          //   placeholder: undefined,
          //   type: InputType.usCurrency,
          //   value: null,
          // },
          // {
          //   label: "Date of death value",
          //   key: "dod",
          //   caption: "This is the estimated value of the asset/{home} on {DOD}",
          //   placeholder: undefined,
          //   type: InputType.usCurrency,
          //   value: null,
          // },
          // {
          //   label: "Cost basis",
          //   key: "cost_basis",
          //   caption:
          //     "This is the estimated value of the asset/{home} when purchased or acquired",
          //   placeholder: undefined,
          //   type: InputType.usCurrency,
          //   value: null,
          // },
          // {
          //   label: "Unrealized (gain/loss)",
          //   key: "gain_loss",
          //   caption: "This is an estimate of the unrealized {gain/loss} ",
          //   placeholder: undefined,
          //   type: InputType.usCurrency,
          //   value: null,
          // },
        ],
      },
      {
        group: "other",
        attributes: [descriptionAttr],
      },
    ],
  },
  {
    id: undefined,
    categoryName: "Vehicles",
    groups: [
      {
        group: "basic",
        attributes: [
          {
            label: "Name",
            key: "name",
            caption: "Name of the vehicle",
            type: InputType.text,
            maxLength: 190,
            regex: "",
            value: null,
          },
          {
            label: "Year",
            key: "year",
            // caption: "Name of the vehicle",
            type: InputType.number,
            // maxLength: 190,
            regex: "", // 1900 - this year
            value: null,
            noCommas: true,
          },
          {
            label: "Make",
            key: "make",
            // caption: "Name of the vehicle",
            type: InputType.text,
            maxLength: 120,
            regex: "",
            value: null,
          },
          {
            label: "Model",
            key: "model",
            // caption: "Name of the vehicle",
            type: InputType.text,
            maxLength: 120,
            regex: "",
            value: null,
          },
          {
            label: "VIN",
            key: "vin",
            // caption: "Name of the vehicle",
            type: InputType.text,
            maxLength: 120,
            regex: "",
            value: null,
          },
          isProbateAttr,
        ],
      },
      {
        group: "value",
        attributes: [
          {
            label: "Value",
            key: "value",
            caption: "This is the estimated current value",
            placeholder: undefined,
            type: InputType.usCurrency,
            value: null,
          },
        ],
      },
      {
        group: "other",
        attributes: [descriptionAttr],
      },
    ],
  },
  {
    id: undefined,
    categoryName: "Digital",
    groups: [
      {
        group: "basic",
        attributes: [
          {
            label: "Name",
            key: "name",
            // caption: "Name of the home or residence",
            type: InputType.text,
            maxLength: 190,
            regex: "",
            value: null,
          },
          {
            label: "URL/web address",
            key: "web_address",
            // caption: "Name of the home or residence",
            type: InputType.text,
            maxLength: 250,
            regex: "",
            value: null,
          },
          isProbateAttr,
        ],
      },
      {
        group: "value",
        attributes: [
          {
            label: "Value",
            key: "value",
            caption: "This is the estimated current value",
            placeholder: undefined,
            type: InputType.usCurrency,
            value: null,
          },
        ],
      },
      {
        group: "other",
        attributes: [descriptionAttr],
      },
    ],
  },
  {
    id: undefined,
    categoryName: "Other",
    groups: [
      {
        group: "basic",
        attributes: [
          {
            label: "Name",
            key: "name",
            type: InputType.text,
            maxLength: 190,
            regex: "",
            value: null,
          },
          isProbateAttr,
        ],
      },
      {
        group: "value",
        attributes: [
          {
            label: "Value",
            key: "value",
            caption: "This is the estimated current value",
            placeholder: undefined,
            type: InputType.usCurrency,
            value: null,
          },
        ],
      },
      {
        group: "other",
        attributes: [descriptionAttr],
      },
    ],
  },
];
