const oneM = 1000000;
const tenT = 10000;
const oneT = 1000;
export const displayValue = (value?: number, isMonetary = false): string => {
  let displayValue = "";
  if (value) {
    const isNegative = value < 0;
    const val = Math.abs(value);
    if (val >= oneM) {
      const roundedVal = Math.round(val / tenT) * tenT;
      displayValue = roundedVal / oneM + "M";
    } else if (val >= oneT) {
      displayValue = val / oneT + "K";
    } else {
      displayValue = val.toString();
    }
    if (isMonetary) {
      displayValue = "$" + displayValue;
    }
    if (isNegative) {
      displayValue = "-" + displayValue;
    }
  }
  return displayValue;
};
