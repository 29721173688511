import React, { useState } from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  Container,
  Grid,
  Theme,
  Typography,
} from "@mui/material";
import { makeStyles } from "tss-react/mui";
import Page from "../../../components/Page";
import ContentHeader from "../../shared/ContentHeader";
import { StoreState } from "../../../reducers";
import { Role, User } from "../../../models/User";
import { connect } from "react-redux";
import { InviteClientForm } from "./InviteClientForm";
import {
  ATTICUS_PAGE_PREFIX,
  CONSUMER_ACCOUNT_DOMAIN,
  CONSUMER_ACCOUNT_PREFIX,
  InviteTypes,
  isLocal,
  atticusWebUrl,
} from "../../../constants";
import { authApi, inviteApi, logger } from "../../../apis";
import { isProd } from "../../../constants"
import { Invite } from "../../../models/Invite";
import { ResponsiveCards } from "../../shared/ResponsiveCards";
import IFrameDialog from "../../shared/IFrameDialog";
import { PreProdOnlyWrapper } from "../../shared/PreProdOnlyWrapper";
import { CustomerOrigin } from "@theatticusapp/atticus-shared-components";

const useStyles = makeStyles()((theme: Theme) => ({
  root: {
    backgroundColor: theme.palette.background.default,
    minHeight: "100%",
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
  button: {
    margin: theme.spacing(1),
  },
}));

interface Props {
  user: User;
}

const _InviteClientView = ({ user }: Props): JSX.Element => {
  const { classes } = useStyles();
  const [showInviteLink, setShowInviteLink] = useState(false);
  // NOTE: Because local env and preprod env use the same cognito, but different
  //  databases, client invite can appear broken.  The estate may not be assigned
  //  to the correct advisor during signup.
  //  Solution: don't use the pre-created inviteId stored in cognito (user?.advisorInviteId)
  //  in non-prod.  Rather: create a new on each time.
  const initInviteId = isProd ? user?.advisorInviteId : undefined;
  const [inviteId, setInviteId] = useState(initInviteId || "");
  const [signUpInviteProcessing, setInitiateSignUpProcessing] = useState(false);

  const [consumerAppUrl, setConsumerAppUrl] = useState<string>();

  const createReusableInvite = async () => {
    if (!showInviteLink) {
      setShowInviteLink(true);
      const invite = new Invite(
        InviteTypes.EndUser,
        undefined,
        Role["Primary_End-User"]
      );
      invite.advisorGroup = user.advisorGroupName;
      invite.advisorId = user.id; // will assign this estate to this advisor automatically
      const inviteId = await inviteApi.createInvite(invite);
      setInviteId(inviteId || "");
    }
  };

  const getEmailPrefix = (group?: string) => {
    let emailPrefix;
    if (group && group in CONSUMER_ACCOUNT_PREFIX) {
      emailPrefix = `${CONSUMER_ACCOUNT_PREFIX[group]}+Estate`;
    } else {
      emailPrefix = `${CONSUMER_ACCOUNT_PREFIX["default"]}+${
        group ? group : ""
      }`;
    }
    return emailPrefix;
  };

  const getEmailDomain = (group?: string): string => {
    return group && group in CONSUMER_ACCOUNT_DOMAIN
      ? CONSUMER_ACCOUNT_DOMAIN[group]
      : CONSUMER_ACCOUNT_DOMAIN["default"] || "weareatticus.biz";
  };

  const initiateSignUp = async () => {
    setInitiateSignUpProcessing(true);
    const invite = new Invite(
      InviteTypes.EndUser,
      undefined,
      Role["Primary_End-User"]
    );
    invite.advisorGroup = user.advisorGroupName;
    invite.advisorId = user.id; // will assign this estate to this advisor automatically
    const inviteId = await inviteApi.createInvite(invite);
    if (inviteId) {
      const group = user?.advisorGroupName?.replace("AG-", "");
      const prefix = getEmailPrefix(group);
      const domain = getEmailDomain(group);
      const emailToPrePopulate = `${prefix}-${inviteId}@${domain}`;
      // set iframe src || url to open incognito
      const url = getInviteUrl(inviteId) + `?email=${emailToPrePopulate}&incState=true&origin=${CustomerOrigin.AdvisorWebApp}`
      console.log("invite url is " + url)
      setConsumerAppUrl(url);
    }
    setInitiateSignUpProcessing(false);
  };

  const createEndUserInvite = async (): Promise<string | null> => {
    try {
      if (user && user?.id && user?.advisorGroupId) {
        const invite = new Invite(
          InviteTypes.EndUser,
          undefined,
          Role["Primary_End-User"],
          user.id,
          user.advisorGroupId
        );
        const newInviteId = await inviteApi.createInvite(invite);
        if (newInviteId && isProd) {
          // save to cognito profile
          await authApi.updateAttributes({
            "custom:advisor_invite_id": newInviteId,
          });
          // save to object in memory
          user.advisorInviteId = newInviteId;
        }
        return newInviteId;
      } else {
        throw Error(
          "missing required data: user, user.id, user.advisorGroupId"
        );
      }
    } catch (error) {
      logger.error(error as Error);
    }
    return null;
  };

  const getInviteUrl = (inviteId: string): string => {
    return inviteId ? `${atticusWebUrl}/invite/${inviteId}` : "";
  };

  return (
    <Page
      className={classes.root}
      title={`${ATTICUS_PAGE_PREFIX} - Invite`}
      data-automation-id="page-content-invite-client"
    >
      <Container maxWidth={false}>
        {/* disabled for localhost */}
        <IFrameDialog
          title="Create a Customer Account and Estate"
          src={!isLocal ? consumerAppUrl : undefined}
          onClose={() => setConsumerAppUrl(undefined)}
        />
        <ContentHeader
          heading="Invite a client"
          subHeading=""
          showSearch={false}
        />

        <Grid container spacing={2}>
          <Grid item xs={10}>
            <Card>
              <CardHeader title="Your Personalized Invitation" />
              <CardContent>
                <Typography>
                  When a new atticus client signs up they will automatically be
                  assigned to you and you will be able to see their activity.
                </Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={10}>
            <ResponsiveCards
              titleLeft="Invite by email"
              cardLeft={
                <InviteClientForm
                  inviteId={inviteId}
                  user={user}
                  createAdvisorInvite={createEndUserInvite}
                />
              }
              titleRight="Create a Customer Account and Estate"
              cardRight={
                <>
                  <Typography
                    sx={{
                      mb: 1,
                    }}
                  >
                    This will open the consumer application to initiate account
                    creation.
                  </Typography>
                  <Button
                    size="small"
                    variant="contained"
                    onClick={initiateSignUp}
                    disabled={signUpInviteProcessing}
                  >
                    Start account creation
                  </Button>
                  {signUpInviteProcessing && (
                    <Box sx={{ my: 1 }}>
                      <CircularProgress />
                    </Box>
                  )}
                  <PreProdOnlyWrapper onlyLocal={true}>
                    {/*  This is required because of domain authentication conflicts of running two apps on one localhost doamin */}
                    <Typography>
                      Click the button above and then open the generated url in
                      an incognito window.
                    </Typography>
                    <Typography>
                      {consumerAppUrl && (
                        <a href={consumerAppUrl} target="_blank">
                          {consumerAppUrl}
                        </a>
                      )}
                    </Typography>
                  </PreProdOnlyWrapper>
                </>
              }
              breakAt="lg"
            />
          </Grid>
          <Grid item xs={10}>
            <Card>
              <CardHeader title="Invite by website address.  Send anyway you choose." />
              <CardContent>
                {showInviteLink ? (
                  <Box>
                    <Typography variant="h4" sx={{ mb: 1 }}>
                      Your Reusable Personalized Invitation URL
                    </Typography>
                    <Typography variant="h5" color="primary">
                      {inviteId ? (
                        <a href={getInviteUrl(inviteId)} target="_blank">
                          {getInviteUrl(inviteId)}
                        </a>
                      ) : (
                        <>Creating...</>
                      )}
                    </Typography>
                  </Box>
                ) : (
                  <Box>
                    <Button
                      size="small"
                      variant="contained"
                      onClick={createReusableInvite}
                    >
                      Create a personalized Sign up address
                    </Button>
                  </Box>
                )}
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
};

const mapStateToProps = ({ user }: StoreState): { user: User } => {
  return { user };
};

export const InviteClientView = connect(mapStateToProps)(_InviteClientView);
