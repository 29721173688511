import React, { useEffect, useState } from "react";
import {
  Divider,
  Theme,
  Checkbox,
  FormGroup,
  FormControlLabel,
  CircularProgress,
} from "@mui/material";
import { makeStyles, withStyles } from "tss-react/mui";
import { Task } from "../../../../models/Task";
import { Check } from "../../../shared/AtticusIcons";
import { TaskTypeById } from "../../../../models/TaskTypes";

const useStyles = makeStyles()((theme: Theme) => ({
  divider: {
    alignSelf: "center",
    height: "30px",
    width: "2px",
    backgroundColor: theme.palette.text.secondary,
  },
  circle: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "20px",
    height: "20px",
    borderRadius: "50%",
    border: `2px solid ${theme.palette.text.secondary}`,
    backgroundColor: theme.palette.background.paper,
  },
  circleChecked: {
    border: `2px solid ${theme.palette.secondary.main}`,
    backgroundColor: theme.palette.secondary.main,
  },
}));

const FormGroupStyled = withStyles(FormGroup, (theme: Theme) => ({
  root: {
    justifyContent: "center",
    gap: "30px",
    flex: 1,
    width: "100%",
    backgroundColor: theme.palette.background.default,
    padding: "17px 0",
  },
}));

const FormControlLabelStyled = withStyles(FormControlLabel, (theme: Theme, _params:any, classes:any) => ({
  root: {
    marginRight: 0,
  },
  disabled: {},
  label: {
    [`&.${classes.disabled}`]: {
      fontWeight: 500,
      color: theme.palette.primary.dark,
    },
  },
}));

interface Props {
  task: Task;
  answer?: number;
  onChange?: (answer?: number) => void;
  hasWriteAccess?: boolean;
}

interface CheckboxProps {
  checked?: boolean;
  saving?: boolean;
}

const CustomCheckBox = ({ checked, saving }: CheckboxProps): JSX.Element => {
  const { classes } = useStyles();
  return saving ? (
    <CircularProgress size={20} />
  ) : (
    <div
      className={`${classes.circle} ${checked? classes.circleChecked : ""}`}
    >
      {checked && (
        <Check style={{ width: "80%", color: "white" }} viewBox="0 0 22 12" />
      )}
    </div>
  );
};

export const TaskAnswerComponent = ({
  task,
  answer,
  onChange,
  hasWriteAccess = false,
}: Props): JSX.Element | null => {
  const { classes } = useStyles();
  const [saving, setSaving] = useState(false);

  useEffect(() => {
    if (saving) {
      setSaving(false);
    }
  }, [answer]);

  // Task types by id
  // 1: Terms, 2: Tip/Feel Good, 3: Must do statement, 4: Must do question, 5: Question w/ data entry , 6: Educational
  const displayAnswer = (task: Task) => {
    switch (task.types?.id) {
      case TaskTypeById.Tip_Feel_Good:
      case TaskTypeById.Must_do_question:
        return "This is done";
      // case 5:
      case TaskTypeById.Educational:
        return "Got it";
      default:
        return "This is done";
    }
  };

  if (task.types?.id === TaskTypeById.Terms) {
    // Task is Terms
    return null;
  }

  if (task.types?.id === TaskTypeById.Must_do_question) {
    // Task is yes or no
    return (
      <FormGroupStyled row>
        <FormControlLabelStyled
          control={
            <Checkbox
              disabled={!hasWriteAccess}
              onChange={(event: any, checked: boolean) => {
                if (hasWriteAccess) {
                  setSaving(true);
                  onChange?.(checked ? 1 : 2);
                }
              }}
              checked={answer === 1}
              icon={<CustomCheckBox saving={saving} />}
              checkedIcon={<CustomCheckBox checked saving={saving} />}
            />
          }
          label="Yes"
        />
        <Divider className={classes.divider} orientation="vertical" />
        <FormControlLabelStyled
          control={
            <Checkbox
              disabled={!hasWriteAccess}
              onChange={(event: any, checked: boolean) => {
                if (hasWriteAccess) {
                  setSaving(true);
                  onChange?.(checked ? 2 : 1);
                }
              }}
              checked={answer === 2}
              icon={<CustomCheckBox saving={saving} />}
              checkedIcon={<CustomCheckBox checked saving={saving} />}
            />
          }
          label="No"
        />
      </FormGroupStyled>
    );
  }

  return (
    <FormGroupStyled row>
      <FormControlLabelStyled
        control={
          <Checkbox
            disabled={!hasWriteAccess}
            onChange={(event: any, checked: boolean) => {
              if (hasWriteAccess) {
                setSaving(true);
                onChange?.(checked ? 1 : 0);
              }
            }}
            checked={answer === 1}
            icon={<CustomCheckBox saving={saving} />}
            checkedIcon={<CustomCheckBox checked saving={saving} />}
          />
        }
        label={displayAnswer(task)}
      />
    </FormGroupStyled>
  );
};