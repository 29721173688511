import React, { useEffect } from "react";
import { Button } from "@mui/material";
import { OpportunitiesTable } from "./OpportunitiesTable";
import { DetailCard } from "../shared/DetailCard";
import { useNavigate } from "react-router-dom";
import { Opportunity } from "../../models/Opportunity";
import { StoreState } from "../../reducers";
import { connect } from "react-redux";
import { User } from "../../models/User";
import { SCREEN_PATHS } from "../../constants";
import { OpportunityStatus } from "../../models/OpportunityStatus";
import { fetchOpportunities, fetchOpportunityStatuses, fetchOpportunityTypes } from "../../actions/opportunityActions";
import { OpportunityType } from "../../models/OpportunityType";

const _LatestOpportunities = ({
  count = 3,
  user,
  estate,
  opportunities,
  opportunitiesCount,
  opportunityStatuses,
  opportunityTypes,
  fetchOpportunities,
  fetchOpportunityTypes,
  fetchOpportunityStatuses,
}): JSX.Element => {
  const navigate = useNavigate();

  const goToOpportunities = (): void => {
    navigate(
      estate
        ? `${SCREEN_PATHS.estates}/${estate?.id}/opportunities`
        : SCREEN_PATHS.opps
    );
  };

  useEffect( () => {  
    if(opportunityTypes && opportunityTypes.length > 0) {
      return
    }
    fetchOpportunityTypes() 
  }, []) 
  useEffect( () => {  
    if(opportunityStatuses && opportunityStatuses.length > 0) {
      return
    }
    fetchOpportunityStatuses() 
  }, []) 

  useEffect(() => {
    if(!opportunityStatuses || !opportunityTypes) {
      return
    }
    fetchOpportunities(opportunityTypes, opportunityStatuses, estate? [estate.id]:[], count, 0, 
      "opportunities.created_at", "DESC", opportunities?.length || 0);
  }, [estate, user, count, opportunityTypes, opportunityStatuses]);

  const sortedOpps = opportunities?.sort((a: Opportunity, b: Opportunity) => {
    return b?.updatedAt && a?.updatedAt
      ? b.updatedAt.getTime() - a.updatedAt.getTime()
      : 0;
  });

  return (
    <>
      <DetailCard
        title="Latest Opportunities"
        subTitle={`Showing ${sortedOpps?.length || 0} of ${opportunitiesCount || 0}`}
        footerComponent={
          <Button size="small" variant="text" onClick={goToOpportunities}>
            See all opportunities
          </Button>
        }
      >
        <OpportunitiesTable
          opportunityStatuses={opportunityStatuses}
          opportunities={sortedOpps}
          user={user}
          estate={estate}
          noPad
          minWidth={600}
          disableEdit={true}
        />
      </DetailCard>
    </>
  );
};

const mapStateToProps = ({
  user,
  opportunities,
  opportunityStatuses,
  opportunityTypes,
  opportunitiesCount,
}: StoreState): { user: User; opportunities: Opportunity[], opportunityStatuses: OpportunityStatus[], 
                  opportunityTypes:OpportunityType[], opportunitiesCount:number } => {
  return { user, opportunities, opportunityStatuses, opportunitiesCount, opportunityTypes };
};

export const LatestOpportunities =
  connect(mapStateToProps, {fetchOpportunities, fetchOpportunityTypes, fetchOpportunityStatuses})(_LatestOpportunities);
