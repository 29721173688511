import React from "react";

export const trimOnBlur = (
  e: React.FocusEvent<any>,
  fieldName: string,
  setFieldValue: any,
  handleBlur: any
): void => {
  setFieldValue(fieldName, e.target.value?.trim());
  handleBlur(e);
};
