// import { API } from "aws-amplify";
// import { PUBLIC_REST_API, SCREEN_PATHS, STORAGE_KEYS } from "../constants";
// import { logger } from "./index";

export class RunTimeConfig {
  async fetch(atMaintenanceScreen: boolean, code?: string): Promise<any> {
    // try {
    //   const override = window.sessionStorage.getItem(STORAGE_KEYS.OVERRIDE);
    //   const body = code && override ? JSON.parse(override) : undefined;
    //   return await API.post(PUBLIC_REST_API, `/config/${code || ""}`, {
    //     body,
    //   });
    // } catch (error) {
    //   if ((error as any)?.response?.status === 503) {
    //     if (!atMaintenanceScreen) {
    //       // service unavailable, come back later
    //       window.document.location.href = SCREEN_PATHS.maintenance;
    //     }
    //   } else {
    //     logger.error(error as Error);
    //   }
    // }
    return {};
  }
}
