export type EstateScreen = {
  title: string;
  path: string;
};

export const estateNavItems: EstateScreen[] = [
  {
    title: "Overview",
    path: "",
  },
  {
    title: "Opportunities",
    path: "opportunities",
  },
  {
    title: "Progress",
    path: "progress",
  },
  {
    title: "Assets",
    path: "assets",
  },
  {
    title: "Forms & Files",
    path: "forms-and-files",
  },
  {
    title: "Notes",
    path: "notes",
  },
  {
    title: "Customer reports",
    path: "reports",
  },
];
