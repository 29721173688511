import { Dispatch } from "redux";
import { ActionTypes } from "./types";
import { Estate } from "../models/Estate";
import { estateApi, notesApi } from "../apis";
import { Asset } from "../models/Asset";
import { Report } from "../models/Report";
import { EstateNote } from "../models/EstateNote";
import { SetTotalEstatesAction } from "./appActions";
import { EstateAvatar } from "../models/EstateAvatar";
import { AssetImage } from "../models/AssetImage";

export interface FetchEstatesAction {
  type: ActionTypes.FetchEstates;
  payload: Estate[];
}
export interface FetchMockEstatesAction {
  type: ActionTypes.FetchMockEstates;
  payload: Estate[];
}

export const fetchEstates = (
  limit?: number,
  offset?: number,
  sort?: string,
  dir?: string,
  includeDeleted = false,
  gid?: number
) => {
  return async (dispatch: Dispatch): Promise<void> => {
    let estates: Estate[] = [];
    let total = 0;
    try {
      const estatesWithTotal = await estateApi.fetchEstates(
        limit,
        offset,
        sort,
        dir,
        includeDeleted,
        gid === 0 ? undefined : gid
      );
      estates = estatesWithTotal.estates;
      total = estatesWithTotal.total;
    } catch (error) {
      console.error(error);
    }
    dispatch<SetTotalEstatesAction>({
      type: ActionTypes.SetTotalEstates,
      payload: total,
    });
    dispatch<FetchEstatesAction>({
      type: ActionTypes.FetchEstates,
      payload: estates,
    });
  };
};

export interface FetchSingleEstateAction {
  type: ActionTypes.FetchSingleEstate;
  payload: Estate;
}

export const fetchSingleEstate = (estateId: number) => {
  return async (dispatch: Dispatch): Promise<void> => {
    let estate: Estate = new Estate();
    try {
      estate = await estateApi.fetchSingleEstate(estateId);
    } catch (error) {
      console.error(error);
    }
    dispatch<FetchSingleEstateAction>({
      type: ActionTypes.FetchSingleEstate,
      payload: estate,
    });
  };
};

export interface CacheEstateAvatarAction {
  type: ActionTypes.CacheEstateAvatar;
  payload: {
    estateAvatar: EstateAvatar | null;
  };
}

// the network call for this is in AsyncAvatar
export const cacheEstateAvatar = (
  estateAvatar: EstateAvatar
): CacheEstateAvatarAction => {
  return {
    type: ActionTypes.CacheEstateAvatar,
    payload: {
      estateAvatar,
    },
  };
};

export interface CacheAssetImageAction {
  type: ActionTypes.CacheAssetImage;
  payload: {
    assetImage: AssetImage | null;
  };
}

// the network call for this is in AsyncAssetImage
export const cacheAssetImage = (
  assetImage: AssetImage
): CacheAssetImageAction => {
  return {
    type: ActionTypes.CacheAssetImage,
    payload: {
      assetImage,
    },
  };
};

export interface SearchEstatesAction {
  type: ActionTypes.SearchEstates;
  payload: {
    cacheKey: string;
    estates: Estate[] | null;
  };
}

export const searchEstates = (
  terms: string,
  cacheKey: string,
  groupId?: number
) => {
  return async (dispatch: Dispatch): Promise<void> => {
    let estates: Estate[] = [];
    try {
      const estatesWithTotal = await estateApi.searchEstates(terms, groupId);
      estates = estatesWithTotal.estates;
    } catch (error) {
      console.error(error);
    }
    // cacheKey is all the inputs that make this query unique: terms, gid
    dispatch<SearchEstatesAction>({
      type: ActionTypes.SearchEstates,
      payload: { cacheKey: cacheKey, estates },
    });
  };
};

export interface ClearSearchedEstatesAction {
  type: ActionTypes.ClearSearchedEstates;
  payload: null;
}

export const clearSearchedEstates = (): ClearSearchedEstatesAction => {
  return {
    type: ActionTypes.ClearSearchedEstates,
    payload: null,
  };
};

export interface FetchEstateAssetsAction {
  type: ActionTypes.FetchEstateAssets;
  payload: {
    estateId: number;
    assets: Asset[] | undefined;
  };
}

export const fetchEstateAssets = (estate: Estate) => {
  return async (dispatch: Dispatch): Promise<void> => {
    if (estate.id) {
      dispatch<FetchEstateAssetsAction>({
        type: ActionTypes.FetchEstateAssets,
        payload: {
          estateId: estate.id,
          assets: await estateApi.fetchEstateAssets(estate.id),
        },
      });
    }
  };
};

export interface FetchEstateReportsAction {
  type: ActionTypes.FetchEstateReports;
  payload: {
    estateId: number;
    reports: Report[] | undefined;
  };
}

export const fetchEstateReports = (estate: Estate) => {
  return async (dispatch: Dispatch): Promise<void> => {
    if (estate.id) {
      dispatch<FetchEstateReportsAction>({
        type: ActionTypes.FetchEstateReports,
        payload: {
          estateId: estate.id,
          reports: await estateApi.fetchEstateReports(estate.id, estate?.name),
        },
      });
    }
  };
};

export interface FetchEstateNotesAction {
  type: ActionTypes.FetchEstateNotes;
  payload: {
    estateId: number;
    estateNotes: EstateNote[] | undefined;
  };
}

export const fetchEstateNotes = (estate: Estate) => {
  return async (dispatch: Dispatch): Promise<void> => {
    if (estate.id) {
      dispatch<FetchEstateNotesAction>({
        type: ActionTypes.FetchEstateNotes,
        payload: {
          estateId: estate.id,
          estateNotes: await notesApi.fetchEstateNotes(estate.id),
        },
      });
    }
  };
};

export interface SaveEstateNoteAction {
  type: ActionTypes.SaveEstateNote;
  payload: {
    estateId: number;
    estateNote: EstateNote | null;
  };
}

export const saveEstateNote = (estateNote: EstateNote, estateId: number) => {
  return async (dispatch: Dispatch): Promise<void> => {
    if (estateId) {
      dispatch<SaveEstateNoteAction>({
        type: ActionTypes.SaveEstateNote,
        payload: { estateId, estateNote },
      });
    }
  };
};

export interface DeleteEstateNoteAction {
  type: ActionTypes.DeleteEstateNote;
  payload: {
    estateId: number;
    estateNote: EstateNote | null;
  };
}

export const deleteEstateNote = (estateNote: EstateNote, estateId: number) => {
  return async (dispatch: Dispatch): Promise<void> => {
    if (estateId) {
      dispatch<DeleteEstateNoteAction>({
        type: ActionTypes.DeleteEstateNote,
        payload: { estateId, estateNote },
      });
    }
  };
};

export interface SetEstateIsDemoAction {
  type: ActionTypes.SetEstateIsDemo;
  payload: {
    estateId: number;
    isDemo: boolean;
  };
}

export const setEstateIsDemo = (estateId: number, isDemo: boolean) => {
  return async (dispatch: Dispatch): Promise<void> => {
    if (estateId) {
      try {
        await estateApi.saveIsDemo(estateId, isDemo);
      } catch (error) {
        console.error(error);
      }
      dispatch<SetEstateIsDemoAction>({
        type: ActionTypes.SetEstateIsDemo,
        payload: { estateId, isDemo },
      });
    }
  };
};

export interface ClearEstatesAction {
  type: ActionTypes.ClearEstates;
  payload: null;
}

export const clearEstates = (): ClearEstatesAction => {
  return {
    type: ActionTypes.ClearEstates,
    payload: null,
  };
};
