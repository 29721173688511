import { ActionTypes } from "../actions/types";
import { AnyAction } from "redux";
import { Country, State, County } from "../models/Geography";

export const countriesReducer = (
  countries: Country[] | null = null,
  action: AnyAction
): Country[] | null => {
  switch (action.type) {
    case ActionTypes.FetchCountries:
      return action.payload;
    case ActionTypes.AppSignOut:
      return null;
    default:
      return countries;
  }
};

export const statesReducer = (
  states: State[] | null = null,
  action: AnyAction
): State[] | null => {
  switch (action.type) {
    case ActionTypes.FetchStates:
      return action.payload;
    case ActionTypes.AppSignOut:
      return null;
    default:
      return states;
  }
};

export const countiesReducer = (
  counties: County[] | null = null,
  action: AnyAction
): County[] | null => {
  switch (action.type) {
    case ActionTypes.FetchCounties:
      return action.payload;
    case ActionTypes.AppSignOut:
      return null;
    default:
      return counties;
  }
};
