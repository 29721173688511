import { User } from "./User";
import { AssetsMap, EstateSummary } from "./EstateSummary";
import { Place, PlaceJSON } from "./Place";
import { OnboardingSummary } from "./OnboardingSummary";
import { ProbateType, ProbateTypeJSON } from "./ProbateType";
import { Country, GeographyJson, State } from "./Geography";
import { AdvisorGroup, AdvisorGroupJSON } from "./AdvisorGroup";
import { UserJSON } from "./User";
import moment from "moment";
// database field names
export type EstateLaravelizedJSON = {
  id: number;
  advisor_group_id?: number;
  advisor_group?: AdvisorGroupJSON;
  deceased_first_name?: string;
  deceased_middle_name?: string;
  deceased_last_name?: string;
  deceased_suffix?: string;
  passed_on?: string | null; // convert to date
  is_demo?: boolean;
  assets_value_total?: number;
  assets_value?: string;
  assets_inventoried?: string;
  num_tasks_assigned?: number;
  num_tasks_completed?: number;
  percent_complete?: number;
  has_will?: string;
  letters?: string;
  value_below_threshold?: string;
  tasks_completed?: string;
  estate_team?: string;
  type_of_assets?: string;
  already_started?: string;
  need_help_with?: string;
  need_help_with_something_else?: string;
  was_veteran?: string;
  property_ownership?: string;
  property_value?: number;
  probate_type_id?: number;
  probate_type?: ProbateTypeJSON;
  country_id?: number;
  country?: GeographyJson;
  state_id?: number;
  state?: GeographyJson;
  advisors?: UserJSON[];
  users?: UserJSON[];
  places?: PlaceJSON[];
  created_at?: string;
  deleted_at?: string;
  updated_at?: string;
};

export class EstateLaravelized {
  static path = "/estates2";

  constructor(
    public id: number,
    public advisorGroupId?: number,
    public advisorGroup?: AdvisorGroup,
    public deceasedFirstName?: string,
    public deceasedMiddleName?: string,
    public deceasedLastName?: string,
    public deceasedNameSuffix?: string,
    public passedOn?: moment.Moment | null,
    public isDemo?: boolean,
    public tasksCompleted?: string,
    public estateSummary?: EstateSummary | null,
    public onboardingSummary?: OnboardingSummary | null,
    public wasVeteran?: string,
    public probateTypeId?: number,
    public probateType?: ProbateType,
    public countryId?: number,
    public country?: Country,
    public stateId?: number,
    public state?: State,
    public advisors?: User[],
    public users?: User[],
    public placees?: Place[],
    public createdAt?: moment.Moment,
    public updatedAt?: moment.Moment,
    public deletedAt?: moment.Moment
  ) {}

  static fromJSON = (json: EstateLaravelizedJSON): EstateLaravelized => {
    return new EstateLaravelized(
      json.id,
      json.advisor_group_id,
      json.advisor_group
        ? AdvisorGroup.fromJSON(json.advisor_group)
        : undefined,
      json.deceased_first_name,
      json.deceased_middle_name,
      json.deceased_last_name,
      json.deceased_suffix,
      json.passed_on ? moment(new Date(json.passed_on)) : null,
      json.is_demo,
      json.tasks_completed,
      EstateSummary.fromJSON(json.id, {
        assets_value_total: json.assets_value_total,
        assets_value: json.assets_value
          ? (JSON.parse(json.assets_value) as AssetsMap)
          : undefined,
        assets_inventoried: json.assets_inventoried
          ? (JSON.parse(json.assets_inventoried) as AssetsMap)
          : undefined,
        num_tasks_assigned: json.num_tasks_assigned,
        num_tasks_completed: json.num_tasks_completed,
        percent_complete: json.percent_complete,
      }),
      OnboardingSummary.fromJSON(json.id, {
        has_will: json.has_will,
        letters: json.letters,
        value_below_threshold: json.value_below_threshold,
        done_so_far: json.tasks_completed
          ? JSON.parse(json.tasks_completed)
          : undefined,
        estate_team: json.estate_team
          ? JSON.parse(json.estate_team)
          : undefined,
        type_of_assets: json.type_of_assets
          ? JSON.parse(json.type_of_assets)
          : undefined,
        already_started: json.already_started,
        need_help_with: json.need_help_with
          ? JSON.parse(json.need_help_with)
          : undefined,
        need_help_with_something_else: json.need_help_with_something_else,
        property_ownership: json.property_ownership,
        property_value: json.property_value,
      }),
      json.was_veteran,
      json.probate_type_id,
      json.probate_type ? ProbateType.fromJSON(json.probate_type) : undefined,
      json.country_id,
      json.country ? Country.fromJSON(json.country) : undefined,
      json.state_id,
      json.state ? State.fromJSON(json.state) : undefined,
      json.advisors ? json.advisors.map((a) => User.fromJSON(a)) : undefined,
      json.users ? json.users.map((u) => User.fromJSON(u)) : undefined,
      json.places
        ? json.places.map((p) => Place.fromJSON(json.id, p))
        : undefined,
      json.created_at ? moment(new Date(json.created_at)) : undefined,
      json.updated_at ? moment(new Date(json.updated_at)) : undefined,
      json.deleted_at ? moment(new Date(json.deleted_at)) : undefined
    );
  };

  clone = (): EstateLaravelized => {
    return new EstateLaravelized(
      this.id,
      this.advisorGroupId,
      this.advisorGroup,
      this.deceasedFirstName,
      this.deceasedMiddleName,
      this.deceasedLastName,
      this.deceasedNameSuffix,
      this.passedOn,
      this.isDemo,
      this.tasksCompleted,
      this.estateSummary,
      this.onboardingSummary,
      this.wasVeteran,
      this.probateTypeId,
      this.probateType,
      this.countryId,
      this.country,
      this.stateId,
      this.state,
      this.advisors,
      this.users,
      this.placees
    );
  };

  toJSON = (): EstateLaravelizedJSON => {
    return {
      id: this.id,
      advisor_group_id: this.advisorGroupId,
      advisor_group: this.advisorGroup?.toJSON(),
      deceased_first_name: this.deceasedFirstName,
      deceased_middle_name: this.deceasedMiddleName,
      deceased_last_name: this.deceasedLastName,
      deceased_suffix: this.deceasedNameSuffix,
      passed_on: this.passedOn
        ? this.passedOn?.utc().format("YYYY-MM-DD")
        : null,
      is_demo: this.isDemo,
      assets_value_total: this.estateSummary?.totalAssetsValue,
      assets_value: this.estateSummary?.assetsValue
        ? JSON.stringify(this.estateSummary?.assetsValue)
        : undefined,
      assets_inventoried: this.estateSummary?.assetsInventoried
        ? JSON.stringify(this.estateSummary?.assetsInventoried)
        : undefined,
      num_tasks_assigned: this.estateSummary?.tasksAssigned,
      num_tasks_completed: this.estateSummary?.tasksCompleted,
      percent_complete: this.estateSummary?.percentCompleted,
      has_will: this.onboardingSummary?.hasWill,
      letters: this.onboardingSummary?.letters,
      value_below_threshold: this.onboardingSummary?.valueBelowThreshold,
      tasks_completed: this.onboardingSummary?.doneSoFar
        ? JSON.stringify(this.onboardingSummary?.doneSoFar)
        : undefined,
      estate_team: this.onboardingSummary?.estateTeam
        ? JSON.stringify(this.onboardingSummary?.estateTeam)
        : undefined,
      type_of_assets: this.onboardingSummary?.typeOfAssets
        ? JSON.stringify(this.onboardingSummary?.typeOfAssets)
        : undefined,
      already_started: this.onboardingSummary?.alreadyStarted,
      need_help_with: this.onboardingSummary?.need_help_with
        ? JSON.stringify(this.onboardingSummary?.need_help_with_something_else)
        : undefined,
      need_help_with_something_else: this.onboardingSummary
        ? this.onboardingSummary?.need_help_with_something_else
        : undefined,
      was_veteran: this.wasVeteran,
      property_ownership: this.onboardingSummary?.propertyOwnership,
      property_value: this.onboardingSummary?.propertyValue,
      probate_type_id: this.probateTypeId,
      probate_type: this.probateType?.toJSON(),
      country_id: this.countryId,
      country: this.country?.toJSON(),
      state_id: this.stateId,
      state: this.state?.toJSON(),
      advisors: this.advisors?.map((a) => a.toJson()),
      users: this.users?.map((u) => u.toJson()),
      places: this.placees?.map((p) => p.toJSON()),
      created_at: this.createdAt?.toISOString(),
      updated_at: this.updatedAt?.toISOString(),
      deleted_at: this.deletedAt?.toISOString(),
    };
  };

  get hasPassed(): boolean {
    return !!this.passedOn;
  }

  get deceasedFullName(): string | undefined {
    let fullName: string | undefined = undefined;
    if (this.deceasedFirstName) {
      fullName = ` ${this.deceasedFirstName}`;
    }

    if (this.deceasedMiddleName) {
      fullName += ` ${this.deceasedMiddleName}`;
    }

    if (this.deceasedLastName) {
      fullName += ` ${this.deceasedLastName}`;
    }

    if (this.deceasedNameSuffix) {
      fullName += `, ${this.deceasedNameSuffix}`;
    }
    return fullName?.trim();
  }

  get momentPassedOnDate(): moment.Moment {
    return moment(this.passedOn);
  }
}
