import React from "react";
import { Box, Typography, CircularProgress, Theme } from "@mui/material";
import { makeStyles } from "tss-react/mui";
import { CircularProgressProps } from "@mui/material/CircularProgress";
import { AtticusForm } from "../../../models/AtticusForm";
import { County } from "../../../models/Geography";
import { FormInterface } from "../../../models/FormInterface";
const useStyles = makeStyles()((theme: Theme) => ({
  root: {
    height: "100%",
    width: "100%",
    padding: "0.25rem 0.25rem 0 0.25rem",
    backgroundColor: "#abb3bf",
    display: "flex",
    alignItems: "center",
  },
  titleContainer: {
    flex: 1,
  },
  title: {
    // overflow: "hidden",
    // textOverflow: "ellipsis",
    // whiteSpace: "nowrap",
  },
  percentCompleteContainer: {
    backgroundColor: theme.palette.background.paper,
    borderRadius: "0.5rem",
    padding: "0.25rem",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  percentCompleteCircle: {
    borderRadius: "50%",
    boxShadow: `inset 0 0 0 0.2rem ${theme.palette.grey[300]}`,
    marginRight: "0.5rem",
  },
}));

function CircularProgressWithLabel(
  props: CircularProgressProps & { value: number }
) {
  const { classes } = useStyles();
  return (
    <Box position="relative" display="inline-flex">
      <CircularProgress
        className={classes.percentCompleteCircle}
        variant="determinate"
        {...props}
      />
      <Box
        top={0}
        left={0}
        bottom={0}
        right={0}
        position="absolute"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Typography
          variant="caption"
          component="div"
          color="textSecondary"
        >{`${Math.round(props.value)}%`}</Typography>
      </Box>
    </Box>
  );
}

interface Props {
  form?: AtticusForm;
  formInterface?: FormInterface;
}

export const TopMetadataBar = ({ form, formInterface }: Props): JSX.Element => {
  const { classes } = useStyles();
  const numberFields = formInterface?.data.length;
  const numberComplete = formInterface?.data.reduce(function (r, a) {
    return r + +(a.answer_value !== null);
  }, 0);

  return (
    <Box className={classes.root}>
      <Box className={classes.titleContainer}>
        {form && (
          <Typography className={classes.title} variant="h3">
            {!(form?.geography instanceof County)
              ? form?.geography?.code
              : form?.geography?.name}
            {form?.getGovernmentId() ? ` [${form.getGovernmentId()}]` : ``}
            {" - "}
            {form?.getTitle()}
          </Typography>
        )}
      </Box>
      <Box>
        <Box className={classes.percentCompleteContainer}>
          <CircularProgressWithLabel
            value={
              numberComplete && numberFields
                ? Math.floor((numberComplete / numberFields) * 100)
                : 0
            }
          />
          <Typography>{`Complete`}</Typography>
        </Box>
      </Box>
    </Box>
  );
};
