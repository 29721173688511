import React, { ForwardedRef, forwardRef, ReactElement } from "react";
import { Helmet } from "react-helmet-async";

interface Props {
  className?: string;
  children: ReactElement;
  title?: string;
  rest?: any[];
}

const Page = forwardRef(
  (
    { children, title = "", className = "", ...rest }: Props,
    ref: ForwardedRef<HTMLDivElement>
  ) => {
    return (
      <div className={className} ref={ref} {...rest}>
        <Helmet>
          <title>{title}</title>
        </Helmet>
        {children}
      </div>
    );
  }
);

export default Page;
