import React from "react";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import { StoreState } from "../../../reducers";
import { Estate } from "../../../models/Estate";
import { Opportunity } from "../../../models/Opportunity";
import { FinancialConvo } from "../../../models/FinancialConvo";
import { EditableOpportunities } from "../../opportunities/EditableOpportunities";
import { User } from "../../../models/User";
import { OpportunityStatus } from "../../../models/OpportunityStatus";

interface Props {
  user?: User;
  estates?: Estate[];
  opportunities?: Opportunity[];
  financialConvos?: FinancialConvo[];
  opportunityStatuses?: OpportunityStatus[],
}

const _OpportunitiesView = ({
  user,
  estates,
  opportunities,
  financialConvos,
  opportunityStatuses,
}: Props) => {
  const { estateId } = useParams();

  let estate: Estate | undefined;
  if (estateId && estates?.length) {
    estate = estates.find((estate: Estate) => estate.id === +estateId);
  }

  // NOTE: when opportunities === null || estate === null (haven't loaded yet)
  //  filteredOpp will be undefined
  const oppForEstate = opportunities?.filter((opp: Opportunity) => {
    return opp.estateId === estate?.id;
  });
  const finConvosForEstate = financialConvos?.filter(
    (convo: FinancialConvo) => {
      return convo.estateId === estate?.id;
    }
  );

  return (
    <div data-automation-id="page-content-estate-opportunities">
        <EditableOpportunities
          estate={estate}
        />
    </div>
  );
};

const mapStateToProps = ({
  user,
  estates,
  opportunities,
  financialConvos,
  opportunityStatuses,
}: StoreState): {
  user: User;
  estates: Estate[];
  opportunities: Opportunity[];
  financialConvos: FinancialConvo[];
  opportunityStatuses: OpportunityStatus[];
} => {
  return { user, estates, opportunities, financialConvos, opportunityStatuses };
};

export const OpportunitiesView = connect(mapStateToProps)(_OpportunitiesView);
