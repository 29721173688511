import { combineReducers } from "redux";
import { Estate } from "../models/Estate";
import { estatesReducer } from "./estates";
import { estatesMocksReducer } from "./estatesMocks";
import { knowledgeEngineFieldCategoriesReducer } from "./knowledgeEngineFieldCategories";
import { userReducer } from "./user";
import { User } from "../models/User";
import { Advisor } from "../models/Advisor";
import { FormType } from "../models/FormType";
import { FormStatusType } from "../models/FormStatusType";
import { Language } from "../models/Language";
import { advisorsReducer } from "./advisors";
import { appReducer, AppState } from "./app";
import { AdvisorGroup } from "../models/AdvisorGroup";
import { advisorGroupsReducer } from "./advisorGroups";
import { OpportunityType } from "../models/OpportunityType";
import { OpportunityStatus } from "../models/OpportunityStatus";
import {
  LoadingOpportunities,
  loadingOpportunitiesReducer,
  opportunitiesReducer,
  opportunityStatusesReducer,
  opportunityTypesReducer,
} from "./opportunities";
import { opportunitiesCountReducer } from "./opportunities";
import { Opportunity } from "../models/Opportunity";
import { financialConvoReducer } from "./financialConvos";
import { FinancialConvo } from "../models/FinancialConvo";
import { EstateSearch, estateSearchCacheReducer } from "./estateSearchCache";
import { EstateAvatar } from "../models/EstateAvatar";
import { estateAvatarCacheReducer } from "./estateAvatarCache";
import { AssetImage } from "../models/AssetImage";
import { assetImageCacheReducer } from "./assetImageCache";
import { formTypesReducer } from "./formTypes";
import { formStatusTypesReducer } from "./formStatusTypes";
import { languagesReducer } from "./languages";
import { ProbateType } from "../models/ProbateType";
import { probateTypesReducer } from "./probateTypes";
import { State, Country, County } from "../models/Geography";
import {
  statesReducer,
  countriesReducer,
  countiesReducer,
} from "./geographies";
import { estateFormsReducer } from "./estateForms";
import { KnowledgeEngineFieldCategory } from "../models/KnowledgeEngine";
import { TaskPhase } from "../models/TaskPhase";
import { taskPhasesReducer } from "./taskPhases";
import { AtticusForm } from "../models/AtticusForm";

export interface StoreState {
  app: AppState;
  user: User;
  advisors: Advisor[];
  estates: Estate[];
  estatesMocks: Estate[];
  estateForms: AtticusForm[];
  estateSearchCache: EstateSearch[];
  estateAvatarCache: EstateAvatar[];
  taskPhases: TaskPhase[];
  assetImageCache: AssetImage[];
  advisorGroups: AdvisorGroup[];
  opportunityTypes: OpportunityType[];
  opportunityStatuses: OpportunityStatus[];
  opportunities: Opportunity[];
  opportunitiesCount: number;
  financialConvos: FinancialConvo[];
  states: State[];
  countries: Country[];
  counties: County[];
  formTypes: FormType[];
  formStatusTypes: FormStatusType[];
  languages: Language[];
  probateTypes: ProbateType[];
  knowledgeEngineFieldCategories: KnowledgeEngineFieldCategory[];
  loadingOpportunities: LoadingOpportunities;
}

export const reducers = combineReducers({
  app: appReducer,
  user: userReducer,
  advisors: advisorsReducer,
  estates: estatesReducer,
  estatesMocks: estatesMocksReducer,
  estateForms: estateFormsReducer,
  estateSearchCache: estateSearchCacheReducer,
  estateAvatarCache: estateAvatarCacheReducer,
  taskPhases: taskPhasesReducer,
  assetImageCache: assetImageCacheReducer,
  advisorGroups: advisorGroupsReducer,
  opportunityTypes: opportunityTypesReducer,
  opportunityStatuses: opportunityStatusesReducer,
  opportunities: opportunitiesReducer,
  opportunitiesCount: opportunitiesCountReducer,
  loadingOpportunities: loadingOpportunitiesReducer,
  financialConvos: financialConvoReducer,
  states: statesReducer,
  countries: countriesReducer,
  counties: countiesReducer,
  formTypes: formTypesReducer,
  formStatusTypes: formStatusTypesReducer,
  languages: languagesReducer,
  probateTypes: probateTypesReducer,
  knowledgeEngineFieldCategories: knowledgeEngineFieldCategoriesReducer,
});
