import React from "react";
import {
  AppBar,
  Box,
  IconButton,
  Toolbar,
  Link,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { BrandLogo } from "@theatticusapp/atticus-shared-components";
import { User } from "../../models/User";
import { StoreState } from "../../reducers";
import { connect } from "react-redux";

interface Props {
  onMobileNavOpen: () => void;
  elevation?: number;
  className?: string;
  user?: User;
  rest?: any[];
}

const _TopBar = ({
  elevation,
  className,
  onMobileNavOpen,
  user,
}: Props): JSX.Element => {
  const theme = useTheme();
  const centerLogo = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <AppBar
      id="App/TopBar"
      className={className}
      elevation={elevation || 0}
      position="fixed"
      sx={{
        backgroundColor: theme.palette.background.paper,
        height: "64px",
      }}
    >
      <Toolbar
        id="Toolbar"
        sx={{
          padding: "0px 10px !IMPORTANT",
          minHeight: "64px",
          // padding: {
          //   sm: "0px 10px", // override MUI responsive sm
          // },
          gap: "10px",
        }}
      >
        <IconButton color="inherit" onClick={onMobileNavOpen}>
          <MenuIcon color="primary" />
        </IconButton>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: centerLogo ? "center" : "flex-start",
            flex: 1,
          }}
        >
          <Link
            href="/app/dashboard"
            sx={{
              display: "flex",
            }}
          >
            <BrandLogo
              advisorGroupKey={user?.advisorGroupName}
              sx={{ height: "32px" }}
            />
          </Link>
        </Box>
        <Box
          sx={{
            width: "2.5rem",
          }}
        >
          {/* Right side placeholder to center middle Box */}
        </Box>
      </Toolbar>
    </AppBar>
  );
};

const mapStateToProps = ({ user }: StoreState): { user: User } => {
  return { user };
};
const TopBar = connect(mapStateToProps)(_TopBar);

export default TopBar;
