import React, { useState, useCallback } from "react";
import type { JsonGroup, Config, ImmutableTree, BuilderProps } from '@react-awesome-query-builder/ui';
import { Utils as QbUtils, Query, Builder, MuiConfig } from '@react-awesome-query-builder/mui';
import '@react-awesome-query-builder/mui/css/styles.css';
import PacketLogicStyleOverrides from "./styles";
import {createConfig} from "./config"
import { v4 as uuid } from "uuid"

const config = createConfig(MuiConfig)
const queryValue: JsonGroup = { id: QbUtils.uuid(), type: "group" };

const DemoQueryBuilder: React.FC = () => {
  const [state, setState] = useState({
    tree: QbUtils.checkTree(QbUtils.loadTree(queryValue), config),
    config: config,
    key: uuid(),
  });

  const onChange = useCallback((immutableTree: ImmutableTree, config: Config) => {
    setState(prevState => ({ ...prevState, key:uuid(), tree: immutableTree, config: config }));
  }, []);

  const renderBuilder = useCallback((props: BuilderProps) => (
    <div className="query-builder-container">
      <div className="query-builder qb-lite">
        <Builder {...props} />
      </div>
    </div>
  ), []);


  return (
    <div style={{paddingLeft:"10px", paddingRight:"10px"}}>
      <PacketLogicStyleOverrides />
      <h3>Logic</h3>
      <Query
        {...config}
        value={state.tree}
        onChange={onChange}
        renderBuilder={renderBuilder}
      />
      <h3 style={{marginTop:"10px"}}>JSON</h3>
       <textarea rows={10} style={{width:"100%"}} key={state.key}>
          {JSON.stringify(QbUtils.jsonLogicFormat(state.tree, state.config), null, 2)}
        </textarea>
    </div>
  );
};
export default DemoQueryBuilder;