import atticusColors from "./colors";
import { Palette, Theme } from "@mui/material";
import { Components } from "@mui/material/styles/components";

export const getOverrides = (palette: Palette, theme: Theme): Components => ({
  MuiContainer: {
    styleOverrides: {
      root: {
        [theme.breakpoints.up("md")]: {
          paddingLeft: 40,
          paddingRight: 40,
        },
      },
    },
  },
  MuiTypography: {
    styleOverrides: {
      gutterBottom: {
        marginBottom: 16,
      },
    },
  },
  MuiCard: {
    styleOverrides: {
      root: {
        borderRadius: 12,
      },
    },
  },
  MuiPaper: {
    styleOverrides: {
      outlined: {
        border: "1px solid " + theme.palette.grey[300],
      },
      elevation: {
        boxShadow:
          "0px 2px 4px -1px rgba(116, 135, 164, 0.2), 0px 4px 5px rgba(116, 135, 164, 0.14), 0px 1px 10px rgba(116, 135, 164, 0.12)",
        "&.loading": {
          backgroundColor: palette.primary.light + "50",
          boxShadow: "none",
          marginBottom: 10,
        },
      },
    },
  },
  MuiCardHeader: {
    styleOverrides: {
      root: {
        paddingBottom: 0,
      },
      title: {
        fontWeight: "700", // can't get this from theme: seems to be chick and egg problem
        color: theme.palette.grey[900],
      },
    },
  },
  MuiCardContent: {
    styleOverrides: {
      root: {
        "&.dense": {
          padding: "10px",
        },
      },
    },
  },
  MuiAlert: {
    styleOverrides: {
      root: {
        textAlign: "left",
        "&.MuiAlert-standardInfo .MuiAlert-icon": {
          color: palette.primary.dark,
        },
      },
      icon: {
        display: "flex",
        alignItems: "center",
        color: palette.primary.dark,
      },
      action: {
        display: "flex",
        alignItems: "center",
        "& .MuiButtonBase-root": {
          color: palette.primary.dark,
        },
      },
      standardInfo: {
        backgroundColor: palette.primary.light,
        color: palette.primary.dark,
      },
    },
  },
  MuiDrawer: {
    styleOverrides: {
      paperAnchorDockedLeft: {
        borderRight: "none",
      },
    },
  },
  MuiTableRow: {
    styleOverrides: {
      head: {
        backgroundColor: palette.background.paper,
      },
      root: {
        backgroundColor: palette.background.default,
        // height: 60, // works like min-height
        "&.no-background": {
          backgroundColor: palette.background.paper,
        },
        "&.dense": {
          height: "auto",
        },
        "&.clickable:hover": {
          cursor: "pointer",
          backgroundColor:
            palette.mode === "light"
              ? atticusColors.purple5
              : atticusColors.grey2,
        },
      },
      // hover: {
      //   cursor: "pointer",
      //   backgroundColor: `${
      //     palette.type === "light" ? atticusColors.purple5 : atticusColors.grey2
      //   } !important `,
      //   // "&.clickable:hover": {
      //   //   backgroundColor: `${
      //   //     palette.type === "light"
      //   //       ? atticusColors.purple5
      //   //       : atticusColors.grey2
      //   //   } !important `,
      //   //   cursor: "pointer",
      // },
    },
  },
  MuiTableCell: {
    styleOverrides: {
      root: {
        // color: atticusColors.grey1,
        borderBottom: `solid 5px ${palette.background.paper}`,
        padding: "5px 10px",
      },
      head: {
        color: palette.text.secondary,
        paddingBottom: 6,
      },
    },
  },
  MuiButtonGroup: {
    styleOverrides: {
      root: {
        textTransform: "none",
        boxShadow: "none",
        "&:hover": {
          boxShadow:
            "0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%)",
          webkitTransition:
            "background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;",
          transition:
            "background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;",
        },
      },
    },
  },
  // Overrides for Button elements: Default/Medium should be 43px high with 14px font, Small should be 37px high with 13px font
  MuiButton: {
    styleOverrides: {
      root: {
        textTransform: "none",
        boxShadow: "none",
        "&.pill": {
          borderRadius: 20,
        },
        "&.primary-inverted": {
          color: palette.primary.main,
          backgroundColor: palette.background.paper,
          border: `solid 2px ${palette.primary.main}`,
        },
        "&.primary-inverted:hover": {
          color: palette.primary.main,
          backgroundColor: palette.background.default,
        },
        "&.Mui-disabled": {
          borderColor: palette.text.disabled,
        },
      },
      text: {
        color: palette.primary.main,
      },
      // Clear Buttons
      sizeSmall: {
        padding: "7.125px 15px",
      },
      sizeMedium: {
        padding: "9.25px 15px",
      },
      // Contained Buttons
      contained: {
        boxShadow: "none",
      },
      containedSizeSmall: {
        padding: "7.125px 15px",
      },
      containedSizeMedium: {
        padding: "9.25px 15px",
      },
      // Outlined Buttons
      outlined: {
        borderWidth: 2,
        "&:hover": {
          borderWidth: 2,
        },
      },
      outlinedSizeSmall: {
        padding: "5.125px 13px",
      },
      outlinedSizeMedium: {
        padding: "7.25px 13px",
      },
    },
  },
  MuiOutlinedInput: {
    styleOverrides: {
      input: {
        backgroundColor: palette.background.paper,
      },
      inputMultiline: {
        backgroundColor: palette.background.paper,
      },
      adornedStart: {
        backgroundColor: palette.background.paper,
      },
    },
  },
  // Overrides for Input Labels
  MuiInputLabel: {
    styleOverrides: {
      root: {
        fontSize: "16px",
        top: "-6px",
      },
      shrink: {
        top: 0,
      },
      sizeSmall: {
        fontSize: "14px",
        top: "inherit",
      },
    },
  },
  // Overrides for Input Base, focusing on Font Sizes.
  MuiInputBase: {
    styleOverrides: {
      root: {
        fontSize: "16px", // Font Size Standards for Default/Medium Form Fields
      },
      sizeSmall: {
        fontSize: "14px", // Font Size Standards for Small Form Fields
      },
    },
  },
  // Overrides for Text Fields and other Form Control elements: Default/Medium should be 43px high with 16px font, Small should be 37px high with 14px font
  MuiFormControl: {
    styleOverrides: {
      marginNormal: {
        marginTop: "10px",
      },
      root: {
        input: {
          padding: "10px 16px",
          "&.MuiInputBase-inputSizeSmall": {
            paddingTop: "8.4375px",
            paddingBottom: "8.4375px",
          },
          "&.MuiInputBase-inputAdornedStart": {
            paddingLeft: "0",
          },
        },
        multiline: {
          padding: "10px 16px",
        },
        inputAdornmentStart: {
          paddingLeft: "0",
        },
      },
    },
  },
  // Overrides for Select Dropdowns: Default/Medium should be 43px high with 16px font, Small should be 37px high with 14px font
  MuiSelect: {
    styleOverrides: {
      select: {
        padding: "10px 32px 10px 16px",
        "&.MuiInputBase-inputSizeSmall": {
          paddingTop: "8.4375px",
          paddingBottom: "8.4375px",
        },
      },
      outlined: {
        borderRadius: 4,
        position: "relative",
      },
    },
  },
  MuiTabs: {
    styleOverrides: {
      indicator: {
        height: 4,
        zIndex: 20,
      },
    },
  },
  MuiTab: {
    styleOverrides: {
      root: {
        textTransform: "none",
      },
    },
  },
  MuiDivider: {
    styleOverrides: {
      root: {
        "&.inverted": {
          borderColor: palette.background.paper,
        },
        "&.dark": {
          margin: theme.spacing(2, 0),
          borderColor: theme.palette.grey[400],
        },
      },
    },
  },
  MuiDialog: {
    styleOverrides: {
      root: {
        "&.noPadding .MuiDialog-paper": {
          padding: 0,
        },
      },
      paper: {
        padding: theme.spacing(4, 8),
        "&.noPadding": {
          padding: 0,
        },
      },
    },
  },
  MuiDialogTitle: {
    styleOverrides: {
      root: {
        "&.centeredText": {
          textAlign: "center",
          paddingLeft: theme.spacing(5),
          paddingRight: theme.spacing(5),
        },
      },
    },
  },
  MuiDialogContent: {
    styleOverrides: {
      root: {
        textAlign: "center",
        marginBottom: theme.spacing(2),
      },
    },
  },
  MuiDialogActions: {
    styleOverrides: {
      root: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      },
      spacing: {
        "&> :not(:first-of-type)": {
          margin: theme.spacing(1, 0),
        },
      },
    },
  },
  MuiChip: {
    styleOverrides: {
      root: {
        borderRadius: 4,
        fontSize: 12,
        fontWeight: "bold",
        height: "auto",
        lineHeight: "2em",
      },
    },
  },
});
