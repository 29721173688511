import React, { MouseEvent, useState } from "react";
import {
  Box,
  Card,
  CardActionArea,
  CardContent,
  IconButton,
  Menu,
  MenuItem,
  Typography,
} from "@mui/material";
import { Asset } from "../../../../models/Asset";
import { AsyncAssetImage, Dimensions } from "./AsyncAssetImage";
import add1 from "../../../../assets/icons/assets/add@1x.png";
import add2 from "../../../../assets/icons/assets/add@2x.png";
import add3 from "../../../../assets/icons/assets/add@3x.png";
import noImage1 from "../../../../assets/icons/assets/no-image@1x.png";
import noImage2 from "../../../../assets/icons/assets/no-image@2x.png";
import noImage3 from "../../../../assets/icons/assets/no-image@3x.png";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { assetIcons } from "../../../../assets/icons/assets/assetIcons";

interface Props {
  asset?: Asset;
  onClick?: () => void;
  onDelete?: () => void;
  hasWriteAccess?: boolean;
}

export const AssetCard = ({
  asset,
  onClick,
  onDelete,
  hasWriteAccess,
}: Props): JSX.Element => {
  const [contextMenuAnchor, setContextMenuAnchor] =
    useState<null | HTMLElement>(null);
  const contextMenuOpen = Boolean(contextMenuAnchor);
  const handleMenuOpen = (event: MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    event.stopPropagation();
    setContextMenuAnchor(event.currentTarget);
  };
  const handleMenuClick = (evt: any) => {
    const id = evt?.currentTarget?.dataset?.id;
    if (id === "view") {
      onClick?.();
    } else if (id === "delete") {
      onDelete?.();
    }
    setContextMenuAnchor(null);
  };

  const sx = {
    display: "flex",
    flexDirection: "column",
    height: "310px",
  };

  const getPlaceholderImage = () => {
    const icon =
      (asset?.category?.category && assetIcons[asset.category.category]) ||
      undefined;
    return (
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          minHeight: 200,
        }}
      >
        {icon || (
          <img
            srcSet={`${noImage2} 2x, ${noImage3} 3x`}
            src={`${noImage1}`}
            alt="No image available"
          />
        )}
        <Typography
          sx={{
            mt: 2,
          }}
        >
          {`${asset?.category?.category} Asset` || "No image available"}
        </Typography>
      </Box>
    );
  };

  return (
    <Card sx={sx}>
      {asset?.id ? (
        // existing asset
        <>
          <CardActionArea
            onClick={onClick}
            sx={{
              height: "100%",
              width: "100%",
              display: "flex",
              flex: "1",
              backgroundColor: "#F0F0FE",
              justifyContent: "center",
              alignItems: "center",
              overflow: "hidden",
              borderRadius: 0,
              "&.hover": {
                backgroundColor: "black",
              },
            }}
          >
            {asset?.images && asset?.images.length > 0 ? (
              // will currently show the first image
              <AsyncAssetImage
                assetImage={asset.images[0]}
                title={asset?.asset}
                dimensions={Dimensions.x300}
                retries={5}
                allowVertCrop
                sx={{
                  height: "100%",
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              />
            ) : (
              getPlaceholderImage()
            )}
          </CardActionArea>
          <CardContent sx={{ paddingBottom: "1rem !IMPORTANT" }}>
            <Box sx={{ display: "flex" }}>
              <Box sx={{ flex: 1 }}>
                {asset?.asset && (
                  <Typography
                    sx={{
                      fontWeight: "bold",
                    }}
                  >
                    {asset.asset}
                  </Typography>
                )}
                {asset?.category && (
                  <Typography variant="body2">
                    {asset.category?.category}
                  </Typography>
                )}
                {asset?.value && (
                  <Typography>
                    ${asset?.value?.toLocaleString() || "--"}
                  </Typography>
                )}
              </Box>
              {hasWriteAccess ? (
                <Box
                  sx={{
                    textAlign: "right",
                    marginRight: 0.5,
                  }}
                >
                  <IconButton
                    id="more-button"
                    aria-controls={contextMenuOpen ? "asset-menu" : undefined}
                    aria-haspopup="true"
                    aria-expanded={contextMenuOpen ? "true" : undefined}
                    onClick={handleMenuOpen}
                    sx={{
                      position: "relative",
                      left: 15,
                    }}
                  >
                    <MoreVertIcon />
                  </IconButton>
                  <Menu
                    id="asset-menu"
                    anchorEl={contextMenuAnchor}
                    open={contextMenuOpen}
                    onClose={handleMenuClick}
                    MenuListProps={{
                      "aria-labelledby": "more-button",
                    }}
                  >
                    <MenuItem onClick={handleMenuClick} data-id="view">
                      View asset
                    </MenuItem>
                    <MenuItem onClick={handleMenuClick} data-id="delete">
                      Delete asset
                    </MenuItem>
                  </Menu>
                </Box>
              ) : (
                <></>
              )}
            </Box>
          </CardContent>
        </>
      ) : (
        // new asset
        <CardActionArea onClick={onClick} sx={{ height: "100%" }}>
          <CardContent
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Box
              sx={{
                backgroundColor: "secondary.main",
                width: 72,
                height: 72,
                borderRadius: "50%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <img
                srcSet={`${add2} 2x, ${add3} 3x`}
                src={`${add1}`}
                alt="Add an asset"
              />
            </Box>
            <Typography
              sx={{
                mt: 2,
              }}
            >
              <strong>Add an asset</strong>
            </Typography>
          </CardContent>
        </CardActionArea>
      )}
    </Card>
  );
};