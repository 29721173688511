import { Dispatch } from "redux";
import { ActionTypes } from "./types";
import { advisorApi } from "../apis";
import { AdvisorsEstates } from "../models/AdvisorsEstates";
import { Advisor } from "../models/Advisor";

export interface FetchAdvisorsAction {
  type: ActionTypes.FetchAdvisors;
  payload: Advisor[] | undefined;
}

export const fetchAdvisors = () => {
  return async (dispatch: Dispatch): Promise<void> => {
    let advisors: Advisor[] | undefined = [];
    try {
      advisors = await advisorApi.fetchAdvisors();
    } catch (error) {
      console.error(error);
    }
    dispatch<FetchAdvisorsAction>({
      type: ActionTypes.FetchAdvisors,
      payload: advisors,
    });
  };
};

export interface ClearAdvisorsAction {
  type: ActionTypes.ClearAdvisors;
  payload: null;
}

export const clearAdvisors = (): ClearAdvisorsAction => {
  return {
    type: ActionTypes.ClearAdvisors,
    payload: null,
  };
};

export interface AssignToAdvisorAction {
  type: ActionTypes.AssignToAdvisor;
  payload: {
    estate: AdvisorsEstates;
  };
}

export const assignToAdvisor = (
  estate: AdvisorsEstates
): AssignToAdvisorAction => {
  return {
    type: ActionTypes.AssignToAdvisor,
    payload: {
      estate,
    },
  };
};

export interface UnassignFromAdvisorAction {
  type: ActionTypes.UnassignFromAdvisor;
  payload: {
    advisorsEstates: AdvisorsEstates;
  };
}

export const unassignFromAdvisor = (
  advisorsEstates: AdvisorsEstates
): UnassignFromAdvisorAction => {
  return {
    type: ActionTypes.UnassignFromAdvisor,
    payload: {
      advisorsEstates,
    },
  };
};
