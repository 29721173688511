import { saveAs } from "file-saver";
import { callGet, callPost, logger } from "./index";
import { DataExport, ExportFormat } from "../models/DataExport";

export class DataExportApi {
  async listDataExports(
    advisorGroupId: number
  ): Promise<DataExport[] | undefined> {
    try {
      const response = await callGet(
        `${DataExport.path}/advisor-group/${advisorGroupId}`,
        true
      );
      if (response?.data) {
        return response.data?.map((data: any) => DataExport.fromJSON(data));
      } else {
        throw Error("Sorry, invalid response.");
      }
    } catch (error) {
      switch ((error as any)?.response?.status) {
        case 401:
          // window.document.location.href = `/app/estates`;
          throw Error("Sorry, you are not authorized to access this export.");
        case 404:
          throw Error("Sorry, the export was not found.");
        case 500:
          logger.error(error as Error);
          throw Error(
            "Sorry, there was a server error while downloading the export."
          );
        default:
          throw error;
      }
    }
  }

  async createDataExport(
    advisorGroupId: number,
    startDate: string,
    endDate: string,
    type: string,
    format: string
  ): Promise<number> {
    try {
      const response = await callPost(
        `${DataExport.path}/advisor-group/${advisorGroupId}/${type}/${format}`,
        {
          start_date: startDate,
          end_date: endDate,
        },
        true
      );
      if (response?.data) {
        return response?.data?.id;
      } else {
        throw Error("Sorry, invalid response.");
      }
    } catch (error) {
      switch ((error as any)?.response?.status) {
        case 401:
          // window.document.location.href = `/app/estates`;
          throw Error("Sorry, you are not authorized to access this export.");
        case 404:
          throw Error("Sorry, the export was not found.");
        case 500:
          logger.error(error as Error);
          throw Error(
            "Sorry, there was a server error while downloading the export."
          );
        default:
          throw error;
      }
    }
  }

  async downloadDataExport(advisorDataExportId: number): Promise<boolean> {
    try {
      const response = await callGet(
        `${DataExport.path}/${advisorDataExportId}`
      );
      if (response?.data) {
        // console.log("-- response=", response);
        // console.log("-- content type=", response.headers["content-type"]);
        const isJson =
          response.headers["content-type"].indexOf(ExportFormat.json) !== -1;
        const isCsv =
          response.headers["content-type"].indexOf(ExportFormat.csv) !== -1;
        // console.log("---- headers check", {
        //   conteType: response.headers["content-type"],
        //   isJson,
        //   isCsv,
        //   jsonType: ExportFormat.json,
        //   csvType: ExportFormat.csv,
        // });
        if (isJson) {
          // const file = new Blob([response.data], { type: "application/json" });
          // const fileURL = URL.createObjectURL(file);
          // window.open(fileURL);
          // console.log("--- making json file");
          const file = new Blob([JSON.stringify(response.data)], {
            type: "application/JSON",
          });
          saveAs(file, "atticus-data-export-" + advisorDataExportId + ".json");
        } else if (isCsv) {
          // console.log("--- making csv file");
          const file = new Blob([response.data], { type: "text/csv" });
          saveAs(file, "atticus-data-export-" + advisorDataExportId + ".csv");
          // const fileURL = URL.createObjectURL(file);
          // window.open(fileURL);
        } else {
          throw Error("Sorry, invalid format");
        }
        return true;
      } else {
        throw Error("Sorry, invalid response.");
      }
    } catch (error) {
      switch ((error as any)?.response?.status) {
        case 401:
          window.document.location.href = `/app/estates`;
          throw Error("Sorry, you are not authorized to access this export.");
        case 404:
          throw Error("Sorry, the export was not found.");
        case 500:
          logger.error(error as Error);
          throw Error(
            "Sorry, there was a server error while downloading the export."
          );
        default:
          throw error;
      }
    }
  }
}
