export type ProductJSON = {
  name?: string;
  description?: string;
  internalName?: string;
  isRecurring?: boolean;
  priceUsd?: number;
  priceValue?: number;
  productPriceLevelsId?: number;
};

export class Product {
  constructor(
    public name?: string,
    public description?: string,
    public internalName?: string,
    public isRecurring?: boolean,
    public priceUsd?: number,
    public priceValue?: number,
    public productPriceLevelsId?: number
  ) {}

  static fromJSON = (json: ProductJSON): Product => {
    if (json) {
      return new Product(
        json.name,
        json.description, //|| "Atticus DIY monthly subscription",
        json.internalName, //|| "diy_monthly_25",
        json.isRecurring,
        json.priceUsd,
        json.priceValue, //|| 1500,
        json.productPriceLevelsId // || 7
      );
    } else {
      return new Product();
    }
  };
}
