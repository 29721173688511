import React, { useEffect } from "react";
import { OpportunitiesFilter } from "../estate/EstateView/opportunities/OpportunitiesFilter";
import { Card, CardContent, Table, Typography } from "@mui/material";
import { OpportunitiesTable } from "./OpportunitiesTable";
import { getQueryStringParams } from "../../utils/getQueryStringParams";
import { useNavigate } from "react-router-dom";
import { fetchOpportunities, fetchOpportunityStatuses, fetchOpportunityTypes } from "../../actions/opportunityActions";
import { connect } from "react-redux";
import { StoreState } from "../../reducers";
import { TableBodyLoader } from "../shared/TableBodyLoader";

type ListParams = {
  page?: number;
  perPage?: number;
  oppTypeId?: number;
  oppStatusId?: number;
};

export const _EditableOpportunities = ({
  user,
  estate,
  opportunities,
  opportunitiesCount,
  financialConvos,
  disableAdd = false,
  fetchOpportunities,
  loadingOpportunities,
  opportunityStatuses,
  opportunityTypes,
}): JSX.Element => {
  const navigate = useNavigate();
  let { page, perPage, oppTypeId, oppStatusId } = getQueryStringParams();
  page = parseInt(page, 10) || 1;
  perPage = parseInt(perPage, 10) || 10;
  oppTypeId = parseInt(oppTypeId, 10) || 0;
  oppStatusId = parseInt(oppStatusId, 10) || 0;

  const handleFilter = (oppTypeId?: number, oppStatusId?: number) => {
    goToUpdatedList({ oppTypeId, oppStatusId });
  };

  const handlePageChange = async (page: number) => {
    goToUpdatedList({ page });
  };

  const handlePerPageChange = (perPage: number) => {
    goToUpdatedList({ page: 1, perPage });
  };

  const goToUpdatedList = (params: ListParams) => {
    let url = location.pathname;
    url += `?page=${params.page || page}`;
    url += `&perPage=${params.perPage || perPage}`;
    if (params.oppTypeId !== 0) {
      url += `&oppTypeId=${params.oppTypeId || oppTypeId}`;
    }
    if (params.oppStatusId !== 0) {
      url += `&oppStatusId=${params.oppStatusId || oppStatusId}`;
    }
    navigate(url);
  };

  useEffect( () => {  
    if(opportunityTypes && opportunityTypes.length > 0) {
      return
    }
    fetchOpportunityTypes() 
  }, []) 
  useEffect( () => {  
    if(opportunityStatuses && opportunityStatuses.length > 0) {
      return
    }
    fetchOpportunityStatuses() 
  }, []) 


  useEffect(() => {
    // The fetcher uses these when creating opportunity objects
    if(!opportunityStatuses || !opportunityTypes) {
      return
    }
    const status = opportunityStatuses?.find(os => os.id === oppStatusId)
    const type = opportunityTypes?.find(ot => ot.id === oppTypeId)
    const offset = (page - 1) * perPage;
    fetchOpportunities(
      type? [type] : opportunityTypes, 
      status ? [status]: opportunityStatuses, 
      estate ? [estate.id]:[], 
      perPage, offset, undefined, undefined, opportunities?.length || 0);
  }, [page, perPage, oppTypeId, oppStatusId, estate, opportunityStatuses, opportunityTypes]);

  return (
    <>
      <OpportunitiesFilter
        oppTypeId={oppTypeId}
        oppStatusId={oppStatusId}
        onFilter={handleFilter}
      />
      <Card>
        <CardContent>
          {(opportunities && opportunities?.length > 0) ? (
            <OpportunitiesTable
              opportunityStatuses={opportunityStatuses}
              // filtered={oppTypeId || oppStatus} // enables showing zero results due to filtering rather than zero from db
              opportunities={opportunities}
              financialConvos={financialConvos}
              user={user}
              total={opportunitiesCount}
              page={page}
              perPage={perPage}
              onPageChange={handlePageChange}
              onPerPageChange={handlePerPageChange}
              estate={estate}
              minWidth={800}
              disableAdd={disableAdd}
              noPad={true}
            />
          ) : (loadingOpportunities?.loading) ? <>
            <Table style={{width:"100%", marginTop:"60px", marginBottom:"60px"}}>
              <TableBodyLoader colSpan={12} rows={perPage}  />
            </Table>
          </> : (
            <Typography variant="h4">
              No results. Try updating your filter selection.
            </Typography>
          )}
        </CardContent>
      </Card>
    </>
  );
};

const mapStateToProps = ({
  user,
  opportunities,
  opportunitiesCount,
  financialConvos,
  loadingOpportunities,
  opportunityStatuses,
  opportunityTypes,
}: StoreState) => {
  return { opportunities, opportunitiesCount, financialConvos, user, loadingOpportunities, opportunityStatuses, opportunityTypes };
};

export const EditableOpportunities = connect(mapStateToProps, {
  fetchOpportunities,
  fetchOpportunityStatuses,
  fetchOpportunityTypes,
})(_EditableOpportunities);
