import React from "react";
import { Box, Typography } from "@mui/material";
import { isLocal, isProd } from "../../constants";

interface Props {
  children: JSX.Element | JSX.Element[];
  mini?: boolean;
  onlyLocal?: boolean;
}

export const PreProdOnlyWrapper = ({
  children,
  mini,
  onlyLocal,
}: Props): JSX.Element => {
  let show = !isProd;
  if (onlyLocal) {
    show = isLocal;
  }
  return show ? (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        my: mini ? 0 : 4,
        background: "#eee",
      }}
    >
      {!mini && (
        <Typography variant="body2">
          {onlyLocal ? "LOCAL" : "DEV"} ONLY:{" "}
        </Typography>
      )}
      {children}
    </Box>
  ) : (
    <></>
  );
};
