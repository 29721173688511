export enum ExportType {
  aggregate = "aggregate",
  individual = "individual",
  both = "both",
}

export enum ExportFormat {
  csv = "csv",
  json = "json",
}

export enum ExportStatus {
  ready = "ready",
  processing = "processing",
  failed = "failed",
}

export type DataExportJSON = {
  id?: number;
  advisor_group_id?: number;
  creator_advisor_id?: number;
  format?: ExportFormat;
  status?: ExportStatus;
  type?: ExportType;
  created_at?: string;
  start_date?: string;
  end_date?: string;
};

export class DataExport {
  static path = "/export";

  constructor(
    public id?: number,
    public advisorGroupId?: number,
    public creatorAdvisorId?: number,
    public format?: ExportFormat,
    public status?: ExportStatus,
    public type?: ExportType,
    public createdAt?: string,
    public startDate?: string,
    public endDate?: string
  ) {}

  static fromJSON = (json: DataExportJSON): DataExport => {
    return new DataExport(
      json.id,
      json.advisor_group_id,
      json.creator_advisor_id,
      json.format,
      json.status,
      json.type,
      json.created_at,
      json.start_date,
      json.end_date
    );
  };
}
