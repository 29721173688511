import React, { useState } from "react";
import { Storage } from "aws-amplify"
import {
  Avatar,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Divider,
  Theme,
  Typography,
} from "@mui/material";
import { makeStyles } from "tss-react/mui";
import { CognitoUserAttributes, User } from "../../../models/User";
import { authApi, logger } from "../../../apis";
import { MAX_PHOTO_BYTES, PROFILE_PHOTO_PREFIX } from "../../../constants";
import { StoragePutResult } from "../../../apis/CognitoAuth";

const useStyles = makeStyles()((theme:Theme) => ({
  root: {},
  avatar: {
    height: 100,
    width: 100,
  },
  uploadBlock: {
    padding: "0 10px",
    textAlign: "center",
    width: "100%",
  },
}));

interface Props {
  user?: User;
  getUser: () => void;
  className?: string;
  rest?: any[];
}

export const ProfileAvatar = ({
  user,
  getUser,
  className,
  ...rest
}: Props): JSX.Element => {
  const { classes } = useStyles();
  const [error, setError] = useState("");
  const [uploading, setUploading] = useState(false);

  const onChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    setError("");
    setUploading(true);
    try {
      let file:any = null
      if(e && e.target && e.target.files && e.target.files.length > 0) {
        file = e.target.files[0]
      }
      if(validateFile(file)) {
        const fileName = createFileName(file);
        if (fileName) {
          // 1. upload the photo
          const result = (await Storage.put(fileName, file, {
            level: "protected",
          })) as StoragePutResult;
          // TODO: setup lambda trigger to reduce the filesize
          if (result?.key) {
            // 2. save it to the cognito user pool record
            const attributes: CognitoUserAttributes = {};
            attributes.picture = result?.key;
            await authApi.updateAttributes(attributes);
            getUser();
          } else {
            throw Error("Upload key is null");
          }
        } else {
          throw Error("Filename is null");
        }
      }
    } catch (error) {
      logger.error(error as Error);
      setError(
        `Sorry, there was an error uploading your profile photo. Error: ${
          (error as Error)?.message
        }.`
      );
    }
    setUploading(false);
  };

  const validateFile = (file: any): boolean => {
    if (!file) {
      throw Error("No file uploaded");
    }
    if (!file?.name) {
      throw Error("No file name");
    }
    if (!file?.size || file.size > +MAX_PHOTO_BYTES) {
      throw Error(
        `You profile photo is too large.  It must be less than ${
          +MAX_PHOTO_BYTES / 1000
        } KB`
      );
    }
    return true;
  };

  function createFileName(file: any) {
    const fileNameParts = file?.name?.split(".");
    const extension = fileNameParts[fileNameParts.length - 1];
    const username = user?.cognitoUsername;
    return PROFILE_PHOTO_PREFIX + username + "." + extension;
  }

  return (
    <Card className={`${classes.root}  ${className}`} {...rest}>
      <CardContent>
        <Box alignItems="center" display="flex" flexDirection="column">
          <Avatar className={classes.avatar} src={user?.pictureUrl}>
            {user?.initials}
          </Avatar>
          <Typography color="textPrimary" gutterBottom variant="h3">
            {user?.first} {user?.last}
          </Typography>
        </Box>
      </CardContent>
      <Divider />
      <CardActions>
        <div className={classes.uploadBlock}>
          {uploading ? (
            <Typography variant="body2">uploading...</Typography>
          ) : (
            <Button color="primary" fullWidth variant="text" component="label">
              Upload photo
              <input
                type="file"
                accept="image/*"
                onChange={onChange}
                onClick={() => setError("")}
                hidden
              />
            </Button>
          )}
          <Typography color="error" variant="body2">
            {error}
          </Typography>
        </div>
      </CardActions>
    </Card>
  );
};
