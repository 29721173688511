import React from "react";
import { Box, Link, Typography, Theme } from "@mui/material";

export const FormsListZeroState = (): JSX.Element => {
  return (
    <Box
      sx={(theme: Theme) => ({
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
        padding: theme.breakpoints.down("sm") ? "1rem" : "3rem",
        textAlign: "center",
      })}
    >
      <Box
        component="img"
        src="/static/icons/forms-zero-state-icon.svg"
        alt="Abstract cartoon icon of people looking for files on a computer"
        sx={{
          marginBottom: "2.5rem",
          maxWidth: "100%",
          width: "285px",
        }}
      />
      <Typography
        variant="h4"
        color="primary.dark"
        sx={{
          marginBottom: "1.125rem",
          fontWeight: "800",
        }}
      >
        {"Hmm... it doesn’t seem like we have any forms matching that search."}
      </Typography>

      <Typography color="primary.dark">
        <>{"If you’re looking for a specific form, please send an email to "}</>
        <Link
          href="mailto:support@weareatticus.com"
          sx={{
            textDecoration: "none",
            "&:hover": {
              textDecoration: "none",
            },
          }}
        >
          {"support@weareatticus.com"}
        </Link>
        <>{" and we’ll work on adding it to our forms library."}</>
      </Typography>
    </Box>
  );
};
