import React from "react";
import { Check } from "./AtticusIcons";
import { Box } from "@mui/material";

export const BigCircledCheck = (): JSX.Element => {
  return (
    <Box
      sx={{
        backgroundColor: "grey.200",
        borderRadius: "50%",
        width: 100,
        height: 100,
        mb: 2,
      }}
    >
      <Check
        sx={{
          position: "relative",
          top: "10%",
          width: "60%",
          height: "100%",
          color: "primary.main",
        }}
      />
    </Box>
  );
};
