import {
  Box,
  Button,
  Grid,
  MenuItem,
  Select,
  TextField,
  Theme,
  Typography,
} from "@mui/material";
import { makeStyles } from "tss-react/mui";
import React, { useState } from "react";
import { Formik, FormikHelpers } from "formik";
const Yup = require("yup")
import { estateApi, logger } from "../../../apis";
import { connect } from "react-redux";
import { AdvisorGroup } from "../../../models/AdvisorGroup";
import { saveAdvisorGroup } from "../../../actions/advisorGroupActions";
import demoEstates from "./demoEstates.json";
import JSONPretty from "react-json-pretty";
import { JSON_INVALID_MSG, SCREEN_PATHS } from "../../../constants";
import { Link, useNavigate } from "react-router-dom";

const useStyles = makeStyles()((theme: Theme) => ({
  formWrapper: {
    padding: theme.spacing(0, 1),
  },
  inputs: {
    backgroundColor: theme.palette.background.paper,
  },
  buttonWrapper: {
    display: "flex",
    flexDirection: "row-reverse",
  },
  buttonsLeft: {
    "& > Button": {
      marginRight: theme.spacing(1),
    },
  },
  columnRight: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
  },
  button: {
    margin: "2px 0",
  },
  status: {
    padding: theme.spacing(0, 1),
  },
}));

const REQUIRED_FIELDS = [
  "phone",
  "email",
  "password",
  "countries_id",
  "languages_id",
  "birthdate",
  "first_name",
  "last_name",
  "name",
  "has_passed",
];

interface Values {
  estateJSON: string;
  advisorGroup: string;
}

interface Props {
  advisorGroups: AdvisorGroup[];
}

export const _DemoEstatesForm = ({ advisorGroups }: Props): JSX.Element => {
  const { classes } = useStyles();
  const navigate = useNavigate();
  const [isJSONInvalid, setIsJSONInvalid] = useState(false);
  const [estatesCreated, setEstatesCreated] = useState<number[]>();

  // https://formik.org/docs/api/formik
  const formikInit = {
    values: {
      estateJSON: JSON.stringify(demoEstates), // insert default
      advisorGroup: (advisorGroups && advisorGroups[0]?.title) || "",
    },
    schema: Yup.object().shape({
      estateJSON: Yup.string().required("demo estates json is required"),
      advisorGroup: Yup.string().required(),
    }),
  };

  const handleSubmit = async (
    values: Values,
    { setSubmitting, setStatus }: FormikHelpers<Values>
  ) => {
    setStatus("");
    try {
      // validate json
      //  1. parse the string to json
      //  2. validate it's an array with one or more elements
      //  3. validate each element has the required fields
      try {
        if (isJSONInvalid) {
          throw Error(JSON_INVALID_MSG);
        }
        // should be an array of estate/user objects
        const parsedJson = JSON.parse(values.estateJSON);
        if (parsedJson.constructor !== Array) {
          throw Error(JSON_INVALID_MSG);
        }
        let missingFieldsString = "";
        parsedJson.forEach((estateObjectJSON: any, index: number) => {
          const fields: string[] = [];
          REQUIRED_FIELDS.forEach((field: string) => {
            if (!estateObjectJSON[field]) fields.push(field);
          });
          if (fields.length > 0) {
            const objectIsMissing = `[${index.toString()}] ${fields.join(
              ", "
            )};`;
            missingFieldsString +=
              missingFieldsString === ""
                ? objectIsMissing
                : " " + objectIsMissing;
          }
        });
        if (missingFieldsString) {
          throw Error("missing fields" + missingFieldsString);
        }
      } catch (error) {
        // catch validation errors
        setStatus("Sorry, error: " + ((error as Error)?.message || ""));
        setSubmitting(false);
        return;
      }

      const advisorGroupId =
        advisorGroups.filter(
          (advisorGroup: AdvisorGroup) =>
            advisorGroup.title === values.advisorGroup
        )[0]?.id || undefined;

      if (advisorGroupId) {
        const response = await estateApi.createDemoEstates(
          values.estateJSON,
          advisorGroupId
        );
        if (response?.data?.data?.estates_created) {
          setEstatesCreated(response?.data?.data?.estates_created);
        } else {
          throw new Error("couldn't create estates");
        }
      } else {
        setStatus("Sorry, error: couldn't determine selected advisor group");
      }
      setSubmitting(false);
    } catch (error) {
      // catch network or unexpected errors
      setStatus("Sorry, error: " + ((error as Error)?.message || ""));
      logger.error(error as Error);
      setSubmitting(false);
    }
  };

  return (
    <div className={classes.formWrapper}>
      <Formik
        initialValues={formikInit.values}
        validationSchema={formikInit.schema}
        onSubmit={handleSubmit}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          touched,
          values,
          resetForm,
          status,
          setStatus,
        }) => (
          <form onSubmit={handleSubmit}>
            <Grid
              container
              spacing={1}
              alignItems="flex-start"
              justifyContent="flex-end"
            >
              <Grid item sm={6} xs={12}>
                <Box mb={2}>
                  <ul>
                    <li>
                      Copy and paste the json below into your favorite text
                      editor
                      <ul>
                        <li>
                          or{" "}
                          <a
                            href="https://www.jsonformatter.io/"
                            target="_blank"
                          >
                            jsonformatter
                          </a>
                        </li>
                      </ul>
                    </li>
                    <li>Make your desired updates.</li>
                    <li>
                      Copy and paste those updates back into the input below.
                    </li>
                    <li>Click "Create Estates"</li>
                  </ul>
                </Box>
                <TextField
                  name="estateJSON"
                  label="new demo estate json (array of estate objects)"
                  variant="outlined"
                  className={classes.inputs}
                  multiline
                  minRows={30}
                  value={values.estateJSON}
                  error={Boolean(touched.estateJSON && errors.estateJSON)}
                  onBlur={handleBlur}
                  onChange={(evt) => {
                    window.setTimeout(() => {
                      setIsJSONInvalid(false);
                      setStatus("");
                    }, 0);
                    handleChange(evt);
                  }}
                  style={{ width: "100%" }}
                />
                <Typography variant="body1" color="error">
                  {errors.estateJSON}
                </Typography>
              </Grid>
              <Grid item sm={6} xs={12}>
                {isJSONInvalid && (
                  <Typography color="error">
                    Your JSON is invalid. Please fix.
                  </Typography>
                )}
                <JSONPretty
                  id="json-pretty"
                  data={values.estateJSON}
                  onJSONPrettyError={() => {
                    setIsJSONInvalid(true);
                  }}
                />
              </Grid>
              <Grid item xs={12} style={{ textAlign: "right" }}>
                <Select
                  name="advisorGroup"
                  value={values.advisorGroup}
                  onChange={handleChange}
                  variant="outlined"
                  // displayEmpty={true}
                >
                  {advisorGroups?.map((advisorGroup: AdvisorGroup) => {
                    return (
                      <MenuItem
                        value={advisorGroup.title}
                        key={advisorGroup.id}
                      >
                        {advisorGroup.title}
                      </MenuItem>
                    );
                  })}
                </Select>
              </Grid>
              <Grid item xs={12} className={classes.buttonWrapper}>
                {/* NOTE: keyboarding tabbing will reach this first*/}
                <Grid item xs={6} className={classes.columnRight}>
                  <Typography
                    variant="body1"
                    color="error"
                    style={{ marginRight: 8 }}
                  >
                    {status}
                  </Typography>
                  <Button
                    disabled={isSubmitting}
                    variant="contained"
                    color="primary"
                    type="submit"
                    className={classes.button}
                  >
                    Create Estates
                  </Button>
                </Grid>
                <Grid item xs={6} className={classes.buttonsLeft}>
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={() => {
                      resetForm();
                      setIsJSONInvalid(false);
                    }}
                    className={classes.button}
                  >
                    Reset
                  </Button>{" "}
                  (replace json above with example json)
                </Grid>
              </Grid>
              <Grid item xs={12} style={{ textAlign: "right" }}>
                {estatesCreated && (
                  <Box>
                    <Typography variant="h3">🤖 Estates Created!</Typography>
                    {estatesCreated.map((estateId: number) => {
                      return (
                        <Typography>
                          <Link to={`${SCREEN_PATHS.estates}/${estateId}`}>
                            estate #{estateId}
                          </Link>
                        </Typography>
                      );
                    })}
                    <Button
                      color="secondary"
                      variant="contained"
                      onClick={() => {
                        navigate("/app/estates");
                      }}
                    >
                      View estate list screen
                    </Button>
                  </Box>
                )}
              </Grid>
            </Grid>
          </form>
        )}
      </Formik>
    </div>
  );
};

// const mapStateToProps = ({
//   user,
// }: StoreState): {
//   user: User;
// } => {
//   return {
//     user,
//   };
// };

export const DemoEstatesForm = connect(null, {
  saveAdvisorGroup,
})(_DemoEstatesForm);
