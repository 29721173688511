import React from "react";
import {
  Card,
  CardContent,
  CardHeader,
  Container,
  Grid,
  Theme,
} from "@mui/material";
import { makeStyles } from "tss-react/mui";
import Page from "../../../components/Page";
import ContentHeader from "../../shared/ContentHeader";
import { ATTICUS_PAGE_PREFIX } from "../../../constants";
import { LogTest } from "./LogTest";
import { OpportunitiesTest } from "./OpportunitiesTest";
import { InviteEmail } from "./InviteEmail";
import { StoreState } from "../../../reducers";
import { User } from "../../../models/User";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";

const useStyles = makeStyles()((theme: Theme) => ({
  root: {
    backgroundColor: theme.palette.background.default,
    minHeight: "100%",
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
}));

interface Props {
  user: User;
}

const _TestView = ({ user }: Props): JSX.Element => {
  const { classes } = useStyles();
  const navigate = useNavigate();
  const hasAccess = user.isUberAdmin;

  if (!hasAccess) {
    window.setTimeout(() => {
      navigate(`/app/dashboard`);
    }, 0);
  }

  return (
    <Page className={classes.root} title={`${ATTICUS_PAGE_PREFIX} - Test`}>
      <Container maxWidth={false}>
        <ContentHeader heading="Test" subHeading="" />
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <LogTest />
          </Grid>
          {user.isUberAdmin && (
            <Grid item xs={12}>
              <Card>
                <CardHeader title="opportunities test" />
                <CardContent>
                  <OpportunitiesTest />
                </CardContent>
              </Card>
            </Grid>
          )}
          <Grid item xs={12}>
            <Card>
              <CardHeader title="send invite email test" />
              <CardContent>
                <InviteEmail />
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
};

const mapStateToProps = ({ user }: StoreState): { user: User } => {
  return { user };
};

export const TestView = connect(mapStateToProps)(_TestView);
