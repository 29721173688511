import React from "react";
import { Container, Theme } from "@mui/material";
import { makeStyles } from "tss-react/mui";
import Page from "../../../components/Page";
import ContentHeader from "../../shared/ContentHeader";
import { ATTICUS_PAGE_PREFIX } from "../../../constants";
import { DataExportView } from "./DataExport";
import { useNavigate } from "react-router-dom";
import { StoreState } from "../../../reducers";
import { User } from "../../../models/User";
import { connect } from "react-redux";

const useStyles = makeStyles()((theme: Theme) => ({
  root: {
    backgroundColor: theme.palette.background.default,
    minHeight: "100%",
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
}));

interface Props {
  user: User;
}

const _MetricsView = ({ user }: Props): JSX.Element => {
  const { classes } = useStyles();

  const navigate = useNavigate();

  const hasAccess = user?.hasMetricsToolAccess;

  if (!hasAccess) {
    window.setTimeout(() => {
      navigate(`/app/dashboard`);
    }, 0);
  }

  return (
    <Page
      className={classes.root}
      title={`${ATTICUS_PAGE_PREFIX} - Metrics`}
      data-automation-id="page-content-metrics"
    >
      {hasAccess ? (
        <Container maxWidth={false}>
          <ContentHeader heading="Metrics" showSearch={false} />
          <DataExportView />
        </Container>
      ) : (
        <></>
      )}
    </Page>
  );
};

const mapStateToProps = ({ user }: StoreState): { user: User } => {
  return { user };
};

export const MetricsView = connect(mapStateToProps, {})(_MetricsView);
