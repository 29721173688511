export type TaskTypesJSON = {
  id: number;
  type: string;
};

export enum TaskTypeById {
  "Terms" = 1,
  "Tip_Feel_Good" = 2,
  "Must_do_statement" = 3,
  "Must_do_question" = 4,
  "Question_w_data_entry" = 5,
  "Educational" = 6,
}

export class TaskTypes {
  constructor(public id: number, public type: string) {}

  static fromJSON(json: TaskTypesJSON): TaskTypes {
    return new TaskTypes(json.id, json.type);
  }

  static from(taskTypes: TaskTypes): TaskTypes {
    return new TaskTypes(taskTypes.id, taskTypes.type);
  }
}
