import {
  AngryIcon,
  OkIcon,
  SmileIcon,
  WorriedIcon,
  QuestioningIcon,
  ShadesIcon,
} from "../../../shared/EmotionIcons";

const iconStyles = { width: 50, height: 50, padding: "6px" } as const;
const iconViewBox = "0 0 48 48";

interface emotionAnswer {
  text: string;
  icon?: JSX.Element;
}

interface genericAnswer {
  text: string;
}

export const financialFeelingsOutcomes: { [key: string]: emotionAnswer } = {
  notSoGreat: {
    text: "Not so great",
    icon: <WorriedIcon style={iconStyles} viewBox={iconViewBox} />,
  },
  ok: {
    text: "Ok",
    icon: <OkIcon style={iconStyles} viewBox={iconViewBox} />,
  },
  iveGotThis: {
    text: "I've got this",
    icon: <SmileIcon style={iconStyles} viewBox={iconViewBox} />,
  },
};

export const financialExperienceOutcomes: { [key: string]: emotionAnswer } = {
  beginner: {
    text: "Beginner",
    icon: <QuestioningIcon style={iconStyles} viewBox={iconViewBox} />,
  },
  intermediate: {
    text: "Intermediate",
    icon: <OkIcon style={iconStyles} viewBox={iconViewBox} />,
  },
  advanced: {
    text: "Advanced",
    icon: <ShadesIcon style={iconStyles} viewBox={iconViewBox} />,
  },
};

export const discussionTopicsOutcomes: { [key: string]: genericAnswer } = {
  inheritingFinancialAccounts: {
    text: "Inheriting Financial Accounts",
  },
  rebalancingManagingPortfolio: {
    text: "Rebalancing and managing a portfolio",
  },
  retirementVsNon: {
    text: "Retirement versus non-retirement accounts",
  },
  sellingRetitlingHome: {
    text: "Selling or re-titling a home",
  },
  splittingInheritance: {
    text: "Distributing/splitting inheritance among beneficiaries",
  },
  somethingElse: {
    text: "Something else",
  },
};

export const assetsOutcomes: { [key: string]: genericAnswer } = {
  rentalRE: {
    text: "Rental real estate property (non-primary residence)",
  },
  investment: {
    text: "Investment / brokerage accounts",
  },
  retirement: {
    text: "Retirement accounts",
  },
  business: {
    text: "Own one or more businesses",
  },
  multiStateAssets: {
    text: "Assets in multiple {states}",
  },
  jewelry: {
    text: "Jewelry",
  },
  insuranceAccounts: {
    text: "Insurance accounts",
  },
  vehicles: {
    text: "Vehicles (e.g. boat, plane, car, tractor)",
  },
  collections: {
    text: "Accounts in collections",
  },
  trust: {
    text: "There is a trust",
  },
  none: {
    text: "None of these assets",
  },
};

export const beneRelationshipStatusOutcomes: {
  [key: string]: emotionAnswer;
} = {
  notTalking: {
    text: "Not talking",
    icon: <AngryIcon style={iconStyles} viewBox={iconViewBox} />,
  },
  ok: {
    text: "Ok",
    icon: <OkIcon style={iconStyles} viewBox={iconViewBox} />,
  },
  great: {
    text: "Great",
    icon: <SmileIcon style={iconStyles} viewBox={iconViewBox} />,
  },
};
