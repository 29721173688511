import React, { useEffect } from "react";
import { connect } from "react-redux";
import { EditableSelect } from "./EditableSelect";
import { StoreState } from "../../../../../../reducers";
import { State } from "../../../../../../models/Geography";
import { fetchStates } from "../../../../../../actions/geographyActions";
import { SxProps } from "@mui/system/styleFunctionSx";

interface Props {
  isEditMode?: boolean;
  name?: string;
  value?: string | number;
  onChange?: (e: any) => void;
  error?: string;
  sx?: SxProps;
  states?: State[];
  fetchStates?: () => void;
}

const _StateSelect = ({
  isEditMode = false,
  name,
  value,
  onChange,
  error,
  sx,
  states,
  fetchStates,
}: Props) => {
  useEffect(() => {
    if (!states || !states?.length) {
      fetchStates && fetchStates();
    }
  }, []);

  return (
    <EditableSelect
      label="State"
      name={name}
      isEditMode={isEditMode}
      value={value}
      options={states?.map((state: State) => {
        return {
          label: state.name,
          value: state.id,
        };
      })}
      onChange={onChange}
      error={error}
      sx={sx}
    />
  );
};

const mapStateToProps = ({
  states,
}: StoreState): {
  states: State[];
} => {
  return {
    states,
  };
};

export const StateSelect = connect(mapStateToProps, {
  fetchStates,
})(_StateSelect);
