import React, { ChangeEvent, DragEvent, useEffect, useState } from "react";
import { Box, Button, LinearProgress, Typography } from "@mui/material";
import { FILE_UPLOAD_TYPES, MAX_PHOTO_BYTES } from "../../../../../constants";
import PhotoCameraIcon from "@mui/icons-material/PhotoCamera";
import { assetApi } from "../../../../../apis";
import { base64Encode, ImageToUpload } from "../../../../../apis/Assets";
import { AssetImage } from "../../../../../models/AssetImage";
import { AsyncAssetImage, Dimensions } from "../AsyncAssetImage";
import { SxProps } from "@mui/system/styleFunctionSx";
import { ImageCarouselDialog } from "../ImageCarouselDialog";
import { ConfirmationDialog } from "../../../../shared/dialog/ConfirmationDialog";
import { DeleteContent } from "../DeleteContent";
import { useSnackbar } from "notistack";

interface Props {
  assetId?: number;
  assetImages?: AssetImage[];
  estateId?: number;
  estateWritable?: boolean;
  assetIdUploadTrigger?: number; // will contain the assetId
  uploadComplete?: (success: boolean) => void;
  removeImage?: (imageId?: number) => void;
}

const imagesBackgroundColor = "#F2F2FF";

export const AssetDialogImages = ({
  assetId,
  assetImages = [],
  estateId,
  estateWritable,
  assetIdUploadTrigger,
  uploadComplete,
  removeImage,
}: Props): JSX.Element => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const isNew = !assetId;
  const inputRef = React.useRef(null);

  const [errors, setErrors] = useState<string[]>([]);
  const [dragActive, setDragActive] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  // const [assetImagePath, setAssetImagePath] = useState<string>();
  const [assetImagePath] = useState<string>();
  // const [processingImage, setProcessingImage] = useState(false);
  const [imagesToUpload, setImagesToUpload] = useState<ImageToUpload[]>([]);
  const [imageCarouselOpen, setImageCarouselOpen] = useState(false);
  // this manages the visibility of the delete image dialog
  const [deleteAssetImage, setDeleteAssetImage] = useState<
    AssetImage | undefined
  >();

  useEffect(() => {
    // handle event coming in from parent element - save click event
    if (assetIdUploadTrigger && assetIdUploadTrigger > 0) {
      handleUpload(assetIdUploadTrigger);
    }
  }, [assetIdUploadTrigger]);

  // handle drag events
  const handleDrag = function (e: DragEvent<HTMLFormElement | HTMLDivElement>) {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === "dragenter" || e.type === "dragover") {
      setDragActive(true);
    } else if (e.type === "dragleave") {
      setDragActive(false);
    }
  };

  // triggers when file is dropped
  const handleDrop = function (e: DragEvent) {
    setErrors([]);
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
    if (e?.dataTransfer?.files && e?.dataTransfer?.files[0]) {
      // at least one file has been dropped so do something
      validate(e.dataTransfer.files);
    }
  };

  // triggers when file is selected with click
  const handleChange = function (e: ChangeEvent<HTMLInputElement>) {
    setErrors([]);
    e.preventDefault();
    if (e.target.files && e.target.files[0]) {
      // at least one file has been selected so do something
      // console.log(e.target.files);
      validate(e.target.files);
    }
  };

  const validate = async (files: FileList): Promise<void> => {
    if (files.length === 0) {
      setErrors(["Please drop or select a file."]);
      return;
    }
    const images = [...imagesToUpload];
    const errs: string[] = [];
    for (let i = 0; i < files.length; i++) {
      const file = files.item(i);
      if (file) {
        let valid = true;
        if (file.size > MAX_PHOTO_BYTES) {
          console.error(file.size, ">", MAX_PHOTO_BYTES);
          errs.push(`"${file.name}" is too large`);
          valid = false;
        }
        if (!FILE_UPLOAD_TYPES.includes(file.type)) {
          console.error(file.type, "!includes", FILE_UPLOAD_TYPES);
          errs.push(`"${file.name}" file type: ${file.type} not accepted`);
          valid = false;
        }
        if (valid) {
          images.push(await createImageToBeUploaded(file));
        }
      } else {
        errs.push("no file");
      }
    }
    setImagesToUpload(images);
    if (errs?.length) {
      setErrors(errs);
    }
  };

  const createImageToBeUploaded = async (
    file: File
  ): Promise<ImageToUpload> => {
    // base 64 encode the image and add it to list of images to be uploaded
    const base64Encoded = await base64Encode(file);
    // this.imgSrc = base64Encoded; // this set the display image
    return {
      id: file.name + "-waiting-to-upload-" + Date.now(),
      url: file.name + "-waiting-to-upload-" + Date.now(),
      fileName: file.name,
      base64Data: base64Encoded,
      file,
    };
  };

  // triggers the input when the button is clicked
  const handleChooseClick = () => {
    const current = inputRef?.current as any
    if(current && current.click) {
      current.click()
    }
  };

  /**
   * Called from
   *  "save images" button - editing an existing asset
   *  parent component updating assetIdUploadTrigger - creating an new asset
   * @param assetId
   */
  const handleUpload = async (assetId?: number): Promise<void> => {
    setIsSubmitting(true);
    if (imagesToUpload.length > 0) {
      const uploadingSnackbar = enqueueSnackbar(
        <Typography>
          <strong>Uploading {imagesToUpload.length} photos</strong>
          <LinearProgress sx={{ my: 1 }} />
        </Typography>
      );
      for (let f = 0; f < imagesToUpload.length; f++) {
        const fileName = imagesToUpload[f].fileName;
        // console.log("uploading file", fileName);
        try {
          const uploadResult = await assetApi.saveAssetImage(
            imagesToUpload[f],
            assetId,
            estateId
          );
          if (uploadResult?.file_name) {
            // console.log("image upload successful: ", uploadResult.file_name);
          } else {
            const message = `Photo upload failed: ${fileName}`;
            console.error(message);
            enqueueSnackbar(getSnackBarContent(message), {
              variant: "warning",
              // persist: true,
            });
            setErrors([...errors, message]);
          }
        } catch (error) {
          console.error(error);
          setErrors([...errors, `File upload failed: ${fileName}`]);
        }
      }
      uploadingSnackbar && closeSnackbar(uploadingSnackbar);
      enqueueSnackbar(getSnackBarContent("Upload complete"), {
        variant: "success",
      });
      setImagesToUpload([]); // clear
    }
    setIsSubmitting(false);
    uploadComplete?.(!errors?.length);
  };

  const handleDeleteImage = async () => {
    if (deleteAssetImage?.base64Data) {
      // just remove it from imagesToUpload
      setImagesToUpload(
        imagesToUpload?.filter(
          (image: ImageToUpload) =>
            image.base64Data !== deleteAssetImage.base64Data
        ) || []
      );
    } else if (deleteAssetImage?.id && assetId) {
      if (
        await assetApi.deleteAssetImage(estateId, assetId, deleteAssetImage.id)
      ) {
        removeImage?.(deleteAssetImage.id);
      }
    }
    enqueueSnackbar(getSnackBarContent("Photo deleted"), {
      variant: "success",
    });
    setDeleteAssetImage?.(undefined);
    setImageCarouselOpen?.(false);
  };

  const imageStyles = {
    single: {
      width: "100%",
    },
    double: {
      first: {
        width: "50%",
      },
      second: {
        width: "50%",
        ml: 1,
      },
    },
  };

  const getImageStyle = (count?: number, index?: number): SxProps => {
    switch (count) {
      case 1:
        return imageStyles.single;
      case 2:
        return index === 0
          ? imageStyles.double.first
          : imageStyles.double.second;
      default:
        return {};
    }
  };

  let images = assetImages;
  if (imagesToUpload?.length !== undefined) {
    images = [
      ...assetImages,
      ...imagesToUpload?.map((img: ImageToUpload) =>
        AssetImage.fromBase64(img.base64Data)
      ),
    ];
  }

  const haveImages = images?.length;

  const getSnackBarContent = (
    message?: string,
    icon?: JSX.Element
  ): JSX.Element => {
    return (
      <Typography sx={{ display: "flex" }}>
        {icon ? <Box sx={{ mr: 1 }}>{icon}</Box> : <></>}
        <strong>{message}</strong>
      </Typography>
    );
  };

  return (
    <Box>
      {/* delete image confirmation */}
      {deleteAssetImage && (
        <ConfirmationDialog
          open={!!deleteAssetImage}
          description={<DeleteContent assetImage={deleteAssetImage} />}
          onConfirm={() => handleDeleteImage()}
          confirmText="Delete Photo"
          maxWidth="md"
          confirmWide={true}
          onDeny={() => {
            setDeleteAssetImage(undefined);
          }}
          denyText="Cancel without deleting"
          denyWide={true}
          denyButtonVariant="outlined"
          buttonOrientation="row-reverse"
        />
      )}
      {imageCarouselOpen && (
        <ImageCarouselDialog
          open={imageCarouselOpen}
          images={images}
          onCancel={() => setImageCarouselOpen(false)}
          onDelete={
            estateWritable
              ? (image?: AssetImage) => {
                  setDeleteAssetImage(image);
                }
              : undefined
          }
        />
      )}
      <Box
        sx={{
          flexGrow: 1,
          borderWidth: estateWritable ? 2 : 0,
          borderRadius: 2,
          borderStyle: haveImages ? "solid" : "dashed",
          borderColor: haveImages ? "background.paper" : "primary.main",
          backgroundColor: dragActive ? "primary.light" : imagesBackgroundColor,
        }}
      >
        <form
          id="form-file-upload"
          style={{
            textAlign: "center",
            position: "relative",
          }}
          onDragEnter={!haveImages ? handleDrag : undefined}
          onSubmit={(e) => e.preventDefault()}
        >
          <input
            type="file"
            ref={inputRef}
            id="input-file-upload"
            multiple={true}
            style={{ display: "none" }}
            onChange={handleChange}
          />
          {!haveImages && estateWritable ? (
            <label
              id="label-file-upload"
              htmlFor="input-file-upload"
              style={{
                display: "flex",
                cursor: "pointer",
                alignItems: "center",
                justifyContent: "center",
                padding: "48px 16px",
              }}
            >
              {dragActive && (
                <Box
                  id="drag-file-element"
                  onDragEnter={handleDrag}
                  onDragLeave={handleDrag}
                  onDragOver={handleDrag}
                  onDrop={handleDrop}
                  sx={{
                    position: "absolute",
                    width: "100%",
                    height: "100%",
                    borderRadius: 0,
                    top: 0,
                    right: 0,
                    bottom: 0,
                    left: 0,
                  }}
                />
              )}
              <Box>
                <Typography
                  variant="h5"
                  sx={{
                    pb: 2,
                    fontWeight: 800,
                    color: "common.black",
                  }}
                >
                  {assetImagePath
                    ? "Choose a different file or drag it here"
                    : "Choose a file or drag it here"}
                </Typography>
                <Typography
                  variant="body2"
                  sx={{
                    mb: 2,
                  }}
                >
                  Accepted formats (5mb size limit): .jpg, .png, .gif
                </Typography>
                <Button
                  variant="outlined"
                  sx={{
                    backgroundColor: "background.paper",
                    "&:hover": {
                      backgroundColor: "background.paper",
                    },
                  }}
                  onClick={handleChooseClick}
                  startIcon={<PhotoCameraIcon />}
                  disabled={isSubmitting}
                >
                  Add Photos
                </Button>
              </Box>
            </label>
          ) : (
            <Box
              sx={{
                width: "100%",
                overflow: "hidden",
                position: "relative",
              }}
            >
              {estateWritable ? (
                <>
                  <Button
                    variant="outlined"
                    sx={{
                      position: "absolute",
                      right: 20,
                      bottom: 20,
                      backgroundColor: "background.paper",
                      "&:hover": {
                        backgroundColor: "background.paper",
                      },
                    }}
                    onClick={handleChooseClick}
                    startIcon={<PhotoCameraIcon />}
                    disabled={isSubmitting}
                  >
                    Add Photos
                  </Button>
                  {!isNew && imagesToUpload?.length ? (
                    <Button
                      variant="contained"
                      sx={{
                        position: "absolute",
                        right: 170,
                        bottom: 20,
                      }}
                      onClick={() => handleUpload(assetId)}
                      disabled={isSubmitting}
                    >
                      Save Photos
                    </Button>
                  ) : (
                    <></>
                  )}
                </>
              ) : (
                <></>
              )}
              <Box
                sx={{
                  cursor: "pointer",
                  display: "flex",
                  height: images.length || estateWritable ? 340 : 0,
                  width: "100%",
                  backgroundColor: "common.white",
                }}
                onClick={() => setImageCarouselOpen(true)}
              >
                {images.length <= 2 ? (
                  // less than 3 image layout
                  images.map((assetImage: AssetImage, index: number) => (
                    <React.Fragment
                      key={`AssetDialogImages_${Math.floor(
                        Math.random() * 1000
                      )}`}
                    >
                      <AsyncAssetImage
                        assetImage={assetImage}
                        sx={{
                          ...getImageStyle(images.length, index),
                          backgroundColor: imagesBackgroundColor,
                          borderRadius: 2,
                        }}
                        dimensions={Dimensions.x720}
                        allowVertCrop={false}
                      />
                    </React.Fragment>
                  ))
                ) : (
                  // 3 or more image layout
                  <Box
                    sx={{
                      width: "100%",
                      display: "flex",
                      backgroundColor: "common.white",
                    }}
                  >
                    <Box
                      sx={{
                        flex: 1,
                        mr: 1,
                        backgroundColor: imagesBackgroundColor,
                        borderRadius: 2,
                      }}
                      key={`AssetDialogImages_${Math.floor(
                        Math.random() * 1000
                      )}`}
                    >
                      <AsyncAssetImage
                        assetImage={images[0]}
                        sx={{
                          height: "100%",
                          width: "100%",
                        }}
                        dimensions={Dimensions.x1440}
                        allowVertCrop={false}
                      />
                    </Box>
                    <Box
                      sx={{
                        width: 200,
                      }}
                    >
                      {images.slice(1, 3).map((assetImage: AssetImage) => (
                        <React.Fragment
                          key={`AssetDialogImages_${Math.floor(
                            Math.random() * 1000
                          )}`}
                        >
                          <AsyncAssetImage
                            assetImage={assetImage}
                            sx={{
                              width: 200,
                              height: 174,
                              pb: 1,
                            }}
                            dimensions={Dimensions.x238}
                          />
                        </React.Fragment>
                      ))}
                    </Box>
                  </Box>
                )}
              </Box>
            </Box>
          )}
          {assetIdUploadTrigger ? (
            <Typography sx={{ m: 1 }}>uploading...</Typography>
          ) : (
            <></>
          )}
          {errors?.length > 0 ? (
            errors.map((error: string) => (
              <Typography color="error" key={error} sx={{ m: 1 }}>
                {error}
              </Typography>
            ))
          ) : (
            <></>
          )}
        </form>
      </Box>
    </Box>
  );
};
