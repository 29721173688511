import React from "react";
import { Container, Grid, Theme } from "@mui/material";
import { makeStyles } from "tss-react/mui";
import Page from "../../../components/Page";
import { ProfileAvatar } from "./ProfileAvatar";
import { ProfileAttributes } from "./ProfileAttributes";
import { ATTICUS_PAGE_PREFIX } from "../../../constants";
import { StoreState } from "../../../reducers";
import { User } from "../../../models/User";
import { connect } from "react-redux";
import { ThemeSwitcher } from "./ThemeSwitcher";
import { getUser } from "../../../actions/userActions";
import { UpdatePassword } from "./UpdatePassword";

const useStyles = makeStyles()((theme: Theme) => ({
  root: {
    backgroundColor: theme.palette.background.default,
    minHeight: "100%",
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
}));

interface Props {
  user?: User;
  getUser: () => void;
}

export const _ProfileView = ({ user, getUser }: Props): JSX.Element => {
  const { classes } = useStyles();

  return (
    <Page
      className={classes.root}
      title={`${ATTICUS_PAGE_PREFIX} - Profile`}
      data-automation-id="page-content-profile"
    >
      <Container maxWidth="lg">
        {user ? (
          <Grid container spacing={2}>
            <Grid item lg={4} md={6} xs={12}>
              <ProfileAvatar user={user} getUser={getUser} />
              <ThemeSwitcher />
            </Grid>
            <Grid item lg={8} md={6} xs={12}>
              <ProfileAttributes user={user} getUser={getUser} />
              <UpdatePassword />
            </Grid>
          </Grid>
        ) : (
          <></>
        )}
      </Container>
    </Page>
  );
};

const mapStateToProps = ({ user }: StoreState): { user: User } => {
  return { user };
};

export const ProfileView = connect(mapStateToProps, { getUser })(_ProfileView);
