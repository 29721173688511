import { ActionTypes } from "../actions/types";
import { AtticusForm } from "../models/AtticusForm";
import { AnyAction } from "redux";

export const estateFormsReducer = (
  estateForms: AtticusForm[] | null = null,
  action: AnyAction
): AtticusForm[] | null => {
  const { type, payload } = action;
  switch (type) {
    case ActionTypes.FlushEstateForms:
      return null;
    case ActionTypes.FetchEstateForms:
      return payload;
    case ActionTypes.AddEstateForm:
      if (estateForms) {
        const newEstateForms = [...estateForms];
        const index = newEstateForms.findIndex(
          (estateForm: AtticusForm) => estateForm.id === payload.id
        );
        if (index !== -1) {
          newEstateForms[index] = payload;
        } else {
          newEstateForms.push(payload);
        }
        return newEstateForms;
      } else {
        return estateForms;
      }
    case ActionTypes.RemoveEstateForm:
      if (estateForms) {
        const newEstateForms = [...estateForms];
        const index = newEstateForms.findIndex(
          (estateForm: AtticusForm) => estateForm.id === payload.form.id
        );
        if (index !== -1) {
          newEstateForms.splice(index, 1);
        }
        return newEstateForms;
      } else {
        return estateForms;
      }
    default:
      return estateForms;
  }
};
