import { Amplify } from 'aws-amplify';
import { amplifyStorageRegion, amplifyStorageBucket } from "../constants"

//
// This is built from the code example at https://docs.amplify.aws/javascript/build-a-backend/storage/existing-resources/
// Values were taken from the original setup in github: https://github.com/TheAtticusApp/atticus-advisor/blob/master/src/aws-exports.js
//
Amplify.configure({
    Storage: {
        S3: {
            region: amplifyStorageRegion,
            bucket: amplifyStorageBucket,
        }
    }
})