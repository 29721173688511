import {
  Box,
  Button,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Theme,
  useMediaQuery,
  Pagination,
} from "@mui/material";
import { makeStyles } from "tss-react/mui";
import { Opportunity } from "../../models/Opportunity";
import React, { useState } from "react";
import { AppTableWrapper } from "../shared/AppTableWrapper";
import PerfectScrollbar from "react-perfect-scrollbar";
import { AddRecordButton } from "../shared/AddRecordButton";
import { OpportunityForm } from "./OpportunityForm";
import { FinancialConvo } from "../../models/FinancialConvo";
import EditIcon from "@mui/icons-material/Edit";
import moment from "moment/moment";
import { TableBodyLoader } from "../shared/TableBodyLoader";
import { OpportunitiesZeroState } from "./OpportunitiesZeroState";
import { Estate } from "../../models/Estate";
import { useNavigate } from "react-router-dom";
import { financialConvosApi } from "../../apis";
import { User } from "../../models/User";
import { camelToSpaced } from "../../utils/camelToSpaced";
import { OpportunityStatus } from "../../models/OpportunityStatus";

const useStyles = makeStyles()((theme: Theme) => ({
  root: {
    position: "relative",
  },
  zeroWrapper: {
    textAlign: "center",
    padding: theme.spacing(2),
  },
  paginationWrapper: {
    display: "flex",
    justifyContent: "space-between",
  },
  mainLink: {
    textAlign: "left",
  },
  selected: {
    backgroundColor: theme.palette.background.default,
  },
}));

interface Props {
  opportunities?: Opportunity[];
  opportunityStatuses: OpportunityStatus[];
  financialConvos?: FinancialConvo[];
  user?: User;
  total?: number;
  page?: number;
  perPage?: number;
  onPageChange?: (newPage: number) => void;
  onPerPageChange?: (newPerPage: number) => void;
  estate?: Estate;
  noPad?: boolean;
  minWidth?: number;
  disableEdit?: boolean;
  disableAdd?: boolean;
}

export const OpportunitiesTable = ({
  opportunities,
  opportunityStatuses,
  financialConvos,
  user,
  total = 100,
  page = 0,
  perPage = 10,
  onPageChange,
  onPerPageChange,
  estate,
  minWidth,
  noPad = false,
  disableEdit = false,
  disableAdd = false,
}: Props): JSX.Element => {
  const { classes } = useStyles();
  const mediumAndUp = useMediaQuery((theme: Theme) =>
    theme.breakpoints.up("md")
  );
  const isLoading = opportunities === null;
  const navigate = useNavigate();
  const [hideZero, setHideZero] = useState<boolean>(false);
  const [showNewForm, setShowNewForm] = useState<boolean>(false);
  const [editId, setEditId] = useState<number>();
  const statusMap:{[key:number]: OpportunityStatus} = {}
  opportunityStatuses?.map(os => statusMap[os.id || 0] = os)

  const handlePageChange = (
    event: React.ChangeEvent<unknown> | null,
    page: number
  ) => {
    onPageChange && onPageChange(page);
  };

  const handlePerPageChange = (newPerPage: number) => {
    if (newPerPage !== perPage) {
      onPerPageChange && onPerPageChange(newPerPage);
    }
  };

  const getOppLink = async (opp: Opportunity) => {
    let link = "/app/estates";
    if (opp.estateId && opp.type) {
      link += `/${opp.estateId}`;
      switch (opp.type.title) {
        case "RealEstate":
        case "FinancialAccount":
          link += "/assets";
          break;
        case "Report":
          link += "/reports";
          break;
        case "TaskInactivity":
        case "NoTask":
          link += "/progress";
          break;
        case "FinancialConvo":
          if (opp.id) {
            let financialConvo = financialConvos?.filter(
              (financialConvo: FinancialConvo) =>
                financialConvo?.opportunityId == opp.id
            )[0];

            if (!financialConvo) {
              financialConvo =
                (await financialConvosApi.fetchSingleFinancialConvoFromOpp(
                  opp.id
                )) || undefined;
            }
            link += `/opportunities/financial-convos/${financialConvo?.id}`;
            break;
          } else {
            link += ""; // overview
            break;
          }
        default: // overview
          link += "";
          break;
      }
    } else if (opp.estateId) {
      link += `/${opp.estateId}`;
    }
    return link;
  };

  const routeToOpp = (event: any, opp: Opportunity) => {
    event.preventDefault();
    getOppLink(opp).then((oppLink) => {
      navigate(oppLink);
    });
  };

  const showZero = opportunities?.length === 0 && !isLoading;

  return (
    <>
      {showZero && !hideZero ? (
        <OpportunitiesZeroState
          disableEdit={disableEdit}
          onAddOpp={() => {
            setHideZero(true);
            setShowNewForm(true);
            setEditId(undefined);
          }}
        />
      ) : (
        <div className={classes.root}>
          {!disableAdd && !disableEdit && (
            <Box
              style={{
                position: "absolute",
                right: 0,
                zIndex: 10,
              }}
            >
              <AddRecordButton
                title="Add Opportunity"
                onClick={() => {
                  setShowNewForm(true);
                  setEditId(undefined);
                }}
              />
            </Box>
          )}
          <PerfectScrollbar
            options={{ suppressScrollY: true, useBothWheelAxes: false }}
          >
            <AppTableWrapper minWidth={minWidth} noPad={noPad}>
              <Table data-automation-id="opportunity-table">
                <TableHead>
                  <TableRow>
                    {/*<TableCell>Id</TableCell>*/}
                    <TableCell>Date</TableCell>
                    <TableCell>Details</TableCell>
                    <TableCell>Estate Name</TableCell>
                    <TableCell>Status</TableCell>
                    {!disableEdit && <TableCell align="right" />}
                  </TableRow>
                </TableHead>
                {isLoading ? (
                  <TableBodyLoader colSpan={5} rows={3} />
                ) : (
                  <TableBody>
                    {showNewForm && estate?.id && estate?.advisorGroupId && (
                      <OpportunityForm
                        estateId={estate.id}
                        estateName={estate.name}
                        advisorGroupId={estate.advisorGroupId}
                        opportunity={new Opportunity()}
                        onComplete={() => {
                          setShowNewForm(false);
                        }}
                        key="new-opp-form"
                      />
                    )}
                    {opportunities &&
                      opportunities.map((opp: Opportunity) => {
                        const hasEditAccess =
                          user?.isUberAdmin ||
                          (user?.isAdvisorAdmin &&
                            user?.advisorGroupId === opp.advisorGroupId) ||
                          user?.id === opp.advisorId;
                        return editId === opp.id &&
                          opp.estateId &&
                          opp.advisorGroupId ? (
                          <OpportunityForm
                            estateId={opp.estateId}
                            estateName={opp.estateName}
                            advisorGroupId={opp.advisorGroupId}
                            opportunity={opp}
                            onComplete={() => {
                              setEditId(undefined);
                            }}
                            key={opp.id + "-form"}
                          />
                        ) : (
                          <TableRow
                            hover
                            key={opp.title + "-" + opp.id + "-row"}
                            onClick={(event: any) => routeToOpp(event, opp)}
                            className="clickable"
                            style={{ height: 60 }}
                          >
                            {/*<TableCell>{opp.id}</TableCell>*/}
                            <TableCell>
                              {moment(opp.updatedAt).format("MM/DD/YYYY")}
                            </TableCell>
                            <TableCell>
                              {opp.title || opp.originalTitle}
                            </TableCell>
                            <TableCell>
                              {opp?.estateName
                                ? `${opp.estateName} estate`
                                : `Estate #${opp.estateId}`}
                            </TableCell>
                            <TableCell>
                              {statusMap[opp?.status?.id || -1]?.title || ""}
                            </TableCell>
                            {!disableEdit && (
                              <TableCell align="right">
                                {hasEditAccess && (
                                  <IconButton
                                    aria-label="edit"
                                    color="primary"
                                    onClick={(event) => {
                                      event.stopPropagation();
                                      setEditId(opp.id || undefined);
                                      setShowNewForm(false);
                                    }}
                                  >
                                    <EditIcon />
                                  </IconButton>
                                )}
                              </TableCell>
                            )}
                          </TableRow>
                        );
                      })}
                    {onPageChange && (
                      <TableRow className="no-background">
                        <TableCell
                          colSpan={!disableEdit ? 7 : 6}
                          style={{ padding: 0, paddingTop: 8 }}
                        >
                          <div
                            className={classes.paginationWrapper}
                            style={{
                              flexDirection: mediumAndUp ? "row" : "column",
                            }}
                          >
                            <Pagination
                              color="primary"
                              count={Math.floor(total / perPage)}
                              onChange={handlePageChange}
                              page={page}
                              showFirstButton={true}
                              showLastButton={true}
                            />
                            {onPerPageChange && (
                              <div>
                                per page:{" "}
                                <Button
                                  onClick={() => handlePerPageChange(10)}
                                  className={
                                    perPage === 10
                                      ? classes.selected
                                      : undefined
                                  }
                                  style={{
                                    minWidth: 40,
                                  }}
                                >
                                  10
                                </Button>{" "}
                                |{" "}
                                <Button
                                  onClick={() => handlePerPageChange(25)}
                                  className={
                                    perPage === 25
                                      ? classes.selected
                                      : undefined
                                  }
                                  style={{
                                    minWidth: 40,
                                  }}
                                >
                                  25
                                </Button>{" "}
                                |{" "}
                                <Button
                                  onClick={() => handlePerPageChange(50)}
                                  className={
                                    perPage === 50
                                      ? classes.selected
                                      : undefined
                                  }
                                  style={{
                                    minWidth: 40,
                                  }}
                                >
                                  50
                                </Button>
                              </div>
                            )}
                          </div>
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                )}
              </Table>
            </AppTableWrapper>
          </PerfectScrollbar>
        </div>
      )}
    </>
  );
};
