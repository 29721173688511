import React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

interface Props {
  open: boolean;
  description: string | JSX.Element | JSX.Element[];
  question?: string;
  onConfirm: () => void;
  confirmText?: string;
  onDeny?: () => void;
  denyText?: string;
}

export const ReassignDialog = ({
  open,
  description,
  question,
  onConfirm,
  confirmText = "Yes",
  onDeny,
  denyText = "No",
}: Props): JSX.Element => {
  return (
    <Dialog
      open={open}
      onClose={onDeny ? onDeny : onConfirm}
      fullWidth={true}
      sx={{
        "&>div>div": {
          p: 1,
          pt: 4,
        },
      }}
      maxWidth="md"
    >
      <IconButton
        style={{
          position: "absolute",
          top: 8,
          right: 8,
        }}
        aria-label="delete"
        onClick={onDeny ? onDeny : onConfirm}
      >
        <CloseIcon />
      </IconButton>
      {question && (
        <DialogTitle id="alert-dialog-title" component="div">
          <Typography variant="h3" sx={{ textAlign: "center" }}>
            {question}
          </Typography>
        </DialogTitle>
      )}
      <DialogContent
        sx={{
          textAlign: "center",
        }}
      >
        {description instanceof String ? (
          <DialogContentText id="alert-dialog-description">
            {description}
          </DialogContentText>
        ) : (
          description
        )}
      </DialogContent>
      <DialogActions
        sx={
          {
            // flexDirection: "row-reverse",
            // justifyContent: "center",
          }
        }
      >
        <Button onClick={() => onConfirm()} color="primary" variant="contained">
          {confirmText}
        </Button>
        {onDeny && (
          <Button
            onClick={() => onDeny()}
            color="primary"
            variant="text"
            // className="primary-inverted"
          >
            {denyText}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};
