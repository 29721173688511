import React, { useEffect, useRef } from "react";
import {
  Box,
  Theme,
  Grid,
  MenuItem,
  ListSubheader,
  FormControl,
  InputLabel,
  TextField,
  Select,
  InputAdornment,
  Typography,
} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import { groupBy } from "lodash";
import { Formik, FormikHelpers, FormikProps } from "formik";

import SearchIcon from "@mui/icons-material/Search";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
// Redux Imports
import { connect } from "react-redux";
import { StoreState } from "../../../../reducers";
import {
  fetchCountries,
  fetchStates,
  fetchCounties,
} from "../../../../actions/geographyActions";

// Model Imports
import { FormType } from "../../../../models/FormType";
import {
  Country,
  County,
  GeographyType,
  State,
} from "../../../../models/Geography";
import { Language } from "../../../../models/Language";

// API Imports
import { QueryPayload } from "../../../../apis/AtticusForms";

export interface FormQueryValues {
  country?: Country;
  state?: State;
  county?: County;
  formType?: FormType;
  language?: Language;
  searchTerms?: string;
  geographyInclusive?: boolean;
}

export interface FormsLibrarySearchProps {
  countries?: Country[];
  fetchCountries?: () => void;
  onSelectCountry?: (c?: Country) => void;
  states?: State[];
  fetchStates?: () => void;
  onSelectState?: (s?: State) => void;
  initGeographyType?: GeographyType;
  initGeographyId?: number;
  onSelectCounty?: (ct?: County) => void;
  formTypes?: FormType[];
  initFormTypeSlug?: string;
  onSelectFormType?: (ft: FormType) => void;
  languages?: Language[];
  initLanguageCode?: string;
  onSelectLanguage?: (lang: Language) => void;
  initSearchTerms?: string;
  geographyInclusive?: boolean;
  onComplete: (values: QueryPayload) => void;
  loadingForms: boolean;
  disableGeographySelect?: boolean;
  presubmit?: boolean;
}

const _FormsLibrarySearch = ({
  countries,
  fetchCountries,
  states,
  fetchStates,
  initGeographyType,
  initGeographyId,
  formTypes,
  initFormTypeSlug = "probate", // Default to Probate forms for now
  languages,
  initLanguageCode,
  initSearchTerms,
  geographyInclusive = false,
  onComplete,
  loadingForms,
  disableGeographySelect = false,
}: FormsLibrarySearchProps): JSX.Element => {
  const formRef = useRef<FormikProps<FormQueryValues>>(null);
  const [initState, setInitState] = React.useState<State | undefined>(
    undefined
  );
  const [initCountry, setInitCountry] = React.useState<Country | undefined>(
    undefined
  );

  useEffect(() => {
    if (!countries || !countries?.length) {
      fetchCountries && fetchCountries();
    }
    if (!states || !states?.length) {
      fetchStates && fetchStates();
    }
  }, []);

  useEffect(() => {
    if (states && states.length && countries && countries.length) {
      let foundCountry: Country | undefined = undefined;
      let foundState: State | undefined = undefined;
      switch (initGeographyType) {
        case GeographyType.Country:
          foundCountry = countries?.find(
            (country) => country.id == initGeographyId
          );
          setInitCountry(foundCountry);
          break;
        case GeographyType.State:
          foundState = states?.find((state) => state.id == initGeographyId);
          foundCountry = countries?.find(
            (country) => country.id == foundState?.countryId
          );
          setInitState(foundState);
          setInitCountry(foundCountry);
          break;
        case GeographyType.County:
          console.error("Init County not yet supported!");
          break;
      }
    }
  }, [states, countries]);

  const formikInit = {
    values: {
      country: initCountry,
      state: initState,
      formType: formTypes?.find((type) => type.slug == initFormTypeSlug),
      language: languages?.find((lang) => lang.code == initLanguageCode),
      searchTerms: initSearchTerms,
      geographyInclusive: geographyInclusive,
    } as FormQueryValues,
  };

  const createPayloadAndSubmit = async (values: FormQueryValues) => {
    if (!values.country && !values.state) {
      throw new Error("Estate Location is required");
    }
    if (!values.country && values.state) {
      throw new Error("Country is required if state is Selected");
    }
    if (!values.state && values.county) {
      throw new Error("State is required if county is Selected");
    }
    if (
      values.country &&
      values.state &&
      values.country?.id != values.state?.countryId
    ) {
      throw new Error("State must be within selected Country.");
    }
    if (
      values.state &&
      values.county &&
      values.state?.id != values.county?.stateId
    ) {
      throw new Error("County must be within selected State.");
    }

    const query: QueryPayload = {
      form_type_id: values.formType?.id,
      search_terms: values.searchTerms,
      language_id: values.language?.id,
      geography_inclusive: values.geographyInclusive,
    };
    if (values.county) {
      query.geography_type = values.county.geographyType;
      query.geography_id = values.county.id;
    } else if (values.state) {
      query.geography_type = values.state.geographyType;
      query.geography_id = values.state.id;
    } else if (values.country) {
      query.geography_type = values.country.geographyType;
      query.geography_id = values.country.id;
    }

    onComplete(query);
  };

  const handleSubmit = async (
    values: FormQueryValues,
    { setSubmitting, setStatus }: FormikHelpers<FormQueryValues>
  ) => {
    setSubmitting(true);
    setStatus("");
    try {
      await createPayloadAndSubmit(values);
    } catch (err: any) {
      setStatus("Sorry, error: " + ((err as Error)?.message || ""));
    }
    setSubmitting(false);
  };

  function getEstateLocationOptions(countries: Country[], states: State[]) {
    const items:JSX.Element[] = [];

    // Sort by Country
    const groupByCountry = groupBy(states, (state) => {
      return state.countryId;
    });

    for (const [key, states] of Object.entries(groupByCountry)) {
      const country = countries.find((c) => c.id == parseInt(key));
      if (country) {
        items.push(
          <ListSubheader
            key={country.code}
            sx={{
              borderTop: "1px solid #e3e3e3",
              borderBottom: "1px solid #e3e3e3",
              backgroundColor: "#F2F2FF",
              fontWeight: "bold",
            }}
          >
            {country.name}
          </ListSubheader>
        );

        for (const state of states) {
          items.push(
            <MenuItem key={state.id} value={state.id?.toString()}>
              {state.name}
            </MenuItem>
          );
        }
      }
    }
    return items;
  }

  return (
    <Formik
      initialValues={formikInit.values}
      onSubmit={handleSubmit}
      innerRef={formRef}
      enableReinitialize
    >
      {({
        handleChange,
        handleSubmit,
        isSubmitting,
        values,
        setFieldValue,
        status,
      }) => (
        <>
          <Box sx={{ mb: 2 }}>
            <Typography variant="h5">{`Atticus Forms Library`}</Typography>
            <Typography variant="body1">
              {`Looking for other forms? Search our Forms Library to find or use more than 3,500 forms. Start by searching for a form, choosing a location, or both:`}
            </Typography>
          </Box>
          <Box
            component="form"
            onSubmit={handleSubmit}
            sx={(theme: Theme) => ({
              display: "flex",
              flexDirection: "row",
              width: "100%",
              gap: "1rem",
              [theme.breakpoints.down("sm")]: {
                flexDirection: "column",
              },
            })}
          >
            <Grid container sx={{ flex: 1 }} spacing={2}>
              <Grid
                item
                xs={12}
                sm={disableGeographySelect ? 9 : 5}
                lg={disableGeographySelect ? 10 : 7}
              >
                <TextField
                  name="searchTerms"
                  fullWidth
                  sx={(theme: Theme) => ({ marginRight: theme.spacing(1) })}
                  value={values.searchTerms || ""}
                  onChange={handleChange}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon />
                      </InputAdornment>
                    ),
                  }}
                  variant="outlined"
                />
              </Grid>
              {!disableGeographySelect && (
                <Grid item xs={12} sm={4} lg={3}>
                  <FormControl
                    fullWidth
                    sx={{
                      width: "100%",
                    }}
                  >
                    <InputLabel>Estate Location</InputLabel>
                    <Select
                      name="state"
                      disabled={!countries || !states}
                      value={values.state?.id?.toString() || ""}
                      label="Estate Location"
                      onChange={async (event: any): Promise<any> => {
                        const newState = states?.find(
                          (s) => s.id == event.target.value
                        );
                        const newCountry = countries?.find(
                          (c) => c.id == newState?.countryId
                        );
                        setFieldValue("state", newState);
                        setFieldValue("country", newCountry);
                        // if (newState) {
                        //   fetchCounties && fetchCounties(newState?.id);
                        // }
                      }}
                    >
                      {countries &&
                        states &&
                        getEstateLocationOptions(countries, states)}
                    </Select>
                  </FormControl>
                </Grid>
              )}
              <Grid item xs={12} sm={3} lg={2}>
                <LoadingButton
                  loading={isSubmitting || loadingForms}
                  variant="contained"
                  color="primary"
                  type="submit"
                  endIcon={<ArrowForwardIcon />}
                  fullWidth
                >
                  Search
                </LoadingButton>
              </Grid>
              {status && (
                <Grid item xs={12}>
                  <Typography variant="body1" color="error">
                    {status}
                  </Typography>
                </Grid>
              )}
            </Grid>
          </Box>
        </>
      )}
    </Formik>
  );
};

const mapStateToProps = ({
  countries,
  states,
  counties,
  formTypes,
  languages,
}: StoreState): {
  countries: Country[];
  states: State[];
  counties: County[];
  formTypes: FormType[];
  languages: Language[];
} => {
  return { countries, states, counties, formTypes, languages };
};

export const FormsLibrarySearch = connect(mapStateToProps, {
  fetchCountries,
  fetchStates,
  fetchCounties,
})(_FormsLibrarySearch);
