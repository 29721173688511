import {
  Button,
  FormControl,
  MenuItem,
  Select,
  Theme,
  Typography,
} from "@mui/material";
import { makeStyles } from "tss-react/mui";
import React, { useEffect, useState } from "react";
import { StoreState } from "../../reducers";
import { connect } from "react-redux";
import { AdvisorGroup } from "../../models/AdvisorGroup";
import {
  ADVISOR_GROUP_PREFIX,
  estateGroupReassignmentMessage,
} from "../../constants";
import { fetchAdvisorGroups } from "../../actions/advisorGroupActions";

const useStyles = makeStyles()((theme: Theme) => ({
  root: {},
  button: {
    backgroundColor: theme.palette.primary.light,
    color: theme.palette.primary.contrastText,
    border: `solid 2px ${theme.palette.primary.light}`,
  },
  invertedButton: {
    backgroundColor: theme.palette.background.default,
    color: theme.palette.primary.light,
    border: `solid 2px ${theme.palette.primary.light}`,
    // margin: theme.spacing(1, 0),
  },
  selectWrapper: {
    flexDirection: "row",
    alignItems: "center",
  },
  select: {
    margin: theme.spacing(0, 1),
    minWidth: 240,
  },
  denseSelect: {
    margin: theme.spacing(0, 1, 0, 0),
    "& .MuiSelect-root": {
      fontSize: 14,
      padding: "8px 32px 8px 8px", //theme.spacing(0.5, 4, 0.5, 1), //"4px 32px 4px 8px",
    },
  },
  status: {
    padding: theme.spacing(0, 1),
  },
}));

interface Props {
  onAssign: (
    newAdvisorGroupId: number,
    newAdvisorGroupName: string
  ) => Promise<boolean>;
  advisorGroups?: AdvisorGroup[];
  fetchAdvisorGroups: () => void;
  advisorGroupId?: number;
  dense?: boolean;
  showEstateGroupConfirm?: boolean;
  onDelete?: () => void;
  deleteText?: string;
}

export const _AssignAdvisorGroupForm = ({
  onAssign,
  advisorGroups,
  fetchAdvisorGroups,
  advisorGroupId,
  dense = false,
  showEstateGroupConfirm = true,
  onDelete,
  deleteText,
}: Props): JSX.Element => {
  const { classes } = useStyles();
  const [status, setStatus] = useState("");
  const [selectedAdvisorGroupId, setSelectedAdvisorGroupId] = useState(0);

  useEffect(() => {
    if (advisorGroups === null) {
      fetchAdvisorGroups();
    }
  }, []);

  if (selectedAdvisorGroupId === 0 && advisorGroupId) {
    setSelectedAdvisorGroupId(advisorGroupId);
  }

  const handleChange = (event: any) => {
    setSelectedAdvisorGroupId(event.target.value as number);
  };
  const getNewGroupName = (): string | undefined => {
    const newGroup = advisorGroups?.filter(
      (group: AdvisorGroup) => group.id === selectedAdvisorGroupId
    )[0];
    return newGroup?.title;
  };

  return (
    <>
      <FormControl className={classes.selectWrapper}>
        <Select
          value={selectedAdvisorGroupId}
          onChange={handleChange}
          size="small"
          variant="outlined"
          displayEmpty={true}
          className={dense ? classes.denseSelect : classes.select}
        >
          <MenuItem value={0}>
            <em>select group</em>
          </MenuItem>
          {advisorGroups &&
            advisorGroups.map((advisorGroup: AdvisorGroup) => (
              <MenuItem
                value={advisorGroup.id}
                key={"AssignAdvisorGroupForm_" + advisorGroup.id}
              >
                {advisorGroup?.id}:{" "}
                {advisorGroup?.title?.replace(ADVISOR_GROUP_PREFIX, "") ||
                  "----"}
              </MenuItem>
            ))}
        </Select>
        <Button
          color="primary"
          variant="contained"
          // className={classes.button}
          size="small"
          onClick={async () => {
            setStatus("");
            if (
              showEstateGroupConfirm &&
              !window.confirm(estateGroupReassignmentMessage)
            ) {
              return;
            }
            if (
              await onAssign(selectedAdvisorGroupId, getNewGroupName() || "")
            ) {
              setStatus("success!✌️");
              window.setTimeout(() => {
                setStatus("");
              }, 2500);
            } else {
              setStatus("failed😞");
            }
          }}
          disabled={selectedAdvisorGroupId === 0}
        >
          Assign
        </Button>
        {onDelete && (
          <Button
            color="primary"
            variant="outlined"
            size="small"
            onClick={onDelete}
            style={{ marginLeft: 8 }}
          >
            {deleteText}
          </Button>
        )}
      </FormControl>
      <Typography variant={"body2"} className={classes.status}>
        {status}
      </Typography>
    </>
  );
};

const mapStateToProps = ({
  advisorGroups,
}: StoreState): { advisorGroups: AdvisorGroup[] } => {
  return { advisorGroups };
};

export const AssignAdvisorGroupForm = connect(mapStateToProps, {
  fetchAdvisorGroups,
})(_AssignAdvisorGroupForm);
