import React from "react";
import { Box, FormControl, MenuItem, Select, Typography } from "@mui/material";
import { ViewAssetAttribute } from "../ViewAssetAttribute";
import { HelpComponent, HelpContent } from "../HelpComponent";
import { InputType } from "./InputTypes";
import { SelectOption } from "../../../../../../models/SelectOption";
import { SxProps } from "@mui/system/styleFunctionSx";

interface Props {
  isEditMode?: boolean;
  type?: InputType;
  label?: string;
  caption?: string;
  name?: string;
  value?: string | number;
  options?: SelectOption[];
  onChange?: (e: any) => void;
  error?: string;
  helpContent?: HelpContent;
  sx?: SxProps;
}

export const EditableSelect = ({
  isEditMode = false,
  // type,
  label,
  caption,
  name,
  value,
  options,
  onChange,
  error,
  helpContent,
  sx,
}: Props): JSX.Element => {
  // console.log("EditableSelect ", label, value);

  // need value as string for the view mode (!isEditMode)
  let valueAsString = typeof value === "string" ? value : undefined;
  if (
    valueAsString === "true" ||
    valueAsString === "false" ||
    valueAsString === "null" ||
    !valueAsString
  ) {
    valueAsString = options?.find((option: SelectOption) => {
      return option.value === value;
    })?.label;
  }

  return isEditMode ? (
    <Box sx={sx}>
      <FormControl fullWidth>
        <label htmlFor={`${label}-label`}>
          <strong>{label}</strong>
        </label>
        {caption && <Typography variant="body2">{caption}</Typography>}
        {options?.length && (
          <Select
            autoWidth={true}
            size="small"
            labelId={`${label}-label`}
            id={`${label}-id`}
            aria-label={label}
            name={name}
            error={!!error}
            value={value?.toString() || ""}
            displayEmpty={true}
            onChange={onChange}
          >
            {options?.map((option: SelectOption) => {
              return (
                <MenuItem value={option.value?.toString()} key={`${label}-${option.value?.toString()}`}>
                  {option.label || option.value}
                </MenuItem>
              );
            })}
          </Select>
        )}
      </FormControl>
      {error && (
        <Typography variant="body2" color="error">
          {error}
        </Typography>
      )}
      <HelpComponent helpContent={helpContent} />
    </Box>
  ) : (
    <ViewAssetAttribute
      type={InputType.select}
      label={label}
      caption={caption}
      value={valueAsString}
      helpContent={helpContent}
    />
  );
};
