import { authApi } from "../apis";
import { UserProfile, UserProfileJSON } from "./UserProfile";
import { AdvisorGroup, AdvisorGroupJSON } from "./AdvisorGroup";

import { getInitials } from "../utils/getInitials";
import { CognitoUser } from "amazon-cognito-identity-js";
import {
  AdvisorGroupAccess,
  SCREEN_PATHS,
  SpecialCognitoGroups,
} from "../constants";
import { AdvisorsEstates } from "./AdvisorsEstates";
import { EstateUserInfo, EstateUserInfoJson } from "./EstateUserInfo";
import parsePhoneNumber, { PhoneNumber } from "libphonenumber-js";

// All of our roles should be defined in a Laravel Model and returned as resources through the API
export enum Role {
  "Primary_End-User" = 1,
  "Advisor" = 2, // really? there's two ways to spell this?
  "Advisor_Admin" = 3,
  "Super_Advisor_Admin" = 4,
  "Uber_Admin" = 5,
}

// database field names
export type UserJSON = {
  id?: number;
  cognito_uuid?: string;
  has_completed_onboarding?: boolean;
  email?: string;
  first_name?: string; // Legacy
  last_name?: string; // Legacy
  phone?: string;
  role_id?: number;
  advisor_group_id?: number;
  advisor_group?: AdvisorGroupJSON;
  countries_id?: number;
  languages_id?: number;
  invite_id?: string;
  user_profile?: UserProfileJSON;
  estates?: AdvisorsEstates[];
  estate_user_info?: EstateUserInfoJson;
};

export type CognitoSignUpAttributes = {
  given_name?: string;
  middle_name?: string;
  family_name?: string;
  phone_number?: string;
  "custom:invite_id"?: string;
};

export type CognitoUserJSON = {
  Username?: string;
  UserStatus?: string;
  Enabled?: boolean;
  Attributes?: [
    {
      Name: string;
      Value: string;
    }
  ];
};

export type CognitoUserAttributes = {
  sub?: string;
  given_name?: string;
  family_name?: string;
  picture?: string;
  email?: string;
  email_verified?: string;
  phone_number?: string;
  phone_number_verified?: string;
  "custom:advisor_invite_id"?: string;
};

export class User {
  static path = "/user";
  path = "/user";
  static className = "User";

  constructor(
    public id?: number,
    public cognitoUsername?: string,
    public cognitoGroups?: string[],
    public email?: string,
    public first?: string,
    public last?: string,
    public phone?: string,
    public pictureUrl?: string,
    public hasCompletedOnboarding?: boolean,
    public admin?: boolean,
    public advisor?: boolean,
    public roleId?: number,
    public estateUserInfo?: EstateUserInfo,
    public advisorGroup?: AdvisorGroup,
    public advisorGroupId?: number,
    public advisorGroupAccessId?: number, // Keep, but deprecate later for relationship
    public advisorGroupAccess?: string, // Keep, but deprecate later for relationship
    public profile?: UserProfile,
    public advisorGroupName?: string, // Keep, but deprecate later for relationship
    public advisorInviteId?: string,
    public countryId?: number,
    public languageId?: number,
    public inviteId?: string,
    public cognitoUser?: CognitoUser,
    public challengeName?: string
  ) {}

  static fromCognitoWithChallenge(
    username?: string,
    cognitoUser?: CognitoUser,
    challengeName?: string
  ): User {
    return new User(
      undefined,
      username,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      cognitoUser,
      challengeName
    );
  }

  static fromCognito(json: CognitoUserAttributes): User {
    return new User(
      undefined,
      json.sub,
      undefined,
      json.email,
      json.given_name,
      json.family_name,
      json.phone_number,
      json.picture,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      json["custom:advisor_invite_id"]
    );
  }

  static fromCognito2(json: CognitoUserJSON): User {
    const attributes: CognitoUserAttributes = {};
    json.Attributes?.forEach((attr) => {
      switch (attr.Name) {
        case "given_name":
          attributes.given_name = attr.Value;
          break;
        case "family_name":
          attributes.family_name = attr.Value;
          break;
        case "picture":
          attributes.picture = attr.Value;
          break;
        case "email":
          attributes.email = attr.Value;
          break;
        case "phone_number":
          attributes.phone_number = attr.Value;
          break;
      }
    });

    return new User(
      undefined,
      json.Username,
      undefined,
      attributes.email,
      attributes.given_name,
      attributes.family_name,
      attributes.phone_number,
      attributes.picture
    );
  }

  clone(): User {
    return new User(
      this.id,
      this.cognitoUsername,
      this.cognitoGroups,
      this.email,
      this.first,
      this.last,
      this.phone,
      this.pictureUrl,
      this.hasCompletedOnboarding,
      this.admin,
      this.advisor,
      this.roleId,
      this.estateUserInfo,
      this.advisorGroup,
      this.advisorGroupId,
      this.advisorGroupAccessId,
      this.advisorGroupAccess,
      this.profile,
      this.advisorGroupName,
      this.advisorInviteId,
      this.countryId,
      this.languageId,
      this.inviteId,
      this.cognitoUser,
      this.challengeName
    );
  }

  static fromJSON(json: UserJSON): User {
    return new User(
      json.id,
      json.cognito_uuid,
      undefined,
      json.email,
      undefined,
      undefined,
      json.phone,
      undefined,
      json.has_completed_onboarding, // Only for Consumer App, but in case an Advisor has a Consumer App
      undefined,
      undefined,
      json.role_id,
      json.estate_user_info
        ? EstateUserInfo.fromJSON(json.estate_user_info)
        : undefined,
      json.advisor_group
        ? AdvisorGroup.fromJSON(json.advisor_group)
        : undefined,
      json.advisor_group_id,
      json.advisor_group?.access.id,
      json.advisor_group?.access.access,
      json.user_profile ? UserProfile.fromJSON(json.user_profile) : undefined,
      json.advisor_group?.name,
      json.advisor_group?.invite_id,
      json.countries_id,
      json.languages_id,
      json.invite_id
    );
  }

  // user data sources are coming to two places - cognito and laravel
  static mergeUsers(user1: User, user2: User): User {
    return new User(
      user1.id || user2.id,
      user1.cognitoUsername || user2.cognitoUsername,
      user1.cognitoGroups || user2.cognitoGroups,
      user1.email || user2.email,
      user1.first || user2.first,
      user1.last || user2.last,
      user1.phone || user2.phone,
      user1.pictureUrl || user2.pictureUrl,
      user1.hasCompletedOnboarding || user2.hasCompletedOnboarding,
      user2.admin || user2.admin,
      user1.advisor || user2.advisor,
      user1.roleId || user2.roleId,
      user1.estateUserInfo || user2.estateUserInfo,
      user1.advisorGroup || user2.advisorGroup,
      user1.advisorGroupId || user2.advisorGroupId,
      user1.advisorGroupAccessId || user2.advisorGroupAccessId,
      user1.advisorGroupAccess || user2.advisorGroupAccess,
      user1.profile || user2.profile,
      user1.advisorGroupName || user2.advisorGroupName,
      user1.advisorInviteId || user2.advisorInviteId,
      user1.countryId || user2.countryId,
      user1.languageId || user2.languageId,
      user1.inviteId || user2.inviteId,
      user1.cognitoUser || user2.cognitoUser,
      user1.challengeName || user2.challengeName
    );
  }

  toJson(): UserJSON {
    return {
      id: this.id,
      cognito_uuid: this.cognitoUsername,
      has_completed_onboarding: this.hasCompletedOnboarding,
      email: this.email,
      phone: this.phone,
      role_id: this.roleId,
      advisor_group_id: this.advisorGroupId,
      advisor_group: this.advisorGroup?.toJSON(),
      countries_id: this.countryId,
      languages_id: this.languageId,
      invite_id: this.inviteId,
      user_profile: this.profile?.toJSON(),
      estate_user_info: this.estateUserInfo?.toJSON(),
    };
  }

  get initials(): string {
    let initials = "";
    const firstName = this?.first;
    const lastName = this?.last;
    if (firstName && lastName) {
      initials = getInitials(`${firstName} ${lastName}`);
    }
    return initials;
  }

  get selectName(): string {
    return (
      (this?.first && this?.last
        ? this?.first + " " + this?.last
        : this?.email) || "undefined"
    );
  }

  get isAdvisor(): boolean {
    return this.roleId === Role.Advisor;
  }

  // user role must be Advisor Admin or higher
  get isAdvisorAdmin(): boolean {
    return (
      this.roleId === Role.Advisor_Admin ||
      this.roleId === Role.Super_Advisor_Admin ||
      this.roleId === Role.Uber_Admin
    );
  }

  get isUberAdmin(): boolean {
    return this.roleId === Role.Uber_Admin;
  }

  get role(): string | undefined {
    return (
      (this?.roleId && Role[this.roleId].replaceAll("_", " ")) || undefined
    );
  }

  async signOut(): Promise<boolean> {
    return await authApi.signOut();
  }

  get metricsTool(): boolean | undefined {
    // see MySQL advisor_group_access table
    return (
      (this.advisorGroup?.access?.access ===
        AdvisorGroupAccess.Aggregate_Data ||
        this.advisorGroup?.access?.access === AdvisorGroupAccess.Full) &&
      !this.isAdvisor
    );
  }

  get onlyMetricsTool(): boolean | undefined {
    // see MySQL advisor_group_access table
    return (
      this.advisorGroup?.access?.access === AdvisorGroupAccess.Aggregate_Data
    );
  }

  get onlyAdminTool(): boolean | undefined {
    return this.cognitoGroups?.includes(SpecialCognitoGroups.OnlyAdminTool);
  }

  get hasInviteAccess(): boolean {
    return !(this.onlyMetricsTool || this.onlyAdminTool);
  }

  get hasEstateAccess(): boolean {
    return !(this.onlyMetricsTool || this.onlyAdminTool);
  }

  get hasMetricsToolAccess(): boolean {
    return this.metricsTool || (this.isUberAdmin && !this.onlyAdminTool);
  }

  // all Uber and "OnlyAdminTool" users
  get hasAdminToolAccess(): boolean {
    return this.isUberAdmin || !!this.onlyAdminTool;
  }

  get hasAssignmentToolAccess(): boolean {
    return this.isUberAdmin;
  }

  get landingScreen(): string {
    if (this?.onlyMetricsTool) {
      return SCREEN_PATHS.metrics;
    } else if (this?.onlyAdminTool) {
      return SCREEN_PATHS.admin;
    } else {
      return this.isUberAdmin ? SCREEN_PATHS.estates : SCREEN_PATHS.dashboard;
    }
  }

  get phoneFormatObject(): PhoneNumber | undefined {
    return this.phone
      ? parsePhoneNumber(this.phone, {
          defaultCountry: "US",
        })
      : undefined;
  }

  get fullName(): string | undefined {
    return this.profile
      ? this.profile.fullName
      : this.first || this.last
      ? `${this.first} ${this.last}`
      : undefined;
  }
}
