import { ActionTypes } from "../actions/types";
import { EstateAvatar } from "../models/EstateAvatar";
import { AnyAction } from "redux";

export const estateAvatarCacheReducer = (
  avatars: EstateAvatar[] = [],
  action: AnyAction
): EstateAvatar[] => {
  switch (action.type) {
    case ActionTypes.CacheEstateAvatar:
      if (avatars && action.payload.estateAvatar) {
        return [...avatars, action.payload.estateAvatar];
      } else {
        return avatars;
      }
    case ActionTypes.AppSignOut:
      return [];
    default:
      return avatars;
  }
};
