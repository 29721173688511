import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Switch,
  Theme,
  Typography,
} from "@mui/material";
import { makeStyles } from "tss-react/mui";
import { AtticusLogger } from "../../../apis/AtticusLogger";
import React from "react";
import { logger } from "../../../apis";

const useStyles = makeStyles()((theme: Theme) => ({
  contentRoot: {
    display: "flex",
    flexDirection: "column",
  },
  button: {
    margin: theme.spacing(1),
    width: "fit-content",
  },
}));

export const LogTest = (): JSX.Element => {
  const { classes } = useStyles();
  const [send2Cloud, setSend2Cloud] = React.useState(false);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { checked: newCheckedState } = event.target;
    setSend2Cloud(newCheckedState);
  };

  const handleLog = (level: string) => {
    switch (level) {
      case AtticusLogger.LogLevels.VERBOSE:
        logger.verbose("test screen VERBOSE log");
        break;
      case AtticusLogger.LogLevels.DEBUG:
        logger.debug("test screen DEBUG log");
        break;
      case AtticusLogger.LogLevels.INFO:
        logger.info("test screen INFO log", send2Cloud);
        break;
      case AtticusLogger.LogLevels.WARN:
        logger.warn("test screen WARN log", send2Cloud);
        break;
      case AtticusLogger.LogLevels.ERROR:
        logger.error("test screen ERROR log", send2Cloud);
        break;
    }
  };

  return (
    <Card>
      <CardHeader title="test log events" />
      <CardContent className={classes.contentRoot}>
        <div style={{ display: "flex", alignItems: "center" }}>
          <Switch
            checked={send2Cloud}
            onChange={handleChange}
            color="primary"
            name="cloud-switch"
            id="cloud-switch"
          />
          <label htmlFor="cloud-switch" style={{ cursor: "pointer" }}>
            <Typography variant="h5" component="div">
              Send to CloudWatch
            </Typography>
          </label>
        </div>
        {Object.keys(AtticusLogger.LogLevels).map((level: string) => {
          return (
            <Button
              key={"log-test-" + level}
              variant="contained"
              color="primary"
              className={classes.button}
              onClick={() => handleLog(level)}
            >
              {level} test log
            </Button>
          );
        })}
      </CardContent>
    </Card>
  );
};
