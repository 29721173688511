import { LogProxy } from "./LogProxy";

enum LogLevels {
  VERBOSE = "VERBOSE",
  DEBUG = "DEBUG",
  INFO = "INFO",
  WARN = "WARN",
  ERROR = "ERROR",
}

export class AtticusLogger {
  private logProxy: LogProxy;
  static LogLevels = LogLevels;
  constructor() {
    this.logProxy = new LogProxy();
  }

  sendToServer(
    msg: string | Record<string, unknown> | Error,
    level = LogLevels.ERROR
  ): void {
    // do nothing
    // disabling this because it's not used
  }
  verbose(msg: string | Record<string, unknown>): void {
    console.debug(msg);
  }
  debug(msg: string | Record<string, unknown>): void {
    console.debug(msg);
  }
  info(msg: string | Record<string, unknown>, sendToServer = false): void {
    console.info(msg);
  }
  warn(msg: string | Record<string, unknown>, sendToServer = false): void {
    console.warn(msg);
  }
  error(
    msg: string | Record<string, unknown> | Error,
    sendToServer = true
  ): void {
    console.error(msg);
  }
}
