import { ActionTypes } from "../actions/types";
import { AnyAction } from "redux";
import { TaskPhase } from "../models/TaskPhase";
import { Task } from "../models/Task";

export const taskPhasesReducer = (
  taskPhases: TaskPhase[] | null = null,
  action: AnyAction
): TaskPhase[] | null => {
  switch (action.type) {
    case ActionTypes.FetchTaskPhases:
    case ActionTypes.ClearTaskPhases:
      return action.payload;
    case ActionTypes.SaveTask:
      if (taskPhases) {
        const newTaskPhases = [...taskPhases];
        // find the task in taskPhases
        let taskPhaseIndex:number|undefined = undefined;
        let taskIndex:number|undefined = undefined;
        taskPhases?.forEach((taskPhase: TaskPhase, phaseIndex: number) => {
          taskPhase?.tasks?.forEach((task: Task, index: number) => {
            if (action.payload.task.id === task.id) {
              taskPhaseIndex = phaseIndex;
              taskIndex = index;
            }
          });
        });
        if (taskPhaseIndex && taskIndex) {
          if (
            newTaskPhases[taskPhaseIndex] &&
            newTaskPhases[taskPhaseIndex]?.tasks
          ) {
            const phaseTasks = newTaskPhases[taskPhaseIndex]?.tasks;
            if (phaseTasks && phaseTasks[taskIndex]) {
              // despite checks above TypeScript is still unhappy
              // @ts-ignore
              newTaskPhases[taskPhaseIndex].tasks[taskIndex] =
                action.payload.task;
            }
          }
        }
        return newTaskPhases;
      } else {
        return taskPhases;
      }
    case ActionTypes.AppSignOut:
      return null;
    default:
      return taskPhases;
  }
};
