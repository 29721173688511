import React, { useEffect } from "react";
import {
  Button,
  Card,
  CardContent,
  Theme,
  Typography,
  LinearProgress,
  Box,
} from "@mui/material";
import { makeStyles, withStyles } from "tss-react/mui";

import atticusColors from "../../../../theme/colors";
import { Task } from "../../../../models/Task";
import { TaskAnswerComponent } from "./TaskAnswerComponent";
import { TaskBannerNav } from "./TaskBannerNav";
import { TaskDetailsDialog } from "./TaskDetailsDialog";
import { TaskPhase } from "../../../../models/TaskPhase";
import { TaskIcon } from "./TaskIcons";
import { connect } from "react-redux";
import { saveTask } from "../../../../actions/taskPhasesActions";
import { TaskAnswer } from "../../../../models/TaskAnswer";

const useStyles = makeStyles()((theme: Theme) => ({
  root: {
    minHeight: "320px",
    marginBottom: theme.spacing(2),
    // getOverrides ignores elevation setting on Mui Paper so we need to set shadow here
    boxShadow:
      "0px 5px 5px -3px rgb(116 135 164 / 20%), 0px 8px 10px 1px rgb(116 135 164 / 14%), 0px 3px 14px 2px rgb(116 135 164 / 12%);",
    backgroundColor: "background.default",
  },
  taskBody: {
    textAlign: "center",
    height: "100%",
  },
  phase: {
    marginBottom: theme.spacing(3),
    fontVariantCaps: "all-petite-caps",
    letterSpacing: "1px",
  },
  title: {
    marginBottom: theme.spacing(2),
  },
  summary: {
    marginBottom: theme.spacing(1),
  },
  detailButton: {
    width: "184px",
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
    color: "white",
  },
  icon: {
    width: "48px",
    height: "48px",
  },
}));

const CardContentStyled = withStyles(CardContent, (theme:Theme) => ({
  root: {
    padding: 0,
    "&:last-child": {
      paddingBottom: 0,
    },
  },
}));

const LinearProgressStyled = withStyles(LinearProgress,(theme:Theme) => ({
  root: {
    height: "8px",
  },
  colorPrimary: {
    backgroundColor: atticusColors.purple5,
  },
  bar1Determinate: {
    backgroundColor: atticusColors.purple6,
  },
}));

interface Props {
  task?: Task;
  taskPhases: TaskPhase[];
  currentTaskPhase: TaskPhase;
  onPhase: (taskPhase: TaskPhase) => void;
  estateId?: number;
  saveTask: (estateId: number, task: Task) => void;
  hasWriteAccess?: boolean;
}

const _TaskView = ({
  task,
  taskPhases,
  currentTaskPhase,
  onPhase,
  estateId,
  saveTask,
  hasWriteAccess,
}: Props): JSX.Element => {
  const { classes } = useStyles();
  const { taskCompleteCount, taskCount, status, taskPhase } = currentTaskPhase;

  const [open, setOpen] = React.useState(false);
  const [progress, setProgress] = React.useState(
    Math.trunc((taskCompleteCount / taskCount) * 100)
  );

  // Update progress for Linear Progress Bar
  useEffect(() => {
    setProgress(Math.trunc((taskCompleteCount / taskCount) * 100));
  }, [taskCompleteCount, taskCount]);

  const handleClickOpen = () => () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleAnswer = (answer?: number) => {
    if (estateId && task) {
      if (task?.answer) {
        task.answer.boolAnswer = answer;
      } else {
        task.answer = new TaskAnswer(undefined, task.id, undefined, answer);
      }
      saveTask(estateId, task);
    }
  };

  const descriptionElementRef = React.useRef<HTMLElement>(null);
  React.useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);

  return (
    <Card className={classes.root}>
      <LinearProgressStyled
        variant="determinate"
        value={progress}
        valueBuffer={100}
      />
      <Box
        sx={{
          display: {
            md: "block",
            lg: "none",
            xl: "none",
          },
        }}
        // lgUp
      >
        <TaskBannerNav
          taskPhases={taskPhases}
          currentTaskPhase={currentTaskPhase}
          onPhase={onPhase}
          status={status}
        />
      </Box>
      <CardContentStyled className={classes.taskBody}>
        {!task ? null : (
          <Box display="flex" flexDirection="column">
            <Box padding={3}>
              <Typography variant="body1" className={classes.phase}>
                {taskPhase}
              </Typography>
              <Box marginBottom={2}>
                <Box
                  sx={{
                    border: 4,
                    borderColor: "grey.300",
                    borderRadius: "50%",
                    width: 72,
                    height: 72,
                    mx: "auto",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <TaskIcon
                    sequence={currentTaskPhase.sequence}
                    iconMd
                    active={true}
                  />
                </Box>
              </Box>
              <Typography variant="h2" component="h4" className={classes.title}>
                {task.title}
              </Typography>
              <Typography variant="body2" className={classes.summary}>
                {task.summary}
              </Typography>
              {task.details && (
                <>
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={handleClickOpen()}
                    className={classes.detailButton}
                  >
                    View details
                  </Button>
                  <TaskDetailsDialog
                    task={task}
                    open={open}
                    onClose={handleClose}
                    elementRef={descriptionElementRef}
                  />
                </>
              )}
            </Box>
            <TaskAnswerComponent
              task={task}
              answer={task?.answer?.boolAnswer}
              onChange={handleAnswer}
              hasWriteAccess={hasWriteAccess}
            />
          </Box>
        )}
      </CardContentStyled>
    </Card>
  );
};

export const TaskView = connect(null, {
  saveTask,
})(_TaskView);