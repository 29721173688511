import React from "react";
import PerfectScrollbar from "react-perfect-scrollbar";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import { AppTableWrapper } from "../../shared/AppTableWrapper";
import { TableBodyLoader } from "../../shared/TableBodyLoader";
import { ZeroStateBody } from "../../shared/ZeroStateBody";
import { AdvisorEstateList } from "./AdvisorEstateList";
import { AdvisorsEstates } from "../../../models/AdvisorsEstates";

interface Props {
  unassignedEstates: AdvisorsEstates[];
  onAssign?: (advisorsEstates?: AdvisorsEstates) => Promise<boolean>;
  total?: number;
  page?: number;
  perPage?: number;
  onPageChange?: (page: number) => void;
  onRowsPerPageChange?: (perPage: number) => void;
}

export const UnassignedEstateTable = ({
  unassignedEstates,
  onAssign,
  total = 0,
  page = 1,
  perPage = 10,
  onPageChange,
  onRowsPerPageChange,
}: Props): JSX.Element => {
  const loading = unassignedEstates === undefined;
  const showZero = unassignedEstates?.length === 0;

  return (
    <PerfectScrollbar>
      <AppTableWrapper minWidth={!showZero ? 800 : 1}>
        {showZero ? (
          <ZeroStateBody
            text="There are no unassigned estates"
            imageAlt="No Estates"
            imagePrefix="/static/images/zero/estates"
            displaySize="small"
            opacity={0.4}
            inCard={false}
          />
        ) : (
          <Table
            sx={{
              "& tr": {
                height: 56,
              },
            }}
          >
            <TableHead>
              <TableRow
                sx={{
                  ">th": {
                    backgroundColor: "background.default",
                    color: "grey.700",
                  },
                }}
              >
                <TableCell width="5%" />
                <TableCell
                  width="40%"
                  sx={{ fontWeight: "bold", color: "common.black" }}
                >
                  Estate Name
                </TableCell>
                <TableCell width="40%" />
                <TableCell width="15%" sx={{ textAlign: "right", pr: 7 }}>
                  Access Type
                </TableCell>
              </TableRow>
            </TableHead>
            {loading ? (
              <TableBodyLoader colSpan={4} rows={1} />
            ) : (
              <TableBody>
                <AdvisorEstateList
                  advisorsEstatesList={unassignedEstates || []}
                  onAssign={onAssign}
                  total={total}
                  page={page}
                  perPage={perPage}
                  onPageChange={(page: number) => {
                    onPageChange && onPageChange(page);
                  }}
                  onRowsPerPageChange={(perPage: number) => {
                    onRowsPerPageChange && onRowsPerPageChange(perPage);
                  }}
                />
              </TableBody>
            )}
          </Table>
        )}
      </AppTableWrapper>
    </PerfectScrollbar>
  );
};
