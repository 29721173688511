import { DialogMessage } from "../../shared/DialogMessage";
import { BigCircledCheck } from "../../shared/BigCircledCheck";
import { Typography } from "@mui/material";
import { ConfirmationDialog } from "../../shared/dialog/ConfirmationDialog";
import React from "react";

interface Props {
  open?: boolean;
  numUnassigned?: number;
  onConfirm: () => void;
}

export const NotifyUnassignedDialog = ({
  open = false,
  numUnassigned,
  onConfirm,
}: Props): JSX.Element => {
  return (
    <ConfirmationDialog
      open={open}
      description={
        <DialogMessage
          icon={<BigCircledCheck />}
          title={`You have ${numUnassigned} new unassigned estates`}
          body={
            <Typography>
              Looks like there are some new estates needing assignment to an
              advisor.
            </Typography>
          }
        />
      }
      maxWidth="md"
      onConfirm={onConfirm}
      confirmText="Ok"
      confirmButtonColor="secondary"
      confirmWide={true}
      buttonOrientation="row"
    />
  );
};
