import React from "react";
import { SvgIcon, SvgIconProps } from "@mui/material";

import { ReactComponent as EmotionWorriedIcon } from "../../assets/icons/emotions/emotion-worried.svg";
import { ReactComponent as EmotionOkIcon } from "../../assets/icons/emotions/emotion-ok.svg";
import { ReactComponent as EmotionSmileIcon } from "../../assets/icons/emotions/emotion-smile.svg";
import { ReactComponent as EmotionQuestioningIcon } from "../../assets/icons/emotions/emotion-questioning.svg";
import { ReactComponent as EmotionShadesIcon } from "../../assets/icons/emotions/emotion-shades.svg";
import { ReactComponent as EmotionAngryIcon } from "../../assets/icons/emotions/emotion-angry.svg";

export const WorriedIcon = (props: SvgIconProps): JSX.Element => {
  return <SvgIcon component={EmotionWorriedIcon} {...props}></SvgIcon>;
};

export const OkIcon = (props: SvgIconProps): JSX.Element => {
  return <SvgIcon component={EmotionOkIcon} {...props}></SvgIcon>;
};

export const SmileIcon = (props: SvgIconProps): JSX.Element => {
  return <SvgIcon component={EmotionSmileIcon} {...props}></SvgIcon>;
};

export const QuestioningIcon = (props: SvgIconProps): JSX.Element => {
  return <SvgIcon component={EmotionQuestioningIcon} {...props}></SvgIcon>;
};

export const ShadesIcon = (props: SvgIconProps): JSX.Element => {
  return <SvgIcon component={EmotionShadesIcon} {...props}></SvgIcon>;
};

export const AngryIcon = (props: SvgIconProps): JSX.Element => {
  return <SvgIcon component={EmotionAngryIcon} {...props}></SvgIcon>;
};
