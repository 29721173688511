import { User } from "../models/User";

export const getGreeting = (user?: User): string => {
  const now = new Date();
  const hour = now.getHours();
  let timeOfDay = "";
  if (hour >= 18) {
    // after 6pm
    timeOfDay = "Good evening";
  } else if (hour >= 12) {
    // after 12pm
    timeOfDay = "Good afternoon";
  } else {
    timeOfDay = "Good morning";
  }
  return `${timeOfDay}${user && user?.first ? ", " + user.first : ""}`;
};
