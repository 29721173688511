import Handlebars from "handlebars";
import { KnowledgeEngineField } from "../models/KnowledgeEngine";
import { FormInterface } from "../models/FormInterface";

export const keFieldsToHandlebarsVars = (
  knowledgeEngineFields: KnowledgeEngineField[]
): { [key: string]: string } => {
  const handlebarsVars: { [key: string]: string } = {};
  knowledgeEngineFields.forEach((field) => {
    handlebarsVars[field.field_id] = field.interface.answer_value;
  });
  return handlebarsVars;
};

enum CheatSheetCategory {
  BooleanManipulations = "Boolean Manipulations",
  ArithmeticNumberManipulations = "Arithmetic/Number Manipulations",
  NumberComparisons = "Number Comparisons",
  DateComparisons = "Date Comparisons",
  StringManipulation = "String Manipulation",
}

enum CheatSheetParamType {
  string = "string",
  number = "number",
  boolean = "boolean",
}

export interface Param {
  type: CheatSheetParamType;
}

export interface CheatSheetItem {
  name: string;
  key: string;
  category: CheatSheetCategory;
  description: string;
  function: (...args: any[]) => any;
  parameters: Param[];
  example: string;
  exampleResult?: string | boolean | number;
}

// TODO: Add Cheat Sheet Parameter Type Safety later

export const cheatSheetList: CheatSheetItem[] = [
  {
    name: "Not",
    key: "not",
    category: CheatSheetCategory.BooleanManipulations,
    description: "Returns the opposite of the given boolean value",
    // eslint-disable-next-line
    function: (param1: any): boolean => {
      return !param1;
    },
    parameters: [
      {
        type: CheatSheetParamType.boolean,
      },
    ],
    example: "{{not true}}",
    exampleResult: false,
  },
  {
    name: "And",
    key: "and",
    category: CheatSheetCategory.BooleanManipulations,
    description:
      "Performs an 'and' operation on two booleans. Only returns 'true' if both booleans are 'true'.",
    // eslint-disable-next-line
    function: (param1: any, param2: any): boolean => {
      return param1 && param2;
    },
    parameters: [
      {
        type: CheatSheetParamType.boolean,
      },
    ],
    example: "{{and true false}}",
    exampleResult: false,
  },
  {
    name: "Or",
    key: "or",
    category: CheatSheetCategory.BooleanManipulations,
    description:
      "Performs an 'or' operation on two booleans. True if one, the other, or both are true.",
    // eslint-disable-next-line
    function: (param1: any, param2: any): boolean => {
      return param1 || param2;
    },
    parameters: [
      {
        type: CheatSheetParamType.boolean,
      },
    ],
    example: "{{or true true}}",
    exampleResult: true,
  },
  {
    name: "Divide",
    key: "divide",
    category: CheatSheetCategory.ArithmeticNumberManipulations,
    description: "Divides the first number by the second number",
    // eslint-disable-next-line
    function: (param1: any, param2: any): any => {
      param1 = processAsNumber(param1);
      param2 = processAsNumber(param2);
      return param1 / param2;
    },
    parameters: [
      {
        type: CheatSheetParamType.number,
      },
      {
        type: CheatSheetParamType.number,
      },
    ],
    example: "{{divide 56 2}}",
    exampleResult: 28,
  },
  {
    name: "Multiply",
    key: "multiply",
    category: CheatSheetCategory.ArithmeticNumberManipulations,
    description: "Multiplies the first number by the second number",
    // eslint-disable-next-line
    function: (param1: any, param2: any): any => {
      param1 = processAsNumber(param1);
      param2 = processAsNumber(param2);
      return param1 * param2;
    },
    parameters: [
      {
        type: CheatSheetParamType.number,
      },
      {
        type: CheatSheetParamType.number,
      },
    ],
    example: "{{multiply 5 3}}",
    exampleResult: 15,
  },
  {
    name: "Add",
    key: "add",
    category: CheatSheetCategory.ArithmeticNumberManipulations,
    description: "Adds the first number to the second number",
    // eslint-disable-next-line
    function: (param1: any, param2: any): any => {
      param1 = processAsNumber(param1);
      param2 = processAsNumber(param2);
      return param1 + param2;
    },
    parameters: [
      {
        type: CheatSheetParamType.number,
      },
      {
        type: CheatSheetParamType.number,
      },
    ],
    example: "{{add 3 4}}",
    exampleResult: 7,
  },
  {
    name: "Subtract",
    key: "subtract",
    category: CheatSheetCategory.ArithmeticNumberManipulations,
    description: "Subtracts the second number from the first number",
    // eslint-disable-next-line
    function: (param1: any, param2: any): any => {
      param1 = processAsNumber(param1);
      param2 = processAsNumber(param2);
      return param1 - param2;
    },
    parameters: [
      {
        type: CheatSheetParamType.number,
      },
      {
        type: CheatSheetParamType.number,
      },
    ],
    example: "{{subtract 4 7}}",
    exampleResult: -3,
  },
  {
    name: "Equals",
    key: "equals",
    category: CheatSheetCategory.NumberComparisons,
    description: "Compares two numbers for equality",
    // eslint-disable-next-line
    function: (param1: any, param2: any): any => {
      param1 = processAsNumber(param1);
      param2 = processAsNumber(param2);
      return param1 == param2;
    },
    parameters: [
      {
        type: CheatSheetParamType.number,
      },
      {
        type: CheatSheetParamType.number,
      },
    ],
    example: "{{equals 8 8}}",
    exampleResult: true,
  },
  {
    name: "Less Than",
    key: "less_than",
    category: CheatSheetCategory.NumberComparisons,
    description:
      "Compares two numbers to see if the first is less than the second",
    // eslint-disable-next-line
    function: (param1: any, param2: any): any => {
      param1 = processAsNumber(param1);
      param2 = processAsNumber(param2);
      return param1 < param2;
    },
    parameters: [
      {
        type: CheatSheetParamType.number,
      },
      {
        type: CheatSheetParamType.number,
      },
    ],
    example: "{{less_than 5 8}}",
    exampleResult: true,
  },
  {
    name: "Less Than or Equal To",
    key: "less_than_or_equal_to",
    category: CheatSheetCategory.NumberComparisons,
    description:
      "Compares two numbers to see if the first is less than or equal to the second",
    // eslint-disable-next-line
    function: (param1: any, param2: any): any => {
      param1 = processAsNumber(param1);
      param2 = processAsNumber(param2);
      return param1 <= param2;
    },
    parameters: [
      {
        type: CheatSheetParamType.number,
      },
      {
        type: CheatSheetParamType.number,
      },
    ],
    example: "{{less_than_or_equal_to 8 8}}",
    exampleResult: true,
  },
  {
    name: "Greater Than",
    key: "greater_than",
    category: CheatSheetCategory.NumberComparisons,
    description:
      "Compares two numbers to see if the first is greater than the second",
    // eslint-disable-next-line
    function: (param1: any, param2: any): any => {
      param1 = processAsNumber(param1);
      param2 = processAsNumber(param2);
      return param1 > param2;
    },
    parameters: [
      {
        type: CheatSheetParamType.number,
      },
      {
        type: CheatSheetParamType.number,
      },
    ],
    example: "{{greater_than 4,000.00 4,100.00}}",
    exampleResult: false,
  },
  {
    name: "Greater Than or Equal To",
    key: "greater_than_or_equal_to",
    category: CheatSheetCategory.NumberComparisons,
    description:
      "Compares two numbers to see if the first is greater than or equal to the second",
    // eslint-disable-next-line
    function: (param1: any, param2: any): any => {
      param1 = processAsNumber(param1);
      param2 = processAsNumber(param2);
      return param1 >= param2;
    },
    parameters: [
      {
        type: CheatSheetParamType.number,
      },
      {
        type: CheatSheetParamType.number,
      },
    ],
    example: "{{greater_than_or_equal_to 23.5 23.5}}",
    exampleResult: true,
  },
  {
    name: "Date Before",
    key: "date_before",
    category: CheatSheetCategory.DateComparisons,
    description:
      "Compares two dates to see if the first is before the second. Dates must be in a JavaScript Date()-compatible format.",
    // eslint-disable-next-line
    function: (param1: any, param2: any): any => {
      const date1 = new Date(param1);
      const date2 = new Date(param2);
      return date1 < date2;
    },
    parameters: [
      {
        type: CheatSheetParamType.string,
      },
      {
        type: CheatSheetParamType.string,
      },
    ],
    example: "{{date_before 2021-02-16 2021-02-17}}",
    exampleResult: true,
  },
  {
    name: "Date Before or On",
    key: "date_before_or_on",
    category: CheatSheetCategory.DateComparisons,
    description:
      "Compares two dates to see if the first is before or on the second. Dates must be in a JavaScript Date()-compatible format.",
    // eslint-disable-next-line
    function: (param1: any, param2: any) => {
      const date1 = new Date(param1);
      const date2 = new Date(param2);
      return date1 <= date2;
    },
    parameters: [
      {
        type: CheatSheetParamType.string,
      },
      {
        type: CheatSheetParamType.string,
      },
    ],
    example: "{{date_before_or_on 2021-02-16 2021-02-16}}",
    exampleResult: true,
  },
  {
    name: "Date After",
    key: "date_after",
    category: CheatSheetCategory.DateComparisons,
    description:
      "Compares two dates to see if the first is after the second. Dates must be in a JavaScript Date()-compatible format.",
    // eslint-disable-next-line
    function: (param1: any, param2: any): any => {
      const date1 = new Date(param1);
      const date2 = new Date(param2);
      return date1 > date2;
    },
    parameters: [
      {
        type: CheatSheetParamType.string,
      },
      {
        type: CheatSheetParamType.string,
      },
    ],
    example: "{{date_after 2021-02-23 2021-02-17}}",
    exampleResult: true,
  },
  {
    name: "Date After or On",
    key: "date_after_or_on",
    category: CheatSheetCategory.DateComparisons,
    description:
      "Compares two dates to see if the first is after or on the second. Dates must be in a JavaScript Date()-compatible format.",
    // eslint-disable-next-line
    function: (param1: any, param2: any): any => {
      const date1 = new Date(param1);
      const date2 = new Date(param2);
      return date1 >= date2;
    },
    parameters: [
      {
        type: CheatSheetParamType.string,
      },
      {
        type: CheatSheetParamType.string,
      },
    ],
    example: "{{date_after_or_on 2021-05-21 2021-05-21}}",
    exampleResult: true,
  },
  {
    name: "To Uppercase",
    key: "upper_case",
    category: CheatSheetCategory.StringManipulation,
    description: "Converts the text to all UPPERCASE.",
    // eslint-disable-next-line
    function: (param1: any): any => {
      return String(param1).toUpperCase();
    },
    parameters: [
      {
        type: CheatSheetParamType.string,
      },
    ],
    example: "{{upper_case 'this is a test'}}",
    exampleResult: "THIS IS A TEST",
  },
  {
    name: "To Lowercase",
    key: "lower_case",
    category: CheatSheetCategory.StringManipulation,
    description: "Converts the text to all lowercase.",
    // eslint-disable-next-line
    function: (param1: any): any => {
      return String(param1).toLowerCase();
    },
    parameters: [
      {
        type: CheatSheetParamType.string,
      },
    ],
    example: "{{lower_case 'ThIs iS a TEST'}}",
    exampleResult: "this is a test",
  },
  {
    name: "To Title Case",
    key: "title_case",
    category: CheatSheetCategory.StringManipulation,
    description: "Capitalize the first letter of each word.",
    // eslint-disable-next-line
    function: (param1: any): any => {
      const words = param1.split(" ");
      return words
        .map((word: string) => {
          return word[0].toUpperCase() + word.substring(1);
        })
        .join(" ");
      return words.join(" ");
    },
    parameters: [
      {
        type: CheatSheetParamType.string,
      },
    ],
    example: "{{title_case 'Twilight Sparkle is the best pony'}}",
    exampleResult: "Twilight Sparkle Is The Best Pony",
  },
  {
    name: "To Sentence Case",
    key: "sentence_case",
    category: CheatSheetCategory.StringManipulation,
    description:
      "Capitalizes the first letter of every sentence, and makes the rest lower case. Warning: Cannot detect proper nouns.",
    // eslint-disable-next-line
    function: (param1: any): any => {
      const newString: string = String(param1)
        .toLowerCase()
        .replace(/(^\s*\w|[.!?]\s*\w)/g, function (c) {
          return c.toUpperCase();
        });
      return newString;
    },
    parameters: [
      {
        type: CheatSheetParamType.string,
      },
    ],
    example: "{{sentence_case 'Twilight Sparkle Is The Best Pony'}}",
    exampleResult: "Twilight sparkle is the best pony",
  },
  {
    name: "Format Number",
    key: "format_number",
    category: CheatSheetCategory.StringManipulation,
    description: "Formats a number with commas and decimal places.",
    // eslint-disable-next-line
    function: (param1: any): any => {
      param1 = processAsNumber(param1);
      return param1.toLocaleString();
    },
    parameters: [
      {
        type: CheatSheetParamType.string,
      },
    ],
    example: "{{format_number 12345678.901}}",
    exampleResult: "12,345,678.901",
  },
  {
    name: "Round a Number",
    key: "round",
    category: CheatSheetCategory.StringManipulation,
    description:
      "Rounds a given number to a certain precision (decimal places).",
    // eslint-disable-next-line
    function: (param1: any, precision: any): any => {
      param1 = processAsNumber(param1);
      return param1.toFixed(precision);
    },
    parameters: [
      {
        type: CheatSheetParamType.string,
      },
    ],
    example: "{{round 12345.41665 2}}",
    exampleResult: "12345.42",
  },
];

const processAsNumber = (numString: any): number => {
  // Try to remove currency marks
  if (typeof numString === "string") {
    return Number(numString.replace(/[^0-9.-]+/g, ""));
  }

  return Number(numString);
};

function registerHelpers() {
  for (const helper of cheatSheetList) {
    Handlebars.registerHelper(helper.key, helper.function);
  }
}

export function applyHandlebars(
  content: FormInterface | any,
  vars: { [key: string]: string }
): any {
  registerHelpers();
  const jsonString = JSON.stringify(content);
  const template = Handlebars.compile(jsonString, { noEscape: true });
  const newContentString = template(vars);
  const newContent = JSON.parse(newContentString);
  return newContent;
}
