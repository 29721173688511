import { Dispatch } from "redux";
import { ActionTypes } from "./types";
import { estateFormApi } from "../apis";
import { AtticusForm } from "../models/AtticusForm";

export interface FetchEstateFormsAction {
  type: ActionTypes.FetchEstateForms;
  payload: AtticusForm[];
}

export const fetchEstateForms = (estateId: number) => {
  return async (dispatch: Dispatch): Promise<void> => {
    let forms: AtticusForm[] = [];
    try {
      forms = await estateFormApi.getEstateForms(estateId);

      dispatch<FetchEstateFormsAction>({
        type: ActionTypes.FetchEstateForms,
        payload: forms,
      });
    } catch (error) {
      console.error(error);
    }
  };
};

export interface AddEstateFormAction {
  type: ActionTypes.AddEstateForm;
  payload: AtticusForm;
}

export const addEstateForm = (estateId: number, form: AtticusForm) => {
  return async (dispatch: Dispatch): Promise<void> => {
    try {
      form = await estateFormApi.addFormToEstate(estateId, form);

      dispatch<AddEstateFormAction>({
        type: ActionTypes.AddEstateForm,
        payload: form,
      });
    } catch (error) {
      console.error(error);
    }
  };
};

export interface RemoveEstateFormAction {
  type: ActionTypes.RemoveEstateForm;
  payload: {
    estateId: number;
    form: AtticusForm;
  };
}

export const removeEstateForm = (estateId: number, form: AtticusForm) => {
  return async (dispatch: Dispatch): Promise<void> => {
    try {
      await estateFormApi.removeFormFromEstate(estateId, form);

      dispatch<RemoveEstateFormAction>({
        type: ActionTypes.RemoveEstateForm,
        payload: {
          estateId,
          form,
        },
      });
    } catch (error) {
      console.error(error);
    }
  };
};

export interface FlushEstateFormsAction {
  type: ActionTypes.FlushEstateForms;
  payload: null;
}

export const flushEstateForms = (): FlushEstateFormsAction => {
  return {
    type: ActionTypes.FlushEstateForms,
    payload: null,
  };
};
