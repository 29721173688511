import { Dispatch } from "redux";
import { ActionTypes } from "./types";
import { estateApi } from "../apis";
import { TaskPhase } from "../models/TaskPhase";
import { Task } from "../models/Task";

export interface FetchTaskPhasesAction {
  type: ActionTypes.FetchTaskPhases;
  payload: TaskPhase[] | undefined;
}

export const fetchTaskPhases = (estateId: number) => {
  return async (dispatch: Dispatch): Promise<void> => {
    if (estateId) {
      dispatch<FetchTaskPhasesAction>({
        type: ActionTypes.FetchTaskPhases,
        payload: await estateApi.fetchEstateTasks(estateId),
      });
    }
  };
};

export interface SaveTaskAction {
  type: ActionTypes.SaveTask;
  payload: {
    estateId: number;
    task: Task;
  };
}

export const saveTask = (estateId: number, task: Task) => {
  return async (dispatch: Dispatch): Promise<void> => {
    if (estateId && task?.id) {
      try {
        await estateApi.saveEstateTask(
          estateId,
          task?.id,
          task.answer?.boolAnswer // will it always be boolAnswer?
        );
      } catch (error) {
        console.error(error);
      }
      dispatch<SaveTaskAction>({
        type: ActionTypes.SaveTask,
        payload: {
          estateId,
          task: task,
        },
      });
    }
  };
};

export interface ClearTaskPhasesAction {
  type: ActionTypes.ClearTaskPhases;
  payload: null;
}

export const clearTaskPhases = () => {
  return async (dispatch: Dispatch): Promise<void> => {
    dispatch<ClearTaskPhasesAction>({
      type: ActionTypes.ClearTaskPhases,
      payload: null,
    });
  };
};
