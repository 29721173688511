import { ActionTypes } from "../actions/types";
import { ThemeKey } from "../theme/ThemeKey";
import { STORAGE_KEYS } from "../constants";
import { AnyAction } from "redux";

// TODO: consider how totalEstates && advisedEstateIds can be combined
//  totalEstates used in estate list screen
//  advisedEstateIds use in dashboard screen
export type AppState = {
  themeKey: ThemeKey;
  terms: string;
  totalEstates: number;
  advisedEstateIds: number[];
  config: any;
};

const getInitialThemeKey = (): ThemeKey => {
  const theme = window.localStorage.getItem(STORAGE_KEYS.THEME_KEY);
  let themeKey: ThemeKey;
  if (theme) {
    themeKey = theme as ThemeKey;
  } else {
    themeKey = ThemeKey.Default;
  }
  return themeKey;
};

const init = {
  themeKey: getInitialThemeKey(),
  terms: "",
  totalEstates: 0,
  advisedEstateIds: [],
  config: {},
};

export const appReducer = (
  appState: AppState = init,
  action: AnyAction
): AppState => {
  switch (action.type) {
    case ActionTypes.SetThemeKey:
      window.localStorage.setItem(STORAGE_KEYS.THEME_KEY, action.payload);
      return { ...appState, themeKey: action.payload };
    case ActionTypes.SetSearchTerms:
      return { ...appState, terms: action.payload };
    case ActionTypes.SetTotalEstates:
      return { ...appState, totalEstates: action.payload };
    case ActionTypes.FetchAdvisedEstateIds:
      return { ...appState, advisedEstateIds: action.payload };
    case ActionTypes.SetConfig:
      return { ...appState, config: action.payload };
    case ActionTypes.AppSignOut:
      return init;
    default:
      return appState;
  }
};
