import React from "react";
import { AssetSubTotal } from "../../shared/AssetSummary";
import { AssetValueRow } from "../../shared/AssetValueRow";

interface Props {
  assetSubTotals?: AssetSubTotal[];
}

export const AssetTypeSummary = ({ assetSubTotals }: Props): JSX.Element => {
  const totalAsset = assetSubTotals?.find(
    (asset: AssetSubTotal) => asset.key === "Total"
  );

  return (
    <>
      {assetSubTotals &&
        assetSubTotals.map(
          (asset: AssetSubTotal) =>
            asset.key !== "Total" && (
              <AssetValueRow asset={asset} key={asset.key} />
            )
        )}
      {totalAsset && <AssetValueRow asset={totalAsset} bold={true} />}
    </>
  );
};
