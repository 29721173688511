import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import { StoreState } from "../../../../../reducers";
import { Estate } from "../../../../../models/Estate";
import { User } from "../../../../../models/User";
import { GeographyType, State } from "../../../../../models/Geography";
import { Language } from "../../../../../models/Language";
import { FormType } from "../../../../../models/FormType";
import { fetchProbateTypes } from "../../../../../actions/probateTypeActions";
import { fetchStates } from "../../../../../actions/geographyActions";
import { fetchLanguages } from "../../../../../actions/languageActions";
import { fetchFormTypes } from "../../../../../actions/formTypeActions";
import { ProbateType } from "../../../../../models/ProbateType";
import EstateFormsComponent from "../EstateFormsComponent";
import AtticusDrawer from "../../../../shared/drawer/AtticusDrawer";
import { useNavigate, useLocation } from "react-router-dom";

interface Props {
  user: User;
  estates?: Estate[];
  states?: State[];
  fetchStates: () => void;
  languages?: Language[];
  fetchLanguages: () => void;
  formTypes?: FormType[];
  fetchFormTypes: () => void;
  probateTypes?: ProbateType[];
  fetchProbateTypes: () => void;
}

const _EstateFormsView = ({
  user,
  estates,
  states,
  fetchStates,
  languages,
  fetchLanguages,
  formTypes,
  fetchFormTypes,
  probateTypes,
  fetchProbateTypes,
}: Props): JSX.Element => {
  const navigate = useNavigate();
  const location = useLocation();

  const { estateId: estateIdString } = useParams();
  const estateId = estateIdString ? parseInt(estateIdString, 10) : undefined;

  const [estate, setEstate] = useState<Estate | undefined>(undefined);
  const [probateType, setProbateType] = useState<ProbateType | undefined>(
    undefined
  );

  const [drawerOpen, setDrawerOpen] = React.useState(false);
  const [drawerContents, setDrawerContents] = React.useState<JSX.Element>();
  const handleDrawerOpen = () => {
    setDrawerOpen(true);
  };

  const handleDrawerClose = () => {
    setDrawerOpen(false);
  };

  const hasAccess = user?.advisorGroup?.hasAdvisorAccessToForms;

  useEffect(() => {
    if (hasAccess && estateId) {
      if (!states || !states?.length) {
        fetchStates();
      }
      if (!languages || !languages?.length) {
        fetchLanguages();
      }
      if (!formTypes || !formTypes?.length) {
        fetchFormTypes();
      }
      if (!probateTypes || !probateTypes?.length) {
        fetchProbateTypes();
      }
    }
  }, []);

  useEffect(() => {
    if (estateId && estates?.length) {
      const estate = estates.find((estate: Estate) => estate.id === +estateId);
      setEstate(estate);
    }
  }, [estates]);

  useEffect(() => {
    const newPT = probateTypes?.find((pt) => pt.id === estate?.probateTypeId);
    setProbateType(newPT);
  }, [estate, probateTypes]);

  return hasAccess ? (
    <div data-automation-id="page-content-estate-forms">
      {estate && (
        <>
          <EstateFormsComponent
            estate={estate}
            probateType={probateType}
            sx={{ mb: 5 }}
            openDrawer={handleDrawerOpen}
            setDrawerContents={setDrawerContents}
            onEmptyButtonClick={() => {
              navigate(`${location.pathname}/../forms-library`, {
                state: {
                  geography_type: GeographyType.State,
                  geography_id: estate.stateId,
                },
              });
            }}
          />
          <AtticusDrawer
            variant="temporary"
            anchor="right"
            open={drawerOpen}
            onClose={handleDrawerClose}
            contents={drawerContents}
          />
        </>
      )}
    </div>
  ) : (
    <></>
  );
};

const mapStateToProps = ({
  user,
  estates,
  states,
  formTypes,
  languages,
  probateTypes,
}: StoreState): {
  user: User;
  estates: Estate[];
  states: State[];
  formTypes: FormType[];
  languages: Language[];
  probateTypes: ProbateType[];
} => {
  return {
    user,
    estates,
    states,
    formTypes,
    languages,
    probateTypes,
  };
};
const EstateFormsView = connect(mapStateToProps, {
  fetchStates,
  fetchLanguages,
  fetchFormTypes,
  fetchProbateTypes,
})(_EstateFormsView);

export default EstateFormsView;
