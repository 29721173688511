import React, { useState } from "react";
import {
  IconButton,
  TableCell,
  TableRow,
  Theme,
  Typography,
  Tooltip,
  Link,
  Box,
} from "@mui/material";
import { makeStyles } from "../../../utils/makeStyles";
import DeleteIcon from "@mui/icons-material/Delete";
import { AtticusForm } from "../../../models/AtticusForm";
import { Language } from "../../../models/Language";
import { connect } from "react-redux";
import { StoreState } from "../../../reducers";
import { ConfirmationDialog } from "../../shared/dialog/ConfirmationDialog";
import { probateApi } from "../../../apis";
import { ProbateType } from "../../../models/ProbateType";
import AccountTreeIcon from "@mui/icons-material/AccountTree";
import { useNavigate } from "react-router-dom";

const useStyles = makeStyles((theme: Theme) => ({
  rowHeader: {
    backgroundColor: theme.palette.background.paper,
  },
  rowContent: {
    backgroundColor: theme.palette.background.paper,
    "&:nth-of-type(even)": {
      backgroundColor: theme.palette.grey[50],
    },
  },
}));

interface Props {
  form: AtticusForm;
  languages?: Language[];
  probateType: ProbateType;
  refresh: () => void;
  onShowForm: (form: AtticusForm) => void;
  display?: boolean;
  showHeader?: boolean;
}

const _ProbateFormRow = ({
  form,
  languages,
  probateType,
  refresh,
  display,
  showHeader = false,
  onShowForm,
}: Props): JSX.Element => {
  const classes = useStyles();
  const navigate = useNavigate();

  const [isDeleteConfirmOpen, setIsDeleteConfirmOpen] = useState(false);

  const handleDeleteForm = async (): Promise<void> => {
    await probateApi.deleteFormMapping(probateType, form);
    setIsDeleteConfirmOpen(false);
    refresh();
  };

  const getDeleteDescription = () => {
    return (
      <>
        <Typography>
          <strong>Probate Type Name</strong>: {probateType?.name}
        </Typography>
        <Typography>
          <strong>Form Title</strong>: {form?.title.original}
        </Typography>
      </>
    );
  };

  return (
    <>
      <ConfirmationDialog
        open={isDeleteConfirmOpen}
        description={getDeleteDescription()}
        question="Are you sure you want to remove this probate to form mapping?"
        onConfirm={() => handleDeleteForm()}
        onDeny={() => {
          setIsDeleteConfirmOpen(false);
        }}
      />
      {showHeader ? (
        <TableRow
          className={`${classes.rowHeader} dense`}
          style={{
            display: display ? "table-row" : "none",
          }}
        >
          <TableCell />
          <TableCell>
            <strong>form title (form id)</strong>
          </TableCell>
          <TableCell>
            <strong>gov id</strong>
          </TableCell>
          <TableCell>
            <strong>lang</strong>
          </TableCell>
          <TableCell>
            <strong>has interface?</strong>
          </TableCell>
          <TableCell />
        </TableRow>
      ) : null}
      <TableRow
        className={`${classes.rowContent} dense`}
        style={{
          display: display ? "table-row" : "none",
        }}
      >
        <TableCell />
        <TableCell>
          <a onClick={() => onShowForm(form)}>
            {form.title.original} ({form.id})
          </a>
        </TableCell>
        <TableCell>{form.governmentId}</TableCell>
        <TableCell>
          {languages?.find((lang) => form.languageId == lang.id)?.code || "--"}
        </TableCell>
        <TableCell>
          {form.latestInterfaceId
            ? `Yes (id: ${form.latestInterfaceId})`
            : "No"}
        </TableCell>
        <TableCell>
          <Box display="flex" flexDirection="row">
            <Tooltip
              title={
                form?.latestInterfaceId
                  ? "Continue editing Form Interface"
                  : "Create Form Interface"
              }
            >
              <span style={{ cursor: "default" }}>
                <Link
                  onClick={() => {
                    navigate(`/app/admin/forms/interface/${form?.id}`);
                  }}
                >
                  <IconButton
                    size="small"
                    aria-label="view"
                    color={form.latestInterfaceId ? "secondary" : "default"}
                  >
                    <AccountTreeIcon />
                  </IconButton>
                </Link>
              </span>
            </Tooltip>
            <IconButton
              size="small"
              aria-label="expand"
              onClick={() => {
                setIsDeleteConfirmOpen(true);
              }}
            >
              <DeleteIcon />
            </IconButton>
          </Box>
        </TableCell>
      </TableRow>
    </>
  );
};

const mapStateToProps = ({
  languages,
}: StoreState): {
  languages: Language[];
} => {
  return {
    languages,
  };
};

export const ProbateFormRow = connect(mapStateToProps)(_ProbateFormRow);
