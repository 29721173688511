import { ActionTypes } from "../actions/types";
import { OpportunityType } from "../models/OpportunityType";
import { Opportunity } from "../models/Opportunity";
import { AnyAction } from "redux";

export const opportunitiesReducer = (
  opportunities: Opportunity[] | null = null,
  action: AnyAction
): Opportunity[] | null => {
  switch (action.type) {
    case ActionTypes.FetchOpportunities:
      return action.payload;
    case ActionTypes.SaveOpportunity:
      if (opportunities && action.payload.opp) {
        if (action.payload.isNew) {
          return [...opportunities, action.payload.opp];
        } else {
          return opportunities.map((opp: Opportunity) =>
            opp.id === action.payload.opp.id ? action.payload.opp : opp
          );
        }
      }
      return opportunities;
    case ActionTypes.DeleteOpportunity: {
      if (opportunities && action.payload.opp) {
        return opportunities.filter(
          (opp: Opportunity) => opp.id !== action.payload.opp.id
        );
      }
      return opportunities;
    }
    case ActionTypes.AppSignOut:
      return null;
    case ActionTypes.FetchSingleOpportunity: {
      if (opportunities) {
        const index = opportunities?.findIndex(
          (opportunity: Opportunity) => opportunity.id === action.payload.id
        );
        if (index !== -1) {
          const newOpportunities = [...opportunities];
          newOpportunities[index] = action.payload;
          return newOpportunities;
        } else {
          return [...opportunities, action.payload];
        }
      } else {
        return [action.payload];
      }
    }
    default:
      return opportunities;
  }
};

export const opportunityTypesReducer = (
  opportunityTypes: OpportunityType[] | null = null,
  action: AnyAction
): OpportunityType[] | null => {
  switch (action.type) {
    case ActionTypes.FetchOpportunityTypes:
      return action.payload;
    case ActionTypes.AppSignOut:
      return null;
    default:
      return opportunityTypes;
  }
};

export const opportunityStatusesReducer = (
  opportunityStatuses: OpportunityType[] | null = null,
  action: AnyAction
): OpportunityType[] | null => {
  switch (action.type) {
    case ActionTypes.FetchOpportunityStatuses:
      return action.payload;
    case ActionTypes.AppSignOut:
      return null;
    default:
      return opportunityStatuses;
  }
};

export const opportunitiesCountReducer = (
  opportunitiesCount: number | null = null,
  action: AnyAction
): number | null => {
  switch (action.type) {
    case ActionTypes.FetchOpportunitiesCount:
      return action.payload;
    default:
      return opportunitiesCount;
  }
};

export interface LoadingOpportunities { loading: boolean, lastNumOpportunities: number } 
export const loadingOpportunitiesReducer = (
  isLoading:LoadingOpportunities = { loading: false, lastNumOpportunities: 0 },
  action: AnyAction
): LoadingOpportunities | null => {
  switch (action.type) {
    case ActionTypes.LoadingOpportunities:
      return action.payload;
    default:
      return isLoading;
  }
};
