import { CognitoPasswordUpdateForm } from "../../auth/CognitoPasswordUpdateForm";
import { Button, Card, CardContent, Theme } from "@mui/material";
import { makeStyles } from "tss-react/mui";
import React, { useState } from "react";

const useStyles = makeStyles()((theme: Theme) => ({
  root: {
    marginTop: theme.spacing(3),
  },
}));

export const UpdatePassword = (): JSX.Element => {
  const { classes } = useStyles();
  const [showPasswordUpdate, setShowPasswordUpdate] = useState(false);

  return (
    <Card className={classes.root}>
      <CardContent>
        {showPasswordUpdate ? (
          <CognitoPasswordUpdateForm />
        ) : (
          <Button
            variant="contained"
            color="primary"
            type="button"
            fullWidth
            onClick={() => setShowPasswordUpdate(true)}
          >
            Update Password
          </Button>
        )}
      </CardContent>
    </Card>
  );
};
