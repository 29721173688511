import * as React from 'react';
import GlobalStyles from '@mui/material/GlobalStyles';
import { Theme } from '@mui/material/styles';

export default <GlobalStyles styles={{
      "*": {
        boxSizing: "border-box",
        margin: 0,
        padding: 0,
      },
      html: {
        fontFamily: '"Montserrat", "Helvetica", "Arial", sans-serif',
        WebkitFontSmoothing: "antialiased",
        MozOsxFontSmoothing: "grayscale",
        height: "100%",
        width: "100%",
      },
      body: {
        height: "100%",
        width: "100%",
      },
      a: {
        color: (theme:Theme) => theme.palette.primary.main,
        "&:hover": {
          color: (theme:Theme) => theme.palette.primary.dark,
        },
        textDecoration: "none",
        cursor: "pointer",
      },
      "#root": {
        height: "100%",
        width: "100%",
      },
      ul: {
        padding: "10px 20px",
      },
      // notistack Snackbar styles
      "div.notistack-MuiContent-success, div.notistack-MuiContent-default": {
        backgroundColor: "#F0F0FE",
        color: "#2A2A52",
      },
      "div.notistack-MuiContent-warning, div.notistack-MuiContent-error": {
        backgroundColor: "#FFE9E8",
        color: "#591918",
      },
  }} />
