import { ActionTypes } from "../actions/types";
import { FormType } from "../models/FormType";
import { AnyAction } from "redux";

export const formTypesReducer = (
  formTypes: FormType[] | null = null,
  action: AnyAction
): FormType[] | null => {
  switch (action.type) {
    case ActionTypes.FetchFormTypes:
      return action.payload;
    case ActionTypes.FetchSingleFormType:
      if (formTypes) {
        const index = formTypes?.findIndex(
          (formType: FormType) => formType.id === action.payload.id
        );
        if (index !== -1) {
          const newFormTypes = [...formTypes];
          newFormTypes[index] = action.payload;
          return newFormTypes;
        } else {
          return [...formTypes, action.payload];
        }
      } else {
        return [action.payload];
      }
    case ActionTypes.AppSignOut:
      return null;
    default:
      return formTypes;
  }
};
