import React, { useEffect, useState } from "react";
import {
  Box,
  Card,
  CardContent,
  Typography,
  Avatar,
  Theme,
  IconButton,
  useTheme,
  Stack,
  useMediaQuery,
  Link,
  SxProps,
  Skeleton,
} from "@mui/material";
import HelpIcon from "@mui/icons-material/HelpOutline";

// Redux Imports
import { connect } from "react-redux";
import { StoreState } from "../../../../reducers";

// Model Imports
import { FormType } from "../../../../models/FormType";
import { AtticusForm } from "../../../../models/AtticusForm";
import { FormThumbnail } from "../FormThumbnail";
import { ProbateType } from "../../../../models/ProbateType";
import { User } from "../../../../models/User";
import { SplitButtonGroup, SplitButtonOption } from "../../SplitButtonGroup";

import { Country, State } from "../../../../models/Geography";
import { atticusColors } from "@theatticusapp/atticus-shared-components";

interface Props {
  user: User;
  form?: AtticusForm;
  formTypes?: FormType[];
  pdfDialogOpen: boolean;
  setPdfDialogForm: (form: AtticusForm) => void;
  togglePdfDialog: () => void;
  descriptionDialogOpen: boolean;
  setDescriptionDialogForm: (form: AtticusForm) => void;
  toggleDescriptionDialog: () => void;
  sx?: SxProps<Theme>;
  formOptions?: SplitButtonOption[];
  checkedBadge?: boolean;
}

function stringAvatar(name?: string) {
  return name
    ? `${name.split(" ")[0][0]}${name.split(" ")[1][0]}`.toUpperCase()
    : undefined;
}

const _FormsListItemCard = ({
  user,
  form,
  formTypes,
  setPdfDialogForm,
  togglePdfDialog,
  descriptionDialogOpen,
  setDescriptionDialogForm,
  toggleDescriptionDialog,
  formOptions,
  sx,
  checkedBadge = false,
}: Props): JSX.Element => {
  const theme = useTheme();
  const isMobileSize = useMediaQuery(theme.breakpoints.down("sm"));

  const [loading, setLoading] = useState<boolean>(true);
  const [formType, setFormType] = useState<FormType>();

  const stackFontSize = isMobileSize ? "0.75rem" : "1rem";

  useEffect(() => {
    if (form && (!form.formTypeId || formType)) {
      setLoading(false);
    } else {
      setLoading(true);
    }
  }, [form, formType]);

  useEffect(() => {
    if (form && formType) {
      setLoading(false);
    } else {
      setLoading(true);
    }
  }, [formTypes]);

  useEffect(() => {
    setFormType(
      formTypes?.find((formType) => formType.id === form?.formTypeId)
    );
  }, [formTypes]);

  const getSourcePuck = (source: ProbateType | User): JSX.Element => {
    let text:string|undefined = undefined;
    let avatarAlt:string|undefined = undefined;
    let avatarSrc:string|undefined = undefined;
    let avatarText:string|undefined = undefined;
    let avatarBgColor:string|undefined = undefined;
    const avatarTextColor:string|undefined = atticusColors.purple5;
    let textColor:string|undefined = undefined;

    switch (true) {
      case source instanceof ProbateType:
        source = source as ProbateType;
        text = `Included in ${source.stateCode} ${source.name}`;
        avatarAlt = "Atticus Logo on top of a purple background";
        avatarSrc = "/static/images/atticus-a.svg";
        avatarBgColor = atticusColors.purple4;
        textColor = "primary.main";
        break;
      case source instanceof User:
        source = source as User;
        avatarText = stringAvatar(source.fullName);
        avatarBgColor = atticusColors.purple2;
        text = `Added by ${source.id == user?.id ? "you" : source.fullName}`;
        textColor = "text.primary";
        break;
    }

    return (
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          gap: "0.5rem",
          flex: 1,
        }}
      >
        <>
          <Avatar
            src={avatarSrc}
            alt={avatarAlt}
            sx={{
              height: "1.5rem",
              width: "1.5rem",
              bgcolor: avatarBgColor,
              color: avatarTextColor,
              fontSize: "0.8rem",
              fontWeight: "800",
              "& img": {
                width: "50%",
                height: "50%",
              },
            }}
          >
            {avatarText}
          </Avatar>

          <Typography
            variant="body1"
            color={textColor}
            sx={{ fontSize: stackFontSize }}
          >
            {text}
          </Typography>
        </>
      </Box>
    );
  };

  return (
    <Card variant="outlined" sx={sx}>
      <CardContent sx={{ padding: "0 !IMPORTANT" }}>
        <Box
          sx={{
            display: "flex",
            gap: 2,
            mb: 0,
          }}
        >
          {loading ? (
            <Skeleton
              variant="rectangular"
              sx={{
                height: "5rem",
                width: "3.875rem",
              }}
            />
          ) : (
            <FormThumbnail
              form={form}
              onClick={async () => {
                if (form) {
                  setPdfDialogForm(form);
                  togglePdfDialog();
                }
              }}
              sx={{
                width: "3.875rem",
                alignItems: isMobileSize ? "baseline" : "center",
              }}
              checkedBadge={checkedBadge}
            />
          )}
          <Box sx={{ display: "flex", gap: 2, flex: 1 }}>
            <Box
              sx={{
                flex: 1,
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              <Box
                sx={(theme: Theme) => ({
                  color: theme.palette.primary.dark,
                  marginBottom: "0.25rem",
                  display: "flex",
                  gap: "0.5rem",
                })}
              >
                {loading ? (
                  <Skeleton
                    variant="text"
                    sx={{
                      width: "90%",
                    }}
                  />
                ) : (
                  <>
                    <Link
                      sx={{
                        fontWeight: "700",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        wordBreak: "break-word",
                        display: "-webkit-box",
                        WebkitLineClamp: "3",
                        WebkitBoxOrient: "vertical",
                        flex: isMobileSize ? 1 : undefined,
                      }}
                      underline="hover"
                      color="primary.dark"
                      onClick={async () => {
                        if (form) {
                          setPdfDialogForm(form);
                          togglePdfDialog();
                        }
                      }}
                    >
                      {form?.getTitle()}
                    </Link>
                    {form?.getDescription() && (
                      <IconButton
                        size="small"
                        disabled={descriptionDialogOpen}
                        onClick={async () => {
                          setDescriptionDialogForm(form);
                          toggleDescriptionDialog();
                        }}
                        sx={{
                          height: "fit-content",
                          marginTop: "-5px",
                          color: "primary.dark",
                          "&:hover": { color: "primary.dark" },
                        }}
                      >
                        <HelpIcon />
                      </IconButton>
                    )}
                  </>
                )}
              </Box>
              <Stack
                direction="row"
                gap={isMobileSize ? 1 : 2}
                useFlexGap
                flexWrap={isMobileSize ? "wrap" : undefined}
              >
                {loading ? (
                  <Skeleton variant="text" sx={{ width: "15%" }} />
                ) : (
                  <Typography
                    variant="body1"
                    color="text.primary"
                    sx={{
                      fontSize: stackFontSize,
                    }}
                  >
                    {form?.geography instanceof Country ||
                    form?.geography instanceof State
                      ? form?.geography.code
                      : form?.geography?.name}
                  </Typography>
                )}
                {loading ? (
                  <Skeleton variant="text" sx={{ width: "15%" }} />
                ) : (
                  <Typography
                    variant="body1"
                    color="text.primary"
                    sx={{
                      fontSize: stackFontSize,
                    }}
                  >
                    {`${
                      formTypes?.find((ft) => ft.id == form?.formTypeId)?.name
                    }`}
                  </Typography>
                )}
                {loading ? (
                  <Skeleton variant="text" sx={{ width: "15%" }} />
                ) : (
                  <Typography
                    variant="body1"
                    color="text.primary"
                    sx={{
                      fontSize: stackFontSize,
                    }}
                  >
                    {form?.getGovernmentId()}
                  </Typography>
                )}
                {form?.estateFormMap?.source && (
                  <>
                    {loading ? (
                      <Skeleton variant="text" sx={{ width: "30%" }} />
                    ) : (
                      getSourcePuck(form?.estateFormMap.source)
                    )}
                  </>
                )}
              </Stack>
            </Box>
            {!loading && formOptions && (
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <SplitButtonGroup
                  color="primary"
                  options={formOptions}
                  variant="contained"
                  popperPlacement="bottom-end"
                  sx={{
                    maxWidth: "12rem",
                  }}
                />
              </Box>
            )}
          </Box>
        </Box>
      </CardContent>
    </Card>
  );
};

const mapStateToProps = ({
  user,
  formTypes,
}: StoreState): {
  user: User;
  formTypes: FormType[];
} => {
  return { user, formTypes };
};

export const FormsListItemCard = connect(mapStateToProps)(_FormsListItemCard);
