import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Grid,
  IconButton,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Theme,
  Typography,
} from "@mui/material";
import { makeStyles } from "tss-react/mui";
import { callDelete, dataExportApi, logger } from "../../../apis";
import moment from "moment/moment";
import { AdvisorGroup } from "../../../models/AdvisorGroup";
import { StoreState } from "../../../reducers";
import { User } from "../../../models/User";
import { connect } from "react-redux";
import { fetchAdvisorGroups } from "../../../actions/advisorGroupActions";
import { DetailCard } from "../../shared/DetailCard";
import PerfectScrollbar from "react-perfect-scrollbar";
import { AppTableWrapper } from "../../shared/AppTableWrapper";
import {
  DataExport,
  ExportFormat,
  ExportStatus,
  ExportType,
} from "../../../models/DataExport";
import { ConfirmationDialog } from "../../shared/dialog/ConfirmationDialog";
import CancelIcon from "@mui/icons-material/Cancel";
import { LoadingSpinner } from "../../shared/LoadingSpinner";

const useStyles = makeStyles()((theme:Theme) => { return {
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  textField: {
    marginRight: "8px",
    width: 200,
  },
  highlight: {
    backgroundColor: "#ccc",
  },
}});

interface Props {
  user: User;
  advisorGroups: AdvisorGroup[];
  fetchAdvisorGroups: () => void;
}

let processingTimeout: number | undefined = undefined;

const _DataExportView = ({
  user,
  advisorGroups,
  fetchAdvisorGroups,
}: Props) => {
  const { classes } = useStyles();
  const [downloading, setDownloading] = useState(false);
  const [creating, setCreating] = useState(false);
  const [error, setError] = useState("");
  const firstOfMonth = moment().format("YYYY-MM") + "-01";
  const tomorrow = moment().add(1, "day").format("YYYY-MM-DD");
  const [startDate, setStartDate] = useState(firstOfMonth);
  const [endDate, setEndDate] = useState(tomorrow);
  const [listAdvisorGroupId, setListAdvisorGroupId] = useState<
    number | undefined
  >(user?.advisorGroupId);
  const [advisorGroupId, setAdvisorGroupId] = useState<number | undefined>(
    user?.advisorGroupId
  );
  const [format, setFormat] = useState(ExportFormat.json);
  const [dataExports, setDataExports] = useState<DataExport[]>();
  const [newExportId, setNewExportId] = useState<number | undefined>();
  const [isDeleteConfirmOpen, setIsDeleteConfirmOpen] = useState(false);
  const [deleteDataExportId, setDeleteDataExportId] = useState<number>();

  useEffect(() => {
    if (user?.isUberAdmin && !advisorGroups?.length) {
      fetchAdvisorGroups();
    }
    return stopGettingExports;
  }, []);

  const getAdvisorGroupName = (advisorGroupId?: number): string | undefined => {
    if (user?.isUberAdmin) {
      return (
        advisorGroups
          .filter(
            (advisorGroup: AdvisorGroup) => advisorGroup.id === advisorGroupId
          )[0]
          ?.title?.substr(3) || undefined
      );
    } else {
      return user?.advisorGroupName;
    }
  };

  const stopGettingExports = (): void => {
    // stop polling for processing exports
    if (processingTimeout) {
      window.clearTimeout(processingTimeout);
    }
  };

  const getDataExports = async (groupId?: number): Promise<void> => {
    try {
      setError("");
      setDownloading(true);
      // sometimes groupId is passed in rather than use the listAdvisorGroupId state
      // this helps to account for react state re-render sequencing
      const advisorGroupId = groupId || listAdvisorGroupId;
      if (advisorGroupId) {
        const exports = await dataExportApi.listDataExports(advisorGroupId);
        setDataExports(exports);
        // check if any are still processing
        const isProcessing = exports?.filter(
          (dataExport: DataExport) =>
            dataExport?.status === ExportStatus.processing
        )?.length;
        if (isProcessing) {
          processingTimeout = window.setTimeout(async () => {
            setDataExports(undefined);
            await getDataExports();
          }, 2000);
        }
      } else {
        throw Error("Sorry, could not determine advisor group");
      }
    } catch (error) {
      logger.error((error as Error)?.message);
      setError((error as Error)?.message);
    }
    setDownloading(false);
  };

  const downloadDataExport = async (dataExportId?: number): Promise<void> => {
    try {
      setError("");
      setDownloading(true);
      if (dataExportId) {
        if (!(await dataExportApi.downloadDataExport(dataExportId))) {
          throw Error("Sorry, export download failed");
        }
      } else {
        throw Error("Sorry, could not determine advisor group");
      }
    } catch (error) {
      logger.error((error as Error)?.message);
      setError((error as Error)?.message);
    }
    setDownloading(false);
  };

  const createNewDataExport = async (type: ExportType): Promise<void> => {
    try {
      setError("");
      setCreating(true);
      if (advisorGroupId) {
        const newExportId = await dataExportApi.createDataExport(
          advisorGroupId,
          startDate,
          endDate,
          type,
          format
        );
        setListAdvisorGroupId(advisorGroupId);
        await getDataExports(advisorGroupId);
        setNewExportId(newExportId);
        // scroll up to list
        const exportList = window.document.getElementById("data-exports-top");
        if (exportList) {
          exportList.scrollIntoView();
        }
        // clear highlight
        window.setTimeout(() => {
          if (setNewExportId) {
            setNewExportId(undefined);
          }
        }, 5000);
      } else {
        throw Error("Sorry, could not determine advisor group");
      }
    } catch (error) {
      logger.error((error as Error)?.message);
      setError((error as Error)?.message);
    }
    setCreating(false);
  };

  const handleDelete = async (dataExportId?: number): Promise<void> => {
    if (dataExportId) {
      try {
        setIsDeleteConfirmOpen(false);
        setDeleteDataExportId(undefined);
        await callDelete(`${DataExport.path}/${dataExportId}`);
        await getDataExports();
      } catch (error) {
        logger.error(error as Error);
      }
    }
  };

  const getStatusElement = (dataExport: DataExport): JSX.Element => {
    switch (dataExport.status) {
      case ExportStatus.ready:
        return (
          <Button
            variant="contained"
            color="primary"
            onClick={() => downloadDataExport(dataExport.id)}
          >
            download
          </Button>
        );
      case ExportStatus.processing:
        return <Typography>processing...</Typography>;
      case ExportStatus.failed:
        return <Typography>failed</Typography>;
      default:
        return <Typography>unknown</Typography>;
    }
  };

  // assumes dateString from server: "2022-01-26T00:00:00.000000Z"
  const formatDateString = (dateString?: string): string => {
    if (dateString) {
      return (
        dateString.substr(5, 5).replace("-", "/") +
        "/" +
        dateString.substr(0, 4)
      );
    } else {
      return "";
    }
  };

  return (
    <>
      <DetailCard title="Data Exports">
        <>
          <div id="data-exports-top" />
          {user?.isUberAdmin && (
            <Grid item xs={12}>
              <Select
                name="group"
                value={listAdvisorGroupId}
                onChange={(e) => {
                  if (e.target.value) {
                    stopGettingExports();
                    setDataExports(undefined);
                    setListAdvisorGroupId(e.target.value as number);
                  }
                }}
                variant="outlined"
                displayEmpty={true}
                style={{ marginBottom: 8 }}
              >
                {advisorGroups?.map((advisorGroup: AdvisorGroup) => {
                  return (
                    <MenuItem value={advisorGroup.id} key={advisorGroup.id}>
                      {advisorGroup.title}
                    </MenuItem>
                  );
                })}
              </Select>
            </Grid>
          )}
          <Button
            variant="contained"
            color="primary"
            disabled={downloading}
            onClick={() => getDataExports()}
          >
            Retrieve existing exports
          </Button>
          {dataExports && (
            <PerfectScrollbar>
              <AppTableWrapper minWidth={600} noPad={true}>
                <>
                  <ConfirmationDialog
                    open={isDeleteConfirmOpen}
                    description={deleteDataExportId?.toString() || ""}
                    question="Are you sure you want to delete this Data Export?"
                    onConfirm={() => handleDelete(deleteDataExportId)}
                    onDeny={() => {
                      setDeleteDataExportId(undefined);
                      setIsDeleteConfirmOpen(false);
                    }}
                  />
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>id</TableCell>
                        <TableCell>date range</TableCell>
                        <TableCell>format</TableCell>
                        <TableCell>type</TableCell>
                        <TableCell>creator id</TableCell>
                        {user?.isUberAdmin && <TableCell>group name</TableCell>}
                        <TableCell />
                        <TableCell />
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {dataExports.length === 0 && (
                        <TableRow>
                          <TableCell colSpan={6}>No exports created</TableCell>
                        </TableRow>
                      )}
                      {dataExports.length ? (
                        dataExports.map((dataExport: DataExport) => (
                          <TableRow
                            key={`${dataExport?.id}_dataExport`}
                            className={
                              dataExport?.id === newExportId
                                ? classes.highlight
                                : ""
                            }
                          >
                            <TableCell>{dataExport?.id}</TableCell>
                            <TableCell>
                              <span style={{ whiteSpace: "nowrap" }}>
                                {formatDateString(dataExport?.startDate)}
                              </span>{" "}
                              -{" "}
                              <span style={{ whiteSpace: "nowrap" }}>
                                {formatDateString(dataExport?.endDate)}
                              </span>
                            </TableCell>
                            <TableCell>{dataExport?.format}</TableCell>
                            <TableCell>{dataExport?.type}</TableCell>
                            <TableCell>
                              {dataExport?.creatorAdvisorId}
                            </TableCell>
                            {user?.isUberAdmin && (
                              <TableCell>
                                {getAdvisorGroupName(
                                  dataExport?.advisorGroupId
                                )}
                              </TableCell>
                            )}
                            <TableCell>
                              {getStatusElement(dataExport)}
                            </TableCell>
                            <TableCell>
                              <IconButton
                                aria-label="delete"
                                onClick={() => {
                                  setDeleteDataExportId(dataExport?.id);
                                  setIsDeleteConfirmOpen(true);
                                }}
                                color="primary"
                              >
                                <CancelIcon />
                              </IconButton>
                            </TableCell>
                          </TableRow>
                        ))
                      ) : (
                        <></>
                      )}
                    </TableBody>
                  </Table>
                </>
              </AppTableWrapper>
            </PerfectScrollbar>
          )}
          {!dataExports && downloading && (
            <LoadingSpinner message="loading Exports..." inCard={false} />
          )}
        </>
      </DetailCard>
      <br />
      <DetailCard
        title="Create a new Data Export"
        subTitle="Select the time period you'd like to export"
      >
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Button
              variant="contained"
              color="inherit"
              disabled={downloading}
              onClick={() => {
                const firstOfLastMonth =
                  moment().subtract(1, "month").format("YYYY-MM") + "-01";
                const lastOfLastMonth = moment()
                  .subtract(1, "month")
                  .endOf("month")
                  .format("YYYY-MM-DD");
                setStartDate(firstOfLastMonth);
                setEndDate(lastOfLastMonth);
              }}
              style={{ marginRight: 8 }}
            >
              last month
            </Button>
            <Button
              color="inherit"
              variant="contained"
              disabled={downloading}
              onClick={() => {
                const firstOfMonth = moment().format("YYYY-MM") + "-01";
                const tomorrow = moment().add(1, "day").format("YYYY-MM-DD");
                setStartDate(firstOfMonth);
                setEndDate(tomorrow);
              }}
            >
              this month
            </Button>
          </Grid>
          <Grid item xs={12}>
            <TextField
              id="startDate"
              label="Start Date"
              type="date"
              value={startDate}
              className={classes.textField}
              InputLabelProps={{
                shrink: true,
              }}
              onChange={(e) => {
                setStartDate(e.target.value);
              }}
            />
            <TextField
              id="endDate"
              label="End Date"
              type="date"
              value={endDate}
              className={classes.textField}
              InputLabelProps={{
                shrink: true,
              }}
              onChange={(e) => {
                setEndDate(e.target.value);
              }}
            />
          </Grid>
          <Grid item xs={12}>
            {user?.isUberAdmin && (
              <Grid item xs={12}>
                <Select
                  name="group"
                  value={advisorGroupId}
                  onChange={(e) => {
                    if (e.target.value) {
                      setAdvisorGroupId(e.target.value as number);
                    }
                  }}
                  variant="outlined"
                  displayEmpty={true}
                  style={{ marginBottom: 8 }}
                >
                  {advisorGroups?.map((advisorGroup: AdvisorGroup) => {
                    return (
                      <MenuItem value={advisorGroup.id} key={advisorGroup.id}>
                        {advisorGroup.title}
                      </MenuItem>
                    );
                  })}
                </Select>
              </Grid>
            )}
            <Grid item xs={12}>
              <Select
                name="format"
                value={format}
                onChange={(e) => {
                  if (e.target.value) {
                    // there's a more elegant way to do this
                    switch (e.target.value) {
                      case "csv":
                        setFormat(ExportFormat.csv);
                        break;
                      case "json":
                        setFormat(ExportFormat.json);
                        break;
                    }
                  }
                }}
                variant="outlined"
                style={{ marginBottom: 8 }}
              >
                <MenuItem value={ExportFormat.json} key={ExportFormat.json}>
                  {ExportFormat.json}
                </MenuItem>
                <MenuItem value={ExportFormat.csv} key={ExportFormat.csv}>
                  {ExportFormat.csv}
                </MenuItem>
              </Select>
            </Grid>
            <Box display="flex" mt={2}>
              {format === ExportFormat.json ? (
                <Button
                  variant="contained"
                  color="primary"
                  disabled={creating}
                  onClick={() => createNewDataExport(ExportType.both)}
                >
                  Create new Data Export
                </Button>
              ) : (
                <>
                  <Button
                    variant="contained"
                    color="primary"
                    disabled={downloading}
                    onClick={() => createNewDataExport(ExportType.aggregate)}
                    style={{ marginRight: 8 }}
                  >
                    Create new Aggregate Data Export
                  </Button>
                  {/* must have full access - id=1 */}
                  {user.advisorGroup?.access?.id === 1 && (
                    <Button
                      variant="contained"
                      color="primary"
                      disabled={creating}
                      onClick={() => createNewDataExport(ExportType.individual)}
                    >
                      Create new Individual Estates Data Export
                    </Button>
                  )}
                </>
              )}
            </Box>
            {creating && <Typography>creating export...</Typography>}
            <Typography color="error">{error}</Typography>
          </Grid>
        </Grid>
      </DetailCard>
    </>
  );
};

const mapStateToProps = ({
  user,
  advisorGroups,
}: StoreState): { user: User; advisorGroups: AdvisorGroup[] } => {
  return { user, advisorGroups };
};

export const DataExportView = connect(mapStateToProps, {
  fetchAdvisorGroups,
})(_DataExportView);
