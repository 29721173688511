import React from "react";

import household1 from "./household@1x.png";
import household2 from "./household@2x.png";
import household3 from "./household@3x.png";

import realestate1 from "./realestate@1x.png";
import realestate2 from "./realestate@2x.png";
import realestate3 from "./realestate@3x.png";

import primaryhome1 from "./primaryhome@1x.png";
import primaryhome2 from "./primaryhome@2x.png";
import primaryhome3 from "./primaryhome@3x.png";

import rentalproperty1 from "./rentalproperty@1x.png";
import rentalproperty2 from "./rentalproperty@2x.png";
import rentalproperty3 from "./rentalproperty@3x.png";

import investmentproperty1 from "./investmentproperty@1x.png";
import investmentproperty2 from "./investmentproperty@2x.png";
import investmentproperty3 from "./investmentproperty@3x.png";

import vacationhome1 from "./vacationhome@1x.png";
import vacationhome2 from "./vacationhome@2x.png";
import vacationhome3 from "./vacationhome@3x.png";

import contracts1 from "./contracts@1x.png";
import contracts2 from "./contracts@2x.png";
import contracts3 from "./contracts@3x.png";

import financial1 from "./financial@1x.png";
import financial2 from "./financial@2x.png";
import financial3 from "./financial@3x.png";

import vehicle1 from "./vehicle@1x.png";
import vehicle2 from "./vehicle@2x.png";
import vehicle3 from "./vehicle@3x.png";

import digital1 from "./digital@1x.png";
import digital2 from "./digital@2x.png";
import digital3 from "./digital@3x.png";

import other1 from "./other@1x.png";
import other2 from "./other@2x.png";
import other3 from "./other@3x.png";

type AssetIcon = {
  [key: string]: JSX.Element;
};

export const assetIcons: AssetIcon = {
  Household: (
    <img
      srcSet={`${household2} 2x, ${household3} 3x`}
      src={`${household1}`}
      alt="Household"
    />
  ),
  // "Antiques": <></>,
  // "Art/Paintings": <></>,
  // "Clothing": <></>,
  // "Collections": <></>,
  // "Electronics": <></>,
  // "Furniture": <></>,
  // "Furnishings & Decorations": <></>,
  // "Garage stuff": <></>,
  // "Holiday decorations": <></>,
  // "Jewelry": <></>,
  // "Kitchenware/Dinnerware": <></>,
  // "Musical Instruments": <></>,
  // "Workbench &/or Tools": <></>,
  // --------------------------
  Financial: (
    <img
      srcSet={`${financial2} 2x, ${financial3} 3x`}
      src={`${financial1}`}
      alt="Financial"
    />
  ),
  // "Annuities": <></>,
  // "Cash": <></>,
  // "CD's or other Certificates": <></>,
  // "Investments": <></>,
  // "Insurance Policies": <></>,
  // "Retirement Accounts": <></>,
  // "Social Security Statements": <></>,
  // "Other": <></>,
  // "Checking": <></>,
  // "Savings": <></>,
  // "Loan": <></>,
  // "Credit Card": <></>,
  // "Line of Credit": <></>,
  // "Mortgage": <></>,
  // "Property": <></>,
  // "Prepaid": <></>,
  // --------------------------
  "Real Estate": (
    <img
      srcSet={`${realestate2} 2x, ${realestate3} 3x`}
      src={`${realestate1}`}
      alt="Real Estate"
    />
  ),
  "Investment property (developed/undeveloped)": (
    <img
      srcSet={`${investmentproperty2} 2x, ${investmentproperty3} 3x`}
      src={`${investmentproperty1}`}
      alt="Investment"
    />
  ),
  "Primary Home": (
    <img
      srcSet={`${primaryhome2} 2x, ${primaryhome3} 3x`}
      src={`${primaryhome1}`}
      alt="Primary"
    />
  ),
  "Rental Properties": (
    <img
      srcSet={`${rentalproperty2} 2x, ${rentalproperty3} 3x`}
      src={`${rentalproperty1}`}
      alt="Rental"
    />
  ),
  // "Time Share": <></>,
  "Timber,Solar, Mineral Interest contracts": (
    <img
      srcSet={`${contracts2} 2x, ${contracts3} 3x`}
      src={`${contracts1}`}
      alt="Contracts"
    />
  ),
  "Vacation home": (
    <img
      srcSet={`${vacationhome2} 2x, ${vacationhome3} 3x`}
      src={`${vacationhome1}`}
      alt="Vacation home"
    />
  ),
  // --------------------------
  Vehicles: (
    <img
      srcSet={`${vehicle2} 2x, ${vehicle3} 3x`}
      src={`${vehicle1}`}
      alt="Vehicle"
    />
  ),
  // Boats: <></>,
  // Cars: <></>,
  // "Lawn Mowers, Tractors, etc.": <></>,
  // Trailers: <></>,
  // --------------------------
  Digital: (
    <img
      srcSet={`${digital2} 2x, ${digital3} 3x`}
      src={`${digital1}`}
      alt="Digital"
    />
  ),
  // --------------------------
  Other: (
    <img srcSet={`${other2} 2x, ${other3} 3x`} src={`${other1}`} alt="Other" />
  ),
  Miscellaneous: (
    <img
      srcSet={`${other2} 2x, ${other3} 3x`}
      src={`${other1}`}
      alt="Miscellaneous"
    />
  ),
};
