import React from "react";
import { Box, Container, Theme, Typography } from "@mui/material";
import { makeStyles } from "tss-react/mui";
import Page from "../../components/Page";
import { ATTICUS_PAGE_PREFIX } from "../../constants";

const useStyles = makeStyles()((theme: Theme) => ({
  root: {
    backgroundColor: theme.palette.background.default,
    height: "100%",
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
  image: {
    marginTop: 50,
    display: "inline-block",
    maxWidth: "100%",
    width: 560,
  },
}));

export const NotFoundView = (): JSX.Element => {
  const { classes } = useStyles();

  return (
    <Page
      className={classes.root}
      title={`${ATTICUS_PAGE_PREFIX} - 404`}
      data-automation-id="page-content-404"
    >
      <Box
        display="flex"
        flexDirection="column"
        height="100%"
        justifyContent="center"
      >
        <Container maxWidth="md">
          <Typography align="center" color="textPrimary" variant="h1">
            404: The page you are looking for isn’t here
          </Typography>
          <Typography align="center" color="textPrimary" variant="subtitle2">
            You either tried an unknown route or you came here by mistake.
            Please try again.
          </Typography>
          <Box textAlign="center">
            {/*<img*/}
            {/*  alt="Under development"*/}
            {/*  className={classes.image}*/}
            {/*  src="/static/images/undraw_page_not_found_su7k.svg"*/}
            {/*/>*/}
          </Box>
        </Container>
      </Box>
    </Page>
  );
};
