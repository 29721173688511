import React from "react";
import { Box, Container, Theme, Typography } from "@mui/material";
import { makeStyles } from "tss-react/mui";
import Page from "../../components/Page";
import {
  ATTICUS_PAGE_PREFIX,
  SCREEN_PATHS,
  STORAGE_KEYS,
} from "../../constants";
import { getQueryStringParams } from "../../utils/getQueryStringParams";

const useStyles = makeStyles()((theme: Theme) => ({
  root: {
    backgroundColor: theme.palette.background.default,
    height: "100%",
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
  image: {
    marginTop: 50,
    display: "inline-block",
    maxWidth: "100%",
    width: 560,
  },
}));

export const MaintenanceView = (): JSX.Element => {
  const { classes } = useStyles();

  // accept code param to send to config
  const params = getQueryStringParams();
  const code = params?.code || undefined;
  if (code) {
    if (code === "delete") {
      window.sessionStorage.removeItem(STORAGE_KEYS.BYPASS);
    } else {
      window.sessionStorage.setItem(STORAGE_KEYS.BYPASS, code);
    }
  }

  const getSupportLink = () => {
    return "mailTo:" + process.env.REACT_APP_SUPPORT_EMAIL || "";
  };

  return (
    <Page
      className={classes.root}
      title={`${ATTICUS_PAGE_PREFIX} - Maintenance Mode`}
      data-automation-id="page-content-maintenance"
    >
      <Box
        display="flex"
        flexDirection="column"
        height="100%"
        justifyContent="center"
      >
        <Container maxWidth="md">
          <Typography align="center" color="textPrimary" variant="h1">
            Atticus Advisor is currently in maintenance mode.
          </Typography>
          <br />
          <Typography align="center" color="textPrimary" variant="subtitle2">
            Maintenance is usually completed in an hour or less. <br />
            Please come back later and{" "}
            <a href={SCREEN_PATHS.login}>try signing in</a>.
          </Typography>
          <br />
          <Typography align="center" color="textPrimary" variant="subtitle2">
            If you are seeing this at an unexpected time please{" "}
            <a href={getSupportLink()} target="_blank">
              contact support
            </a>
            .
          </Typography>
        </Container>
      </Box>
    </Page>
  );
};
