// database field names
import { TaskAnswer, TaskAnswerJSON } from "./TaskAnswer";
import { TaskTypes, TaskTypesJSON } from "./TaskTypes";

export type TaskJSON = {
  id: number;
  sequence: number;
  title?: string;
  summary?: string;
  details?: string;
  task_estate?: TaskAnswerJSON;
  entry_type?: string; // change to enum?
  countries_id?: number;
  languages_id?: number;
  types?: TaskTypesJSON;
};

export class Task {
  constructor(
    public id: number,
    public sequence: number,
    public title?: string,
    public summary?: string,
    public details?: string,
    public answer?: TaskAnswer,
    public entryType?: string, // change to enum?
    public countriesId?: number,
    public languagesId?: number, // public types?: TaskTypes[] // seems singular // filter // task_phase_id // task_type_id // task_user_filter_id
    public types?: TaskTypes
  ) {}

  static fromJSON(json: TaskJSON): Task {
    return new Task(
      json.id,
      json.sequence,
      json.title,
      json.summary,
      json.details,
      json.task_estate ? TaskAnswer.fromJSON(json.task_estate) : undefined,
      json.entry_type,
      json.countries_id,
      json.languages_id,
      json.types ? TaskTypes.fromJSON(json.types) : undefined
    );
  }

  // clone
  static from(task: Task): Task {
    return new Task(
      task.id,
      task.sequence,
      task.title,
      task.summary,
      task.details,
      task.answer ? TaskAnswer.from(task.answer) : undefined,
      task.entryType,
      task.countriesId,
      task.languagesId,
      task.types ? TaskTypes.from(task.types) : undefined
    );
  }
}
