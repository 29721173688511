import React from "react";
import { AtticusForm } from "../../../models/AtticusForm";
import {
  Box,
  Card,
  Badge,
  CardActionArea,
  SxProps,
  Theme,
} from "@mui/material";
import genericFormSvg from "../../../assets/generic-form.svg";

interface Props {
  form?: AtticusForm;
  onClick?: () => void;
  maxHeight?: string;
  sx?: SxProps<Theme>;
  checkedBadge?: boolean;
}

export const FormThumbnail = ({
  form,
  onClick,
  maxHeight,
  sx,
  checkedBadge = false,
}: Props): JSX.Element => {
  return (
    <Box
      sx={[
        {
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        },
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
    >
      <Badge
        component={checkedBadge ? Badge : Box}
        badgeContent={checkedBadge ? "✔" : undefined}
        color={checkedBadge ? "secondary" : undefined}
        // overlap={checkedBadge ? "circular" : undefined}
        anchorOrigin={
          checkedBadge
            ? {
                vertical: "bottom",
                horizontal: "right",
              }
            : undefined
        }
        sx={{ width: "fit-content" }}
      >
        <Box
          component={Card}
          sx={{
            width: "inherit",
            objectFit: "contain",
            borderRadius: "0px",
            overflow: "hidden",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Box
            component={onClick ? CardActionArea : Box}
            onClick={onClick}
            sx={{
              display: "flex",
            }}
          >
            <Box
              component="img"
              sx={{
                height: "100%",
                width: "100%",
                maxHeight: maxHeight ? maxHeight : "8rem",
              }}
              src={
                form?.s3Urls?.thumbnailFile
                  ? form?.s3Urls.thumbnailFile
                  : genericFormSvg
              }
              onError={({ currentTarget }) => {
                currentTarget.onerror = null; // prevents looping
                // currentTarget.src = genericFormSvg;
              }}
            />
          </Box>
        </Box>
      </Badge>
    </Box>
  );
};
