import Cookies from "js-cookie";
import {
  REST_API,
  SCREEN_PATHS,
  AdvisorGroupAccess,
  AUTH_ERRORS,
  COOKIES,
  CONSTANTS,
  STORAGE_KEYS,
  amplifyStorageRegion,
} from "../constants";
import { User, UserJSON } from "../models/User";
import { AxiosInstance } from "axios";
import { advisorApi, callGet, logger } from "./index";
import { API, Auth, Storage } from "aws-amplify";
import { Advisor } from "../models/Advisor";
import { CognitoUser } from "amazon-cognito-identity-js";
import { createId } from "../utils/createId";

export type StoragePutResult = {
  key: string;
};

export class CognitoAuth {
  private sessionId?: string = "";
  constructor(private axios: AxiosInstance) {
    // if not in session storage will be set upon sign in
    this.setAuthToken(Cookies.get(COOKIES.authToken));
    // will be set upon app startup regardless of auth state
    this.setSessionId(
      window.sessionStorage.getItem(CONSTANTS.STORAGE_KEYS.LOG_SESSION_ID) || createId()
    );
  }

  protected setAuthToken(authToken?: string): void {
    if (authToken) {
      Cookies.set(COOKIES.authToken, authToken);
    } else {
      Cookies.remove(COOKIES.authToken);
    }
    this.axios.defaults.headers.common["Authorization"] = authToken
      ? `Bearer ${authToken}`
      : "";
  }

  protected setSessionId(sessionId?: string): void {
    this.sessionId = sessionId;
    window.sessionStorage.setItem(
      CONSTANTS.STORAGE_KEYS.LOG_SESSION_ID,
      sessionId || ""
    );
    this.axios.defaults.headers.common["X-UI-Session-Id"] = sessionId || "";
  }

  public getSessionId(): string | undefined {
    return this.sessionId;
  }

  protected clearSession(): void {
    this.setAuthToken();
    this.setSessionId();
    window.sessionStorage.clear();
  }

  async getAndSetAuthToken(): Promise<void> {
    Auth.currentSession()
      .then((session) => {
        this.setAuthToken(session.getAccessToken().getJwtToken());
        if (!this.sessionId) {
          this.setSessionId(createId());
        }
      })
      .catch((error) => logger.error(error as Error));
  }

  async signOut(all = false): Promise<boolean> {
    try {
      if (all) {
        await Auth.signOut({ global: true });
      } else {
        await Auth.signOut();
      }
      this.clearSession();
    } catch (error) {
      console.error(`${AUTH_ERRORS.signOut}: ${(error as Error)?.message}`);
      return false;
    }
    return true;
  }

  // Delete once fully committed to hosted auth UI
  async verify(username: string, code: string): Promise<any> {
    return await Auth.confirmSignUp(username, code);
  }

  // Delete once fully committed to hosted auth UI
  async resendVerify(username: string): Promise<any> {
    return await Auth.resendSignUp(username);
  }

  // Delete once fully committed to hosted auth UI
  async forgotPassword(username: string): Promise<any> {
    return Auth.forgotPassword(username);
  }

  // Delete once fully committed to hosted auth UI
  async updatePasswordWithCode(
    username: string,
    code: string,
    newPassword: string
  ): Promise<any> {
    return Auth.forgotPasswordSubmit(username, code, newPassword);
  }

  // Delete once fully committed to hosted auth UI
  async updatePassword(oldPassword: string, newPassword: string): Promise<any> {
    return Auth.currentAuthenticatedUser().then((user) => {
      return Auth.changePassword(user, oldPassword, newPassword);
    });
  }

  // Delete once fully committed to hosted auth UI
  async setNewPassword(
    cognitoUser: CognitoUser,
    newPassword: string
  ): Promise<any> {
    return Auth.completeNewPassword(cognitoUser, newPassword);
  }

  // TODO: implement use of resend confirmation code
  // updatePasswordWithCode(code: string, newPassword: string): Promise<void> {
  //   return Auth.currentAuthenticatedUser()
  //     .then((user) => {
  //       // Collect confirmation code and new password, then
  //       return Auth.forgotPasswordSubmit(user.getUsername(), code, newPassword)
  //         .then((data) => console.log(data))
  //         .catch((err) => console.log(err));
  //     })
  //     .then((data) => console.log(data))
  //     .catch((err) => console.log(err));
  // }

  /**
   * most user attributes come from cognito
   *  except roleId, advisorGroupId, advisorGroupName
   */
  async getUser(): Promise<User | null> {
    try {
      let user: User | null = null;
      const cognitoResponse = await Auth.currentAuthenticatedUser();
      const user_cognito = User.fromCognito(cognitoResponse.attributes);
      const groups = cognitoResponse.signInUserSession.idToken.payload["cognito:groups"];
      user_cognito.cognitoGroups = groups;
      user_cognito.advisorGroupName = this.getAdvisorGroup(groups);
      if (cognitoResponse.attributes.picture) {
        user_cognito.pictureUrl = await this.getProfilePhoto(
          cognitoResponse.attributes.picture
        );
      }
      console.log('calling get /me')
      const response = await callGet(Advisor.path + "/me", true);
      if (response !== null) {
        if (response?.data) {
          this.checkAdvisorGroupAccess(response.data);
          const user_laravel = User.fromJSON(response?.data);
          user = User.mergeUsers(user_cognito, user_laravel);
          if (!user_laravel?.profile?.firstName) {
            // need to save cognito profile data to laravel - should be one-time
            await advisorApi.updateAdvisor({
              first_name: user_cognito.first,
              last_name: user_cognito.last,
              phone: user_cognito.phone?.replace("+", ""),
            } as UserJSON);
          }
        } else {
          logger.error(AUTH_ERRORS.laravelNoData);
          window.document.location.href = `${SCREEN_PATHS.signOut}?error=group`;
        }
      } else {
        throw new Error(AUTH_ERRORS.getUserFailed);
      }
      return user;
    } catch (error) {
      if (error === AUTH_ERRORS.cognitoNotAuthed) {
        window.localStorage.setItem(
          STORAGE_KEYS.AUTH_REDIRECT_FROM,
          window.location.pathname
        );
        window.document.location.href = SCREEN_PATHS.login;
      } else {
        logger.error(error as Error);
        window.document.location.href = SCREEN_PATHS.signOut;
      }
    }
    return null;
  }

  checkAdvisorGroupAccess(laravelUserData: UserJSON): void {
    const advisorGroupAccess = laravelUserData?.advisor_group?.access.access;
    if (advisorGroupAccess === AdvisorGroupAccess.Suspended) {
      logger.error(
        `Advisor group id: ${laravelUserData?.advisor_group_id} attempted access while ${AdvisorGroupAccess.Suspended}`
      );
      window.document.location.href = `/login?error=suspended`;
    }
  }

  getAdvisorGroup(groups: string[] | undefined): string | undefined {
    const filteredGroups = groups?.filter((group: string) =>
      group.includes(CONSTANTS.ADVISOR_GROUP_PREFIX)
    )
    return filteredGroups && filteredGroups[0] ? filteredGroups[0] : undefined;
  }

  async getProfilePhoto(key: string): Promise<string> {
    const photoUrl = (await Storage.get(key, {
      region: amplifyStorageRegion,
      level: "protected",
    })) as string;
    return photoUrl;
  }

  async updateAttributes(updates: Record<string, string>): Promise<boolean> {
    const user = await Auth.currentAuthenticatedUser();
    const result = await Auth.updateUserAttributes(user, updates);
    logger.debug(result);
    return true;
  }

  async fetchAdvisors(): Promise<Advisor[]> {
    try {
      const response = await API.get(REST_API, Advisor.path, {});
      if (response?.data) {
        return response.data.map((json: any) => {
          const user = User.fromCognito2(json);
          return Advisor.fromUser(user);
        });
      } else {
        throw Error("no data property");
      }
    } catch (error) {
      logger.error(error as Error);
    }
    return [new Advisor(new User())];
  }

  async updateAdvisorGroup(
    cognitoUsername?: string,
    groupName?: string,
    rethrow?: false
  ): Promise<boolean> {
    try {
      if (cognitoUsername) {
        const response = await API.put(
          REST_API,
          Advisor.path + "/" + cognitoUsername,
          {
            body: {
              group: groupName || "AG-", // indicates removal
            },
          }
        );
        if (response?.data) {
          return response.data === "success";
        } else {
          throw Error("no data property");
        }
      } else {
        throw Error("cognitoUsername && groupName required");
      }
    } catch (error) {
      logger.error(error as Error);
      if (rethrow) throw error;
    }
    return false;
  }
}
