import { SendEmail } from "../../../apis/SendEmail";
import { TestButton } from "./TestButton";
import React, { useState } from "react";
import { TextField } from "@mui/material";

export const InviteEmail = (): JSX.Element => {
  // ***
  const [email, setEmail] = useState(
    "stellarjaysoftware+test-client-invite@gmail.com"
  );
  const [inviteId, setInviteId] = useState("t8ivmi6wzglkr0u77fn");
  return (
    <>
      <TextField
        id="email"
        label="Email"
        variant="outlined"
        fullWidth
        value={email}
        onChange={(
          event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
        ) => {
          setEmail(event.target.value);
        }}
        required
      />
      <br />
      <br />
      <TextField
        id="inviteId"
        label="Invite id"
        variant="outlined"
        fullWidth
        value={inviteId}
        onChange={(
          event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
        ) => {
          setInviteId(event.target.value);
        }}
        required
      />
      <TestButton
        onClick={async () => {
          if (email && inviteId) {
            const sendEmail = new SendEmail();
            sendEmail.sendInvite({ email, inviteId });
          }
        }}
      >
        send email
      </TestButton>
    </>
  );
};
