import React, { useEffect } from "react";
import { Box, Card, CardContent, CardHeader } from "@mui/material";
import { StoreState } from "../../../reducers";
import { AdvisorGroup } from "../../../models/AdvisorGroup";
import { connect } from "react-redux";
import { fetchAdvisorGroups } from "../../../actions/advisorGroupActions";
import { DemoEstatesForm } from "./DemoEstatesForm";

interface Props {
  advisorGroups: AdvisorGroup[];
  fetchAdvisorGroups: () => void;
}

export const _DemoEstatesView = ({
  advisorGroups,
  fetchAdvisorGroups,
}: Props): JSX.Element => {
  useEffect(() => {
    if (!advisorGroups) {
      fetchAdvisorGroups();
    }
  }, []);

  return (
    <div data-automation-id="page-content-demo-estates">
      <Card>
        <CardHeader
          title="Demo Estates"
          subheader="🦉 Baby owl's sage advice"
          style={{ paddingBottom: 0 }}
        />
        <CardContent>
          <DemoEstatesForm advisorGroups={advisorGroups} />
        </CardContent>
      </Card>
      <Box my={2}>
        <Card>
          <CardHeader title="Reference" />
          <CardContent>
            <a
              href="https://docs.google.com/document/d/1vqzRY0JU-SjoeOy0CQ6OlPOoOKHNjr4efAZqfuDUXZA/edit?usp=sharing"
              target="_blank"
            >
              database record guide (google doc)
            </a>
          </CardContent>
        </Card>
      </Box>
    </div>
  );
};

const mapStateToProps = ({
  advisorGroups,
}: StoreState): { advisorGroups: AdvisorGroup[] } => {
  return { advisorGroups };
};

export const DemoEstatesView = connect(mapStateToProps, {
  fetchAdvisorGroups,
})(_DemoEstatesView);
