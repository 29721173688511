import { ActionTypes } from "../actions/types";
import { Estate } from "../models/Estate";
import { AnyAction } from "redux";

export type EstateSearch = {
  cacheKey: string;
  estates: Estate[] | null;
};

export const estateSearchCacheReducer = (
  searchedEstates: EstateSearch[] | null = null,
  action: AnyAction
): EstateSearch[] | null => {
  switch (action.type) {
    case ActionTypes.ClearSearchedEstates:
      return action.payload;
    case ActionTypes.SearchEstates: {
      // const searchIndex = -1; // effectively disable the local search cache
      // const searchIndex = searchedEstates?.findIndex(
      //   (estateSearch: EstateSearch) =>
      //     estateSearch.cacheKey === action.payload.cacheKey
      // );

      // have a result in the cache already?
      const searchIndex = searchedEstates?.findIndex(
        (estateSearch: EstateSearch) =>
          estateSearch.cacheKey === action.payload.cacheKey
      );
      if (!searchIndex || searchIndex === -1) {
        // cache a new result
        return [action.payload];
      } else {
        // replace the existing result
        const newSearchedEstates = searchedEstates ? [...searchedEstates] : [];
        newSearchedEstates[searchIndex] = {
          cacheKey: action.payload.cacheKey,
          estates: action.payload.estates,
        };
        return newSearchedEstates;
      }
    }
    case ActionTypes.AppSignOut:
      return null;
    default:
      return searchedEstates;
  }
};
