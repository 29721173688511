import React, { useState, useEffect } from "react";
import { Box, Grow, SxProps, Theme, Typography } from "@mui/material";

import { FormsListItemCard } from "./FormsListItemCard";
import { AtticusForm } from "../../../../models/AtticusForm";
import AtticusFormViewDialog, {
  PdfFileChoice,
} from "../../dialog/AtticusFormViewDialog";
import { DetailsDialog } from "../../dialog/DetailsDialog";
import {
  addEstateForm,
  removeEstateForm,
} from "../../../../actions/estateFormActions";
import { StoreState } from "../../../../reducers";
import { connect } from "react-redux";
import { State } from "../../../../models/Geography";
import { ProbateType } from "../../../../models/ProbateType";
import { SplitButtonOption } from "../../SplitButtonGroup";
import download from "downloadjs";
import axios from "axios";
import { Estate } from "../../../../models/Estate";
const FORM_DETAILS_ICON = "/static/icons/form-details-icon.svg";

interface Props {
  forms?: AtticusForm[];
  estateForms?: AtticusForm[];
  estate?: Estate;
  sx?: SxProps<Theme>;
  truncateFormsAt?: number;
  addEstateForm: (estateId: number, form: AtticusForm) => void;
  removeEstateForm: (estateId: number, form: AtticusForm) => void;
  emptyState?: JSX.Element;
  allowCheckedBadge?: boolean;
}

const _FormsList = ({
  forms,
  estateForms,
  estate,
  sx,
  truncateFormsAt,
  addEstateForm,
  removeEstateForm,
  emptyState,
  allowCheckedBadge = false,
}: Props): JSX.Element => {
  // Form PDF Dialog State
  const [pdfDialogOpen, setPdfDialogOpen] = useState(false);
  const [pdfDialogForm, setPdfDialogForm] = useState<AtticusForm>();

  const [formsToDisplay, setFormsToDisplay] = useState<
    AtticusForm[] | undefined
  >(truncateFormsAt ? forms?.slice(0, truncateFormsAt) : forms);

  const togglePdfDialog = () => {
    setPdfDialogOpen(!pdfDialogOpen);
    if (pdfDialogOpen === true) {
      setPdfDialogForm(undefined);
    }
  };
  // End Form PDF Dialog State

  // Form Description Dialog State
  const [descriptionDialogOpen, setDescriptionDialogOpen] = useState(false);
  const [descriptionDialogForm, setDescriptionDialogForm] =
    useState<AtticusForm>();

  const toggleDescriptionDialog = () => {
    setDescriptionDialogOpen(!descriptionDialogOpen);
    if (descriptionDialogOpen === true) {
      setDescriptionDialogForm(undefined);
    }
  };
  // End Form Description Dialog State

  useEffect(() => {
    setFormsToDisplay(
      truncateFormsAt ? forms?.slice(0, truncateFormsAt) : forms
    );
  }, [forms]);

  useEffect(() => {
    if (forms && estateForms) {
      const updates = Object.fromEntries(
        estateForms.map((form) => [form.id, form])
      );
      const displayForms = forms.map((f) => updates[f.id] || f);
      setFormsToDisplay(displayForms);
    }
  }, [forms, estateForms]);

  const getFormOptions = (form: AtticusForm): SplitButtonOption[] => {
    const formOptions: SplitButtonOption[] = [
      {
        label: "View",
        key: "view",
        onClick: async () => {
          if (form) {
            setPdfDialogForm(form);
            togglePdfDialog();
          }
        },
      },
      {
        label: "Download",
        key: "download",
        onClick: async () => {
          if (form && form.s3Urls?.asyncProcessedPdf) {
            const fileUrl = await form.s3Urls?.asyncProcessedPdf();
            if (fileUrl) {
              const response = await axios.get(fileUrl, {
                responseType: "arraybuffer",
              });
              const fileUrlObj = new URL(fileUrl);
              const fileName = fileUrlObj.pathname.split("/").pop();
              download(new Uint8Array(response.data), fileName);
            }
          }
        },
      },
    ];

    if (
      estate &&
      estate.hasWriteAccess &&
      estateForms &&
      estateForms.find((estateForm) => estateForm.id == form.id)
    ) {
      formOptions.push({
        label: "Remove from My Forms",
        key: "remove",
        hidden: !estateForms || !estateForms?.find((ef) => ef.id == form.id),
        onClick: async () => {
          if (form && estate.id) {
            removeEstateForm(estate.id, form);
          }
        },
      });
    } else if (estate && estate.hasWriteAccess && estateForms) {
      formOptions.push({
        label: "Add to My Forms",
        key: "add",
        hidden: !estateForms || !!estateForms?.find((ef) => ef.id == form.id),
        onClick: async () => {
          if (form && estate.id) {
            addEstateForm(estate.id, form);
          }
        },
      });
    }

    return formOptions;
  };

  const renderForms = (forms?: AtticusForm[]): JSX.Element[] => {
    const rows:JSX.Element[] = [];
    if (!forms) {
      for (let formI = 0; formI < 3; formI++) {
        rows.push(
          <Grow in key={formI} timeout={formI * 100}>
            <Box>
              <FormsListItemCard
                pdfDialogOpen={pdfDialogOpen}
                setPdfDialogForm={setPdfDialogForm}
                togglePdfDialog={togglePdfDialog}
                descriptionDialogOpen={descriptionDialogOpen}
                setDescriptionDialogForm={setDescriptionDialogForm}
                toggleDescriptionDialog={toggleDescriptionDialog}
                sx={{
                  padding: "1rem",
                  marginBottom: "1rem",
                }}
              />
            </Box>
          </Grow>
        );
      }
    } else {
      forms.forEach((form, index) => {
        rows.push(
          <Grow in key={form.id} timeout={index * 100}>
            <Box>
              <FormsListItemCard
                form={form}
                pdfDialogOpen={pdfDialogOpen}
                setPdfDialogForm={setPdfDialogForm}
                togglePdfDialog={togglePdfDialog}
                descriptionDialogOpen={descriptionDialogOpen}
                setDescriptionDialogForm={setDescriptionDialogForm}
                toggleDescriptionDialog={toggleDescriptionDialog}
                sx={{
                  padding: "1rem",
                  marginBottom: "1rem",
                }}
                formOptions={getFormOptions(form)}
                checkedBadge={
                  allowCheckedBadge && form.estateFormMap ? true : false
                }
              />
            </Box>
          </Grow>
        );
      });
    }
    return rows;
  };

  return (
    <Box sx={sx}>
      <AtticusFormViewDialog
        open={pdfDialogOpen}
        onClose={togglePdfDialog}
        form={pdfDialogForm}
        fileChoice={PdfFileChoice.PROCESSED}
        withDisclaimer
      />
      <DetailsDialog
        open={descriptionDialogOpen}
        headerImageSrc={FORM_DETAILS_ICON}
        headerImageAlt="A magnifying glass observing a form's text"
        title={descriptionDialogForm?.getTitle() || "Form Details"}
        subtitle={`${
          descriptionDialogForm?.geography?.fullName
            ? descriptionDialogForm?.geography?.fullName
            : ""
        }${
          descriptionDialogForm?.geography?.fullName &&
          descriptionDialogForm?.getGovernmentId()
            ? " | "
            : ""
        }${
          descriptionDialogForm?.getGovernmentId()
            ? descriptionDialogForm?.getGovernmentId()
            : ""
        }`}
        content={
          descriptionDialogForm?.getDescription() || "No Description Available."
        }
        onConfirm={toggleDescriptionDialog}
        confirmText="Ok"
        maxWidth="md"
      />

      <Box>
        {!formsToDisplay || formsToDisplay.length > 0 ? (
          renderForms(formsToDisplay)
        ) : (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              gap: 4,
              padding: 4,
            }}
          >
            {formsToDisplay ? (
              <>{emptyState}</>
            ) : (
              <Typography>Loading forms...</Typography>
            )}
          </Box>
        )}
      </Box>
    </Box>
  );
};

const mapStateToProps = ({
  states,
  probateTypes,
  estateForms,
}: StoreState): {
  states: State[];
  probateTypes: ProbateType[];
  estateForms: AtticusForm[];
} => {
  return {
    states,
    probateTypes,
    estateForms,
  };
};

export const FormsList = connect(mapStateToProps, {
  addEstateForm,
  removeEstateForm,
})(_FormsList);
