import { Dispatch } from "redux";
import { ActionTypes } from "./types";
import { OpportunityType } from "../models/OpportunityType";
import { OpportunityStatus } from "../models/OpportunityStatus";
import { opportunitiesApi } from "../apis";
import { Opportunity } from "../models/Opportunity";
import { LoadingOpportunities } from "../reducers/opportunities";

export interface FetchOpportunitiesAction {
  type: ActionTypes.FetchOpportunities;
  payload: Opportunity[] | null;
}
export interface FetchOpportunitiesCountAction {
  type: ActionTypes.FetchOpportunitiesCount;
  payload: number | null,
}
export interface LoadingOpportunitiesAction {
  type: ActionTypes.LoadingOpportunities;
  payload: LoadingOpportunities | null,
}

export const fetchOpportunities = (
  types: OpportunityType[],
  statuses: OpportunityStatus[],
  estateIds: number[],
  limit = 50,
  offset = 0,
  sort = "created_at",
  dir: "DESC"|"ASC" = "DESC",
  numPreviousOpportunities = 0,
) => {
  return async (dispatch: Dispatch): Promise<void> => {
    let opportunities: Opportunity[] | null = null;
    let count: number | null = null;
    try {
      // Basically undefine opps while we load things
      dispatch<LoadingOpportunitiesAction>({
        type: ActionTypes.LoadingOpportunities,
        payload: {
          loading: true,
          lastNumOpportunities: numPreviousOpportunities,
        },
      });
      dispatch<FetchOpportunitiesAction>({
        type: ActionTypes.FetchOpportunities,
        payload: null,
      });

      const res = await opportunitiesApi.fetchOpportunitiesWithCount(
        types,
        statuses,
        estateIds,
        limit,
        offset,
        sort,
        dir,
      )
      opportunities = res?.opportunities || []
      count = res?.count || 0

      dispatch<FetchOpportunitiesAction>({
        type: ActionTypes.FetchOpportunities,
        payload: opportunities,
      });
      dispatch<FetchOpportunitiesCountAction>({
        type: ActionTypes.FetchOpportunitiesCount,
        payload: count,
      });
      dispatch<LoadingOpportunitiesAction>({
        type: ActionTypes.LoadingOpportunities,
        payload: {
          loading: false,
          lastNumOpportunities: opportunities?.length || 0,
        },
      });
    } catch (error) {
      console.error(error);
    }

  };
};

export interface SaveOpportunityAction {
  type: ActionTypes.SaveOpportunity;
  payload: {
    opp: Opportunity;
    isNew: boolean;
  };
}

export const saveOpportunity = (opp: Opportunity, isNew = false) => {
  return async (dispatch: Dispatch): Promise<void> => {
    if (opp) {
      dispatch<SaveOpportunityAction>({
        type: ActionTypes.SaveOpportunity,
        payload: { opp, isNew },
      });
    }
  };
};

export interface DeleteOpportunityAction {
  type: ActionTypes.DeleteOpportunity;
  payload: {
    opp: Opportunity;
  };
}

export const deleteOpportunity = (opp: Opportunity) => {
  return async (dispatch: Dispatch): Promise<void> => {
    if (opp) {
      // TODO: make network call through the api
      dispatch<DeleteOpportunityAction>({
        type: ActionTypes.DeleteOpportunity,
        payload: { opp },
      });
    }
  };
};

export interface FetchOpportunityTypesAction {
  type: ActionTypes.FetchOpportunityTypes;
  payload: OpportunityType[] | null;
}

export const fetchOpportunityTypes = () => {
  return async (dispatch: Dispatch): Promise<void> => {
    let opportunityTypes: OpportunityType[] | null = [];
    try {
      opportunityTypes = await opportunitiesApi.fetchOpportunityTypes();
    } catch (error) {
      console.error(error);
    }
    dispatch<FetchOpportunityTypesAction>({
      type: ActionTypes.FetchOpportunityTypes,
      payload: opportunityTypes,
    });
  };
};

export interface FetchOpportunityStatusesAction {
  type: ActionTypes.FetchOpportunityStatuses;
  payload: OpportunityStatus[] | null;
}

export const fetchOpportunityStatuses = () => {
  return async (dispatch: Dispatch): Promise<void> => {
    let opportunityStatuses: OpportunityStatus[] | null = [];
    try {
      opportunityStatuses = await opportunitiesApi.fetchOpportunityStatuses();
    } catch (error) {
      console.error(error);
    }
    dispatch<FetchOpportunityStatusesAction>({
      type: ActionTypes.FetchOpportunityStatuses,
      payload: opportunityStatuses,
    });
  };
};

export interface FetchSingleOpportunityAction {
  type: ActionTypes.FetchSingleOpportunity;
  payload: Opportunity | null;
}

export const fetchSingleOpportunity = (
  opportunityId: number,
  types: OpportunityType[],
  statuses: OpportunityStatus[]
) => {
  return async (dispatch: Dispatch): Promise<void> => {
    let opportunity: Opportunity | null = null;
    try {
      opportunity = await opportunitiesApi.fetchSingleOpportunity(
        opportunityId,
        types,
        statuses
      );
    } catch (error) {
      console.error(error);
    }
    dispatch<FetchSingleOpportunityAction>({
      type: ActionTypes.FetchSingleOpportunity,
      payload: opportunity,
    });
  };
};
