import { Amplify } from 'aws-amplify';
import { isProd } from '../constants';

// This is built from the code example at https://docs.amplify.aws/lib/auth/getting-started/q/platform/js/#set-up-and-configure-amplify-auth
// Values were taken from the original setup in github: https://github.com/TheAtticusApp/atticus-advisor/blob/master/src/aws-exports.js
Amplify.configure({
  Auth: !isProd ? {
    identityPoolId: 'us-east-2:bff57a87-6cef-4b15-889d-5565a7d0d9d7',
    region: 'us-east-2',
    userPoolId: 'us-east-2_OEtLLQNxl',
    userPoolWebClientId: '590gelbl1ad1rtc1cerlla61ks',
    signUpVerificationMethod: 'link',   // 'code' is used for Auth.confirmSignUp, 'link' is used for email link verification
  } : {
    identityPoolId: 'us-east-2:89339093-b9a2-41a2-b1d5-496be3bd693b',
    region: 'us-east-2',
    userPoolId: 'us-east-2_FK5kJVeJs',
    userPoolWebClientId: '26v6g526v394bilpecu9ircrla',
    signUpVerificationMethod: 'link',   // 'code' is used for Auth.confirmSignUp, 'link' is used for email link verification
  }
});

