import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Typography,
} from "@mui/material";
import { makeStyles } from "tss-react/mui";
import React from "react";
import { Task } from "../../../../models/Task";
import CloseIcon from "@mui/icons-material/Close";

const useStyles = makeStyles()(() => ({
  root: {},
  actionWrapper: {
    justifyContent: "center",
  },
  title: {
    "&>h2": {
      textAlign: "center",
    },
    marginTop: 32,
    marginBottom: 8,
  },
  closeButton: {
    position: "absolute",
    top: 8,
    right: 8,
  },
}));

interface Props {
  task: Task;
  open: boolean;
  onClose: () => void;
  elementRef: any;
}

export const TaskDetailsDialog = ({
  task,
  open,
  onClose,
  elementRef,
}: Props): JSX.Element => {
  const { classes } = useStyles();
  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="md"
      scroll="paper"
      aria-labelledby="task-detail-dialog-title"
      aria-describedby="task-detail-dialog-description"
    >
      <IconButton
        className={classes.closeButton}
        aria-label="delete"
        onClick={() => {
          onClose();
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogTitle
        id="task-detail-dialog-title"
        className={classes.title}
        component="div"
      >
        <Typography variant="h2">{task.title}</Typography>
      </DialogTitle>
      <DialogContent dividers={false}>
        <DialogContentText
          id="task-detail-dialog-description"
          ref={elementRef}
          tabIndex={-1}
          style={{ textAlign: "center", marginLeft: 16, marginRight: 16 }}
        >
          {task.details}
        </DialogContentText>
      </DialogContent>
      <DialogActions className={classes.actionWrapper}>
        <Button
          onClick={onClose}
          variant="contained"
          color="secondary"
          sx={{
            width: 280,
            marginBottom: 3,
          }}
        >
          Ok
        </Button>
      </DialogActions>
    </Dialog>
  );
};
