import {
  axiosInstance,
  callDelete,
  callGet,
  callPost,
  callPut,
  logger,
} from "./index";
import { AssetCategory } from "../models/AssetCategory";
import { Estate } from "../models/Estate";
import { AxiosResponse } from "axios";
import { Asset } from "../models/Asset";
import { AssetImage } from "../models/AssetImage";

export const base64Encode = (file: File): Promise<string> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = (error) => reject(error);
  });

export type ImageToUpload = {
  id: string;
  url: string;
  fileName: string;
  base64Data: string;
  file: File;
};

export type CachedImage = {
  fileName: string;
  size: string;
  img: string | ArrayBuffer;
};

export type AssetImageUploadResult = {
  asset_id?: number;
  file_name?: string;
  url?: string;
};

export class AssetsApi {
  async fetchAssetCategories(): Promise<AssetCategory[] | undefined> {
    const response = await callGet(`${Asset.path}/categories`);
    return (
      response?.data
        .map((json: any) => AssetCategory.fromJSON(json))
        ?.filter((cat: AssetCategory) => cat.category !== "All")
        ?.sort((a: AssetCategory, b: AssetCategory) =>
          (a.sequence || 0) > (b.sequence || 0) ? 1 : -1
        ) || ([] as AssetCategory[])
    );
  }

  async saveAsset(
    estateId?: number,
    asset?: Asset
  ): Promise<Asset | undefined> {
    if (!estateId || !asset) return;
    try {
      if (!asset?.id) {
        // new asset
        const response = await callPost(
          `${Estate.path}/${estateId}${Asset.path}`,
          asset.toJSON()
        );
        if (response?.data?.data) {
          return Asset.fromJSON(response.data.data);
        } else {
          throw Error("asset creation failed");
        }
      } else {
        const response = await callPut(
          `${Estate.path}/${estateId}${Asset.path}/${asset?.id}`,
          asset.toJSON()
        );
        if (response?.data?.id) {
          return asset;
        } else {
          throw Error("asset update failed");
        }
      }
    } catch (error) {
      logger.error(error as Error);
      throw error;
    }
  }

  async deleteAsset(
    estateId?: number,
    assetId?: number
  ): Promise<AxiosResponse | null | undefined> {
    if (!estateId || !assetId) return;
    return await callDelete(
      `${Estate.path}/${estateId}${Asset.path}/${assetId}`
    );
  }

  async saveAssetImage(
    image?: ImageToUpload,
    assetId?: number,
    estateId?: number
  ): Promise<AssetImageUploadResult | undefined> {
    if (image?.file && assetId && estateId) {
      const formData = new FormData();
      formData.append("image", image.file, image?.fileName);
      // formData.append("asset_id", assetId.toString());
      const response = await axiosInstance.post(
        `${Estate.path}/${estateId}${Asset.path}/${assetId}${AssetImage.path}`,
        formData
      );
      return response.data as AssetImageUploadResult;
    }
  }

  async deleteAssetImage(
    estateId?: number,
    assetId?: number,
    imageId?: number
  ): Promise<AxiosResponse | null | undefined> {
    if (!estateId || !assetId || !imageId) return;
    return await callDelete(
      `${Estate.path}/${estateId}${Asset.path}/${assetId}${AssetImage.path}/${imageId}`
    );
  }
}
