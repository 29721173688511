import { CognitoAuth } from "./CognitoAuth";
import { AtticusEstate } from "./AtticusEstate";
import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import { AtticusAdvisor } from "./AtticusAdvisor";
import { COOKIES, ENVS, LARAVEL_APP_SERVICE_PATH, SCREEN_PATHS } from "../constants";
import { AtticusLogger } from "./AtticusLogger";
import { EstateNotes } from "./EstateNotes";
import { Opportunities } from "./Opportunities";
import { FinancialConvos } from "./FinancialConvos";
import { KnowledgeEngine } from "./KnowledgeEngine";
import { Languages } from "./Languages";
import { Invites } from "./Invites";
import { RunTimeConfig } from "./RunTimeConfig";
import { AtticusForms } from "./AtticusForms";
import { Geographies } from "./Geographies";
import { DataExportApi } from "./DataExportApi";
import { ProbateApi } from "./Probate";
import { AssetsApi } from "./Assets";
import { AtticusEstateLaravelized } from "./AtticusEstateLaravelized";
import { AtticusEstateUser } from "./AtticusEstateUser";
import { EstateForms } from "./EstateForms";
import Cookies from "js-cookie";

// include default headers used by our APIs
try {
  axios.defaults.headers.common["Atticus-User-Time-Zone"] = new Date()
    .toLocaleString("en", { timeZoneName: "short" })
    .split(" ")
    .pop();
} catch (e) {
  console.error("Failed to set Atticus-User-Time-Zone header: ", e);
}

// environment helpers
export const isProd = process.env.NODE_ENV === ENVS.prod;
export const isDev = process.env.NODE_ENV === ENVS.dev;
export const isTest = process.env.NODE_ENV === ENVS.test;

// API singletons
export const axiosInstance = axios.create({
  baseURL: LARAVEL_APP_SERVICE_PATH,
});
export const CancelToken = axios.CancelToken;
export const log2Console = process.env.NODE_ENV === "development";
export const authApi = new CognitoAuth(axiosInstance);
export const estateApi = new AtticusEstate();
export const estateLaravelizedApi = new AtticusEstateLaravelized();
export const estateFormApi = new EstateForms();
export const estateUserApi = new AtticusEstateUser();
export const assetApi = new AssetsApi();
export const advisorApi = new AtticusAdvisor();
export const notesApi = new EstateNotes();
export const opportunitiesApi = new Opportunities();
export const financialConvosApi = new FinancialConvos();
export const formsApi = new AtticusForms();
export const knowledgeEngineApi = new KnowledgeEngine();
export const geographiesApi = new Geographies();
export const inviteApi = new Invites();
export const languagesApi = new Languages();
export const logger = new AtticusLogger();
export const runTimeConfig = new RunTimeConfig();
export const dataExportApi = new DataExportApi();
export const probateApi = new ProbateApi();

function headersPlusAuth(currentHeaders?: any): any {
  const authToken = Cookies.get(COOKIES.authToken)
  let headers: any = currentHeaders || {}
  if (authToken) {
    headers = {
      ...headers,
      "Authorization": "Bearer " + Cookies.get(COOKIES.authToken)
    }
  }

  return headers
}

export async function callGet(
  path: string,
  rethrowError = false,
  config?: AxiosRequestConfig
): Promise<AxiosResponse | null> {
  try {

    return await axiosInstance.get(path, { ...config, headers: headersPlusAuth(config?.headers), });
  } catch (error) {
    if (rethrowError) {
      throw error;
    } else {
      handleError(error);
    }
  }
  return null;
}

export async function callPost(
  path: string,
  body?: Record<string, unknown>,
  rethrowError = false,
  config?: AxiosRequestConfig
): Promise<AxiosResponse | null> {
  try {
    return await axiosInstance.post(path, body, { ...config, headers: headersPlusAuth(config?.headers), });
  } catch (error) {
    if (rethrowError) {
      throw error;
    } else {
      handleError(error);
    }
  }
  return null;
}

export async function callPut(
  path: string,
  body?: Record<string, unknown>,
  rethrowError = false,
  config?: AxiosRequestConfig
): Promise<AxiosResponse | null> {
  try {
    return await axiosInstance.put(path, body, { ...config, headers: headersPlusAuth(config?.headers), });
  } catch (error) {
    if (rethrowError) {
      throw error;
    } else {
      handleError(error);
    }
  }
  return null;
}

export async function callDelete(
  path: string,
  rethrowError = false,
  config?: AxiosRequestConfig
): Promise<AxiosResponse | null> {
  try {
    return await axiosInstance.delete(path, { ...config, headers: headersPlusAuth(config?.headers), });
  } catch (error) {
    if (rethrowError) {
      throw error;
    } else {
      handleError(error);
    }
  }
  return null;
}

function handleError(error: any) {
  if ((error as any)?.response?.status === 401) {
    console.log("user failed authentication for an API call, redirecting to login page")
    // user not authenticated
    // NOTE: something was causing a false positive on this
    //    commenting out until I can figure it out
    // const revoked = error?.response?.data?.message === "Revoked";
    // window.document.location.href =
    //   "/login" + (revoked ? "?error=revoked" : "");
    window.document.location.href = SCREEN_PATHS.login;
    // TODO: add path to redirect back to once logged in
  } else if ((error as any)?.response?.status === 503) {
    // service unavailable, come back later
    window.document.location.href = SCREEN_PATHS.maintenance;
  } else {
    const errorObject = {
      message: (error as Error)?.message,
      baseURL: error?.config?.baseURL,
      url: error?.config?.url,
      method: error?.config?.method,
    };
    logger.error(errorObject);
  }
}

/**
 * artificial delay for testing
 * @param milliseconds
 */
export async function addDelay(milliseconds = 500): Promise<void> {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve();
    }, milliseconds);
  });
}
