import React from "react";
import workingForms15 from "../../../../assets/working-forms-1.5x.png";
import workingForms2 from "../../../../assets/working-forms-2x.png";
import workingForms3 from "../../../../assets/working-forms-3x.png";
import workingForms1 from "../../../../assets/working-forms-1x.png";
import { Card, CardContent, Typography, Box } from "@mui/material";
import { State } from "../../../../models/Geography";
import MuiMarkdown from "mui-markdown";

interface Props {
  state?: State;
}

export const NoStateForms = ({ state }: Props): JSX.Element => {
  return (
    <Card>
      <CardContent
        sx={{
          display: "flex",
          flex: 1,
          paddingBottom: "1rem !IMPORTANT",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        {state?.content.probateFallbackContent ? (
          <Box>
            <MuiMarkdown
              overrides={{
                // Because for some reason someone overrode all <ol> components to not have padding.
                ol: {
                  component: "ol",
                  props: {
                    style: { marginBottom: "0.5rem", padding: "10px 20px" },
                  },
                },
              }}
            >
              {state?.content.probateFallbackContent}
            </MuiMarkdown>
          </Box>
        ) : (
          <>
            <img
              srcSet={`${workingForms15} 1.5x, ${workingForms2} 2x, ${workingForms3} 3x`}
              src={workingForms1}
              alt="We're working on these forms"
              style={{ maxWidth: 477, width: "100%" }}
            />
            <Typography variant="h4" sx={{ my: 2, textAlign: "center" }}>
              We’re still working on preparing {state?.name} forms
            </Typography>
          </>
        )}
      </CardContent>
    </Card>
  );
};
