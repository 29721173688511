import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Theme,
  useMediaQuery,
  Breakpoint,
  CardActions,
} from "@mui/material";
import React from "react";

interface Props {
  cardLeft: JSX.Element;
  cardRight: JSX.Element;
  actionsLeft?: JSX.Element;
  actionsRight?: JSX.Element;
  breakAt: Breakpoint;
  titleLeft?: string;
  titleRight?: string;
  leftPercent?: number;
  rightPercent?: number;
}

export const ResponsiveCards = ({
  cardLeft,
  cardRight,
  actionsLeft,
  actionsRight,
  breakAt,
  titleLeft,
  titleRight,
  leftPercent = 50,
  rightPercent = 50,
}: Props): JSX.Element => {
  const gridRowStack = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down(breakAt)
  );

  return (
    <Box
      style={{
        display: "flex",
        flexDirection: gridRowStack ? "column" : "row",
        justifyContent: "space-between",
      }}
    >
      <Card style={{ width: gridRowStack ? "100%" : `${leftPercent}%` }}>
        {titleLeft && <CardHeader title={titleLeft} />}
        <CardContent>{cardLeft}</CardContent>
        {actionsLeft && (
          <CardActions
            sx={{
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            {actionsLeft}
          </CardActions>
        )}
      </Card>
      <Card
        style={{
          width: gridRowStack ? "100%" : `${rightPercent}%`,
          marginTop: gridRowStack ? 16 : 0,
          marginLeft: gridRowStack ? 0 : 16,
        }}
      >
        {titleRight && <CardHeader title={titleRight} />}
        <CardContent>{cardRight}</CardContent>
        {actionsRight && (
          <CardActions
            sx={{
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            {actionsRight}
          </CardActions>
        )}
      </Card>
    </Box>
  );
};
