import React, { useState, MouseEvent } from "react";
import {
  Box,
  Button,
  Container,
  IconButton,
  Popover,
  SwipeableDrawer,
  Theme,
  Typography,
} from "@mui/material";
import { withStyles } from "tss-react/mui";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import MenuIcon from "@mui/icons-material/Menu";

import atticusColors from "../../../../theme/colors";
import { TaskPhaseNav } from "./TaskPhaseNav";
import { TaskPhase } from "../../../../models/TaskPhase";

interface Props {
  currentTaskPhase: TaskPhase;
  status: string;
  taskPhases: TaskPhase[];
  onPhase: (taskPhase: TaskPhase) => void;
}

const Banner = withStyles(Container, (theme:Theme) => ({
  root: {
    height: "56px",
    display: "flex",
    padding: "0",
    borderBottom: `1px solid ${atticusColors.purple5}`,
    justifyContent: "space-between",
    alignItems: "center",
  },
}));

const StatusType = withStyles(Typography,(theme:Theme) => ({
  root: {
    color: `${atticusColors.purple2}`,
    padding: "0 16px",
  },
}));

const ButtonStyled = withStyles(Button,(theme:Theme) => ({
  root: {
    padding: "8px 16px",
  },
}));

const PopoverStyled = withStyles(Popover,(theme:Theme) => ({
  paper: {
    borderRadius: "8px",
    width: "270px",
  },
}));

const SwipeableDrawerStyled = withStyles(SwipeableDrawer, (theme:Theme) => ({
  paper: {
    borderTopLeftRadius: "8px",
    borderTopRightRadius: "8px",
    paddingTop: " 24px",
  },
}));

const Puller = withStyles(Box, (theme: Theme) => ({
  root: {
    width: 44,
    height: 6,
    backgroundColor: theme.palette.text.secondary,
    borderRadius: 3,
    position: "absolute",
    top: 12,
    left: "calc(50% - 22px)",
  },
}));

export const TaskBannerNav = ({
  status,
  onPhase,
  currentTaskPhase,
  taskPhases,
}: Props): JSX.Element => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [drawerOpen, setDrawerOpen] = useState<boolean>(false);

  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const toggleDrawer = () => {
    setDrawerOpen(!drawerOpen);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Banner>
      <Box
        sx={{
          display: {
            xs: "none",
            sm: "block",
          },
        }}
      >
        <ButtonStyled
          aria-controls="progress-menu"
          aria-haspopup="true"
          endIcon={<KeyboardArrowDownIcon />}
          onClick={handleClick}
          disableRipple={true}
        >
          {currentTaskPhase.taskPhase}
        </ButtonStyled>
        <PopoverStyled
          id="progress-menu"
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          <TaskPhaseNav
            taskPhases={taskPhases}
            currentTaskPhase={currentTaskPhase}
            onPhase={onPhase}
            onClose={handleClose}
          />
        </PopoverStyled>
      </Box>
      <Box
        sx={{
          display: {
            sm: "none",
            md: "none",
            lg: "none",
            xl: "none",
          },
        }}
      >
        <IconButton color="primary" onClick={toggleDrawer} disableRipple={true}>
          <MenuIcon />
        </IconButton>
        <SwipeableDrawerStyled
          anchor="bottom"
          open={drawerOpen}
          onClose={toggleDrawer}
          onOpen={toggleDrawer}
        >
          <Puller />
          <TaskPhaseNav
            taskPhases={taskPhases}
            currentTaskPhase={currentTaskPhase}
            onPhase={onPhase}
            onClose={toggleDrawer}
          />
        </SwipeableDrawerStyled>
      </Box>
      <StatusType variant="h6">{status}</StatusType>
    </Banner>
  );
};