import React from "react";
import { Box, Checkbox, MenuItem, Select } from "@mui/material";

interface Props {
  categoryKeys?: string[];
  onFilter: (probate: string, categories: string[]) => void;
  categories: string[];
  probate: string;
}

export const AssetsFilter = ({
  categoryKeys,
  onFilter,
  categories = [],
  probate = "all",
}: Props): JSX.Element => {
  const handleChange = (event: any) => {
    switch (event.target.name) {
      case "probate": {
        const value = event.target.value as string;
        onFilter(value, categories);
        break;
      }
      case "category": {
        const value = event.target.value as string[];
        onFilter(probate, value);
        break;
      }
    }
  };

  return (
    <form>
      <Box
        sx={{
          mb: 3,
          ">div": {
            mr: 1,
            fontSize: 14,
          },
          ".MuiInputBase-root, .MuiInputBase-root>div": {
            borderRadius: 5,
          },
        }}
      >
        <Select
          name="probate"
          value={probate}
          onChange={handleChange}
          variant="outlined"
          autoWidth={true}
        >
          <MenuItem value="all">
            <em>All types</em>
          </MenuItem>
          <MenuItem value="Probate">Probate assets only</MenuItem>
          <MenuItem value="Not Probate">Non-probate assets only</MenuItem>
          <MenuItem value="null">Uncategorized</MenuItem>
        </Select>
        <Select
          name="category"
          value={categories}
          onChange={handleChange}
          variant="outlined"
          autoWidth={true}
          displayEmpty={true}
          renderValue={() =>
            categories?.length
              ? categories.length === 1
                ? categories[0]
                : `${categories?.length} categories`
              : "All categories"
          }
          multiple
        >
          {categoryKeys &&
            categoryKeys.map((cat: string) => (
              <MenuItem value={cat} key={cat}>
                <Checkbox checked={categories.includes(cat)} />
                {cat || "----"}
              </MenuItem>
            ))}
        </Select>
      </Box>
    </form>
  );
};
