import React from "react";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import { Button, Card, CardContent, Typography } from "@mui/material";
import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()(() => ({
  root: {},
  content: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: 40,
  },
  checkIcon: {
    width: 72,
    height: 72,
  },
  body: {
    maxWidth: 460,
    textAlign: "center",
    margin: "20px 0",
  },
}));

interface Props {
  title: string;
  body: string;
  onClose: () => void;
  onDashboard: () => void;
}

export const InviteSentBody = ({
  title,
  body,
  onClose,
  onDashboard,
}: Props): JSX.Element => {
  const { classes } = useStyles();
  return (
    <Card className={classes.root}>
      <CardContent className={classes.content}>
        <CheckCircleOutlineIcon className={classes.checkIcon} color="primary" />
        <Typography variant="h2" id="invite-sent-title">
          {title}
        </Typography>
        <Typography
          variant="body1"
          id="invite-sent-body"
          className={classes.body}
        >
          {body}
        </Typography>
        <Button variant="contained" color="primary" onClick={onDashboard}>
          Go to my Dashboard
        </Button>
        <Button onClick={onClose}>Invite another client</Button>
      </CardContent>
    </Card>
  );
};
