import { EstateLaravelized } from "../models/EstateLaravelized";
import { callGet, callPut } from "./index";

export class AtticusEstateLaravelized {
  async getEstate(
    estate_id: number,
    rethrowError = false
  ): Promise<EstateLaravelized | undefined> {
    const fullPath = `${EstateLaravelized.path}/${estate_id}`;
    const response = await callGet(fullPath, rethrowError);
    const estate: EstateLaravelized = EstateLaravelized.fromJSON(
      response?.data
    );

    return estate;
  }

  async updateEstate(
    estate_id: number,
    editedEstate: EstateLaravelized,
    rethrowError = false
  ): Promise<EstateLaravelized | undefined> {
    const fullPath = `${EstateLaravelized.path}/${estate_id}`;

    const response = await callPut(
      fullPath,
      editedEstate.toJSON(),
      rethrowError
    );
    const estate: EstateLaravelized = EstateLaravelized.fromJSON(
      response?.data
    );

    return estate;
  }
}
