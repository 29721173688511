import React, { useEffect, useState } from "react";
import { Box, Button, Typography } from "@mui/material";
import { Estate } from "../../../../models/Estate";
import { advisorApi } from "../../../../apis";
import { AdvisorsEstates } from "../../../../models/AdvisorsEstates";
import { AdvisorsDialog } from "./AdvisorsDialog";

interface Props {
  estate?: Estate;
  isAdvisorAdmin?: boolean;
  onRefresh?: () => void;
}

export const AdvisorInfo = ({
  estate,
  isAdvisorAdmin = false,
  onRefresh,
}: Props): JSX.Element => {
  const [advisorEstates, setAdvisorEstates] = useState<AdvisorsEstates[]>();
  const [advisorsDialogOpen, setAdvisorsDialogOpen] = useState(false);

  useEffect(() => {
    (async () => {
      getAdvisors();
    })();
  }, []);

  const getAdvisors = async (): Promise<void> => {
    if (estate?.id) {
      // get the advisors for this estate
      setAdvisorEstates(await advisorApi.fetchEstatesAdvisors(estate.id));
    }
  };

  const accessSortedAdvisorEstates =
    advisorEstates?.sort((a: AdvisorsEstates, b: AdvisorsEstates) =>
      (a?.hasWriteAccess || "") < (b?.hasWriteAccess || "") ? 1 : -1
    ) || advisorEstates;

  const noAdvisors = !accessSortedAdvisorEstates?.length;

  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Typography
          sx={{
            fontWeight: "bold",
          }}
        >
          Advisor Assignments/Team Management
        </Typography>
        {isAdvisorAdmin && (
          <Button
            variant="contained"
            size="small"
            onClick={() => setAdvisorsDialogOpen(true)}
          >
            Manage
          </Button>
        )}
      </Box>
      <AdvisorsDialog
        open={advisorsDialogOpen}
        estate={estate}
        onClose={(refresh?: boolean) => {
          setAdvisorsDialogOpen(false);
          if (refresh) {
            getAdvisors();
            onRefresh?.();
          }
        }}
      />
      {noAdvisors ? (
        <Typography>No advisors assigned</Typography>
      ) : (
        <Box
          sx={{
            overflowY: "auto",
            maxHeight: 170,
          }}
        >
          {accessSortedAdvisorEstates?.map(
            (advisorEstates: AdvisorsEstates) => (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  borderTop: "solid 1px",
                  borderColor: "grey.400",
                  py: 2,
                  px: 1,
                  "&:first-of-type": {
                    border: "none",
                  },
                }}
                key={`advisorEstates_${advisorEstates.id}`}
              >
                <Typography>{advisorEstates?.advisorName}</Typography>
                <Typography>
                  {advisorEstates?.hasWriteAccess ? "Editor" : "Viewer"}
                </Typography>
              </Box>
            )
          )}
        </Box>
      )}
    </>
  );
};
