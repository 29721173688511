import { callGet, callPost, callDelete } from "./index";
import { AtticusForm } from "../models/AtticusForm";
import { FormType } from "../models/FormType";
import { FormStatusType } from "../models/FormStatusType";
import { GeographyType } from "../models/Geography";
import { ATTICUS_DOC_LOGO_LOCATIONS } from "../constants";
import { FormInterface } from "../models/FormInterface";

const path = "/forms";

export interface QueryPayload {
  geography_type?: GeographyType;
  geography_id?: number;
  form_type_id?: number;
  search_terms?: string;
  language_id?: number;
  geography_inclusive?: boolean;
}

export class AtticusForms {
  async fetchSignedUrl(
    formId: number,
    pdfStorage = false
  ): Promise<string | undefined> {
    let fullPath = `${path}/${formId}`;

    if (pdfStorage) {
      fullPath = fullPath + "/pdf-storage-signed-url";
    } else {
      fullPath = fullPath + "/signed-url";
    }
    const response = await callGet(fullPath);
    return response?.data;
  }

  async fetchBatchInformation(batchId: number): Promise<string | undefined> {
    const fullPath = `${path}/batch/${batchId}`;
    const response = await callGet(fullPath);
    return response?.data;
  }

  async deleteForms(formIds: number[]): Promise<boolean | undefined> {
    const fullPath = `${path}/delete-bulk`;
    const response = await callPost(fullPath, {
      formIds: formIds,
    });
    return response?.data;
  }

  async deleteForm(formId: number): Promise<boolean | undefined> {
    const fullPath = `${path}/${formId}`;
    const response = await callDelete(fullPath);
    return response?.data;
  }

  async importFormsByFileKeys(
    files: string[],
    logoLocation?: string
  ): Promise<any> {
    const fullPath = `${path}/from-files`;
    let logoOptions;
    if (logoLocation) {
      logoOptions = ATTICUS_DOC_LOGO_LOCATIONS.filter(
        (loc: any) => loc.name === logoLocation
      )[0];
    }
    const response = await callPost(fullPath, {
      keys: files,
      logoOptions: logoOptions?.opts,
    });
    return response?.data;
  }

  async importFormsByFolderKeys(
    folders: string[],
    logoLocation?: string
  ): Promise<any> {
    const fullPath = `${path}/from-folders`;
    let logoOptions;
    if (logoLocation) {
      logoOptions = ATTICUS_DOC_LOGO_LOCATIONS.filter(
        (loc: any) => loc.name === logoLocation
      )[0];
    }
    const response = await callPost(fullPath, {
      keys: folders,
      logoOptions: logoOptions?.opts,
    });
    return response?.data;
  }

  async fetchForms(queryPayload: QueryPayload): Promise<AtticusForm[] | null> {
    const fullPath = `${path}/query`;
    const response = await callPost(fullPath, {
      geography_type: queryPayload.geography_type,
      geography_id: queryPayload.geography_id,
      form_type_id: queryPayload.form_type_id,
      search_terms: queryPayload.search_terms,
      language_id: queryPayload.language_id,
      geography_inclusive: queryPayload.geography_inclusive || true,
    });

    const forms: AtticusForm[] = [];
    response?.data?.forEach((form: any) => {
      const newForm = AtticusForm.fromJSON(form);
      forms.push(newForm);
    });
    return forms;
  }

  async fetchForm(formId: number): Promise<AtticusForm | null> {
    const fullPath = `${path}/${formId}`;
    const response = await callGet(fullPath);

    const form: AtticusForm = AtticusForm.fromJSON(response?.data);
    return form;
  }

  async fetchFormType(formTypeId: number): Promise<FormType | null> {
    const fullPath = `${path}/form-types/${formTypeId}`;
    const response = await callGet(fullPath);

    const formType: FormType = FormType.fromJSON(response?.data);
    return formType;
  }

  async fetchFormTypes(): Promise<FormType[] | null> {
    const fullPath = `${path}/form-types`;
    const response = await callGet(fullPath, true);

    const formTypes: FormType[] = [];
    response?.data?.forEach((formType: any) => {
      formTypes.push(FormType.fromJSON(formType));
    });

    return formTypes;
  }

  async fetchFormStatusTypes(): Promise<FormStatusType[] | null> {
    const fullPath = `${path}/status-types`;
    const response = await callGet(fullPath, true);

    const formStatusTypes: FormStatusType[] = [];
    response?.data?.forEach((formStatusType: any) => {
      formStatusTypes.push(FormStatusType.fromJSON(formStatusType));
    });

    return formStatusTypes;
  }

  async fetchFormStatusType(
    formStatusTypeId: number
  ): Promise<FormStatusType | null> {
    const fullPath = `${path}/status-types/${formStatusTypeId}`;
    const response = await callGet(fullPath);

    const formStatusType: FormStatusType = FormStatusType.fromJSON(
      response?.data
    );
    return formStatusType;
  }

  async fetchLatestFormInterface(
    formId: number
  ): Promise<FormInterface | null> {
    try {
      const fullPath = `${path}/${formId}/interface`;
      const response = await callGet(fullPath, true);
      const fInterface: FormInterface = FormInterface.fromJSON(response?.data);
      return fInterface;
    } catch (e) {
      return null;
    }
  }

  async saveFormInterface(
    formId: number,
    formInterface: FormInterface
  ): Promise<FormInterface> {
    const fullPath = `${path}/${formId}/interface`;
    const response = await callPost(
      fullPath,
      {
        data: formInterface.data,
      },
      true
    );
    const fInterface: FormInterface = FormInterface.fromJSON(response?.data);
    return fInterface;
  }
}
