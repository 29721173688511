import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogTitle,
  Box,
  Button,
  DialogContent,
  useMediaQuery,
  useTheme,
  CircularProgress,
  Typography,
  DialogActions,
  Alert,
  Grow,
} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import DownloadIcon from "@mui/icons-material/Download";
import { AtticusForm } from "../../../models/AtticusForm";
import download from "downloadjs";
import axios from "axios";

const dialogContentStyle = {
  height: "75vh",
  width: "100%",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  padding: 0,
  marginBottom: 0,
  position: "relative",
} as const;

export enum PdfFileChoice {
  PROCESSED = "processed",
  WATERMARKED = "watermarked",
  THUMBNAIL = "thumbnail",
}

interface Props {
  open: boolean;
  onClose: () => void;
  form?: AtticusForm;
  fileChoice?: PdfFileChoice;
  withDisclaimer?: boolean;
}

export default function AtticusFormViewDialog({
  open,
  onClose,
  form,
  fileChoice = PdfFileChoice.PROCESSED,
  withDisclaimer = false,
}: Props): JSX.Element {
  const theme = useTheme();
  const isMobileSize = useMediaQuery(theme.breakpoints.down("sm"));
  const [fileUrl, setFileUrl] = useState<string>();
  const [processedPdfByteArray, setProcessedPdfByteArray] =
    useState<Uint8Array>();
  const [showDisclaimer, setShowDisclaimer] = useState<boolean>(withDisclaimer);

  useEffect(() => {
    async function fetchFormUrl() {
      switch (fileChoice) {
        case PdfFileChoice.PROCESSED:
          // Get Processed Url
          if (form?.s3Urls?.asyncProcessedPdf) {
            const url = await form.s3Urls?.asyncProcessedPdf();
            setFileUrl(url);
          }
          break;
        case PdfFileChoice.WATERMARKED:
          // Get Watermarked URL
          if (form?.s3Urls?.watermarkedPdf) {
            setFileUrl(form.s3Urls.watermarkedPdf);
          }
          break;
        case PdfFileChoice.THUMBNAIL:
          // Get Thumbnail URL
          if (form?.s3Urls?.thumbnailFile) {
            setFileUrl(form.s3Urls.thumbnailFile);
          }
          break;
      }
    }
    if (form) {
      fetchFormUrl();
    } else {
      setFileUrl(undefined);
    }
  }, [form, fileChoice]);

  useEffect(() => {
    if (withDisclaimer && !showDisclaimer) {
      setShowDisclaimer(true);
    }
  }, [open]);

  useEffect(() => {
    async function fetchProcessedPdfByteArray() {
      try {
        if (fileChoice === PdfFileChoice.PROCESSED && fileUrl) {
          const response = await axios.get(fileUrl, {
            responseType: "arraybuffer",
          });
          setProcessedPdfByteArray(new Uint8Array(response.data));
        }
      } catch (error) {
        console.error(error);
      }
    }
    if (fileChoice === PdfFileChoice.PROCESSED && fileUrl) {
      fetchProcessedPdfByteArray();
    }
  }, [fileUrl]);

  const onDownload = async () => {
    if (fileUrl && processedPdfByteArray) {
      const fileUrlObj = new URL(fileUrl);
      const fileName = fileUrlObj.pathname.split("/").pop();
      download(processedPdfByteArray, fileName);
    } else {
      console.error("Missing File URL or Byte Array. Cannot download.");
    }
  };

  const dismissAlertButKeepPersisting = () => {
    setShowDisclaimer(false);
    setTimeout(function () {
      setShowDisclaimer(true);
    }, 15000);
  };

  return (
    <Dialog
      fullScreen={isMobileSize}
      fullWidth
      maxWidth="lg"
      open={open}
      onClose={onClose}
      aria-labelledby="responsive-dialog-title"
      className="noPadding"
    >
      {open && form ? (
        <>
          <DialogTitle id="responsive-dialog-title" component="div">
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Box
                sx={{
                  flex: 1,
                  marginRight: isMobileSize ? "2.5rem" : undefined,
                }}
              >
                <Typography
                  variant="h5"
                  sx={{
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    wordBreak: "break-word",
                    display: "-webkit-box",
                    WebkitLineClamp: "2",
                    WebkitBoxOrient: "vertical",
                    fontWeight: "700",
                  }}
                  color="primary.dark"
                >
                  {form.getGovernmentId()
                    ? `[${form.getGovernmentId()}] - `
                    : ``}
                  {form.getTitle()}
                </Typography>
                {form.geography?.fullName && (
                  <Typography variant="h6" color="text.secondary">
                    {form.geography?.fullName}
                  </Typography>
                )}
              </Box>
              {!isMobileSize && fileChoice == PdfFileChoice.PROCESSED && (
                <Box
                  sx={{
                    marginRight: "2.5rem",
                  }}
                >
                  <Button
                    color="primary"
                    variant="contained"
                    endIcon={<DownloadIcon />}
                    onClick={onDownload}
                  >
                    {"Download"}
                  </Button>
                </Box>
              )}
            </Box>
            {onClose ? (
              <IconButton
                aria-label="close"
                size="small"
                sx={{
                  position: "absolute",
                  right: 5,
                  top: 5,
                  color: "grey.500",
                }}
                onClick={onClose}
              >
                <CloseIcon />
              </IconButton>
            ) : null}
          </DialogTitle>
          <DialogContent sx={dialogContentStyle}>
            {fileUrl && fileChoice && fileChoice != PdfFileChoice.THUMBNAIL && (
              <>
                <object
                  style={{
                    width: "100%",
                    height: "100%",
                  }}
                  data={fileUrl}
                  type="application/pdf"
                >
                  <iframe src={fileUrl} width="100%" height="100%">
                    This browser does not support PDFs. Please download the PDF
                    to view it:
                    <a href={fileUrl}>Download PDF</a>
                  </iframe>
                </object>

                <Box
                  sx={{
                    position: "absolute",
                    bottom: 15,
                    maxWidth: "80%",
                  }}
                >
                  <Grow in={showDisclaimer}>
                    <Alert
                      severity="warning"
                      color="info"
                      elevation={1}
                      action={
                        <Button
                          color="inherit"
                          size="small"
                          onClick={dismissAlertButKeepPersisting}
                        >
                          HIDE
                        </Button>
                      }
                    >
                      <Box component="span">
                        {`Heads up: This form is provided in product as view-only. If you would like to edit and make changes, please download this form to your computer first, otherwise `}
                        <strong>{`changes to this form will not be saved`}</strong>
                        {` within product.`}
                      </Box>
                    </Alert>
                  </Grow>
                </Box>
              </>
            )}
            {fileUrl && fileChoice && fileChoice == PdfFileChoice.THUMBNAIL && (
              <Box
                component="img"
                sx={{
                  height: "90%",
                  width: "90%",
                  objectFit: "contain",
                  filter: "drop-shadow(0px 0px 8px rgba(0,0,0,.3))",
                }}
                src={fileUrl}
              />
            )}
            {(!fileUrl || !fileChoice) && <CircularProgress />}
          </DialogContent>
          {isMobileSize && fileChoice == PdfFileChoice.PROCESSED && (
            <DialogActions>
              <Button
                color="primary"
                variant="contained"
                endIcon={<DownloadIcon />}
                onClick={onDownload}
                fullWidth
              >
                {"Download"}
              </Button>
            </DialogActions>
          )}
        </>
      ) : (
        <DialogContent sx={dialogContentStyle}>Form Required.</DialogContent>
      )}
    </Dialog>
  );
}
