import React, { useState } from "react";
import { ConsumerApp, LARAVEL_APP_HOST, STORAGE_KEYS } from "../../constants";
import { Box, FormControl, MenuItem, Select, Typography } from "@mui/material";
import { enumToArrayString } from "../../utils/enumToArrayString";

const environments = enumToArrayString(ConsumerApp);

export const EnvironmentInputs = (): JSX.Element => {
  const [environment, setEnv] = useState(
    window.localStorage.getItem(STORAGE_KEYS.ENV_OVERRIDE) || LARAVEL_APP_HOST
  );

  return (
    <Box
      sx={{
        flexDirection: "column",
        textAlign: "center",
      }}
    >
      <Typography>
        <strong>LARAVEL HOST OVERRIDE</strong>
      </Typography>
      <Typography>
        Node Env: {process.env.NODE_ENV}
      </Typography>
      <FormControl
        style={{
          marginTop: 1,
          marginBottom: 1,
        }}
      >
        <Select
          id="environment-select"
          name="environment"
          onChange={(event: any) => {
            const envHost = event.target.value;
            setEnv(envHost);
            if (envHost === "CLEAR") {
              window.localStorage.removeItem(STORAGE_KEYS.ENV_OVERRIDE);
            } else {
              window.localStorage.setItem(STORAGE_KEYS.ENV_OVERRIDE, envHost);
            }
            window.location.reload();
          }}
          value={environment}
          fullWidth
        >
          {environments.map((env: string) => {
            return (
              <MenuItem value={env} key={env}>
                {env}
              </MenuItem>
            );
          })}
          <MenuItem value="CLEAR" key="CLEAR">
            CLEAR OVERRIDE
          </MenuItem>
        </Select>
      </FormControl>
      {window.localStorage.getItem(STORAGE_KEYS.ENV_OVERRIDE) && (
        <Typography>
          <strong>CURRENT:</strong>{" "}
          {window.localStorage.getItem(STORAGE_KEYS.ENV_OVERRIDE)}
        </Typography>
      )}
    </Box>
  );
};
