import atticusColors from "../theme/colors";

export type Chip = {
  color?: string;
  textColor?: string;
  label?: string;
};

export const AtticusChips = {
  guide: {
    label: "Guide",
    color: atticusColors.pink,
    textColor: atticusColors.white,
  },
  "end-of-life": {
    label: "End of Life",
    color: atticusColors.turquoise,
    textColor: atticusColors.white,
  },
  tool: {
    label: "Tool",
    color: atticusColors.lightYellow,
    textColor: atticusColors.white,
  },
  resource: {
    label: "Resource",
    color: atticusColors.magenta,
    textColor: atticusColors.white,
  },
};
