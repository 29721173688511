import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import { StoreState } from "../../../../../reducers";
import { Estate } from "../../../../../models/Estate";
import { User } from "../../../../../models/User";
import { GeographyType, State } from "../../../../../models/Geography";
import { Language } from "../../../../../models/Language";
import { FormType } from "../../../../../models/FormType";
import { fetchProbateTypes } from "../../../../../actions/probateTypeActions";
import { fetchStates } from "../../../../../actions/geographyActions";
import { fetchLanguages } from "../../../../../actions/languageActions";
import { fetchFormTypes } from "../../../../../actions/formTypeActions";
import { ProbateType } from "../../../../../models/ProbateType";
import { useLocation } from "react-router-dom";
import { FormsLibrarySearch } from "../../../../shared/forms/library/FormsLibrarySearch";
import { AtticusForm } from "../../../../../models/AtticusForm";
import { QueryPayload } from "../../../../../apis/AtticusForms";
import { formsApi } from "../../../../../apis";
import { Box } from "@mui/material";
import { FormsList } from "../../../../shared/forms/list/FormsList";
import { FormsListZeroState } from "../../../../shared/forms/list/FormsListZeroState";

interface Props {
  user: User;
  estates?: Estate[];
  states?: State[];
  fetchStates: () => void;
  languages?: Language[];
  fetchLanguages: () => void;
  formTypes?: FormType[];
  fetchFormTypes: () => void;
  probateTypes?: ProbateType[];
  fetchProbateTypes: () => void;
}

const _FormsLibraryEstateView = ({
  user,
  estates,
  states,
  fetchStates,
  languages,
  fetchLanguages,
  formTypes,
  fetchFormTypes,
  probateTypes,
  fetchProbateTypes,
}: Props): JSX.Element => {
  const { estateId: estateIdString } = useParams();
  const estateId = estateIdString ? parseInt(estateIdString, 10) : undefined;

  const { state } = useLocation();

  const [estate, setEstate] = useState<Estate | undefined>(undefined);
  const [forms, setForms] = useState<AtticusForm[] | undefined>(undefined);
  const [loadingForms, setLoadingForms] = useState(false);

  const hasAccess = user?.advisorGroup?.hasAdvisorAccessToForms;

  const getForms = async (queryPayload: QueryPayload): Promise<void> => {
    setLoadingForms(true);
    const newForms = await formsApi.fetchForms(queryPayload);
    // Temporary filtering of only English and French forms until we touch
    // FormsLibrarySearch again (county-wide feature expansion for example).
    // Ideal solution is to add a "Language" dropdown to the
    // FormsLibrarySearch component.

    const allowedLanguages = ["en", "fr"];

    const allowedLanguageIds = languages
      ?.filter((lang) => {
        return allowedLanguages.includes(lang.code);
      })
      .map((lang) => lang.id);

    const filteredForms = newForms?.filter(
      (form) =>
        (form.languageId && allowedLanguageIds?.includes(form.languageId)) ||
        null
    );

    if (filteredForms) {
      setForms(filteredForms);
    }

    // End Temporary

    setLoadingForms(false);
  };

  useEffect(() => {
    if (hasAccess && estateId) {
      if (!states || !states?.length) {
        fetchStates();
      }
      if (!languages || !languages?.length) {
        fetchLanguages();
      }
      if (!formTypes || !formTypes?.length) {
        fetchFormTypes();
      }
      if (!probateTypes || !probateTypes?.length) {
        fetchProbateTypes();
      }
    }
  }, []);

  useEffect(() => {
    if (estateId && estates?.length) {
      const estate = estates.find((estate: Estate) => estate.id === +estateId);
      setEstate(estate);
    }
  }, [estates]);

  useEffect(() => {
    if (state && languages) {
      getForms(state as QueryPayload);
    }
  }, [state, languages]);

  return hasAccess ? (
    <div data-automation-id="page-content-estate-forms-library">
      {estate && (
        <>
          <Box mt={3} mb={3}>
            <FormsLibrarySearch
              initGeographyType={
                state?.geography_type && state?.geography_id
                  ? state?.geography_type
                  : GeographyType.State
              }
              initGeographyId={
                state?.geography_type && state?.geography_id
                  ? state?.geography_id
                  : estate.stateId
              }
              geographyInclusive={state?.geography_inclusive}
              initLanguageCode={
                languages?.find((lang) => lang.id === state?.language_id)?.code
              }
              initFormTypeSlug={
                formTypes?.find((ft) => ft.id === state?.form_type_id)?.slug
              }
              initSearchTerms={state?.search_terms}
              onComplete={getForms}
              loadingForms={loadingForms}
            />
          </Box>
          <Box>
            <FormsList
              forms={forms}
              estate={estate}
              emptyState={<FormsListZeroState />}
              allowCheckedBadge
            />
          </Box>
        </>
      )}
    </div>
  ) : (
    <></>
  );
};

const mapStateToProps = ({
  user,
  estates,
  states,
  formTypes,
  languages,
  probateTypes,
}: StoreState): {
  user: User;
  estates: Estate[];
  states: State[];
  formTypes: FormType[];
  languages: Language[];
  probateTypes: ProbateType[];
} => {
  return {
    user,
    estates,
    states,
    formTypes,
    languages,
    probateTypes,
  };
};
const FormsLibraryEstateView = connect(mapStateToProps, {
  fetchStates,
  fetchLanguages,
  fetchFormTypes,
  fetchProbateTypes,
})(_FormsLibraryEstateView);

export default FormsLibraryEstateView;
