import React from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  Chip,
  Typography,
} from "@mui/material";
import { Chip as AttChip } from "../../models/Chip";

import SendIcon from "@mui/icons-material/Send";
interface Props {
  title?: string;
  brief?: string;
  url?: string;
  chips?: AttChip[];
  maxWidth?: number;
}

export const ResourceCard = ({
  title,
  brief,
  url,
  chips,
  maxWidth = 360,
}: Props): JSX.Element => {
  return (
    <Card style={{ maxWidth }}>
      {chips && (
        <Box
          style={{
            padding: 16,
            paddingBottom: 0,
          }}
        >
          {chips.map((chip: AttChip) => (
            <Chip
              key={chip?.label}
              label={chip?.label?.toUpperCase()}
              style={{
                backgroundColor: chip?.color || "blue",
                marginRight: 8,
                color: chip?.textColor || "white",
              }}
            />
          ))}
        </Box>
      )}
      <CardContent>
        <Box
          style={{
            height: 160,
          }}
        >
          {title && (
            <Typography
              variant="h5"
              style={{
                fontWeight: 700,
                color: "black",
              }}
              gutterBottom
            >
              {title}
            </Typography>
          )}
          {brief && (
            <Typography
              variant="body2"
              style={{
                marginBottom: 16,
                overflow: "hidden",
                height: 80,
              }}
            >
              {brief}
            </Typography>
          )}
        </Box>
        {url && (
          <Button
            color="primary"
            variant="contained"
            startIcon={
              <SendIcon
                style={{
                  transform: "translateY(-2.5px) rotate(335deg)",
                }}
              />
            }
            onClick={() => {
              window.open(url);
            }}
          >
            Share with clients
          </Button>
        )}
      </CardContent>
    </Card>
  );
};
