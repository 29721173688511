import React, { useEffect } from "react";
import "react-perfect-scrollbar/dist/css/styles.css";
import "react-mosaic-component/react-mosaic-component.css";
import { ThemeProvider } from "@mui/material";
import { StyledEngineProvider } from "@mui/material/styles";
import { useLocation, useNavigate, useRoutes } from "react-router-dom";
import { SnackbarProvider } from "notistack";
import routes from "./routes";
import { themes } from "./theme";
import { connect } from "react-redux";
import { StoreState } from "./reducers";
import { User } from "./models/User";
import { getUser } from "./actions/userActions";
import { AppState } from "./reducers/app";
import { runTimeConfig } from "./apis";
import { setConfig } from "./actions/appActions";
import { META_ROBOTS_VALUE, SCREEN_PATHS, STORAGE_KEYS } from "./constants";
import { SessionMonitor } from "./components/SessionMonitor";
import { restrictScreenAccess } from "./utils/restrictScreenAccess";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import "./amplify"
import { Helmet } from "react-helmet-async";

interface Props {
  app: AppState;
  user: User;
  getUser: () => void;
  setConfig: (config: any) => void;
}

const _App = ({
  app: { themeKey },
  user,
  getUser,
  setConfig,
}: Props): JSX.Element => {
  const routing = useRoutes(routes);
  const location = useLocation();
  const navigate = useNavigate();
  const authRequired = location.pathname.includes("/app", 0);
  const atMaintenanceScreen = location.pathname.includes(
    SCREEN_PATHS.maintenance,
    0
  );

  useEffect(() => {
    // go get the config on first app load
    (async () => {
      setConfig(
        await runTimeConfig.fetch(
          atMaintenanceScreen,
          window.sessionStorage.getItem(STORAGE_KEYS.BYPASS) || undefined
        )
      );
    })();
  }, []);

  if (authRequired) {
    if (user === null) {
      getUser();
    } else if (user.cognitoUsername === undefined) {
      // empty id indicates not authenticated;
      window.localStorage.setItem(
        STORAGE_KEYS.AUTH_REDIRECT_FROM,
        window.location.pathname
      );
      navigate(SCREEN_PATHS.login);
    }
    // redirect restricted users screen access
    if (user) {
      restrictScreenAccess(user, navigate);
    }
  }

  const showContent = !(authRequired && user === null);
  return (
    // https://mui.com/material-ui/guides/interoperability/#css-injection-order
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={themes[themeKey]}>
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <Helmet>
            <meta name="robots" content={META_ROBOTS_VALUE} />
          </Helmet>
          <SnackbarProvider
            maxSnack={10}
            autoHideDuration={3500}
            anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
          >
            {authRequired && <SessionMonitor />}
            {showContent && routing}
          </SnackbarProvider>
        </LocalizationProvider>
      </ThemeProvider>
    </StyledEngineProvider>
  );
};

const mapStateToProps = ({
  app,
  user,
}: StoreState): { app: AppState; user: User } => {
  return { app, user };
};

export const App = connect(mapStateToProps, { getUser, setConfig })(_App);