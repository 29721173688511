import React from "react";

export const SupportAnchorLink = (): JSX.Element => {
  const getSupportLink = () => {
    return "mailTo:" + process.env.REACT_APP_SUPPORT_EMAIL || "";
  };

  return (
    <span style={{ whiteSpace: "nowrap" }}>
      <a href={getSupportLink()} target="_blank">
        {process.env.REACT_APP_SUPPORT_EMAIL}
      </a>
    </span>
  );
};
