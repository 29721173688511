import React, { useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import {
  Button,
  Dialog,
  Box,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  useTheme,
  useMediaQuery,
  Breakpoint,
  CircularProgress,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { Asset } from "../../../../../models/Asset";
import { AssetDialogHeader } from "./AssetDialogHeader";
import { AssetDialogNavBar } from "./AssetDialogNavBar";
import { AssetDialogForm } from "./AssetDialogForm";
import { Estate } from "../../../../../models/Estate";
import { ConfirmationDialog } from "../../../../shared/dialog/ConfirmationDialog";
import { UnsavedContent } from "../UnsavedContent";

interface Props {
  open: boolean;
  asset?: Asset;
  estate?: Estate;
  headerImageSrc?: string;
  headerImageAlt?: string;
  onComplete: (refresh?: boolean) => void;
  onCancel?: () => void;
  onDelete?: () => void;
  maxWidth?: Breakpoint | false;
}

export const AssetDialog = ({
  open,
  asset,
  estate,
  onComplete,
  onCancel,
  onDelete,
  maxWidth = "lg",
}: Props): JSX.Element => {
  const theme = useTheme();
  const { hash } = useLocation();
  const contentRef = useRef<HTMLElement>();
  const contentSections = useRef<NodeListOf<Element>>();
  const [selectedNavId, setSelectedNavId] = useState("");
  // updating this will trigger an event the form is listening for
  const [formSubmitTrigger, setFormSubmitTrigger] = useState(0);
  const [confirmCancelOpen, setConfirmCancelOpen] = useState(false);

  const isNew = !asset?.id;
  const formTouched = useRef(isNew ? true : false);
  const [formSubmitting, setFormSubmitting] = useState(false);

  const imagesEdited = React.useRef(false);

  useEffect(() => {
    // scroll to section based on url hash updates
    if (hash) {
      scrollToSection(hash);
    }
  }, [hash]);

  useEffect(() => {
    // setup content scroll monitor
    addScrollListener();
    return () => {
      contentRef?.current?.removeEventListener("scroll", contentScrollListener);
    };
  }, [contentRef?.current]);

  const scrollToSection = (hash: string): void => {
    const attributeSection = window.document.getElementById(
      hash.replace("#", "")
    );
    attributeSection?.scrollIntoView({
      behavior: "smooth",
    });
  };

  const addScrollListener = (triesLeft = 3): void => {
    if (triesLeft > 0) {
      // the dialog needs time to open before the listener can be added
      setTimeout(() => {
        if (contentRef?.current) {
          contentRef?.current?.addEventListener(
            "scroll",
            contentScrollListener
          );
          contentSections.current =
            contentRef.current?.querySelectorAll("a.section");
        } else {
          addScrollListener(triesLeft--);
        }
      }, 200);
    }
  };

  const contentScrollListener = (): void => {
    // update selected nav based on content scroll location
    const contentTopAnchor = contentRef.current?.querySelector(
      "a#top"
    ) as HTMLElement;
    const navBar = contentRef.current?.querySelector(
      ".AssetDialogNavBar"
    ) as HTMLElement;

    let topOffset = contentTopAnchor?.offsetTop;
    if (navBar?.clientHeight) {
      // add the height of the navbar that will overlap the content
      topOffset += navBar.clientHeight;
    }
    let lowestSectionId = "";
    contentSections?.current?.forEach((section: any) => {
      if (
        contentRef?.current?.scrollTop &&
        contentRef.current.scrollTop >= section.offsetTop - topOffset
      ) {
        lowestSectionId = section.id;
      }
    });
    setSelectedNavId(lowestSectionId);
  };

  const getNavBar = (): JSX.Element => {
    return (
      <AssetDialogNavBar
        selectedId={selectedNavId}
        onUpdateSelectedId={(id: string) => {
          scrollToSection(id);
          setSelectedNavId(id);
        }}
        sx={{
          my: 3,
          mx: 0,
          top: 0,
          height: 48,
          position: "sticky",
          backgroundColor: "background.paper",
          zIndex: 10,
        }}
        className="AssetDialogNavBar"
      />
    );
  };

  const handleCancel = () => {
    if (formTouched.current) {
      setConfirmCancelOpen(true);
    } else {
      handleClose();
    }
  };

  const handleClose = () => {
    // if the images have been edited, the form will be untouched, but the assets should still refresh upon close
    if (imagesEdited.current) {
      onComplete?.(true); // refresh assets
    } else {
      onCancel?.();
    }
  };

  const isMobileSize = useMediaQuery(theme.breakpoints.down("sm"));
  return (
    <Box>
      {/* unsaved changes notification */}
      <ConfirmationDialog
        open={confirmCancelOpen}
        maxWidth="md"
        description={<UnsavedContent />}
        onConfirm={() => handleClose()}
        confirmText="Exit"
        confirmWide={true}
        onDeny={() => setConfirmCancelOpen(false)}
        denyText="Cancel"
        denyWide={true}
        denyButtonVariant="outlined"
        buttonOrientation="row-reverse"
      />
      <Dialog
        fullScreen={isMobileSize}
        fullWidth
        maxWidth={maxWidth}
        open={open}
        onClose={handleCancel}
        className="noPadding"
      >
        <>
          <DialogTitle component="div" sx={{ color: "inherit", mt: 4 }}>
            <AssetDialogHeader asset={asset} />
            <IconButton
              aria-label="close"
              sx={{
                position: "absolute",
                right: 0,
                top: 0,
                padding: "1rem",
                color: "grey.500",
              }}
              onClick={handleCancel}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent ref={contentRef} sx={{ mb: 0 }}>
            <AssetDialogForm
              asset={asset}
              estate={estate}
              formSubmitTrigger={formSubmitTrigger}
              navBar={getNavBar()}
              onComplete={onComplete}
              onTouched={() => {
                formTouched.current = true;
              }}
              onSubmitting={(isSubmitting) => setFormSubmitting(isSubmitting)}
              onImagesEdited={() => {
                imagesEdited.current = true;
              }}
            />
          </DialogContent>
          {estate?.hasWriteAccess ? (
            <DialogActions
              sx={{
                display: "flex",
                flexDirection: "row-reverse",
                alignItems: "center",
                justifyContent: "center",
                button: {
                  width: 180,
                  mx: 6,
                },
                py: 3,
                borderTop: "solid 2px",
                borderColor: "grey.300",
              }}
            >
              {isNew ? (
                <>
                  <Button
                    onClick={() => {
                      // submit the form embedded in AssetDialogForm
                      setFormSubmitTrigger(formSubmitTrigger + 1);
                    }}
                    variant="contained"
                    autoFocus
                    disabled={formSubmitting}
                    endIcon={
                      formSubmitting ? (
                        <CircularProgress color="inherit" size={16} />
                      ) : undefined
                    }
                  >
                    {formSubmitting ? "Saving" : "Save Asset"}
                  </Button>
                  {onCancel && (
                    <Button
                      onClick={() => setConfirmCancelOpen(true)}
                      variant="outlined"
                    >
                      Cancel
                    </Button>
                  )}
                </>
              ) : (
                <Button
                  onClick={onDelete}
                  variant="text"
                  sx={{
                    textDecoration: "underline",
                    color: "grey.600",
                  }}
                >
                  Delete this Asset
                </Button>
              )}
            </DialogActions>
          ) : (
            <></>
          )}
        </>
      </Dialog>
    </Box>
  );
};
