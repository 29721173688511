import React from "react";
import {
  Card,
  CardContent,
  CardHeader,
  colors,
  Grid,
  Typography,
  Theme,
} from "@mui/material";
import { makeStyles } from "tss-react/mui";
import CircularProgress from "@mui/material/CircularProgress";


const useStyles = makeStyles()((theme: Theme) => ({
  // TODO: complete, or remove attempt at shimmer seen here: https://medium.com/@dhilipkmr/the-loading-shimmer-f7129ac41894
  "@keyframes fullView": {
    "100%": {
      width: "100%",
    },
  },
  "@keyframes shimmer": {
    "0%": {
      backgroundPosition: "-1000px 0",
    },
    "100%": {
      backgroundPosition: "1000px 0",
    },
  },
  root: {
    height: "100%",
    // animation: "fullView 0.5s forwards linear",
  },
  animate: {
    animation: "shimmer 2s infinite",
    background:
      "linear-gradient(to right, #eff1f3 4%, #e2e2e2 25%, #eff1f3 36%)",
    backgroundSize: "1000px 100%",
  },
  avatar: {
    backgroundColor: colors.red[600],
    height: 56,
    width: 56,
  },
  differenceIcon: {
    color: colors.red[900],
  },
  differenceValue: {
    color: colors.red[900],
    marginRight: theme.spacing(1),
  },
}));

interface Props {
  title?: string;
  subTitle?: string;
  content?: string;
  loading?: boolean;
}

export const SummaryCard = ({
  title,
  subTitle,
  content,
  loading,
}: Props): JSX.Element => {
  const { classes } = useStyles();

  return (
    <Card className={classes.root}>
      <CardHeader title={title} subheader={subTitle} />
      <CardContent style={{ paddingBottom: 12 }}>
        <Grid container justifyContent="space-between" spacing={3}>
          <Grid item style={{ width: "100%" }}>
            <Typography color="textPrimary" variant="h2">
              {loading !== true ? (
                content
              ) : (
                <CircularProgress color="primary" />
              )}
            </Typography>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};
