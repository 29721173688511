// database field names
export type TaskAnswerJSON = {
  id: number;
  task_id: number;
  estate_id: number;
  bool_answer?: number;
  text_answer?: string;
  task_date?: string;
  status?: string; // change to enum?
  notes?: string;
};

export class TaskAnswer {
  constructor(
    public id?: number,
    public taskId?: number,
    public estateId?: number,
    public boolAnswer?: number,
    public textAnswer?: string,
    public taskDate?: string,
    public status?: string,
    public notes?: string
  ) {}

  static fromJSON(json: TaskAnswerJSON): TaskAnswer {
    return new TaskAnswer(
      json.id,
      json.task_id,
      json.estate_id,
      json.bool_answer,
      json.text_answer,
      json.task_date, // convert to date
      json.status,
      json.notes
    );
  }

  // clone
  static from(taskAnswer: TaskAnswer): TaskAnswer {
    return new TaskAnswer(
      taskAnswer.id,
      taskAnswer.taskId,
      taskAnswer.estateId,
      taskAnswer.boolAnswer,
      taskAnswer.textAnswer,
      taskAnswer.taskDate, // convert to date
      taskAnswer.status,
      taskAnswer.notes
    );
  }
}
