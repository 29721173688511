import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Checkbox,
  IconButton,
  TableContainer,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  FormControlLabel,
  Tooltip,
  Typography,
  Link,
  Card,
  CardActionArea,
  TablePagination,
  Chip,
  useTheme,
  Theme,
} from "@mui/material";
import { makeStyles } from "tss-react/mui";
import { AppTableWrapper } from "../../shared/AppTableWrapper";
import { TableBodyLoader } from "../../shared/TableBodyLoader";
import { ZeroStateBody } from "../../shared/ZeroStateBody";
import { AtticusForm } from "../../../models/AtticusForm";
import moment from "moment/moment";
import { formsApi, logger } from "../../../apis";
import CancelIcon from "@mui/icons-material/Cancel";
import DescriptionIcon from "@mui/icons-material/Description";
import WatermarkIcon from "@mui/icons-material/Opacity";
import ThumbnailIcon from "@mui/icons-material/Image";
import AccountTreeIcon from "@mui/icons-material/AccountTree";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import { ConfirmationDialog } from "../../shared/dialog/ConfirmationDialog";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import LastPageIcon from "@mui/icons-material/LastPage";
import { connect } from "react-redux";
import { StoreState } from "../../../reducers";
import { FormType } from "../../../models/FormType";
import { Language } from "../../../models/Language";
import AtticusFormViewDialog, {
  PdfFileChoice,
} from "../../shared/dialog/AtticusFormViewDialog";
import { useNavigate } from "react-router-dom";

const useStyles = makeStyles()((theme: Theme) => ({
  tablePaginationActions: {
    flexShrink: 0,
    marginLeft: theme.spacing(2.5),
  },
  tableHead: {
    "& a": {
      padding: 0,
    },
    "& a span": {
      justifyContent: "flex-start",
    },
  },
  container: {
    maxHeight: 800,
    position: "relative",
  },
  thumbnailContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  thumbnailCard: {
    width: "max-content",
    borderRadius: "2px",
    height: "100px",
  },
  thumbnail: {
    height: "100px",
  },
  tableFooter: {
    display: "flex",
    marginBottom: "-1rem",
    [theme.breakpoints.down("lg")]: {
      flexDirection: "column-reverse",
    },
  },
  tableToggles: {
    display: "flex",
    alignItems: "center",
    "&> *": {
      marginRight: theme.spacing(2.5),
    },
  },

  tablePagination: {
    flex: 1,
    display: "flex",
    alignItems: "center",
    justifyContent: "end",
    "& .MuiToolbar-root": {
      minHeight: "0",
    },
  },
  s3StatusWrapper: {
    "&> :not(:last-child)": {
      marginBottom: theme.spacing(0.5),
    },
  },
}));

enum SortBy {
  id = "id",
}

interface Props {
  formTypes?: FormType[];
  languages?: Language[];
  atticusForms?: AtticusForm[];
  // onImport?: (importType: ImportType, params: any) => Promise<boolean>;
  onRefresh: () => void;
  loadingForms: boolean;
}
interface TablePaginationActionsProps {
  count: number;
  page: number;
  rowsPerPage: number;
  onPageChange: (
    event: React.MouseEvent<HTMLButtonElement>,
    newPage: number
  ) => void;
}

function TablePaginationActions(props: TablePaginationActionsProps) {
  const { classes } = useStyles();
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <div className={classes.tablePaginationActions}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </div>
  );
}

const _AtticusFormsTable = ({
  formTypes,
  languages,
  atticusForms,
  onRefresh,
}: // loadingForms,
Props): JSX.Element => {
  const { classes } = useStyles();
  const navigate = useNavigate();
  const docsLoading = atticusForms === undefined;
  const showZero = atticusForms !== null && atticusForms?.length === 0;
  const [selectedDocs, setSelectedDocs] = useState<AtticusForm[]>();

  const [isDeleteBatchConfirmOpen, setIsDeleteBatchConfirmOpen] =
    useState(false);

  const [status, setStatus] = useState("");
  const [loading, setLoading] = useState(false);
  const [fileLoading, setFileLoading] = useState(false);

  const [pdfViewerOpen, setPdfViewerOpen] = useState(false);
  const [pdfViewerFileChoice, setPdfViewerFileChoice] = useState<PdfFileChoice>(
    PdfFileChoice.PROCESSED
  );

  const [dialogForm, setDialogForm] = useState<AtticusForm>();

  const togglePdfViewer = () => {
    if (pdfViewerOpen === true) {
      setDialogForm(undefined);
    }
    setPdfViewerOpen(!pdfViewerOpen);
  };

  const [isDeleteConfirmOpen, setIsDeleteConfirmOpen] = useState(false);
  const [deleteDoc, setDeleteDoc] = useState<AtticusForm>();
  const [deleteDocName, setDeleteDocName] = useState<string>();

  // const [titleOrFormIdFilter] = useState<string>(""); // setTitleOrFormIdFilter
  // const [sortBy, setSortBy] = useState<SortBy>(SortBy.id);

  const [showThumbnails, setShowThumbnails] = React.useState(true);

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleDelete = async (atticusForm?: AtticusForm) => {
    if (atticusForm && (await formsApi.deleteForm(atticusForm.id))) {
      onRefresh();
    } else {
      setStatus("Error: deleting form");
    }
    setIsDeleteConfirmOpen(false);
  };

  const handleDeleteBatch = async (atticusForms?: AtticusForm[]) => {
    if (
      atticusForms &&
      (await formsApi.deleteForms(atticusForms.map((f) => f.id)))
    ) {
      setSelectedDocs(undefined);
      onRefresh();
    } else {
      setStatus("Error: deleting form");
    }
    setIsDeleteBatchConfirmOpen(false);
  };

  // const twoWayMatch = (
  //   matchString: string,
  //   subjectString?: string
  // ): boolean => {
  //   if (!subjectString) return false;
  //   return (
  //     subjectString.toLowerCase().includes(matchString.toLowerCase()) ||
  //     matchString.toLowerCase().includes(subjectString.toLowerCase())
  //   );
  // };

  // const filteredAtticusDocs = atticusForms?.filter(
  //   (atticusForm: AtticusForm) => {
  //     // lang filter is active and the code doesn't match
  //     // if (
  //     //   langFilter !== langDefault &&
  //     //   atticusForm?.languageCode?.toLowerCase() !== langFilter
  //     // ) {
  //     //   return false;
  //     // }
  //     // title filter is active and the string doesn't match
  //     if (titleOrFormIdFilter !== "") {
  //       const titleMatch = twoWayMatch(
  //         titleOrFormIdFilter,
  //         atticusForm?.title.original
  //       );
  //       const formIdMatch = twoWayMatch(
  //         titleOrFormIdFilter,
  //         atticusForm?.governmentId
  //       );
  //       if (!titleMatch && !formIdMatch) {
  //         return false;
  //       }
  //     }
  //     return true;
  //   }
  // );

  useEffect(() => {
    setPage(0);
  }, [atticusForms]);

  // const sortedAtticusDocs = filteredAtticusDocs
  //   ? filteredAtticusDocs?.sort((doc1: AtticusForm, doc2: AtticusForm) => {
  //       const doc1Comp = doc1[sortBy];
  //       const doc2Comp = doc2[sortBy];
  //       if (!doc1Comp || !doc2Comp) return 0;
  //       return doc1Comp < doc2Comp ? -1 : 1;
  //     })
  //   : filteredAtticusDocs;

  interface Column {
    displayName: string;
    sortKey?: SortBy;
    disabled?: boolean;
  }

  const columns: Column[] = [
    {
      displayName: "ID",
      // sortKey: SortBy.id,
    },
    {
      displayName: "Thumbnail",
      disabled: !showThumbnails,
    },
    {
      displayName: "Geography",
    },
    {
      displayName: "Title",
    },
    {
      displayName: "Government ID",
    },
    {
      displayName: "Language",
    },
    {
      displayName: "Description",
    },
    {
      displayName: "Scraped File Ext",
    },
    {
      displayName: "File Name",
    },
    {
      displayName: "File Source URL",
    },
    {
      displayName: "Form Type",
    },
    {
      displayName: "Form Detail URL",
    },
    {
      displayName: "Form Category (From Scrape)",
    },
    {
      displayName: "Statutes",
    },
    {
      displayName: "Published Date",
    },
    {
      displayName: "Revision Date",
    },
    {
      displayName: "Effective Date",
    },
    {
      displayName: "Scrape Start",
    },
    {
      displayName: "Imported By",
    },
    {
      displayName: "Last Edited By",
    },

    {
      displayName: "Contentful Form Page Template ID",
    },
    {
      displayName: "Fillable?",
    },
    {
      displayName: "Created At",
    },
    {
      displayName: "Updated At",
    },
    {
      displayName: "Deleted At",
    },
    {
      displayName: "Authority",
    },
    {
      displayName: "Form Status",
    },
    {
      displayName: "S3 Status",
    },
    {
      displayName: "Actions",
    },
  ];

  const numColumns = columns.length + 1;

  return (
    <>
      <AppTableWrapper noPad minWidth={!showZero ? 300 : 1}>
        <Box>
          {showZero ? (
            <ZeroStateBody text="No atticus forms returned" />
          ) : (
            <>
              <AtticusFormViewDialog
                open={pdfViewerOpen}
                onClose={togglePdfViewer}
                form={dialogForm}
                fileChoice={pdfViewerFileChoice}
              />
              <ConfirmationDialog
                open={isDeleteConfirmOpen}
                description={deleteDocName || ""}
                question="Are you sure you want to delete form?"
                onConfirm={() => handleDelete(deleteDoc)}
                onDeny={() => {
                  setDeleteDoc(undefined);
                  setDeleteDocName("");
                  setIsDeleteConfirmOpen(false);
                }}
              />
              <ConfirmationDialog
                open={isDeleteBatchConfirmOpen}
                description={
                  selectedDocs?.map((doc) => doc.governmentId).join(", ") || ""
                }
                question="Are you sure you want to delete the selected forms?"
                onConfirm={() => handleDeleteBatch(selectedDocs)}
                onDeny={() => {
                  setIsDeleteBatchConfirmOpen(false);
                }}
              />
              {/* {atticusForms?.length ? (
              <Box
                display="flex"
                flexDirection="row"
                alignItems="center"
                my={1}
              >
                <Box mx={1}>
                  <Typography>Filters: </Typography>
                </Box>
                <TextField
                  id="text"
                  label="title or formId"
                  variant="outlined"
                  size="small"
                  style={{ minWidth: 460 }}
                  value={titleOrFormIdFilter}
                  onChange={(event) => {
                    setTitleOrFormIdFilter(event.target.value as string);
                  }}
                />
                <Box mx={1}>
                  <Select
                    name="formType"
                    value={langFilter}
                    onChange={(
                      event
                    ) => {
                      setLangFilter(event.target.value as string);
                    }}
                    variant="outlined"
                  >
                    <MenuItem value={langDefault} key="lang_all">
                      {langDefault}
                    </MenuItem>
                    {Object.entries(LanguageCodes).map(
                      ([lang, code]: [lang: string, code: string]) => {
                        return (
                          <MenuItem value={code} key={`lang_${code}`}>
                            {lang}
                          </MenuItem>
                        );
                      }
                    )}
                  </Select>
                </Box>
                <Box>Number of forms: {filteredAtticusDocs?.length || 0}</Box>
              </Box>
            ) : (
              <></>
            )} */}
              <TableContainer className={classes.container}>
                <Table stickyHeader>
                  <TableHead className={classes.tableHead}>
                    <TableRow className="dense">
                      <TableCell>
                        <Checkbox
                          checked={
                            selectedDocs?.length === atticusForms?.length &&
                            selectedDocs?.length !== 0 &&
                            selectedDocs?.length !== undefined
                          }
                          onChange={(
                            event: React.ChangeEvent<HTMLInputElement>
                          ) => {
                            setStatus("");
                            if (event.target.checked) {
                              // select all
                              setSelectedDocs(atticusForms);
                            } else {
                              // un-select all
                              setSelectedDocs(undefined);
                            }
                          }}
                          inputProps={{ "aria-label": "selected all" }}
                          title="select/unselect all"
                        />
                      </TableCell>
                      {columns.map((column: Column, index) => {
                        if (!column.disabled) {
                          return (
                            <TableCell key={index}>
                              <Button
                                component="a"
                                disabled={!column.sortKey}
                                // onClick={() => {
                                //   if (column.sortKey) setSortBy(column.sortKey);
                                // }}
                              >
                                {column.displayName}
                              </Button>
                            </TableCell>
                          );
                        }
                      })}
                    </TableRow>
                  </TableHead>
                  {docsLoading ? (
                    <>
                      <TableBodyLoader colSpan={numColumns} rows={1} />
                    </>
                  ) : !atticusForms || atticusForms.length == 0 ? (
                    <>Nothing</>
                  ) : (
                    <>
                      <TableBody>
                        {(rowsPerPage > 0
                          ? atticusForms.slice(
                              page * rowsPerPage,
                              page * rowsPerPage + rowsPerPage
                            )
                          : atticusForms
                        )?.map((atticusForm: AtticusForm) => {
                          return (
                            <TableRow key={atticusForm?.id} className="dense">
                              <TableCell>
                                <Checkbox
                                  checked={
                                    selectedDocs?.includes(atticusForm) || false
                                  }
                                  onChange={(
                                    event: React.ChangeEvent<HTMLInputElement>
                                  ) => {
                                    setStatus("");
                                    if (event.target.checked) {
                                      // add it
                                      setSelectedDocs(
                                        selectedDocs
                                          ? [...selectedDocs, atticusForm]
                                          : [atticusForm]
                                      );
                                    } else {
                                      // remove it
                                      setSelectedDocs(
                                        selectedDocs?.filter(
                                          (doc: AtticusForm) =>
                                            doc?.id !== atticusForm?.id
                                        )
                                      );
                                    }
                                  }}
                                  inputProps={{ "aria-label": "selected" }}
                                />
                              </TableCell>
                              <TableCell>{atticusForm?.id || "--"}</TableCell>
                              {showThumbnails && (
                                <TableCell>
                                  {atticusForm?.s3Urls?.thumbnailFile ? (
                                    <Box className={classes.thumbnailContainer}>
                                      <Card className={classes.thumbnailCard}>
                                        <CardActionArea
                                          onClick={async () => {
                                            setFileLoading(true);
                                            setPdfViewerFileChoice(
                                              PdfFileChoice.PROCESSED
                                            );
                                            setDialogForm(atticusForm);
                                            togglePdfViewer();
                                            setFileLoading(false);
                                          }}
                                        >
                                          <img
                                            className={classes.thumbnail}
                                            src={
                                              atticusForm.s3Urls?.thumbnailFile
                                            }
                                          />
                                        </CardActionArea>
                                      </Card>
                                    </Box>
                                  ) : (
                                    "--"
                                  )}
                                </TableCell>
                              )}
                              <TableCell>
                                {`${atticusForm?.geography?.fullName}` || "--"}
                              </TableCell>
                              <TableCell>
                                {atticusForm?.title?.original || "--"}
                              </TableCell>
                              <TableCell>
                                {atticusForm?.governmentId || "--"}
                              </TableCell>
                              <TableCell>
                                {languages?.find(
                                  (lang) => atticusForm?.languageId == lang.id
                                )?.name || "--"}
                              </TableCell>
                              <TableCell>
                                {atticusForm?.description?.original || "--"}
                              </TableCell>
                              <TableCell>
                                {atticusForm?.scrapedFileExt || "--"}
                              </TableCell>
                              <TableCell>
                                {atticusForm?.fileName || "--"}
                              </TableCell>
                              <TableCell>
                                {atticusForm?.fileSourceUrl || "--"}
                              </TableCell>
                              <TableCell>
                                {formTypes?.find(
                                  (ft) => atticusForm?.formTypeId == ft.id
                                )?.name || "--"}
                              </TableCell>
                              <TableCell>
                                {atticusForm?.formDetailUrl || "--"}
                              </TableCell>
                              <TableCell>
                                {atticusForm?.formCategoryFromScrape || "--"}
                              </TableCell>
                              <TableCell>
                                {atticusForm?.statutes
                                  ? atticusForm?.statutes.join(", ")
                                  : "--"}
                              </TableCell>
                              <TableCell>
                                {atticusForm?.publishedDate
                                  ? moment(
                                      new Date(atticusForm.publishedDate)
                                    ).format("MM/DD/YYYY")
                                  : "--"}
                              </TableCell>
                              <TableCell>
                                {atticusForm?.publishedDate
                                  ? moment(
                                      new Date(atticusForm.publishedDate)
                                    ).format("MM/DD/YYYY")
                                  : "--"}
                              </TableCell>
                              <TableCell>
                                {atticusForm?.effectiveDate
                                  ? moment(
                                      new Date(atticusForm.effectiveDate)
                                    ).format("MM/DD/YYYY")
                                  : "--"}
                              </TableCell>
                              <TableCell>
                                {atticusForm?.scrapeStart || "--"}
                              </TableCell>
                              <TableCell>
                                {`${atticusForm?.importedBy?.profile?.fullName} (ID: ${atticusForm?.importedBy?.id})` ||
                                  "--"}
                              </TableCell>
                              <TableCell>
                                {`${atticusForm?.lastEditedBy?.profile?.fullName} (ID: ${atticusForm?.lastEditedBy?.id})` ||
                                  "--"}
                              </TableCell>
                              <TableCell>
                                {atticusForm?.contentfulFormPageTemplateId ||
                                  "--"}
                              </TableCell>
                              <TableCell>
                                {atticusForm?.isFillable === true
                                  ? "Yes"
                                  : atticusForm?.isFillable === false
                                  ? "No"
                                  : "--"}
                              </TableCell>

                              <TableCell>
                                {atticusForm?.createdAt
                                  ? atticusForm.getLocalCreatedAt()
                                  : "--"}
                              </TableCell>
                              <TableCell>
                                {atticusForm?.updatedAt
                                  ? atticusForm.getLocalUpdatedAt()
                                  : "--"}
                              </TableCell>
                              <TableCell>
                                {atticusForm?.deletedAt
                                  ? atticusForm.getLocalDeletedAt()
                                  : "--"}
                              </TableCell>
                              <TableCell>
                                {atticusForm?.authority ? (
                                  <Tooltip
                                    title={atticusForm.authority?.description}
                                  >
                                    <span style={{ cursor: "default" }}>
                                      {atticusForm.authority?.name}
                                    </span>
                                  </Tooltip>
                                ) : (
                                  "--"
                                )}
                              </TableCell>
                              <TableCell>
                                {atticusForm?.formStatus?.name || "--"}
                              </TableCell>
                              <TableCell>
                                <Box className={classes.s3StatusWrapper}>
                                  <Chip
                                    size="small"
                                    icon={<DescriptionIcon />}
                                    label={
                                      atticusForm?.s3Keys?.processedPdf
                                        ? "Processed in S3"
                                        : "Processed Missing"
                                    }
                                    color={
                                      atticusForm?.s3Keys?.processedPdf
                                        ? "primary"
                                        : "secondary"
                                    }
                                    disabled={
                                      fileLoading ||
                                      !atticusForm.s3Keys?.processedPdf
                                    }
                                    onClick={async () => {
                                      setFileLoading(true);
                                      setPdfViewerFileChoice(
                                        PdfFileChoice.PROCESSED
                                      );
                                      setDialogForm(atticusForm);
                                      togglePdfViewer();
                                      setFileLoading(false);
                                    }}
                                  />
                                  <Chip
                                    size="small"
                                    icon={<WatermarkIcon />}
                                    label={
                                      atticusForm?.s3Keys?.watermarkedPdf
                                        ? "Watermarked in S3"
                                        : "Watermarked Missing"
                                    }
                                    color={
                                      atticusForm?.s3Keys?.watermarkedPdf
                                        ? "primary"
                                        : "secondary"
                                    }
                                    disabled={
                                      fileLoading ||
                                      !atticusForm.s3Urls?.watermarkedPdf
                                    }
                                    onClick={async () => {
                                      setFileLoading(true);
                                      setPdfViewerFileChoice(
                                        PdfFileChoice.WATERMARKED
                                      );
                                      setDialogForm(atticusForm);
                                      togglePdfViewer();
                                      setFileLoading(false);
                                    }}
                                  />
                                  <Chip
                                    size="small"
                                    icon={<ThumbnailIcon />}
                                    label={
                                      atticusForm?.s3Keys?.thumbnailFile
                                        ? "Thumbnail in S3"
                                        : "Thumbnail Missing"
                                    }
                                    disabled={
                                      fileLoading ||
                                      !atticusForm.s3Urls?.thumbnailFile
                                    }
                                    color={
                                      atticusForm?.s3Keys?.thumbnailFile
                                        ? "primary"
                                        : "secondary"
                                    }
                                    onClick={async () => {
                                      setFileLoading(true);
                                      setPdfViewerFileChoice(
                                        PdfFileChoice.THUMBNAIL
                                      );
                                      setDialogForm(atticusForm);
                                      togglePdfViewer();
                                      setFileLoading(false);
                                    }}
                                  />
                                </Box>
                              </TableCell>
                              <TableCell>
                                <Box display="flex" flexDirection="row">
                                  <Tooltip
                                    title={
                                      atticusForm?.latestInterfaceId
                                        ? "Continue editing Form Interface"
                                        : "Create Form Interface"
                                    }
                                  >
                                    <span style={{ cursor: "default" }}>
                                      <Link
                                        onClick={() => {
                                          navigate(
                                            `/app/admin/forms/interface/${atticusForm?.id}`
                                          );
                                        }}
                                      >
                                        <IconButton
                                          size="small"
                                          aria-label="view"
                                          disabled={fileLoading}
                                          color={
                                            atticusForm?.latestInterfaceId
                                              ? "secondary"
                                              : "default"
                                          }
                                        >
                                          <AccountTreeIcon />
                                        </IconButton>
                                      </Link>
                                    </span>
                                  </Tooltip>
                                  <Tooltip title="copy s3 file key">
                                    <span style={{ cursor: "default" }}>
                                      <IconButton
                                        size="small"
                                        aria-label="view"
                                        disabled={fileLoading}
                                        onClick={async () => {
                                          if (
                                            atticusForm?.s3Keys?.jsonFile &&
                                            navigator.clipboard
                                          ) {
                                            navigator.clipboard.writeText(
                                              atticusForm?.s3Keys.jsonFile
                                            );
                                          }
                                        }}
                                        color="default"
                                      >
                                        <FileCopyIcon />
                                      </IconButton>
                                    </span>
                                  </Tooltip>
                                  <Tooltip title="delete">
                                    <span style={{ cursor: "default" }}>
                                      <IconButton
                                        size="small"
                                        aria-label="delete"
                                        onClick={() => {
                                          setStatus("");
                                          setDeleteDocName(
                                            atticusForm?.title.original
                                          );
                                          setDeleteDoc(atticusForm);
                                          setIsDeleteConfirmOpen(true);
                                        }}
                                        color="primary"
                                      >
                                        <CancelIcon />
                                      </IconButton>
                                    </span>
                                  </Tooltip>
                                </Box>
                              </TableCell>
                            </TableRow>
                          );
                        })}
                      </TableBody>
                    </>
                  )}
                </Table>
              </TableContainer>
              <Box className={classes.tableFooter}>
                <Box className={classes.tableToggles}>
                  <Chip
                    color="default"
                    variant="outlined"
                    label={`Form Count: ${atticusForms?.length || 0}`}
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={showThumbnails}
                        onChange={(
                          event: React.ChangeEvent<HTMLInputElement>
                        ) => {
                          setShowThumbnails(event.target.checked);
                        }}
                        name="showThumbnails"
                        color="primary"
                      />
                    }
                    label="Show Thumbnails?"
                  />
                </Box>
                <TablePagination
                  className={classes.tablePagination}
                  rowsPerPageOptions={[
                    25,
                    50,
                    100,
                    { label: "All", value: -1 },
                  ]}
                  component="div"
                  count={atticusForms?.length || 0}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  SelectProps={{
                    inputProps: { "aria-label": "rows per page" },
                    native: true,
                  }}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  ActionsComponent={TablePaginationActions}
                />
              </Box>
            </>
          )}
        </Box>
      </AppTableWrapper>
      {selectedDocs?.length ? (
        <Box m={2}>
          <Typography variant="body2">
            🦉 This will only update the json metadata of a form. To re-import
            pdf file, delete the form and use an import option below.
          </Typography>
          <br />
          <Button
            variant="contained"
            color="primary"
            type="button"
            disabled={loading}
            onClick={async () => {
              setStatus("");
              try {
                setIsDeleteBatchConfirmOpen(true);
              } catch (error: any) {
                logger.error(error as Error);
              }
              setLoading(false);
            }}
            style={{ marginLeft: 8 }}
          >
            Delete selected forms
          </Button>
          <Typography
            color={
              status?.toLowerCase().includes("error") ? "error" : "inherit"
            }
          >
            {status}
          </Typography>
        </Box>
      ) : (
        <></>
      )}
    </>
  );
};

const mapStateToProps = ({
  formTypes,
  languages,
}: StoreState): {
  formTypes: FormType[];
  languages: Language[];
} => {
  return {
    formTypes,
    languages,
  };
};

export const AtticusFormsTable = connect(mapStateToProps)(_AtticusFormsTable);
