import React, { useEffect, useState } from "react";
import PerfectScrollbar from "react-perfect-scrollbar";
import {
  Box,
  Button,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Theme,
  Typography,
} from "@mui/material";
import { makeStyles } from "tss-react/mui";
import { AppTableWrapper } from "../../shared/AppTableWrapper";
import { TableBodyLoader } from "../../shared/TableBodyLoader";
import { ZeroStateBody } from "../../shared/ZeroStateBody";
import { probateApi } from "../../../apis";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { ProbateType } from "../../../models/ProbateType";
import { AtticusForm, FormPacket } from "../../../models/AtticusForm";
import { ProbateFormRow } from "./ProbateFormRow";
import AtticusFormViewDialog, {
  PdfFileChoice,
} from "../../shared/dialog/AtticusFormViewDialog";
import { State } from "../../../models/Geography";
import { Language } from "../../../models/Language";
import { CountryStateSelect } from "../../shared/CountryStateSelect";
import { StoreState } from "../../../reducers";
import { connect } from "react-redux";

const useStyles = makeStyles()((theme: Theme) => ({
  root: {
    position: "relative",
  },
  tableHead: {
    "& a": {
      padding: 0,
    },
    "& a span": {
      justifyContent: "flex-start",
    },
  },
  formRow: {
    backgroundColor: theme.palette.background.paper,
  },
}));

interface Props {
  probateTypes?: ProbateType[];
  languages?: Language[];
  onExport?: (state?: State) => Promise<void>;
  reloadEvent?: number;
}

const _ProbatesTypesTable = ({
  probateTypes,
  languages,
  onExport,
  reloadEvent,
}: Props): JSX.Element => {
  const { classes } = useStyles();
  const docsLoading = probateTypes === undefined;
  const showZero = probateTypes !== null && probateTypes?.length === 0;

  const [state, setState] = useState<State | undefined>();
  const [probatesExpanded, setProbatesExpanded] = useState<number[]>([]);
  const [formPackets, setFormPackets] = useState<FormPacket[]>([]);

  const [pdfViewerOpen, setPdfViewerOpen] = useState(false);
  const [pdfViewerFileChoice, setPdfViewerFileChoice] = useState<PdfFileChoice>(
    PdfFileChoice.PROCESSED
  );

  useEffect(() => {
    setProbatesExpanded([]);
  }, [reloadEvent]);

  const [dialogForm, setDialogForm] = useState<AtticusForm>();

  const togglePdfViewer = () => {
    if (pdfViewerOpen) {
      setDialogForm(undefined);
    }
    setPdfViewerOpen(!pdfViewerOpen);
  };

  const handleShowForm = (form: AtticusForm): void => {
    setPdfViewerFileChoice(PdfFileChoice.PROCESSED);
    setDialogForm(form);
    togglePdfViewer();
  };

  const toggleProbateOpen = async (probateTypeId?: number): Promise<void> => {
    if (!probateTypeId) return;
    if (probatesExpanded.includes(probateTypeId)) {
      // close it
      setProbatesExpanded(
        probatesExpanded.filter((id: number) => id !== probateTypeId)
      );
    } else {
      // fetch forms
      await fetchForms(probateTypeId);
      // open it
      setProbatesExpanded([...probatesExpanded, probateTypeId]);
    }
  };

  const renderFormsRows = (
    probateType?: ProbateType,
    display = false
  ): JSX.Element | JSX.Element[] | undefined => {
    if (!probateType?.id) return;
    const probateForms = formPackets.filter(
      (formPacket: FormPacket) => formPacket.probateTypeId === probateType.id
    );
    let rows: JSX.Element[] = [];
    if (probateForms?.length) {
      if (probateForms[0]?.forms?.length) {
        probateForms[0].forms.sort((a: AtticusForm, b: AtticusForm) => {
          const aLang = languages?.find((lang) => a.languageId == lang.id);
          const bLang = languages?.find((lang) => b.languageId == lang.id);
          return (aLang?.code && bLang?.code && aLang?.code > bLang?.code) ? 1 : -1;
        });
        rows = probateForms[0].forms.map((form: AtticusForm, index: number) => {
          return (
            <ProbateFormRow
              key={`form_${form.id}_${Math.floor(Math.random() * 1000)}`}
              form={form}
              probateType={probateType}
              display={display}
              showHeader={index === 0}
              refresh={() => {
                fetchForms(probateType.id);
              }}
              onShowForm={handleShowForm}
            />
          );
        });
      } else {
        rows.push(
          <TableRow
            key={`no_forms_${probateType.id}`}
            className={`dense ${classes.formRow}`}
            style={{
              display: display ? "table-row" : "none",
            }}
          >
            <TableCell
              colSpan={5}
              style={{
                textAlign: "center",
              }}
            >
              no forms
            </TableCell>
          </TableRow>
        );
      }
      // add a from row
      // rows.push(
      //   <TableRow
      //     key={`form_add_${probateType.id}`}
      //     className="dense"
      //     style={{
      //       display: display ? "table-row" : "none",
      //       backgroundColor: "#E7E9E9",
      //     }}
      //   >
      //     <TableCell />
      //     <TableCell
      //       colSpan={4}
      //       style={{
      //         textAlign: "right",
      //       }}
      //     >
      //       <IconButton
      //         size="small"
      //         aria-label="add form"
      //         onClick={() => console.log("add from")}
      //       >
      //         <AddIcon />
      //       </IconButton>
      //     </TableCell>
      //   </TableRow>
      // );
      return rows;
    }
  };

  const fetchForms = async (probateTypeId?: number): Promise<void> => {
    if (!probateTypeId) return;
    const forms = await probateApi.fetchFormsByProbateType(probateTypeId);
    const packet: FormPacket = {
      probateTypeId,
      forms,
    };
    const packetIndex = formPackets.findIndex(
      (packet: FormPacket) => packet.probateTypeId === probateTypeId
    );
    if (packetIndex === -1) {
      // add the packet
      setFormPackets([...formPackets, packet]);
    } else {
      // update the packet
      setFormPackets([
        ...formPackets.filter(
          (packet: FormPacket) => packet.probateTypeId !== probateTypeId
        ),
        packet,
      ]);
    }
  };

  const numColumns = 11;

  const filteredProbateTypes = state?.id
    ? probateTypes?.filter(
        (probateType: ProbateType) => probateType.stateId === state?.id
      )
    : probateTypes;

  return (
    <div className={classes.root}>
      <Box
        style={{
          display: "flex",
          margin: "0 16px 8px",
        }}
      >
        <CountryStateSelect
          initCountryId={1}
          onSelectCountry={() => {
            // if country is re-selected, clear state
            setState(undefined);
          }}
          onSelectState={(state?: State) => setState(state)}
        />
      </Box>
      <PerfectScrollbar>
        <AppTableWrapper minWidth={!showZero ? 300 : 1}>
          {showZero ? (
            <ZeroStateBody text="No probates returned" />
          ) : (
            <>
              <AtticusFormViewDialog
                open={pdfViewerOpen}
                onClose={togglePdfViewer}
                form={dialogForm}
                fileChoice={pdfViewerFileChoice}
              />
              <Table>
                <TableHead className={classes.tableHead}>
                  <TableRow className="dense">
                    <TableCell>id</TableCell>
                    <TableCell>probate type</TableCell>
                    <TableCell>state</TableCell>
                    <TableCell>{""}</TableCell>
                    <TableCell>{""}</TableCell>
                    <TableCell />
                  </TableRow>
                </TableHead>
                {docsLoading ? (
                  <TableBodyLoader colSpan={numColumns} rows={1} />
                ) : (
                  <TableBody>
                    {filteredProbateTypes?.length ? (
                      filteredProbateTypes?.map((probateType: ProbateType) => {
                        return probateType?.id ? (
                          <React.Fragment key={probateType.id}>
                            <TableRow
                              hover
                              className="clickable"
                              onClick={() => {
                                toggleProbateOpen(probateType.id);
                              }}
                            >
                              <TableCell>{probateType.id}</TableCell>
                              <TableCell>{probateType?.name || "--"}</TableCell>
                              <TableCell>{probateType?.stateName}</TableCell>
                              <TableCell />
                              <TableCell />
                              <TableCell>
                                <IconButton size="small" aria-label="expand">
                                  {probatesExpanded.includes(probateType.id) ? (
                                    <KeyboardArrowUpIcon />
                                  ) : (
                                    <KeyboardArrowDownIcon />
                                  )}
                                </IconButton>
                              </TableCell>
                            </TableRow>
                            {renderFormsRows(
                              probateType,
                              probatesExpanded.includes(probateType.id)
                            )}
                          </React.Fragment>
                        ) : null;
                      })
                    ) : (
                      <TableRow className="no-background">
                        <TableCell colSpan={numColumns}>
                          <Typography align="center">
                            {state?.id === undefined
                              ? "Select a State/Province ☝️"
                              : `There are no probate types for this ${state?.name} yet`}
                          </Typography>
                        </TableCell>
                      </TableRow>
                    )}
                    {filteredProbateTypes?.length && state?.id ? (
                      <TableRow className="no-background">
                        <TableCell
                          colSpan={numColumns}
                          style={{ textAlign: "center" }}
                        >
                          <Button
                            variant="contained"
                            onClick={() => onExport && onExport(state)}
                            color="primary"
                          >
                            Export Mapping
                          </Button>
                        </TableCell>
                      </TableRow>
                    ) : (
                      <></>
                    )}
                  </TableBody>
                )}
              </Table>
            </>
          )}
        </AppTableWrapper>
      </PerfectScrollbar>
    </div>
  );
};

const mapStateToProps = ({
  languages,
}: StoreState): {
  languages: Language[];
} => {
  return { languages };
};

export const ProbatesTypesTable = connect(mapStateToProps)(_ProbatesTypesTable);
