import React, { useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { Box, Drawer, List, Divider, Theme } from "@mui/material";
import { makeStyles } from "tss-react/mui";
import { BrandLogo } from "@theatticusapp/atticus-shared-components";
import NavItem from "./NavItem";
import {
  advisorNavItems,
  adminNavItems,
  AppScreen,
  estateAccessNavItems,
  formNavItems,
  communicationNavItems,
  uberNavItems,
  metricNavItems,
  inviteClientNavItems,
  inviteAdvisorNavItems,
} from "./navItems";
import { AdvisorMenuAndSignOut } from "./AdvisorMenuAndSignOut";
import { StoreState } from "../../../reducers";
import { User } from "../../../models/User";
import { connect } from "react-redux";

const useStyles = makeStyles()((theme: Theme) => ({
  mobileDrawer: {
    width: 256,
  },
  desktopDrawer: {
    width: 256,
    height: "100%",
  },
  logoWrapper: {
    marginTop: "2rem",
    padding: "0rem 2rem",
  },
  navItemWrapper: {
    marginTop: "2rem",
    flex: 1,
    overflowY: "auto",
  },
  divider: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
}));

interface Props {
  user: User;
  onMobileClose?: () => void;
  openMobile: boolean;
}

const _NavBar = ({
  user,
  onMobileClose,
  openMobile = false,
}: Props): JSX.Element => {
  const { classes } = useStyles();
  const location = useLocation();

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
    // es lint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  // nav items will be added in order they should appear in the UI
  let fullNavItems: AppScreen[] = [];

  // some users won't have access to see estate details
  if (user.hasEstateAccess) {
    fullNavItems = [...estateAccessNavItems];
  }

  if (user.advisorGroup?.hasAdvisorAccessToForms) {
    fullNavItems = [...fullNavItems, ...formNavItems];
  }

  // all users will see the communications nave times
  fullNavItems = [...fullNavItems, ...communicationNavItems];

  if (user.hasInviteAccess) {
    fullNavItems = [...fullNavItems, ...inviteClientNavItems];
    if (user?.isAdvisorAdmin) {
      fullNavItems = [...fullNavItems, ...inviteAdvisorNavItems];
    }
  }

  const navDividers = [fullNavItems.length - 2];

  // first test the most restrictive roles: only metrics & only admin
  if (user.onlyMetricsTool) {
    navDividers.push(fullNavItems.length);
    fullNavItems = [...fullNavItems, ...metricNavItems];
  } else if (user.onlyAdminTool) {
    navDividers.push(fullNavItems.length);
    fullNavItems = [...fullNavItems, ...adminNavItems];
  } else if (user?.isUberAdmin) {
    navDividers.push(fullNavItems.length);
    fullNavItems = [
      ...fullNavItems,
      ...metricNavItems,
      ...uberNavItems,
      ...adminNavItems,
    ];
  } else if (user?.isAdvisorAdmin) {
    navDividers.push(fullNavItems.length);
    const metricsItems = user.hasMetricsToolAccess
      ? metricNavItems
      : ([] as AppScreen[]);
    const adminItems = user.hasEstateAccess
      ? advisorNavItems
      : ([] as AppScreen[]);
    fullNavItems = [...fullNavItems, ...metricsItems, ...adminItems];
  }

  const getContent = (logo: boolean): JSX.Element => (
    <Box height="100%" display="flex" flexDirection="column">
      {logo && (
        <Box
          alignItems="center"
          display="flex"
          flexDirection="column"
          className={classes.logoWrapper}
        >
          <Link to={"/app/dashboard"}>
            <BrandLogo
              advisorGroupKey={user.advisorGroup?.title}
              secondary
              sx={{ maxWidth: "9rem" }}
            />
          </Link>
        </Box>
      )}
      <Box className={classes.navItemWrapper}>
        <List data-automation-id="left-nav">
          {fullNavItems.map((screen: AppScreen, index: number) => (
            <React.Fragment key={screen.title}>
              {navDividers.includes(index) ? (
                <Divider
                  className={classes.divider}
                  variant="middle"
                  key="NavBar-divider"
                />
              ) : (
                ""
              )}
              <NavItem
                path={screen.path}
                title={screen.title}
                icon={screen.icon}
              />
            </React.Fragment>
          ))}
        </List>
      </Box>
      <Divider variant="middle" key="NavBar-divider2" />
      <AdvisorMenuAndSignOut />
    </Box>
  );

  return (
    <>
      <Box
        id="NavBar/MobileDrawer"
        sx={{
          display: {
            xs: "block",
            sm: "block",
            md: "none",
            lg: "none",
            xl: "none",
          },
        }}
      >
        <Drawer
          anchor="left"
          classes={{ paper: classes.mobileDrawer }}
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
        >
          {getContent(false)}
        </Drawer>
      </Box>
      <Box
        id="NavBar/DesktopDrawer"
        sx={{
          display: {
            xs: "none",
            sm: "none",
            md: "block",
            lg: "block",
            xl: "block",
          },
        }}
      >
        <Drawer
          anchor="left"
          classes={{ paper: classes.desktopDrawer }}
          open
          variant="persistent"
        >
          {getContent(true)}
        </Drawer>
      </Box>
    </>
  );
};

const mapStateToProps = ({ user }: StoreState): { user: User } => {
  return { user };
};

export const NavBar = connect(mapStateToProps)(_NavBar);
