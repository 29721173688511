import React from "react";
import { Box, Typography } from "@mui/material";
import { HelpComponent, HelpContent } from "./HelpComponent";
import { InputType } from "./inputs/InputTypes";

interface Props {
  type?: InputType;
  label?: string;
  caption?: string;
  value?: string | number;
  helpContent?: HelpContent;
  noCommas?: boolean;
}

// display the attribute label and value as non-editable display
export const ViewAssetAttribute = ({
  type,
  label,
  caption,
  value,
  helpContent,
  noCommas = false,
}: Props): JSX.Element => {
  const displayValue = (): JSX.Element => {
    switch (type) {
      case InputType.static:
        return <></>;
      case InputType.usCurrency:
        return <Typography>${value?.toLocaleString() || "--"}</Typography>;
      case InputType.number:
        return (
          <Typography>
            {noCommas ? value : value?.toLocaleString() || "--"}
          </Typography>
        );
      default:
        return (
          <Typography>{value === "null" ? "--" : value || "--"}</Typography>
        );
    }
  };

  return (
    <Box
      sx={{
        mb: 3,
      }}
    >
      <Typography>
        <strong>{label}</strong>
      </Typography>
      <Typography variant="body2">{caption}</Typography>
      {displayValue()}
      <HelpComponent helpContent={helpContent} />
    </Box>
  );
};
