import React, { useEffect, useState } from "react";
import { TaskPhase } from "../../../../models/TaskPhase";
import { Box, Grid } from "@mui/material";
import { TaskPhaseNav } from "./TaskPhaseNav";
import { TaskView } from "./TaskView";
import { TaskNav } from "./TaskNav";
import { Task } from "../../../../models/Task";

type TaskPhaseAndTask = {
  task?: Task;
  taskPhase?: TaskPhase;
};

interface Props {
  taskPhases: TaskPhase[];
  estateId?: number;
  hasWriteAccess?: boolean;
}

export const TaskProgress = ({
  taskPhases,
  estateId,
  hasWriteAccess,
}: Props): JSX.Element => {
  const getTaskPhaseBySequence = (sequence: number): TaskPhase | undefined => {
    return taskPhases.find(
      (taskPhase: TaskPhase) => taskPhase.sequence === sequence
    );
  };
  const getFirstUnanswered = (): TaskPhaseAndTask => {
    let taskPhase: TaskPhase | undefined;
    let task: Task | undefined;
    // Loop over the groups tasks
    taskPhases.forEach((tp: TaskPhase) => {
      if (taskPhase === undefined) {
        // keep looking
        tp?.tasks?.forEach((t: Task) => {
          if (task === undefined) {
            // keep looking
            if (t?.types?.type !== "Terms" && !t?.answer?.boolAnswer) {
              // found one that's not answered
              taskPhase = tp;
              task = t;
            }
          }
        });
      }
    });
    return { task, taskPhase };
  };

  const getTaskBySequence = (
    taskPhase: TaskPhase | undefined,
    sequence: number
  ): Task | undefined => {
    let task;
    if (taskPhase && taskPhase.tasks && sequence) {
      task = taskPhase.tasks.find((task: Task) => task.sequence === sequence);
    }
    return task;
  };

  const initTaskPhase = getTaskPhaseBySequence(1);
  const initTask = getTaskBySequence(initTaskPhase, 1);
  const [currentTaskPhase, setCurrentTaskPhase] = useState(initTaskPhase);
  const [currentTask, setCurrentTask] = useState(initTask);
  // console.log("currentTaskPhase", currentTaskPhase, "currentTask", currentTask);

  useEffect(() => {
    const { taskPhase, task } = getFirstUnanswered();
    if (taskPhase?.sequence === 1 && task?.sequence === 2) {
      // special use case - no tasks have been answered
      // the first unanswered will be the second screen
      //  but don't jump to it.  allow the first (welcome) to be shown
    } else {
      taskPhase && setCurrentTaskPhase(taskPhase);
      task && setCurrentTask(task);
    }
  }, []);

  const handlePreviousTask = (): void => {
    return handleTaskMove("prev");
  };
  const handleNextTask = (): void => {
    return handleTaskMove("next");
  };

  // NOTE: relying on task and taskPhase sequence leaves this logic vulnerable to sequence quality from db
  const handleTaskMove = (dir: string): void => {
    if (currentTask && currentTaskPhase) {
      const firstPhase = currentTaskPhase.sequence === 1;
      const lastPhase = currentTaskPhase.sequence === taskPhases.length;
      const firstInPhase = currentTask.sequence === 1;
      const lastInPhase =
        currentTask.sequence === currentTaskPhase?.tasks?.length;
      // [X][][]
      // [][]
      // [][][]
      // if dir=next and lastInPhase set to next phase, first task in phase
      // if dir=next and !lastInPhase set to next task
      // if dir=prev and firstInPhase set to prev phase, last task in phase
      // if dir=prev and !firstInPhase set to prev task
      if (dir === "next") {
        if (lastInPhase) {
          if (lastPhase) {
            return console.log("~~~ at end");
          }
          const nextTaskPhase = getTaskPhaseBySequence(
            currentTaskPhase.sequence + 1
          );
          if (nextTaskPhase) {
            const nextTask = getTaskBySequence(nextTaskPhase, 1);
            if (nextTask) {
              setCurrentTaskPhase(nextTaskPhase);
              setCurrentTask(nextTask);
            } else console.error("could not get nextTask");
          } else console.error("could not get nextTaskPhase");
        } else {
          const nextTask = getTaskBySequence(
            currentTaskPhase,
            currentTask.sequence + 1
          );
          if (nextTask) {
            setCurrentTask(nextTask);
          } else console.error("could not get next task");
        }
      } else {
        if (firstInPhase) {
          if (firstPhase) {
            return console.log("~~~ at beginning");
          }
          const prevTaskPhase = getTaskPhaseBySequence(
            currentTaskPhase.sequence - 1
          );
          if (prevTaskPhase && prevTaskPhase?.tasks?.length) {
            const prevTask = getTaskBySequence(
              prevTaskPhase,
              prevTaskPhase.tasks.length
            );
            if (prevTask) {
              setCurrentTaskPhase(prevTaskPhase);
              setCurrentTask(prevTask);
            } else console.error("could not get prevTask");
          } else
            console.error(
              "could not get prevTaskPhase or no prevTaskPhase.tasks"
            );
        } else {
          const prevTask = getTaskBySequence(
            currentTaskPhase,
            currentTask.sequence - 1
          );
          if (prevTask) {
            setCurrentTask(prevTask);
          } else console.error("could not get prevTask");
        }
      }
    }
  };
  const handlePhase = (taskPhase: TaskPhase) => {
    setCurrentTaskPhase(taskPhase);
    // first task phase, or last active one (not sure how to know)?
    setCurrentTask(getTaskBySequence(taskPhase, 1));
  };

  const hidePrevious = () => {
    return currentTaskPhase?.sequence === 1 && currentTask?.sequence === 1;
  };

  const hideNext = () => {
    return (
      currentTaskPhase?.sequence === taskPhases?.length &&
      currentTask?.sequence === currentTaskPhase?.tasks?.length
    );
  };

  return (
    <>
      <Grid container>
        <Grid
          item
          sm={3}
          sx={{
            display: {
              xs: "none",
              sm: "none",
              md: "none",
              lg: "block",
            },
          }}
        >
          {currentTaskPhase && (
            <TaskPhaseNav
              taskPhases={taskPhases}
              currentTaskPhase={currentTaskPhase}
              onPhase={handlePhase}
            />
          )}
        </Grid>
        <Grid item xs={12} lg={9}>
          {currentTask && (
            <Box pt={3} px={2} pb={1} height="100%">
              {currentTaskPhase && (
                <TaskView
                  taskPhases={taskPhases}
                  currentTaskPhase={currentTaskPhase}
                  onPhase={handlePhase}
                  task={currentTask}
                  estateId={estateId}
                  hasWriteAccess={hasWriteAccess}
                />
              )}
              <TaskNav
                activeStep={currentTask.sequence - 1}
                steps={currentTaskPhase?.tasks?.length}
                hidePrevious={hidePrevious()}
                hideNext={hideNext()}
                onNext={handleNextTask}
                onPrevious={handlePreviousTask}
              />
            </Box>
          )}
        </Grid>
      </Grid>
    </>
  );
};
