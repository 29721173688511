import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from "@mui/material";
import { cheatSheetList } from "../../../../../utils/handlebarsCompiler";
import { CheatSheetContent } from "./CheatSheetContent";

interface Props {
  open: boolean;
  handleClose: () => void;
}

export const CheatSheetDialog = ({ open, handleClose }: Props): JSX.Element => {
  return (
    <Dialog open={open} onClose={handleClose} maxWidth={"lg"}>
      <DialogTitle>Cheat Sheet</DialogTitle>
      <DialogContent>
        <CheatSheetContent cheatSheetList={cheatSheetList} />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Close</Button>
      </DialogActions>
    </Dialog>
  );
};
