import { Estate } from "../models/Estate";
import { User, UserJSON } from "../models/User";
import { callGet, callPut } from "./index";

export class AtticusEstateUser {
  async getEstateUser(
    estate_id: number,
    user_id: number,
    rethrowError = false
  ): Promise<User | undefined> {
    const fullPath = `${Estate.path2}/${estate_id}/users/${user_id}`;
    const response = await callGet(fullPath, rethrowError);
    const user: User = User.fromJSON(response?.data);
    return user;
  }

  async getEstateUsers(
    estate_id: number,
    rethrowError = true
  ): Promise<User[] | undefined> {
    const fullPath = `${Estate.path2}/${estate_id}/users`;
    const response = await callGet(fullPath, rethrowError);
    const users: User[] = [];
    response?.data?.forEach((user: UserJSON) => {
      users.push(User.fromJSON(user));
    });

    return users;
  }

  async updateEstateUser(
    estate_id: number,
    user_id: number,
    editedUser: User,
    rethrowError = false
  ): Promise<User> {
    const fullPath = `${Estate.path2}/${estate_id}/users/${user_id}`;
    const response = await callPut(fullPath, editedUser.toJson(), rethrowError);
    const user: User = User.fromJSON(response?.data);
    return user;
  }
}
