import React, { useEffect } from "react";
import {
  Box,
  Button,
  MenuItem,
  Select,
  Theme,
  Stack,
  Grid,
} from "@mui/material";
import { makeStyles } from "tss-react/mui";
import { StoreState } from "../../../../reducers";
import { connect } from "react-redux";
import { OpportunityType } from "../../../../models/OpportunityType";
import { OpportunityStatus } from "../../../../models/OpportunityStatus";
import { camelToSpaced } from "../../../../utils/camelToSpaced";
import { fetchOpportunityTypes, fetchOpportunityStatuses } from "../../../../actions/opportunityActions";

const useStyles = makeStyles()((theme: Theme) => ({
  root: {
    marginBottom: "20px",
  },
  inputs: {
    backgroundColor: theme.palette.background.paper,
  },

  select: {
    "& .MuiSelect-root": {
      fontSize: 14,
      padding: "8px 32px 8px 8px", //theme.spacing(0.5, 4, 0.5, 1), //"4px 32px 4px 8px",
    },
  },
}));

const _OpportunitiesFilter = ({
  oppTypeId = 0,
  oppStatusId = 0,
  opportunityTypes,
  opportunityStatuses,
  onFilter,
  fetchOpportunityTypes,
  fetchOpportunityStatuses,
}): JSX.Element => {
  const { classes } = useStyles();
  let [myOppTypeId, setOppTypeId] = React.useState<number>(oppTypeId)
  let [myOppStatusId, setOppStatusId] = React.useState<number>(oppStatusId)

  useEffect( () => {  
    if(opportunityTypes && opportunityTypes.length > 0) {
      return
    }
    fetchOpportunityTypes() 
  }, []) 
  useEffect( () => {  
    if(opportunityStatuses && opportunityStatuses.length > 0) {
      return
    }
    fetchOpportunityStatuses() 
  }, []) 

  let dontRenderYet = (!opportunityTypes || opportunityTypes.length === 0)  
    || (!opportunityStatuses || opportunityStatuses.length === 0)
  if(dontRenderYet) {
    return <Box className={classes.root} />
  }

  return (
    <Box className={classes.root}>  
          <>
              <Grid container spacing={{ xs: 1, sm: 2 }}>
                <Grid item xs={12} sm={4} lg={3}>
                  <Select
                    name="typeId"
                    fullWidth
                    value={myOppTypeId}
                    onChange={(event) => {
                      const id = Number.parseInt(event.target.value.toString(), 10)
                      setOppTypeId(id)
                      onFilter(id, myOppStatusId)
                    }}
                    variant="outlined"
                    autoWidth={true}
                    displayEmpty={true}
                    renderValue={() =>
                      camelToSpaced(
                        opportunityTypes?.find(
                          (type: OpportunityType) => type.id === myOppTypeId
                        )?.title
                      ) || "Show All Types"
                    }
                    className={classes.select}
                  >
                    <MenuItem value={0}>
                      <em>Show All Types</em>
                    </MenuItem>
                    {opportunityTypes?.map((type: OpportunityType) => (
                      <MenuItem
                        value={type.id}
                        key={`${type?.title}_${type?.id}`}
                      >
                        {camelToSpaced(type?.title) || "----"}
                      </MenuItem>
                    ))}
                  </Select>
                </Grid>
                <Grid item xs={12} sm={4} lg={3}>
                  <Select
                    fullWidth
                    name="statusId"
                    value={myOppStatusId}
                    onChange={(event) => {
                      const id = Number.parseInt(event.target.value.toString(), 10)
                      setOppStatusId(id)
                      onFilter(myOppTypeId, id)
                    }}
                    variant="outlined"
                    autoWidth={true}
                    displayEmpty={true}
                    renderValue={() =>
                      camelToSpaced(
                        opportunityStatuses?.find(
                          (status: OpportunityStatus) =>
                            status.id === myOppStatusId
                        )?.title
                      ) || "Show All Statuses"
                    }
                    className={classes.select}
                  >
                    <MenuItem value={0}>
                      <em>Show All Statuses</em>
                    </MenuItem>
                    {opportunityStatuses?.map((status: OpportunityStatus) => (
                      <MenuItem
                        value={status.id}
                        key={`${status?.title}_${status?.id}`}
                      >
                        {camelToSpaced(status?.title) || "----"}
                      </MenuItem>
                    ))}
                  </Select>
                </Grid>
                <Grid item xs={12} sm={4} lg={3}>
                  <Stack direction="row" spacing={1}>
                    <Button
                      variant="outlined"
                      color="primary"
                      onClick={() => {
                        setOppStatusId(0)
                        setOppTypeId(0)
                        onFilter(0, 0)
                      }}
                    >
                      Reset
                    </Button>
                  </Stack>
                </Grid>
              </Grid>
          </>
    </Box>
  );
};

const mapStateToProps = ({
  opportunityTypes,
  opportunityStatuses,
}: StoreState): {
  opportunityTypes: OpportunityType[];
  opportunityStatuses: OpportunityStatus[];
} => {
  return { opportunityTypes, opportunityStatuses };
};

export const OpportunitiesFilter = connect(mapStateToProps, {
  fetchOpportunityTypes,
  fetchOpportunityStatuses,
})(_OpportunitiesFilter)