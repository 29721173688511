export type ReportJSON = {
  id: number;
  report?: string;
  report_content?: string;
  estate_id?: number;
  estate_name?: string;
  created_at?: string;
  updated_at?: string;
  status?: string;
};

export enum ReportStatus {
  ready = "ready",
  failed = "failed",
  processing = "processing",
}

export class Report {
  static path = "reports";
  constructor(
    public id?: number,
    public title?: string,
    public estateId?: number,
    public estateName?: string,
    public createdAt?: string,
    public status?: ReportStatus
  ) {}

  static fromJSON = (
    json: ReportJSON,
    name?: string,
    estateId?: number
  ): Report => {
    return new Report(
      json.id,
      json.report,
      json.estate_id || estateId,
      json.estate_name || name,
      json.created_at,
      this.getStatus(json.status)
    );
  };

  static getStatus(status?: string): ReportStatus | undefined {
    switch (status) {
      case "ready":
        return ReportStatus.ready;
      case "failed":
        return ReportStatus.failed;
      case "processing":
        return ReportStatus.processing;
      default:
        return undefined;
    }
  }
}
