import React, { useEffect } from "react";
import { StoreState } from "../../../reducers";
import { AdvisorGroup } from "../../../models/AdvisorGroup";
import { connect } from "react-redux";
import { fetchAdvisorGroups } from "../../../actions/advisorGroupActions";
import { AllAdvisorsTable } from "./AllAdvisorsTable";
import { User } from "../../../models/User";

interface Props {
  user: User;
  advisorGroups: AdvisorGroup[];
  fetchAdvisorGroups: () => void;
}

export const _AllAdvisorsView = ({
  user,
  advisorGroups,
  fetchAdvisorGroups,
}: Props): JSX.Element => {
  useEffect(() => {
    if (!advisorGroups) {
      fetchAdvisorGroups();
    }
  }, []);

  return <AllAdvisorsTable advisorGroups={advisorGroups} user={user} />;
};

const mapStateToProps = ({
  user,
  advisorGroups,
}: StoreState): { user: User; advisorGroups: AdvisorGroup[] } => {
  return { user, advisorGroups };
};

export const AllAdvisorsView = connect(mapStateToProps, {
  fetchAdvisorGroups,
})(_AllAdvisorsView);
