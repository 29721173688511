import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  IconButton,
} from "@mui/material";
import { ProbateType } from "../../../models/ProbateType";
import { Language } from "../../../models/Language";
import { ProbatesTypesTable } from "./ProbatesTypesTable";
import { ProbateMappingForm } from "./ProbateMappingForm";
import PacketLogicHelper from "./PacketLogic/PacketLogicHelper"
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import { ConfirmationDialog } from "../../shared/dialog/ConfirmationDialog";
import probateMappingExample from "./probate_mapping.json";
import JSONPretty from "react-json-pretty";
import { StoreState } from "../../../reducers";
import { connect } from "react-redux";
import { fetchProbateTypes } from "../../../actions/probateTypeActions";
import { fetchLanguages } from "../../../actions/languageActions";
import { probateApi } from "../../../apis";
import { State } from "../../../models/Geography";
import FileCopyIcon from "@mui/icons-material/FileCopy";

interface Props {
  languages?: Language[];
  fetchLanguages: () => void;
  probateTypes?: ProbateType[];
  fetchProbateTypes: () => void;
}

const _ProbateView = ({
  fetchProbateTypes,
  languages,
  fetchLanguages,
  probateTypes,
}: Props): JSX.Element => {
  // const [stateId, setStateId] = useState<number>();
  const [showImportExample, setShowImportExample] = useState(false);
  const [mappingExport, setMappingExport] = useState<string>();
  const [stateName, setStateName] = useState<string>();
  const [reloadProbateTypes, setReloadProbateTypes] = useState(0);

  useEffect(() => {
    (async () => {
      if (!probateTypes) {
        fetchProbateTypes();
      }
      if (!languages || !languages?.length) {
        fetchLanguages();
      }
    })();
  }, [reloadProbateTypes]);

  const getImportExample = () => {
    return (
      <JSONPretty
        id="json-pretty"
        data={probateMappingExample}
        // onJSONPrettyError={() => {
        //   setIsJSONInvalid(true);
        // }}
      />
    );
  };

  const handleExport = async (state?: State) => {
    if (state?.id) {
      const json: any = await probateApi.fetchProbateMapping(state.id);
      setStateName(json?.states[0]?.state_name);
      setMappingExport(json);
    }
  };

  return (
    <div data-automation-id="page-content-probate">
      <Card>
        <CardHeader title="Probate Types and Form Packets" />
        <CardContent
          style={{
            padding: 0,
          }}
        >
          <ProbatesTypesTable
            probateTypes={probateTypes}
            onExport={handleExport}
            reloadEvent={reloadProbateTypes}
          />
        </CardContent>
      </Card>
      {mappingExport && (
        <Card
          style={{
            marginTop: 16,
            position: "relative",
          }}
        >
          <Button
            style={{
              position: "absolute",
              right: 8,
              top: 8,
            }}
            onClick={() => setMappingExport(undefined)}
          >
            clear
          </Button>
          <CardHeader title={`Exported: Probate/Forms map for ${stateName}`} />
          <CardContent>
            <JSONPretty id="json-pretty" data={mappingExport} />
            <Box style={{ textAlign: "center" }}>
              <Button
                variant="contained"
                color="primary"
                onClick={() => {
                  if (navigator.clipboard) {
                    navigator.clipboard.writeText(
                      JSON.stringify(mappingExport)
                    );
                  }
                }}
                startIcon={<FileCopyIcon />}
              >
                Copy export json
              </Button>
            </Box>
          </CardContent>
        </Card>
      )}
      <Card
        style={{
          marginTop: 16,
        }}
      >
        <CardHeader title="Packet Mapping Import" />
        <CardContent
          style={{
            position: "relative",
          }}
        >
          <IconButton
            onClick={() => {
              setShowImportExample(true);
            }}
            style={{
              position: "absolute",
              right: 10,
              top: -50,
            }}
          >
            <HelpOutlineIcon />
          </IconButton>
          <ProbateMappingForm
            onImportComplete={() => {
              setReloadProbateTypes(reloadProbateTypes + 1);
            }}
          />
        </CardContent>
      </Card>
      <ConfirmationDialog
        open={showImportExample}
        description={getImportExample()}
        question="Packet Mapping Import Example"
        onConfirm={() => setShowImportExample(false)}
        confirmText="Ok"
        contentTextAlign="left"
      />
      <Card
        style={{
          marginTop: 16,
        }}
      >
        <CardHeader title="Packet logic helper" />
        <CardContent
          style={{
            position: "relative",
          }}
        >
          <PacketLogicHelper />
        </CardContent>
      </Card>
    </div>
  );
};

const mapStateToProps = ({
  probateTypes,
  languages,
}: StoreState): {
  probateTypes: ProbateType[];
  languages: Language[];
} => {
  return { probateTypes, languages };
};

export const ProbateView = connect(mapStateToProps, {
  fetchProbateTypes,
  fetchLanguages,
})(_ProbateView);
