import React from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  TextField,
  Typography,
  Theme,
} from "@mui/material";
import { makeStyles } from "tss-react/mui";
import { CognitoUserAttributes, User, UserJSON } from "../../../models/User";
const Yup = require("yup")
import { Formik, FormikHelpers } from "formik";
import { advisorApi, authApi, logger } from "../../../apis";
import { phoneFormat } from "../../../utils/phoneFormat";

const useStyles = makeStyles()((theme: Theme) => ({
  root: {
    paddingTop: theme.spacing(3),
  },
  grid: {
    "& .MuiGrid-item": {
      paddingTop: 0,
      paddingBottom: 0,
    },
  },
}));

interface Values {
  first: string;
  last: string;
  phone: string;
}

interface Props {
  user: User;
  getUser: () => void;
}

export const ProfileAttributes = ({ user, getUser }: Props): JSX.Element => {
  const { classes } = useStyles();

  // https://formik.org/docs/api/formik
  const formikInit = {
    values: {
      first: user?.first || "",
      last: user?.last || "",
      phone: user?.phone || "",
    },
    schema: Yup.object().shape({
      first: Yup.string().max(255),
      name: Yup.string().max(255),
      phone: Yup.string().matches(
        /^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/g,
        "Invalid phone number"
      ),
    }),
  };

  const handleSubmit = async (
    values: Values,
    { setSubmitting, setStatus }: FormikHelpers<Values>
  ) => {
    try {
      setStatus("");
      // if (!touched.first && !touched.last && !touched.phone) return;
      const { first, last, phone } = values;

      // prepare the fields for cognito
      const attributes: CognitoUserAttributes = {};
      if (first) attributes.given_name = first;
      if (last) attributes.family_name = last;
      if (phone) {
        attributes.phone_number = phoneFormat(phone);
      }
      // update cognito
      const result = await authApi.updateAttributes(attributes);
      // only continue with laravel update if cognito succeeded
      if (result) {
        // prepare the fields for laravel
        const profileAttributes: UserJSON = {};
        if (first) profileAttributes.first_name = first;
        if (last) profileAttributes.last_name = last;
        if (phone) {
          profileAttributes.phone = phoneFormat(phone);
        }
        // update laravel/mysql
        const result2 = await advisorApi.updateAdvisor(profileAttributes);
        if (result2) {
          getUser();
          setStatus("Profile successfully updated");
          window.setTimeout(() => {
            setStatus("");
          }, 3000);
        } else {
          throw new Error("Could not update profile.  Please try again");
        }
      } else {
        throw new Error("Could not update profile.  Please try again");
      }
    } catch (error) {
      logger.error("profile update error: " + JSON.stringify(error));
      setStatus((error as Error)?.message);
    }
    setSubmitting(false);
  };

  return (
    <Formik
      initialValues={formikInit.values}
      validationSchema={formikInit.schema}
      onSubmit={handleSubmit}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values,
        status,
      }) => (
        <form onSubmit={handleSubmit}>
          <Card>
            <CardContent className={classes.root}>
              <Grid container spacing={2} className={classes.grid}>
                <Grid item xs={12}>
                  <Typography variant="h4">
                    {user?.role || "Profile"} at{" "}
                    {user?.advisorGroupName?.replace("AG-", "") || "unknown"}
                  </Typography>
                  <br />
                </Grid>
                <Grid item xs={12}>
                  <Typography color="textPrimary" gutterBottom variant="h5">
                    {user?.id}: {user?.email}
                  </Typography>
                </Grid>
                <Grid item lg={6} xs={12}>
                  <TextField
                    error={Boolean(touched.first && errors.first)}
                    fullWidth
                    helperText={touched.first && errors.first}
                    label="First Name"
                    margin="normal"
                    name="first"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type="text"
                    value={values.first}
                    variant="outlined"
                    required
                  />
                </Grid>
                <Grid item lg={6} xs={12}>
                  <TextField
                    error={Boolean(touched.last && errors.last)}
                    fullWidth
                    helperText={touched.last && errors.last}
                    label="Last name"
                    margin="normal"
                    name="last"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type="last"
                    value={values.last}
                    variant="outlined"
                  />
                </Grid>
                <Grid item lg={6} xs={12}>
                  <TextField
                    error={Boolean(touched.phone && errors.phone)}
                    fullWidth
                    helperText={touched.phone && errors.phone}
                    label="Phone number"
                    margin="normal"
                    name="phone"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type="phone"
                    value={values.phone}
                    variant="outlined"
                  />
                </Grid>
                <Grid item lg={6} xs={12}>
                  <Typography
                    variant="h4"
                    color={
                      status?.toString()?.includes("success")
                        ? "primary"
                        : "error"
                    }
                  >
                    {status}
                  </Typography>
                  {isSubmitting && (
                    <Typography variant="h4">submitting...</Typography>
                  )}
                </Grid>
              </Grid>
            </CardContent>
            <Box display="flex" justifyContent="flex-end" p={2}>
              <br />
              <Button
                color="primary"
                disabled={isSubmitting}
                fullWidth
                size="large"
                type="submit"
                variant="contained"
              >
                Save updates
              </Button>
            </Box>
          </Card>
        </form>
      )}
    </Formik>
  );
};
