import { Theme, Typography } from "@mui/material";
import { makeStyles } from "tss-react/mui";
import React from "react";
import { InCard } from "./InCard";
import { Typography as TypographyType } from "@mui/material/styles/createTypography";

const useStyles = makeStyles()((theme: Theme) => ({
  wrapper: {
    textAlign: "center",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    gap: "1rem",
  },
  image: {
    marginTop: theme.spacing(4),
    width: "80%",
    maxWidth: 1000,
    "&.medium": {
      width: "60%",
    },
    "&.small": {
      width: "40%",
    },
  },
  content: {
    margin: `${theme.spacing(2)} auto`,
  },
}));

interface Props {
  text?: string;
  subtext?: TypographyType;
  textLink?: JSX.Element;
  textMaxWidth?: number;
  imageAlt?: string;
  imagePrefix?: string;
  imageElement?: JSX.Element;
  inCard?: boolean;
  displaySize?: string;
  opacity?: number;
  maxWidth?: number;
}

export const ZeroStateBody = ({
  text,
  subtext,
  textLink,
  textMaxWidth = 480,
  imageAlt,
  imagePrefix,
  inCard = true,
  displaySize,
  opacity = 1,
  maxWidth = 600,
  imageElement,
}: Props): JSX.Element => {
  const { classes } = useStyles();
  const imageSizeClass =
    displaySize === "medium" || displaySize === "small" ? displaySize : "";
  return (
    <InCard card={inCard}>
      <div className={classes.wrapper}>
        {imageElement ? (
          <>{imageElement}</>
        ) : (
          imagePrefix && (
            <img
              srcSet={`${imagePrefix}-1.5x.png 1.5x, ${imagePrefix}-2x.png 2x, ${imagePrefix}-3x.png 3x`}
              src={`${imagePrefix}.png`}
              alt={imageAlt}
              className={`${classes.image} ${imageSizeClass}`}
              style={{ opacity: opacity, maxWidth: maxWidth }}
            />
          )
        )}
        <div className={classes.content} style={{ maxWidth: textMaxWidth }}>
          {text && <Typography variant="body1">{text}</Typography>}
          {textLink && <Typography variant="body1">{textLink}</Typography>}
          {/* {subtext && { subtext }} */}
        </div>
      </div>
    </InCard>
  );
};
