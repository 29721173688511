import {
  Card,
  CardContent,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from "@mui/material";
import React from "react";

interface Props {
  colSpan: number;
  rows?: number;
  title?: string;
}

export const TableBodyLoader = ({
  colSpan,
  rows = 5,
  title,
}: Props): JSX.Element => {
  const getRows = (): JSX.Element[] => {
    const rowCards:JSX.Element[] = [];
    for (let i = 0; i <= rows - 1; i++) {
      rowCards.push(
        <Card className="loading" key={`TableBodyLoader_${i}`}>
          {title && i == 0 ? (
            <CardContent>
              <Typography variant="body1">{title}</Typography>
            </CardContent>
          ) : (
            <CardContent />
          )}
        </Card>
      );
    }
    return rowCards;
  };

  return (
    <TableBody>
      {/* add header space */}
      <TableRow className="no-background">
        <TableCell colSpan={colSpan}>{getRows()}</TableCell>
      </TableRow>
    </TableBody>
  );
};
