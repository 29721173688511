import { FinancialConvo } from "../models/FinancialConvo";
import { callGet, logger } from "./index";

export class FinancialConvos {
  async fetchSingleFinancialConvo(
    financialConvoId: number
  ): Promise<FinancialConvo | null> {
    try {
      const response = await callGet(
        `${FinancialConvo.path}/${financialConvoId}`,
        true
      );
      return FinancialConvo.fromJSON(response?.data) || null;
    } catch (error) {
      if (
        (error as any)?.response?.status === 404 ||
        (error as any)?.response?.status === 401
      ) {
        // requesting missing or invalid resource
        window.document.location.href = `/app/estates`;
      }
    }
    return null;
  }

  async fetchSingleFinancialConvoFromOpp(
    opportunityId: number
  ): Promise<FinancialConvo | null> {
    try {
      const response = await callGet(
        `${FinancialConvo.path}/from-opportunity/${opportunityId}`,
        true
      );
      return FinancialConvo.fromJSON(response?.data) || null;
    } catch (error) {
      if (
        (error as any)?.response?.status === 404 ||
        (error as any)?.response?.status === 401
      ) {
        // requesting missing or invalid resource
        window.document.location.href = `/app/estates`;
      }
    }
    return null;
  }

  async fetchFinancialConvos(): Promise<FinancialConvo[] | null> {
    try {
      const response = await callGet(`${FinancialConvo.path}?completed=true`);
      return (
        response?.data?.map((financialConvo: any) =>
          FinancialConvo.fromJSON(financialConvo)
        ) || []
      );
    } catch (error) {
      console.log(error);
      logger.error(error as Error);
    }
    return null;
  }
}
