import React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Typography,
} from "@mui/material";
import { makeStyles } from "tss-react/mui";

import CloseIcon from "@mui/icons-material/Close";
import {
  ButtonPropsColorOverrides,
  ButtonPropsVariantOverrides,
} from "@mui/material/Button/Button";
import { OverridableStringUnion } from "@mui/types";
import { Property } from "csstype";
import { Breakpoint } from "@mui/system";

const useStyles = makeStyles()(() => ({
  root: {},
  // actionWrapper: {
  //   display: "flex",
  //   flexDirection: "column",
  //   alignItems: "center",
  //   // justifyContent: "center",
  // },
  title: {
    "&>h2": {
      textAlign: "center",
    },
  },
  button: {
    minWidth: 125,
  },
}));

export type ButtonColorType = OverridableStringUnion<
  | "inherit"
  | "primary"
  | "secondary"
  | "success"
  | "error"
  | "info"
  | "warning",
  ButtonPropsColorOverrides
>;

export type ButtonVariantType = OverridableStringUnion<
  "text" | "outlined" | "contained",
  ButtonPropsVariantOverrides
>;

interface Props {
  open: boolean;
  description: string | JSX.Element | JSX.Element[];
  question?: string;
  maxWidth?: false | Breakpoint | undefined;
  onConfirm: () => void;
  confirmText?: string;
  confirmButtonColor?: ButtonColorType;
  confirmButtonVariant?: ButtonVariantType;
  confirmWide?: boolean;
  onDeny?: () => void;
  denyText?: string;
  denyButtonColor?: ButtonColorType;
  denyButtonVariant?: ButtonVariantType;
  denyWide?: boolean;
  contentTextAlign?: CanvasTextAlign;
  buttonOrientation?: Property.FlexDirection;
}

export const ConfirmationDialog = ({
  open,
  description,
  question,
  maxWidth = "sm",
  onConfirm,
  confirmText = "Yes",
  confirmButtonColor = "primary",
  confirmButtonVariant = "contained",
  confirmWide,
  onDeny,
  denyText = "No",
  denyButtonColor = "primary",
  denyButtonVariant = "text",
  denyWide,
  contentTextAlign = "center",
  buttonOrientation = "column",
}: Props): JSX.Element => {
  const { classes } = useStyles();

  return (
    <Dialog
      open={open}
      maxWidth={maxWidth}
      onClose={onDeny ? onDeny : onConfirm}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <IconButton
        style={{
          position: "absolute",
          top: 8,
          right: 8,
        }}
        aria-label="delete"
        onClick={onDeny ? onDeny : onConfirm}
      >
        <CloseIcon />
      </IconButton>
      {question && (
        <DialogTitle
          id="alert-dialog-title"
          className={classes.title}
          component="div"
        >
          <Typography variant="h2">{question}</Typography>
        </DialogTitle>
      )}
      <DialogContent
        style={{
          textAlign: contentTextAlign,
        }}
      >
        {typeof description === "string" ? (
          <DialogContentText id="alert-dialog-description">
            {description}
          </DialogContentText>
        ) : (
          description
        )}
      </DialogContent>
      <DialogActions
        sx={{
          display: "flex",
          flexDirection: {
            xs: "column",
            sm: "column",
            md: buttonOrientation,
          },
          alignItems: "center",
          justifyContent: "space-evenly",
        }}
      >
        <Button
          onClick={() => onConfirm()}
          color={confirmButtonColor}
          variant={confirmButtonVariant}
          autoFocus
          sx={{
            minWidth: {
              xs: "auto",
              sm: confirmWide ? 275 : "auto",
            },
            mx: 1,
          }}
        >
          {confirmText}
        </Button>
        {onDeny && (
          <Button
            onClick={() => onDeny()}
            color={denyButtonColor}
            variant={denyButtonVariant}
            sx={{
              minWidth: {
                xs: "auto",
                sm: denyWide ? 275 : "auto",
              },
              mx: 1,
            }}
          >
            {denyText}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};
