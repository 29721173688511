import { Button, Theme } from "@mui/material";
import { makeStyles } from "tss-react/mui";
import React from "react";

const useStyles = makeStyles()((theme: Theme) => ({
  button: {
    margin: theme.spacing(1),
    width: "fit-content",
  },
}));

interface Props {
  children?: JSX.Element | string;
  onClick: () => void;
}

export const TestButton = ({ children, onClick }: Props): JSX.Element => {
  const { classes } = useStyles();

  return (
    <Button
      onClick={() => onClick()}
      variant="contained"
      color="primary"
      className={classes.button}
    >
      {children}
    </Button>
  );
};
