export type FinancialConvoJSON = {
  id?: number;
  estates_users_id?: number;
  has_completed_request?: boolean;
  financial_feelings?: string;
  financial_experience?: string;
  discussion_topics?: string; // json string to be converted to string[]
  discussion_topics_something_else?: string;
  declared_type_of_assets?: string; // json string to be converted to string[]
  bene_relationship_status?: string;
  anything_else_to_know?: string;
  updated_at?: Date;
  created_at?: Date;
  opportunity_id?: number;
  estate_id?: number;
};

export class FinancialConvo {
  static path = "/financial-convos";
  constructor(
    public id?: number,
    public hasCompletedRequest?: boolean,
    public financialFeelings?: string,
    public financialExperience?: string,
    public discussionTopics?: string[],
    public discussionTopicsSomethingElse?: string,
    public declaredTypeOfAssets?: string[],
    public beneRelationshipStatus?: string,
    public anythingElseToKnow?: string,
    public updatedAt?: Date,
    public createdAt?: Date,
    public opportunityId?: number,
    public estateId?: number
  ) {}

  static fromJSON(json: FinancialConvoJSON): FinancialConvo {
    if (json && json.id) {
      return new FinancialConvo(
        json.id,
        json.has_completed_request,
        json.financial_feelings,
        json.financial_experience,
        json.discussion_topics ? JSON.parse(json.discussion_topics) : undefined,
        json.discussion_topics_something_else,
        json.declared_type_of_assets
          ? JSON.parse(json.declared_type_of_assets)
          : undefined,
        json.bene_relationship_status,
        json.anything_else_to_know,
        json.updated_at ? new Date(json.updated_at) : undefined,
        json.created_at ? new Date(json.created_at) : undefined,
        json.opportunity_id,
        json.estate_id
      );
    } else {
      return new FinancialConvo();
    }
  }
  toJson(): FinancialConvoJSON {
    const json: any = {};
    this.hasCompletedRequest !== undefined &&
      (json.has_completed_request = this.hasCompletedRequest);
    this.financialFeelings !== undefined &&
      (json.financial_feelings = this.financialFeelings);
    this.financialExperience !== undefined &&
      (json.financial_experience = this.financialExperience);
    this.discussionTopics !== undefined &&
      (json.discussion_topics = JSON.stringify(this.discussionTopics));
    this.discussionTopicsSomethingElse !== undefined &&
      (json.discussion_topics_something_else =
        this.discussionTopicsSomethingElse);
    this.declaredTypeOfAssets !== undefined &&
      (json.declared_type_of_assets = JSON.stringify(
        this.declaredTypeOfAssets
      ));
    this.beneRelationshipStatus !== undefined &&
      (json.bene_relationship_status = this.beneRelationshipStatus);
    this.anythingElseToKnow !== undefined &&
      (json.anything_else_to_know = this.anythingElseToKnow);
    this.opportunityId !== undefined &&
      (json.opportunity_id = this.opportunityId);
    this.estateId !== undefined && (json.estate_id = this.estateId);
    return json;
  }
}
