import React, { useEffect, useState } from "react";
import {
  Box,
  CircularProgress,
  IconButton,
  Chip,
  Tooltip,
  Typography,
  FormControl,
  InputLabel,
  Select,
  ListSubheader,
  MenuItem,
  Button,
  Theme,
} from "@mui/material";
import { makeStyles, withStyles } from "tss-react/mui";
import {
  FormInterface,
  FormInterfaceFieldJson,
} from "../../../../models/FormInterface";
import Editor from "@monaco-editor/react";
import { CheatSheetDialog } from "./CheatSheet/CheatSheetDialog";
import HelpIcon from "@mui/icons-material/Help";
import SaveIcon from "@mui/icons-material/Save";
import { AtticusForm } from "../../../../models/AtticusForm";
import { Estate } from "../../../../models/Estate";

import {
  SplitButtonGroup,
  SplitButtonOption,
} from "../../../shared/SplitButtonGroup";

const useStyles = makeStyles()((theme: Theme) => ({
  root: {
    height: "100%",
    width: "100%",
    display: "flex",
    flexDirection: "column",
  },
  mainContainer: {
    flex: "1",
    // Hack to get the editor to fill the space
    height: "0%",
    backgroundColor: theme.palette.background.paper,
  },
  interfaceToolbar: {
    display: "flex",
    backgroundColor: theme.palette.background.default,
  },
  interfaceFootbar: {
    display: "flex",
    justifyContent: "center",
    padding: "0.5rem",
    gap: "0.75rem",
    backgroundColor: theme.palette.background.default,
  },
  estateSelectContainer: {
    flex: 1,
    minWidth: "0%",
  },
  loadingContainer: {
    flex: 1,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    height: "100%",
  },
  saveBox: {
    display: "flex",
    alignItems: "center",
    // justifyContent: "center",
    flex: "1",
  },
  saveInfo: {
    marginLeft: "0.5rem",
    marginRight: "0.5rem",
    display: "flex",
    flexDirection: "column",
  },
  editInfoBox: {
    display: "flex",
    alignItems: "center",
  },
  editorChip: {
    padding: "0rem",
    height: "1.25rem",
    "& .MuiChip-label": {
      paddingLeft: "0.5rem",
      paddingRight: "0.5rem",
    },
  },
}));

const HtmlTooltip = withStyles(Tooltip,(theme: Theme) => ({
  tooltip: {
    backgroundColor: theme.palette.grey[300],
    color: theme.palette.text.primary,
    fontSize: theme.typography.pxToRem(12),
    border: `1px solid ${theme.palette.grey[800]}`,
  },
}));

export interface EstateGrouping {
  title: string;
  estates: Estate[];
}

interface Props {
  form?: AtticusForm;
  formInterface?: FormInterface;
  setFormInterface: (formInterface: FormInterface) => void;
  saveFormInterface: (formId: number, formInterface: FormInterface) => void;
  setFormInterfaceParsingError: (error: Error | null) => void;
  estateGroupings: EstateGrouping[];
  isInjectDisabled: boolean;
  isViewFormFieldsDisabled: boolean;
  isClearFormDisabled: boolean;
  estatesLoading: boolean;
  selectedEstateId?: number;
  setSelectedEstateId: (estateId: number) => void;
  onInjectClick: () => void;
  onViewFieldsClick: () => void;
  onClearFormClick: () => void;
  onViewHybridClick: () => void;
}

export const InterfaceEditor = ({
  form,
  formInterface,
  setFormInterface,
  saveFormInterface,
  setFormInterfaceParsingError,
  estateGroupings,
  isInjectDisabled,
  isViewFormFieldsDisabled,
  isClearFormDisabled,
  estatesLoading,
  selectedEstateId,
  setSelectedEstateId,
  onInjectClick,
  onViewFieldsClick,
  onClearFormClick,
  onViewHybridClick,
}: Props): JSX.Element => {
  const { classes } = useStyles();
  const [openCheatSheet, setOpenCheatSheet] = React.useState(false);
  const [jsonHasErrors, setJsonHasErrors] = React.useState(false);
  const [estateOptions, setEstateOptions] = useState([] as JSX.Element[]);

  const handleOpenCheatSheet = () => {
    setOpenCheatSheet(true);
  };

  const handleCloseCheatSheet = () => {
    setOpenCheatSheet(false);
  };

  const handleFormInterfaceChange = (val: string | undefined) => {
    if (formInterface && val) {
      try {
        const valAsFieldJson = JSON.parse(val) as FormInterfaceFieldJson[];
        setFormInterface(formInterface.setData(valAsFieldJson));
        setFormInterfaceParsingError(null);
        setJsonHasErrors(false);
      } catch (e) {
        setFormInterfaceParsingError(e as Error);
        setJsonHasErrors(true);
      }
    }
  };

  const footerButtonOptions: SplitButtonOption[] = [
    {
      label: "View Fields",
      key: "view-fields",
      disabled: isViewFormFieldsDisabled,
      onClick: onViewFieldsClick,
    },
    {
      label: "View Hybrid",
      key: "view-hybrid",
      disabled: isInjectDisabled || isViewFormFieldsDisabled,
      onClick: onViewHybridClick,
    },
    {
      label: "Clear Form",
      key: "clear-form",
      disabled: isClearFormDisabled,
      onClick: onClearFormClick,
    },
  ];

  useEffect(() => {
    const items:JSX.Element[] = [];
    for (const grouping of estateGroupings) {
      if (grouping.estates?.length > 0) {
        items.push(
          <ListSubheader key={grouping.title}>{grouping.title}</ListSubheader>
        );

        for (const estate of grouping.estates) {
          items.push(
            <MenuItem key={estate.id?.toString()} value={estate.id?.toString()}>
              {estate.name || "- -"} [ID: {estate.id}]
            </MenuItem>
          );
        }
      }
    }

    setEstateOptions(items);
  }, [estateGroupings]);

  return (
    <>
      <CheatSheetDialog
        open={openCheatSheet}
        handleClose={handleCloseCheatSheet}
      />
      <Box className={classes.root}>
        <Box className={classes.interfaceToolbar}>
          <Box className={classes.saveBox}>
            <IconButton
              aria-label="save-interface"
              disabled={!formInterface || jsonHasErrors}
              onClick={() => {
                if (form && formInterface) {
                  saveFormInterface(form.id, formInterface);
                }
              }}
            >
              <SaveIcon />
            </IconButton>
            {formInterface && (
              <Box className={classes.saveInfo}>
                {formInterface.lastEditedAt && (
                  <Typography variant="body1">
                    <Box
                      component="span"
                      fontWeight="fontWeightMedium"
                    >{`Last Edited:`}</Box>
                    {` ${formInterface.lastEditedAt?.toLocaleString()}`}
                  </Typography>
                )}
                {formInterface?.lastEditedByUserInfo && (
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <HtmlTooltip
                      arrow
                      title={
                        <React.Fragment>
                          <Typography variant="h4" color="inherit">
                            {`${formInterface?.lastEditedByUserInfo.email} (ID: ${formInterface?.lastEditedByUserInfo.id})`}
                          </Typography>
                        </React.Fragment>
                      }
                    >
                      <Chip
                        className={classes.editorChip}
                        color="default"
                        label={`by: ${formInterface?.lastEditedByUserInfo.email}`}
                      />
                    </HtmlTooltip>
                  </Box>
                )}
              </Box>
            )}
          </Box>
          <IconButton onClick={handleOpenCheatSheet}>
            <HelpIcon fontSize="small" />
          </IconButton>
        </Box>
        <Box className={classes.mainContainer}>
          {formInterface ? (
            <Editor
              defaultLanguage="json"
              defaultValue={JSON.stringify(formInterface.data, null, 2)}
              onChange={handleFormInterfaceChange}
            />
          ) : (
            <Box className={classes.loadingContainer}>
              <CircularProgress />
            </Box>
          )}
        </Box>
        <Box className={classes.interfaceFootbar}>
          <Box className={classes.estateSelectContainer}>
            <FormControl fullWidth variant="outlined" size="small">
              <InputLabel id="estate-select-label">Estate</InputLabel>
              <Select
                labelId="estate-select-label"
                id="estate-select"
                value={selectedEstateId?.toString() || ""}
                label="Estate"
                disabled={estatesLoading}
                onChange={(event) => {
                  setSelectedEstateId(parseInt(event.target.value));
                }}
              >
                {estateOptions}
              </Select>
            </FormControl>
          </Box>
          <Button
            size="small"
            disabled={isInjectDisabled}
            variant="contained"
            color="inherit"
            onClick={onInjectClick}
          >
            Inject
          </Button>
          <SplitButtonGroup
            options={footerButtonOptions}
            variant="contained"
            size="small"
            color="inherit"
            popperPlacement={"top-end"}
            defaultKey="view-fields"
          />
        </Box>
      </Box>
    </>
  );
};