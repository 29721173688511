import { Card, CardContent, Theme, Typography } from "@mui/material";
import { makeStyles } from "tss-react/mui";
import { abbreviateNumber } from "js-abbreviation-number";
import React from "react";
import { AssetSubTotal } from "./AssetSummary";

const useStyles = makeStyles()((theme: Theme) => ({
  row: {
    boxShadow: "none",
    backgroundColor: theme.palette.background.default,
    margin: "10px 0 0",
  },
  rowContent: {
    display: "flex",
    justifyContent: "space-between",
  },
}));

export const AssetValueRow = ({
  asset,
  monetary = false,
  bold = false,
}: {
  asset: AssetSubTotal;
  monetary?: boolean;
  bold?: boolean;
}): JSX.Element => {
  const { classes } = useStyles();

  return (
    <Card className={classes.row} key={asset.key}>
      <CardContent className={`${classes.rowContent} dense`}>
        <Typography
          variant="body2"
          style={{
            fontWeight: bold ? "bold" : "normal",
          }}
        >
          {asset.key}
        </Typography>
        <Typography
          variant="body2"
          style={{
            fontWeight: bold ? "bold" : "normal",
          }}
        >
          {monetary ? "$" : ""}
          {abbreviateNumber(asset.value, 0)}
        </Typography>
      </CardContent>
    </Card>
  );
};
