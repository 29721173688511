import { EstateLaravelized, EstateLaravelizedJSON } from "./EstateLaravelized";
import { UserJSON, User } from "./User";

// database field names
export type EstateUserInfoJson = {
  id?: number;
  user_id?: number;
  user?: UserJSON;
  estate_id?: number;
  estate?: EstateLaravelizedJSON;
  declared_role?: string;
  name_of_corporate_executor?: string;
  relationship_to_deceased?: string;
  relationship_to_deceased_more?: string;
  advisory_position?: string;
  advisory_position_more?: string;
  is_first_time_executor?: boolean;
  initial_feeling_status?: string;
  created_at?: string;
  updated_at?: string;
};

export class EstateUserInfo {
  constructor(
    public id?: number,
    public user_id?: number,
    public user?: User,
    public estate_id?: number,
    public estate?: EstateLaravelized,
    public declaredRole?: string,
    public nameOfCorporateExecutor?: string,
    public relationshipToDeceased?: string,
    public relationshipToDeceasedMore?: string,
    public advisoryPosition?: string,
    public advisoryPositionMore?: string,
    public isFirstTimeExecutor?: boolean,
    public initialFeelingStatus?: string,
    public createdAt?: Date,
    public updatedAt?: Date
  ) {}

  static fromJSON(json: EstateUserInfoJson): EstateUserInfo {
    return new EstateUserInfo(
      json.id,
      json.user_id,
      json.user ? User.fromJSON(json.user) : undefined,
      json.estate_id,
      json.estate ? EstateLaravelized.fromJSON(json.estate) : undefined,
      json.declared_role,
      json.name_of_corporate_executor,
      json.relationship_to_deceased,
      json.relationship_to_deceased_more,
      json.advisory_position,
      json.advisory_position_more,
      json.is_first_time_executor,
      json.initial_feeling_status,
      json.created_at ? new Date(json.created_at) : undefined,
      json.updated_at ? new Date(json.updated_at) : undefined
    );
  }

  toJSON(): EstateUserInfoJson {
    return {
      id: this.id,
      user_id: this.user_id,
      user: this.user ? this.user.toJson() : undefined,
      estate_id: this.estate_id,
      estate: this.estate ? this.estate.toJSON() : undefined,
      declared_role: this.declaredRole,
      name_of_corporate_executor: this.nameOfCorporateExecutor,
      relationship_to_deceased: this.relationshipToDeceased,
      relationship_to_deceased_more: this.relationshipToDeceasedMore,
      advisory_position: this.advisoryPosition,
      advisory_position_more: this.advisoryPositionMore,
      is_first_time_executor: this.isFirstTimeExecutor,
      initial_feeling_status: this.initialFeelingStatus,
      created_at: this.createdAt ? this.createdAt.toISOString() : undefined,
      updated_at: this.updatedAt ? this.updatedAt.toISOString() : undefined,
    };
  }

  get advisoryPositionDisplay(): string {
    if (this.advisoryPosition && this.advisoryPosition !== "Something else") {
      return this.advisoryPosition;
    } else if (this.advisoryPositionMore) {
      return this.advisoryPositionMore;
    } else {
      return "--";
    }
  }

  get relationshipDisplay(): string {
    if (
      this.relationshipToDeceased &&
      this.relationshipToDeceased !== "Something else"
    ) {
      return this.relationshipToDeceased;
    } else if (this.relationshipToDeceasedMore) {
      return this.relationshipToDeceasedMore;
    } else {
      return "--";
    }
  }
}
