import { User } from "../models/User";
import { SCREEN_PATHS } from "../constants";
import { NavigateFunction } from "react-router";

export const restrictScreenAccess = (
  user: User,
  navigate: NavigateFunction
): void => {
  // list or details
  const atEstate = location.pathname.includes("/app/estate", 0);
  const atEstateFormsAndFiles =
    location.pathname.includes("/app/estate", 0) &&
    location.pathname.includes(SCREEN_PATHS.formsAndFilesSubpath);
  const atDashboard = location.pathname.includes(SCREEN_PATHS.dashboard, 0);
  const atOpportunities = location.pathname.includes(SCREEN_PATHS.opps, 0);
  // advisor or client
  const atInvite = location.pathname.includes("/app/invite", 0);
  const atAssignments = location.pathname.includes("/app/assignments", 0);
  const atFormsLibrary = location.pathname.includes(
    SCREEN_PATHS.formsLibrary,
    0
  );

  if (
    !user?.hasEstateAccess &&
    (atEstate || atDashboard || atOpportunities || atInvite)
  ) {
    setTimeout(() => navigate(SCREEN_PATHS.support), 0);
  }
  if (!user?.hasAssignmentToolAccess && atAssignments) {
    setTimeout(() => navigate(SCREEN_PATHS.support), 0);
  }
  if (!user?.advisorGroup?.hasAdvisorAccessToForms && atEstateFormsAndFiles) {
    setTimeout(
      () =>
        navigate(
          location.pathname.replace(SCREEN_PATHS.formsAndFilesSubpath, "")
        ),
      0
    );
  }
  if (!user?.advisorGroup?.hasAdvisorAccessToForms && atFormsLibrary) {
    setTimeout(() => navigate(SCREEN_PATHS.dashboard), 0);
  }
};
