import { ActionTypes } from "../actions/types";
import { AnyAction } from "redux";
import { ProbateType } from "../models/ProbateType";

export const probateTypesReducer = (
  probateTypes: ProbateType[] | null = null,
  action: AnyAction
): ProbateType[] | null => {
  switch (action.type) {
    case ActionTypes.FetchProbateTypes:
      return action.payload;
    case ActionTypes.AppSignOut:
      return null;
    default:
      return probateTypes;
  }
};
