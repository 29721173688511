import { Palette } from "@mui/material";
import { TypographyOptions } from "@mui/material/styles/createTypography";

export const getTypography = (palette: Palette): TypographyOptions => ({
  fontFamily: '"Montserrat", "Helvetica", "Arial", sans-serif',
  fontWeightLight: 400,
  fontWeightRegular: 500,
  fontWeightMedium: 700,
  fontWeightBold: 800,
  h1: {
    fontWeight: 500,
    fontSize: 35,
    letterSpacing: "-0.24px",
    color: palette.text.primary,
  },
  h2: {
    fontWeight: 700,
    fontSize: 28,
    lineHeight: "36px",
    color: palette.text.primary,
    // letterSpacing: '-0.24px'
  },
  h3: {
    fontWeight: 500,
    fontSize: 24,
    letterSpacing: "-0.06px",
    color: palette.text.primary,
  },
  h4: {
    fontWeight: 500,
    fontSize: 20,
    letterSpacing: "-0.06px",
    color: palette.text.secondary,
  },
  h5: {
    fontWeight: 500,
    fontSize: 16,
    letterSpacing: "-0.05px",
    color: palette.text.secondary,
  },
  h6: {
    fontWeight: 500,
    fontSize: 14,
    letterSpacing: "-0.05px",
    color: palette.text.secondary,
  },
  overline: {
    fontWeight: 500,
  },
});
