import { ActionTypes } from "./types";
import { geographiesApi } from "../apis";
import { Dispatch } from "redux";
import { Country, State, County } from "../models/Geography";

export interface FetchCountriesAction {
  type: ActionTypes.FetchCountries;
  payload: Country[] | null;
}

export const fetchCountries = () => {
  return async (dispatch: Dispatch): Promise<void> => {
    let countries: Country[] | null = [];
    try {
      countries = await geographiesApi.fetchCountries();
    } catch (error) {
      console.error(error);
    }
    dispatch<FetchCountriesAction>({
      type: ActionTypes.FetchCountries,
      payload: countries,
    });
  };
};

export interface FetchStatesAction {
  type: ActionTypes.FetchStates;
  payload: State[] | null;
}

export const fetchStates = () => {
  return async (dispatch: Dispatch): Promise<void> => {
    let states: State[] | null = [];
    try {
      states = await geographiesApi.fetchStates();
    } catch (error) {
      console.error(error);
    }
    dispatch<FetchStatesAction>({
      type: ActionTypes.FetchStates,
      payload: states,
    });
  };
};

export interface FetchCountiesAction {
  type: ActionTypes.FetchCounties;
  payload: County[] | null;
}

export const fetchCounties = (stateId: number) => {
  return async (dispatch: Dispatch): Promise<void> => {
    let counties: County[] | null = [];
    try {
      counties = await geographiesApi.fetchCounties(stateId);
    } catch (error) {
      console.error(error);
    }
    dispatch<FetchCountiesAction>({
      type: ActionTypes.FetchCounties,
      payload: counties,
    });
  };
};
