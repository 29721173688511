import { LanguageJson, Language } from "./Language";
export enum GeographyType {
  Country = "Country",
  State = "State",
  County = "County",
}

export const stateLabelMappings: { [key: string]: string } = {
  "united-states": "State",
  canada: "Province/Territory",
};

export const stateLabelMappingsById: { [key: number]: string } = {
  1: "State",
  2: "Province/Territory",
};

export interface StateContentJson {
  id: number;
  name: string;
  code: string;
  states_id: number;
  language: LanguageJson;
  // state_obituary_requirement: string,
  // state_small_estate_requirement: string,
  // state_statute_of_limitation: string,
  // state_executor_compensation: string,
  // state_inventory_appraisal_filing: string,
  // obituary_legal_source: string,
  // small_legal_source: string,
  // statute_legal_source: string,
  // executor_legal_source: string,
  // inventory_legal_source: string,
  // value_threshold: number,
  has_probate_definitions: boolean;
  probate_fallback_content?: string;
}

export class StateContent {
  constructor(
    public name: string,
    public code: string,
    public id: number,
    public stateId: number,
    public language: Language,
    public hasProbateDefinitions: boolean,
    public probateFallbackContent?: string
  ) {}

  static fromJSON(json: StateContentJson): StateContent {
    return new StateContent(
      json.name,
      json.code,
      json.id,
      json.states_id,
      Language.fromJSON(json.language),
      json.has_probate_definitions,
      json.probate_fallback_content
    );
  }

  toJSON(): StateContentJson {
    return {
      name: this.name,
      code: this.code,
      id: this.id,
      states_id: this.stateId,
      language: this.language.toJSON(),
      has_probate_definitions: this.hasProbateDefinitions,
      probate_fallback_content: this.probateFallbackContent,
    };
  }
}

export interface GeographyJson {
  id: number;
  name: string;
  slug: string;
  geography_type?: GeographyType;
  states?: GeographyJson[]; // Only for Country
  code?: string; // Only for Country and State
  content?: StateContentJson[]; // Only for State
  countries_id?: number; // Only for State
  country?: GeographyJson; // Only for State
  counties?: GeographyJson[]; // Only for State
  state_id?: number; // Only for County
  state?: GeographyJson; // Only for County
}

export class Geography {
  constructor(
    public id: number,
    public name: string,
    public fullName: string,
    public slug: string,
    public geographyType?: GeographyType
  ) {}

  toJSON(): GeographyJson {
    return {
      id: this.id,
      name: this.name,
      slug: this.slug,
      geography_type: this.geographyType,
    };
  }
}

export class Country extends Geography {
  constructor(
    public id: number,
    public name: string,
    public fullName: string,
    public code: string,
    public slug: string,
    public geographyType?: GeographyType
  ) {
    super(id, name, fullName, slug, geographyType);
  }

  toJSON(): GeographyJson {
    const retJson = {
      id: this.id,
      name: this.name,
      code: this.code,
      slug: this.slug,
      geography_type: this.geographyType,
    };
    return retJson;
  }

  static fromJSON(json: GeographyJson): Country {
    if (!json.code) {
      throw new Error("Country code is missing");
    }

    return new Country(
      json.id,
      json.name,
      json.name,
      json.code,
      json.slug,
      json.geography_type
    );
  }
}

export class State extends Geography {
  constructor(
    public id: number,
    public name: string,
    public fullName: string,
    public code: string,
    public slug: string,
    public content: StateContent,
    public countryId: number,
    public geographyType?: GeographyType
  ) {
    super(id, name, fullName, slug, geographyType);
  }

  toJSON(): GeographyJson {
    const retJson = {
      id: this.id,
      name: this.name,
      code: this.code,
      slug: this.slug,
      geography_type: this.geographyType,
      content: [this.content.toJSON()],
      countries_id: this.countryId,
    };
    return retJson;
  }

  static fromJSON(json: GeographyJson): State {
    if (!json.content) {
      throw new Error("State's states_content is missing");
    }
    if (!json.countries_id) {
      throw new Error("State's countries_id is missing");
    }

    // Get the State Content to use based on current user's language.
    let contentToUse = json.content[0];
    for (let i = 0; i < json.content.length; i++) {
      const currentContent: StateContentJson = json.content[i];
      if (currentContent.language.code.toLowerCase() == "en") {
        contentToUse = currentContent;
        break;
      }
    }
    const stateContent = StateContent.fromJSON(contentToUse);

    return new State(
      json.id,
      stateContent.name,
      json.country?.name
        ? `${stateContent.name}, ${json.country?.code}`
        : stateContent.name,
      stateContent.code,
      json.slug,
      stateContent,
      json.countries_id,
      json.geography_type
    );
  }
}

export class County extends Geography {
  constructor(
    public id: number,
    public name: string,
    public fullName: string,
    public slug: string,
    public stateId: number,
    public geographyType?: GeographyType
  ) {
    super(id, name, fullName, slug, geographyType);
  }

  toJSON(): GeographyJson {
    const retJson = {
      id: this.id,
      name: this.name,
      slug: this.slug,
      geography_type: this.geographyType,
      state_id: this.stateId,
    };
    return retJson;
  }

  static fromJSON(json: GeographyJson): County {
    if (!json.state_id) {
      throw new Error("County's state_id is missing");
    }

    // Get the State Content to use based on current user's language.
    let contentToUse;
    if (json.state && json.state.content) {
      contentToUse = json.state?.content?.[0];
      for (let i = 0; i < json.state?.content.length; i++) {
        const currentContent: StateContentJson = json.state.content[i];
        if (currentContent.language.code.toLowerCase() == "en") {
          contentToUse = currentContent;
          break;
        }
      }
    }

    return new County(
      json.id,
      json.name,
      contentToUse ? `${json.name}, ${contentToUse.code}` : json.name,
      json.slug,
      json.state_id,
      json.geography_type
    );
  }
}
