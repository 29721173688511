import React, { useContext, useEffect, useState } from "react";
import { Box, Typography } from "@mui/material";
import { Formik, FormikHelpers } from "formik";
const Yup = require("yup")
import { AssetDialogImages } from "./AssetDialogImages";
import {
  Asset,
  CategoryAttributes,
  AttributeGroup,
} from "../../../../../models/Asset";
import { RenderContentSection } from "./RenderContentSection";
import { AssetFormContextWrapper } from "./AssetFormContext";
// hard-coded field details to make legacy asset database
import { attributesByCategory } from "./asset-attributes";
import { AssetsContext } from "../AssetsContext";
import { AssetCategory } from "../../../../../models/AssetCategory";
import { AssetSubCategory } from "../../../../../models/AssetSubCategory";
import { InputType } from "./inputs/InputTypes";
import { assetApi } from "../../../../../apis";
import { Estate } from "../../../../../models/Estate";
import { AssetImage } from "../../../../../models/AssetImage";

export interface AssetFormValues {
  category: number;
  subcategory: number | null;
  name: string;
  value: number | null;
  description: string;
  is_probate: string; // this will be translated to boolean or null
  // -- household --
  quantity: number | null;
  // -- digital --
  web_address: string;
  // userName: string;
  // password: string;
  // -- financial --
  bank_institution: string;
  // -- real estate --
  address1: string;
  address2: string;
  city: string;
  states_id: number | null;
  zip_code: string;
  assessor_parcel_number: string;
  countries_id: number | null;
  counties_id: number | null;
  google_places_id: string;
  formatted_address: string;
  ownership: string;
  has_mortgage: string;
  remaining_mortgage: number | null;
  title_type: string;
  title: string;
  future_plan: string;
  // -- vehicles --
  year: number | null;
  make: string;
  model: string;
  vin: string;
}

export type InputMatches = {
  regexp: RegExp;
  failMessage: string;
};

// these will contain Yup field schemas
type SectionSchema = {
  [key: string]: any;
};

interface Props {
  navBar: JSX.Element;
  asset?: Asset;
  estate?: Estate;
  formSubmitTrigger?: number;
  onComplete?: (refresh?: boolean) => void;
  onTouched?: () => void;
  onSubmitting?: (isSubmitting: boolean) => void;
  onImagesEdited?: () => void;
}

export const AssetDialogForm = ({
  navBar,
  asset,
  estate,
  formSubmitTrigger,
  onComplete,
  onTouched,
  onSubmitting,
  onImagesEdited,
}: Props): JSX.Element => {
  const { categories } = useContext(AssetsContext) || {};

  const isNew = !asset?.id;
  const [activeSection, setActiveSection] = useState<string>(
    isNew ? "all" : ""
  );
  const [assetImages, setAssetImages] = useState<AssetImage[]>(
    asset?.images || []
  );

  // updating this will trigger an event the image complete is listening for
  const [assetIdUploadTrigger, setAssetIdUploadTrigger] = useState(0);

  useEffect(() => {
    // handle event coming in from parent element - save click event
    if (formSubmitTrigger && formSubmitTrigger > 0) {
      submitForm();
    }
  }, [formSubmitTrigger]);

  const submitForm = (): void => {
    (
      window.document.getElementById("asset-form-submit") as HTMLInputElement
    ).click();
  };

  // https://github.com/jquense/yup/tree/pre-v1
  const sectionSchema: SectionSchema = {
    overview: {
      category: Yup.number().required(),
      subcategory: Yup.number().nullable(),
    },
    basic: {
      name: Yup.string().required(),
      isProbate: Yup.boolean().nullable(),
    },
    value: {
      value: Yup.number().nullable(),
    },
    other: {
      description: Yup.string(),
    },
  };

  const getValidationSchema = () => {
    if (activeSection === "all") {
      return Yup.object().shape({
        ...sectionSchema.overview,
        ...sectionSchema.basic,
        ...sectionSchema.value,
        ...sectionSchema.other,
      });
    } else if (activeSection in sectionSchema) {
      return Yup.object().shape(sectionSchema[activeSection]);
    }
  };

  const handleSubmit = async (
    values: AssetFormValues,
    { setSubmitting, setStatus }: FormikHelpers<AssetFormValues>
  ) => {
    setStatus("");
    onSubmitting?.(true); // notify parent
    try {
      const assetToSave = Asset.fromFormValues(asset?.id, values, categories);
      const savedAsset = await assetApi.saveAsset(estate?.id, assetToSave);
      // start image upload
      savedAsset?.id && setAssetIdUploadTrigger(savedAsset.id);
      // the upload images process which will result in handleImagesComplete
      //  even if no new images are uploaded
    } catch (error) {
      console.error(error);
      setStatus("Sorry, error: " + ((error as Error)?.message || ""));
      setSubmitting(false);
    }
    onSubmitting?.(false); // notify parent
  };

  // fired by AssetDialogImages uploadComplete
  const handleImagesComplete = (success?: boolean) => {
    // images uploads have completed, now close this form and refresh
    if (success) {
      onComplete?.(true);
    } else {
      // images upload failed, but asset created.
    }
  };

  const getAttributesBySection = (categoryId?: number) => {
    const assetCategory = categories?.find(
      (cat: AssetCategory) => cat.id === categoryId
    );
    const categoryAttrGroups = attributesByCategory.find(
      (catAttr: CategoryAttributes) =>
        catAttr?.categoryName === assetCategory?.category
    )?.groups;

    // order by section
    return {
      overview: categoryAttrGroups?.find(
        (group: AttributeGroup) => group?.group === "overview"
      )?.attributes,
      basic: categoryAttrGroups?.find(
        (group: AttributeGroup) => group?.group === "basic"
      )?.attributes,
      value: categoryAttrGroups?.find(
        (group: AttributeGroup) => group?.group === "value"
      )?.attributes,
      other: categoryAttrGroups?.find(
        (group: AttributeGroup) => group?.group === "other"
      )?.attributes,
    };
  };

  // console.log("asset", asset);
  return asset ? (
    <>
      <a id="top" className="section" />
      <Box
        sx={{
          textAlign: "left",
        }}
      >
        <Formik
          initialValues={asset.toFormValues()}
          validationSchema={getValidationSchema()}
          onSubmit={handleSubmit}
          enableReinitialize
        >
          {(helpers) => {
            // destructure the helpers you need for this context
            const {
              errors,
              handleSubmit,
              handleReset,
              values,
              status,
              touched,
            } = helpers;
            // publish touched to the parent
            if (Object.keys(touched)?.length > 0) {
              onTouched?.();
            }
            const sectionAttributes = getAttributesBySection(values?.category);
            return (
              <AssetFormContextWrapper
                helpers={helpers}
                asset={asset}
                activeSection={activeSection}
              >
                <>
                  <AssetDialogImages
                    assetId={asset?.id}
                    assetImages={assetImages}
                    estateId={estate?.id}
                    estateWritable={estate?.hasWriteAccess}
                    assetIdUploadTrigger={assetIdUploadTrigger}
                    uploadComplete={(success?: boolean) => {
                      onImagesEdited?.();
                      handleImagesComplete(success);
                    }}
                    removeImage={(imageId?: number) => {
                      onImagesEdited?.();
                      setAssetImages(
                        assetImages?.filter(
                          (image: AssetImage) => image?.id !== imageId
                        ) || []
                      );
                    }}
                  />
                  <form onSubmit={handleSubmit}>
                    {navBar}
                    <Typography color="error">{status}</Typography>
                    {/* hard-coded overview section for integration with 2022 assets database tables */}
                    <RenderContentSection
                      sectionId="overview"
                      hasWriteAccess={estate?.hasWriteAccess}
                      assetAttributes={[
                        {
                          label: "Category",
                          key: "category",
                          caption: undefined,
                          placeholder: undefined,
                          type: InputType.select,
                          regex: null,
                          value: values.category,
                          options: categories?.map((cat: AssetCategory) => {
                            return { label: cat.category, value: cat.id };
                          }),
                        },
                        {
                          label: "Subcategory",
                          key: "subcategory",
                          caption: undefined,
                          placeholder: undefined,
                          // will hide this field until cat is selected
                          type: values.category ? InputType.select : undefined,
                          regex: null,
                          value: values.subcategory,
                          options: categories
                            ?.find(
                              (cat: AssetCategory) => cat.id === values.category
                            )
                            ?.subCategories?.map((subcat: AssetSubCategory) => {
                              return {
                                label: subcat.subcategory,
                                value: subcat.id,
                              };
                            }),
                        },
                      ]}
                      onEdit={() => setActiveSection("overview")}
                      onCancel={() => {
                        handleReset();
                        setActiveSection("");
                      }}
                      onSave={() => {
                        handleSubmit();
                        if (Object.keys(errors)?.length === 0) {
                          setActiveSection("");
                        }
                      }}
                    />
                    {/* if category has been selected show the other fields */}
                    {!!values?.category && (
                      <>
                        <RenderContentSection
                          sectionId="basic"
                          sectionTitle="Basic Info"
                          hasWriteAccess={estate?.hasWriteAccess}
                          assetAttributes={sectionAttributes?.basic}
                          onEdit={() => setActiveSection("basic")}
                          onCancel={() => {
                            handleReset();
                            setActiveSection("");
                          }}
                          onSave={() => {
                            handleSubmit();
                            if (Object.keys(errors)?.length === 0) {
                              setActiveSection("");
                            }
                          }}
                        />
                        <RenderContentSection
                          sectionId="value"
                          sectionTitle="Value"
                          hasWriteAccess={estate?.hasWriteAccess}
                          assetAttributes={sectionAttributes?.value}
                          onEdit={() => setActiveSection("value")}
                          onCancel={() => {
                            handleReset();
                            setActiveSection("");
                          }}
                          onSave={() => {
                            handleSubmit();
                            if (Object.keys(errors)?.length === 0) {
                              setActiveSection("");
                            }
                          }}
                        />
                        <RenderContentSection
                          sectionId="other"
                          sectionTitle="Other"
                          hasWriteAccess={estate?.hasWriteAccess}
                          assetAttributes={sectionAttributes?.other}
                          onEdit={() => setActiveSection("other")}
                          onCancel={() => {
                            handleReset();
                            setActiveSection("");
                          }}
                          onSave={() => {
                            handleSubmit();
                            if (Object.keys(errors)?.length === 0) {
                              setActiveSection("");
                            }
                          }}
                        />
                      </>
                    )}
                    <Box sx={{ visibility: "hidden" }}>
                      <input type="submit" id="asset-form-submit" />
                    </Box>
                  </form>
                </>
              </AssetFormContextWrapper>
            );
          }}
        </Formik>
      </Box>
    </>
  ) : (
    <></>
  );
};
