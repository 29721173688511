import { Box, Typography } from "@mui/material";
import { Asset } from "../../../../../models/Asset";

interface Props {
  asset?: Asset;
}

export const AssetDialogHeader = ({ asset }: Props): JSX.Element => {
  const getCategories = (): JSX.Element => {
    let categories = asset?.category?.category;
    if (asset?.subcategory?.subcategory) {
      categories += ` | ${asset?.subcategory?.subcategory}`;
    }
    if (asset?.is_probate) {
      categories += ` | Probate`;
    }
    return <Typography variant="body2">{categories}</Typography>;
  };

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
      }}
    >
      <Box
        sx={{
          textAlign: "left",
        }}
      >
        <Typography
          variant="h3"
          sx={{
            fontWeight: "800",
            color: asset?.asset ? "inherit" : "grey.400",
          }}
        >
          {asset?.asset || "Add Asset"}
        </Typography>
        {getCategories()}
      </Box>
      {asset?.value && (
        <Box
          sx={{
            textAlign: "right",
          }}
        >
          <Typography
            sx={{
              fontWeight: "bold",
            }}
          >{`$${asset.value.toLocaleString()}`}</Typography>
          <Typography variant="body2">Fair market value</Typography>
        </Box>
      )}
    </Box>
  );
};
