import { Task } from "./Task";

// database field names
export type TaskPhaseJSON = {
  id: number;
  task_phase: string;
  sequence: number;
  status: string;
  icon?: string;
  tasks?: Task[];
  task_complete_count: number;
  task_count: number;
};

export class TaskPhase {
  constructor(
    public id: number,
    public taskPhase: string,
    public sequence: number,
    public status: string,
    public taskCompleteCount: number,
    public taskCount: number,
    public icon?: string,
    public tasks?: Task[]
  ) {}

  // from database
  static fromJSON(json: TaskPhaseJSON): TaskPhase {
    return new TaskPhase(
      json.id,
      json.task_phase,
      json.sequence,
      json.status,
      json.task_complete_count,
      json.task_count,
      json.icon,
      json.tasks && json.tasks.length > 0
        ? json.tasks.map((taskJson: any) => Task.fromJSON(taskJson))
        : undefined
    );
  }

  // clone
  static from(taskPhase: TaskPhase): TaskPhase {
    return new TaskPhase(
      taskPhase.id,
      taskPhase.taskPhase,
      taskPhase.sequence,
      taskPhase.status,
      taskPhase.taskCompleteCount,
      taskPhase.taskCount,
      taskPhase.icon,
      taskPhase.tasks && taskPhase.tasks.length > 0
        ? taskPhase.tasks.map((task: Task) => Task.from(task))
        : undefined
    );
  }
}
