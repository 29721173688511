import { Estate } from "../models/Estate";
import { Asset } from "../models/Asset";
import { Report } from "../models/Report";
import { TaskPhase } from "../models/TaskPhase";
import { callGet, callPost, callPut, logger } from "./index";
// import { EstateSummary } from "../models/EstateSummary";
import { AxiosResponse } from "axios";
import { ESTATE_SORT_BY, ESTATE_SORT_DIR } from "../constants";

export type EstatesWithTotal = {
  estates: Estate[];
  total: number;
};

export type ReportsWithTotal = {
  reports: Report[];
  total: number;
};

export class AtticusEstate {
  async fetchEstates(
    limit = 10,
    offset = 0,
    sort = ESTATE_SORT_BY.activity,
    dir = ESTATE_SORT_DIR.dsc,
    includeDeleted = false,
    gid?: number
  ): Promise<EstatesWithTotal> {
    let path = `${Estate.path}?limit=${limit}&offset=${offset}&sort=${sort}&dir=${dir}`;
    if (includeDeleted) path += "&deleted=true";
    if (gid) path += `&gid=${gid}`;
    const response = await callGet(path);
    const total = response?.data?.total_count;
    let estates: Estate[];
    if (response?.data?.estates && Array.isArray(response.data.estates)) {
      estates = response.data.estates.map((json: any) => Estate.fromJSON(json));
      // estates = this.dedupById(estates);
      return {
        estates,
        total,
      };
    } else {
      logger.error("no response.data", false);
    }
    // empty user indicates not authenticated or error
    return { estates: [new Estate()], total: 0 };
  }

  async fetchMockEstates(): Promise<EstatesWithTotal> {
    const path = `${Estate.path}/mock`;
    const response = await callGet(path);
    let estates: Estate[];
    if (response?.data?.estates && Array.isArray(response.data.estates)) {
      estates = response.data.estates.map((json: any) => Estate.fromJSON(json));
      // estates = this.dedupById(estates);
      return {
        estates,
        total: estates.length,
      };
    } else {
      logger.error("no response.data", false);
    }
    // empty user indicates not authenticated or error
    return { estates: [new Estate()], total: 0 };
  }

  async fetchSingleEstate(estateId: number): Promise<Estate> {
    try {
      const response = await callGet(Estate.path + "/" + estateId, true);
      if (response?.data) {
        const estate = Estate.fromJSON(response.data);
        return estate;
      } else {
        logger.error("no response.data", false);
      }
      // empty user indicates not authenticated or error
      return new Estate();
    } catch (error) {
      if (
        (error as any)?.response?.status === 404 ||
        (error as any)?.response?.status === 401
      ) {
        // requesting missing or invalid resource
        window.document.location.href = `/app/estates`;
      }
    }
    return new Estate();
  }

  async searchEstates(
    terms: string,
    groupId?: number
  ): Promise<EstatesWithTotal> {
    const response = await callPost(Estate.path + `/search`, {
      terms,
      gid: groupId,
    });
    const total = response?.data?.total_count;
    let estates: Estate[];
    if (response?.data?.estates && Array.isArray(response.data.estates)) {
      estates = response.data.estates.map((json: any) => Estate.fromJSON(json));
      return {
        estates,
        total,
      };
    } else {
      logger.error("no response.data", false);
    }
    // empty user indicates not authenticated or error
    return { estates: [new Estate()], total: 0 };
  }

  async fetchEstateAssets(id: number): Promise<Asset[] | undefined> {
    const response = await callGet(`${Estate.path}/${id}/assets`);
    return (
      response?.data.data.assets.map((json: any) => Asset.fromJSON(json)) ||
      ([] as Asset[])
    );
  }

  async fetchEstateReports(
    estateId: number,
    name?: string
  ): Promise<Report[] | undefined> {
    const response = await callGet(`${Estate.path}/${estateId}/reports`);
    return (
      response?.data?.map((json: any) =>
        Report.fromJSON(json, name, estateId)
      ) || ([] as Report[])
    );
  }

  async fetchReports(limit = 10, offset = 0): Promise<ReportsWithTotal> {
    const response = await callGet(
      `${Report.path}?limit=${limit}&offset=${offset}`
    );
    const total = response?.data?.total_count;
    let reports: Report[];
    if (response?.data?.reports && Array.isArray(response.data.reports)) {
      reports = response.data.reports.map((json: any) => Report.fromJSON(json));
      return {
        reports,
        total,
      };
    } else {
      logger.error("no response.data", false);
    }
    return { reports: [new Report()], total: 0 };
  }

  async getReport(
    id?: number,
    rethrowError = true
  ): Promise<AxiosResponse | null> {
    return await callGet(`${Report.path}/${id}/download`, rethrowError, {
      responseType: "blob",
    });
  }

  async createReport(estateId: string): Promise<AxiosResponse | null> {
    return await callPost(`${Report.path}/${estateId}`, {}, true);
  }

  async fetchEstateTasks(id: number): Promise<TaskPhase[] | undefined> {
    const response = await callGet(`${Estate.path}/${id}/tasks`);
    return (
      response?.data?.map((json: any) => TaskPhase.fromJSON(json)) ||
      ([] as TaskPhase[])
    );
  }

  async saveEstateTask(
    id: number,
    taskId: number,
    answer?: number | null
  ): Promise<boolean> {
    const response = await callPost(`${Estate.path}/${id}/tasks`, {
      task_id: taskId,
      bool_answer: answer,
    });
    return response?.data?.["success"];
  }

  async createDemoEstates(
    demoEstates: string,
    advisorGroupId: number
  ): Promise<AxiosResponse | null> {
    return await callPost(`${Estate.path}/demo`, {
      demo_estates: demoEstates,
      advisor_group_id: advisorGroupId,
    });
  }

  async saveProbateType(
    estateId: number,
    probateTypeId: number | string
  ): Promise<AxiosResponse | null> {
    return await callPut(`${Estate.path}/${estateId}/probate-type`, {
      probate_type_id: probateTypeId,
    });
  }

  async saveIsDemo(
    estateId: number,
    demo: boolean
  ): Promise<AxiosResponse | null> {
    return await callPut(`${Estate.path}/${estateId}/is-demo`, {
      is_demo: demo,
    });
  }

  async reassignUserToEmail(
    estateId: number,
    email: string
  ): Promise<AxiosResponse | null> {
    return await callPost(
      `${Estate.path}/${estateId}/reassign`,
      {
        email,
      },
      true
    );
  }

  dedupById(estates: Estate[]): Estate[] {
    return estates
      .slice()
      .reverse()
      .filter(
        (estate: Estate, index: number, estates: Estate[]) =>
          estates.findIndex((estate2) => estate2.id === estate.id) === index
      )
      .reverse();
  }
}
