export enum ActionTypes {
  SetThemeKey,
  SetSearchTerms,
  SetTotalEstates,
  FetchAdvisedEstateIds,
  SetUser,
  FetchAdvisors,
  ClearAdvisors,
  AssignToAdvisor,
  UnassignFromAdvisor,
  FetchEstates,
  FetchMockEstates,
  FetchSingleEstate,
  FetchSingleOpportunity,
  SetConfig,
  CacheEstateAvatar,
  CacheAssetImage,
  FetchEstateAssets,
  FetchEstateReports,
  FetchTaskPhases,
  ClearTaskPhases,
  SaveTask,
  FetchEstateNotes,
  SaveEstateNote,
  DeleteEstateNote,
  ClearEstates,
  SearchEstates,
  ClearSearchedEstates,
  FetchAdvisorGroups,
  ClearAdvisorGroups,
  SaveAdvisorGroup,
  FetchOpportunities,
  FetchOpportunitiesCount,
  LoadingOpportunities,
  SaveOpportunity,
  DeleteOpportunity,
  FetchOpportunityTypes,
  FetchOpportunityStatuses,
  FetchSingleFinancialConvo,
  FetchSingleFinancialConvoFromOpp,
  FetchFinancialConvos,
  FetchFormTypes,
  FetchSingleFormType,
  FetchFormStatusTypes,
  FetchSingleFormStatusType,
  SaveAtticusForm,
  DeleteAtticusForm,
  FetchStates,
  FetchCounties,
  FetchSingleCounty,
  FetchCountries,
  FetchLanguages,
  FetchSingleLanguage,
  FetchProbateTypes,
  FetchEstateForms,
  AddEstateForm,
  RemoveEstateForm,
  FlushEstateForms,
  SetEstateIsDemo,
  FetchKnowledgeEngineFieldCategories,
  AppSignOut,
}
