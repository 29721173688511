import React from "react";
import { Box, Button, Card, CardContent, Typography } from "@mui/material";
import { SxProps } from "@mui/system";
import { Theme } from "@mui/material/styles";

interface Props {
  title?: string | JSX.Element;
  subtitle?: string | JSX.Element;
  onClick?: () => void;
  clickText?: string;
  icon?: JSX.Element;
  isCreate?: boolean;
  disabled?: boolean;
  sx?: SxProps<Theme>;
}

export const ReportCard = ({
  title,
  subtitle = <></>,
  onClick,
  clickText = "View",
  isCreate,
  icon,
  disabled = false,
  sx,
}: Props): JSX.Element => {
  return (
    <Card sx={sx}>
      <CardContent
        sx={{
          display: "flex",
          justifyContent: "space-between",
          flexDirection: {
            xs: "column",
            sm: "column",
            md: "column",
            lg: "row",
          },
          background: isCreate
            ? "linear-gradient(120.89deg, #734ED2 0%, #5E78E7 83.84%);"
            : "inherit",
          color: isCreate ? "common.white" : "inherit",
          "& div": {
            display: "flex",
          },
          p: 3,
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            pr: 1,
          }}
        >
          {icon && (
            <Box
              sx={{
                mr: 3,
              }}
            >
              {icon}
            </Box>
          )}
          <Box
            sx={{
              flexDirection: "column",
              maxWidth: 425,
            }}
          >
            {title && (
              <Typography
                variant="h5"
                sx={{
                  fontWeight: "bold",
                  color: isCreate ? "common.white" : "inherit",
                  mb: 1,
                }}
              >
                {title}
                {/*  Create a new report*/}
              </Typography>
            )}

            {typeof subtitle === "string" ? (
              <Typography variant="body2">{subtitle}</Typography>
            ) : (
              subtitle
            )}
          </Box>
        </Box>
        <Box
          sx={{
            alignItems: "center",
            mt: {
              xs: 2,
              sm: 2,
              md: 2,
              lg: 0,
            },
            justifyContent: "flex-end",
          }}
        >
          {onClick && (
            <Button
              disabled={disabled}
              variant={isCreate ? "outlined" : "contained"}
              sx={
                isCreate
                  ? {
                      color: "common.white",
                      borderColor: "common.white",
                      backgroundColor: "primary",
                      minWidth: 165,
                      "&:hover": {
                        borderColor: "common.white",
                        backgroundColor: "primary.dark",
                      },
                    }
                  : { minWidth: 165 }
              }
              onClick={onClick}
            >
              {clickText}
            </Button>
          )}
        </Box>
      </CardContent>
    </Card>
  );
};
