import React, { useEffect, useState, useRef } from "react";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import { StoreState } from "../../../reducers";
import { Box, Button, Grid, Typography } from "@mui/material";
import {
  fetchEstateAssets,
  fetchEstateReports,
} from "../../../actions/estateActions";
import { Estate } from "../../../models/Estate";
import { ReportsTable } from "./reports/ReportsTable";
import { User } from "../../../models/User";
import { OnboardingCard } from "./reports/OnboardingReport";
import { DetailCard } from "../../shared/DetailCard";
import {
  ContentState,
  ContentStateWrapper,
} from "../../shared/ContentStateWrapper";
import { ReportCard } from "./reports/ReportCard";
import { estateApi } from "../../../apis";
import { Report, ReportStatus } from "../../../models/Report";
import { ConfirmationDialog } from "../../shared/dialog/ConfirmationDialog";
import { TimedCircularLoader } from "../../shared/TimedCircularLoader";

import createReport from "../../../assets/magnify-doc.png";
import createReport2 from "../../../assets/magnify-doc@2x.png";
import createReport3 from "../../../assets/magnify-doc@3x.png";

import folder from "../../../assets/folder-files-green.png";
import folder2 from "../../../assets/folder-files-green@2x.png";
import folder3 from "../../../assets/folder-files-green@3x.png";
import { DialogMessage } from "../../shared/DialogMessage";
import { BigCircledCheck } from "../../shared/BigCircledCheck";

interface Props {
  user?: User;
  estates?: Estate[];
  fetchEstateReports: (estate: Estate) => void;
  fetchEstateAssets: (estate: Estate) => void;
}

const _ReportsView = ({
  // user,
  estates,
  fetchEstateReports,
  fetchEstateAssets,
}: Props): JSX.Element => {
  const { estateId } = useParams();
  // give the report this much time before attempting to poll for completion
  const createReportAnimation = 5000;
  const [isCreating, setIsCreating] = useState(false);
  const [createReportMessage, setCreateReportMessage] = useState<JSX.Element>();

  // when any report's status is processing, poll for updates
  const maxTries = 5;
  const refreshTries = useRef(maxTries);
  // useRef enables state tracking without component re-render
  const refreshReportsTimeout = useRef<number | null>(null);
  const createReportTimeout = useRef<number | null>(null);

  let estate: Estate | undefined;
  if (estateId && estates?.length) {
    estate = estates.find((estate: Estate) => estate.id === +estateId);
  }

  useEffect(() => {
    if (estate) {
      if (estate.reports === undefined) {
        fetchEstateReports(estate);
      }
      if (estate.assets === undefined) {
        fetchEstateAssets(estate);
      }
    }
    return () => {
      // in case there are any pending timeouts
      if (refreshReportsTimeout?.current) {
        clearTimeout(refreshReportsTimeout.current);
        refreshReportsTimeout.current = null;
      }
      if (createReportTimeout?.current) {
        clearTimeout(createReportTimeout.current);
        createReportTimeout.current = null;
      }
    };
  }, []);

  const refreshReports = (): void => {
    if (refreshTries?.current) {
      refreshTries.current = refreshTries.current - 1;
    }
    refreshReportsTimeout.current = null; // clear the timeout reference
    console.log("checking for report updates");
    estate && fetchEstateReports(estate);
  };

  // are any reports still processing?
  const isReportProcessing = (): boolean => {
    let stillProcessing = false;
    if (estate && estate?.reports?.length) {
      estate?.reports?.forEach((report: Report) => {
        if (report?.status === ReportStatus.processing) {
          stillProcessing = true;
        }
      });
    }
    return stillProcessing;
  };

  const getStillUpToDate = () => {
    return (
      <DialogMessage
        icon={<BigCircledCheck />}
        title="Still up to date!"
        body="The latest report is still up to date. In order for a new report to be
        generated, you need to make a new update or change to the estate."
      />
    );
  };

  const getReportError = (error?: string) => {
    return (
      <DialogMessage
        title="Sorry, there was an error creating the report"
        body={
          <>
            <Typography>Please try again.</Typography>
            <Typography>
              If this persists, please report this through Atticus support.
            </Typography>
            {error && <Typography>{error}</Typography>}
          </>
        }
      />
    );
  };

  const handleCreate = async () => {
    if (estateId) {
      setIsCreating(true);
      try {
        const result = await estateApi.createReport(estateId);
        if (result?.data?.report === false) {
          // show still up to date message
          setCreateReportMessage(getStillUpToDate());
          setIsCreating(false);
        } else if (result?.data?.id) {
          // ?
          window.setTimeout(() => {
            setIsCreating(false);
            refreshReports();
          }, createReportAnimation);
        } else {
          setCreateReportMessage(getReportError());
          setIsCreating(false);
        }
      } catch (error) {
        // show error
        if ((error as any)?.response?.status === 401) {
          setCreateReportMessage(getReportError("Sorry, access denied"));
        } else {
          const message =
            (error as any)?.response?.data?.message ||
            (error as any)?.message;
          setCreateReportMessage(getReportError(message));
        }
        setIsCreating(false);
      }
    }
  };

  // Every render:
  //  if any reports are still processing
  //    and there's not a pending refresh (refreshReportsTimeout)
  //  start a timer to refresh reports
  if (isReportProcessing() && refreshReportsTimeout.current === null) {
    // each refresh will be more delayed than the previous
    const delay = Math.abs(refreshTries.current - (maxTries + 1)) * 1000;
    if (refreshTries.current > 0) {
      refreshReportsTimeout.current = window.setTimeout(refreshReports, delay);
    }
  }

  return (
    <div data-automation-id="page-content-estate-reports">
      <ConfirmationDialog
        open={!!createReportMessage}
        description={createReportMessage || ""}
        confirmText="Ok"
        onConfirm={() => setCreateReportMessage(undefined)}
        confirmButtonColor="secondary"
        confirmWide={true}
      />
      <Grid container spacing={2}>
        <Grid item xs={12} md={8}>
          {estate?.hasWriteAccess ? (
            <ReportCard
              onClick={handleCreate}
              clickText="Create new report"
              isCreate={true}
              title="Create a new report"
              subtitle="Create an up to date report using the latest data we have available on file for your user"
              icon={
                <img
                  src={createReport}
                  srcSet={`${createReport2} 2x, ${createReport3} 3x`}
                  alt="Estate Report"
                />
              }
              sx={{
                mb: 2,
              }}
            />
          ) : (
            <></>
          )}
          {isCreating && (
            <ReportCard
              title="Generating new Report"
              subtitle="Your report will appear here in just a moment..."
              icon={
                <TimedCircularLoader
                  duration={createReportAnimation}
                  size={68}
                  incrementor={5}
                >
                  <img
                    src={folder}
                    srcSet={`${folder2} 2x, ${folder3} 3x`}
                    alt="Estate Report"
                  />
                </TimedCircularLoader>
              }
              sx={{
                mb: 2,
              }}
            />
          )}
          <ContentStateWrapper
            contentType="Estate Reports"
            contentTypeSingular="Estate Report"
            zeroText="It looks like this estate has not created any reports yet."
            zeroImagePrefix="/static/images/zero/reports"
            zeroShowAddButton={false}
            nonContentInCard={true}
            contentState={((): ContentState => {
              if (!estate?.reports) {
                return ContentState.loading;
              } else if (estate?.reports?.length === 0) {
                return ContentState.zero;
              } else {
                return ContentState.render;
              }
            })()}
          >
            {estate?.reports ? (
              <>
                <ReportsTable
                  reports={estate.reports}
                  onError={(error?: string) =>
                    setCreateReportMessage(getReportError(error))
                  }
                />
                <Box
                  sx={{
                    textAlign: "center",
                  }}
                >
                  <Button onClick={() => refreshReports()}>
                    refresh reports
                  </Button>
                </Box>
              </>
            ) : (
              <></>
            )}
          </ContentStateWrapper>
        </Grid>
        <Grid item xs={12} md={4}>
          <DetailCard title="Onboarding Report">
            <OnboardingCard estate={estate} assets={estate?.assets} />
          </DetailCard>
        </Grid>
      </Grid>
    </div>
  );
};

const mapStateToProps = ({
  user,
  estates,
}: StoreState): { user: User; estates: Estate[] } => {
  return { user, estates };
};

export const ReportsView = connect(mapStateToProps, {
  fetchEstateReports,
  fetchEstateAssets,
})(_ReportsView);
