import React from "react";
import {
  Button,
  Dialog,
  Box,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Typography,
  useTheme,
  useMediaQuery,
  Breakpoint,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

interface Props {
  open: boolean;
  headerImageSrc?: string;
  headerImageAlt?: string;
  title: string | JSX.Element | JSX.Element[];
  subtitle?: string | JSX.Element | JSX.Element[];
  content: string | JSX.Element | JSX.Element[];
  onConfirm: () => void;
  confirmText?: string;
  onDeny?: () => void;
  denyText?: string;
  contentTextAlign?: CanvasTextAlign;
  maxWidth?: Breakpoint | false;
}

export const DetailsDialog = ({
  open,
  headerImageSrc,
  headerImageAlt,
  title,
  subtitle,
  content,
  onConfirm,
  confirmText = "Yes",
  onDeny,
  denyText = "No",
  contentTextAlign = "center",
  maxWidth = "lg",
}: Props): JSX.Element => {
  const theme = useTheme();
  const isMobileSize = useMediaQuery(theme.breakpoints.down("sm"));
  return (
    <Dialog
      fullScreen={isMobileSize}
      fullWidth
      maxWidth={maxWidth}
      open={open}
      onClose={onDeny ? onDeny : onConfirm}
      aria-labelledby="details-dialog-title"
      className="noPadding"
      sx={{
        "& .MuiDialog-paper": {
          backgroundColor: "#f2f2ff",
          borderRadius: isMobileSize ? undefined : "0.5rem",
          padding: isMobileSize
            ? "2rem 1rem !IMPORTANT"
            : "4rem 3rem !important",
        },
      }}
    >
      {open && (
        <>
          <DialogTitle
            id="details-dialog-title"
            component="div"
            className="centeredText"
          >
            <Box>
              {headerImageSrc && (
                <Box
                  component="img"
                  src={headerImageSrc}
                  alt={headerImageAlt}
                  sx={{
                    maxWidth: "125px",
                    maxHeight: "125px",
                    height: "100%",
                    width: "100%",
                    marginBottom: "1.5rem",
                  }}
                />
              )}
              <Typography
                variant="h4"
                color="primary.dark"
                sx={{
                  marginBottom: "0.75rem",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  wordBreak: "break-word",
                  display: "-webkit-box",
                  WebkitLineClamp: "3",
                  WebkitBoxOrient: "vertical",
                }}
              >
                {title}
              </Typography>
              {subtitle && (
                <Typography
                  variant="caption"
                  color="primary.dark"
                  sx={{ marginBottom: "0.75rem" }}
                >
                  {subtitle}
                </Typography>
              )}
            </Box>

            <IconButton
              aria-label="close"
              sx={{
                position: "absolute",
                right: 0,
                top: 0,
                padding: "1rem",
                color: "grey.500",
              }}
              onClick={onDeny ? onDeny : onConfirm}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent
            style={{
              textAlign: contentTextAlign,
            }}
          >
            {content instanceof String ? (
              <DialogContentText id="details-dialog-description">
                {content}
              </DialogContentText>
            ) : (
              content
            )}
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => onConfirm()}
              color="secondary"
              variant="contained"
              autoFocus
              sx={{
                width: onDeny ? "inherit" : isMobileSize ? "100%" : "50%",
              }}
            >
              {confirmText}
            </Button>
            {onDeny && (
              <Button
                onClick={() => onDeny()}
                color="secondary"
                variant="text"
                // className="primary-inverted"
              >
                {denyText}
              </Button>
            )}
          </DialogActions>
        </>
      )}
    </Dialog>
  );
};
