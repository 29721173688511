import { Button, Card, CardContent, Modal, Theme, Typography } from "@mui/material";
import { makeStyles } from "tss-react/mui";
import React, { useEffect, useState, useRef } from "react";
import { AUTO_LOGOUT_SECONDS, SESSION_IDLE_MINUTES } from "../constants";
import { useLocation, useNavigate } from "react-router-dom";
import ScheduleIcon from "@mui/icons-material/Schedule";
import { StoreState } from "../reducers";
import { User } from "../models/User";
import { connect } from "react-redux";
import { appSignOut } from "../actions/userActions";
import { NavigateFunction } from "react-router";

const useStyles = makeStyles()((theme:Theme) => ({
  root: {},
  content: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: 40,
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  icon: {
    height: 72,
    width: 72,
  },
  body: {
    maxWidth: 460,
    textAlign: "center",
    margin: "20px 0",
  },
}));

interface Props {
  user?: User;
  appSignOut: (user?: User, navigate?: NavigateFunction) => void;
}

/**
 * Anytime the app loads a new screen a session timer is restarted
 * If the timer expires a modal is opened warning of session expiration
 * A second timer starts allowing the user a chance to extend the session
 * If the user chooses to extend the session the modal is closed and the session timer is restarted
 * If no action is taken app sign out happens
 */
export const _SessionMonitor = ({ user, appSignOut }: Props): JSX.Element => {
  const { classes } = useStyles();
  const location = useLocation();
  const navigate = useNavigate();
  const [signOutWarningIsOpen, setSignOutWarningIsOpen] = useState(false);

  const sessionTimeoutMilliseconds = SESSION_IDLE_MINUTES * 60 * 1000;
  const [sessionTimeout, setSessionTimeout] = useState<number>();
  const sessionTimeoutRef = useRef<number>();

  const signOutTimeoutMilliseconds = AUTO_LOGOUT_SECONDS * 1000;
  const [signOutTimeout, setSignOutTimeout] = useState<number>();

  useEffect(() => {
    // useRef is require to access the sessionTimeout in the other useEffect cleanup function
    // https://www.timveletta.com/blog/2020-07-14-accessing-react-state-in-your-component-cleanup-with-hooks/
    sessionTimeoutRef.current = sessionTimeout;
  }, [sessionTimeout]);

  useEffect(() => {
    // initial timer start
    startSessionTimer();
    return () => {
      // clean up timer method
      window.clearTimeout(sessionTimeoutRef.current);
    };
  }, [location.pathname]);

  const startSessionTimer = () => {
    if (sessionTimeout) window.clearTimeout(sessionTimeout);
    const thisTimeout = window.setTimeout(
      () => handleShowWarningModal(),
      sessionTimeoutMilliseconds
    );
    setSessionTimeout(thisTimeout);
  };

  const startSignOutTimer = () => {
    if (signOutTimeout) window.clearTimeout(signOutTimeout);
    setSignOutTimeout(
      window.setTimeout(() => handleSignOut(), signOutTimeoutMilliseconds)
    );
  };

  const handleShowWarningModal = () => {
    setSignOutWarningIsOpen(true);
    startSignOutTimer();
  };

  const handleSignOut = () => {
    setSignOutWarningIsOpen(false);
    appSignOut(user, navigate);
  };

  const extendSession = () => {
    window.clearTimeout(signOutTimeout);
    startSessionTimer();
    setSignOutWarningIsOpen(false);
  };

  return (
    <Modal
      className={classes.modal}
      open={signOutWarningIsOpen}
      onClose={extendSession}
      aria-describedby="sign-out-warning-body"
    >
      <Card className={classes.root}>
        <CardContent className={classes.content}>
          <ScheduleIcon className={classes.icon} color="primary" />
          <Typography
            variant="body1"
            id="sign-out-warning-body"
            className={classes.body}
          >
            Your session is about to expire.
            <br />
            You will be signed out in {AUTO_LOGOUT_SECONDS} seconds.
          </Typography>
          <Button
            variant="contained"
            color="primary"
            onClick={() => extendSession()}
          >
            Keep working
          </Button>
        </CardContent>
      </Card>
    </Modal>
  );
};

const mapStateToProps = ({ user }: StoreState): { user: User } => {
  return { user };
};

export const SessionMonitor = connect(mapStateToProps, {
  appSignOut,
})(_SessionMonitor);
