import { ActionTypes } from "../actions/types";
import { Advisor } from "../models/Advisor";
import { AdvisorsEstates } from "../models/AdvisorsEstates";
import { AnyAction } from "redux";

export const advisorsReducer = (
  advisors: Advisor[] | null = null,
  action: AnyAction
): Advisor[] | null => {
  switch (action.type) {
    case ActionTypes.ClearAdvisors:
    case ActionTypes.FetchAdvisors:
      return action.payload;
    case ActionTypes.AssignToAdvisor: {
      // add action.payload.estate into the advisors estates record
      if (advisors) {
        const newAdvisors: Advisor[] = [...advisors]; // clone
        const advisorIndex = newAdvisors.findIndex(
          (advisor: Advisor) =>
            advisor.user.id === action.payload.estate.advisorUserId
        );
        if (newAdvisors[advisorIndex] && newAdvisors[advisorIndex].estates) {
          //@ts-ignore
          newAdvisors[advisorIndex].estates.push(action.payload.estate);
        } else {
          newAdvisors[advisorIndex].estates = [action.payload.estate];
        }
        return newAdvisors;
      } else {
        return advisors;
      }
    }
    case ActionTypes.UnassignFromAdvisor: {
      // remove the estate from an advisor
      if (advisors) {
        const newAdvisors: Advisor[] = [...advisors]; // clone
        const advisorIndex = newAdvisors.findIndex(
          (advisor: Advisor) =>
            advisor.user.id === action.payload.advisorsEstates.advisorUserId
        );
        if (newAdvisors[advisorIndex] && newAdvisors[advisorIndex].estates) {
          //@ts-ignore
          newAdvisors[advisorIndex].estates = newAdvisors[
            advisorIndex
          ].estates.filter(
            (advisorsEstates: AdvisorsEstates) =>
              advisorsEstates.id !== action.payload.advisorsEstates.id
          );
        }
        return newAdvisors;
      } else {
        return advisors;
      }
    }
    case ActionTypes.AppSignOut:
      return null;
    default:
      return advisors;
  }
};
