import { ActionTypes } from "./types";
import { ThemeKey } from "../theme/ThemeKey";
import { advisorApi } from "../apis";
import { AdvisorsEstates } from "../models/AdvisorsEstates";
import { Dispatch } from "redux";

export interface SetThemeKeyAction {
  type: ActionTypes.SetThemeKey;
  payload: ThemeKey;
}

export const setThemeKey = (themeKey: ThemeKey): SetThemeKeyAction => {
  return {
    type: ActionTypes.SetThemeKey,
    payload: themeKey,
  };
};

export interface SetSearchTermsAction {
  type: ActionTypes.SetSearchTerms;
  payload: string;
}

export const setSearchTerms = (terms: string): SetSearchTermsAction => {
  return {
    type: ActionTypes.SetSearchTerms,
    payload: terms,
  };
};

export interface SetTotalEstatesAction {
  type: ActionTypes.SetTotalEstates;
  payload: number;
}

export interface FetchAdvisedEstateIdsAction {
  type: ActionTypes.FetchAdvisedEstateIds;
  payload: number[];
}

export const fetchAdvisedEstateIds = (advisorUserId: number) => {
  return async (dispatch: Dispatch): Promise<void> => {
    const advisedEstateIds: number[] = [];
    try {
      const { estates } = await advisorApi.fetchAdvisorsEstates(advisorUserId);
      estates?.forEach((advisorEstate: AdvisorsEstates) => {
        if (advisorEstate?.estateId) {
          advisedEstateIds.push(advisorEstate.estateId);
        }
      });
    } catch (error) {
      console.error(error);
    }
    if (advisedEstateIds) {
      dispatch<FetchAdvisedEstateIdsAction>({
        type: ActionTypes.FetchAdvisedEstateIds,
        payload: advisedEstateIds,
      });
    }
  };
};

export interface SetConfigAction {
  type: ActionTypes.SetConfig;
  payload: any;
}

export const setConfig = (config: any): SetConfigAction => {
  return {
    type: ActionTypes.SetConfig,
    payload: config,
  };
};
