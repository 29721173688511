import React, { useState } from "react";
import { Button } from "@mui/material";
import { ZeroStateBody } from "./ZeroStateBody";
import { LoadingSpinner } from "./LoadingSpinner";

export enum ContentState {
  loading = "loading",
  zero = "zero",
  render = "render",
}

interface Props {
  contentType: string;
  contentTypeSingular: string;
  children: JSX.Element;
  zeroText?: string;
  zeroImagePrefix?: string;
  zeroShowAddButton?: boolean;
  contentState?: ContentState;
  nonContentInCard?: boolean;
}

export const ContentStateWrapper = ({
  contentType,
  contentTypeSingular,
  children,
  zeroText,
  zeroImagePrefix,
  zeroShowAddButton,
  contentState = ContentState.render,
  nonContentInCard = false,
}: Props): JSX.Element => {
  const [overrideZeroState, setOverrideZeroState] = useState(false);
  const contentTypeLower = contentType.toLowerCase();
  return ((): JSX.Element => {
    switch (contentState) {
      case ContentState.loading:
        return (
          <LoadingSpinner
            inCard={nonContentInCard}
            margin="16px"
            message={`Loading ${contentTypeLower}...`}
          />
        );
      case ContentState.zero:
        return !overrideZeroState ? (
          <ZeroStateBody
            text={zeroText || `There aren't any ${contentTypeLower} yet.`}
            textLink={
              zeroShowAddButton ? (
                <Button
                  color="primary"
                  variant="contained"
                  onClick={() => setOverrideZeroState(true)}
                  style={{ margin: 8 }}
                >
                  Add a {contentTypeSingular}
                </Button>
              ) : undefined
            }
            imageAlt={`"No ${contentTypeLower}"`}
            imagePrefix={zeroImagePrefix}
            inCard={nonContentInCard}
          />
        ) : (
          children || <></>
        );
      default:
        return children || <></>;
    }
  })();
};
