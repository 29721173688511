import {
  ProbateAtticusTier,
  ProbateAtticusTierJSON,
} from "./ProbateAtticusTier";

export type ProbateTypeJSON = {
  id?: number;
  name?: string;
  state_id?: number;
  state_name?: string;
  state_code?: string;
  explanation?: string;
  probate_atticus_tier_id?: number;
  atticus_tier?: ProbateAtticusTierJSON;
};

export class ProbateType {
  static path = "/probate-types";
  static className = "ProbateType";
  constructor(
    public id?: number,
    public name?: string,
    public stateId?: number,
    public stateName?: string,
    public stateCode?: string,
    public explanation?: string,
    public probateAtticusTier?: ProbateAtticusTier
  ) {}

  static fromJSON(json: ProbateTypeJSON): ProbateType {
    return new ProbateType(
      json.id,
      json.name,
      json.state_id,
      json.state_name,
      json.state_code,
      json.explanation,
      json.atticus_tier
        ? ProbateAtticusTier.fromJSON(json.atticus_tier)
        : undefined
    );
  }

  toJSON(): ProbateTypeJSON {
    return {
      id: this.id,
      name: this.name,
      state_id: this.stateId,
      state_name: this.stateName,
      state_code: this.stateCode,
      explanation: this.explanation,
      probate_atticus_tier_id: this.probateAtticusTier?.id,
      atticus_tier: this.probateAtticusTier?.toJSON(),
    };
  }
}
