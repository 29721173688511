import React from "react";
import { Estate } from "../../models/Estate";
import { Opportunity } from "../../models/Opportunity";
import { User } from "../../models/User";
import { SingleOpportunityTable } from "./SingleOpportunityTable";

interface Props {
  user?: User;
  estate?: Estate; // won't be send when multi-estate screen
  opportunity?: Opportunity;
  noPad?: boolean;
}

export const EditableOpportunity = ({
  user,
  estate,
  opportunity,
  noPad = false,
}: Props): JSX.Element => {
  return (
    <SingleOpportunityTable
      opportunity={opportunity}
      user={user}
      estate={estate}
      minWidth={600}
      noPad={noPad}
    />
  );
};
