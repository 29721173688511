import React, { useEffect, useState } from "react";
import { Container, Grid, Theme, Typography } from "@mui/material";
import { makeStyles } from "tss-react/mui";
import Page from "../../../components/Page";
import { DetailCard } from "../../shared/DetailCard";
import ContentHeader from "../../shared/ContentHeader";
import { StoreState } from "../../../reducers";
import { User } from "../../../models/User";
import { connect } from "react-redux";
import { AssetSummary } from "../../shared/AssetSummary";
import { ATTICUS_PAGE_PREFIX } from "../../../constants";
import { LatestOpportunities } from "../../opportunities/LatestOpportunities";
import { AssetTypeSummary } from "./AssetTypeSummary";
import { AssetsMap, EstatesSummary } from "../../../models/EstateSummary";
import { AppState } from "../../../reducers/app";
import { callGet, isDev, logger } from "../../../apis";
import { Link } from "react-router-dom";
import { fetchAdvisedEstateIds } from "../../../actions/appActions";
import { getGreeting } from "../../../utils/getGreeting";
import { SummaryCard } from "../../shared/SummaryCard";
import { Resources } from "../../shared/Resources";
import { ResponsiveCards } from "../../shared/ResponsiveCards";
// import CircularProgress from "@mui/material/CircularProgress";

const useStyles = makeStyles()((theme: Theme) => ({
  root: {
    backgroundColor: theme.palette.background.default,
    minHeight: "100%",
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
}));

interface Props {
  app: AppState;
  user?: User;
  opportunitiesCount?: number,
  fetchAdvisedEstateIds: (advisorUserId: number) => void;
}

const _DashboardView = ({ user, opportunitiesCount }: Props): JSX.Element => {
  const { classes } = useStyles();
  const [numEstates, setNumEstates] = useState<number>();
  const [numTasksCompleted, setNumTasksCompleted] = useState<number>();
  const [totalAssetsValue, setTotalAssetsValue] = useState<
    AssetsMap | undefined
  >();
  const [totalAssetsInventoried, setTotalAssetsInventoried] = useState<
    AssetsMap | undefined
  >();
  const [error, setError] = useState("");

  // featureAEnabled controls display of the relationship pipeline rounded box
  // const { config } = app;
  // let showRelationshipPipeline = false;
  // TODO: fix race condition
  // eslint-disable-next-line no-prototype-builtins
  // if (config.hasOwnProperty("featureAEnabled")) {
  //   showRelationshipPipeline = config["featureAEnabled"];
  // }

  // useEffect(() => {
  //   setNumOpps(estatesSummary?.num_opportunities);

  // })


  useEffect(() => {
    if (user) {
      (async () => {
        if (user?.id && user?.advisorGroupName) {
          const summaryResult = await callGet("estates/summary");
          const estatesSummary = summaryResult?.data as EstatesSummary;
          setNumEstates(estatesSummary?.num_estates);
          // setEstatesTotalValue(estatesSummary?.estates_total_value);
          setTotalAssetsInventoried(estatesSummary?.total_assets_inventoried);
          setTotalAssetsValue(estatesSummary?.total_assets_value);
          setNumTasksCompleted(estatesSummary?.num_tasks_completed);
        } else {
          logger.error("Advisors group required for dashboard totals");
          setError(
            "Sorry, your advisor group could not be determined and is required to calculate totals."
          );
        }
      })();
    } else {
      logger.error("User required for dashboard totals");
      setError("User not set.");
    }
  }, []);

  // const getPreProdLink = (): JSX.Element => {
  //   const url = LARAVEL_APP_HOST;
  //   return url ? (
  //     <Typography variant="body1">
  //       Companion app for this environment:{" "}
  //       <a href={url} target="_blank">
  //         {url}
  //       </a>
  //     </Typography>
  //   ) : (
  //     <></>
  //   );
  // };

  const assetValuesSum = [
    {
      key: "Total",
      value: totalAssetsValue?.total || 0,
    },
    {
      key: "Financial accounts",
      value: totalAssetsValue?.financial || 0,
    },
    {
      key: "Real estate",
      value: totalAssetsValue?.realEstate || 0,
    },
    {
      key: "Household",
      value: totalAssetsValue?.household || 0,
    },
    {
      key: "Vehicles",
      value: totalAssetsValue?.vehicle || 0,
    },
    {
      key: "Digital",
      value: totalAssetsValue?.digital || 0,
    },
    {
      key: "Other",
      value: totalAssetsValue?.other || 0,
    },
  ];

  const assetInventoriedSum = [
    {
      key: "Total",
      value: totalAssetsInventoried?.total || 0,
    },
    {
      key: "Financial accounts",
      value: totalAssetsInventoried?.financial || 0,
    },
    {
      key: "Real estate",
      value: totalAssetsInventoried?.realEstate || 0,
    },
    {
      key: "Household",
      value: totalAssetsInventoried?.household || 0,
    },
    {
      key: "Vehicles",
      value: totalAssetsInventoried?.vehicle || 0,
    },
    {
      key: "Digital",
      value: totalAssetsInventoried?.digital || 0,
    },
    {
      key: "Other",
      value: totalAssetsInventoried?.other || 0,
    },
  ];

  return (
    <Page
      className={classes.root}
      title={`${ATTICUS_PAGE_PREFIX} - Dashboard`}
      data-automation-id="page-content-dashboard"
    >
      <Container maxWidth={false}>
        <ContentHeader heading={getGreeting(user)} />
        {/*<EstatesSummaryUpdateButton />*/}
        <Grid container spacing={2}>
          {error && (
            <Grid item xs={12}>
              <DetailCard title={error}>
                <Typography variant="body1">
                  {isDev && (
                    <Typography color="error">
                      This probably means laravel isn't running or is broken
                    </Typography>
                  )}
                  Please <Link to="/app/support">contact support</Link>
                </Typography>
              </DetailCard>
            </Grid>
          )}
          {/*<Grid item xs={12}>*/}
          {/*  <DetailCard title="Atticus client web application">*/}
          {/*    {getPreProdLink()}*/}
          {/*  </DetailCard>*/}
          {/*</Grid>*/}
          <Grid item lg={3} sm={6} xs={12}>
            <SummaryCard
              title="Active Estates"
              content={numEstates?.toLocaleString() || "..."}
            />
          </Grid>
          <Grid item lg={3} sm={6} xs={12}>
            <SummaryCard
              title="Current Opportunities"
              content={opportunitiesCount?.toLocaleString() || "..."}
            />
          </Grid>
          <Grid item lg={3} sm={6} xs={12}>
            <SummaryCard
              title="Tasks Completed"
              content={numTasksCompleted?.toLocaleString() || "..."}
            />
          </Grid>
          <Grid item lg={3} sm={6} xs={12}>
            <SummaryCard
              title="Active Assets"
              content={
                (totalAssetsInventoried &&
                  totalAssetsInventoried?.total?.toLocaleString()) ||
                "..."
              }
            />
          </Grid>
          <Grid item xs={12}>
              <LatestOpportunities count={3} estate={undefined} />
          </Grid>
          <Grid item xs={12}>
            <ResponsiveCards
              breakAt="lg"
              leftPercent={70}
              rightPercent={30}
              titleLeft="Total Active Estate Assets"
              cardLeft={<AssetSummary assetSubTotals={assetValuesSum} />}
              titleRight="Estate Assets by Category"
              cardRight={
                <AssetTypeSummary assetSubTotals={assetInventoriedSum} />
              }
            />
          </Grid>
          {/*{showRelationshipPipeline && (*/}
          {/*  <Grid item xs={12}>*/}
          {/*    <DetailCard title="Event relationship pipeline" />*/}
          {/*  </Grid>*/}
          {/*)}*/}
          <Grid item xs={12}>
            <Resources />
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
};

const mapStateToProps = ({
  app,
  user,
  opportunitiesCount,
}: StoreState): {
  app: AppState;
  user: User;
  opportunitiesCount: number,
} => {
  return { app, user, opportunitiesCount };
};

export const DashboardView = connect(mapStateToProps, {
  fetchAdvisedEstateIds,
})(_DashboardView);
