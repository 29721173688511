import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  useMediaQuery,
  useTheme,
  IconButton,
  Typography,
  Divider,
} from "@mui/material";

// Redux Imports
import { connect } from "react-redux";
import { StoreState } from "../../../../reducers";
import {
  fetchCountries,
  fetchStates,
} from "../../../../actions/geographyActions";

import { Country, State } from "../../../../models/Geography";

import { EstateLaravelized } from "../../../../models/EstateLaravelized";
import { estateLaravelizedApi, estateUserApi } from "../../../../apis";
import { User } from "../../../../models/User";
import CloseIcon from "@mui/icons-material/Close";
import EstateOverviewEstateEditForm from "./EstateOverviewEstateEditForm";
import EstateOverviewExecutorEditForm from "./EstateOverviewExecutorEditForm";

interface Props {
  open: boolean;
  estateId?: number;
  onClose: (refresh?: boolean) => void;
  refresh?: () => void;
  countries?: Country[];
  fetchCountries?: () => void;
  states?: State[];
  fetchStates?: () => void;
}

export interface UpdateStatus {
  success: boolean;
  message: string;
  errors?: any;
}

function _EstateOverviewEditDialog({
  open,
  estateId,
  onClose,
  refresh,
  countries,
  fetchCountries,
  states,
  fetchStates,
}: Props): JSX.Element {
  const theme = useTheme();
  const isMobileSize = useMediaQuery(theme.breakpoints.down("sm"));

  const [estateLaravelized, setEstateLaravelized] =
    useState<EstateLaravelized>();
  const [executor, setExecutor] = useState<User>();

  useEffect(() => {
    if (!countries || !countries?.length) {
      fetchCountries && fetchCountries();
    }
    if (!states || !states?.length) {
      fetchStates && fetchStates();
    }
  }, []);

  useEffect(() => {
    async function getEstateInfo() {
      if (estateId) {
        const [estate, users] = await Promise.all([
          await estateLaravelizedApi.getEstate(estateId),
          await estateUserApi.getEstateUsers(estateId),
        ]);
        if (estate) setEstateLaravelized(estate);
        if (users) setExecutor(users[0]); // First and only user is the executor, for now
      }
    }

    getEstateInfo();
  }, [estateId]);

  const saveEstate = async (
    estateId: number,
    editedEstate: EstateLaravelized
  ) => {
    const newEstate = await estateLaravelizedApi.updateEstate(
      estateId,
      editedEstate,
      true
    );
    setEstateLaravelized(newEstate);
    refresh?.();
  };

  const saveExecutor = async (
    estateId: number,
    executorId: number,
    editedExecutor: User
  ) => {
    const newExecutor = await estateUserApi.updateEstateUser(
      estateId,
      executorId,
      editedExecutor,
      true
    );
    setExecutor(newExecutor);
    refresh?.();
  };

  return (
    <Dialog
      fullScreen={isMobileSize}
      fullWidth
      maxWidth="lg"
      open={open}
      onClose={() => {
        onClose?.();
      }}
      className="noPadding"
    >
      <>
        <IconButton
          style={{
            position: "absolute",
            top: 8,
            right: 8,
          }}
          aria-label="delete"
          onClick={() => {
            onClose?.();
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogTitle component="div">
          <Typography variant="h2">Estate Overview</Typography>
        </DialogTitle>

        <DialogContent sx={{ textAlign: "left" }}>
          <>
            <EstateOverviewEstateEditForm
              estate={estateLaravelized}
              onComplete={saveEstate}
            />
            <Divider sx={{ margin: "1rem 0" }} />
            <EstateOverviewExecutorEditForm
              estate={estateLaravelized}
              executor={executor}
              onComplete={saveExecutor}
            />
          </>
        </DialogContent>
      </>
    </Dialog>
  );
}
const mapStateToProps = ({
  countries,
  states,
}: StoreState): {
  countries: Country[];
  states: State[];
} => {
  return { countries, states };
};

const EstateOverviewEditDialog = connect(mapStateToProps, {
  fetchCountries,
  fetchStates,
})(_EstateOverviewEditDialog);

export default EstateOverviewEditDialog;
