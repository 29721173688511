import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Container,
  Switch,
  Table,
  Theme,
  Typography,
} from "@mui/material";
import Cookies from "js-cookie";
import { makeStyles } from "tss-react/mui";
import { connect } from "react-redux";
import { Outlet, useNavigate, useParams, useLocation } from "react-router-dom";
import Page from "../../../components/Page";
import ContentHeader from "../../shared/ContentHeader";
import { StoreState } from "../../../reducers";
import {
  fetchSingleEstate,
  setEstateIsDemo,
} from "../../../actions/estateActions";
import {
  fetchEstateForms,
  flushEstateForms,
} from "../../../actions/estateFormActions";
import { Estate } from "../../../models/Estate";
import {
  ATTICUS_PAGE_PREFIX,
  isLocal,
  isProd,
  SCREEN_PATHS,
  SCROLL_DIV,
} from "../../../constants";
import { EstateNavBar } from "./EstateNavBar";
import { TableBodyLoader } from "../../shared/TableBodyLoader";
import { AppState } from "../../../reducers/app";
import ArrowBackIcon from "@mui/icons-material/ArrowBackIos";
import { AssignAdvisorGroupForm } from "../../assignments/AssignAdvisorGroupForm";
import { User } from "../../../models/User";
import { advisorApi, logger } from "../../../apis";
import { ConfirmationDialog } from "../../shared/dialog/ConfirmationDialog";
import { getQueryStringParams } from "../../../utils/getQueryStringParams";
import { AssetsContextWrapper } from "./assets/AssetsContext";
import { AtticusForm } from "../../../models/AtticusForm";

const useStyles = makeStyles()((theme: Theme) => ({
  root: {
    backgroundColor: theme.palette.background.default,
    minHeight: "100%",
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
  breadCrumb: {
    marginBottom: theme.spacing(1),
    "& .MuiButton-startIcon": {
      marginRight: 0,
    },
  },
  invertedButton: {
    backgroundColor: theme.palette.background.default,
    color: theme.palette.primary.light,
    border: `solid 2px ${theme.palette.primary.light}`,
    margin: theme.spacing(1, 0),
  },
}));

interface CapserCookie {
  userId: number,
  executorName: string,
  estateId: number,
  estateName: string,
}


interface Props {
  app: AppState;
  user: User;
  estates: Estate[];
  estateForms: AtticusForm[];
  fetchSingleEstate: (estateId: number) => void;
  setEstateIsDemo: (estateId: number, isDemo: boolean) => void;
  fetchEstateForms: (estateId: number) => void;
  flushEstateForms: () => void;
}

const _EstateView = ({
  app,
  user,
  estates,
  estateForms,
  fetchSingleEstate,
  setEstateIsDemo,
  fetchEstateForms,
  flushEstateForms,
}: Props): JSX.Element => {
  const { classes } = useStyles();
  const navigate = useNavigate();
  const location = useLocation();
  const [isDeleteConfirmOpen, setIsDeleteConfirmOpen] = useState(false);
  const { estateId } = useParams();
  const [opacity, setOpacity] = useState<number>(0)
  const [spooking, setSpooking] = useState<boolean>(false)
  const estate = estates?.filter(
    (estate: Estate) => estateId && estate?.id === +estateId
  )[0];

  const params = getQueryStringParams();
  // NOTE: add ?force=true to the url to activate hard-delete
  const forceDelete = params?.force || undefined;

  useEffect(() => {
    if (!user.isUberAdmin) {
      return
    }

    const rotateCounter = setInterval(() => {
      setOpacity(r => r >= 100000 ? 0.3 : r + 0.3)
    }, 200)
    return () => clearInterval(rotateCounter)
  }, [])

  useEffect(() => {
    if (!estate) {
      estateId && fetchSingleEstate(+estateId);
    }
    window.document.querySelector(`.${SCROLL_DIV}`)?.scrollTo(0, 0);

    if (
      estateId &&
      (!estateForms ||
        estateForms.length < 1 ||
        estateForms[0].estateFormMap?.estateId != +estateId)
    ) {
      flushEstateForms();
      fetchEstateForms(+estateId);
    }
  }, [estate]);

  const loadingEstates = estates === null;

  const handleBackToEstates = () => {
    if (app.terms) {
      navigate(`${SCREEN_PATHS.estates}?terms=${encodeURI(app.terms)}`);
    } else {
      if (location?.state?.from === SCREEN_PATHS.estates) {
        navigate(-1);
      } else {
        navigate(SCREEN_PATHS.estates);
      }
    }
  };

  const handleAssign = async (
    estateId?: number,
    advisorsGroupId?: number
  ): Promise<boolean> => {
    if (estateId && advisorsGroupId) {
      try {
        return await advisorApi.assignEstateToGroup(estateId, advisorsGroupId);
      } catch (error) {
        logger.error(error as Error);
        return false;
      }
    }
    return false;
  };

  const handleDeleteOrRecover = async (estateId?: number): Promise<boolean> => {
    if (estateId) {
      try {
        const result = estate?.deletedAt
          ? await advisorApi.recoverEstate(estateId)
          : await advisorApi.deleteEstate(estateId, forceDelete);
        if (result) {
          setIsDeleteConfirmOpen(false);
          setTimeout(() => {
            window.location.reload();
          }, 10);
        }
      } catch (error) {
        logger.error(error as Error);
        return false;
      }
    }
    return false;
  };

  const enterCasperMode = () => {
    if (!user.isUberAdmin) {
      console.error("uber users only!")
      return
    }
    if (!estate.userId) {
      console.error("unsure of who to login as")
      return
    }

    const casperCookie: CapserCookie = {
      userId: estate.userId,
      executorName: [estate.executorFirstName || "", estate.executorLastName || ""].join(" "),
      estateId: estate.id || 0,
      estateName: estate.name || "",
    }
    Cookies.set("acting_as", JSON.stringify(casperCookie), {
      domain: isLocal ? "localhost" : ".weareatticus.com",
    });
    if (isProd) {
      window.location.href = "https://app2.weareatticus.com/app/home"
    } else if (isLocal) {
      window.location.href = "http://localhost:3002/app/home"
    } else {
      window.location.href = "https://app-ppd1.weareatticus.com/app/home"
    }
  }

  const getEstateAdmin = (): JSX.Element | undefined => {
    if (user.isUberAdmin) {
      return (
        <>

          <AssignAdvisorGroupForm
            advisorGroupId={estate?.advisorGroupId}
            onAssign={async (newAdvisorGroupId: number) => {
              return await handleAssign(estate.id, newAdvisorGroupId);
            }}
            onDelete={() => {
              setIsDeleteConfirmOpen(true);
            }}
            deleteText={
              estate?.deletedAt
                ? "Recover"
                : forceDelete
                  ? "DELETE FOREVER"
                  : "Delete"
            }
            dense={true}
            showEstateGroupConfirm={false}
          />
          <Button
            className="rotate-inner-span"
            onMouseOver={() => { setSpooking(true); setOpacity(3.5 / 2) }}
            onMouseOut={() => { setSpooking(false); setOpacity(3.5 / 2) }}
            variant="outlined"
            onClick={enterCasperMode}
            style={{ marginTop: "0.5rem", marginBottom: "0.5rem" }}
          >
            <span style={spooking ? { opacity: Math.abs(Math.sin(opacity)), transition: 'opacity 100ms ease' } : {}}>
              👻
            </span>
            Enter Casper Mode
            <span style={spooking ? { opacity: 1 - Math.abs(Math.sin(opacity)), transition: 'opacity 100ms ease' } : { opacity: 0 }}>
              👻
            </span>
          </Button >
          {estate?.isDemo !== undefined && (
            <Box>
              <Switch
                checked={!!estate?.isDemo}
                onChange={() => {
                  if (estate?.id) {
                    setEstateIsDemo(estate?.id, !estate?.isDemo);
                  }
                }}
                color="primary"
                name="is-demo-switch"
                id="is-demo-switch"
                inputProps={{ "aria-label": "is demo" }}
              />
              <Typography variant="h5" component="span">
                <label htmlFor="is-demo-switch" style={{ cursor: "pointer" }}>
                  Is Demo
                </label>
              </Typography>
            </Box>
          )
          }
        </>
      );
    } else {
      return undefined;
    }
  };

  const getSubHeader = (): JSX.Element => {
    const strings: string[] = [];
    if (estate?.lastActivityWithTime) {
      strings.push(`Last updated ${estate?.lastActivityWithTime}`);
    }
    if (estate?.deletedAt) {
      strings.push(
        "* to see estate progress, assets, reports, etc - recover estate 👉"
      );
    }
    return (
      <Typography variant="body2">
        {strings.map((str: string, index: number) => {
          return (
            <React.Fragment
              key={`SubHeader_${Math.floor(Math.random() * 10000)}`}
            >
              {index > 0 ? <br /> : ""}
              {str}
            </React.Fragment>
          );
        })}
      </Typography>
    );
  };

  return (
    <Page
      className={classes.root}
      title={`${ATTICUS_PAGE_PREFIX} - Estate`}
      data-automation-id="page-content-estate-detail"
    >
      <Container maxWidth={false}>
        <ContentHeader
          breadcrumbs={
            <Button
              className={classes.breadCrumb}
              startIcon={<ArrowBackIcon />}
              onClick={() => handleBackToEstates()}
              color="primary"
            >
              Estates
            </Button>
          }
          heading={
            estate?.name
              ? `${estate.name} estate${estate?.isDemo ? " *" : ""}`
              : "Estate"
          }
          headingLineThrough={!!estate?.deletedAt}
          subHeading={getSubHeader()}
          showSearch={false}
          belowSearch={getEstateAdmin()}
        />
        <ConfirmationDialog
          open={isDeleteConfirmOpen}
          description={estate?.name || ""}
          question={
            estate?.deletedAt
              ? "Are you sure you want to recover this estate?"
              : `Are you sure you want to ${forceDelete
                ? "DELETE THIS ESTATE FOREVER"
                : "delete this estate?"
              }`
          }
          onConfirm={() => handleDeleteOrRecover(estate?.id)}
          onDeny={() => setIsDeleteConfirmOpen(false)}
        />
        <EstateNavBar />
        {loadingEstates ? (
          <Table>
            <TableBodyLoader colSpan={1} />
          </Table>
        ) : location.pathname.includes("/assets") ? (
          <AssetsContextWrapper>
            <Outlet />
          </AssetsContextWrapper>
        ) : (
          <Outlet />
        )}
      </Container>
    </Page>
  );
};

const mapStateToProps = ({
  app,
  user,
  estates,
  estateForms,
}: StoreState): {
  app: AppState;
  user: User;
  estates: Estate[];
  estateForms: AtticusForm[];
} => {
  return { app, user, estates, estateForms };
};

export const EstateView = connect(mapStateToProps, {
  fetchSingleEstate,
  setEstateIsDemo,
  flushEstateForms,
  fetchEstateForms,
})(_EstateView);
