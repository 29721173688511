import React, { useState } from "react";
import PerfectScrollbar from "react-perfect-scrollbar";
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { makeStyles } from "tss-react/mui";

import { AppTableWrapper } from "../../shared/AppTableWrapper";
import { TableBodyLoader } from "../../shared/TableBodyLoader";
import { ZeroStateBody } from "../../shared/ZeroStateBody";
import { AdvisorGroup, AdvisorGroupAccess } from "../../../models/AdvisorGroup";
import { ADVISOR_GROUP_PREFIX } from "../../../constants";
import { AddRecordButton } from "../../shared/AddRecordButton";
import { AdvisorGroupForm } from "./AdvisorGroupForm";
import { AssignGroupAccessForm } from "./AssignGroupAccessForm";
import { callPut, logger } from "../../../apis";

const useStyles = makeStyles()(() => ({
  root: {
    position: "relative",
  },
}));

interface Props {
  advisorGroups: AdvisorGroup[];
  accessLevels?: AdvisorGroupAccess[];
}

export const AllGroupsTable = ({
  advisorGroups,
  accessLevels,
}: Props): JSX.Element => {
  const { classes } = useStyles();
  const loading = advisorGroups === undefined;
  const showZero = advisorGroups !== null && advisorGroups.length === 0;
  const [showNewForm, setShowNewForm] = useState<boolean>(false);
  const [editId, setEditId] = useState<number>();

  return (
    <div className={classes.root}>
      <Box
        style={{
          position: "absolute",
          right: 16,
          zIndex: 10,
        }}
      >
        <AddRecordButton
          title="Add Group"
          onClick={() => {
            setShowNewForm(true);
            setEditId(undefined);
          }}
        />
      </Box>
      <PerfectScrollbar>
        <AppTableWrapper minWidth={!showZero ? 300 : 1}>
          {showZero ? (
            <ZeroStateBody text="No advisorGroups returned" />
          ) : (
            <Table>
              <TableHead>
                <TableRow className="dense">
                  <TableCell>id</TableCell>
                  <TableCell>Group Name</TableCell>
                  <TableCell>Invite Id</TableCell>
                  <TableCell>Access</TableCell>
                  <TableCell />
                </TableRow>
              </TableHead>
              {loading ? (
                <TableBodyLoader colSpan={5} rows={3} />
              ) : (
                <TableBody>
                  {showNewForm && (
                    <AdvisorGroupForm
                      colSpan={5}
                      advisorGroup={new AdvisorGroup()}
                      onComplete={() => {
                        setShowNewForm(false);
                      }}
                      key="new-group-form"
                    />
                  )}
                  {advisorGroups &&
                    advisorGroups.map((advisorGroup: AdvisorGroup) => {
                      return editId === advisorGroup.id ? (
                        <AdvisorGroupForm
                          colSpan={5}
                          advisorGroup={advisorGroup}
                          onComplete={() => {
                            setEditId(undefined);
                          }}
                          key={advisorGroup.id + "-form"}
                        />
                      ) : (
                        <TableRow key={advisorGroup.id} className="dense">
                          <TableCell>{advisorGroup.id}</TableCell>
                          <TableCell>
                            <Box alignItems="center" display="flex">
                              <Typography color="textPrimary" variant="body1">
                                {advisorGroup?.title?.replace(
                                  ADVISOR_GROUP_PREFIX,
                                  ""
                                ) || "----"}
                              </Typography>
                            </Box>
                          </TableCell>
                          <TableCell>{advisorGroup.inviteId}</TableCell>
                          <TableCell>
                            <AssignGroupAccessForm
                              onUpdate={async (accessId: number) => {
                                try {
                                  await callPut(
                                    `${AdvisorGroup.path}/${advisorGroup.id}`,
                                    {
                                      access_id: accessId,
                                    }
                                  );
                                } catch (error) {
                                  logger.error(error as Error);
                                  return false;
                                }
                                return true;
                              }}
                              accessLevels={accessLevels}
                              accessId={advisorGroup.accessId}
                            />
                          </TableCell>
                          <TableCell />
                        </TableRow>
                      );
                    })}
                </TableBody>
              )}
            </Table>
          )}
        </AppTableWrapper>
      </PerfectScrollbar>
    </div>
  );
};
