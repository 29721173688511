// Gets around an issue with these being pulled in before being set somehow
if (!window.process) {
  window.process = {} as any
}
if (!window.process.env) {
  window.process.env = {} as any
}

export const ENVS = {
  prod: "production",
  dev: "development",
  test: "test",
};
export const isProd =
  `${window.location.protocol}//${window.location.hostname}` ===
  "https://advisor.weareatticus.com";

// atticus-web-dev (VUE app)
export enum ConsumerApp {
  prod = "https://app.weareatticus.com",
  ppd1 = "https://app-preprod1.weareatticus.com",
  ppd2 = "https://app-preprod2.weareatticus.com",
  local = "http://localhost:3000",
}
export const ATTICUS_ENDPOINTS = {
  login: "/login",
  logout: "/logout",
  user: "/get-user",
};
export const ATTICUS_PAGE_PREFIX = "Atticus Advisor";
export const STORAGE_KEYS = {
  THEME_KEY: "AtticusThemeKey",
  TOKEN: "AtticusAdvisorToken",
  LOG_SESSION_ID: "AtticusAdvisorSessionId",
  BYPASS: "AtticusMaintenance",
  OVERRIDE: "AtticusConfig",
  ENV_OVERRIDE: "AtticusLaravelHost",
  AUTH_REDIRECT_FROM: "RedirectFrom",
};
const laravelHostOverride = window.localStorage.getItem(
  STORAGE_KEYS.ENV_OVERRIDE
);

export const atticusWebUrl = isProd ? "https://app2.weareatticus.com" : "https://app-ppd1.weareatticus.com/"

if (laravelHostOverride)
  console.warn("*** LARAVEL HOST OVERRIDE ***", laravelHostOverride);
export const LARAVEL_APP_HOST =
  laravelHostOverride ||
  process?.env?.REACT_APP_LARAVEL_HOST ||
  ConsumerApp.prod;
const LARAVEL_APP_API = process?.env?.REACT_APP_LARAVEL_API || "/advisor";
const LARAVEL_APP_VERSION =
  process?.env?.REACT_APP_LARAVEL_API_VERSION || "/v1";
export const LARAVEL_APP_SERVICE_PATH =
  LARAVEL_APP_HOST + LARAVEL_APP_API + LARAVEL_APP_VERSION;

export const isLocal = window.document.location.host?.includes("localhost");

// if (window) {
//   (window as any).LOG_LEVEL = 'DEBUG'; //Enable if you have issues with cognito auth and need to debug
// }

export const REST_API = "AdvisorProtectedREST";
export const PUBLIC_REST_API = "AdvisorPublicREST";
export const SCREEN_PATHS = {
  dashboard: "/app/dashboard",
  estates: "/app/estates",
  formsAndFilesSubpath: "/forms-and-files",
  opps: "/app/opportunities",
  formsLibrary: "app/forms-library",
  metrics: "/app/metrics",
  admin: "/app/admin/forms",
  support: "/app/support",
  login: "/login",
  not_found: "/404",
  maintenance: "/maintenance",
  signOut: "/sign-out",
};

export const PASSWORD_REGEX = new RegExp(
  /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[\^$*.[\]{}()?\-“!@#%&/,><’:;|_~`])\S{8,99}$/g
);
export const PASSWORD_EXAMPLE = "P4ssw*rd";
export const PASSWORD_REQUIREMENT_MESSAGE = `Your password must include at least one number, upper, and lower case and as well as a special character. It must be at least 8 characters in length. Example: ${PASSWORD_EXAMPLE}`;
export const SIGN_IN_LONG_MSG_DELAY_SECONDS = 3;
export const LOGGER_NAME = "atticus-advisor";
export const MAX_PHOTO_BYTES = 5120000; //~5MB
export const FILE_UPLOAD_TYPES = [
  "image/jpeg",
  "image/jpg",
  "image/gif",
  "image/png",
];
export const SESSION_IDLE_MINUTES = 20;
export const AUTO_LOGOUT_SECONDS = 30;
export const ADVISOR_GROUP_PREFIX = "AG-";
export const PUBLIC_FORMS_CDN_URL = isProd ?
  "https://forms-cdn.weareatticus.com" :
  "https://atticus-forms-dev-public.s3.us-east-2.amazonaws.com"
export type ROLE = {
  id: number;
  title: string;
};
export const ASSIGNABLE_ROLES = [
  {
    id: 2,
    title: "Advisor",
  },
  {
    id: 3,
    title: "Admin",
  },
  {
    id: 5,
    title: "Uber",
  },
];
export enum InviteTypes {
  Advisor = "advisor",
  EndUser = "enduser",
  Group = "group",
}
export enum SpecialCognitoGroups {
  Uber = "Uber",
  Admin = "Admin",
  OnlyAdminTool = "OnlyAdminTool",
}

// matches MySQL advisor_group_access table
export enum AdvisorGroupAccess {
  Full = "Full",
  Aggregate_Data = "Aggregate Data",
  Suspended = "Suspended",
}

// NOTE: the following is just for ui display
//  invite expiration is set via a lambda function environment variable
//  invitesfunction: INVITE_TTL_SEC
//  used at invite creation time: amplify/backend/function/invitesfunction/ts/app.ts
export const INVITE_TTL_DAYS = 3;
export const ASSETS_PER_PAGE = 12;

export const estateGroupReassignmentMessage = `
Warning: Estate re-assignment should be used infrequently. 
If moving estate from one group to another any advisors 
will need to be reassigned and estate summaries need to be 
deleted. Are you sure you want to proceed?`;
export const REPORT_IFRAME_NAME = "report-viewer";
export const REPORT_IFRAME_HEIGHT = 1000;
export const BLANK_HTML_PATH = "/blank.html";
export const IMPORT_FORMS_DETAIL_URL =
  "https://github.com/TheAtticusApp/atticus-advisor/wiki/Atticus-forms-import-and-maintenance-process";
export const JSON_INVALID_MSG = "JSON invalid";
export const ATTICUS_DOC_LOGO_LOCATIONS = [
  {
    name: "BottomRight",
    opts: undefined,
    comment: "this is default, no params necessary",
  },
  {
    name: "BottomCenter",
    opts: { x: 275, y: 10, width: 70, height: 9 },
  },
  {
    name: "BottomLeft",
    opts: { x: 10, y: 10, width: 70, height: 9 },
  },
  {
    name: "TopRight",
    opts: { x: 520, y: 775, width: 70, height: 9 },
  },
  {
    name: "TopCenter",
    opts: { x: 275, y: 775, width: 70, height: 9 },
  },
  {
    name: "TopLeft",
    opts: { x: 10, y: 775, width: 70, height: 9 },
  },
];
// these are what the laravel layer understands, others will be ignored or cause errors
export const ESTATE_SORT_BY = {
  id: "id",
  group: "advisor_group_id",
  name: "name",
  state: "state_name",
  activity: "latest_activity",
  created: "created_at",
  value: "assets_value_total",
  progress: "percent_complete",
  packet: "probate_type_name",
  payment: "price_value",
  inboundFunnel: "inbound_funnel",
};
export const ESTATE_SORT_DIR = {
  asc: "asc",
  dsc: "dsc",
};
export const PROFILE_PHOTO_PREFIX = "profile-";
// future: may want to reference from atticus-shared-components
export const AUTH_ERRORS = {
  cognitoNotAuthed: "The user is not authenticated",
  laravelNoData: "could not determine users role and/or group",
  getUserFailed: "getUser failed",
  accountCreation: "account creation failed",
  inviteRedemption: "invite redemption failed",
  signOut: "error signing out",
};
export const COOKIES = {
  authToken: "AtticusAuth",
  invite: "AtticusInvite",
};
export const CONSTANTS = {
  STORAGE_KEYS: {
    THEME_KEY: "AtticusThemeKey",
    TOKEN: "AtticusAdvisorToken",
    LOG_SESSION_ID: "AtticusAdvisorSessionId",
    BYPASS: "AtticusMaintenance",
    OVERRIDE: "AtticusConfig",
  },
  SESSION_IDLE_MINUTES: 20,
  AUTO_LOGOUT_SECONDS: 30,
  ADVISOR_GROUP_PREFIX: "AG-",
  REGEX: {
    phone: new RegExp(
      /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/
    ),
    email: new RegExp(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    ),
    password: new RegExp(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[\^$*.[\]{}()?\-“!@#%&/,><’:;|_~`])\S{8,99}$/g
    ),
  },
};
export const SCROLL_DIV = "scroller";
const CONSUMER_ACCOUNT_PREFIX_CONFIG =
  process?.env?.REACT_APP_CONSUMER_ACCOUNT_PREFIX;
export const CONSUMER_ACCOUNT_PREFIX = CONSUMER_ACCOUNT_PREFIX_CONFIG
  ? JSON.parse(CONSUMER_ACCOUNT_PREFIX_CONFIG)
  : {
    TrusteeServicesGroup: "TrusteeServicesGroup",
    default: "support",
  };
export const CONSUMER_ACCOUNT_DOMAIN_CONFIG =
  process?.env?.REACT_APP_CONSUMER_ACCOUNT_DOMAIN;
export const CONSUMER_ACCOUNT_DOMAIN = CONSUMER_ACCOUNT_DOMAIN_CONFIG
  ? JSON.parse(CONSUMER_ACCOUNT_DOMAIN_CONFIG)
  : { default: "weareatticus.biz" };

export const META_ROBOTS_VALUE = process.env.REACT_APP_META_ROBOTS_VALUE || "all"


// These were taken from the amplify config

export const amplifyStorageBucket = isProd ?
  "atticusadvisorapp-161743-advisor-content-prod161743-prod"
  : "atticusadvisorapp97f3e53298c140d2be8ff85b354356-master"
export const amplifyStorageRegion = "us-east-2"

