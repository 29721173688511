import React, { useState } from "react";
import { Box, Grow } from "@mui/material";

import { AtticusForm } from "../../../../models/AtticusForm";
import AtticusFormViewDialog, {
  PdfFileChoice,
} from "../../../shared/dialog/AtticusFormViewDialog";
import { DetailsDialog } from "../../../shared/dialog/DetailsDialog";
import { FormsListZeroState } from "../list/FormsListZeroState";
import { FormsListItemCard } from "../list/FormsListItemCard";
import { SplitButtonOption } from "../../SplitButtonGroup";

const formDetailsIcon = "/static/icons/form-details-icon.svg";

interface Props {
  forms?: AtticusForm[];
  loadingForms: boolean;
}

export const FormsLibraryList = ({
  forms,
  loadingForms,
}: Props): JSX.Element => {
  // Form PDF Dialog State
  const [pdfDialogOpen, setPdfDialogOpen] = useState(false);
  const [pdfDialogForm, setPdfDialogForm] = useState<AtticusForm>();
  const togglePdfDialog = () => {
    setPdfDialogOpen(!pdfDialogOpen);
    if (pdfDialogOpen === true) {
      setPdfDialogForm(undefined);
    }
  };
  // End Form PDF Dialog State

  // Form Description Dialog State
  const [descriptionDialogOpen, setDescriptionDialogOpen] = useState(false);
  const [descriptionDialogForm, setDescriptionDialogForm] =
    useState<AtticusForm>();

  const toggleDescriptionDialog = () => {
    setDescriptionDialogOpen(!descriptionDialogOpen);
    if (descriptionDialogOpen === true) {
      setDescriptionDialogForm(undefined);
    }
  };
  // End Form Description Dialog State

  const getFormOptions = (form: AtticusForm): SplitButtonOption[] => {
    const formOptions: SplitButtonOption[] = [
      {
        label: "View",
        key: "view",
        onClick: () => {
          setPdfDialogForm(form);
          togglePdfDialog();
        },
      },
    ];
    return formOptions;
  };

  const renderForms = (forms?: AtticusForm[]): JSX.Element[] => {
    const rows:JSX.Element[] = [];
    if (!forms) {
      for (let formI = 0; formI < 3; formI++) {
        rows.push(
          <Grow in key={formI} timeout={formI * 100}>
            <Box>
              <FormsListItemCard
                pdfDialogOpen={pdfDialogOpen}
                setPdfDialogForm={setPdfDialogForm}
                togglePdfDialog={togglePdfDialog}
                descriptionDialogOpen={descriptionDialogOpen}
                setDescriptionDialogForm={setDescriptionDialogForm}
                toggleDescriptionDialog={toggleDescriptionDialog}
                sx={{
                  padding: "1rem",
                  marginBottom: "1rem",
                }}
              />
            </Box>
          </Grow>
        );
      }
    } else {
      forms.forEach((form, index) => {
        rows.push(
          <Grow in key={form.id} timeout={index * 100}>
            <Box>
              <FormsListItemCard
                form={form}
                pdfDialogOpen={pdfDialogOpen}
                setPdfDialogForm={setPdfDialogForm}
                togglePdfDialog={togglePdfDialog}
                descriptionDialogOpen={descriptionDialogOpen}
                setDescriptionDialogForm={setDescriptionDialogForm}
                toggleDescriptionDialog={toggleDescriptionDialog}
                sx={{
                  padding: "1rem",
                  marginBottom: "1rem",
                }}
                formOptions={getFormOptions(form)}
              />
            </Box>
          </Grow>
        );
      });
    }
    return rows;
  };

  return (
    <Box sx={{ marginTop: 2 }}>
      {loadingForms ? (
        renderForms()
      ) : (
        <>
          <AtticusFormViewDialog
            open={pdfDialogOpen}
            onClose={togglePdfDialog}
            form={pdfDialogForm}
            fileChoice={PdfFileChoice.PROCESSED}
            withDisclaimer
          />
          <DetailsDialog
            open={descriptionDialogOpen}
            headerImageSrc={formDetailsIcon}
            headerImageAlt="A magnifying glass observing a form's text"
            title={descriptionDialogForm?.getTitle() || "Form Details"}
            subtitle={`${
              descriptionDialogForm?.geography?.fullName
                ? descriptionDialogForm?.geography?.fullName
                : ""
            }${
              descriptionDialogForm?.geography?.fullName &&
              descriptionDialogForm?.getGovernmentId()
                ? " | "
                : ""
            }${
              descriptionDialogForm?.getGovernmentId()
                ? descriptionDialogForm?.getGovernmentId()
                : ""
            }`}
            content={
              descriptionDialogForm?.getDescription() ||
              "No Description Available."
            }
            onConfirm={toggleDescriptionDialog}
            confirmText="Ok"
            maxWidth="md"
          />
          {!forms ? (
            <>
              {/* Do not display anything here for now. Forms have not been loaded yet. */}
            </>
          ) : forms.length < 1 ? (
            <FormsListZeroState />
          ) : (
            <Box>{renderForms(forms)}</Box>
          )}
        </>
      )}
    </Box>
  );
};
