import React, { useState } from "react";
import { Button, Grid, Typography, Box } from "@mui/material";
import { makeStyles } from "tss-react/mui";
import { Estate } from "../../../../models/Estate";
import moment from "moment";
import { AsyncAvatar } from "../../../shared/AsyncAvatar";
import { ReassignAccount } from "./ReassignAccount";
import EstateOverviewEditDialog from "../../../shared/dialog/EstateOverview/EstateOverviewEditDialog";

const useStyles = makeStyles()(() => ({
  root: {
    // display: "flex",
  },
  infoContent: {
    fontWeight: "bold",
  },
}));

interface Props {
  estate?: Estate;
  isUber?: boolean;
  onRefresh?: () => void;
}

export const EstateInfo = ({
  estate,
  isUber = false,
  onRefresh,
}: Props): JSX.Element => {
  const { classes } = useStyles();
  const [reassignDialogOpen, setReassignDialogOpen] = useState(false);
  const [editDialogOpen, setEditDialogOpen] = useState(false);

  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Typography
          sx={{
            fontWeight: "bold",
          }}
        >
          Estate Information
        </Typography>
        {estate?.hasWriteAccess && (
          <Button
            variant="contained"
            size="small"
            onClick={() => setEditDialogOpen(true)}
          >
            Manage
          </Button>
        )}
      </Box>
      <ReassignAccount
        estate={estate}
        isOpen={reassignDialogOpen}
        onClose={(reassigned?: boolean) => {
          setReassignDialogOpen(false);
          if (reassigned) {
            onRefresh?.();
          }
        }}
      />
      <EstateOverviewEditDialog
        open={editDialogOpen}
        estateId={estate?.id}
        refresh={onRefresh}
        onClose={(refresh?: boolean) => {
          setEditDialogOpen(false);
          if (refresh) {
            onRefresh?.();
          }
        }}
      />
      <Grid container spacing={1}>
        <Grid
          item
          xs={3}
          sx={{
            display: {
              xs: "none",
              sm: "none",
              md: "block",
            },
          }}
        >
          <AsyncAvatar estate={estate} size="large" />
        </Grid>
        <Grid item md={9} sm={12}>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Typography variant="body2">
                {(estate?.executorSummary?.declaredRole == "Corporate executor"
                  ? "Corporate "
                  : "") + "Executor"}
              </Typography>
              <Typography variant="body2" className={classes.infoContent}>
                {estate?.executorSummary?.declaredRole ==
                  "Corporate executor" &&
                estate?.executorSummary?.nameOfCorporateExecutor
                  ? estate?.executorSummary?.nameOfCorporateExecutor
                  : estate?.executorFullName || "--"}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="body2">Deceased DOD</Typography>
              <Typography variant="body2" className={classes.infoContent}>
                {estate?.passedOn
                  ? moment(estate?.passedOn).format("MM/DD/YYYY")
                  : "Not yet"}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="body2">Relation to deceased</Typography>
              <Typography variant="body2" className={classes.infoContent}>
                {estate?.executorSummary?.relationshipDisplay}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="body2">Declared Role</Typography>
              <Typography variant="body2" className={classes.infoContent}>
                {estate?.executorSummary?.declaredRole || "--"}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="body2">Advisory Position</Typography>
              <Typography variant="body2" className={classes.infoContent}>
                {estate?.executorSummary?.advisoryPositionDisplay}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="body2">Domicile Location</Typography>
              <Typography variant="body2" className={classes.infoContent}>
                {estate?.state || "--"}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body2">Email</Typography>
              <Typography variant="body2" className={classes.infoContent}>
                <a href={`mailto:${estate?.executorEmail}`} target="_blank">
                  {estate?.executorEmail || "--"}
                </a>
              </Typography>
              {isUber && (
                <Button
                  sx={{ mt: 1 }}
                  variant="outlined"
                  size="small"
                  onClick={() => {
                    setReassignDialogOpen(true);
                  }}
                >
                  Reassign Account
                </Button>
              )}
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body2">Phone</Typography>
              <Typography variant="body2" className={classes.infoContent}>
                {estate?.executorPhoneFormatObject?.formatInternational() ||
                  "--"}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="body2">Estate created at</Typography>
              <Typography variant="body2" className={classes.infoContent}>
                {estate?.creationDateWithTime || "--"}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="body2">Last activity</Typography>
              <Typography variant="body2" className={classes.infoContent}>
                {estate?.lastActivityWithTime || "--"}
              </Typography>
            </Grid>
            <Grid item xs={12}>
                <Typography variant="body2">Inbound funnel</Typography>
                <Typography variant="body2" className={classes.infoContent}>
                  {estate?.leadId ? "Probate Questionnaire" : "--"}
                </Typography>
              </Grid>
            {isUber ? (
              <>
                <Grid item xs={6}>
                  <Typography variant="body2">User id</Typography>
                  <Typography variant="body2" className={classes.infoContent}>
                    {estate?.userId || "--"}
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="body2">Estate id</Typography>
                  <Typography variant="body2" className={classes.infoContent}>
                    {estate?.id || "--"}
                  </Typography>
                </Grid>
              </>
            ) : null}
            {isUber ? (
              <>
                <Grid item xs={6}>
                  <Typography variant="body2">Lead id</Typography>
                  <Typography variant="body2" className={classes.infoContent}>
                    {estate?.leadId || "--"}
                  </Typography>
                </Grid>
              </>
            ) : null}
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};
