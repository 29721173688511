import { ActionTypes } from "../actions/types";
import { Language } from "../models/Language";
import { AnyAction } from "redux";

export const languagesReducer = (
  languages: Language[] | null = null,
  action: AnyAction
): Language[] | null => {
  switch (action.type) {
    case ActionTypes.FetchLanguages:
      return action.payload;
    case ActionTypes.FetchSingleLanguage:
      if (languages) {
        const index = languages?.findIndex(
          (language: Language) => language.id === action.payload.id
        );
        if (index !== -1) {
          const newLanguages = [...languages];
          newLanguages[index] = action.payload;
          return newLanguages;
        } else {
          return [...languages, action.payload];
        }
      } else {
        return [action.payload];
      }
    case ActionTypes.AppSignOut:
      return null;
    default:
      return languages;
  }
};
