import { AssetCategory, AssetCategoryJSON } from "./AssetCategory";
import { AssetSubCategory, AssetSubCategoryJSON } from "./AssetSubCategory";
import { AssetImage, AssetImagesJSON } from "./AssetImage";
import {
  AssetDetail,
  AssetDigital,
  AssetFinancial,
  AssetHousehold,
  AssetRealEstate,
  AssetVehicle,
} from "./AssetDetail";
import { InputType } from "../views/estate/EstateView/assets/assetDialog/inputs/InputTypes";
import { SelectOption } from "./SelectOption";
import { HelpContent } from "../views/estate/EstateView/assets/assetDialog/HelpComponent";
import { AssetFormValues } from "../views/estate/EstateView/assets/assetDialog/AssetDialogForm";

export type Image = {
  id?: number;
  s3_key?: string;
  s3_thumbnail_key?: string;
};

export type AssetAttribute = {
  label?: string;
  key?: string;
  caption?: string;
  placeholder?: string;
  type?: InputType;
  regex?: string | null;
  value?: string | number | null; // used to initialize formit
  options?: SelectOption[];
  helpContent?: HelpContent;
  rows?: number;
  maxLength?: number;
  noCommas?: boolean;
};

export type AttributeGroup = {
  group?: string;
  comment?: string;
  comment2?: string;
  attributes?: AssetAttribute[];
};

export type CategoryAttributes = {
  id?: number;
  categoryName?: string;
  comment?: string;
  // images?: Image[];
  groups?: AttributeGroup[];
};

export type AssetJSON = {
  id?: number;
  asset?: string;
  description?: string;
  value?: number;
  images?: AssetImagesJSON[];
  estate_id?: number;
  category_id?: number;
  category?: AssetCategoryJSON;
  subcategory_id?: number;
  subcategory?: AssetSubCategoryJSON;
  asset_household?: { quantity?: number };
  asset_financial?: { bank_institution?: string };
  asset_real_estate?: {
    address1?: string;
    address2?: string;
    assessor_parcel_number?: string;
    city?: string;
    states_id?: number;
    zip_code?: string;
    counties_id?: number;
    google_places_id?: string;
    formatted_address?: string;
    countries_id?: number;
    ownership?: string;
    has_mortgage?: string;
    remaining_mortgage?: number;
    title_type?: string;
    title?: string;
    future_plan?: string;
  };
  asset_vehicles?: {
    year?: number;
    make?: string;
    model?: string;
    vin?: string;
  };
  asset_digital?: { web_address?: string };
  is_probate?: number;
};

export class Asset {
  static path = "/assets";
  constructor(
    public id?: number,
    public asset?: string,
    public description?: string,
    public value?: number | null,
    public images?: AssetImage[],
    public estateId?: number,
    public categoryId?: number,
    public category?: AssetCategory,
    public subcategoryId?: number | null,
    public subcategory?: AssetSubCategory,
    public detail?: AssetDetail,
    public is_probate?: boolean | null
  ) {}

  static fromJSON = (json: AssetJSON): Asset => {
    let detail: AssetDetail | undefined = undefined;
    if (json?.category?.category) {
      // TODO: move these formJson steps to the AssetDetail model
      switch (json.category.category) {
        case "Household":
          detail = json?.asset_household
            ? new AssetHousehold(json?.asset_household?.quantity)
            : undefined;
          break;
        case "Financial":
          detail = json?.asset_financial
            ? new AssetFinancial(json?.asset_financial?.bank_institution)
            : undefined;
          break;
        case "Real Estate":
          detail = json?.asset_real_estate
            ? new AssetRealEstate(
                json?.asset_real_estate?.address1,
                json?.asset_real_estate?.address2,
                json?.asset_real_estate?.assessor_parcel_number,
                json?.asset_real_estate?.city,
                json?.asset_real_estate?.states_id,
                json?.asset_real_estate?.zip_code,
                json?.asset_real_estate?.counties_id,
                json?.asset_real_estate?.google_places_id,
                json?.asset_real_estate?.formatted_address,
                json?.asset_real_estate?.countries_id,
                json?.asset_real_estate?.ownership,
                json?.asset_real_estate?.has_mortgage,
                json?.asset_real_estate?.remaining_mortgage,
                json?.asset_real_estate?.title_type,
                json?.asset_real_estate?.title,
                json?.asset_real_estate?.future_plan
              )
            : undefined;
          break;
        case "Vehicles":
          detail = json?.asset_vehicles
            ? new AssetVehicle(
                json?.asset_vehicles?.make,
                json?.asset_vehicles?.model,
                json?.asset_vehicles?.year,
                json?.asset_vehicles?.vin
              )
            : undefined;
          break;
        case "Digital":
          detail = json?.asset_digital
            ? new AssetDigital(json?.asset_digital?.web_address)
            : undefined;
          break;
      }
    }

    return new Asset(
      json.id,
      json.asset,
      json.description,
      json.value,
      json.images?.map((imageJson: any, index: number) =>
        AssetImage.fromJSON(imageJson, index, json.estate_id)
      ),
      json.estate_id,
      json.category_id,
      json.category ? AssetCategory.fromJSON(json.category) : undefined,
      json.subcategory_id,
      json.subcategory
        ? AssetSubCategory.fromJSON(json.subcategory)
        : undefined,
      detail,
      json.is_probate === 1 ? true : json.is_probate === 0 ? false : null
    );
  };

  toJSON(): AssetJSON {
    const json: any = {};
    json.assets_id = this.id;
    this.asset ? (json.asset = this.asset) : null;
    this.description ? (json.description = this.description) : null;
    this.value ? (json.value = this.value) : null;
    this.estateId ? (json.estate_id = this.estateId) : null;
    this.category?.id || this.categoryId
      ? (json.categories_id = this.category?.id || this.categoryId)
      : null;
    this.subcategory?.id || this.subcategoryId
      ? (json.subcategories_id = this.subcategory?.id || this.subcategoryId)
      : null;
    this.is_probate !== undefined ? (json.is_probate = this.is_probate) : null;
    // combine the detail properties into a single object
    return { ...json, ...this?.detail?.toJson() };
  }

  toFormValues(): AssetFormValues {
    // translate probate value to string
    let probate = "null";
    if (this?.is_probate === true) {
      probate = "true";
    } else if (this?.is_probate === false) {
      probate = "false";
    }

    return {
      category: this?.category?.id || 0,
      subcategory: this?.subcategory?.id || null,
      name: this?.asset || "",
      value: this?.value || null,
      description: this?.description || "",
      is_probate: probate,
      // -- household --
      quantity: (this?.detail as AssetHousehold)?.quantity || null,
      // -- digital --
      web_address: (this?.detail as AssetDigital)?.web_address || "",
      // userName: string;
      // password: string;
      // -- financial --
      bank_institution:
        (this?.detail as AssetFinancial)?.bank_institution || "",
      // -- real estate --
      address1: (this?.detail as AssetRealEstate)?.address1 || "",
      address2: (this?.detail as AssetRealEstate)?.address2 || "",
      city: (this?.detail as AssetRealEstate)?.city || "",
      states_id: (this?.detail as AssetRealEstate)?.states_id || null,
      zip_code: (this?.detail as AssetRealEstate)?.zip_code || "",
      assessor_parcel_number:
        (this?.detail as AssetRealEstate)?.assessor_parcel_number || "",
      countries_id: (this?.detail as AssetRealEstate)?.countries_id || null,
      counties_id: (this?.detail as AssetRealEstate)?.counties_id || null,
      google_places_id:
        (this?.detail as AssetRealEstate)?.google_places_id || "",
      formatted_address:
        (this?.detail as AssetRealEstate)?.formatted_address || "",
      ownership: (this?.detail as AssetRealEstate)?.ownership || "",
      has_mortgage: (this?.detail as AssetRealEstate)?.has_mortgage || "",
      remaining_mortgage:
        (this?.detail as AssetRealEstate)?.remaining_mortgage || null,
      title_type: (this?.detail as AssetRealEstate)?.title_type || "",
      title: (this?.detail as AssetRealEstate)?.title || "",
      future_plan: (this?.detail as AssetRealEstate)?.future_plan || "",
      // -- vehicles --
      year: (this?.detail as AssetVehicle)?.year || null,
      make: (this?.detail as AssetVehicle)?.make || "",
      model: (this?.detail as AssetVehicle)?.model || "",
      vin: (this?.detail as AssetVehicle)?.vin || "",
    };
  }

  static fromFormValues(
    id?: number,
    values?: AssetFormValues,
    categories?: AssetCategory[]
  ): Asset {
    if (!values) return new Asset();
    let detail: AssetDetail | undefined = undefined;

    if (values?.category && categories) {
      switch (values.category) {
        case AssetCategory.getCategoryId(categories, "Household"):
          detail = new AssetHousehold(values.quantity);
          break;
        case AssetCategory.getCategoryId(categories, "Financial"):
          detail = new AssetFinancial(values?.bank_institution);
          break;
        case AssetCategory.getCategoryId(categories, "Real Estate"):
          detail = new AssetRealEstate(
            values?.address1,
            values?.address2,
            values?.assessor_parcel_number,
            values?.city,
            values?.states_id,
            values?.zip_code,
            values?.counties_id,
            values?.google_places_id,
            values?.formatted_address,
            values?.countries_id,
            values?.ownership,
            values?.has_mortgage,
            values?.remaining_mortgage,
            values?.title_type,
            values?.title,
            values?.future_plan
          );
          break;
        case AssetCategory.getCategoryId(categories, "Vehicles"):
          detail = new AssetVehicle(
            values?.make,
            values?.model,
            values?.year,
            values?.vin
          );
          break;
        case AssetCategory.getCategoryId(categories, "Digital"):
          detail = new AssetDigital(values?.web_address);
          break;
      }
    }

    // translate probate value to boolean | null
    let probate:boolean|null = null;
    if (values.is_probate === "true") {
      probate = true;
    } else if (values.is_probate === "false") {
      probate = false;
    }

    return new Asset(
      id,
      values.name,
      values.description,
      values.value,
      // values.images?.map((imageJson: any, index: number) =>
      //   AssetImage.fromJSON(imageJson, index, values.estate_id)
      // ),
      undefined,
      undefined,
      values.category,
      undefined,
      values.subcategory,
      undefined,
      detail,
      probate
    );
  }

  static ofCategory = (
    category?: AssetCategory,
    subCategory?: AssetSubCategory
  ) => {
    return new Asset(
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      category?.id,
      category,
      subCategory?.id,
      subCategory
    );
  };
}
